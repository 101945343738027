import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DashboardService } from 'projects/core/src/services/dashboard.service';
import moment from 'moment';

@Component({
  selector: 'app-widget-upcoming-projects',
  templateUrl: './widget-upcoming-projects.component.html',
  styleUrls: ['./widget-upcoming-projects.component.scss']
})
export class WidgetUpcomingProjectsComponent implements OnInit {

  @Input() widget; 

  @Output() onremove = new EventEmitter();
  @Output() onupdate = new EventEmitter();

  projects: any = [];

  constructor(public dashboardService: DashboardService) { 
  }

  ngOnInit(): void {
    this.getUpcomingProjects();
    for(var i=0; i++; i < this.projects.length) {
      this.projects[i]["projectStartDate"] = (moment(this.projects[i]["projectStartDate"])).format("dd MMMM");
    }
  }

  getUpcomingProjects() {
    var userIds = localStorage.getItem('dashboard');
    let users: string[] = [];
    if (userIds != null && userIds != '') {
      users = userIds.split(',').map(item => item.trim());
    }
    this.dashboardService.getUpcomingProjects(users).subscribe(data => {
      this.projects = data;
    });

  }

  remove() { this.onremove.emit(); }

  updateWidget() {
    this.widget = {... this.widget};
    this.onupdate.emit();
  }

  updateTitle(widgetTitle) {
    this.widget.title = widgetTitle;
    this.updateWidget();
  }

}
