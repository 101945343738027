<div class="nav-content">
  <div *ngIf="!homepage" class="container-fluid px-4">
    <div class="col">
      <ng-container *ngIf="dashboards?.length > 0">
        <div class="btn-group mr-3 mb-1" dropdown>
          <button id="button-basic" dropdownToggle type="button" class="btn btn-outline-secondary dropdown-toggle" aria-controls="dropdown-basic">
            <span class="mx-2 h5">{{ currentDashboard?.name }}</span>
            <span class="caret"></span>
          </button>
          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic" style="width: 220px">
            <li role="menuitem" *ngFor="let dashboard of this.dashboards; index as i" class="p-2">
              <or-action-button [label]="dashboard.name" (selected)="openDashboard(dashboard)"></or-action-button>
            </li>
            <li role="menuitem" class="p-2">
              <or-action-button label="+ Add New" [style]="'dark'" (selected)="newDashboard()"></or-action-button>
            </li>
          </ul>
        </div>
      </ng-container>
      <ng-container *ngIf="dashboards?.length == 0">
        <or-action-button label="+ Add New" [style]="'dark'" (selected)="newDashboard()"></or-action-button>
      </ng-container>
    </div>
    <div class="col text-right">
      <ng-container *ngIf="currentDashboard != null">
        <button type="button" class="btn btn-sm btn-secondary" (click)="actionSelected('add-widget')">
          <i class="fas fa-plus cursor-pointer"></i> Add Widget
        </button>
        <div class="btn-group" dropdown>
          <span id="button-basic" dropdownToggle type="button" class="btn-lg p-2" controls="dropdown-basic">
            <span><i class="fas fa-cog or-secondary opacity-70"></i></span>
          </span>
          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic" style="width: 100px;">
            <li role="menuitem" class="p-1">
              <or-action-button label="Rename" (selected)="actionSelected('rename')"></or-action-button>
            </li>
            <li role="menuitem" class="p-1">
              <or-action-button label="Delete" (selected)="actionSelected('delete')"></or-action-button>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="homepage" class="container-fluid px-4">
    <div class="w-100">
      <div class="nav-font16 grey4 text-center">{{ now | date:'fullDate'}}</div>
      <div class="nav-font20 font-weight-bold grey5 text-center">{{ greeting }}</div>
      <div class="row justify-content-center my-4" *ngIf="mode != 'manager'">
        <div class="btn-group summary grey4 nav-font14 pr-3">
          <button type="button" class="btn" dropdown container="body">
            <span id="button-basic" dropdownToggle type="button" class="border-right p-1 px-3" controls="dropdown-basic">
              <span class="font-weight-bold">
                <span class="font-weight-normal" *ngIf="homeBannerOffset !== 'Today'">My</span>
                {{homeBannerOffset}}
              </span>
              <i class="far fa-chevron-down grey4 ml-2"></i>
            </span>
            <ul *dropdownMenu class="dropdown-menu position-relative cursor-pointer" role="menu" aria-labelledby="button-basic" style="width: 60px;">
              <a class="btn px-3 py-1 dropdown-item" (click)="setOffset('today')">Today</a>
              <a class="btn px-3 py-1 dropdown-item" (click)="setOffset('week')">Week</a>
              <a class="btn px-3 py-1 dropdown-item" (click)="setOffset('month')">Month</a>
            </ul>
          </button>
          <button type="button" class="btn px-3 py-1 summary-item">
            <i class="fas fa-check grey4 font-weight-normal"></i>
            <span class="mx-2 font-weight-bold grey5">{{homeBannerInfo?.tasksCompleted}}</span>
            <span class="grey4">Tasks Completed</span>
          </button>
          <button type="button" class="btn px-3 py-1 summary-item">
            <i class="fak fa-medal grey4 fa-lg font-weight-normal"></i>
            <span class="mx-2 font-weight-bold grey5">{{homeBannerInfo?.projectsCompleted}}</span>
            <span class="grey4">Projects Completed</span>
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-8">
        <div class="my-3" *ngIf="mode == 'manager'">
          <app-widget-filter-users totalColumns="3" (onChange)="setDashboardsFilter($event)"></app-widget-filter-users>
        </div>       
        <div class="my-3" *ngIf="showProject">
          <app-widget-current-projects-tasks></app-widget-current-projects-tasks>
        </div>
        <div class="my-3" *ngIf="showJobs">
          <app-widget-jobs-to-be-done></app-widget-jobs-to-be-done>
        </div>
      </div>
      <div class="col-4">
        <div class="py-3 h-100" *ngIf="showUpCommingProjects">
          <app-widget-upcoming-projects></app-widget-upcoming-projects>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-8">
        <div class="my-0 h-100" *ngIf="showpriorities">
          <app-widget-my-priorities></app-widget-my-priorities>
        </div>
      </div>
      <div class="col-4">
        <div class="my-0" *ngIf="showScore">
          <app-widget-ytd></app-widget-ytd>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid px-4" *ngIf="!homepage && currentDashboard != null">
    <ktd-grid
      [cols]="cols"
      [rowHeight]="rowHeight"
      [layout]="this.layout"
      gap=6
      (layoutUpdated)="onLayoutUpdated($event)">
      <ktd-grid-item *ngFor="let widget of currentDashboard?.widgets; trackBy:trackById; widget" [id]="widget.id" [draggable]="!homepage" [resizable]="!homepage">
        <!-- Your grid item content goes here -->
        <div class="widget">
          <ng-container *ngIf="widget.type =='project-by-manager'">
            <app-widget-project-by-manager [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-project-by-manager>
          </ng-container>
          <ng-container *ngIf="widget.type =='project-by-category'">
            <app-widget-project-by-category [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-project-by-category>
          </ng-container>
          <ng-container *ngIf="widget.type =='project-health-by-client'">
            <app-widget-project-health-by-client [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-project-health-by-client>
          </ng-container>
          <ng-container *ngIf="widget.type =='newsfeed'">
            <app-widget-newsfeed [widget]="widget"  (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-newsfeed>
          </ng-container>
          <ng-container *ngIf="widget.type =='project-health'">
            <app-widget-project-health  [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-project-health>
          </ng-container>
          <ng-container *ngIf="widget.type =='project-favourite'">
            <app-widget-project-favourite  [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-project-favourite>
          </ng-container>
          <ng-container *ngIf="widget.type =='project-margin'">
            <app-widget-margin  [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-margin>
          </ng-container>
          <ng-container *ngIf="widget.type =='revenue-by-manager'">
            <app-widget-revenue-by-manager [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-revenue-by-manager>
          </ng-container>
          <ng-container *ngIf="widget.type =='custom-report'">
            <app-widget-custom-report [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-custom-report>
          </ng-container>
          <ng-container *ngIf="widget.type =='jobs-to-be-done'">
            <app-widget-jobs-to-be-done [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-jobs-to-be-done>
          </ng-container>
          <ng-container *ngIf="widget.type =='my-priorties'">
            <app-widget-my-priorities [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-my-priorities>
          </ng-container>
          <ng-container *ngIf="widget.type == 'ytd'">
            <app-widget-ytd [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-ytd>
          </ng-container>
          <ng-container *ngIf="widget.type == 'upcoming-projects'">
            <app-widget-upcoming-projects [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-upcoming-projects>
          </ng-container>

          <!-- placeholders -->
          <ng-container *ngIf="widget.type =='placeholder'">
            <div class="card border-0 h-100">
              <div class="card-header border-bottom">
                <app-widget-title [widget]="widget" ></app-widget-title>
              </div>
              <div class="card-body">
               PLACEHOLDER
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="widget.type =='tasks'">
            <div><h1> TO DO: TASKS</h1></div>
          </ng-container>
          <ng-container *ngIf="widget.type =='partner-fraud-rate'">
            <app-widget-partner-fraud-rate [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)"></app-widget-partner-fraud-rate>
          </ng-container>
          <!-- Optional Custom placeholder template -->
          <ng-template ktdGridItemPlaceholder>
            <!-- Custom placeholder content goes here -->
          </ng-template>
        </div>
      </ktd-grid-item>
    </ktd-grid>
  </div>
  <div *ngIf="homepage" class="text-center nav-font14 grey3 mt-4 oRSmallText">
    <span>© 2024 OpinionRoute, LLC. | All Rights Reserved.</span>
  </div>
</div>

<ng-template #modalConfirm>
  <div class="modal-header">
    <p class="h5">Confirm Delete</p>
  </div>
  <div class="modal-body text-center">
    <p class="h6">Do you wish to delete this Dashboard?</p>
    <button type="button" class="btn btn-outline-secondary m-2" (click)="removeDashboard()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="modalRefConfirm.hide()">No</button>
  </div>
</ng-template>
