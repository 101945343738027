import { RulesComponent } from './../../rules/rules.component';
import { Component, OnInit, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { AlertifyService, SettingsService, ProjectService, Project, UtilsService, AuthService, ProjectDataService } from 'core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportFilesService } from 'projects/core/src/services/exportFiles.service';
import { formatDate } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UnsavedchangesGuardService } from '../../../_guards/unsavedchanges.guard.service';
import { ProjectIdSuiteComponent } from '../project-id-suite/project-id-suite.component';
import { ScheduleComponent } from '../../schedule/schedule.component';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
  providers: []
})
export class ProjectDetailsComponent implements OnInit, OnDestroy {
  openModalRef: BsModalRef;
  projectList: Array<string> = [];
  project = {} as Project;
  projectType: any;
  sections = [];
  pageSettings: any = {};
  subsection: string;
  showSettings = false;
  ready = false;
  editingRule = false;
  cardNotes = { open: false, expanded: false };
  cardRules = { open: false };
  currentSection = 0;
  openSetupTab = 1;
  projectRespondents = [];
  projectPerformance = [];
  urls: any = {
    completeRedirectURL: '',
    terminateRedirectURL: '',
    overQuotaRedirectURL: '',
    qcRedirectURL: ''
  };
  bsModalRef: BsModalRef;
  instanceId: string;
  hasDropDownActions: boolean = false;
  sidebarSectionOpen = false;
  currentSideSection = "";
  refreshMyTasks: number = 0;
  refreshTasks: number = 0;
  sectionFromUrl = '';
  sidebarOpen = false;
  vendorId: string='';
  isVendorView: boolean = false;
  vendorViewId: string='';
  clientViewId: string='';
  canChangeRoute = false;
  intendedRoute = '';
  intendedSection = '';

  @ViewChild('rules')
  rules: RulesComponent;

  @ViewChild(ProjectIdSuiteComponent) idsuiteChild!: ProjectIdSuiteComponent;
  @ViewChild(ScheduleComponent) scheduleChild!: ScheduleComponent;

  @ViewChild('setup') setup;
  constructor(private alertify: AlertifyService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
    public auth: AuthService,
    private settingsService: SettingsService,
    private exportFilesService: ExportFilesService,
    private utilsService: UtilsService,
    private modalService: BsModalService,
    private projectDataService: ProjectDataService,
    private unsavedChangesService: UnsavedchangesGuardService) { }

  ngOnInit() {
    this.instanceId = this.auth.getInstance();
    this.route.params.subscribe((params) => {
      this.ready = false;
      const pid = params.id;
      if (params?.vendorId) {
        this.vendorViewId = params?.vendorId;
        this.projectDataService.setvendorViewSidebar(this.vendorViewId);
      }else if(params?.clientId){      
        this.clientViewId = params?.clientId;
        this.projectDataService.setclientViewSidebar(this.clientViewId);
      }else {
        this.vendorViewId = '';
        this.projectDataService.setvendorViewSidebar('');
        this.projectDataService.setclientViewSidebar('');
      }
      if (params.section) {
        this.sectionFromUrl = params.section;
        if (this.sectionFromUrl == 'settings') this.showSettings = true;
      }

      this.projectRespondents = [];
      this.projectPerformance = [];
      this.loadData(pid);
    });

    //subscribe to sidebar (project submenu) click
    this.projectDataService.getProject.subscribe(data => {
      if (data?.sidebarProject)
        this.showSideSection(data?.sidebarProject);
    });

    //subscribe to sidebar mode
    this.projectDataService.getSidebarMode.subscribe(data => {
      data = data || 'reduced';
      this.sidebarOpen = (data === 'reduced' ? false : true);
    });

       //subscribe to unsaved changes within idsuite page
      this.projectDataService.getAnyData.subscribe(data => {
        this.intendedRoute = this.intendedRoute == '' ? this.router.url : this.intendedRoute;

        if (!this.intendedRoute) return;

        if (data?.type === 'idsuite-unsaved-changes') {
          this.canChangeRoute = data?.data;

        if (!data?.isSection)
          this.router.navigateByUrl(this.intendedRoute);
        else {
          if (this.intendedSection !== 'settings') this.switchSection(this.intendedSection);
          else this.openSettings();
        }

        }
        else 
        {
          this.canChangeRoute = true;
        }
    });
  }

  isClientview:boolean=false;
  ngAfterViewInit() { 
    setTimeout(() => {
      this.projectDataService.getClientViewSidebar.subscribe(data => {
        if (data) {        
          this.isClientview = data != '';
        }
      });
    }, 500 * 1);
    
  }



  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(event: BeforeUnloadEvent): void {
    if (!this.canChangeRoute && this.idsuiteChild?.canDeactivate) {
      event.returnValue = 'unsaved';
      event.preventDefault();
    }
  }

  canDeactivate(nextRoute: string, showDialog: boolean): boolean {
    this.intendedRoute = nextRoute;
    if (!this.canChangeRoute && (this.idsuiteChild?.canDeactivate || this.scheduleChild?.canDeactivate)) {
      if (this.idsuiteChild) return this.idsuiteChild.canDeactivate(showDialog);
      if (this.scheduleChild) return this.scheduleChild.canDeactivate(showDialog);
    } else return true;
  }

  ngOnDestroy() {
    this.projectDataService.setCurrentProject(null);
  }

  refreshPerformance() {
    this.projectService.GetProjectParticipants(this.project.id, "").subscribe(data => {
      this.projectPerformance = data.map(e => {
        const ret: any = { ...e };
        const inTime = new Date(e.date);
        ret.date = new Date(inTime.getFullYear(), inTime.getMonth(), inTime.getDate());
        ret.partner = ret.vendor;
        ret.partnerId = ret.vendorId;
        ret.complete = (e.completeLois == null) ? 0 : e.completeLois.length;

        return ret;
      }).sort((a, b) => (new Date(a.in).valueOf() - new Date(b.in).valueOf()));

      this.projectRespondents = this.projectPerformance.filter(e => e.partner != null)
    });
  }

  refreshProject() {
    this.projectService.GetProject(this.project.id,this.vendorViewId).subscribe(data => {
      this.project = this.projectService.EnrichProject(data);
    }, error => {
      this.alertify.error('Unable to get Project');
    });
  }

  handleIdSuiteChange(project) { this.project = project }
  handleSettingsChange() { this.project = { ...this.project }; }
  infoCallback(event) {
    if (event == 'auditLog')
      this.showSubsection('audit');
  }
  loadData(id: any) {  
    this.projectService.GetProject(id,this.vendorViewId).subscribe(data => {
      this.project = this.projectService.EnrichProject(data);
      this.pageSettings = this.settingsService.getProjectPageSettings(1, this.project.projectStatus);
      this.GetProjectLayout();
    }, error => {
      this.alertify.error('Unable to get Project');
    });
  }

  checkDropDownActions() {
    if (this.projectType?.showAuditLogs ||
      this.projectType?.showCopyUrls ||
      this.projectType?.showDownload ||
      this.projectType?.showReconciliation)
      this.hasDropDownActions = true;
    else this.hasDropDownActions = false;
  }

  GetProjectLayout() {
    this.projectService.GetProjectTypes(null, true, false, this.vendorViewId,this.clientViewId).subscribe(data => {
      this.projectType = data.find(e => e.id === this.project.projectTypeId);
      this.checkDropDownActions();
      this.sections = (this.project.projectStages == null || this.project.projectStages.length == 0) ? this.projectType.sections.filter(e => e.type != 'schedule') : this.projectType.sections;

      let sectionFromUrlIndex = null;

      for (let i = 0; i < this.sections.length; i++) {
        if (this.replaceHyphenWithSpaces(this.sectionFromUrl) == this.sections[i].title.toLowerCase()) {
          sectionFromUrlIndex = i;
        }
        this.sections[i].cards.forEach(card => {
          card.mode = this.pageSettings.cardSettings[card.cardType] || 'reduced';
        });
      }

      if (!this.showSettings) {
        if (sectionFromUrlIndex != null) {
          this.currentSection = sectionFromUrlIndex;
        } else {
          const lastOpenedSection = localStorage.getItem("currentSection_" + this.project.projectStatus);
          if (lastOpenedSection) {
            this.currentSection = this.sections.findIndex(e => e.title === lastOpenedSection);
            if (this.currentSection == -1) this.currentSection = 0;
          } else {
            this.currentSection = 0;
          }
        }
        const title = this.sections[this.currentSection].title;
        localStorage.setItem("currentSection_" + this.project.projectStatus, title);
        history.pushState(null, '', `/projects/${this.project.id}/${this.replaceSpacesWithHyphen(title.toLowerCase())}`);
      }

      this.ready = true;

      // update currentProject (opened) for sidebar and navbar
      this.closeSideBar();

      this.showSection();
    }, error => {
      this.alertify.error('Unable to get Project Layout');
    });
  }
  openSetup(defaultTab) {
    const section = this.sections.findIndex(e => e.type === 'setup');
    this.openSetupTab = defaultTab;
    if (section != null) {
      this.switchSection(section);
    }
  }
  openExportReport(modalTemplate) {
    this.openModalRef = this.modalService.show(modalTemplate, { ignoreBackdropClick: true, keyboard: false, class: 'modal-md' });
  }

  showSubsection(section) { this.subsection = section; }

  openSettings() {

    //check unsaved changes. Only runs validation if leaving those pages
    if (!this.canChangeRoute) {
      if (this.sections[this.currentSection].type === 'id-suite') {
        this.intendedSection = 'settings';
        this.idsuiteChild.isSection = true;

        if (this.idsuiteChild?.canDeactivate)
          this.canChangeRoute = this.idsuiteChild.canDeactivate(true);

        if (!this.canChangeRoute) return;
      }

      if (this.sections[this.currentSection].type === 'schedule') {
        this.intendedSection = 'settings';
        this.scheduleChild.isSection = true;

        if (this.scheduleChild?.canDeactivate)
          this.canChangeRoute = this.scheduleChild.canDeactivate(true);

        if (!this.canChangeRoute) return;
      }

    }

    this.showSettings = true;
    this.currentSection = null;
    history.pushState(null, '', `/projects/${this.project.id}/settings`);
  }

  closeSettings() { this.switchSection(this.currentSection); }

  switchSection(section) {

    const title = this.sections[section].title;
    const type = this.sections[section].type;

    //check unsaved changes. Only runs validation if leaving those pages
    if (!this.canChangeRoute && this.currentSection != null) {

      if (this.sections[this.currentSection].type === 'id-suite' && type !== 'id-suite') {
        this.intendedSection = section;
        this.idsuiteChild.isSection = true;

        if (this.idsuiteChild?.canDeactivate)
          this.canChangeRoute = this.idsuiteChild.canDeactivate(true);

          if (!this.canChangeRoute) return;
      }

      if (this.sections[this.currentSection].type === 'schedule' && type !== 'schedule') {
        this.intendedSection = section;
        this.scheduleChild.isSection = true;

        if (this.scheduleChild?.canDeactivate)
          this.canChangeRoute = this.scheduleChild.canDeactivate(true);

          if (!this.canChangeRoute) return;
      }

    }

    this.canChangeRoute = false;
    this.showSettings = false;
    if (this.setup) {
      this.unsavedChangesService.register(
        this.setup,
        () => this.setup?.hasChanges
      );
    }

    this.unsavedChangesService.check([this.setup], false).then((cont) => {
      if (cont) {
        this.subsection = null;
        // ****
        this.currentSection = section;
        this.cardRules.open = false;
        this.saveProjectViewSettings();
        this.showSection();

        localStorage.setItem("currentSection_" + this.project.projectStatus, title);
        history.pushState(null, '', `/projects/${this.project.id}/${this.replaceSpacesWithHyphen(title.toLowerCase())}`);
      }
    });
  }

  // Imports and Exports
  download(type) {
    if (type === 'participant') {
      const filename = formatDate(new Date(), 'MM-dd-yyyy', 'en').toString() + '-' + this.project.projectCode + '.xlsx';
      this.alertify.message('Your file will download once ready');
      this.projectList = [this.project.id];

      if (this.auth.isVendor()) {
        this.exportFilesService.downloadVendorParticipantData(this.projectList, filename, this.auth.getUser().vendorId).subscribe(url => {
          window.location.assign(url);
        }, error => {
          this.alertify.error(error);
        }, () => { });
      } else {
        this.exportFilesService.downloadParticipantData(this.projectList, filename, true).subscribe(url => {
          console.log(url);
          this.utilsService.downloadXLSX(url, filename);
          //window.location.assign(url);
        }, error => {
          this.alertify.error(error);
        }, () => {
          // All Good
        });
      }
    }
  }

  showSection() {

    // TO DO: HOW DO WE DECIDE TO REFRESH PERFORMANCE?
    if (this.projectPerformance.length === 0) {
      let getRespondents = false;
      if (this.sections[this.currentSection]?.type === 'quotas') {
        getRespondents = true;
      }
      else {
        for (let i = 0; i < this.sections[this.currentSection]?.cards.length && !getRespondents; i++) {
          const type = this.sections[this.currentSection]?.cards[i].cardType;
          // Check if need project report data for this view
          getRespondents = (
            type === 'survey-performance-chart' ||
            type === 'survey-performance-table' ||
            type === 'vendor' ||
            type === 'survey-cleanid' ||
            type === 'vendor' ||
            type === 'survey-dedupe' 
          );
        }
      }

      if (getRespondents) {
        // TO DO: RENAME THIS TO SUMMARY AS WE ARE NOT GETTING ALL DATA
        this.projectService.GetProjectParticipants(this.project.id,this.vendorViewId).subscribe(data => {
          this.projectPerformance = data.map(e => {
            const ret: any = { ...e };
            const inTime = new Date(e.date);
            ret.date = new Date(inTime.getFullYear(), inTime.getMonth(), inTime.getDate());
            ret.partner = ret.vendor;
            ret.partnerId = ret.vendorId;
            ret.complete = (e.completeLois == null) ? 0 : e.completeLois.length;

            return ret;
          }).sort((a, b) => (new Date(a.in).valueOf() - new Date(b.in).valueOf()));

          this.projectRespondents = this.projectPerformance.filter(e => e.partner != null)
        });
      }
    }
  }
  cardResize(mode, card) {
    card.mode = mode;
    this.saveProjectViewSettings();
  }

  notesResize(mode) { this.cardNotes.expanded = (mode === 'expanded'); }
  projectStatusChanged(status) { this.project.projectStatus = status; }
  updatePartnerStatus(status) {
    this.project.projectSegments[0].projectSurveyPartners.forEach((e) => {
      e.partnerStatus = status;
    });
  }

  downloadCleanId() {
    this.alertify.message('Your file will download once ready');
    const filename = formatDate(new Date(), 'MM-dd-yyyy', 'en').toString() + '-' + this.project.projectCode + '.zip';
    this.exportFilesService.downloadPartnerParticipants(this.project.id, filename).subscribe(url => {
      window.location.assign(url);
    }, error => {
      this.alertify.error(error);
    }, () => { });
  }

  copyUrls() {
    this.settingsService.getSurveyLinkUrls().subscribe(urls => {
      const numberOfPartners = this.project.projectSegments[0].projectSurveyPartners.length;
      let totalString = "";
      let name;
      let url;

      for (let i = 0; i < numberOfPartners; i++) {
        if (this.project.projectSegments[0].projectSurveyPartners[i].isDeleted) continue;

        name = this.project.projectSegments[0].projectSurveyPartners[i].partnerName;
        url = this.project.projectSegments[0].projectSurveyPartners[i].surveyEntryUrl;

        if (i === 0) {
          totalString = name.concat('\n' + url + '\n\n');
        } else {
          totalString = totalString.concat(name.concat('\n' + url + '\n\n'));
        }
      }

      totalString = totalString.concat('\n' + 'CompleteRedirectUrl  = ' + urls.completeRedirectURL + '\n' +
        'TerminateRedirectUrl = ' + urls.terminateRedirectURL + '\n' +
        'OverQuotaRedirectUrl = ' + urls.overQuotaRedirectURL + '\n' +
        'QCRedirectUrl        = ' + urls.qcRedirectURL);

      this.utilsService.copyTextToBuffer(totalString);
      this.alertify.success('URLs copied');
    }, error => {
      this.alertify.error('Unable to get Survey Links');
    }, () => { });
  }

  saveProjectViewSettings() {
    const cardSettings = {};
    this.sections.forEach(section => {
      section.cards.forEach(card => {
        // TO DO: Handle if card is there in more than one section and has a different mode in each
        if (cardSettings[card.cardType] == null) { cardSettings[card.cardType] = card.mode; }
      });
    });

    this.settingsService.setProjectPageSettings(1, this.project.projectStatus, { section: this.currentSection, cardSettings });
  }

  handleSave(data) { this.refreshProject(); }

  showSideSection(section) {
    this.currentSideSection = section;
    this.sidebarSectionOpen = true;
    this.projectDataService.setCurrentProject({
      project: this.project,
      projectType: this.projectType,
      sidebarSectionOpen: true,
      projectOpen: true,
      projectSummaryOpen: false
    });
  }
  closeSideBar() {
    // send event to change navbar
    this.sidebarSectionOpen = false;
    this.editingRule = false;
    this.projectDataService.setCurrentProject({
      project: this.project,
      projectType: this.projectType,
      sidebarSectionOpen: false,
      projectOpen: true,
      projectSummaryOpen: false
    });
  }

  callbackTasks(evt) {
    if (evt) {
      this.refreshMyTasks = new Date().getTime();
    }
  }

  callbackRules(evt) {
    this.editingRule = evt?.editing || false;
  }

  callbackMyTasks(evt) {
    if (evt) {
      this.refreshTasks = new Date().getTime();
    }
  }

  redirectToSection(sectionType) {
    if (sectionType != null) {
      var i = this.sections.findIndex(x => x.type === sectionType);
      if (i >= 0) this.switchSection(i);
      else this.alertify.error('Unable to find section for redirect')
    }
  }

  replaceSpacesWithHyphen(input: string): string {
    return input.replace(/\s+/g, '-');
  }

  replaceHyphenWithSpaces(input: string): string {
    return input.replace(/-/g, ' ');
  }
}
