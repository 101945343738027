import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PartnersComponent } from './sections/partners/partners.component';
import { ClientsComponent } from './sections/clients/clients.component';
import { ClientDetailsComponent } from './sections/clients/client-details/client-details.component';
import { TeamComponent } from './sections/team/team.component';
import { DashboardComponent } from './sections/dashboard/dashboard.component';
import { ProjectsComponent } from './sections/projects/projects.component';
import { ProjectFamilySummaryComponent } from './sections/projects/project-family-summary/project-family-summary.component';
import { ProjectDetailsComponent } from './sections/projects/project-details/project-details.component';
import { AuditReportComponent } from './sections/reports/audit-report/audit-report.component';
import { PerformanceReportComponent } from './sections/reports/performance-report/performance-report.component';
import { CleanidReportComponent } from './sections/reports/cleanid-report/cleanid-report.component';
import { AudienceComponent } from './sections/audience/audience.component';

import { LoginComponent } from './sections/login/login.component';
import { AuthGuard } from './_guards/auth.guard';
import { AuthGuestGuard } from './_guards/auth-guest.guard';
import { ResetPasswordComponent } from './sections/reset-password/reset-password.component';
import { ResetLinkComponent } from './sections/reset-link/reset-link.component';
import { ChangePasswordComponent } from './sections/change-password/change-password.component';
import { AccountEditComponent } from './sections/account-edit/account-edit.component';
import { HelpComponent } from './sections/help/help.component';
import { OnlineHelpComponent } from './sections/onlinehelp/onlinehelp.component';
import { AboutComponent } from './sections/about/about.component';
import { NotesComponent } from './sections/notes/notes.component';
import { PartnerDetailsComponent } from './sections/partners/partner-details/partner-details.component';
import { AlertSettingsComponent } from './sections/alert-settings/alert-settings.component';
import { UnsavedchangesGuardService } from './_guards/unsavedchanges.guard.service';
import { ReportBuilderComponent } from './sections/reports/builder/report-builder.component';
import { ConfirmationLinkComponent } from './sections/confirmation-link/confirmation-link.component';
import { SurveyTesterComponent } from './components/survey-tester/survey-tester.component';
import { ScheduleComponent } from './sections/schedule/schedule.component';
import { WorkloadReportComponent } from './sections/reports/workload-report/workload-report.component';
import { ClientReportComponent } from './sections/reports/client-report/client-report.component';
import { VendorReportComponent } from './sections/reports/vendor-report/vendor-report.component';
import { LicenseeReportComponent } from './sections/reports/licensee-report/licensee-report.component';
import { ResponseIdApiReportComponent } from './sections/reports/responseid-api-report/responseid-api-report.component';
import { CanDeactivateGuard } from './_guards/candeactivate.guard.service';
import { ProjectIdSuiteComponent } from './sections/projects/project-id-suite/project-id-suite.component';

const routes: Routes = [
  {path: '', component: LoginComponent},
  {path: 'resetpassword', component: ResetPasswordComponent},
  {path: 'resetlink', component: ResetLinkComponent},
  {path: 'confirmationlink', component: ConfirmationLinkComponent},
  {
    path: 'survey-tester/guest', component: SurveyTesterComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuestGuard]
  },
  {
      path: '',
      runGuardsAndResolvers: 'always',
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard, UnsavedchangesGuardService],
      children: [
          {path: 'home', component: DashboardComponent},
          {path: 'home/:mode', component: DashboardComponent},
          {path: 'dashboard', component: DashboardComponent},
          {path: 'audience', component: AudienceComponent},

          {path: 'projects', component: ProjectsComponent},
          {path: 'projects/list/:id', component: ProjectsComponent},
          {path: 'projects/summary/:bidNumber', component: ProjectFamilySummaryComponent},

          {path: 'projects/:id/:section', component: ProjectDetailsComponent, canDeactivate: [CanDeactivateGuard]},
          {path: 'projects/:id/vendorview/:vendorId', component: ProjectDetailsComponent},
          {path: 'projects/:id/clientview/:clientId', component: ProjectDetailsComponent},
          {path: 'projects/:id', component: ProjectDetailsComponent},

          {path: 'schedule', component: ScheduleComponent},
          {path: 'partners', component: PartnersComponent},
          {path: 'vendors', component: PartnersComponent},
          {path: 'vendors/:id', component: PartnerDetailsComponent},
          {path: 'clients', component: ClientsComponent},
          {path: 'clients/:id', component: ClientDetailsComponent, data: {tab: "projects"}},
          {path: 'team', component: TeamComponent},
          {path: 'reports/cleanid/:mode/:instanceid', component: CleanidReportComponent},
          {path: 'reports/cleanid/:mode', component: CleanidReportComponent},
          {path: 'reports/performance', component: PerformanceReportComponent},
          {path: 'reports/audit', component: AuditReportComponent},
          {path: 'reports/builder', component: ReportBuilderComponent},
          {path: 'reports/builder/:id', component: ReportBuilderComponent},
          {path: 'reports/workload', component: WorkloadReportComponent},
          {path: 'reports/client/:mode', component: ClientReportComponent},
          {path: 'reports/vendor', component: VendorReportComponent},
          {path: 'reports/responseid/:mode/:instanceid', component: ResponseIdApiReportComponent},
          {path: 'reports/responseid/:mode', component: ResponseIdApiReportComponent},
          {path: 'reports/licensee', component: LicenseeReportComponent},
          {path: 'changepassword', component: ChangePasswordComponent},
          {path: 'account', component: AccountEditComponent},
          {path: 'account/:section', component: AccountEditComponent},
          {path: 'onlinehelp', component: OnlineHelpComponent},
          {path: 'about', component: AboutComponent},
          {path: 'notes', component: NotesComponent},
          {path: 'alert-settings', component: AlertSettingsComponent},
          {path: 'survey-tester', component: SurveyTesterComponent }
      ]
  },
  {path: '**', redirectTo: '', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
