import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { AlertifyService, AuthService, SurveyTesterService } from 'core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import writeXlsxFile from "write-excel-file";

@Component({
  selector: 'survey-tester-activity',
  templateUrl: './survey-tester-activity.component.html',
  styleUrls: ['./survey-tester-activity.component.scss']
})
export class SurveyTesterActivityComponent {

  @Input() public set data(args) {
    this.client = args.client;
    this._instanceId = this.auth.getInstance();
    this._surveyId = args.surveyId || '';
    this.replaySession.playMode = args.playMode || false;
    this.getSessions();
  }

  @ViewChild('modalConfirmPlayMode') modalRefPlayMode: BsModalRef;
  @ViewChild('modalSurveyPath') modalRef: BsModalRef;
  openModalRef;

  _surveyId = '';
  client = ''
  loadType: any;
  _instanceId = '';
  sessions = [];
  teamSessions = [];
  individualSessions = [];
  sessionViewed = null;
  individual = false;
  notAskAgainPlayMode = false;
  replaySession: any = {};
  summary = { totalOpen: 0, totalResolved: 0, totalClosed: 0, totalErrors: 0, totalChanges: 0, totalOther: 0 };

  tooltipSummary = `
    <div class="nav-primary text-left p-2">
      <span class="d-block mb-3">
        <span class="d-block font-weight-bold">Opened</span>
        <span class="d-block">Opened comments for programming changes.</span>
      </span>
      <span class="d-block mb-3">
        <span class="d-block font-weight-bold">Resolved</span>
        <span class="d-block">Changes made by programming sent for approval.</span>
      </span>
      <span class="d-block mb-3">
        <span class="d-block font-weight-bold">Closed</span>
        <span class="d-block">Approved and cancelled comments.</span>
      </span>
      <span class="d-block mb-3">
        <span class="d-block font-weight-bold">Program errors</span>
        <span class="d-block">Changes due to an error by programming.</span>
      </span>
      <span class="d-block mb-3">
        <span class="d-block font-weight-bold">Client changes</span>
        <span class="d-block">Changes made by client from original instruction.</span>
      </span>
      <span class="d-block mb-3">
      <span class="d-block font-weight-bold">Other changes</span>
      <span class="d-block">Comments that don't fall under another category</span>
    </span>
    </div>`;

  @Output() callback = new EventEmitter();

  constructor(
    public auth: AuthService,
    private alertify: AlertifyService,
    private service: SurveyTesterService,
    private modalService: BsModalService
  ) { }

  handleAction(session, action) {
    if (action === 'or_surveytester_replayStarted') {

      const exists = this.individualSessions.some(x => x?.playMode);
      if (exists) {
        this.alertify.error('You are in play mode already. Stop the current session to start a new one.');
        return;
      }

      this.replaySession = session;

      let keepPlayMode = localStorage.getItem('keepPlayMode');
      if (keepPlayMode == null) {
        this.openModal(this.modalRefPlayMode);
      }
      else {
        session.playMode = true;
        this.callback.emit({ type: action, session: session });
      }
    }
    else if (action === 'or_surveytester_replayStopped') {
      session.playMode = false;
      this.callback.emit({ type: action, session: session });
    }
    else if (action === 'or_surveytester_view') {
      if (!this.sessionViewed) {
        this.service.getSessionActivities(session.id).subscribe((activity) => {
          activity.forEach(item => {
            item.timeStamp = new Date(item.timeStamp);
            item.answers = JSON.parse(item.answers);
            item.respondAnswerId = JSON.parse(item.respondAnswerId);
            item.chosenAnswers = this.GetChosenAnswers(item);

            let chosenIds = [];
            let chosenText = [];
            item.chosenAnswers.forEach(a => {
              chosenIds.push(a.answerId);
              chosenText.push(a.answerText);
            });
            item.answerID = chosenIds.join(',');
            item.answerText = chosenText.join(',');
            item.questionID = item.questionID.replace('question_','');
          });
          session.activities = activity.sort((a, b) => (a.timeStamp - b.timeStamp));
          this.sessionViewed = session;
        });
        this.openModal(this.modalRef);
      }
    }
  }

  continue() {

    if (this.notAskAgainPlayMode)
      localStorage.setItem('keepPlayMode', '1');

    if (this.openModalRef)
      this.openModalRef.hide();

    this.replaySession.playMode = true;
    this.callback.emit({ type: 'or_surveytester_replayStarted', session: this.replaySession });
  }

  openModal(template: any) {
    this.openModalRef = this.modalService.show(template, { ignoreBackdropClick: true, keyboard: false, class: 'modal-sm st-modal st-modal-style survey-path' });
  }

  closeModal() {
    this.sessionViewed = null;
    this.openModalRef.hide()
  }

  showComments(session) {
    this.callback.emit({ type: 'or_surveytester_showComments', data: session });
  }

  changeView(individual = false, data = null) {
    this.individual = individual;

    if (individual) {
      this.individualSessions = data?.sessions;
      this.getSessionsSummary(this.individualSessions);
    } else {
      this.getTeamSummary();
    }
  }

  getTeamSummary() {
    this.summary.totalOpen = this.teamSessions.reduce((prev, elm) => prev + elm.totalOpen, 0);
    this.summary.totalResolved = this.teamSessions.reduce((prev, elm) => prev + elm.totalResolved, 0);
    this.summary.totalClosed = this.teamSessions.reduce((prev, elm) => prev + elm.totalClosed, 0);
    this.summary.totalChanges = this.teamSessions.reduce((prev, elm) => prev + elm.totalChanges, 0);
    this.summary.totalOther = this.teamSessions.reduce((prev, elm) => prev + elm.totalOther, 0);
    this.summary.totalErrors = this.teamSessions.reduce((prev, elm) => prev + elm.totalErrors, 0);
    this.generateCounter(this.teamSessions);
  }

  getSessionsSummary(sessions) {
    this.summary.totalOpen = sessions.reduce((prev, elm) => prev + elm.commentCreatedCount, 0);
    this.summary.totalResolved = sessions.reduce((prev, elm) => prev + elm.commentPendingCount, 0);
    this.summary.totalClosed = sessions.reduce((prev, elm) => prev + elm.commentApprovedCount + elm.commentCancelledCount, 0);
    this.summary.totalChanges = sessions.reduce((prev, elm) => prev + elm.totalClientChange, 0);
    this.summary.totalOther = sessions.reduce((prev, elm) => prev + elm.totalOtherChange, 0);
    this.summary.totalErrors = sessions.reduce((prev, elm) => prev + elm.totalProgramError, 0);
    this.generateCounter(sessions);
  }

  generateCounter(sessions) {
    sessions.map((item, index) => {
      item.counter = sessions.length - index;
      return item;
    });
  }

  getSessions() {
    this.service.getProjectSessions(this._surveyId).subscribe((data) => {
      this.teamSessions = this.groupByUser(data);
      this.teamSessions = this.teamSessions.map((item, index) => {
        item.totalOpen = item.sessions.reduce((prev, elm) => prev + elm.commentCreatedCount, 0);
        item.totalResolved = item.sessions.reduce((prev, elm) => prev + elm.commentPendingCount, 0);
        item.totalClosed = item.sessions.reduce((prev, elm) => prev + elm.commentApprovedCount + elm.commentCancelledCount, 0);
        item.totalChanges = item.sessions.reduce((prev, elm) => prev + elm.totalClientChange, 0);
        item.totalOther = item.sessions.reduce((prev, elm) => prev + elm.totalOtherChange, 0);
        item.totalErrors = item.sessions.reduce((prev, elm) => prev + elm.totalProgramError, 0);
        item.lastSessionDate = item.sessions.map(e => { return e.lastUpdated; }).sort().reverse()[0];
        return item;
      });

      this.getTeamSummary();
    },
      error => {
        this.alertify.error('There was an error getting sessions');
      });
  }

  groupByUser(array) {
    let result = [];
    array.reduce((prev, elm) => {
      var key = elm['updatedBy'];
      let exist = result.find(x => x.userId == key);
      if (!exist) {
        exist = { userId: key, user: elm['user'] };
        exist.sessions = [];
        result.push(exist);
      }
      exist.sessions.push(elm);
      return prev;
    }, {});
    return result;
  }

  GetChosenAnswers(item) {
    var chosenAnswers = []
    var listOfRepsonses = item.respondAnswerId
    if (listOfRepsonses) {
      listOfRepsonses.forEach(id => {
        item.answers.forEach(answer => {
          if (answer.answerId == id) {
            chosenAnswers.push({ answerId: id, answerText: answer.answerText })
          }
        });
      });
    }
    return chosenAnswers;
  }

  export() {
    var firstRow = [
      { value: 'Question', fontWeight: 'bold' },
      { value: 'Selected Answer', fontWeight: 'bold' }
    ];

    var finalData: any = [firstRow];

    for (var item of this.sessionViewed.activities) {
      var rowData = [
        { value: item.questionID.toLowerCase(), alignVertical: 'center' },
        { value: item.answerID + "-" + item.answerText, alignVertical: 'center' }
      ];

      finalData.push(rowData);
    }

    var columns = [{ width: 55 }, { width: 55 }];
    writeXlsxFile(finalData, { columns, sheet: `Survey Path ${this.sessionViewed.counter} Export`, stickyRowsCount: 1, fileName: `survey-path-${this.sessionViewed.counter}-export.xlsx` });
  }

}
