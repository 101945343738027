
<div class="decipher-label  mt-0 mb-0 pt-2 pb-2">Decipher</div>
<ul class="nav nav-pills flex-column mb-auto">
  <li id="edit" class="nav-item p-1 border-top cursor-pointer text-center text-uppercase">
    <i class="fa fa-edit fa-lg d-block p-2 mt-2"></i>
    <a class="nav-link text-secondary text-uppercase">Edit</a>
  </li>
  <li id="one-page" class="nav-item p-1 border-top cursor-pointer text-center text-uppercase">
    <i class="fa fa-file-alt fa-lg d-block p-2 mt-2"></i>
    <a class="nav-link text-secondary text-uppercase">One Page</a>
  </li>
  <li id="spell-check" class="nav-item p-1 border-top cursor-pointer text-center text-uppercase">
    <i class="fa fa-spell-check fa-lg d-block p-2 mt-2"></i>
    <a class="nav-link text-secondary text-uppercase">Spell Check</a>
  </li>
  <li id="changes" class="nav-item p-1 border-top border-bottom cursor-pointer text-center text-uppercase">
    <i class="fa fa-clock fa-lg d-block p-2 mt-2"></i>
    <a class="nav-link text-secondary text-uppercase">Changes</a>
  </li>
</ul>
