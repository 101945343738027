import { map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AuthService } from 'core';
import { DashboardService } from 'projects/core/src/services/dashboard.service';
import Highcharts from 'highcharts';

@Component({
  selector: 'app-widget-margin',
  templateUrl: './widget-margin.component.html',
  styleUrls: ['./widget-margin.component.scss']
})
export class WidgetMarginComponent implements OnInit  {

  @Input() widget;
  @Output() onremove = new EventEmitter();
  @Output() onupdate = new EventEmitter();
  @Output() onopenconfig = new EventEmitter();

  @ViewChild('dropdown', {static: false}) dropdown;

  highcharts = Highcharts;
  highchartOptions: Highcharts.Options;

  settings = {
    myProjects: false,
    timeSpan: null,
    dateRange: 'year to date',
    maxCount: null,
    projectCategories: [],
    projectTypes: []
  };


  // ngx charts
  dataEstimated = [];
  dataInvoiced = [];
  dataMargin = [];

  view: any[] = [200, 200];
  cardColor: string = '#f0f3f7';

  colorScheme1 = {
    domain: ['#7cb5ec']
  };
  colorScheme2 = {
    domain: ['#434348']
  };
  colorScheme3 = {
    domain: ['#90ed7d']
  };
  constructor(
    public http: HttpClient,
    public auth: AuthService,
    public dashboardService: DashboardService) {  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.widget.settings === null) this.widget.settings = this.settings
    this.getMarginChart();
  }

  getMarginChart() {
    forkJoin([this.dashboardService.getMarginByCategory(this.widget.settings), this.dashboardService.getMarginOverview(this.widget.settings)]).subscribe(data => {
   //   if (data == null || data.length <= 0) return;

      var chartDataByCategory = this.buildChartData(data[0]);
      this.drawHighChartsPie(chartDataByCategory);
      this.drawChartNumberCards(data[1][0]);
    },
    error => console.log(error));
  }

  buildChartData(data) {

    let series: [] = data.map(item => {
      item.totalValue = parseFloat(item.totalValue).toFixed(2);
      return {
        name: `${item.category} (${item.percent}%)`,
        y: item.percent,
        custom: {
          extraInformation: `TOTAL: $${item.totalValue}`
        }
      };
    });

    return series;
  }

  drawChartNumberCards(data) {
      this.dataEstimated = [
        {
          "name": "Estimated",
          "value": parseFloat(data.estimated).toFixed(2)
        }];
        this.dataInvoiced = [
          {
            "name": "Invoiced",
            "value": parseFloat(data.invoiced).toFixed(2)
          }];
          this.dataMargin = [
            {
              "name": "Margin",
              "value": parseFloat(data.margin).toFixed(2)
            }];

  }

  drawHighChartsPie(chartData) {
    this.highchartOptions = {
      credits: {
        enabled: false
      },
      chart: {
        height: '100%',
        margin: 20
    },
      title: { text: null },
      tooltip: {
        enabled: true,
        headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
        pointFormat: '<span style="color:{point.color}">\u25CF</span><b>{point.custom.extraInformation}</b><br/>',
        style: {
          fontSize: '16px',
          fontWeight: 'bold'
        }
      },
      plotOptions: {
        pie: {

          dataLabels: {
            enabled: true,
            distance: 0,
            style: {
              //color: 'white',
              fontSize: '15px'
            }
          },
          startAngle: -90,
          endAngle: 90,
          size: '90%'
        }
      },
      series: [{
        type: 'pie',
        name: 'TOTAL',
        innerSize: '60%',
        data: chartData
      }]
    };
  }

  onSelect(event) {
    console.log(event);
  }

  labelFormator = (item) => {
    return `<div class="h4 d-flex font-weight-light justify-content-center">${item.label}</div>`;
  }

  formator = (item) => {
    return (item.label == 'Margin') ? item.value + '%': '$' + item.value.toLocaleString('en-US');
  }

  remove() {
    this.onremove.emit();
  }

  updateWidget() {
    this.onupdate.emit();
  }

  configWidget() {
    this.onopenconfig.emit();
  }


  updateTitle(widgetTitle) {
    this.widget.title = widgetTitle;
    this.updateWidget();
  }

}
