import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AlertifyService, PartnerContactService, PartnerService } from 'core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ClosureService } from 'projects/core/src/services/closure.service';
import { SpinnerButtonComponent } from 'projects/core/src/components/spinner-button/spinner-button.component';
import { v4 as uuidv4 } from 'uuid';
import { of } from 'rxjs';

@Component({
  selector: 'app-project-close-vendor-email',
  templateUrl: './project-close-vendor-email.component.html',
  styleUrls: ['./project-close-vendor-email.component.scss']
})
export class ProjectCloseVendorEmailComponent implements OnInit {

  @Input() public set data(data) {
    this.processData(data);
  }

  project;
  vendors;
  loading = true;
  allAccepted = false;
  partnerContacts = {};
  addContact = {};
  newContact = {};
  addContactMode = false;
  vendorIdToGetList;
  selectedPartnerContacts: any = [];

  @ViewChild('spinner')
  spinner: SpinnerButtonComponent;

  @Output() saveevent = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    private closureService: ClosureService,
    private partnerService: PartnerService,
    private partnerContactService: PartnerContactService,
    private alertify: AlertifyService) { }

  ngOnInit(): void { }

  processData(data: any) {
    if (data == null || data === undefined) return;

    this.loading = false;
    this.project = data.project;
    this.vendors = data.vendors;

    if (this.vendors != null) {
      this.vendors = this.vendors.map(item => ({
        id: item.id,
        partnerId: item.partnerId,
        partnerContactId: null,
        vendorName: item.vendorName,
        externalPortalUrl: item.externalPortalUrl,
        externalId: item.externalId,
        sendEmailTo: null,
        isDeleted: item.isDeleted,
        latestClosurePartnerEmails: item.latestClosurePartnerEmails,
        latestDecisionStatus: item.latestDecisionStatus,
        action: (item.latestDecisionStatus !== 'NA' ? `Don't Send` : 'NA'),
        accepted: item.latestDecisionStatus === 'Accepted' || item.latestDecisionStatus === 'Auto-Accepted' || item.latestDecisionStatus === 'Confirmed'
      }));
    }
    this.vendors.forEach(vendor => {
      if (this.partnerContacts[vendor.partnerId] == null) {
        this.partnerContacts[vendor.partnerId] = [];
        var selected = []
        let pm = this.project.projectTeam.filter(e => e.projectRole == 'Vendor PM' && e.partnerId.toLowerCase() == vendor.partnerId.toLowerCase()).map(e => ({ name: e.displayName, id: e.userId, email: e.email }));
        if (pm.length > 0) {
          selected.push(pm[0]);
          this.selectedPartnerContacts[vendor.partnerId] = selected;
        }
          this.partnerContactService.GetPartnerContacts(vendor.partnerId).subscribe(contacts => {
            this.partnerContacts[vendor.partnerId] = contacts;
            const projectPartner = this.project.projectSegments[0].projectSurveyPartners
              .find(x => x.partnerId.toLowerCase() === vendor.partnerId.toLowerCase());
            if (projectPartner) {
              vendor.partnerContactId = projectPartner.partnerContactId;
              vendor.sendEmailTo = projectPartner.partnerContactEmail;
              vendor.latestClosurePartnerEmails.split(',').forEach(e => {
                if (contacts.find(x => x.email === e)) {
                  if (selected) {
                    const contact = contacts.find(x => x.email === e);
                    const sel = selected.find(x => x.email === e);
                    if (!sel) {
                      selected.push(contact);
                    }
                  }
                }
              });
              if (selected.length > 0) {
                this.selectedPartnerContacts[vendor.partnerId] = selected
              }
            }
          });       
      }
    });

    this.allAccepted = this.vendors.filter(x => x.accepted === false).length === 0;
  }

  addNewContact(guid) {
    this.newContact[guid] = {
      id: uuidv4(),
      mode: 'new',
      firstName: null,
      lastName: '',
      phone: '',
      email: '',
      partnerId: guid,
      address: {
        address1: '',
        address2: '',
        city: '',
        country: ''
      }, social: {
        facebook: '',
        linkedIn: '',
        twitter: '',
        skype: ''
      }
    };
    this.addContact[guid] = true;
    this.addContactMode = true;
  }

  cancelNewContact(guid) { this.addContact[guid] = this.addContactMode = false; }

  saveNewContact(guid) {

    const contact = this.newContact[guid];
    this.addContact[guid] = false;
    this.partnerContactService.AddPartnerContact(contact).subscribe(
      data => {
        this.partnerContactService.GetPartnerContacts(contact.partnerId).subscribe(contacts => {
          this.partnerContacts[contact.partnerId] = contacts;
          this.selectedPartnerContacts[contact.partnerId] = contacts.filter(e => e.id == contact.id);
          this.addContactMode = false;
        });
      },
      error => { this.alertify.error('Unable to add contact'); },
      () => { this.alertify.success('Contact added successfully'); }
    );
  }

  handleContact(newContactId, partnerId) {
    const vendor = this.vendors.find(x => x.partnerId == partnerId);
    if (vendor !== null && vendor !== undefined) {
      vendor.partnerContactId = newContactId;
      vendor.sendEmailTo = this.partnerContacts[partnerId].find(x => x.id === newContactId)?.email;
    }
  }

  sendEmails() {
    let OK = true;
    this.vendors.forEach(vendor => {
      if (vendor.isDeleted) return;
      if (vendor.action === 'Send' && vendor.sendEmailTo == null && this.selectedPartnerContacts[vendor.partnerId]?.length <= 0) {
        OK = false;
        this.alertify.warning('Please select a Contact for ' + vendor.vendorName);
      }
    });

    if (!OK) return;

    this.spinner.showSpinner = true;
    let arrMissing = this.vendors.filter(x => x.externalId === null);
    if (arrMissing.length > 0) {
      this.alertify.warning("Must add Project Number or add line item in Vendor Pricing tab and save changes.");
      return;
    }
    this.vendors.forEach(vendor => {
      vendor.sendEmailTo = '';
      this.selectedPartnerContacts[vendor.partnerId]?.forEach(selected => {
        vendor.sendEmailTo += vendor.sendEmailTo.length > 0
          ? ',' + selected.email
          : selected.email;
      });
    });

    this.closureService.sendVendorExternalLink(this.vendors.filter(x => !x.isDeleted)).subscribe(data => {
      if (data) this.alertify.success('Vendor Notifications have been saved.');
      else this.alertify.success('Project Updated. No email sent to Vendors.');
    }, error => {
      this.alertify.error(error);
    }, () => {
      this.saveevent.emit();
      this.bsModalRef.hide();
    });
  }

  checkVendorIsDeleted(vendors) { return vendors.filter(x => x.isDeleted === undefined || !x.isDeleted); }

  setVendorIdToGetList(vendorId) { this.vendorIdToGetList = vendorId; }
  getPartnerContactList = (filter) => {
    return of(this.partnerContacts[this.vendorIdToGetList]);
  }
  updatePartnerContacts(vendorId, event) {
    this.selectedPartnerContacts[vendorId] = event;
    this.vendors.forEach(vendor => {
      vendor.sendEmailTo = '';
      if (vendor.partnerId === vendorId && event.length > 0) {
        vendor.action = 'Send';
      }

      if (vendor.partnerId === vendorId && event.length === 0) {
        vendor.action = `Don't Send`;
      }
    });
  }
}
