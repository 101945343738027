<div class="h-100 p-3 mt-2" *ngIf="sessions">

  <div class="row d-flex align-items-center">
    <ng-container *ngIf="individual">
      <div class="col-3">
        <i class="fak fa-left-arrow fa-lg nav-primary cursor-pointer" (click)="changeView()"></i>
      </div>
      <div class="col">
        <h3 class="nav-primary my-0 ml-2">{{individualSessions[0]?.user.split(" ")[0]}}'s Sessions</h3>
      </div>
    </ng-container>
    <h3 class="w-100 nav-primary text-center my-0" *ngIf="!individual">Session Log</h3>
  </div>

  <div class="w-100 mt-4">

    <ng-template #tooltip><div [innerHtml]="tooltipSummary"></div></ng-template>
    <h4 class="w-100 nav-snow-white ml-2">
      <span class="font-weight-bold">Comment Summary</span>
      <span [tooltip]="tooltip" container="body" placement="bottom" class="ml-2"><i class="fak fa-info"></i></span>
    </h4>

    <hr class="st-border-secondary mx-2 mt-1 mb-2">
    <div class="row pl-3 nav-primary">
      <div class="col">
        <span>Opened: {{summary.totalOpen}}</span>
      </div>
      <div class="col">
        <span>Program errors: {{summary.totalErrors}}</span>
      </div>
    </div>
    <div class="row pl-3 nav-primary">
      <div class="col">
        <span>Resolved: {{summary.totalResolved}}</span>
      </div>
      <div class="col">
        <span>Client changes: {{summary.totalChanges}}</span>
      </div>
    </div>
    <div class="row pl-3 nav-primary">
      <div class="col">
        <span>Closed: {{summary.totalClosed}}</span>
      </div>
      <div class="col">
        <span>Other changes: {{summary.totalOther}}</span>
      </div>
    </div>
  </div>

  <hr class="st-border-secondary">
  <h4 class="w-100 nav-snow-white font-weight-bold" *ngIf="!individual">My Team's Sessions</h4>
  <h4 class="w-100 nav-snow-white font-weight-bold" *ngIf="individual">Individual Sessions</h4>

  <div class="scrollbar overflow-y mt-3">
  <ng-container *ngIf="!individual">
    <div class="card mb-2 mr-2" *ngFor="let item of teamSessions">
      <div class="card-body">
        <div class="d-flex align-items-center">

          <h5 class="nav-secondary w-100 font-weight-bold">{{item.user || "Name"}}</h5>

          <div class="btn-group d-block text-right" dropdown container="body">
            <span dropdownToggle type="button">
              <i class="fas fa-ellipsis-v or-secondary opacity-70 ml-1 fa-lg"></i>
            </span>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
              <li role="menuitem" class="p-2 cursor-pointer">
                <div class="btn px-3 py-1" (click)="changeView(true, item)">
                  <i class="fak fa-sessions fa-md grey4"></i>
                  <span class="mx-2 align-self-center">View all sessions</span>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <h6 class="mb-2 text-muted">Last session {{item.lastSessionDate | timeAgo}}</h6>
        <div class="w-100 mt-3 grey4">
          <h5 class="w-100">Comment Summary</h5>
          <div class="col">
            <div class="row border-bottom text-center">
              <div class="col border-right font-weight-bold py-2">
                <span>Opened</span>
              </div>
              <div class="col border-right font-weight-bold py-2">
                <span>Resolved</span>
              </div>
              <div class="col font-weight-bold py-2">
                <span>Closed</span>
              </div>
            </div>
            <div class="row text-center grey4">
              <div class="col border-right">
                <span>{{item?.totalOpen}}</span>
              </div>
              <div class="col border-right">
                <span>{{item?.totalResolved}}</span>
              </div>
              <div class="col">
                <span>{{item?.totalClosed}}</span>
              </div>
            </div>
          </div>
          <div class="col mt-3 text-right">
            <a class="nav-link nav-accent-blue font-weight-bold" (click)="changeView(true, item)">View more</a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="individual">
    <div class="card mb-2 mr-2" *ngFor="let session of individualSessions">
      <div class="card-body">
        <div class="d-flex align-items-center">

          <h5 class="nav-secondary w-100 font-weight-bold">Session {{session.counter}}</h5>

          <div class="play-mode d-flex align-items-center" *ngIf="session?.playMode">
            <h6 class="badge badge-pill my-0 px-2 font-weight-normal mx-2">
              <i class="far fa-circle-play mx-1"></i>In play session mode
            </h6>
          </div>

          <div class="btn-group d-block text-right" dropdown container="body">
            <span dropdownToggle type="button">
              <i class="fas fa-ellipsis-v or-secondary opacity-70 ml-1 fa-lg"></i>
            </span>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
              <li role="menuitem" class="p-2 cursor-pointer" *ngIf="this.client !== 'Other'">
                <div class="btn px-3 py-1" (click)="handleAction(session, 'or_surveytester_replayStarted')" data-commentid=0>
                  <i class="fak fa-play fa-md grey4"></i>
                  <span class="mx-2 align-self-center">Play survey session</span>
                </div>
              </li>
              <li role="menuitem" class="p-2 cursor-pointer">
                <div class="btn px-3 py-1" (click)="handleAction(session, 'or_surveytester_view')" data-commentid=0>
                  <i class="fak fa-survey fa-lg grey4"></i>
                  <span class="mx-2 align-self-center">View survey path</span>
                </div>
              </li>
              <li role="menuitem" class="p-2 cursor-pointer">
                <div class="btn px-3 py-1" (click)="showComments(session)">
                  <i class="fak fa-messages fa-md grey4"></i>
                  <span class="mx-2 align-self-center">View session comments</span>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <h6 class="mb-2 text-muted">{{session.lastUpdated | timeAgo}}</h6>
        <div class="w-100 mt-3 grey4">
          <h5 class="w-100">Comment Summary</h5>
          <div class="col">
            <div class="row border-bottom text-center">
              <div class="col border-right font-weight-bold py-2">
                <span>Opened</span>
              </div>
              <div class="col border-right font-weight-bold py-2">
                <span>Resolved</span>
              </div>
              <div class="col font-weight-bold py-2">
                <span>Closed</span>
              </div>
            </div>
            <div class="row text-center grey4">
              <div class="col border-right">
                <span>{{session?.commentCreatedCount}}</span>
              </div>
              <div class="col border-right">
                <span>{{session?.commentPendingCount}}</span>
              </div>
              <div class="col">
                <span>{{session?.commentApprovedCount + session?.commentCancelledCount}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  </div>
</div>

<ng-template #modalSurveyPath>
  <div class="modal-header d-flex align-items-center justify-content-center">
    <div class="w-100">
      <div class="d-flex justify-content-end align-content-center">
        <i class="fas fa-close grey4" (click)="closeModal()"></i>
      </div>
    </div>
  </div>
  <div class="modal-body pt-0">
    <h3 class="font-weight-bold nav-secondary text-center">Session {{sessionViewed?.counter}} Survey Path</h3>
    <ng-container *ngIf="sessionViewed?.activities.length > 0">
      <div class="mt-4 scrollbar overflow-y" style="height: 550px">
        <div class="table-responsive bg-white">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="border-top-0 text-center align-middle border-right"><h5 class="grey5 font-weight-bold mb-0">Question</h5></th>
                <th scope="col" class="border-top-0 text-center align-middle"><h5 class="grey5 font-weight-bold mb-0">Selected Answer</h5></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of sessionViewed?.activities">
                <td class="border-right text-center align-middle"><h5 class="grey5 mb-0">{{item.questionID | uppercase}}</h5></td>
                <td class="align-middle"><h5 class="grey5 mb-0">{{item.answerID}} - {{item.answerText}}</h5></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="sessionViewed?.activities.length == 0">
      <div class="col text-center mt-4">
        <h5>No activities for this session.</h5>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer">
    <div class="row d-flex w-100 align-items-center">
      <div class="col d-flex align-items-end justify-content-end">
        <button type="button" class="btn btn-sm nav-btn-accent" *ngIf="sessionViewed?.activities.length > 0" (click)="export()">
          Export <i class="fak fa-export ml-1"></i>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modalConfirmPlayMode>
  <div class="modal-header d-flex align-items-center justify-content-center">
    <div class="w-100">
      <div class="d-flex justify-content-end align-content-center">
        <i class="fas fa-close grey4" (click)="closeModal()"></i>
      </div>
    </div>
  </div>
  <div class="modal-body pt-0">
    <h3 class="font-weight-bold nav-secondary text-center">You are now in play mode.</h3>
    <h5 class="grey5 text-center d-block my-3">
      <i class="fak fa-info grey4 fa-md"></i>
      Play {{replaySession?.user}}'s survey session by clicking the continue button.
    </h5>
  </div>
  <div class="modal-footer">
    <div class="row d-flex w-100 align-items-center">
      <div class="col-5 px-0 or-checkbox d-flex justify-content-start">
        <input [(ngModel)]="notAskAgainPlayMode" type="checkbox" name="notAskAgainPlayMode" id="notAskAgainPlayMode">
        <label for="notAskAgainPlayMode"></label>
        <small class="pl-4 grey4">Don't ask again</small>
      </div>
      <div class="col text-right px-0">
        <button class="btn btn-sm st-btn-primary mx-1 shadow-none" (click)="closeModal()">Cancel</button>
        <button class="btn btn-sm nav-btn-accent shadow-none" (click)="continue()">Continue</button>
      </div>
    </div>
  </div>
</ng-template>
