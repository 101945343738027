import { Component, TemplateRef, ViewChild, Input, Output, EventEmitter, OnChanges, Renderer2, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { ScheduleService, AlertifyService, UtilsService, AuthService, ProjectTask, UserService, ProjectService, ProjectDataService, ScheduleTemplateTask, ScheduleTemplate } from 'core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as Highcharts from "highcharts/highcharts-gantt";

// import theme from 'highcharts/themes/brand-light';
// theme(Highcharts);
import HC_dragg from 'highcharts/modules/draggable-points';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { DndDropEvent } from 'ngx-drag-drop';
import { of, map, timeout, max } from 'rxjs';
import { eventTupleToStore } from '@fullcalendar/core/internal';
import { DatePipe } from '@angular/common';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';

HC_dragg(Highcharts);
interface Custom extends Highcharts.PointOptionsObject {
  description: string;
  owner: string;
  completed: number;
  start: number;
  end: number;
  milestone: boolean;
  type: string,
  color: string,
  stageDescription: string
  showInlineTask: boolean
}

export interface filterCol {
  name: string;
  value: string;
}

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  providers: [DatePipe]
})
export class ScheduleComponent implements OnChanges {
  @Input() refresh: number = 0; // used to force component refresh
  @Input() project: any;
  @Input() templateMode: string = null; // edit, view
  @Input() bidNumber: any;
  @Input() templateForPreview: any;
  @Output() callback = new EventEmitter();
  @Output() submitTemplate = new EventEmitter();
  @Output() cancelTemplate = new EventEmitter();

  projectSchedule;
  allTasks: any[] = [];
  newTask: any = {};
  editTaskAssignees: any = {};
  assigneesAcrossTasks: any = {};
  selectedStage;
  chkSellectAll: Boolean = false;
  loading = true;
  loadingTemplates = false;
  loadingNewTemplate = false;
  editMode = false;
  addFieldContainer = 'body';
  startDateValid: boolean;
  dueDateValid: boolean;
  selectedBulkDateType = 1;
  shiftdays = 1;
  lastStartDate: Date;
  lastDueDate: Date;
  actualUploadedFileName: string;
  s3buckPath: string;
  users = [];
  usersForDropdown = [];
  showFilter = false;
  userFilter = '';
  calendarData = [];
  currentView = 'list';
  reAssignedToId: string;
  tasksLimited = false;
  fifteenTasks = [];
  expandCollapseLabel = '';
  inlineAddMode = false;
  includedOnRelease = false;
  isSection = false;

  assigneeFilterText = '';
  filteredAssignees = [];

  gridTemplateColumns: string;
  scheduleColumns:any = {
    colStatus: false,
    colTaskLength: false,
    colReoccurrence: false
  };

  showAssigneePicker = false;
  filter = {
    assignedToId: [],
    projectdId: null,
    status: '',
    textFilter: '',
    dueDateBegin: null,
    dueDateEnd: null,
    startDateBegin: null,
    startDateEnd: null,
    completeDateBegin: null,
    completeDateEnd: null,
    mode: '',
    orderBy: '',
    orderDirection: ''
  };

  filters: any = [];
  showInlineTask: boolean;
  chartData = [];
  highcharts: typeof Highcharts = Highcharts;
  highchartOptions: Highcharts.Options;
  newTaskUser: any[] = [];
  uploadedFile: string;
  today;
  newTaskAssignedToId = null;
  newTaskAssignedToDropdown = null;
  asyncSearch: string = '';
  matches = [];
  selectedMatch = null;
  _newProject: any = {};
  startDateEnable = true;
  sidebarOpen = false;
  notAskAgainScheduleColumns = false;

  // template
  canSaveTemplate = false;
  newTemplate: ScheduleTemplate = null;
  projectScheduleCopyForTemplate = null;
  newTemplateName: string = '';
  templateViewFilter: string = '';
  scheduleTemplates: ScheduleTemplate[] = [];
  filteredScheduleTemplates: ScheduleTemplate[] = [];
  templateScheduleSelected = null;
  templateStep;
  assignAllToMe = false;
  overrideExisting = true;
  myMath;
  reoccurrenceChange = false;

  //reoccurrence
  frequencies = []
  frequencyTypes = ['Daily', 'Weekly']
  taskToEditReoccurrence = null;
  currentReoccurrenceCopy = null;
  onlyEndDateChanged = false;

  @ViewChild('modalUser')
  modalRef: TemplateRef<any>;
  openModalRef: any;

  @ViewChild('modalTask')
  modalRefTask: TemplateRef<any>;
  openModalRefTask: any;

  @ViewChild('modalEditAssignees')
  modalRefEditAssignees: TemplateRef<any>;
  openModalRefEditAssignees: any;

  @ViewChild('modalAssigneeAcrossTasks')
  modalRefAssigneeAcrossTasks: TemplateRef<any>;
  openModalRefAssigneeAcrossTasks: any;

  @ViewChild('modalBulkDateAssign')
  modalRefmodalBulkDateAssign: TemplateRef<any>;
  openModalRefmodalBulkDateAssign: any;

  @ViewChild('modalBulkTaskDelete')
  modalRefmodalBulkTaskDelete: TemplateRef<any>;
  openModalRefmodalBulkTaskDelete: any;

  @ViewChild('modalImportSchedule')
  modalRefmodalImportSchedule: TemplateRef<any>;
  openModalRefmodalImportSchedule: any;

  @ViewChild('modalConfirmStageDeactivate')
  modalRefConfirmStageDeactivate: TemplateRef<any>;
  openModalConfirmStageDeactivate: any;

  @ViewChild('modalCloneSchedule')
  modalRefmodalCloneSchedule: TemplateRef<any>;
  openModalRefmodalCloneSchedule: any;

  @ViewChild('modalViewTemplates')
  modalRefViewTemplates: TemplateRef<any>;
  openModalRefViewTemplates: any;

  @ViewChild('modalReoccurrenceSettings')
  modalRefReoccurrenceSettings: TemplateRef<any>;
  openModalRefReoccurrenceSettings: any;

  @ViewChild('modalReoccurringTaskChangeConfirm')
  modalRefReoccurringTaskChangeConfirm: TemplateRef<any>;
  openModalRefReoccurringTaskChangeConfirm: any;
  reoccurringTaskChangeData: any;

  bsModalRef: BsModalRef;

  @ViewChild('assigneeSearch')
  assigneeSearch: ElementRef;

  // @ViewChild('modalUnsavedChanges')
  // modalRefUnsavedChanges: TemplateRef<any>;
  // openModalRefUnsavedChanges: any;

  @ViewChild('modalConfirmDelete')
  modalRefConfirmDelete: TemplateRef<any>;
  openModalRefConfirmDelete: any;
  modalConfirmDeleteData: any;

  @ViewChild('newTaskName', { static: false }) newtaskname: ElementRef;

  bulkDateTypes = [{
    "name": "Set Start Date",
    "id": "1",
  }, {
    "name": "Set End Date",
    "id": "2",
  }, {
    "name": "Shift Start Date",
    "id": "3",
  }, {
    "name": "Shift End Date",
    "id": "4",
  }, {
    "name": "Shift Both Dates",
    "id": "5",
  }]

  constructor(
    private datepipe: DatePipe,
    private scheduleService: ScheduleService,
    private projectService: ProjectService,
    private alertify: AlertifyService,
    public utils: UtilsService,
    public userService: UserService,
    public auth: AuthService,
    private utilService: UtilsService,
    private modalService: BsModalService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private projectDataService: ProjectDataService,
    private elRef: ElementRef,
    private router: Router,
    private cdr: ChangeDetectorRef) {
    this.today = new Date();
  }

  ngOnInit() {
    this.myMath = Math;
    if (this.templateMode === 'view' && this.templateForPreview) {
      this.projectSchedule = this.templateForPreview;
      this.projectSchedule.forEach(stage => {
        stage.isOpen = true;
      });
      this.loading = false;
    }
    else {
      if (this.templateMode === 'edit') {
        this.editMode = true; 
        this.loadingNewTemplate = true;
        this.addFieldContainer = null;
        this.LoadData(false);
      }
      this.currentView = 'list';
      this.SetFilter();
      this.userService.GetUsers().subscribe(data => {
        const me = data.find(e => e.id == this.auth.getUser().id);
        var users = data.filter(e => e.id != this.auth.getUser().id);
        this.users = users;
        //me.lastName += " (me)";
        this.users.unshift(me);
        this.users.filter(e => this.filter.assignedToId.includes(e.id)).map(e => e.filter = true);
        this.fetchAssigneeList();
      })
      if (this.project == null) {
        this.ApplyFilter(false);
      } else {
        this.s3buckPath = "task/" + this.project.id;
      }

      //subscribe to sidebar mode
      this.projectDataService.getSidebarMode.subscribe(data => {
        data = data || 'reduced';
        this.sidebarOpen = (data === 'reduced' ? false : true);
      });
    }
    this.setScheduleColumns();
  }

  ngOnChanges() {
    if (this.templateMode !== 'view') {
      this.LoadData(false);
    }
  }


  canDeactivate(showDialog: boolean): boolean {
    if (this.inlineAddMode) {  //Check if adding/editing mode
      if (showDialog)
        //this.openModalRefUnsavedChanges = this.modalService.show(this.modalRefUnsavedChanges, { class: 'modal-dialog-centered' });
      return false;
    }
    return true;
  }

  addField(field) {
    if (field === 'status') {
      this.scheduleColumns.colStatus = true;
      localStorage.setItem(`${this.project?.id}_colStatus`, '1');
    }
    if (field === 'task-length') {
      this.scheduleColumns.colTaskLength = true;
      localStorage.setItem(`${this.project?.id}_colTaskLength`, '1');
    }
    if (field === 'reoccurrence') {
      this.scheduleColumns.colReoccurrence = true;
      localStorage.setItem(`${this.project?.id}_colReoccurrence`, '1');
    }
    this.setScheduleColumns();
  }

  removeField(field) {
    if (field === 'status') {
      this.scheduleColumns.colStatus = false;
      localStorage.removeItem(`${this.project?.id}_colStatus`);
    }
    if (field === 'task-length') {
      this.scheduleColumns.colTaskLength = false;
      localStorage.removeItem(`${this.project?.id}_colTaskLength`);
    }
    if (field === 'reoccurrence') {
      this.scheduleColumns.colReoccurrence = false;
      localStorage.removeItem(`${this.project?.id}_colReoccurrence`);
    }
    this.setScheduleColumns();
  }

  setScheduleColumns() {
    let dynamicColumns = '';

    this.scheduleColumns.colReoccurrence = localStorage.getItem(`${this.project?.id}_colReoccurrence`) !== null;
    if (!this.templateMode) {
      dynamicColumns = 'auto 148px';

      this.scheduleColumns.colStatus = localStorage.getItem(`${this.project?.id}_colStatus`) !== null;
      this.scheduleColumns.colTaskLength = localStorage.getItem(`${this.project?.id}_colTaskLength`) !== null;

      if (this.scheduleColumns.colStatus) dynamicColumns += ' 148px';
      if (this.scheduleColumns.colTaskLength) dynamicColumns += ' 148px';
      if (this.scheduleColumns.colReoccurrence) dynamicColumns += ' 148px';
      dynamicColumns += ' 148px 148px 38px';
    }
    else if (this.templateMode === 'edit') {
      //dynamicColumns += ' 372px 187px 105px 152px'; // with reocurring and add field button
      dynamicColumns += ' 372px 187px 105px 190px'; // with reocurring and no add field button
      //dynamicColumns += '523px 187px 105px 38px';
    }

    this.gridTemplateColumns = `${dynamicColumns}`;
  }

  SetFilter() {
    //1. First we read from URL
    this.filter.mode = this.route.snapshot.queryParamMap.get('mode') ?? '';
    this.filter.dueDateBegin = this.route.snapshot.queryParamMap.get('dueDateBegin');
    this.filter.dueDateEnd = this.route.snapshot.queryParamMap.get('dueDateEnd');
    this.filter.startDateBegin = this.route.snapshot.queryParamMap.get('startDateBegin');
    this.filter.startDateEnd = this.route.snapshot.queryParamMap.get('startDateEnd')
    this.filter.completeDateBegin = this.route.snapshot.queryParamMap.get('completeDateBegin')
    this.filter.completeDateEnd = this.route.snapshot.queryParamMap.get('completeDateEnd')
    this.filter.status = this.route.snapshot.queryParamMap.get('status') ?? '';
    let assignedTo = this.route.snapshot.queryParamMap.get('assignedTo');

    if (assignedTo == null && this.filter.mode == '' && this.filter.dueDateBegin == null && this.filter.dueDateEnd == null && this.filter.startDateBegin == null && this.filter.startDateEnd == null && this.filter.completeDateBegin == null && this.filter.completeDateEnd == null && this.filter.status == '') {
      //2. If nothing in URL we read from local storage.
      const lsFilter = localStorage.getItem((this.project == null && this.bidNumber == null) ? 'scheduleFilter' : (this.project != null ? 'projectScheduleFilter' : 'familyScheduleFilter'));
      if (lsFilter != null) {
        this.filter = JSON.parse(lsFilter);
      } else if (this.project == null && this.bidNumber == null) {
        //3. Otherwise we set defaults
        if (this.filter.status == '') this.filter.status = 'Pending';
        if (this.filter.assignedToId.length == 0) this.filter.assignedToId = [this.auth.getUser().id];
      }

    } else {
      //handle filters passed as parameters
      if (this.filter.dueDateBegin != null) this.filter.dueDateBegin = new Date(this.filter.dueDateBegin);
      if (this.filter.dueDateEnd != null) this.filter.dueDateEnd = new Date(this.filter.dueDateEnd);
      if (this.filter.startDateBegin != null) this.filter.startDateBegin = new Date(this.filter.startDateBegin);
      if (this.filter.startDateEnd != null) this.filter.startDateEnd = new Date(this.filter.startDateEnd);
      if (this.filter.completeDateBegin != null) this.filter.completeDateBegin = new Date(this.filter.completeDateBegin);
      if (this.filter.completeDateEnd != null) this.filter.completeDateEnd = new Date(this.filter.completeDateEnd);
      if (assignedTo != null) {
        this.filter.assignedToId = assignedTo.split(',');
      }
    }

    if (this.filter.mode) this.ApplyQuickFilter(null, this.filter.mode);
  }

  ApplyQuickFilter(dropdown, mode) {
    this.ClearAllFilters(dropdown);

    this.filter.mode = mode;
    if (this.filter.mode === 'overdue') {
      let yesterday = new Date(this.today); // create a new Date object with the same value as today
      yesterday.setDate(this.today.getDate() - 1);
      this.filter.status = 'Pending';
      this.filter.assignedToId = [this.auth.getUser().id];
      this.filter.dueDateEnd = yesterday; //this.formatTaskDate(yesterday);
    } else if (this.filter.mode === 'todo') {
      this.filter.status = 'Pending';
      this.filter.assignedToId = [this.auth.getUser().id];
      this.filter.dueDateBegin = this.today;
      this.filter.dueDateEnd = this.today;
    } else if (this.filter.mode === 'upcoming') {
      this.filter.status = 'Pending';
      let tomorrow = new Date(this.today); // create a new Date object with the same value as today
      tomorrow.setDate(this.today.getDate() + 1);
      this.filter.assignedToId = [this.auth.getUser().id];
      this.filter.startDateBegin = tomorrow;
    }

    this.ApplyFilter(false);
  }

  getUsersForDropdown(filter) {
    // this.usersForDropdown = [...users];
    var users = [...this.users];

    this.usersForDropdown = users.filter((item) => {
      return (item.name.toLowerCase().includes(filter.toLowerCase()));
    });
    this.usersForDropdown.sort((a, b) => a.name.localeCompare(b.name));
  }

  ExpandCollapseAll() {
    this.projectSchedule?.forEach(stage => {
      stage.isOpen = this.expandCollapseLabel == 'Collapse all' ? false : true ;
    });
    this.expandCollapseLabel = this.projectSchedule.every(e => e.isOpen) ? 'Collapse all' : 'Expand all';
  }

  ExpandCollapseStage(stage) {
    stage.isOpen = !stage.isOpen;
    if (!this.templateMode) this.expandCollapseLabel = this.projectSchedule.every(e => e.isOpen) ? 'Collapse all' : 'Expand all';
  }

  ApplyFilter(updateLocalStorage) {

    this.filters = [];
    if (this.filter.mode == 'overdue') {
      if (this.filter.status != '') {
        this.filters.push({
          label: 'My Overdue Tasks',
          type: 'mode'
        });
      }
    } else if (this.filter.mode == 'todo') {
      if (this.filter.status != '') {
        this.filters.push({
          label: 'Todays Tasks',
          type: 'mode'
        });
      }
    } else if (this.filter.mode == 'upcoming') {
      if (this.filter.status != '') {
        this.filters.push({
          label: 'My Upcoming',
          type: 'mode'
        });
      }
    } else {
      if (this.filter.status != '') {
        this.filters.push({
          label: this.filter.status,
          type: 'status'
        });
      }
      if (this.filter.assignedToId.length > 0) {
        this.filters.push({
          label: (this.filter.assignedToId.length == 1 && this.filter.assignedToId[0] == this.auth.getUser().id) ? 'Assigned to Me' : 'Assigned Users',
          type: 'assignedTo'
        });
      }
      if (this.filter.startDateBegin != null || this.filter.startDateEnd != null) {
        this.filters.push({
          label: 'Start Date',
          type: 'startDate'
        });
      }
      if (this.filter.dueDateBegin != null || this.filter.dueDateEnd != null) {
        this.filters.push({
          label: 'Due Date',
          type: 'dueDate'
        });
      }
      if (this.filter.completeDateBegin != null || this.filter.completeDateEnd != null) {
        this.filters.push({
          label: 'Complete Date',
          type: 'completeDate'
        });
      }
    }

    this.users.map(e => e.filter = this.filter.assignedToId.includes(e.id));
    if (this.project == null && this.bidNumber == null) {
      this.LoadData(false);
    } else {
      //Filtering in Project and Family is done client side.
      if (this.project != null) {
        const textFilter = this.filter.textFilter.toLowerCase();
        this.projectSchedule.forEach(stage => {
          // filter tasks
          stage.projectTasks.forEach(task => {
            let show = true;
            if (textFilter != '' && task.taskName.toLowerCase().indexOf(textFilter) < 0) show = false;
            else if (this.filter.status != '' && task.status != this.filter.status) show = false;
            else if (this.filter.assignedToId.length > 0 && (task.assignedToUsers.length == 0 || !task.assignedToUsers.some(x => x.assignedToId != null && this.filter.assignedToId.includes(x.assignedToId)))) show = false;
            else if (this.filter.dueDateBegin != null && (task.dueDate == null || this.compareDates(task.dueDate_dt, this.filter.dueDateBegin) == 'before')) show = false;
            else if (this.filter.dueDateEnd != null && (task.dueDate == null || this.compareDates(task.dueDate_dt, this.filter.dueDateEnd) == 'after')) show = false;
            else if (this.filter.startDateBegin != null && (task.startDate == null || this.compareDates(task.startDate_dt, this.filter.startDateBegin) == 'before')) show = false;
            else if (this.filter.startDateEnd != null && (task.startDate == null || this.compareDates(task.startDate_dt, this.filter.startDateEnd) == 'after')) show = false;
            else if (this.filter.completeDateBegin != null && (task.completeDate == null || this.compareDates(task.completeDate_dt, this.filter.completeDateBegin) == 'before')) show = false;
            else if (this.filter.completeDateEnd != null && (task.completeDate == null || this.compareDates(task.completeDate_dt, this.filter.completeDateEnd) == 'after')) show = false;
            task.showTask = show
          });

          // filter stages
          let show = true;
          if (textFilter !== '') {
            const stageNameMatches = stage.stageName.toLowerCase().indexOf(textFilter) >= 0;
            const tasksMatch = stage.projectTasks.some(task => { return task.taskName.toLowerCase().indexOf(textFilter) >= 0; });  // Check if any task matches the filter

            if (stageNameMatches || tasksMatch) {
              show = true;
              stage.isOpen = tasksMatch ? true : false;

              if (stageNameMatches) {
                stage.projectTasks.forEach(task => task.showTask = true); // If stage name matches, ensure all tasks are shown
              }

            } else {
              show = false;
              stage.isOpen = true;  // Expand if neither matches
            }
          }
          stage.showStage = show;

        });
      }
      else if (this.bidNumber != null) {
        const textFilter = this.filter.textFilter.toLowerCase();
        this.allTasks.forEach(task => {
          let show = true;
          if (textFilter != '' && task.taskName.toLowerCase().indexOf(textFilter) < 0) show = false;
          else if (this.filter.status != '' && task.status != this.filter.status) show = false;
          else if (this.filter.assignedToId.length > 0 && (task.assignedToUsers.length == 0 || !task.assignedToUsers.some(x => x.assignedToId != null && this.filter.assignedToId.includes(x.assignedToId)))) show = false;
          else if (this.filter.dueDateBegin != null && (task.dueDate == null || this.compareDates(task.dueDate_dt, this.filter.dueDateBegin) == 'before')) show = false;
          else if (this.filter.dueDateEnd != null && (task.dueDate == null || this.compareDates(task.dueDate_dt, this.filter.dueDateEnd) == 'after')) show = false;
          else if (this.filter.startDateBegin != null && (task.startDate == null || this.compareDates(task.startDate_dt, this.filter.startDateBegin) == 'before')) show = false;
          else if (this.filter.startDateEnd != null && (task.startDate == null || this.compareDates(task.startDate_dt, this.filter.startDateEnd) == 'after')) show = false;
          else if (this.filter.completeDateBegin != null && (task.completeDate == null || this.compareDates(task.completeDate_dt, this.filter.completeDateBegin) == 'before')) show = false;
          else if (this.filter.completeDateEnd != null && (task.completeDate == null || this.compareDates(task.completeDate_dt, this.filter.completeDateEnd) == 'after')) show = false;
          task.showTask = show
        });

        this.sort(this.allTasks, this.filter.orderBy, this.filter.orderDirection)

        var i = 0;
        var taskCount = 0;
        this.fifteenTasks = this.allTasks.filter((ele) => {
          if (ele.showTask) taskCount++;
          if (i >= 0 && i < 15 && ele.showTask) {
            i++;
            return true;
          }
          return false;
        })
        this.callback.emit(true)
        if (this.bidNumber) this.callback.emit(taskCount);
      };
    }

    if (updateLocalStorage) {
      this.filter.mode = ''; //clear the mode if any filter change
      const key = (this.project == null && this.bidNumber == null) ? 'scheduleFilter' : ((this.project != null) ? 'projectScheduleFilter' : 'familyScheduleFilter');
      if (this.filters.length == 0) localStorage.removeItem(key);
      else localStorage.setItem(key, JSON.stringify(this.filter));
    }
    this.drawGanttChart();
    this.calendarview();
  }


  // only used for project roll up task list
  sort(array, type, direction) {
    array.sort((a, b) => {
      var A;
      var B;
      if (type == 'status') {
        A = a.status;
        B = b.status;
      }

      if (type == 'assignedTo') {
        A = a.assignedToName;
        B = b.assignedToName;
      }
      else if (type == 'assignedTo') {
        A = a.assignedToName;
        B = b.assignedToName;
      }
      else if (type == 'stage') {
        A = a.stageName;
        B = b.stageName;
      }
      else if (type == 'client') {
        A = a.clientName;
        B = b.clientName;
      }
      else if (type == 'projectName') {
        A = a.projectName;
        B = b.projectName;
      }
      else if (type == 'projectCode') {
        A = a.projectCode;
        B = b.projectCode;
      }

      let comparison = 0;
      if (direction === 'ASC') {
        if (A > B) {
          comparison = 1;
        } else if (A < B) {
          comparison = -1;
        }
        return comparison;
      }
      else {
        if (A < B) {
          comparison = 1;
        } else if (A > B) {
          comparison = -1;
        }
        return comparison;
      }

    });
    return array;
  }

  AssignTaskAssignee(task, assignee, evt, type = null) {

    task.assignedToUsers.forEach(user => {
      user.showPicker = false;
      user.showDropdown = false;
    });

    if (type === 'inline' || type === 'new-inline') {
      if (evt?.id) {
        task.inlineEdit = (type === 'inline');

        let exist = task.assignedToUsers.find(x => x.assignedToId === evt.id);
        if (!exist) {
          let newAssignee = task.assignedToUsers.find(u => u.assignedToId === null);
          if (newAssignee) {
            newAssignee.assignedToId = evt.id;
            newAssignee.assignedToName = evt.name;
          }  else {
            assignee.assignedToId = evt.id;
            assignee.assignedToName = evt.name;
          }
          task.addingAssignee = false;

          // only save assignees if inline edit mode
          if (type === 'inline') {
            if (task.reoccurrenceSettings) {
              this.openReoccurringTaskChangeConfirmModal(task, 'inline', 'assignees', task.assignedToUsers.filter(u => u.id), null)
            }
            else this.SaveProjectAssignees(task, task.assignedToUsers, false);
            
          }
        } else {
          assignee.showPicker = true;
          //assignee.showDropdown = true;
          this.alertify.error("Assignee already added to this task");
        }
      }
    }
    else if (evt?.length > 0) {
      task.assignedToId = evt[0].id;
      task.assignedToDisplayName = evt[0].firstName + ' ' + evt[0].lastName;
      this.SaveTask(task, false, false);
    }
  }

  LoadData(emitChangeEvent) {

    if (emitChangeEvent) this.callback.emit(true);

    if (this.project != null) {
      this.scheduleService.GetProjectSchedule(this.project.id).subscribe(data => {
        this.SetupData(data);
        this.ApplyFilter(false);
        this.loading = false;
        this.loadingNewTemplate = false;
      }, error => {
        this.alertify.error('Unable to get schedule');
      });
    }
    else if (this.bidNumber != null) {
      this.scheduleService.GetFamilySchedule(this.bidNumber).subscribe(data => {
        this.tasksLimited = true;
        this.allTasks = [];
        data.forEach(task => {
          if (task.startDate != null) {
            task.startDate_dt = new Date(task.startDate);
          }
          if (task.dueDate != null) {
            task.dueDate_dt = new Date(task.dueDate);
          }
          this.allTasks.push(task);
        });

        // this.RemoveFilters({type: 'mode'});
        this.ApplyFilter(false);

      }, error => {
        this.alertify.error('Unable to get schedule');
      });
    }
    else this.GetAllTasks();

  }

  SetupData(data) {
    this.allTasks = [];
    let handledRecurringIds = {};
    let recurringTaskList = [];
    data.forEach(stage => {
      if (!this.templateMode) {
        var completeTasks = stage.projectTasks.filter(t => t.status == ScheduleService.STATUS.COMPLETE)
        stage.completeCount = completeTasks?.length;
        stage.isComplete = (stage.completeCount == stage.projectTasks.length && stage.completeCount > 0);
        stage.isOpen = (stage.isOpen == null) ? !stage.isComplete : stage.isOpen;
      }
      else {
        stage.isOpen = true;   
      }

      stage.isSelected = false;
      stage.projectTasks.forEach(task => {
        if (task.startDate != null) {
          task.startDate_dt = new Date(task.startDate);
        }
        if (task.dueDate != null) {
          task.dueDate_dt = new Date(task.dueDate);
        }
        if (stage.dueDate_dt == null || stage.dueDate_dt < task.dueDate_dt) {
          stage.dueDate_dt = task.dueDate_dt;
        }

        task.showReoccurrenceSettings = false;

        if(task.reoccurrenceSettings?.id && handledRecurringIds[task.reoccurrenceSettings.id] == null){
          //there was an issue with the recurring tasks not being sorted by start date, so we need to find the earliest task
          let recurringTasks:any = stage.projectTasks.filter(t => t.reoccurrenceSettings?.id == task.reoccurrenceSettings.id);
          let earliestTask = recurringTasks[0];
          for (const recurringTask of recurringTasks) {
            if(new Date(recurringTask.startDate).getTime() < new Date(earliestTask.startDate).getTime())
              earliestTask = recurringTask;
          }
          recurringTaskList = recurringTaskList.concat(recurringTasks.filter(t => t.id != earliestTask.id).map(t => t.id));
          handledRecurringIds[task.reoccurrenceSettings.id] = true;
        } 

        task.assignedToUsers.sort((a, b) => a.assignedToName.localeCompare(b.assignedToName));

        let assignees = task.assignedToUsers?.map(e => e.assignedToName);
        if (assignees.length > 1)
          task.assignedToUsersTooltip = (assignees.length === 1) ? assignees : `${assignees.slice(0, -1).join(', ')} and ${assignees[assignees.length - 1]}`;

        if (this.templateMode) {
          if (!this.project.projectStartDate || !task.startDate) {
            task.daysFromProjectStart = 0;
          }
          else {
            task.daysFromProjectStart = this.daysBetween(new Date(this.project.projectStartDate), new Date(task.startDate))
            if (new Date(this.project.projectStartDate) > new Date(task.startDate)) task.daysFromProjectStart = task.daysFromProjectStart * -1;

          }
          if (!task.dueDate || !task.startDate) {
            task.taskLength = 0;
          }
          else {
            task.taskLength = this.daysBetween(new Date(task.startDate), new Date(task.dueDate));
          }
        }

        task.stageName = stage.stageName;
        task.stageColor = stage.color;
        task.stageColorOpacity = stage.colorWithOpacity;
        task.isSelected = false;
        this.allTasks.push(task);
      });

      // Sorting - If user ever changes the order of tasks using drag&drop, we will sort them by sortOrder forever, otherwise we will sort them by date
      const hasSortOrder = stage.projectTasks.some(task => task?.sortOrder > 0);

      if (hasSortOrder) {
        stage.projectTasks.sort((a, b) => {
          const aSortOrder = (a.sortOrder > 0 ? a.sortOrder : Number.MAX_SAFE_INTEGER);
          const bSortOrder = (b.sortOrder > 0 ? b.sortOrder : Number.MAX_SAFE_INTEGER);
          // sort by sortorder first and then by date
          return aSortOrder - bSortOrder || ((a.dueDate_dt ?? a.startDate_dt ?? 0) - (b.dueDate_dt ?? b.startDate_dt ?? 0));

        });
      } else {
        stage.projectTasks.sort((a, b) => {
          // how would you rewrite the below function to bring the tasks with no due date to the top?
          return ((a.dueDate_dt ?? a.startDate_dt ?? 0) - (b.dueDate_dt ?? b.startDate_dt ?? 0));
        });
      }
    });

    if (this.templateMode)
    {
      //remove all tasks recrruing tasks from the schedule (this keeps the first recurring task)
      this.projectSchedule = data.map(stage => {
        stage.projectTasks = stage.projectTasks.filter(task => !recurringTaskList.includes(task.id));
        return stage;
      });
    }
    else this.projectSchedule = data;
    this.expandCollapseLabel = this.projectSchedule.every(e => e.isOpen) ? 'Collapse all' : 'Expand all';
  }

  GetAllTasks() {
    this.scheduleService.GetAllTasks(this.filter).subscribe(data => {
      this.tasksLimited = false;
      this.allTasks = [];
      data.forEach(task => {
        if (task.startDate != null) {
          task.startDate_dt = new Date(task.startDate);
        }

        if (task.dueDate != null) {
          task.dueDate_dt = new Date(task.dueDate);
        }

        let assignees = task.assignedToUsers?.map(e => e.assignedToName);
        if (assignees.length > 1)
          task.assignedToUsersTooltip = (assignees.length === 1) ? assignees : `${assignees.slice(0, -1).join(', ')} and ${assignees[assignees.length - 1]}`;

        this.allTasks.push(task);
      });
    }, error => {
      this.alertify.error('Unable to get tasks' + error);
    });
  }

  SwitchView(view) {
    if (view == 'gantt') {
      this.drawGanttChart();
    } else if (view == 'calendar') {
      this.calendarview();
    }
    this.currentView = view;
  }

  viewMore(task) {
    this.projectSchedule?.forEach(stage =>
      stage?.projectTasks?.forEach(t => {
        if (t.id != task.id) {
          t.viewMore = false
        }})
    );
    task.viewMore = !task.viewMore;
  }

  endInline(clearStages = false) {
    // end all inline edit mode
    console.log('endInline');
    if (this.templateMode === 'view') return;

    this.projectSchedule?.forEach(stage => {

      if (clearStages) {
        stage.showInlineTask = false;
        stage.newInlineTask = null;
      }

      stage?.projectTasks.forEach(t => {
        t.inlineEditMode = false;
        t.editTaskName = null;
        t.editTaskDescription = null;
        t.editDueDate = null;
        t.editStartDate = null;
        t.addingAssignee = false;
        t.showReoccurrenceSettings = false;
        
        if (!t.taskLength) t.taskLength = 0;

        t.assignedToUsers = t.assignedToUsers.filter(user => user.assignedToId !== null); //remove empty assignees
        t.assignedToUsers.forEach(user => {
          user.showPicker = false;
          user.showDropdown = false;
        });
      })
    });
    this.handleInlineAddMode();
  }

  onDrop(event: DndDropEvent, list: any[], stageId) {

    if (list && event.dropEffect === 'move') {
      let index = event.index;

      if (typeof index === 'undefined') {
        index = list.length;
      }

      // Needed for when dragging bewteen stages
      let originalList;
      let oldIndex = -1;
      let draggingNewTask = false;
      let movedTask;

      for (const s of this.projectSchedule) {
        if (s.projectTasks) {

          // Check for newInlineTask with null id
          if (s.newInlineTask) {
            movedTask = s.newInlineTask;
            movedTask.inlineEditMode = true;
            s.showInlineTask = false;
            originalList = s.projectTasks;
            draggingNewTask = true;
            break;
          }

          // Check for existing tasks by id
          oldIndex = s.projectTasks.findIndex(item => item.id?.toLowerCase() === event.data.id?.toLowerCase());

          if (oldIndex > -1) {
            originalList = s.projectTasks;
            break;
          }
        }
      }

      if (originalList) {

        if (!draggingNewTask) {
          // Remove from original list
          [movedTask] = originalList.splice(oldIndex, 1);
        }

        // Add to new list at the specified index
        list.splice(index, 0, movedTask);

        //change sortOrder for all tasks
        if (event?.type === 'task') {
          list = list.map((item, idx) => {
            item.sortOrder = idx + 1;
            return item;
          });

          const tasks = list.map((item) => {
            return { id: item.id, sortOrder: item.sortOrder, stageId } as ProjectTask;
          });

          //Batch Save task order (only if not dragging newInlineTask)
          if (!draggingNewTask && !this.templateMode) {
            this.scheduleService.SaveProjectTaskOrder(tasks).subscribe(data => {
              this.callback.emit(true);
              this.alertify.success('Tasks order saved');
            }, error => {
              this.alertify.error('Failed to save task order');
            });
          }

        }
      }
    }
  }

  handleEditMode() {
    if (this.templateMode) return;

    this.editMode = !this.editMode;
    this.endInline(true);
  }

  handleTaskDescription(stage, task) {
    task.showDescription = !task.showDescription;
    if (!task.showDescription) task.editTaskDescription = null;

    // auto save when editing mode
    if (!stage.newInlineTask && task.inlineEditMode) {
      this.SaveTaskDescription(task);
    }
  }

  inlineEdit(stage, task, event) {
    // if complete checkbox is clicked don't set to inline edit
    if (this.templateMode === 'view' || task.inlineEditMode) return;

    const htmlElement = (event.target as HTMLElement)
    if (!htmlElement.classList.contains('clickable') && htmlElement.classList.length > 0) {
      if (this.project != null && (!this.editMode || this.templateMode === 'edit') && stage.newInlineTask == null) {
        task.inlineEditMode = true;
        task.editTaskName = task.taskName;
        task.editTaskDescription = task.description;
        task.editTaskLengthHours = task.taskLengthHours;
        task.editTaskLengthMinutes = task.taskLengthMinutes;
        task.showDescription = (this.templateMode === 'edit' || (task.description != null && task.description != ''));
        task.editDueDate = task.dueDate_dt ? new Date(task.dueDate_dt) : null;
        task.editStartDate = task.startDate_dt ? new Date(task.startDate_dt) : null;
        if (this.templateMode === 'edit') {
          if (task.daysFromProjectStart >= 0) task.afterProjectStart = true;
          else task.afterProjectStart = false;

          task.inputDaysFromProjectStart = Math.abs(task.daysFromProjectStart) || 0;
        }
        this.projectSchedule.forEach(stage => {
          stage.projectTasks.forEach(t => {
            if (t.id != task.id) {
              t.inlineEditMode = false;
              t.editTaskName = null;
              t.editTaskDescription = null;
              t.editTaskLengthHours = null;
              t.editTaskLengthMinutes = null;
              t.showDescription = false;
              t.editDueDate = null;
              t.editStartDate = null;
            }
          });
        });
        this.handleInlineAddMode();
      }
    }
  }

  calendarview() {
    this.calendarData = [];
    this.allTasks.filter(t => t.showTask).forEach((p) => {
      if (p.startDate_dt == null && p.dueDate_dt == null) return;
      p.startDateCalendar = (p.startDate_dt ?? p.dueDate_dt);
      p.dueDateCalendar = (p.dueDate_dt ?? p.startDate_dt);

      if (p?.reoccurrenceSettings) this.CreateReoccurrenceSummary(p.reoccurrenceSettings, true)
      
      this.calendarData.push(p);
    });
  }

  //Interactions
  ToggleStageActive(stage, confirmed = false) {
    this.selectedStage = stage;

    if (stage.isActive && !confirmed) {
      this.openModalConfirmStageDeactivate = this.modalService.show(this.modalRefConfirmStageDeactivate, { class: 'nav-modal-style nav-modal-tasks' });
      return;
    }
    stage.isActive = !stage.isActive;
    this.scheduleService.ToggleStageActive(stage.id, stage.isActive).subscribe(data => {
      if (confirmed) this.CloseConfirmStageDeactivateModal()
    }, error => {
      stage.isActive = !stage.isActive;
      this.alertify.error('Failed to updated Stage');
    });
  }
  CloseConfirmStageDeactivateModal() {
    this.LoadData(true);
    this.openModalConfirmStageDeactivate.hide();
  }

  ToggleStatus(task) {
    if (task.status == ScheduleService.STATUS.PENDING) {
      task.status = ScheduleService.STATUS.COMPLETE;
      task.completeDate = new Date();
      this.scheduleService.CompleteTask(task.id).subscribe(data => {
        if (this.project != null) this.SetupData(this.projectSchedule);
        this.callback.emit(true);
      }, error => {
        this.alertify.error('Failed to Complete Task');
      });
    } else {
      task.status = ScheduleService.STATUS.PENDING;
      task.completeDate = null;
      this.scheduleService.UnCompleteTask(task.id).subscribe(data => {
        if (this.project != null) this.SetupData(this.projectSchedule);
        this.callback.emit(true);
      }, error => {
        this.alertify.error('Failed to Un-Complete Task');
      });
    }
  }

  OpenCreateTaskModal() {
    if (this.projectSchedule.length == 0) {
      this.alertify.message("Cannot add task");
    } else {
      this.newTask = { stageId: this.projectSchedule[0].id, isValid: false, assignedToUsers: [{assignedToId: null, assignedToName: '', new: true}] };
      this.openModalRefTask = this.modalService.show(this.modalRefTask, { class: 'nav-modal-style nav-modal-new-task' });
    }
  }
  CloseCreateTaskModal() { 
    if (this.openModalRefTask) this.openModalRefTask.hide(); 
  }



  AddTask(stage, event) {
    const stageId = stage.id;

    this.projectSchedule.forEach(stag => {
      stag.showInlineTask = true; //disable Add Task button for all stages

      if (stag.id == stageId) {
        stag.newInlineTask = {
          stageId: stageId,
          taskName: "",
          status: "",
          showDescription: true,
          description: "",
          startDate: null,
          dueDate: null,
          editTaskName: null,
          editTaskDescription: null,
          showTask: true,
          sortOrder: 0,
          taskLengthHours: null,
          taskLengthMinutes: null,
          assignedToUsers: [{assignedToId: this.auth.getUser().id, assignedToName: this.auth.getUser().name, new: true}],
        };
      }
    });

    setTimeout(() => {
      this.newtaskname?.nativeElement?.focus();
    }, 300);

    this.handleInlineAddMode();
    event.stopPropagation();
  }

  handleInlineAddMode() {
    this.inlineAddMode = this.projectSchedule?.some(stage =>
      stage.showInlineTask && !stage.projectTasks?.some(task => task.inlineEditMode)
    );
  }

  CancelTask(stageId) {
    this.projectSchedule.forEach(stage => {
      stage.showInlineTask = false;
      stage.newInlineTask = null;
    });
    this.handleInlineAddMode();
  }

  CreateInlineTask(stageToSave) {
    this.scheduleService.CreateProjectTask(this.project.id, stageToSave.id, stageToSave.newInlineTask).subscribe((res) => {
      this.alertify.success("Task created successfully");
      this.LoadData(true);
    }, (err) => {
      this.alertify.error('Error creating task');
    }, () => {

      this.projectSchedule.forEach(stage => {
        stage.showInlineTask = false;
        stage.newInlineTask = null;
      });
      this.handleInlineAddMode();
    });
  }

  validateNewTask() {
    this.newTask.isValid = (this.newTask.name?.trim() && this.newTask?.startDate && this.newTask?.dueDate);
  }

  SaveTaskModal() {

    if (this.newTask?.description?.trim().length > 300) {
      this.alertify.error("Description cannot be more than 300 characters");
      return;
    }

    let task: ProjectTask = {
      id: this.newTask.id,
      taskName: this.newTask.name,
      assignedToUsers : this.newTask.assignedToUsers.filter(user => user.assignedToId !== null),
      dueDate: this.DateAsUTCDate(this.newTask.dueDate),
      startDate: this.DateAsUTCDate(this.newTask.startDate),
      description: this.newTask.description,
      stageId: this.newTask.stageId,
    }

    if (!task?.id) {
      this.scheduleService.CreateProjectTask(this.project.id, this.newTask.stageId, task).subscribe((res) => {
        this.LoadData(true);
        this.alertify.success("Task created successfully");
        this.CloseCreateTaskModal();
      }, (err) => {
        this.alertify.error('Error creating task');
      });
    } else {
      if (this.newTask.reoccurrenceSettings) {
        this.openReoccurringTaskChangeConfirmModal(task, 'edit-modal', 'all', null, null)
      }
      else {
        this.scheduleService.SaveProjectTask(task.id, task, false, false).subscribe((res) => {
          this.LoadData(true);
          this.alertify.success("Task saved successfully");
          this.CloseCreateTaskModal();
        }, (err) => {
          this.alertify.error('Error Saving task');
      });
      }
    }
  }

  editTaskFromCalendar(event) {
    const taskid = event?.taskid;
    if (taskid) {
       this.scheduleService.GetTask(taskid).subscribe((res) => {
        if (res) {
          this.newTask = {
            id: res.id,
            stageId: res.stageId,
            name: res.taskName,
            dueDate: res.dueDate,
            startDate: res.startDate,
            description: res.description,
            isValid: true,
            assignedToUsers: res.assignedToUsers.map((user, index) => ({
              assignedToId: user.assignedToId,
              assignedToName: user.assignedToName,
              assigneeFilterText: user.assignedToName,
              new: (index+1) === res.assignedToUsers.length
            })),
            reoccurrenceSettings: res.reoccurrenceSettings
          }

          if (this.newTask.assignedToUsers.length === 0) {
            this.newTask.assignedToUsers.push({assignedToId: null, assignedToName: '', new: true});
          }

          setTimeout(() => {
            this.openModalRefTask = this.modalService.show(this.modalRefTask, { class: 'nav-modal-style nav-modal-new-task' });
          }, 100);
        };
      }, (err) => {
        this.alertify.error('Error fetching task');
      });
    }
  }

  EditTaskAssignees() {
    this.editTaskAssignees.assignedToUsers = this.editTaskAssignees.assignedToUsers.filter(user => user.assignedToId !== null); //remove empty users
    if (this.editTaskAssignees?.id) {
      if (this.editTaskAssignees.reoccurrenceSettings) {
        this.openReoccurringTaskChangeConfirmModal(this.editTaskAssignees, 'inline', 'assignees', null, null)
      }
      else this.SaveProjectAssignees(this.editTaskAssignees, this.editTaskAssignees.assignedToUsers);
    } else {
      this.openModalRefEditAssignees?.hide();
    }
  }

  SaveProjectAssignees(task, assignees, load = true) {
    this.scheduleService.SaveProjectTaskAssignees(task.id, assignees).subscribe((res) => {
      if (load) this.LoadData(true);
      this.alertify.success("Assignees saved successfully");
      this.CloseEditAssigneesModal();
    }, (err) => {
      this.LoadData(true);
      this.alertify.error('Error saving task assignees');
    });
  }

  EditAssigneesAcross() {
    if (this.assigneesAcrossTasks?.tasks.length == 0) {
      this.alertify.error('No open tasks to update');
      return;
    }

    if (!this.assigneesAcrossTasks?.assignedToUsers.some(x => x.assignedToId != null)) {
      this.alertify.error('Select at least one assignee');
      return;
    }

    this.assigneesAcrossTasks.assignedToUsers = this.assigneesAcrossTasks.assignedToUsers.filter(user => user.assignedToId !== null); //remove empty users
    this.scheduleService.BulkReassignTasks(this.assigneesAcrossTasks.assignedToUsers, this.assigneesAcrossTasks.tasks, this.project.id).subscribe((res) => {
      this.LoadData(true);
      this.alertify.success("Assignees saved successfully");
      this.CloseAssigneeAcrossTasks();
    }, (err) => {
      this.LoadData(true);
      this.alertify.error('Error saving task assignees');
    });
  }

  SaveTaskName(task) {
    var originalValue = JSON.parse(JSON.stringify(task.taskName));
    if (task.taskName != task.editTaskName) {
      task.taskName = task.editTaskName;
      if (task?.id && this.templateMode !== 'edit') {
        if (task.reoccurrenceSettings) {
          this.openReoccurringTaskChangeConfirmModal(task, 'inline', 'name', originalValue, null)
        }
        else this.SaveTask(task, false, false, false);
      }
    }
  }

  validateTaskLengthMinute(event: any, task: any) {
    const input = event.target;
    let value = parseInt(input.value, 10);

    if (value > 60) {
      input.value = 60;
      task.editTaskLengthMinutes = 60;
      this.alertify.error('Task length minutes must be between 1 and 60');
    } else if (value < 0) {
      input.value = 0;
      task.editTaskLengthMinutes = null;
    }
  }

  SaveTaskLength(task) {
    let save = false;

    if (task.taskLengthHours != task.editTaskLengthHours) {
      task.taskLengthHours = task.editTaskLengthHours;
      save = true;
    }
    if (task.taskLengthMinutes != task.editTaskLengthMinutes) {
      task.taskLengthMinutes = task.editTaskLengthMinutes;
      save = true;
    }

    if (!task.taskLengthHours || parseInt(task.taskLengthHours) === 0) task.taskLengthHours = null;
    if (!task.taskLengthMinutes) task.taskLengthMinutes = null;

    if (task?.id && save) {
      this.SaveTask(task, false, false, false);
    }
  }

  SaveTaskDescription(task) {
    var originalValue = JSON.parse(JSON.stringify(task.description));
    if (task.description != task.editTaskDescription) {
      task.description = task.editTaskDescription;
      if (task?.id && this.templateMode !== 'edit') {
        if (task.reoccurrenceSettings) {
          this.openReoccurringTaskChangeConfirmModal(task, 'inline', 'description', originalValue, null)
        }
        else this.SaveTask(task, false, false , false);
      }
    }
}

  SaveTaskStartDate(task, newDate) {
    if (newDate == null || task.startDate_dt == newDate) return;
    var originalValue = task?.startDate_dt ? JSON.parse(JSON.stringify(task.startDate_dt)) : null;

    var utcDate = this.DateAsUTCDate(newDate);
    const newDateWithoutTime = utcDate.split('T')[0];
    if (task.startDate !== null) {
      const dateWithoutTime = task.startDate.split('T')[0];
      if (newDateWithoutTime == dateWithoutTime) return;
    }

    task.startDate = utcDate;
    task.startDate_dt = utcDate;
    if (task?.id) {
      if (task.reoccurrenceSettings) {
        this.openReoccurringTaskChangeConfirmModal(task, 'inline', 'startdate', originalValue, null)
      }
      else this.SaveTask(task, false, false, false);
    }
  }

  SaveTaskDueDate(task, newDate) {
    if (newDate == null) return;
    var originalValue = task?.dueDate_dt ? JSON.parse(JSON.stringify(task.dueDate_dt)) : null;
    var utcDate = this.DateAsUTCDate(newDate);
    const newDateWithoutTime = utcDate.split('T')[0];
    if (task.dueDate !== null) {
      const dateWithoutTime = task.dueDate.split('T')[0];
      if (newDateWithoutTime == dateWithoutTime) return;
    }
    task.dueDate = utcDate;
    task.dueDate_dt = utcDate;
    if (task?.id) {
      if (task.reoccurrenceSettings) {
        this.openReoccurringTaskChangeConfirmModal(task, 'inline', 'duedate', originalValue, null)
      }
      else this.SaveTask(task, false, false, false);
    }
  }

  SaveTask(task, updateFutureTasks, updateAllTasks, endInline = true, reloadData = false) { // future tasks and all tasks is only for reoccurrrence
    if (this.templateMode) return;

    this.scheduleService.SaveProjectTask(task.id, task, updateFutureTasks, updateAllTasks).subscribe((res) => {
      if (updateFutureTasks || updateAllTasks || reloadData) { // update task details without reloading all tasks
        this.LoadData(true);
      }
      this.callback.emit(true);
      this.alertify.success("Task saved successfully");
      if (endInline) this.endInline(true);
    }, (err) => {
      this.LoadData(false);
      this.alertify.error('Error Saving task');
    });
  }

  DeleteTask(task, event) {
    if (event != null) event.stopPropagation();

    if (this.templateMode == 'edit') {
      this.projectSchedule.forEach(stage => {
        stage.projectTasks = stage.projectTasks.filter(t => t.id !== task.id);
      });
      return;
    }
    else {
      this.scheduleService.DeleteTask(task.id).subscribe((res) => {
        this.LoadData(true);
        this.alertify.success("Task deleted");
      }, (err) => {
        this.LoadData(false);
        this.alertify.error('Error deleting task');
      });
    }
  }

  SelectTask($event, stage, task) {
    this.projectSchedule.forEach(s => {
      if (stage == null || stage.id == s.id)
        s.projectTasks.forEach(p => {
          if (p.id == task.id) {
            p.isSelected = $event.target.checked;
          }
        });
    });
    let stagestatus = true;
    this.projectSchedule.forEach(s => {
      if (stage.id == s.id) {
        s.projectTasks.forEach(p => {
          if (p.isSelected == false) {
            stagestatus = false
          }
        });
        stage.isSelected = stagestatus;
      }

    });
  }
  SelectAll($event, stage) {
    this.projectSchedule.forEach(s => {
      if (stage == null || stage.id == s.id) {
        s.isSelected = $event.target.checked;
        s.projectTasks.map(e => e.isSelected = ($event.target.checked && e.showTask));
        s.isOpen = true;
      }
    });
  }

  SelectAllGlobal($event) { this.allTasks.map(e => e.isSelected = $event.target.checked); }

  BulkAction(type) {
    var selectedTasks = [];
    if (this.project == null) {
      selectedTasks = this.allTasks.filter(e => e.isSelected);
    } else {
      this.projectSchedule.forEach(stage => {
        selectedTasks = selectedTasks.concat(stage.projectTasks.filter(e => e.isSelected));
      });
    }
  }

  validateDueDate(newDate) {
    this.lastDueDate = newDate;
    if (newDate && newDate.toString() === 'Invalid Date') {
      this.dueDateValid = false;
    } else {
      this.dueDateValid = true;
    }
  }
  validateStartDate(newDate) {
    this.lastStartDate = newDate;
    this.startDateEnable = false;
    if (newDate && newDate.toString() === 'Invalid Date') {
      this.startDateValid = false;
    } else {
      this.startDateValid = true;
    }
  }

  setNewTaskStartDate(task, newDate: Date, dp: BsDatepickerDirective) {
    if (newDate && newDate.toString() !== 'Invalid Date') {
      if (task.startDate !== newDate) {
        task.startDate = newDate;
        var utcDate = this.DateAsUTCDate(newDate);

        if (dp !== null) {
          dp.minDate = new Date(utcDate);
        }
        task.dueDate = null;
      }
    } else {
      if (dp !== null && task.startDate) {
        task.startDate = new Date(task.startDate);
        var utcDate = this.DateAsUTCDate(task.startDate);
        dp.minDate = utcDate;
      } else
        task.startDate = null;
    }
    this.validateNewTask();
  }

  setNewTaskDueDate(task, newDate: Date) {
    if (newDate && newDate.toString() !== 'Invalid Date') {
      if (task.dueDate !== newDate) {
        task.dueDate = this.DateAsUTCDate(newDate);
      }
    } else {
      if (task.dueDate) {
        task.dueDate = new Date(task.dueDate);
      } else
        task.dueDate = null;
    }

    this.validateNewTask();
  }

  matchDatesWithProject(dp: BsDatepickerDirective) {
    if (dp !== null) {
      if (this.project.projectStartDate) dp.minDate = new Date(this.project.projectStartDate);
      if (this.project.projectEndDate) dp.maxDate = new Date(this.project.projectEndDate);
      dp.toggle();
    }
  }

  setNewInlineStartDate(stage, newDate, dp: BsDatepickerDirective) {
    if (newDate == null || stage.newInlineTask.startDate_dt == newDate) return;

    var utcDate = this.DateAsUTCDate(newDate);
    const newDateWithoutTime = utcDate.split('T')[0];
    if (stage.newInlineTask?.startDate !== null) {
      const dateWithoutTime = stage.newInlineTask?.startDate?.split('T')[0];
      if (newDateWithoutTime == dateWithoutTime) return;
    }

    stage.newInlineTask.startDate = utcDate;
    stage.newInlineTask.startDate_dt = utcDate;

    if (dp !== null) {
      dp.minDate = new Date(utcDate);
    }
  }

  setNewInlineDueDate(stage, newDate) {
    if (newDate == null || stage.newInlineTask.dueDate_dt == newDate) return;

    var utcDate = this.DateAsUTCDate(newDate);
    const newDateWithoutTime = utcDate.split('T')[0];
    if (stage.newInlineTask?.dueDate !== null) {
      const dateWithoutTime = stage.newInlineTask?.dueDate?.split('T')[0];
      if (newDateWithoutTime == dateWithoutTime) return;
    }

    stage.newInlineTask.dueDate = utcDate;
    stage.newInlineTask.dueDate_dt = utcDate;
  }

  bulkassignDateModel() {
    if (this.isSelectedTaskAvailable()) {
      this.openModalRefmodalBulkDateAssign = this.modalService.show(this.modalRefmodalBulkDateAssign, { class: 'nav-modal-style nav-modal-tasks' });
    }
  }

  closeBulkAssignDateModal() { this.openModalRefmodalBulkDateAssign.hide(); }

  BulkUpdateTaskDates() {
    let date = this.lastStartDate, days = this.shiftdays, type = this.selectedBulkDateType;
    var selectedTasks = [];
    if ((type == 1 || type == 2) && date == undefined) {
      this.alertify.error("Invalid Date");
    } else {
      if (date == null) date = new Date();

      var utcDate = this.DateAsUTCDate(date);

      if (this.project == null) {
        selectedTasks = this.allTasks.filter(e => e.isSelected);
      } else {
        this.projectSchedule.forEach(stage => {
          selectedTasks = selectedTasks.concat(stage.projectTasks.filter(e => e.isSelected));
        });
      }

      let taskIds: any[] = [];
      for (let i = 0; i < selectedTasks.length; i++) {
        taskIds.push(selectedTasks[i].id)
      }
      this.scheduleService.BulkReAssignDate(this.project.id, taskIds.toString(), utcDate, type, days.toString()).subscribe((res) => {
        this.LoadData(true);
        this.alertify.success("Task date assigned sucessfully");
        this.closeBulkAssignDateModal();
        this.lastStartDate = null;
        this.chkSellectAll = false;
      }, (err) => {
        this.alertify.error('Error in updating dates');
      });
    }
    this.chkSellectAll = false;
  }
  //Filtering & Sorting
  SortBy(field) {
    if (field == this.filter.orderBy) {
      this.filter.orderDirection = (this.filter.orderDirection == 'ASC') ? 'DESC' : 'ASC'
    } else {
      this.filter.orderBy = field;
      this.filter.orderDirection = 'ASC';
    }
    this.LoadData(false);
  }
  ClearAllFilters(dropdown) {
    this.filter = {
      assignedToId: [],
      projectdId: null,
      status: '',
      textFilter: '',
      dueDateBegin: null,
      dueDateEnd: null,
      startDateBegin: null,
      startDateEnd: null,
      completeDateBegin: null,
      completeDateEnd: null,
      mode: '',
      orderBy: '',
      orderDirection: ''
    };
    this.users.map(e => e.filter = false);
    this.showFilter = false;
    if (dropdown) dropdown.hide();
  }
  RemoveFilters(filter) {
    if (filter.type == 'mode') {
      this.ClearAllFilters(null);
      if (this.project == null) {
        //3. Otherwise we set defaults
        if (this.filter.status == '') this.filter.status = 'Pending';
        if (this.bidNumber == null && this.filter.assignedToId.length == 0) this.filter.assignedToId = [this.auth.getUser().id];
      }
    }
    else if (filter.type == 'status') this.filter.status = '';
    else if (filter.type == 'assignedTo') this.filter.assignedToId = [];
    else if (filter.type == 'startDate') this.filter.startDateBegin = this.filter.startDateEnd = null;
    else if (filter.type == 'dueDate') this.filter.startDateBegin = this.filter.startDateEnd = null;
    else if (filter.type == 'startDate') this.filter.dueDateBegin = this.filter.dueDateEnd = null;
    else if (filter.type == 'completeDate') this.filter.completeDateBegin = this.filter.completeDateEnd = null;

    this.ApplyFilter(true);
  }
  FilterStatus(status) {
    this.filter.status = status;
    this.ApplyFilter(true);
  }

  ApplyFilterDialog(dropdown) {
    this.filter.assignedToId = this.users.filter(e => e.filter).map(e => e.id);
    this.ApplyFilter(true);
    dropdown.hide();
  }

  //Helper functions
  //Set the dates to UTC at 12 so that it is the same day everywhere
  DateAsUTCDate(date) {
    if (date != null) {
      date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 12)).toISOString()
    }
    return date;
  }

  formatTaskDate(date) {
    date.setHours(12, 0, 0);
    return date.toISOString()
  }

  getUserList = () => { return of(this.users); }

  reAssignUser(evt) {
    if (evt.length > 0) {
      this.reAssignedToId = evt[0].id;
    }
  }

  // #region Assignees
  fetchAssigneeList(assignee = null) {
    var users = [...this.users].map(item => ({assignedToId: item.id, assignedToName: item.name}));

    const filter = assignee?.assigneeFilterText?.toLowerCase();
    if (!filter) {
      this.filteredAssignees = users;
    }
    else {
      this.filteredAssignees = users.filter(item => item.assignedToName.toLowerCase().includes(assignee?.assigneeFilterText?.toLowerCase()));
      this.filteredAssignees.sort((a, b) => a.assignedToName.localeCompare(b.assignedToName));
    }
    this.updateAssignees(null, null, null, null);
  }

  setIdIfExists(event, assignee) {
    var users = [...this.users].map(item => ({assignedToId: item.id, assignedToName: item.name}));

    if (event.key === 'Enter') {
      let exist = users.find(item => item.assignedToName.toLowerCase() === assignee?.assigneeFilterText?.toLowerCase());
      if (exist) {
        this.assigneeSearch.nativeElement.blur();
        return true;
      }
    }
    return false;
  }

  setIdIfExistsOnBlur(dropdown, assignee, task) {
    var users = [...this.users].map(item => ({assignedToId: item.id, assignedToName: item.name}));

    // set timeout of 5 ms to allow the click event to fire first
    setTimeout(() => {
      let exist = users.find(item => item.assignedToName.toLowerCase() === assignee?.assigneeFilterText?.toLowerCase());
      if (exist) {
        this.updateAssignees(exist, assignee, null, task);
      }
      else {
        assignee.assigneeFilterText = '';
        assignee.assignedToId = null;
        assignee.assignedToName = '';
        assignee.new = true;
        task.canAdd = false;
        this.fetchAssigneeList(assignee);
      }
      if (dropdown && dropdown.isOpen) dropdown.toggle(true);
    }, 5);
  }

  updateAssignees(id, assignee, dropdown, task) {
    var users = [...this.users].map(item => ({assignedToId: item.id, assignedToName: item.name} as any));

    if (task) task.canAdd = false;

    let exist = users.find(x => x.assignedToId === id);
    if (!exist) {
      if (task) task.canAdd = true;
      if (dropdown) {
        if (dropdown.isOpen) dropdown.toggle(true);
        dropdown.blur();
      }
    }
    else {

      let addedIndex = task.assignedToUsers.findIndex(x => x.assignedToId === id);
      if (addedIndex === -1) {
        //task.assignedToUsers = task.assignedToUsers.filter(user => user.assignedToId !== null); // empty assignee
        task.canAdd = true;
        exist.new = true;
        exist.assigneeFilterText = exist.assignedToName;

        if (assignee.id || assignee.assignedToId) {
          let assigneeIndex = (assignee.id) ? task.assignedToUsers.findIndex(x => x.id === assignee?.id) : task.assignedToUsers.findIndex(x => x.assignedToId === assignee?.assignedToId);
          if (assigneeIndex > -1) task.assignedToUsers[assigneeIndex] = exist;
        } else {
          task.assignedToUsers = task.assignedToUsers.filter(user => user.assignedToId !== null); // empty assignee
          task.assignedToUsers.push(exist);
        }

      } else {
        this.alertify.error("Assignee already added to this task");
        task.canAdd = true;
        task.assignedToUsers[addedIndex].new = true;
      }
    }
  }

  addAssignee(assignee) {
    assignee.new = false;
    this.newTask.assignedToUsers.push({assignedToId: null, assignedToName: '', new: true});
  }

  addEditAssignee() {
    this.editTaskAssignees.canAdd = false;
    this.editTaskAssignees.assignedToUsers.push({assignedToId: null, assignedToName: '', new: true});
  }

  removeAssignee(assignee, type) {
    if (type == 'edit') {
      this.editTaskAssignees.assignedToUsers.splice(this.editTaskAssignees.assignedToUsers.indexOf(assignee), 1);
      this.editTaskAssignees.canAdd = !this.editTaskAssignees.assignedToUsers.some(x => x.assignedToId === null);
    }
    else {
      this.assigneesAcrossTasks.assignedToUsers.splice(this.assigneesAcrossTasks.assignedToUsers.indexOf(assignee), 1);
      this.assigneesAcrossTasks.canAdd = !this.assigneesAcrossTasks.assignedToUsers.some(x => x.assignedToId === null);
    }
  }

  addAssigneeAcrossTasks() {
    this.assigneesAcrossTasks.canAdd = false;
    this.assigneesAcrossTasks.assignedToUsers.push({assignedToId: null, assignedToName: '', new: true});
  }

  OpenModalEditAssignees(task, type) {

    let editTask = (type === 'new-inline') ? task : {...task};

    if (!editTask) {
      this.alertify.message("Cannot add task");
      return;
    }
    this.editTaskAssignees = editTask;
    this.editTaskAssignees.assignedToUsers = editTask.assignedToUsers.map(item => ({
      id: item.id,
      assignedToId: item.assignedToId,
      assignedToName: item.assignedToName,
      assigneeFilterText: item.assignedToName // top show in the dropdown
    }));

    this.editTaskAssignees.canAdd = !this.editTaskAssignees.assignedToUsers.some(x => x.assignedToId === null);
    this.openModalRefEditAssignees = this.modalService.show(this.modalRefEditAssignees, { class: 'nav-modal-style modal-edit-assignees' });
  }

  CloseEditAssigneesModal() {
    this.openModalRefEditAssignees?.hide();
    setTimeout(() => {
      this.editTaskAssignees.assignedToUsers = this.editTaskAssignees.assignedToUsers?.filter(user => user?.id); // remove empty users
      this.editTaskAssignees.canAdd = false;
    }, 10);
  }

  OpenModalAssigneeAcrossTasks() {
    this.assigneesAcrossTasks.tasks = this.allTasks.filter(task => task.status == ScheduleService.STATUS.PENDING).map(item => ({ id: item.id, status: item.status }));
    this.assigneesAcrossTasks.assignedToUsers = [{ assignedToId: null, assignedToName: '', new: true }];
    this.openModalRefAssigneeAcrossTasks = this.modalService.show(this.modalRefAssigneeAcrossTasks, { class: 'nav-modal-style' });
  }

  CloseAssigneeAcrossTasks() {
    if (this.openModalRefAssigneeAcrossTasks) this.openModalRefAssigneeAcrossTasks.hide();
    setTimeout(() => {
      this.assigneesAcrossTasks.assignedToUsers = this.assigneesAcrossTasks.assignedToUsers.filter(user => user?.id); // remove empty users
      this.assigneesAcrossTasks.canAdd = false;
    }, 10);
  }

  // #endregion

  isSelectedTaskAvailable() {
    var selectedTasks = [];
    if (this.project == null) {
      selectedTasks = this.allTasks.filter(e => e.isSelected);
    } else {
      this.projectSchedule.forEach(stage => {
        selectedTasks = selectedTasks.concat(stage.projectTasks.filter(e => e.isSelected));
      });
    }
    if (selectedTasks.length == 0) {
      this.alertify.error("Select the Task.");
      return false;
    }
    return true;
  }

  newBulkDeleteModel() {
    if (this.isSelectedTaskAvailable()) {
      this.openModalRefmodalBulkTaskDelete = this.modalService.show(this.modalRefmodalBulkTaskDelete, { class: 'nav-modal-style nav-modal-tasks' });
    }
  }

  closeBulkTaskDeleteModal() { this.openModalRefmodalBulkTaskDelete.hide(); }
  taskIds: any[] = [];
  onChangeTask(event, taskId) {
    if (event.currentTarget.checked) {
      this.taskIds.push(taskId);
    } else {
      this.taskIds.forEach((value, index) => {
        if (value == taskId) this.taskIds.splice(index, 1);
      });
    }
  }

  bulkDeleteTask() {
    var selectedTasks = [];
    if (this.project == null) {
      selectedTasks = this.allTasks.filter(e => e.isSelected);
    } else {
      this.projectSchedule.forEach(stage => {
        selectedTasks = selectedTasks.concat(stage.projectTasks.filter(e => e.isSelected));
      });
      this.chkSellectAll = false;
    }

    let taskIds: any[] = [];
    for (let i = 0; i < selectedTasks.length; i++) {
      taskIds.push(selectedTasks[i].id)
    }

    if (taskIds.length == 0 && this.project) {
      this.alertify.error("Select the task.");
    } else {
      this.scheduleService.BulkDeleteTask((this.project?.id ?? "00000000-0000-0000-0000-000000000000"), taskIds).subscribe((data) => {
        this.LoadData(true);
        this.closeBulkTaskDeleteModal();
        this.alertify.success("Task(s) deleted");
      }, error => {
        this.alertify.error(error);
      });
    }
  }

  removeInlineAssignee(task, assignee, evt, type) {
    var originalValue = JSON.parse(JSON.stringify(task.assignedToUsers));
    task.assignedToUsers.splice(task.assignedToUsers.indexOf(assignee), 1);

    task.addingAssignee = false;
    task.assignedToUsers.forEach(user => {
      user.showPicker = false;
    });

    if (type === 'inline' && assignee?.id) {
      if (task.reoccurrenceSettings) {
        this.openReoccurringTaskChangeConfirmModal(task, 'inline', 'assignees', originalValue, null)
      }
      else {
        this.scheduleService.SaveProjectTaskAssignees(task.id, task.assignedToUsers).subscribe((res) => {
          //this.LoadData(true);
          this.alertify.success("Assignee removed");
        }, (err) => {
          this.LoadData(true);
          this.alertify.error('Error removing assignee');
        });
      }
    } else if (type === 'new-inline') {
      this.alertify.success("Assignee removed");
    }

    evt.stopPropagation();
  }

  handleAssigneeClick(task, stage, assignee, evt, type) {
    if (!task?.inlineEditMode && type === 'inline')
      this.inlineEdit(stage, task, evt);
    else {
      task.addingAssignee = true;
      assignee.showPicker = true;
      assignee.showDropdown = !assignee.showDropdown;

      this.toggleAssignedPicker(task, assignee);
    }
  }

  toggleAssignedPicker(task, assignee, addNew = false) {
    if (!this.editMode) {

      if (!assignee) assignee = { assignedToId: null, assignedToName: '', new: true };

      if (addNew) {
        task.addingAssignee = true;
        task.assignedToUsers.push(assignee);
        assignee.showPicker = true;
        assignee.showDropdown = true;
      }

      this.usersForDropdown = [...this.users];
      this.usersForDropdown.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  //Gannt & Calendar
  drawGanttChart() {
    this.chartData = [];
    let stageList = {};

    if (this.project) {
      this.allTasks.filter(t => t.showTask).forEach((p) => {
        if (p.startDate_dt == null && p.dueDate_dt == null) return;

        p.startDateCalendar = (p.startDate_dt ?? p.dueDate_dt);
        p.dueDateCalendar = (p.dueDate_dt ?? p.startDate_dt);

        let task: any = {
          name: p.stageName,
          id: p.id,
          description: p.taskName,
          start: new Date(p.startDateCalendar).getTime(),
          end: new Date(p.dueDateCalendar).getTime(),
          completedDate: new Date(p.dueDateCalendar).getTime(),
          milestone: p.startDateCalendar == p.dueDateCalendar,
          completed: 0.0,
          color: p.stageColor,
          colorWithOpacity: this.addOpacityToHex(p.stageColor, 0.2),
          stageDescription: (this.project != null) ? p.stageName : p.projectCode + '-' + p.projectName,
          type: 'task',
          className: 'task-' + p.id
        };
        if (stageList[p.stageName] != null) task.name = '';
        stageList[p.stageName] = true;
        this.chartData.push(task);
      });
    } else {
      this.allTasks.forEach((p) => {
        if (p.startDate_dt == null && p.dueDate_dt == null) return;
        p.startDateCalendar = (p.startDate_dt ?? p.dueDate_dt);
        p.dueDateCalendar = (p.dueDate_dt ?? p.startDate_dt);

        let task: any = {
          name: p.stageName,
          id: p.id,
          description: p.taskName,
          start: new Date(p.startDateCalendar).getTime(),
          end: new Date(p.dueDateCalendar).getTime(),
          completedDate: new Date(p.dueDateCalendar).getTime(),
          milestone: p.startDateCalendar == p.dueDateCalendar,
          completed: 0.0,
          color: p.stageColor,
          colorWithOpacity: this.addOpacityToHex(p.stageColor, 0.2),
          stageDescription: (this.project != null) ? p.stageName : p.projectCode + '-' + p.projectName,
          type: 'task',
          className: 'task-' + p.id
        };
        if (stageList[p.stageName] != null) task.name = '';
        stageList[p.stageName] = true;
        this.chartData.push(task);
      });
    }

    this.highchartOptions = {
      credits: {
        enabled: false
      },
      chart: {
        type: 'gantt',
        styledMode: true,
        events: {
          load: this.onChartLoad.bind(this)
        }
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        currentDateIndicator: true,
        gridLineWidth: 0
      },
      yAxis: {
        labels: {
          // formatter: function() {
          //   let point = this.chart.series[0].data[this.pos];
          //   let options = point.options as Custom;
          //   let chartPoint = document.getElementsByClassName('task-' + options.id);
          //   if (chartPoint) {
          //     for (var j = 0; j < chartPoint.length; j++) {
          //       this.renderer.setStyle(chartPoint[j], 'fill', options.color);
          //     }
          //   }

          //   if (point) {
          //     console.log(point);
          //     return `<span class="dot d-inline-block mr-3" [style]="'background-color:'+ ${point.color} +';'"></span> ${this.value}`;
          //   }
          //   return this.value.toString();
          // }
        }
      },
      accessibility: {
        enabled: false
      },
      navigator: {
        enabled: true,
        // liveRedraw: true,
        series: {
          type: 'gantt',
          pointPadding: 0.25,
          accessibility: {
            enabled: false
          }
        },
        yAxis: {
          min: 0,
          max: 3,
          reversed: true,
          categories: []
        }
      },
      scrollbar: {
        enabled: true
      },
      rangeSelector: {
        enabled: true,
        selected: 0
      },
      tooltip: {
        pointFormatter: function () {
          var point = this,
            format = '%A, %m/%d/%Y',
            options = point.options as Custom,
            completed = options.completed || 0,
            status = ((completed || 0) * 100) + '%',
            lines = [];

          lines = [
            { value: options.stageDescription, style: 'font-weight: bold; font-size: 14px; font-family: "Lato"' },
            { value: '<br/><br/>' }
          ];

          if (options.start) {
            lines.push({ title: options.milestone ? 'End Date' : 'Start Date', value: Highcharts.dateFormat(format, options.start), style: 'font-size: 14px' });
          }

          if (options.end) {
            lines.push({ title: 'Due Date', visible: !options.milestone, value: Highcharts.dateFormat(format, options.end), style: 'font-size: 14px' });
          }

          if (options.completed) {
            lines.push({ title: 'Completed', value: status, style: 'font-size: 14px' });
          }

          if (options.owner) {
            lines.push({ title: 'Owner', value: options.owner, style: 'font-size: 14px' });
          }

          return lines.reduce(function (str, line) {
            var s = '',
              style = (Highcharts.defined(line.style) ? line.style : 'font-size: 11px;');

            if (line.visible !== false) {
              s = (
                '<span style="' + style + '">' +
                (Highcharts.defined(line.title) ? line.title + ': ' : '') +
                (Highcharts.defined(line.value) ? line.value : '') +
                '</span><br/>'
              );
            }
            return str + s;
          }, '');
        }
      },
      plotOptions: {
        series: {
          animation: true,
          dragDrop: {
            draggableX: true,
            draggableY: true,
            dragMinY: 0,
            dragMaxY: 200,
            liveRedraw: true,
            dragPrecisionX: 8 // Snap to eight hours
          },
          dataLabels: {
            enabled: true,
            format: '{point.description}'
          },
          connectors: {
            lineWidth: 0
          },
          allowPointSelect: true,
          point: {
            events: {
              drop: this.onChartDrop.bind(this),
              drag: this.onChartDrag.bind(this)
            }
          }
        }
      },
      series: [{
        name: '',
        type: 'gantt',
        data: this.chartData
      }]
    };
  }

  addOpacityToHex(color, opacity) {
    var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  onChartLoad(event) {
    const series = event.target.series[0];
    const points = series.points;
    const labels = event.target.renderer.box.querySelector('.highcharts-yaxis-labels').childNodes;

    for (let i = 0; i < points.length; i++) {
      const point = points[i];
      const options = point.options as Custom;
      const chartPoint = document.getElementsByClassName('task-' + options.id);

      if (chartPoint) {
        for (let j = 0; j < chartPoint.length; j++) {
          this.renderer.setStyle(chartPoint[j], 'fill', options.color);
        }
      }

      const textElement = labels[i] as SVGTextElement;
      const content = (textElement.textContent) ? `<tspan class="fas" style="fill:${options.color};">&#xf111;</tspan>&nbsp;&nbsp;${textElement.textContent}` : '';
      textElement.innerHTML = content;
    }
  }

  onChartDrag(event) {
    var options = event.target.options as Custom;
    var type = options.type;
    if (type !== 'task') {
      event.preventDefault();
    }
  }

  onChartDrop(event) {
    var options = event.target.options as Custom;
    var type = options.type;
    if (type !== 'task') {
      event.preventDefault();
    } else {
      let newStartDate = new Date(options.start);
      let newDueDate = new Date(options.end);

      if (newDueDate == null || newDueDate.toString() == 'Invalid Date') {
        newDueDate = newStartDate;
      }

      let taskId = options.id;
      var task = this.allTasks.find(e => e.id == taskId);
      task.startDate = newStartDate;
      task.dueDate = newDueDate;
      this.SaveTask(task, false, false);
    }
  }
  openProjectFromCalendar(project) { }
  ImportSchedule() { this.openModalRefmodalImportSchedule = this.modalService.show(this.modalRefmodalImportSchedule, { class: 'nav-modal-style nav-modal-tasks' }); }

  CloneSchedule() {
    this.openModalRefmodalCloneSchedule = this.modalService.show(this.modalRefmodalCloneSchedule, { class: 'nav-modal-style nav-modal-tasks modal-lg' });
    this.getMatches();
  }

  CreateTemplate() {
    var initialState = {
      project: this.project,
      templateMode: 'edit'
    }
    this.bsModalRef = this.modalService.show(ScheduleComponent, { class: 'nav-modal-style template-modal modal-lg', ignoreBackdropClick: true, keyboard: false, initialState}, );
    this.bsModalRef.content.submitTemplate.subscribe((data) => {
      if (data != null) {
        this.saveTemplate(data.name, data.schedule)
      }
    })
    this.bsModalRef.content.cancelTemplate.subscribe(() => {
      this.bsModalRef.hide();
      this.bsModalRef = null;
    })
  }

  viewTemplates() {
    this.templateViewFilter = null;
    this.templateScheduleSelected = null;
    this.openModalRefViewTemplates = this.modalService.show(this.modalRefViewTemplates, {class: 'nav-modal-style modal-lg nav-modal-view-templates', ignoreBackdropClick: true, keyboard: false});
    this.getTemplateList();
    this.templateStep = 1;
  }

  getTemplateList() {
    // get gtemplate list
    this.loadingTemplates = true;
    var scheduleTemplates = [];
    this.scheduleService.GetAllTemplates(this.project.projectTypeId).subscribe((data) => {
      data.forEach(template => {
        scheduleTemplates.push(template);
      });
      this.loadingTemplates = false;

    });
    this.scheduleTemplates = scheduleTemplates;
    this.updateTemplateList();
  }

  updateTemplateList() {
    const filter = this.templateViewFilter?.toLowerCase();
    if (!filter) {
      this.filteredScheduleTemplates = this.scheduleTemplates;
    }
    else {
      this.filteredScheduleTemplates = this.scheduleTemplates.filter(item => item.templateName.toLowerCase().includes(filter));
    }
  }

  viewSelectedTemplate() {
    this.templateForPreview = this.templateScheduleSelected.stages;
    this.templateStep = 2;
  }

  applySelectedTemplate() {
    this.scheduleService.ApplyTemplate(this.project.id, this.templateScheduleSelected.id, this.overrideExisting, this.assignAllToMe).subscribe((data) => {
      this.closeViewTemplates();
      this.LoadData(true);
      this.alertify.success('Template applied');
    }, error => this.alertify.error('Error in applying template'));
  }

  closeViewTemplates() {
    this.openModalRefViewTemplates.hide();
  }

  daysBetween(date1, date2) {
    if (!date1 || !date2) return 0;
    const oneDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs((date1 - date2) / oneDay));
  }

  closeImportScheduleModal() { this.openModalRefmodalImportSchedule.hide(); }
  closeCloneScheduleModal() { this.openModalRefmodalCloneSchedule.hide(); }

  ExportSchedule() {
    let csvContent = 'Stage Name,Task Name,Start Date,End Date,AssignedToName';
    this.projectSchedule.forEach(q => {
      if (q.startDate == undefined) q.startDate = null;
      if (q.dueDate == undefined) q.dueDate = null;

      let dataCSV = '\n' + q.stageName;
      csvContent += dataCSV;
      q.projectTasks.forEach(q1 => {
        let dataCSV = '\n' + '' + ',' + q1.taskName + ',' + q1.startDate + ',' + q1.dueDate + ',' + q1.assignedToDisplayName;
        csvContent += dataCSV;
      });
    });
    this.utilService.generateXLSX(csvContent, 'taskExport.csv');
  }

  ExportGlobalSchedule() {
    let csvContent = 'Project Code,Project Name,Client,Stage,Task,AssignedToName,Due Date,Complete Date';
    this.allTasks.forEach(q => {
      if (q.completeDate == undefined) q.completeDate = null;
      if (q.dueDate == undefined) q.dueDate = null;
      let dataCSV = '\n' + q.projectCode.replace(',', '') + ',' + q.projectName.replace(',', '') + ',' + q.clientName.replace(',', '') + ',' + q.stageName.replace(',', '') + ',' + q.taskName.replace(',', '') + ',' + q.assignedToName.replace(',', '') + ',' + q.dueDate + ',' + q.completeDate;
      csvContent += dataCSV;
    });
    this.utilService.generateXLSX(csvContent, 'GlobalTaskExport.csv');
    this.alertify.success("Sucessfully exported");
  }
  ImportScheduleTemplate() {
    let csvContent = 'Stage Name,Task Name,Start Date,End Date,AssignedToName';
    let task: boolean = false;
    let dummyTask: boolean = false;
    let dummyDatetime: Date = new Date();
    this.projectSchedule.forEach(q => {
      if (q.projectTasks.length > 0) {
        task = true;
        return
      }
    });
    this.projectSchedule.forEach(q => {
      if (q.startDate == undefined) q.startDate = null;
      if (q.dueDate == undefined) q.dueDate = null;
      let dataCSV = '\n' + q.stageName;
      csvContent += dataCSV;
      if (!task && !dummyTask) {
        dummyTask = true;
        let dataCSV = '\n' + '' + ',Dummy-task,' + '2024-05-19T12:00:00,2024-08-19T12:00:00,,';
        csvContent += dataCSV;
      }
      csvContent += '\n';
    });
    this.utilService.generateXLSX(csvContent, 'taskExport.csv');
  }
  replaceAll(str, find, replace) {
    var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return str.replace(new RegExp(escapedFind, 'g'), replace);
  }

  handleFileInput(files: FileList) { this.actualUploadedFileName = files.item(0).name; }
  importTasks() {
    if (this.uploadedFile !== null && this.uploadedFile !== '') {
      const fileName = this.uploadedFile.substring(this.uploadedFile.lastIndexOf('/') + 1);
      const url = this.replaceAll(this.uploadedFile, '/', '@');
      let f = this.actualUploadedFileName;
      this.scheduleService.ImportTasks(this.project.id, fileName, this.actualUploadedFileName, url).subscribe((data) => {
        this.LoadData(true);
        this.closeImportScheduleModal();
        this.alertify.success("Sucessfully imported");
      }, error => {
        this.alertify.error(error);
      });
    }
  }

  compareDates(date1, date2) {
    date1 = new Date(date1);
    date2 = new Date(date2)
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth();
    const day1 = date1.getDate();

    const year2 = date2.getFullYear();
    const month2 = date2.getMonth();
    const day2 = date2.getDate();

    if (year1 < year2 || (year1 === year2 && month1 < month2) || (year1 === year2 && month1 === month2 && day1 < day2)) {
      return 'before'
    } else if (year1 > year2 || (year1 === year2 && month1 > month2) || (year1 === year2 && month1 === month2 && day1 > day2)) {
      return 'after'
    } else {
      return 'same'
    }
  }

  private isClickInsideAnyDiv(event: MouseEvent): boolean {
    // Check if the click target is inside any of the divs
    const htmlElement = event?.target as HTMLElement
    if (htmlElement.classList.contains("dropdown-list-item") || htmlElement.classList.contains("dropdown-list")) {
      return true
    }

    else if (htmlElement.classList.contains("editable")) {
      return true
    }
    else {
      const divElements = Array.from(document.querySelectorAll('.inline-div'));
      for (const divElement of divElements) {
        if (divElement.contains(event.target as Node)) {
          return true;
        }
      }
    }
    return false;
  }

  redirectToGlobalTasks() {
    this.router.navigate(["/schedule"])
  }
  copySearch = (filter) => { return this.projectService.CopySearch(filter); }

  getMatches(preSelected = false) {
    this.copySearch(this.asyncSearch).subscribe((items: any) => {
      this.matches = items
      if (!preSelected) this.selectedMatch = null
    });
  }

  selectMatch(match, event = null) {
    if (event != null) {
      this.selectedMatch = match.id;
      const projectToClone = match;
      this._newProject.cloneProjectid = projectToClone.id;

    }
  }

  calculateTemplateTaskDates(task, days) {
    if (days === null || days === "") {
      task.daysFromProjectStart = 0;
    }
    else {
      days = parseInt(days);
      if ((days < 0 && task.afterProjectStart) || (days > 0 && !task.afterProjectStart)) {
        days = days * -1;
      }
      task.daysFromProjectStart = days;
    }
  }

  checkCanSaveTemplate(newName) {
    if (newName == null || newName.trim() === '') this.canSaveTemplate = false;
    else {
      this.canSaveTemplate = true;
    }
  }

  public returnCurrentSchedule(){
    return this.projectSchedule;
  }

  saveTemplate(templateName, schedule) {
    if (templateName == null || templateName.trim() == '') return;
    else {
      this.newTemplate = {templateName: templateName, projectTypeId: this.project.projectTypeId, tasks: [],}
      schedule.forEach(stage => { 
        stage.projectTasks.sort((a, b) => a.sortOrder - b.sortOrder);
        var index = 1;
        stage.projectTasks.forEach(task => {
          var newTask: ScheduleTemplateTask = {
            taskName: task.taskName,
            description: task.description,
            daysFromProjectStart: task.daysFromProjectStart,
            taskLength: task.taskLength,
            sortOrder: index,
            projectTypeStageId: stage.projectTypeStageId,
            reoccurrenceSettings: null
          };
          if(task.reoccurrenceSettings != null) {
            newTask.reoccurrenceSettings = {
              daysFromProjectStart_begin:  this.daysBetween(new Date(this.project.projectStartDate), new Date(task.reoccurrenceSettings.startDate)),
              daysFromProjectStart_end: this.daysBetween(new Date(this.project.projectStartDate), new Date(task.reoccurrenceSettings.endDate)),
              frequency: task.reoccurrenceSettings.frequency,
              frequencyType: task.reoccurrenceSettings.frequencyType,
              days: task.reoccurrenceSettings.days,
            }
          }

          this.newTemplate.tasks.push(newTask);
          index++;
        });
      });
      if (this.newTemplate.tasks.length == 0) {
        this.alertify.error("Please add a task to save the template");
        return;
      }
      console.log(this.newTemplate);
      return;
      this.scheduleService.CreateScheduleTemplate(this.newTemplate).subscribe((res) => {
          this.bsModalRef.hide();
          this.alertify.success(`"${this.newTemplate.templateName}" has been saved`);
      }, error => {
        this.alertify.error(`Error saving template "${this.newTemplate.templateName}"`);
      });
    }
  }

  selectTemplate(event, template) {
    if (event.srcElement.className.includes('fa-trash')) return;

    this.templateScheduleSelected = template
    this.overrideExisting = true;
    this.assignAllToMe = false;
  }

  deleteTemplate(template) {
    if (this.auth.isSuperAdmin() || this.auth.isAdmin()) {
      this.scheduleService.DeleteTemplate(template.id).subscribe((res) => {
        if (res) {
          if (this.templateScheduleSelected?.id == template.id) this.templateScheduleSelected = null;
          this.scheduleTemplates = this.scheduleTemplates.filter(x => x.id !== template.id);
          this.updateTemplateList();
          this.alertify.success("Template successfully deleted");
        }
        else {
          this.alertify.error("Error deleting template");
        }
      }, error => {
        this.alertify.error("Error deleting template");

      });
    }
  }

  cloneSchedule() {
    var tt = this.lastStartDate;
    if (!this.lastStartDate) {
      this.alertify.error('Please select the date to clone');
      return;
    }
    if (!this._newProject.cloneProjectid) {
      this.alertify.error('Please search and select a existing project to copy');
      return;
    }
    this.scheduleService.CloneSchedule(this.project.id, this._newProject.cloneProjectid, this.lastStartDate).subscribe((res) => {
      this.LoadData(true);
      this.alertify.success("Schedule cloned sucessfully");
      this.closeCloneScheduleModal();
      this.lastStartDate = null;
    }, (err) => {
      this.alertify.error('Error in schedule clone');
    });
  }

  validateInlineAddFields(showMessage = true) {
    this.projectSchedule?.forEach(stage => {
  
      if (stage.newInlineTask) {
  
        if (!stage.newInlineTask.errors) stage.newInlineTask.errors = {};
  
        stage.newInlineTask.errors.taskName = (!stage.newInlineTask.taskName || !stage.newInlineTask.taskName?.trim()) ? true : false;
        stage.newInlineTask.errors.description = (!stage.newInlineTask.description || !stage.newInlineTask.description?.trim()) ? true : false;
        stage.newInlineTask.errors.startDate = (!stage.newInlineTask.startDate) ? true : false;
        stage.newInlineTask.errors.dueDate = (!stage.newInlineTask.dueDate) ? true : false;
        //stage.newInlineTask.errors.taskLengthHours = (!stage.newInlineTask.taskLengthHours) ? true : false;
        //stage.newInlineTask.errors.taskLengthMinutes = (!stage.newInlineTask.taskLengthMinutes) ? true : false;

        // check if there is any newInlineTask with errors
        const errors = Object.values(stage.newInlineTask.errors).filter(e => e).length;

        // if user has not entered at least one field, we close the inline add mode
        if (errors == 4) {
          this.projectSchedule.forEach(stage => {
            stage.showInlineTask = false;
            stage.newInlineTask = null;
          });
          this.handleInlineAddMode();
        }
        else if (errors > 1 || (errors === 1 && stage.newInlineTask.description)) {
          if (showMessage) {
            if (stage.newInlineTask.description && stage.newInlineTask.description.trim().length > 300)
              this.alertify.error('Description should not exceed 300 characters');
            else if (stage.newInlineTask.taskLengthHours && parseInt(stage.newInlineTask.taskLengthHours) === 0)
              this.alertify.error('Task length hours should be greater than 0');
            else if (stage.newInlineTask.taskLengthMinutes && (parseInt(stage.newInlineTask.taskLengthMinutes) === 0 || parseInt(stage.newInlineTask.taskLengthMinutes) > 60))
              this.alertify.error('Task length minutes should be greater than 0 and less than 60');
            else
              this.alertify.error('Enter fields to save task or delete task to continue');
          }
        }
        else {
          if (stage.newInlineTask) {
            if (!stage.newInlineTask.taskLengthHours) stage.newInlineTask.taskLengthHours = null;
            if (!stage.newInlineTask.taskLengthMinutes) stage.newInlineTask.taskLengthMinutes = null;
            this.CreateInlineTask(stage);
          }
        }
      }
    });
  }

  openConfirmDeleteTimeTracking(column) {
    this.removeField(column);
  }

  getDaysArrayFromString(string) {
    var array = [{bit: 0, label: 'S'}, {bit: 0, label: 'M'}, {bit: 0, label: 'T'}, 
      {bit: 0, label: 'W'}, {bit: 0, label: 'T'}, {bit: 0, label: 'F'}, {bit: 0, label: 'S'}];

    array.forEach(day => {
      day.bit = parseInt(string[array.indexOf(day)]);
    });
  
    return array;
  }

  openReoccurrenceSettingsInCalendar(taskId) {
    if (taskId) {
      var task = this.allTasks.find(e => e.id === taskId);
      if (task) {
        this.openReoccurrenceSettings(task);
      }
    }
  }

  openReoccurrenceSettings(task) {
    task.showReoccurrenceSettings = true;
    this.taskToEditReoccurrence = JSON.parse(JSON.stringify(task));
    var startDate = new Date();
    if (this.taskToEditReoccurrence.reoccurrenceSettings?.startDate) startDate = new Date(this.taskToEditReoccurrence.reoccurrenceSettings.startDate);
    else if (this.taskToEditReoccurrence.startDate) startDate = new Date(this.taskToEditReoccurrence.startDate);
    else if (this.taskToEditReoccurrence.dueDate) startDate = new Date(this.taskToEditReoccurrence.dueDate);
    
    if (this.taskToEditReoccurrence.reoccurrenceSettings == null) {
      this.taskToEditReoccurrence.reoccurrenceSettings = {
        startDate: new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 12)).toISOString(),
        startDate_dt: startDate,
        endDate: null,
        frequency: null,
        frequencyType: null,
        days: '0000000'
      }
    }
    else {
      var endDate = new Date(this.taskToEditReoccurrence.reoccurrenceSettings?.endDate)
      this.taskToEditReoccurrence.reoccurrenceSettings.startDate_dt = new Date(startDate);
      this.taskToEditReoccurrence.reoccurrenceSettings.startDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 12)).toISOString();
      this.taskToEditReoccurrence.reoccurrenceSettings.endDate_dt = new Date(endDate);
      this.taskToEditReoccurrence.reoccurrenceSettings.endDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 12)).toISOString();

    }
    this.taskToEditReoccurrence.reoccurrenceSettings.daysArray = this.getDaysArrayFromString(this.taskToEditReoccurrence.reoccurrenceSettings.days);
    
    this.onReocurrenceChange();

    this.currentReoccurrenceCopy = JSON.parse(JSON.stringify(this.taskToEditReoccurrence.reoccurrenceSettings));
    this.createFrequenciesOptions(this.taskToEditReoccurrence.reoccurrenceSettings.frequencyType);
    this.checkIfAnyChangeInReoccurrence(this.taskToEditReoccurrence.reoccurrenceSettings);
    this.openModalRefReoccurrenceSettings = this.modalService.show(this.modalRefReoccurrenceSettings, { ignoreBackdropClick: true, class: 'nav-modal-style modal-dialog-centered reoccurrenceDialog' }, );
  }

  daysPickedChanged(settings) {
    var days = '';
    for (var i = 0; i < 7; i++) {
      days += settings.daysArray[i].bit;
    }
    settings.days = days;
    this.onReocurrenceChange();
  }

  checkIfAnyChangeInReoccurrence(settings) {
    var oldSettings = this.currentReoccurrenceCopy;
    var onlyEndDateChanged = false;
    if (oldSettings != null && oldSettings.startDate === settings.startDate && 
      oldSettings.frequency == settings.frequency && oldSettings.frequencyType === settings.frequencyType && 
      oldSettings.days === settings.days) {
      
      if (oldSettings.endDate === settings.endDate) {
        this.reoccurrenceChange = false;
      }
      else {
        this.reoccurrenceChange = true;
        onlyEndDateChanged = true;
      }
    }
    else this.reoccurrenceChange = true;

    this.onlyEndDateChanged = onlyEndDateChanged;
  }

  validateReoccurrence(settings) {
    if (settings.startDate == null || settings.endDate == null || settings.frequency == null || 
    settings.frequencyType == null || (settings.days === '0000000' && settings.frequencyType === 'Weekly')) {
      
      return false;
    }
    else return true;
  }

  onReocurrenceChange() {
    var valid = this.validateReoccurrence(this.taskToEditReoccurrence.reoccurrenceSettings);
    if (valid) this.CreateReoccurrenceSummary(this.taskToEditReoccurrence.reoccurrenceSettings);
    else this.taskToEditReoccurrence.reoccurrenceSettings.summary = null

    if (this.currentReoccurrenceCopy != null) this.checkIfAnyChangeInReoccurrence(this.taskToEditReoccurrence.reoccurrenceSettings);
  }

  saveReocurrenceSettings(taskId, settings) {
    if (this.validateReoccurrence(settings)) {
      
      this.scheduleService.SaveReoccurrenceSettings(taskId, settings, true).subscribe((res) => {
        this.alertify.success('Reoccurrence settings saved');
        this.LoadData(true);

        // this.projectSchedule.forEach(stage => {
        //   stage.projectTasks.forEach(task => {
        //     if (task.id === taskId) {
        //       task.reoccurrenceSettings = settings;
        //     }
        //   });
        // });
        this.openModalRefReoccurrenceSettings.hide();
        this.openModalRefReoccurrenceSettings = null;
        setTimeout(() => {
          this.projectSchedule.forEach(stage => {
            stage.projectTasks.sort((a, b) => a.startDate - b.startDate);
          });
        }, 2000);
      }, error => {
        this.alertify.error('Error saving reoccurrence settings');
      });
    }
  }

  createFrequenciesOptions(type) {
    var frequencies = [];
    var maxFrequency = 7;
    if (type === 'Weekly') {
      maxFrequency = 26;
    }
    for (var i = 1; i <= maxFrequency; i++) {
      frequencies.push(i);
    }
    this.frequencies = frequencies;
  }

  changeFrequencyType(type) {
    this.taskToEditReoccurrence.reoccurrenceSettings.frequencyType = type;
    this.taskToEditReoccurrence.reoccurrenceSettings.frequency = null;

    if (type === 'Daily') {
      this.taskToEditReoccurrence.reoccurrenceSettings.days = '0000000';
      this.taskToEditReoccurrence.reoccurrenceSettings.daysArray = this.getDaysArrayFromString('0000000')
    }
    this.createFrequenciesOptions(type);
    this.onReocurrenceChange();
  }

  CreateReoccurrenceSummary(settings, calendarView = false) {
    var summary = calendarView ? 'Reoccurs ' : 'Occurs '
    if (settings.frequencyType === 'Daily') {
      if (settings.frequency == null) summary += 'on ';
      else {
        summary += 'every ';
        summary += '<span class="font-weight-bold">' 
        summary += this.getFrequencyLabel(settings.frequency);
        summary += ' </span><span class="font-weight-bold">day </span>';

      }
    }
    else if (settings.frequencyType === 'Weekly') {
      if (settings.frequency == null) summary += 'on ';
      else {
        summary += 'every ';
        summary += '<span class="font-weight-bold">' 
        summary += this.getFrequencyLabel(settings.frequency);
        summary += '</span>';
      }
      var index = 0;
      var allDaysSelected = [];
      settings.days.split('').forEach(day => {
        switch (index) {
          case 0: if (day === '1') allDaysSelected.push('Sunday'); break;
          case 1: if (day === '1') allDaysSelected.push('Monday'); break;
          case 2: if (day === '1') allDaysSelected.push('Tuesday'); break;
          case 3: if (day === '1') allDaysSelected.push('Wednesday'); break;
          case 4: if (day === '1') allDaysSelected.push('Thursday'); break;
          case 5: if (day === '1') allDaysSelected.push('Friday'); break;
          case 6: if (day === '1') allDaysSelected.push('Saturday'); break;
        }
        index++;
      });
      var lastDaySelected = `<span class="font-weight-bold"> ${allDaysSelected.pop()}</span> `;
      
      summary += `<span class="font-weight-bold"> ${allDaysSelected.join(', ')} </span>`;
      summary += (allDaysSelected.length > 0) ? ' and ' + lastDaySelected : lastDaySelected;
    }
    var startDate = this.datepipe.transform(settings.startDate,"MM/dd/YY");
    var endDate = this.datepipe.transform(settings.endDate,"MM/dd/YY");

    summary += `${calendarView ? 'from' : 'beginning on'} <span class="font-weight-bold"> ${startDate}</span>
      and ${calendarView ? 'ends on' : 'ending on'} <span class="font-weight-bold">${endDate}</span>`;

    settings.summary = summary;
  }

  SetReoccurrenceDate(newDate, type) {    

    var asUTC;
    var settings = this.taskToEditReoccurrence.reoccurrenceSettings;
    if (newDate) {
      asUTC = new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 12)).toISOString()
    }
    else {
      var asUTC = null;
    }

    if (type === 'start') {
      settings.startDate = asUTC;
    }

    else if (type === 'end'){
      settings.endDate = asUTC;
    }
   
    this.onReocurrenceChange();
  }

  openReoccurringTaskChangeConfirmModal(task, mode, valueType, originalValue, event = null) {
    if (event) event.stopPropagation();

    var options = [];
    if (mode !== 'edit' || (mode === 'edit' && !this.taskToEditReoccurrence)) {
      options.push({ label: 'This task only', value: 'this', checked: true});
    }

    options.push({ label: 'This and following tasks', value: 'this-following', checked: false});
    options.push({ label: 'All tasks', value: 'all', checked: false});

    this.reoccurringTaskChangeData = {
      mode: mode,
      task: task,
      options: options,
      valueType: valueType,
      originalValue: originalValue,
    }
    if (this.openModalRefEditAssignees) {
      // add class 'dont-show' to modal
      const modalElement = document.querySelector('.modal-edit-assignees'); // Assuming modal has this class
      const modalContent = modalElement.querySelector('.modal-content');
      this.renderer.addClass(modalContent, 'dont-show');
    }
    if (this.openModalRefTask) {
      // add class 'dont-show' to modal
      const modalElement = document.querySelector('.nav-modal-new-task'); // Assuming modal has this class
      const modalContent = modalElement.querySelector('.modal-content');
      this.renderer.addClass(modalContent, 'dont-show');
    }
    
    this.openModalRefReoccurringTaskChangeConfirm = this.modalService.show(this.modalRefReoccurringTaskChangeConfirm, 
      { ignoreBackdropClick: true, keyboard: false, class: 'nav-modal-style modal-dialog-centered modal-reoccurring-task-confirm' }, );

  }

  checkboxClickedReoccuringTaskChange(valueChecked) {
    this.reoccurringTaskChangeData.options.forEach(o => {
      o.checked = o.value !== valueChecked ? false : true;
    });
  }

  updateReoccuringTasks(mode) {
    if (this.openModalRefReoccurrenceSettings) {
      this.openModalRefReoccurrenceSettings.hide();
      this.openModalRefReoccurrenceSettings = null;
    }
    if (this.openModalRefEditAssignees) {
      this.openModalRefEditAssignees.hide();
      this.openModalRefEditAssignees = null;
    }
    var checked = null
    checked = this.reoccurringTaskChangeData.options.find(o => o.checked);
    if (checked) {
      if (mode === 'delete') {
        if (checked.value === 'this') {
          this.DeleteTask(this.reoccurringTaskChangeData.task, null);
        }
        else {
          var updateAll = checked.value === 'all';
          var task = this.reoccurringTaskChangeData.task;
          this.scheduleService.DeleteReoccurringTasks(task.id, task.reoccurrenceSettings.id, updateAll).subscribe((res) => {
            this.alertify.success('Reoccurrence settings saved');
            this.LoadData(true);
          });
        }
      }
      else if (mode === 'edit') {
        var updateAll = checked.value === 'all';
        var task = this.reoccurringTaskChangeData.task;
        this.scheduleService.SaveReoccurrenceSettings(task.id, this.taskToEditReoccurrence.reoccurrenceSettings, updateAll).subscribe((res) => {
          this.alertify.success('Reoccurrence settings saved');
          this.LoadData(true);
        });
      }
      else if (mode === 'inline' || mode === 'edit-modal') {
        var updateAll = checked.value === 'all';
        var updateFuture = checked.value === 'this-following';
        var task = this.reoccurringTaskChangeData.task;
        if (this.reoccurringTaskChangeData.valueType === 'assignees') {
          if (!updateAll && !updateFuture) {
            this.SaveProjectAssignees(task, task.assignedToUsers, true);
          }
          else {
            var reoccuringTasks = this.projectSchedule.reduce((acc, stage) => { 
              return acc.concat(stage.projectTasks.filter(t => t.reoccurrenceSettings && t.reoccurrenceSettings.id === task.reoccurrenceSettings.id)) 
            }, []);

            if (updateFuture) reoccuringTasks = reoccuringTasks.filter(t => t.startDate >= task.startDate);
            this.scheduleService.BulkReassignTasks(task.assignedToUsers, reoccuringTasks, this.project.id).subscribe((res) => {
              this.LoadData(true);
              this.alertify.success("Assignees saved successfully");
              this.CloseAssigneeAcrossTasks();
            }, (err) => {
              this.LoadData(true);
              this.alertify.error('Error saving task assignees');
            });
          }
        }
        else {
          this.CloseCreateTaskModal();
          this.SaveTask(task, updateFuture, updateAll, true, true);
        }
      }
    }

    setTimeout(() => {
      this.closeReoccurringTaskChange();
    }, 500);
  }

  cancelReoccurringTaskConfirm() {
    if (this.openModalRefReoccurrenceSettings || this.openModalRefEditAssignees || this.openModalRefTask) {
      if (this.openModalRefReoccurrenceSettings) {
        this.openModalRefReoccurrenceSettings.hide();
        this.openModalRefReoccurrenceSettings = null;
      }
      else if (this.openModalRefEditAssignees) {
        this.openModalRefEditAssignees.hide();
        this.openModalRefEditAssignees = null;
      }
      else if(this.openModalRefTask) {
        this.openModalRefTask.hide();
        this.openModalRefTask = null;
      }

      setTimeout(() => {
        this.closeReoccurringTaskChange();
      }, 200);
    }
    else this.closeReoccurringTaskChange();
  
    var originalValue = this.reoccurringTaskChangeData.originalValue;
    if (this.reoccurringTaskChangeData.mode === 'inline') {
      var task = this.reoccurringTaskChangeData.task
      if (this.reoccurringTaskChangeData.valueType === 'name') {
        task.taskName = originalValue;
        task.editTaskName = originalValue;
      }
      else if (this.reoccurringTaskChangeData.valueType === 'description') {
        task.description = originalValue;
        task.editTaskDescription = originalValue;
      }
      else if (this.reoccurringTaskChangeData.valueType === 'startdate') {
        task.startDate = originalValue;
        task.startDate_dt = new Date(originalValue)
        task.editStartDate = task.startDate_dt;
      }
      else if (this.reoccurringTaskChangeData.valueType === 'duedate') {
        task.dueDate = originalValue;
        task.dueDate_dt = new Date(originalValue)
        task.editDueDate = task.dueDate_dt;
      }
      else if (this.reoccurringTaskChangeData.valueType === 'assignees' && originalValue != null) {
        task.assignedToUsers = originalValue;
      }
    }
    else if (this.reoccurringTaskChangeData.modal === 'edit-modal') {
      
    }
  }

  
  closeReoccurringTaskChange() {
    if (this.openModalRefReoccurringTaskChangeConfirm) {
      this.openModalRefReoccurringTaskChangeConfirm.hide();
      this.openModalRefReoccurringTaskChangeConfirm = null;
    }
  }
  
  getFrequencyLabel(frequency) {
    if (frequency == null) return '';
    var label = '';
    switch (frequency) {
      case 2: label = 'second'; break;
      case 3: label = 'third'; break;
      case 4: label = 'fourth'; break;
      case 5: label = 'fifth'; break;
      case 6: label = 'sixth'; break;
      case 7: label = 'seventh'; break;
      case 8: label = 'eighth'; break;
      case 9: label = 'ninth'; break;
      case 10: label = 'tenth'; break;
      case 11: label = 'eleventh'; break;
      case 12: label = 'twelfth'; break;
      case 13: label = 'thirteenth'; break;
      case 14: label = 'fourteenth'; break;
      case 15: label = 'fifteenth'; break;
      case 16: label = 'sixteenth'; break;
      case 17: label = 'seventeenth'; break;
      case 18: label = 'eighteenth'; break;
      case 19: label = 'nineteenth'; break;
      case 20: label = 'twentieth'; break;
      case 21: label = 'twenty-first'; break;
      case 22: label = 'twenty-second'; break;
      case 23: label = 'twenty-third'; break;
      case 24: label = 'twenty-fourth'; break;
      case 25: label = 'twenty-fifth'; break;
      case 26: label = 'twenty-sixth'; break;
    }
    return label;
  }
  
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {

    if (this.bsModalRef) return;
    if (this.openModalRefReoccurrenceSettings) return;
    if (this.openModalRefReoccurringTaskChangeConfirm) return;
    const clickedElement = event.target as HTMLElement;
    if ((!this.editMode || this.templateMode === 'edit') && !clickedElement.classList.contains('clickable') && !clickedElement.classList.contains('dropdown-list-item')) {

      const editingDiv = this.elRef.nativeElement.querySelector('.inline-edit');
      const isClickInside = editingDiv && editingDiv.contains(clickedElement);

      // Check if the click target is not one of the dynamically created divs
      if (!this.isClickInsideAnyDiv(event)) this.endInline();
      if (!isClickInside) this.validateInlineAddFields();
    }
  }

}


