<div class="card">
  <div class="card-header d-flex align-items-center">
    <span class="nav-secondary nav-font16 font-weight-bold mr-auto mb-0"><i class="fak fa-settings fa-lg mr-2 grey4 font-weight-normal"></i>Sample Specifications</span>
    <i class="fas fa-arrows-maximize fa-md grey4 font-weight-normal float-right cursor-pointer" (click)="openSettings('sample-spec')"></i>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col d-flex justify-content-center">
            <div class="circle mb-2 cursor-pointer" [class.add-value]="!_project.ir" (click)="(!_project.ir || _project.ir === 0)  ? openSettings('sample-spec') : null">
              @if (!_project.ir || _project.ir === 0) {
                <i class="fa fa-plus fa-lg nav-accent-blue font-weight-normal"></i>
              }
              @if (_project.ir && _project.ir > 0) {
                <span class="nav-secondary nav-font14 font-weight-bold">{{_project.ir}}%</span>
              }
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <span class="d-block grey5 nav-font12 font-weight-bold">Incidence rate</span>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col d-flex justify-content-center">
            <div class="circle mb-2 cursor-pointer" [class.add-value]="!_project.loi" (click)="(!_project.loi || _project.loi === 0) ? openSettings('sample-spec') : null">
              @if (!_project.loi || _project.loi === 0) {
                <i class="fa fa-plus fa-lg nav-accent-blue font-weight-normal"></i>
              }
              @if (_project.loi && _project.loi > 0) {
                <div class="d-block text-center loi mt-2">
                  <span class="d-block nav-secondary nav-font14 font-weight-bold">{{_project.loi | minuteSeconds}}</span>
                  <span class="nav-secondary nav-font14 font-weight-bold">mins</span>
                </div>
              }
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <span class="d-block grey5 nav-font12 font-weight-bold">Length of interview</span>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col d-flex justify-content-center">
            <div class="circle mb-2 cursor-pointer" [class.add-value]="!_project.fullLaunchQuota" (click)="(!_project.fullLaunchQuota || _project.fullLaunchQuota === 0) ? openSettings('sample-spec') : null">
              @if (!_project.fullLaunchQuota || _project.fullLaunchQuota === 0) {
                <i class="fa fa-plus fa-lg nav-accent-blue font-weight-normal"></i>
              }
              @if (_project.fullLaunchQuota && _project.fullLaunchQuota > 0) {
                <span class="nav-secondary nav-font14 font-weight-bold">{{_project.fullLaunchQuota}}</span>
              }
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <span class="d-block grey5 nav-font12 font-weight-bold">Completes</span>
        </div>
      </div>
    </div>
  </div>
</div>
