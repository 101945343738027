import { Component, OnInit, ViewChild } from '@angular/core';
import {ReportsService } from 'core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-responseid-api-report',
  templateUrl: './responseid-api-report.component.html',
  styleUrls: ['./responseid-api-report.component.scss']
})
export class ResponseIdApiReportComponent implements OnInit {
  iframeUrl = '';
  reportId = "106";
  instanceId = '';
  @ViewChild('myIframe') public myIframe;

  constructor(private reportService: ReportsService, private route: ActivatedRoute) {
    this.route.params.subscribe((params) => {
      this.reportId = (params.mode == 'nav') ? "140" : "106";
      if(params.instanceid != null){
        this.instanceId = params.instanceid;
      }

      this.loadDashboard();
    });
   }

  ngOnInit() {
  }
  loadDashboard(){
    this.reportService.GenerateMetabaseEmbedToken(this.reportId, this.instanceId).subscribe( data => {
      this.iframeUrl = data;
      const iframe = this.myIframe.nativeElement;
      if (iframe != null) {
        iframe.src = this.iframeUrl;
      }
     }, error => {
 
     });
  }
}
