import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FavoritesService } from 'core';

@Component({
  selector: 'app-project-card-idsuite',
  templateUrl: './project-card-idsuite.component.html',
  styleUrls: ['./project-card-idsuite.component.scss']
})
export class ProjectCardIdsuiteComponent implements OnInit {

  @Input() project: any;
  @Input() actions: [];
  @Input() isFavorite: boolean;
  @Output() action = new EventEmitter();
  @Output() favoriteChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  

  constructor(private favoritesService: FavoritesService) { }

  ngOnInit(): void {
  }

  toggleFavorite() {

    this.isFavorite = !this.isFavorite;
    if (this.isFavorite ) {
      this.favoritesService.setProjectFavorite(this.project.id).subscribe(() => {
        this.favoriteChange.emit(this.isFavorite);
      }, error => console.error(error));
    }
    else {
      this.favoritesService.removeProjectFavorite(this.project.id).subscribe(() => {
        this.favoriteChange.emit(this.isFavorite);
      }, error => console.error(error));
    }
  }

  actionSelected(type) {
    const row = this.project;
    this.action.emit({row, type});
  }

}
