import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'core';

declare var google: any;

@Component({
  selector: 'app-performance-report',
  templateUrl: './performance-report.component.html',
  styleUrls: ['./performance-report.component.scss']
})
export class PerformanceReportComponent implements OnInit {
  iframe: any;
  tab = 'client';
  instanceId;
  vendorUrl = 'https://analytics.zoho.com/open-view/2230517000007149852/02f6996243527df2efa022c24bd336ab?ZOHO_CRITERIA="resolvedreporting"."instanceid"%3D%27#instanceId#%27';
  clientUrl = 'https://analytics.zoho.com/open-view/2230517000007471009/3f0c3fac7c63ec7b6a44de3421f609bd?ZOHO_CRITERIA="resolvedreporting"."instanceid"%3D%27#instanceId#%27';

  @ViewChild('zohoreport') public zohoreport;

  constructor(private auth: AuthService) { }

  ngOnInit() { 
    this.instanceId = this.auth.getUser().instanceId.toLowerCase();
    setTimeout(() => {
      this.showReport('client');
    }, 500);
   }

  showReport(mode) {
    this.tab = mode;
    this.iframe = this.zohoreport.nativeElement;
    this.iframe.attributes.src.value= '';
    if (this.iframe != null && this.iframe.hasAttribute('src') && this.tab === 'client') {
      this.iframe.attributes.src.value = this.clientUrl.replace('#instanceId#',this.instanceId.toUpperCase());
    }
    else if (this.iframe != null && this.iframe.hasAttribute('src') && this.tab === 'vendor') {
      this.iframe.attributes.src.value = this.vendorUrl.replace('#instanceId#',this.instanceId.toUpperCase());
    }
  }
}
