<h3 class="font-weight-bold nav-font18 grey5 px-4" *ngIf="!notesOpen">
  <i class="fak fa-info grey4 mr-2 font-weight-normal"></i>Project Info
</h3>

<ng-container *ngIf="notesOpen">
  <div class="row px-2">
    <div class="col d-flex align-items-center">
      <i class="fak fa-left-arrow grey4 fa-xl cursor-pointer" (click)="toggleNotes()"></i>
      <span class="nav-font14 grey5 ml-4">Information</span>
      <i class="fak fa-right-arrow fa-xs mt-1 grey4 mx-2"></i>
      <span class="nav-font14 nav-secondary font-weight-bold">client notes</span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="bidNumber == ''">
  <div class="my-2 px-3">
    <div *ngIf="!notesOpen">

      <div class="col d-flex justify-content-end px-0 mb-3" *ngIf="isInternal && !isVendorview && !isClientview">
        <span *ngIf="!editMode" (click)="editMode = !editMode" type="button"
          class="shadow-none grey4 nav-font14 font-weight-normal"><i class="mr-2 cursor-pointer fak fa-edit"
            aria-hidden="true"></i>Show Edit Mode </span>
        <span *ngIf="editMode" (click)="editMode = !editMode" type="button"
          class="shadow-none grey4 nav-font14 font-weight-normal"><i class="mr-2 cursor-pointer fa-light fa-eye"
            aria-hidden="true"></i>Show View Mode </span>
      </div>

      <div class="px-2">
        <div class="row nav-font14 my-2">
          <div class="col-5 grey4">Project name</div>
          <div class="col nav-black2" *ngIf="!editMode">{{ _project.projectName }}</div>
        </div>
        <div class="col px-0 mb-3" *ngIf="editMode">
          <input [(ngModel)]="_project.projectName" (change)="updateProjectField('projectName')"
            class="form-control form-control-sm form-control-nav" name="projectName">
        </div>

        <div class="row nav-font14 my-2">
          <div class="col-5 grey4">Primary PM</div>
          <div class="col nav-black2" *ngIf="!editMode">{{ this.projectManager[0]?.name }}</div>
        </div>
        <div class="col px-0 mb-3" *ngIf="editMode">
          <or-type-ahead [(ngModel)]="projectManager" name="projectManager" [labelField]="'name'" [valueField]="'id'"
            [dataCallback]="getUserList" (valueChange)="updateProjectTeam('projectManager', $event)"
            placeholder="Type to search PM" class="form-control-nav" [selectedValues]="projectManager">
          </or-type-ahead>
        </div>

        <div class="row nav-font14 my-2">
          <div class="col-5 grey4">Secondary PM</div>
          <div class="col nav-black2" *ngIf="!editMode">{{ this.projectSecondaryManager[0]?.name }}</div>
        </div>
        <div class="col px-0 mb-3" *ngIf="editMode">
          <or-type-ahead [(ngModel)]="projectSecondaryManager" name="secondaryProjectManager" [labelField]="'name'"
            [valueField]="'id'" [dataCallback]="getUserList"
            (valueChange)="updateProjectTeam('projectSecondaryManager', $event)" placeholder="Type to search PM"
            class="form-control-nav" [selectedValues]="projectSecondaryManager">
          </or-type-ahead>
        </div>
        <div class="row nav-font14 my-2">
          <div class="col-5 grey4">Account owner</div>
          <div class="col nav-black2" *ngIf="!editMode">{{ this.internalAccountOwner[0]?.name }}</div>
        </div>
        <div class="col px-0 mb-3" *ngIf="editMode">
          <or-type-ahead [(ngModel)]="internalAccountOwner" name="accountOwner" [labelField]="'name'"
            [valueField]="'id'" [dataCallback]="getUserList" (valueChange)="updateInternalAccountOwner($event)"
            class="form-control-nav" placeholder="Type to search Account owner" [selectedValues]="internalAccountOwner">
          </or-type-ahead>
        </div>

        <div class="row nav-font14 my-2">
          <div class="col-5 grey4">Programmer</div>
          <div class="col nav-black2" *ngIf="!editMode || !auth.isInternal()">{{ this.programmersNames }}</div>
        </div>
        <div class="col px-0 mb-3" *ngIf="editMode && auth.isInternal()">
          <or-type-ahead [labelField]="'fullName'"
          [valueField]="'id'"
          [multi]="true"
          [dataCallback]="getProgrammerList"
            placeholder="Type name and press enter"
            (valueChange)="updateProgrammers($event)"
            [selectedValues]="programmers">
          </or-type-ahead>
        </div>
        <ng-container *ngIf="!editMode">
          <div class="row nav-font14 my-2">
            <div class="col-5 grey4">Project Type</div>
            <div class="col nav-black2" *ngIf="!editMode">{{ this.projectType.name }}</div>
          </div>
        </ng-container>


        <div class="row nav-font14 my-2">
          <div class="grey4" [ngClass]="{'col-5': !editMode, 'col': editMode}">Respondent Type</div>
          <div class="col nav-black2" *ngIf="!editMode">{{ this._project.category }}</div>
        </div>
        <div class="col px-0 mb-3" *ngIf="editMode">
          <select [(ngModel)]="_project.category" (change)="updateProjectField('category')" name="category"
            class="form-control form-control-sm form-control-nav">
            <option value=""></option>
            <option value="Consumer">Consumer</option>
            <option value="B2B">B2B</option>
            <option value="Healthcare">Healthcare</option>
          </select>
        </div>

        <div class="row nav-font14 my-2">
          <div class="col-5 grey4">Country</div>
          <div class="col nav-black2" *ngIf="!editMode">{{ this._project.countries }}</div>
        </div>
        <div class="col px-0 mb-3" *ngIf="editMode">
          <or-type-ahead [selectedValues]="countries" (valueChange)="updateProjectCountries('countries', $event)"
            [labelField]="'Country'" [multi]="true" [valueField]="'value'" [labelField]="'value'" [showLoading]="false"
            class="form-control-nav" [dataCallback]="getCountryList" placeholder="Type to find a country">
          </or-type-ahead>
        </div>

        <div class="row nav-font14 my-2">
          <div class="grey4" [ngClass]="{'col-5': !editMode, 'col': editMode}">Start date</div>
          <ng-container *ngIf="!editMode">
            <div class="col nav-black2" *ngIf="this._project.projectStartDate == null">
              No start date
            </div>
            <div class="col nav-black2" *ngIf="this._project.projectStartDate != null">
              {{ this._project.projectStartDate | date: 'LLL dd yyyy' }}
            </div>
          </ng-container>
          <ng-container *ngIf="editMode">
            <div class="col nav-black2">
              <input type="text" autocomplete="off" name="projectStartDate" readonly
                [(ngModel)]="_project.projectStartDate" (ngModelChange)="updateProjectField('projectStartDate')"
                bsDatepicker placeholder="Select" #dpStartDate="bsDatepicker"
                [bsConfig]="{ dateInputFormat: 'MMM Do, YYYY', isAnimated: true, isOpen: true, showWeekNumbers:false, customTodayClass: 'todays-date' }"
                class="form-control nav-font14 date d-inline bg-white w-100 px-2">
            </div>
          </ng-container>
        </div>

        <div class="row nav-font14 my-2">
          <div class="grey4" [ngClass]="{'col-5': !editMode, 'col': editMode}">Deadline</div>
          <ng-container *ngIf="!editMode">
            <div class="col nav-black2" *ngIf="this._project.projectEndDate == null">
              No Deadline Date
            </div>
            <div class="col nav-black2" *ngIf="this._project.projectEndDate != null">
              {{ this._project.projectEndDate | date: 'LLL dd yyyy' }}
            </div>
          </ng-container>
          <ng-container *ngIf="editMode">
            <div class="col nav-black2">
              <input type="text" autocomplete="off" name="projectEndDate" readonly [(ngModel)]="_project.projectEndDate"
                (ngModelChange)="updateProjectField('projectEndDate')" bsDatepicker placeholder="Select"
                #dpEndDate="bsDatepicker"
                [bsConfig]="{ dateInputFormat: 'MMM Do, YYYY', isAnimated: true, isOpen: true, showWeekNumbers:false, customTodayClass: 'todays-date' }"
                class="form-control nav-font14 date d-inline bg-white w-100 px-2">
            </div>
          </ng-container>
        </div>

        <div class="row nav-font14 my-2" *ngIf="isInternal && !isVendorview && !isClientview">
          <div class="col-5 grey4">Tags</div>
          <div class="col nav-black2" *ngIf="!editMode">
            <span class="badge badge-pill text-white font-weight-normal mr-1"
              *ngFor="let tag of _project.projectTags">{{ tag.tag }}</span>
          </div>
        </div>
        <div class="col px-0 mb-4" *ngIf="editMode">
          <or-input-multiple (changed)="updateProjectTags($event)" class="form-control-nav"
            placeholder="Type and press enter to create tags" [selectedValuesString]="tags"></or-input-multiple>
        </div>

        <div class="row nav-font14 my-2" *ngIf="hasSurveyUrl && isDecipherUrl && !auth.isVendor() && !isVendorview && !isClientview">
          <div class="col-5 grey4">Decipher Link</div>
          <div class="col nav-accent-blue cursor-pointer" (click)="goToLink(surveyLink)">View here</div>
        </div>

      </div>

      <ng-container *ngIf="isInternal && !isVendorview && !isClientview">
        <div class="mt-5 tabsProjectInfo">
          <tabset>
            <tab>
              <ng-template tabHeading>
                <span class="nav-font14">Client</span>
              </ng-template>

              <div class="px-2">
                <div class="row nav-font14 mb-4 mt-3" *ngIf="this.auth.getUser().access.clientView" (click)="openClientView(_project.clientId)">
                  <div class="px-3 cursor-pointer underline-on-hover">
                    <i class="nav-font14 fa-light fa-eye mr-2 grey4"></i>
                    <span class="nav-font14 nav-accent-blue font-weight-bold">Open client's view</span>
                  </div>
                </div>
                <div class="row nav-font14 my-2">
                  <div class="col-5 grey4">Client</div>
                  <a class="col nav-accent-blue cursor-pointer" [routerLink]="['/clients', _project.clientId]"
                    *ngIf="!editMode">{{ _project.clientName }}</a>
                </div>
                <div class="col px-0 mb-3" *ngIf="editMode">
                  <or-type-ahead [labelField]="'name'" [valueField]="'value'"
                    [selectedValues]="_project.clientId == null ? []: [{value: _project.clientId, name: _project.clientName }]"
                    dropdownMode=true class="form-control-sm form-control-nav w-100 px-0" [dataCallback]="getClients"
                    (valueChange)="updateClient($event)">
                  </or-type-ahead>
                </div>

                <div class="row nav-font14 my-2">
                  <div class="col-5 grey4">Project number</div>
                  <div class="col nav-black2" *ngIf="!editMode">{{ _project.clientPo }}</div>
                </div>
                <div class="col px-0 mb-3" *ngIf="editMode">
                  <input [(ngModel)]="_project.clientPo" (change)="updateProjectField('clientPo')"
                    class="form-control form-control-sm form-control-nav" name="clientPo">
                </div>

                <div class="row nav-font14 my-2">
                  <div class="col-5 grey4">Point of contact</div>
                  <a *ngIf="editMode" (click)="addNewContact(addContact)">
                    <i class="fa fa-plus or-secondary cursor-pointer"></i>
                  </a>
                  <div class="col nav-black2 d-flex align-items-center" *ngIf="!editMode">
                    {{ _project.clientContactName }}
                    <i class="far ml-2 mt-1 grey4 fa fa-envelope cursor-pointer" (click)="copyEmail()"
                      [tooltip]="_project.clientContactEmail" container="body" placement="top"></i>
                  </div>
                </div>
                <div class="col px-0 mb-3" *ngIf="editMode">
                  <select class="form-control form-control-sm form-control-nav" [(ngModel)]="_project.clientContactId"
                    (change)="updateProjectField('clientContactId')" name="clientContactId">
                    <option></option>
                    <option *ngFor="let contact of clientContacts" [value]="contact.id">{{contact.firstName}}
                      {{contact.lastName}}</option>
                  </select>
                </div>
                <div class="row nav-font14 mt-2 mb-4" *ngIf="!editMode">
                  <div class="col-5 grey4">Notes</div>
                  <a class="col nav-accent-blue cursor-pointer" [routerLink]="['/clients', this._project.clientId]"
                    [queryParams]="{ tab: 'Notes'}" *ngIf="!editMode && _project.clientName">View here</a>
                </div>
              </div>
            </tab>

            <tab>
              <ng-template tabHeading>
                <span class="nav-font14">Project folder</span>
              </ng-template>
              <div class="files overflow-x-hidden mt-3">

                <!-- Files -->
                <div class="row px-2">
                  <div class="col">
                    <span class="grey4 nav-font14">Files</span>
                  </div>
                  <div class="col d-flex align-items-center justify-content-end px-0">
                    <or-spinner-button label="Add" #spinnerFile showSpinnerOnCallback=true
                      icon="fas fa-plus fa-md grey4 mr-2" customClass="btn btn-sm nav-accent-blue nav-font-14"
                      (callback)="fileInput.click()">
                    </or-spinner-button>
                    <input #fileInput type="file" (change)="uploadFile($event)" onclick="this.value = null"
                      style="display:none;" />
                  </div>
                </div>
                <div class="mt-3 px-2" *ngIf="assets_Files.length > 0">
                  <div class="row mb-3" *ngFor="let file of assets_Files">
                    <div class="col-8 d-flex align-items-center">
                      <span class="grey5 nav-font12 font-weight-bold cursor-pointer" (click)="downloadAsset(file)">
                        <i class="far fa-file grey4 mr-1"></i>{{file.fileName}}
                      </span>
                    </div>
                    <div class="col-4 d-flex align-items-center justify-content-end">
                      <i class="fak fa-trash grey4 fa-md mx-2 cursor-pointer"
                        (click)="openModal(modalConfirm, file)"></i>
                      <i class="fak fa-download grey4 fa-md cursor-pointer" (click)="downloadAsset(file)"></i>
                    </div>
                  </div>
                </div>

                <!-- Links -->
                <div class="row px-2 mt-4">
                  <div class="col">
                    <span class="grey4 nav-font14">Links</span>
                  </div>
                  <div class="col d-flex align-items-center justify-content-end px-0">
                    <span class="btn btn-sm nav-accent-blue nav-font-14" (click)="openModal(modalAddLink, null)">
                      <i class="fas fa-plus fa-md grey4 mr-2"></i>Add
                    </span>
                  </div>
                </div>
                <div class="mt-3 px-2" *ngIf="assets_Links.length > 0">
                  <div class="row mb-3" *ngFor="let link of assets_Links">
                    <div class="col-8 d-flex align-items-center">
                      <span class="nav-accent-blue nav-font12 font-weight-bold cursor-pointer" (click)="openLink(link)">
                        <i class="far fa-link grey4 mr-1"></i>{{link.fileName}}
                      </span>
                    </div>
                    <div class="col-4 d-flex align-items-center justify-content-end">
                      <i class="fak fa-trash grey4 fa-md mx-2 cursor-pointer"
                        (click)="openModal(modalConfirm, link)"></i>
                      <i class="fak fa-go-to-link grey4 fa-md cursor-pointer" (click)="openLink(link)"></i>
                    </div>
                  </div>
                </div>
                <div class="row px-2 text-center" *ngIf="assets?.length === 0">
                  <div class="col grey4 small">No documents available</div>
                </div>
              </div>

              <ng-template #modalAddLink>
                <form [formGroup]="addLinkForm" (ngSubmit)="addLink()" novalidate>
                  <div class="modal-header d-flex align-items-center justify-content-center">
                    <div class="header-grid w-100 position-relative">
                      <div class="d-flex justify-content-center align-content-center mt-3">
                        <h2 class="nav-secondary mb-0 ml-4 font-weight-bold">Add Link</h2>
                      </div>
                      <div class="d-flex justify-content-end align-content-center position-absolute w-100 sticky-top">
                        <i class="fas fa-close grey4" (click)="closeModal()"></i>
                      </div>
                    </div>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <div class="col">
                        <div class="row mb-3">
                          <div class="col-12 col-md-3">
                            <label class="required nav-font14 grey4 font-weight-bold">Name</label>
                          </div>
                          <div class="col-12 col-md-9 d-flex align-content-center">
                            <input [id]="'name'" formControlName="name"
                              class="form-control form-control-sm form-control-nav" name="name" placeholder="Type Name"
                              required>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-12 col-md-3 pr-0">
                            <label class="required nav-font14 grey4 font-weight-bold">Link</label>
                          </div>
                          <div class="col-12 col-md-9 d-flex align-content-center">
                            <input [id]="'link'" formControlName="link"
                              class="form-control form-control-sm form-control-nav" name="link" placeholder="Type Link"
                              required>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <div class="row d-flex w-100">
                      <div class="col text-right pr-0">
                        <div class="btn btn-sm nav-btn-primary mx-1 shadow-none" (click)="closeModal()">Cancel</div>
                        <button class="btn btn-sm nav-btn-accent shadow-none" type="submit">Save</button>
                      </div>
                    </div>
                  </div>
                </form>
              </ng-template>

              <ng-template #modalConfirm>
                <div class="modal-header d-flex align-items-center justify-content-center">
                  <div class="w-100">
                    <div class="d-flex justify-content-end align-content-center">
                      <i class="fas fa-close grey4" (click)="closeModal()"></i>
                    </div>
                  </div>
                </div>
                <div class="modal-body pt-0 text-center">
                  <h3 class="font-weight-bold grey5">Are you sure you want to delete this asset?</h3>
                  <h5 class="grey5 text-center d-block my-3">
                    <i class="fak fa-info grey4" (click)="closeModal()"></i>
                    This action cannot be undone.
                  </h5>
                </div>
                <div class="modal-footer">
                  <div class="row d-flex w-100">
                    <div class="col text-right pr-0">
                      <button class="btn btn-sm nav-btn-primary mx-1 shadow-none" (click)="closeModal()">Cancel</button>
                      <button class="btn btn-sm nav-btn-accent shadow-none" (click)="confirmDelete()">Delete</button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </tab>

            <tab>
              <ng-template tabHeading>
                <span class="nav-font14">Related projects</span>
              </ng-template>
              <div class="overflow-x-hidden mt-3">
                <ng-container *ngIf="relatedProjects.length === 0">
                  <div class="col grey4 small"> No projects found.</div>
                  <div class="col grey4 small"> Use project tags to relate this project to others.</div>
                </ng-container>
                <ng-container *ngIf="relatedProjects.length > 0">
                  <ng-container *ngFor="let related of relatedProjects; let idx = index">
                    <hr *ngIf="idx > 0">
                    <div class="project mb-4 pr-2 pl-4">
                      <div class="row">
                        <div class="col-7 px-0">
                          <div class="d-block text-truncate">
                            <a [routerLink]="['/projects', related.id]"
                              class="nav-font14 font-weight-bold nav-accent-blue cursor-pointer">{{ related.projectCode
                              }}</a>
                          </div>
                        </div>
                        <div class="col or-dropdown-status pl-0 text-right">
                          <or-dropdown-status [status]="related.projectStatus" [projectId]="related.id"
                            [isPositionRelative]="true"></or-dropdown-status>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col px-0">
                          <span [routerLink]="['/projects', related.id]" class="nav-font14 text-truncate grey4">{{
                            related.projectName }}</span>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </tab>
          </tabset>
        </div>
      </ng-container>

    </div>

    <!-- Code calls and renders the client notes -->
    <div *ngIf="notesOpen">
      <div class="row nav-font14 mt-4">
        <div class="col-5 grey4 font-weight-bold">{{ _project.clientName }}</div>
        <div class="col nav-accent-blue cursor-pointer text-right mr-2" (click)="showClientTab('Notes')">Go to client
          notes</div>
      </div>
      <div class="row grey4 mt-3 nav-font14 preferences">
        <div class="col">

          <ng-container *ngIf="client.notes?.length > 0">
            <ng-container *ngFor="let note of client.notes">
              <div class="card mb-3 note">
                <div class="card-header px-3">
                  <div class="row py-1">
                    <div class="col d-flex">
                      <or-profile-image [url]="note.createdByImage" [name]="note.createdByName"
                        size="medium2"></or-profile-image>
                      <div class="mx-2">
                        <div class=" font-weight-bold nav-font14 grey5">
                          {{note.createdByName}}
                        </div>
                        <div class="grey2 nav-font12">
                          {{note.originalNoteId ? 'Edited' : 'Created'}} {{note.createdOn | date: 'LLL dd @ HH:mm a'}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <ng-container *ngIf="note.tags.length > 0">
                  <div class="col text-left tag-container">
                    <ng-container *ngFor="let tag of note.tags">
                      <span class="mx-1 tagPill" tooltip="{{tagOptions[tag]}}">{{ truncate(tagOptions[tag]) }}</span>
                    </ng-container>
                    <span class="ml-1 mr-1 subjectPill" *ngIf="note.subject" tooltip="{{subjectOptions[note.subject]}}">
                      {{ truncate(subjectOptions[note.subject]) }}</span>
                    <!-- <span class="fak fa-ellipsis-vertical grey5 cursor-pointer mx-2"></span> -->
                  </div>
                </ng-container>


                <div class="card-body indented pb-1 pt-0">
                  <div *ngIf="note.title != null && note.title != ''" class="row py-1">
                    <div class="col-5 d-flex align-items-center font-weight-bold grey5 nav-font16"
                      [innerHTML]="'<p>'+note.title +'</p>'">
                    </div>

                  </div>
                  <div class="row pb-1 pt-2">
                    <div class="col-12 nav-font14 grey5 text-left" [innerHTML]="note.body">
                    </div>
                  </div>

                </div>

              </div>
            </ng-container>
          </ng-container>

        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="bidNumber != ''">
  <div class="my-2 px-3">
    <div *ngIf="!notesOpen">
      <div class="px-2">
        <!-- <ng-container *ngIf="projectType?.showFSSO"> -->
        <ng-container>
          <div class="row nav-font14 my-2">
            <div class="col-5 grey4">Project Type</div>
            <div class="col nav-black2">{{summaryModel.type === 'FullService' ? 'Full Service' : (summaryModel.type ===
              'SampleOnly' ? 'Sample Only' : (summaryModel.type === 'Programming and Hosting Only' ? 'Programming and
              Hosting Only' : '') ) }}</div>
          </div>
        </ng-container>

        <div class="row nav-font14 my-2">
          <div class="col-5 grey4">Country</div>
          <div class="col nav-black2">{{ this.summaryModel.countries }}</div>
        </div>
      </div>

      <ng-container *ngIf="isInternal">
        <div class="mt-5 tabsProjectInfo">
          <tabset>
            <tab>
              <ng-template tabHeading>
                <span class="nav-font14">Client</span>
              </ng-template>

              <div class="px-2 mt-3">
                <div class="row nav-font14 my-2">
                  <div class="col-5 grey4">Client</div>
                  <div class="col nav-accent-blue cursor-pointer" [routerLink]="['/clients', summaryModel.clientId]">{{
                    summaryModel.clientName }}</div>
                </div>

                <div class="row nav-font14 my-2">
                  <div class="col-5 grey4">Project number</div>
                  <div class="col nav-black2">{{ summaryModel.clientPo }}</div>
                </div>

                <div class="row nav-font14 my-2">
                  <div class="col-5 grey4">Point of contact</div>
                  <div class="col nav-black2 d-flex align-items-center">
                    {{ summaryModel.clientContactName }}
                    <i class="far ml-2 mt-1 grey4 fa fa-envelope cursor-pointer" (click)="copyEmail()"
                      [tooltip]="summaryModel.clientContactEmail" container="body" placement="top"></i>
                  </div>
                </div>

                <div class="row nav-font14 my-2">
                  <div class="col-5 grey4">Account owner</div>
                  <div class="col nav-black2">{{ summaryModel.accountOwnerName }}</div>
                </div>
              </div>
            </tab>

            <tab>
              <ng-template tabHeading>
                <span class="nav-font14">Project folder</span>
              </ng-template>

              <div class="files overflow-x-hidden mt-3">
                <!-- Files -->
                <div class="row px-2">
                  <div class="col">
                    <span class="grey5 nav-font14 font-weight-bold">Files</span>
                  </div>

                </div>
                <div class="mt-3 px-2" *ngIf="summaryModel.assets_Files.length > 0">
                  <div class="row mb-3" *ngFor="let file of summaryModel.assets_Files">
                    <div class="row mx-0 w-100">
                      <div class="col-8 d-flex align-items-center mb-1">
                        <span class="grey4 nav-font12 font-weight-bold cursor-pointer" (click)="downloadAsset(file)">
                          <i class="far fa-file grey4 mr-1"></i>{{file.filename}}
                        </span>
                      </div>
                      <div class="col-4 d-flex align-items-center justify-content-end">
                        <i class="fak fa-trash grey4 fa-md mx-2 cursor-pointer"
                          (click)="openModal(modalConfirm, file)"></i>
                        <i class="fak fa-download grey4 fa-md cursor-pointer" (click)="downloadAsset(file)"></i>
                      </div>
                    </div>
                    <div class="row mx-0">
                      <div class="col-12 nav-font12 grey3">
                        <span>{{file.projectCode}}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Links -->
                <div class="row px-2 mt-4">
                  <div class="col">
                    <span class="grey5 font-weight-bold  nav-font14">Links</span>
                  </div>

                </div>
                <div class="mt-3 px-2" *ngIf="summaryModel.assets_Links.length > 0">
                  <div class="row mb-3" *ngFor="let link of summaryModel.assets_Links">
                    <div class="row mx-0 w-100">
                      <div class="col-8 d-flex align-items-center mb-1">
                        <span class="nav-accent-blue font-weight-bold nav-font12 cursor-pointer"
                          (click)="openLink(link)">
                          <i class="far fa-link grey4 mr-1 font-weight-bold"></i>{{link.filename}}
                        </span>
                      </div>
                      <div class="col-4 d-flex align-items-center justify-content-end">
                        <i class="fak fa-trash grey4 fa-md mx-2 cursor-pointer"
                          (click)="openModal(modalConfirm, link)"></i>
                        <i class="fak fa-go-to-link grey4 fa-md cursor-pointer" (click)="openLink(link)"></i>
                      </div>
                    </div>
                    <div class="row mx-0">
                      <div class="col-12 nav-font12 grey3">
                        <span>{{link.projectCode}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row px-2 text-center"
                  *ngIf="summaryModel.assets_Links.length === 0 && summaryModel.assets_Files.length === 0 ">
                  <div class="col grey4 small">No documents available</div>
                </div>
              </div>

              <ng-template #modalConfirm>
                <div class="modal-header d-flex align-items-center justify-content-center">
                  <div class="w-100">
                    <div class="d-flex justify-content-end align-content-center">
                      <i class="fas fa-close grey4" (click)="closeModal()"></i>
                    </div>
                  </div>
                </div>
                <div class="modal-body pt-0 text-center">
                  <h3 class="font-weight-bold grey5">Are you sure you want to delete this asset?</h3>
                  <h5 class="grey5 text-center d-block my-3">
                    <i class="fak fa-info grey4" (click)="closeModal()"></i>
                    This action cannot be undone.
                  </h5>
                </div>
                <div class="modal-footer">
                  <div class="row d-flex w-100">
                    <div class="col text-right pr-0">
                      <button class="btn btn-sm nav-btn-primary mx-1 shadow-none" (click)="closeModal()">Cancel</button>
                      <button class="btn btn-sm nav-btn-accent shadow-none" (click)="confirmDelete()">Delete</button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </tab>

            <tab>
              <ng-template tabHeading>
                <span class="nav-font14">Related projects</span>
              </ng-template>

              <div class="overflow-x-hidden mt-3">
                <ng-container *ngIf="relatedProjects.length === 0">
                  <div class="col grey4 small"> No projects found.</div>
                  <div class="col grey4 small"> Use project tags to relate this project to others.</div>
                </ng-container>
                <ng-container *ngIf="relatedProjects.length > 0">
                  <ng-container *ngFor="let related of relatedProjects; let idx = index">
                    <hr *ngIf="idx > 0">
                    <div class="project mb-4 pr-2 pl-4">
                      <div class="row">
                        <div class="col-7 px-0">
                          <div class="d-block text-truncate">
                            <a [routerLink]="['/projects', related.id]"
                              class="nav-font14 font-weight-bold nav-accent-blue cursor-pointer">{{ related.projectCode
                              }}</a>
                          </div>
                        </div>
                        <div class="col or-dropdown-status pl-0 text-right">
                          <or-dropdown-status [status]="related.projectStatus" [projectId]="related.id"
                            [isPositionRelative]="true"></or-dropdown-status>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col px-0">
                          <span [routerLink]="['/projects', related.id]" class="nav-font14 text-truncate grey4">{{
                            related.projectName }}</span>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </tab>
          </tabset>
        </div>
      </ng-container>
    </div>

    <!-- Code that gets and renders client notes -->
    <div *ngIf="notesOpen">
      <div class="row nav-font14 mt-4">
        <div class="col-5 grey4 font-weight-bold">{{ summaryModel.clientName }}</div>
        <div class="col nav-accent-blue cursor-pointer text-right mr-2"
          [routerLink]="['/clients', summaryModel.clientId]">Go to client page</div>
      </div>
      <div class="row grey4 mt-3 nav-font14 preferences">
        <div class="col">
          <p class="font-weight-bold mb-1">Overview:</p>

          <div>
            <p class="nav-font14 font-weight-normal" [innerHTML]="summaryModel.clientPreference"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #addContact>
  <div class="modal-header d-flex align-items-center justify-content-center"
    style="background-color: white; border-bottom: none">
    <div class="header-grid w-100 position-relative">
      <div class="d-flex justify-content-left align-content-center mt-2 px-2">
        <h3 class="nav-secondary mb-0 font-weight-bold">Add New Contact</h3>
      </div>
      <div class="d-flex justify-content-end align-content-center position-absolute w-100 sticky-top">
        <i class="fas fa-close grey4" (click)="cancelNewContact()"></i>
      </div>
    </div>
  </div>

  <div class="modal-body px-4 py-0">
    <!--    <div class="row my-4 row-upload" id="upload">
      <div class="col-4 d-flex justify-content-center">
        <or-profile-image #img size="large"></or-profile-image>
      </div>
      <div class="col-8">
        <or-upload
          mainMsg="Select profile photo to upload"
          smallMsg="or drag and drop .JPEG or .PNG here"
          [includeProgressBar]=false
          acceptImages=true
          showFileName=false
          directToS3=true
          showFileIcon=false
          class="upload"
          [(ngModel)]="profilePhoto"
          (uploadCompleted)="profilePhotoUploadCompleted(img)"
        >
        </or-upload>
      </div>
    </div> -->
    <div class="row my-4">
      <div class="col-4 grey4 nav-font14">
        Contact First Name
      </div>
      <div class="col-8">
        <input [(ngModel)]="contactFirstName" class="form-control form-control-sm form-control-nav"
          placeholder="First Name">
      </div>
    </div>
    <div class="row my-4">
      <div class="col-4 grey4 nav-font14">
        Contact Last Name
      </div>
      <div class="col-8">
        <input [(ngModel)]="contactLastName" class="form-control form-control-sm form-control-nav"
          placeholder="Last Name">
      </div>
    </div>
    <div class="row my-4">
      <div class="col-4 grey4 nav-font14">
        Company Position
      </div>
      <div class="col-8">
        <input [(ngModel)]="companyPosition" class="form-control form-control-sm form-control-nav"
          placeholder="Position">
      </div>
    </div>
    <div class="row my-4">
      <div class="col-4 grey4 nav-font14">
        Phone number
      </div>
      <div class="col-8">
        <input [(ngModel)]="phoneNumber" class="form-control form-control-sm form-control-nav"
          placeholder="(333) 333-4444">
      </div>
    </div>
    <div class="row my-4">
      <div class="col-4 grey4 nav-font14">
        E-mail
      </div>
      <div class="col-8">
        <input [(ngModel)]="email" class="form-control form-control-sm form-control-nav" placeholder="E-mail">
      </div>
    </div>
  </div>

  <div class="modal-footer pt-0 pb-3" style="border-top: none;">
    <div class="row d-flex w-100">
      <div class="col"></div>
      <div class="col-8 text-right px-3">
        <button type="button" (click)="cancelNewContact()"
          class="btn btn-sm nav-btn-primary mx-2 shadow-none">Cancel</button>
        <button type="submit" (click)="saveNewContact()" class="btn btn-sm nav-btn-accent shadow-none">Add
          Contact</button>
      </div>
    </div>
  </div>
</ng-template>
