import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { AuthService} from 'core';
import { DashboardService } from 'projects/core/src/services/dashboard.service';
import Highcharts from 'highcharts';

@Component({
  selector: 'app-widget-project-by-manager',
  templateUrl: './widget-project-by-manager.component.html',
  styleUrls: ['./widget-project-by-manager.component.scss']
})
export class WidgetProjectByManagerComponent implements OnInit {

  @Input() widget;
  @Output() onremove = new EventEmitter();
  @Output() onupdate = new EventEmitter();

  // Highcharts
  highcharts = Highcharts;
  highchartOptions: Highcharts.Options;

  projectByTypeMyProjects = true;
  settings = {
    myProjects: false,
    timeSpan: null,
    projectStatus: ['Test', 'Soft Launch', 'Paused', 'Full Launch']
  };

  constructor(
    public http: HttpClient,
    public auth: AuthService,
    public dashboardService: DashboardService) { }

  ngOnInit() {
    this.getProjectByManagerData();
  }
  getProjectByManagerData() {
    this.dashboardService.getProjectByManager(this.settings).subscribe({
      next: (data) => this.projectByManagerChart(data),
      error: (e) => console.error(e)
  })
 }
  projectByManagerChart(data) {
      if (data.length <= 0) return;

      let dataCounts = this.buildDataCountsArray(data);

      let managers = data.map(function(project) { return project.manager; });
      let uniqueManagers = [...new Set(managers)].sort();

      const status = data.map(function(project) { return project.status; });
      const header = [...new Set(status)].sort();

      var chartData = [];
       header.forEach((status: string) => {
         let item = {
          name: status,
          data: []
         }
         uniqueManagers.forEach((manager: string) => {
          var count = dataCounts[status][manager] ?? 0;
          item.data.push(count);
        });
        chartData.push(item);
      });

      this.drawHighChartsChart(uniqueManagers, chartData);
  }
  buildDataCountsArray(data) {
    let dataCounts:any = { };
    data.forEach(row => {
      delete row.id;

      if(row.status === '') row.status = 'Blank';
      if(row.manager === '') row.manager = 'Blank';
      if(dataCounts[row.status] == null) dataCounts[row.status] = {};
      dataCounts[row.status][row.manager] = row.count;
    });
    return dataCounts;
  }

  drawHighChartsChart(header, chartData) {
    this.highchartOptions = {
      credits: {
        enabled: false
      },
      chart: {
        type: 'column',
        events: {
          render: function() {
            this.reflow();
          }
        }
      }, title: {
        text: ''
      }, xAxis: {
        categories: header
      }, yAxis: {
        min: 0,
        title: {
          text: 'Projects'
        }, stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: ( // theme
              Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color
            ) || 'gray',
            textOutline: 'none'
          }
        }
      }, legend: {
        align: 'right',
        x: 0,
        verticalAlign: 'top',
        y: 0,
        floating: true,
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      }, tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      }, plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      }, series: chartData
    };
  }

  remove() {
    this.onremove.emit();
  }

  updateWidget() {
    this.onupdate.emit();
  }

  updateTitle(widgetTitle) {
    this.widget.title = widgetTitle;
    this.updateWidget();
  }
}
