<ng-template #metricsOverviewCardTemplate>
<div class="card m-2">
  <div class="card-header">
    <div class="row align-items-center projectHealthBreakdown">
      <div class="col">
        <h3 class="nav-secondary font-weight-bold">Metrics Overview</h3>
      </div>
      <div *ngIf="this.auth.isClient() || isClientview" class="mr-2">
        <div class="or-dropdown-status d-flex align-items-center justify-content-end" [class.test-status]="project.projectStatus == 'Test'">
          <or-dropdown-status [status]="project.projectStatus"  [projectId]="project.id" [isClient]="this.auth.isClient() || isClientview"></or-dropdown-status>
        </div>
      </div>
      <div *ngIf="!modalView" class="nav-font12 grey6 font-weight-bold d-flex justify-content-center projectHealthPill"
        [ngClass]="data.length == 0 ? 'mr-4' : 'mr-3'"
        placement="bottom"
        [tooltip]="project.health_icons.length > 1 ? project.health_icons[1].message : ''">
        <span class="paddingTop2px">Project Health </span>
        <span class="">
          <i class="pl-2 {{project.health_icons[0].icon}} nav-font18 cursor-default"></i>
        </span>

      </div>
      <ng-container *ngIf="!this.auth.isClient() && !isClientview">
        <div *ngIf="data.length > 0 && !modalView" class="text-right">
          <i (click)="previewClientReport()" class="fas fa-eye fa-md grey4 cursor-pointer mr-4" container="body" placement="top" tooltip="View Client Metrics"></i>
        </div>
      </ng-container>
    </div>
  </div>

  <ng-container >
    <div class="card-body pt-1">
      <div class="row">
        <div class="col-7 pl-4" style="padding-right: 6rem;">
          <div class="row mb-1">
            <div class="col grey6 nav-font14">
              Completes
            </div>
            <div class="col grey5 nav-font14 text-right">
              {{ complete }} / {{ fullLaunchQuota }}n
            </div>
          </div>
          <div class="col px-0">
            <div class="rule">
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  [ngClass]="ProgressStyle"
                  [style.width.%]="(complete * 100/fullLaunchQuota )"
                  aria-valuenow=""
                  aria-valuemin="0"
                  aria-valuemax="100">
                </div>
                <div class="vl" [style.right.%]="100 - daysPercentagePast"></div>
              </div>
            </div>
          </div>
          <div class="row mb-2 mt-1">
            <div class="col grey5 nav-font12">
              Current: {{ complete / fullLaunchQuota | percent }}
            </div>
            <div class="px-3 grey4 nav-font12 text-right">
              <div class="grey6">
                <div class="mb-3" *ngIf="data.length == 0 && project.fieldStartDate ">
                  Field begins {{project.fieldStartDate | date: 'MMM d, y'}}
                </div>
                <div *ngIf="data.length > 0"
                  class="id-metrics-end-date d-flex justify-content-end align-items-center fieldEndDateInput"
                  placement="bottom" container="body" [tooltip]="!editFieldEndDate ? 'Double click to change field end date' : ''"
                  (dblclick)="this.editFieldEndDate = true;">
                    {{project.fieldEndDate || editFieldEndDate? 'Field ends' : 'No field end date'}}&nbsp;
                    <span *ngIf="!editFieldEndDate">
                      {{project.fieldEndDate | date: 'MMM d, y'}}
                    </span>
                    <input
                      *ngIf="editFieldEndDate"
                      #fieldEndDatePicker="bsDatepicker"
                      #fieldEndDateInput
                      bsDatepicker
                      appAutoFocusDirective
                      (focus)="fieldEndDatePicker.show()"
                      placeholder="MM/DD/YY"
                      #dp="bsDatepicker"
                      [bsValue]="fieldEndDateFormatted"
                      [bsConfig]="{ dateInputFormat: 'MM/DD/YY', showWeekNumbers:false, adaptivePosition: false, customTodayClass: 'todays-date' }"
                      (bsValueChange)="SaveProjectFieldEndDate($event)"
                      class="fieldEndDateInput form-control form-control-nav form-control-sm nav-font12 grey5 w-50 ml-1"/>
                  </div>
              </div>
              <span *ngIf="data.length > 0" class="grey4">
                {{ project.totalDaysLeft }} day{{ project.totalDaysLeft == 1 ? '' : 's'}} left
              </span>
            </div>
          </div>
          <div class="row chart">
            <div class="col mt-3">
              <p class="nav-font12 nav-black2">Daily Completes</p>
              <p *ngIf="data.length == 0" class=" nav-font12 grey6"> No data available to display in chart </p>
              <div class="chart_performance_reduced" *ngIf="data.length > 0 && !modalView" id="chart_performance_reduced"></div>
              <div class="chart_performance_reduced" *ngIf="data.length > 0 && modalView" id="chart_performance_client_reduced"></div>
            </div>
          </div>
        </div>
        <div class="col-5 information nav-leftborder px-4">
          <div class="row">
            <div class="col">
              <div class="nav-font14 nav-black2 mb-2">
                Incidence Rate (IR)
              </div>
              <div class="rule">
                <or-progress-guage *ngIf="data.length == 0" mode="split" [value]="project.projectSegments[0].projectIR" [expected]="project.projectSegments[0].projectIR"></or-progress-guage>
                <or-progress-guage *ngIf="data.length > 0" mode="split" [value]="(actualIR == 0 ) ? project.projectSegments[0].projectIR : actualIR * 100" [expected]="project.projectSegments[0].projectIR"></or-progress-guage>
              </div>
              <div class="row nav-font12">
                <div class="col grey5">
                  Current: {{ actualIR | percent }}
                </div>
                <div class="col text-right grey4">
                  Expected: {{ (project.projectSegments[0].projectIR || 0) + '%'}}
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <div class="nav-font14 nav-black2 mb-2">
                Length of Interview (LOI)
              </div>
              <div class="rule">
                <or-progress-guage *ngIf="data.length == 0" mode="split" [value]="project.projectSegments[0].projectLOI * 60" [expected]="project.projectSegments[0].projectLOI * 60"></or-progress-guage>
                <or-progress-guage *ngIf="data.length > 0" mode="split" [value]="(medianLoi == 0 ) ? project.projectSegments[0].projectLOI * 60 : medianLoi" [expected]="project.projectSegments[0].projectLOI * 60"></or-progress-guage>
              </div>
              <div class="row nav-font12">
                <div class="col grey5">
                  Current: {{ medianLoi | minuteSeconds }}
                </div>
                <div class="col text-right grey4">
                  Expected: {{(project.projectSegments[0].projectLOI || 0 ) + ' min'}}
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="!this.auth.isClient() && !isClientview">
          
            <div class="row mt-4">
              <div class="col">
                <div class="nav-font14 nav-black2 mb-2">
                  Quality Control (Manual QCs)
                </div>
                <div class="rule">
                  <or-progress-guage *ngIf="data.length > 0" [value]="qcRate * 100" [expected]="project.alertQCRate"></or-progress-guage>
                </div>
                <div class="row nav-font12">
                  <div class="col grey5">
                    Current: {{ qcRate | percent}}
                  </div>
                  <div class="col text-right grey4">
                    Expected: {{(project.alertQCRate || 0 ) + '%'}}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="this.auth.isClient() || isClientview">
            <div class="row mt-4">
              <div class="col">
                <div class="nav-font14 nav-black2 mb-2">
                  Conversion Rate
                </div>
                <div class="rule">
                  <or-progress-guage *ngIf="data.length > 0" [value]="conversionRate" [expected]="expectedRate" [mode]="'target'"></or-progress-guage>
                </div>
                <div class="row nav-font12">
                  <div class="col grey5">
                    Current: {{ conversionRate }}%
                  </div>
                  <div class="col text-right grey4">
                    Expected: {{ expectedRate }}%
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="row mt-4">
            <div class="col">
              <div class="nav-font14 nav-black2 mb-2">
                Drop Out Rate (DOR)
              </div>
              <div class="rule">
                <or-progress-guage *ngIf="data.length > 0" [value]="dropoffRate * 100" [expected]="project.alertDropoutRate"></or-progress-guage>
              </div>
              <div class="row nav-font12">
                <div class="col grey5">
                  Current: {{ dropoffRate | percent }}
                </div>
                <div class="col text-right grey4">
                  Expected: {{(project.alertDropoutRate || 0) + '%'}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
</ng-template>

<ng-container *ngTemplateOutlet="metricsOverviewCardTemplate"></ng-container>

<ng-template #modalClientReport>
  <div class="modal-header">
    <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18">Client View - Metrics</h2>
    <div class="d-flex justify-content-end">
      <i class="fas fa-close grey4" (click)="closeModal()"></i>
    </div>
  </div>
  <div class="modal-body grey4 nav-font14">
    <div class="row" *ngIf="data.length == 0">
      <div class="col or-secondary">
        No Data
      </div>
    </div>
    <ng-container *ngIf="data?.length > 0">
      <h2 class="nav-secondary font-weight-bold">{{project.projectName}}</h2>

      <div class="row">
        <div class="col mt-3">
          <div style="max-width:1200px">
            <ng-template *ngTemplateOutlet="metricsOverviewCardTemplate"></ng-template>
          </div>
          <div>
            <app-project-card-survey-performance-table
            [respondents]="projectPerformance"
            [project]="project"
            [viewStyle]="'modal'">
          </app-project-card-survey-performance-table>
          </div>

        </div>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer">
    <div class="row d-flex w-100" >
      <div class="col text-right pr-0">
        <button class="btn btn-sm nav-btn-primary mx-1 shadow-none" (click)="closeModal()">Close</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #modalFieldTracking>
  <div class="modal-header nav-error pt-2 pr-2">
    <div class="col pt-2 px-0">
        <div class="row d-flex align-items-center mx-0">
          <i class="fak fa-edit-square grey4 nav-font20 mx-2"></i>
          <span class="font-weight-bold nav-font16 nav-secondary">Change field end date</span>
        </div>
    </div>
    <div class="col-2 text-right px-0">
      <i class="far fa-close grey4 nav-font18 p-1 pr-2" (click)="closeFieldTrackingModal()"></i>
    </div>
</div>
<div class="modal-body pt-0">
  <div class="row">
    <div class="col mx-4">
      <h5 class="grey5 d-block mb-3 ml-1">Please select a reason for changing the field end date:</h5>
    </div>
  </div>
  <div class="row">
    <div class="col mx-4">
      <select class="form-control form-control-nav form-control-sm d-block w-75 ml-1" [style]="!fieldTrackingModel?.reasonType ? 'color: #889095 !important' : ''" [(ngModel)]="fieldTrackingModel.reasonType" required>
        <option value="">Select reason</option>
        <option value="Client">Client</option>
        <option value="OR">OR</option>
        <option value="Scope">Scope</option>
        <option value="Other">Other</option>
      </select>
    </div>
  </div>
  <div class="row" *ngIf="fieldTrackingModel?.reasonType === 'Other'">
    <div class="col  mx-4">
      <textarea autosize="false" rows="2" class="form-control form-control-nav form-control-sm d-block w-75 mt-3 ml-1" [(ngModel)]="fieldTrackingModel.reason" placeholder="Type in the other reason" required></textarea>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="row d-flex w-100 align-items-center">
    <div class="col text-right px-0">
      <button class="btn btn-sm nav-btn-primary mx-2 shadow-none font-weight-bold" (click)="closeFieldTrackingModal()">Cancel</button>
      <button class="id-metrics-end-date-save btn btn-sm shadow-none pb-1" [disabled]="!fieldTrackingModel.reasonType" [ngClass]="!fieldTrackingModel.reasonType ? 'nav-btn-disabled' : 'nav-btn-accent'" (click)="!fieldTrackingModel.reasonType ? null : AddProjectFieldTracking('fieldEndDate')">Save</button>
    </div>
  </div>
</div>
</ng-template>
