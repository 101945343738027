<div class="card m-2">
  <div class="card-header">
    <div class="row">
      <div class="col">
        <h3 class="nav-secondary font-weight-bold">Related Projects</h3>
      </div>
    </div>
  </div>
  <div class="card-body scrollbar overflow-y">
    <ng-container *ngIf="relatedProjects.length == 0">
      <div class="or-secondary"> No projects found.</div>
      <div class="or-secondary"> Use project tags to relate this project to others.</div>
    </ng-container>
    <ng-container *ngIf="relatedProjects.length > 0">
      <div class="project mb-4" *ngFor="let related of relatedProjects">
        <div class="row">
          <div class="col">
            <div class="d-block text-truncate">
              <a [routerLink]="['/projects', related.id]" tooltip="{{ related.projectName }}" class="h6 font-weight-bold text-truncate dotted-underline or-title">{{ related.projectName }}</a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4 or-dropdown-status">
            <or-dropdown-status [status]="related.projectStatus" [projectId]="related.id"></or-dropdown-status>
          </div>
          <div class="col-8 text-center">
            <span [routerLink]="['/projects', related.id]" class="font-weight-light text-truncate d-block dotted-underline">{{ related.projectCode }}</span>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col">
            <span class="badge badge-pill badge-light text-uppercase or-primary-bg text-white mr-1" *ngFor="let tag of related.projectTags">{{ tag.tag }}</span>
          </div>
        </div>
      </div>
    </ng-container>

  </div>
</div>
