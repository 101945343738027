import { Component, OnInit, OnChanges, EventEmitter, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { AlertifyService, AuthService, ReportsService } from 'core';
import * as moment from 'moment';
import { ListComponent } from 'projects/core/src/components/list/list.component';

@Component({
  selector: 'app-report-builder',
  templateUrl: './report-builder.component.html',
  styleUrls: ['./report-builder.component.scss']
})
export class ReportBuilderComponent implements OnInit, OnChanges {

  @Output() conditionChanged = new EventEmitter();
  @ViewChild(ListComponent) child: ListComponent;


  tableData = [];
  savedReports = [];
  loadCount = 500;
  loading = false;
  loadingData = false;
  myReport = false;
  step: number = 1;
  filterColumns = ['reportName', 'reportType', 'createdBy', 'lastRun', 'lastModified'];
  filterText = '';

  reportTypes = [
    { name: 'project', displayName: 'Project', icon: 'fas fa-tasks', info: 'Pull information across all projects regardless of client or vendor.' },
    { name: 'client', displayName: 'Client', icon: 'fas fa-user-tie', info: 'Pull information across all projects for an individual client.' },
    { name: 'vendor', displayName: 'Vendor', icon: 'fas fa-handshake', info: 'Pull information across all projects for an individual vendor.' },
    { name: 'summary', displayName: 'Summary', icon: 'fas fa-abacus', info: 'Summary counts for PM, Client or Project.' }
  ];

  layout: any = [
    {
      label: 'Report Name',
      id: 'reportName',
      sortable: true,
      selectable: true
    },{
      label: 'Type',
      id: 'reportType',
      sortable: true
    },{
      label: 'Created By',
      id: 'userName',
      filterable: true,
      sortable: true
    },{
      label: 'Last Run',
      id: 'lastRun',
      sortable: true,
      type: 'datetime'
    },{
      label: 'Last Modified',
      id: 'lastModified',
      sortable: true,
      type: 'datetime'
    },{
      label: 'Actions',
      type: 'actions-inline'
    }
  ];
  actions = [
    {
      label: 'Edit Report',
      type: 'edit',
      icon: 'pen-square'
    }
  ];
  //operators = [{label: 'Below', value: '<'}, {label: 'Above', value: '>'}];

  date: Date = null;
  dateRange: Date[] = [];

  distinctModules = [];
  selectedModules = [];
  headers = ["Modules", "Fields", "Selected"];
  moduleData = [];
  filterableData = [];
  selectedFields = [];
  unselectedFields = [];
  report = {
    id: null,
    instanceId: null,
    reportName: '',
    createdBy: null,
    reportType: '',
    columns: null,
    filters: null,
    isSuspended: false
  };
  reportTableColumns = [];
  reportTableFilterColumns = [];
  filtersList = [];
  filterValue = 0;

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertifyService,
    private reportService: ReportsService) { }

  ngOnInit() {
    this.moduleData = this.getModuleData(this.report.reportType);
    this.distinctModules = [...new Set(this.moduleData.map(item => item.module))];
    this.filterableData = this.moduleData.filter(x => x.filterable);

    var reportId = this.activatedRoute.snapshot.params.id;
    if (reportId === undefined) {
      this.report.createdBy = this.authService.getUser().id;
      this.report.instanceId = this.authService.getUser().instanceId;
      this.myReport = true;
      this.step = 1;
      this.loadReports();
    } else {
      this.step = 3;
      this.getReport(reportId);
    }
  }

  ngOnChanges() { }

  getModuleData(reportType: string, useFilter: boolean = false) {
    let data: any = [
      // Finance Modules
      { parent: 'Finance', filterable: true, type: 'text', id: 'finance_billingPrefix', label: 'Billing Prefix', field: '', projectType: ['project', 'client'] },
      { parent: 'Finance', filterable: false, type: 'text', id: 'finance_clientPo', label: 'Client PO', field: '', projectType: ['project', 'client'] },
      { parent: 'Finance', filterable: true, type: 'date', id: 'finance_invoiceDate', label: 'Invoice Date', field: '', projectType: ['project', 'client'] },
      { parent: 'Finance', filterable: true, type: 'text', id: 'finance_invoiceNumber', label: 'Invoice Number', field: '', projectType: ['project', 'client'] },
      { parent: 'Finance', filterable: false, type: 'number', id: 'finance_totalClient', label: 'Total Client', field: '', projectType: ['project', 'client', 'vendor'] },
      { parent: 'Finance', filterable: false, type: 'number', id: 'finance_totalVendor', label: 'Total Vendor', field: '', projectType: ['project', 'client', 'vendor'] },
      { parent: 'Finance', filterable: false, type: 'number', id: 'finance_margin', label: 'Margin', field: '', projectType: ['project', 'client', 'vendor'] },

     // Performance Modules
      { parent: 'Performance', filterable: false, type: 'number', id: 'performance_actualIr', label: 'Actual IR', field: '', projectType: ['project', 'client', 'vendor'] },
      { parent: 'Performance', filterable: false, type: 'text', id: 'performance_actualLoi', label: 'Actual LOI', field: '', projectType: ['project', 'client'] },
      { parent: 'Performance', filterable: false, type: 'number', id: 'performance_cleanIdBlocks', label: 'CleanID Blocks', field: '', projectType: ['project', 'client', 'vendor'] },
      { parent: 'Performance', filterable: false, type: 'number', id: 'performance_completes', label: 'Completes', field: '', projectType: ['project', 'client', 'vendor'] },
      { parent: 'Performance', filterable: false, type: 'number', id: 'performance_convRate', label: 'Conv. Rate', field: '', projectType: ['project'] },
      { parent: 'Performance', filterable: false, type: 'number', id: 'performance_dor', label: 'DOR', field: '', projectType: ['project', 'client', 'vendor'] },
      { parent: 'Performance', filterable: false, type: 'number', id: 'performance_duplicateBlocks', label: 'Duplicate Blocks', field: '', projectType: ['project', 'client', 'vendor'] },
      { parent: 'Performance', filterable: false, type: 'number', id: 'performance_navEntry', label: 'NAV Entry', field: '', projectType: ['project'] },
      { parent: 'Performance', filterable: false, type: 'number', id: 'performance_navOqs', label: 'NAV OQs', field: '', projectType: ['project'] },
      { parent: 'Performance', filterable: false, type: 'number', id: 'performance_overQuotas', label: 'Overquotas', field: '', projectType: ['project', 'client', 'vendor'] },
      { parent: 'Performance', filterable: false, type: 'number', id: 'performance_qc', label: 'QC', field: '', projectType: ['project', 'client', 'vendor'] },
      { parent: 'Performance', filterable: false, type: 'number', id: 'performance_starts', label: 'Starts', field: '', projectType: ['project', 'client', 'vendor'] },
      { parent: 'Performance', filterable: false, type: 'number', id: 'performance_terminates', label: 'Terminates', field: '', projectType: ['project', 'client', 'vendor'] },

      // Project Modules
      { parent: 'Project', filterable: false, type: 'text', id: 'project_accountOwner', label: 'Account Owner', field: '', projectType: ['project', 'client'] },
      { parent: 'Project', filterable: true, type: 'text', id: 'project_bidNumber', label: 'Bid Number', field: '', projectType: ['project', 'client', 'vendor'] },
      { parent: 'Project', filterable: true, type: 'text', id: 'project_category', label: 'Project Category', field: '', projectType: ['project', 'client', 'vendor'] },
      { parent: 'Project', filterable: true, type: 'text', id: 'project_client', label: 'Client', field: '', projectType: ['project'] },
      { parent: 'Project', filterable: true, type: 'text', id: 'project_clientContact', label: 'Client Contact', field: '', projectType: ['project', 'client'] },
      { parent: 'Project', filterable: true, type: 'text', id: 'project_country', label: 'Country', field: '', projectType: ['project', 'client', 'vendor'] },
      { parent: 'Project', filterable: true, type: 'date', id: 'project_dateLastActivity', label: 'Date of last activity', field: '', projectType: ['project'] },
      { parent: 'Project', filterable: true, type: 'date', id: 'project_endDate', label: 'Project End Date', field: '', projectType: ['project'] },
      { parent: 'Project', filterable: true, type: 'text', id: 'project_projectId', label: 'Project ID', field: '', projectType: ['project', 'client', 'vendor'] },
      { parent: 'Project', filterable: true, type: 'text', id: 'project_projectCode', label: 'Project Code', field: '', projectType: ['project', 'client', 'vendor'] },
      { parent: 'Project', filterable: true, type: 'text', id: 'project_projectManager', label: 'Project Manager', field: '', projectType: ['project', 'client', 'vendor'] },
      { parent: 'Project', filterable: true, type: 'text', id: 'project_projectName', label: 'Project Name', field: '', projectType: ['project', 'client', 'vendor'] },
      { parent: 'Project', filterable: true, type: 'date', id: 'project_fieldStartDate', label: 'Field Start Date', field: '', projectType: ['project'] },
      { parent: 'Project', filterable: true, type: 'text', id: 'project_secondaryProjectManager', label: 'Secondary Project Manager', field: '', projectType: ['project', 'client', 'vendor'] },
      { parent: 'Project', filterable: false, type: 'number', id: 'project_sellCpi', label: 'Sell CPI', field: '', projectType: ['project', 'client', 'vendor'] },
      { parent: 'Project', filterable: false, type: 'number', id: 'project_softLaunchTarget', label: 'Soft Launch Target', field: '', projectType: ['project'] },
      { parent: 'Project', filterable: true, type: 'status', readonly: true, id: 'project_status', label: 'Project Status', field: '', projectType: ['project', 'client', 'vendor'] },
      { parent: 'Project', filterable: false, type: 'text', id: 'project_tags', label: 'Tags', field: '', projectType: ['project', 'client', 'vendor'] },
      { parent: 'Project', filterable: false, type: 'number', id: 'project_targetCompletes', label: 'Target Completes', field: '', projectType: ['project', 'client', 'vendor'] },
      { parent: 'Project', filterable: false, type: 'number', id: 'project_targetIr', label: 'Target IR', field: '', projectType: ['project', 'client', 'vendor'] },
      { parent: 'Project', filterable: false, type: 'number', id: 'project_targetLoi', label: 'Target LOI', field: '', projectType: ['project', 'client', 'vendor'] },
      { parent: 'Project', filterable: false, type: 'number', id: 'project_stage', label: 'Current Project Stage', field: '', projectType: ['project'] },

      //Client Module
      { parent: 'Client', filterable: true, type: 'text', id: 'project_client', label: 'Client Name', field: '', projectType: ['client'] },

      // Vendor Modules
      { parent: 'Vendor', filterable: true, type: 'text', id: 'vendor_name', label: 'Vendor Name', field: '', projectType: ['vendor'] },
      { parent: 'Vendor', filterable: true, type: 'text', id: 'vendor_targetCompletes', label: 'Vendor Target Completes', field: '', projectType: ['vendor'] },
      { parent: 'Vendor', filterable: false, type: 'number', id: 'vendor_buyCpi', label: 'Buy CPI', field: '', projectType: ['vendor'] },
      { parent: 'Vendor', filterable: false, type: 'text', id: 'vendor_projectStatus', label: 'Vendor Project Status', field: '', projectType: ['vendor'] },

      // Vendor Satisfaction Modules
      { parent: 'Vendor Satisfaction', filterable: true, type: 'number', id: 'vendorSatisfaction_helpfulWithProblemSolving', label: 'Helpful with problem solving', field: '', projectType: ['vendor'] },
      { parent: 'Vendor Satisfaction', filterable: true, type: 'number', id: 'vendorSatisfaction_timelinessOfResponses', label: 'Timeliness of responses', field: '', projectType: ['vendor'] },
      { parent: 'Vendor Satisfaction', filterable: true, type: 'number', id: 'vendorSatisfaction_accuracyOfFeasibility', label: 'Accuracy of feasibility', field: '', projectType: ['vendor'] },
      { parent: 'Vendor Satisfaction', filterable: true, type: 'number', id: 'vendorSatisfaction_followDailyDirection', label: 'Follow daily direction', field: '', projectType: ['vendor'] },


       // Summary Counts 
       { parent: 'Summary', filterable: true, type: 'text', id: 'summary_projectManager', label: 'Project Manager', field: '', projectType: ['summary'] },
       { parent: 'Summary', type: 'text', id: 'summary_clientName', label: 'ClientName', field: '', projectType: ['summary'] },
       { parent: 'Summary', type: 'number', id: 'summary_projectCount', label: 'Project Count', field: '', projectType: ['summary'] },
       { parent: 'Summary', type: 'text', id: 'summary_projectStage', label: 'Project Stage', field: '', projectType: ['summary'] }, 
       { parent: 'Summary', type: 'number', id: 'summary_completeTaskCount', label: 'Completed Task Count', field: '', projectType: ['summary'] },
       { parent: 'Summary', type: 'number', id: 'summary_openTaskCount', label: 'Open Task Count', field: '', projectType: ['summary'] },
       { parent: 'Summary', type: 'number', id: 'summary_alertSetCount', label: 'Alert Set Count', field: '', projectType: ['summary'] },
       { parent: 'Summary', type: 'number', id: 'summary_alertTriggeredCount', label: 'Alert Triggered Count', field: '', projectType: ['summary'] },
       

       
      //LINE ITEMS AND VENDOR MARGIN
      // { parent: 'Vendor', filterable: true, type: 'text', id: 'vendor_description', label: 'Description', field: '', projectType: ['vendor'] },
      // { parent: 'Vendor', filterable: false, type: 'text', id: 'vendor_lineTotal', label: 'Line Total', field: '', projectType: ['vendor'] },
      // { parent: 'Vendor', filterable: true, type: 'text', id: 'vendor_product', label: 'Product', field: '', projectType: ['vendor'] },
      // { parent: 'Vendor', filterable: false, type: 'text', id: 'vendor_qty', label: 'Qty', field: '', projectType: ['vendor'] },
      // { parent: 'Vendor', filterable: false, type: 'text', id: 'vendor_unitPrice', label: 'Unit Price', field: '', projectType: ['vendor'] },
      // { parent: 'Vendor', filterable: false, type: 'text', id: 'vendor_total', label: 'Vendor Total', field: '', projectType: ['vendor'] },

      /*{ parent: 'Finance', filterable: true, type: 'text', id: 'finance_description', label: 'Description', field: '', projectType: ['project', 'client'] },
      { parent: 'Finance', filterable: true, type: 'text', id: 'finance_category', label: 'Finance Category', field: '', projectType: ['project', 'client'] },
      { parent: 'Finance', filterable: true, type: 'text', id: 'finance_product', label: 'Product', field: '', projectType: ['project', 'client'] },
      { parent: 'Finance', filterable: false, type: 'number', id: 'finance_qty', label: 'Qty', field: '', projectType: ['project', 'client'] },
      { parent: 'Finance', filterable: false, type: 'text', id: 'finance_unitCost', label: 'Unit Cost', field: '', projectType: ['project', 'client'] },
      { parent: 'Finance', filterable: false, type: 'number', id: 'finance_lineTotal', label: 'Line Total', field: '', projectType: ['project', 'client'] },
      { parent: 'Finance', filterable: false, type: 'text', id: 'finance_vendorMargin', label: 'Vendor Margin', field: '', projectType: ['vendor'] },
      */

      ];
    data.sort( (a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))

    data.forEach(x => { x.name = x.label; });

    if (reportType === undefined || reportType === null || reportType === "") return data;

    let typeList = data.filter(x => x.projectType.includes(reportType));
    if (useFilter) typeList = typeList.filter(x => x.filterable);

    return typeList;
  }

  handleSelectedFields(selected) {
    this.report.columns = selected;
  }

  handleAction(action) {
    if (action.type === 'edit') {
      this.router.navigateByUrl('/reports/builder/' + action.row.id);
    }
  }

  changeFilter(evt) {
    let filter = {
      id: evt.id,
      label: evt.label,
      parent: evt.parent,
      type: evt.type,
      options: {}
    };
    this.filtersList.push(filter);
    this.filterableData = this.filterableData.filter(x => x.id !== evt.id);
    this.report.filters = this.filtersList;
  }

  removeFilter(filterId) {
    this.filtersList = this.filtersList.filter(x => x.id !== filterId);
    this.filterableData.push(this.moduleData.find(x => x.id === filterId));
    this.report.filters = this.filtersList;
    this.getReportPreview();
  }

  getReport(reportId) {
    this.loading = true;
    this.reportService.GetReportConfig(reportId).subscribe(data => {
      this.loading = false;
      // parse JSON blobs
      data.columns = JSON.parse(data.columns);
      data.filters = JSON.parse(data.filters);

      // populate list box columns
      this.report = data;
      this.myReport = (this.report.createdBy ==  this.authService.getUser().id);
      this.report.instanceId = this.authService.getUser().instanceId;
      this.distinctModules = [...new Set(this.moduleData.map(item => item.module))];
      this.unselectedFields = this.moduleData;
      this.selectedFields = data.columns;
      // remove selected fields from unselected column
      for (let field of this.selectedFields)
        this.unselectedFields = this.unselectedFields.filter(x => x.id !== field.id);

      // load all reports for step 1
      this.loadReports();
      this.reportTableColumns = this.report.columns;
      this.reportTableFilterColumns = this.report.columns.map(x => x.id);

      // populate filters for step 3
      this.moduleData = this.getModuleData(this.report.reportType, true);
      this.filterableData = this.moduleData;
      data.filters.forEach(x => { this.handleFilterOptions(x.options); });
      this.filtersList = data.filters;
      this.filtersList.forEach(x => {
        this.filterableData = this.filterableData.filter(y => y.id !== x.id);
      });
      this.getReportPreview();
    });
  }

  loadReports() {
    this.loading = true;
    this.reportService.GetSavedReportConfigs().subscribe(data => {
      this.loading = false;
       this.savedReports = data;
    });
  }

  manageModuleFields() {
    let list = [];
    for (let index in this.moduleData) {
      if (this.selectedModules.includes(this.moduleData[index].module)) {
        if(this.selectedFields.filter(x => x.id === this.moduleData[index].id).length <= 0) {
          list.push(this.moduleData[index])
        }
      }
    }
    this.unselectedFields = list;
  }

  handleFilterOptions(options) {
    if (options.hasOwnProperty('date')) options.date = new Date(options.date);
    if (options.hasOwnProperty('dateRange')) options.dateRange = [new Date(options.dateRange[0]), new Date(options.dateRange[1])];
  }

  back() {
    this.step -= 1;
    if (this.step == 0) this.step = 1;

    switch (this.step) {
      case 1:
        this.step1();
        break;
      case 2:
        this.step2(this.report.reportType);
        break;
      case 3:
        this.step3();
        break;
      case 4:
        this.step4();
        break;
    }
  }

  next() {
    this.step += 1;
    if (this.step > 4) this.step = 4;

    switch (this.step) {
      case 1:
        this.step1();
        break;
      case 2:
        this.step2(this.report.reportType);
        break;
      case 3:
        this.step3();
        break;
      case 4:
        this.step4();
        break;
    }
  }

  step1() {
    this.step = 1;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate(['/reports/builder'])
    );
  }
  createNewReport(reportType: string){
    if(reportType == 'summary'){
      let dateFilter = {
        id: 'summary_date',
        label: 'Summary Date',
        parent: 'Summary',
        type: 'date',
        options: { operator : 'month'}
      };
      let pmFilter = {
        id: 'summary_user',
        label: 'Project Manager/User',
        parent: 'Summary',
        type: 'text',
        options: { operator : 'contains' }
      };
      this.filtersList= [dateFilter, pmFilter];
    }
    this.step2(reportType);
  }
  step2(reportType: string) {
    switch (reportType) {
      case "project":
      case "client":
      case "vendor":
      case "summary":
        this.report.reportType = reportType;
        if (this.report.id === null) this.report.id = uuidv4();
        this.moduleData = this.getModuleData(reportType);
        this.step = 2;
        break;
      default: this.step = 1; break;
    }
  }
  step3() {
    this.step = 3;
    this.reportTableColumns = this.report.columns
    this.reportTableFilterColumns = this.report.columns.map(x => x.id);

    this.getReportPreview();
  }
  step4() {
    this.step = 4;
    this.tableData = null;
    this.report.filters = this.filtersList;
    if (this.report.filters === null) this.report.filters = { dateRange: [] };
    if (this.dateRange !== undefined && this.dateRange !== null) this.report.filters.dateRange = this.dateRange ?? null;
    this.reportTableColumns = this.report.columns;
    this.reportTableFilterColumns = this.report.columns.map(x => x.id);

    this.getReportData();
  }

  getReportPreview() {
    this.fixFilters();
    this.loadingData = true;
    this.reportService.GetReportData(this.report, 10).subscribe(result => {
      this.tableData = result.data[0];
      this.loadingData = false;
    }, err => {
      console.log(err);
      this.alertService.error("Could not get the report preview. An error occurred!")
    });

  }
  getReportData() {
    this.fixFilters();
    this.loadingData = true;
    this.reportService.GetReportData(this.report).subscribe(result => {
      this.tableData = result.data[0];
      this.loadingData = false;
    }, err => {
      console.log(err);
      this.alertService.error("Could not get the report data. An error occurred!")
    });

  }
  fixFilters() {
    if (this.report.filters == null) this.report.filters = [];

    this.report.filters.forEach(element => {
      if (element.options.operator === undefined || element.options.operator === null)
        element.options.operator = "equals";
    });
  }

  exportReport() {
    if (this.tableData.length <= 0) {
      this.alertService.warning("Could not export as no data received!")
    } else {
      let columns = this.report.columns;
      let filters = this.report.filters;
      this.report.columns = JSON.stringify(this.report.columns);
      this.report.filters = JSON.stringify(this.report.filters);
      this.reportService.ExportReportData(this.report).subscribe(_ => {}); // Update last run if config exists
      this.report.columns = columns;
      this.report.filters = filters;

      let date = new Date();
      this.child.exportFilename = this.report.reportName + '-' + moment(date).format('YYYYMMDD HHmmss') + '.csv';
      this.child.exportTable();
    }
  }

  saveReport() {
    if (this.report.reportName == null || this.report.reportName == '' ) {
      this.alertService.error("Report Name is mandatory!");
      return;
    } else if (this.report.reportName.length <= 4) {
      this.alertService.warning("Report must have a name greater than 4 characters to be saved!")
    } else {
      let columns = this.report.columns;
      let filters = this.report.filters;
      if (this.report.id === null || this.myReport == false) {
        this.report.createdBy = this.authService.getUser().id;
        this.report.id = uuidv4();
      }
      this.report.columns = JSON.stringify(this.report.columns);
      this.report.filters = JSON.stringify(this.report.filters);

      this.reportService.SaveReportConfigs(this.report).subscribe(data => {
        this.report.columns = columns;
        this.report.filters = filters;
        this.alertService.success("Report config saved successfully");
      }, err => {
        console.log(err);
        this.alertService.error("Could not save the report config. An error occurred!")
      });
      this.report.columns = columns;
      this.report.filters = filters;
    }
  }
}
