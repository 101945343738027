<div class="modal-header text-white">
  <p class="h4 position-absolute">Project Status - Vendors</p>
  <button type="button" class="close pull-right text-white" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col d-flex align-items-center" *ngIf="loading">
      <div class="loading"></div>
    </div>
    <div class="col" *ngIf="!loading">
      <div class="alert alert-primary" role="alert">
        Total Clicks: {{ projectStats.totalClicks }}
      </div>
      <div class="alert alert-secondary" role="alert">
        Total Completes: {{ projectStats.totalCompletes }}
      </div>
      <!-- <button (click)="changeViewMode('edit')" class="btn m-1 btn-sm btn-outline-secondary float-right" type="button">Edit Mode</button>
      <button (click)="download()" class="btn m-1 btn-sm btn-outline-secondary  float-right" type="button">Download</button> -->
    </div>
  </div>
</div>
