<div class="nav-content container-fluid">
  <div class="row">
    <div class="col">
      <p class="display-6 pr-3">
        NavigatOR is a workflow management system built by and for Professional Market Researchers. 
        It manages process, timelines, teams and improves data quality while reducing labor spent on low-value tasks. 
        Our features and roadmap are crafted to tackle the tough, recurring problems that slow down researchers’ businesses. 
        Welcome to a new way of working.
      </p>
      <div class="row py-2">
        <div class="col p-3">
          <div class="text-center">
            <img src="https://www.opinionroute.com/wp-content/uploads/2022/05/laptop-technology.png" width="500" class="img-fluid">
          </div>
        </div>
        <div class="col p-4 mt-4">
          <div class="d-block">
            <div class="mb-3">
              <img src="https://assets-global.website-files.com/5dc0621d9bf13e971a5a0a82/5df954249910b7c081f7df37_Logos1-03.png" width="160" class="img-fluid">
              <h3 class="d-inline"> is:</h3>
            </div>
            <div class="features">
              <div>
                <i class="fas fa-chevron-right"></i>
                <span class="font-weight-light"> A project hub that tracks the details</span>
              </div>
              <div>
                <i class="fas fa-chevron-right"></i>
                <span class="font-weight-light"> Easy Dashboards</span>
              </div>
              <div>
                <i class="fas fa-chevron-right"></i>
                <span class="font-weight-light"> Automated Project Alerts and reminders</span>
              </div>
              <div>
                <i class="fas fa-chevron-right"></i>
                <span class="font-weight-light"> Real-time budget management</span>
              </div>
              <div>
                <i class="fas fa-chevron-right"></i>
                <span class="font-weight-light"> Built-in Data Quality</span>
              </div>
              <div>
                <i class="fas fa-chevron-right"></i>
                <span class="font-weight-light"> Integrated Vendor Connections</span>
              </div>
              <div>
                <i class="fas fa-chevron-right"></i>
                <span class="font-weight-light"> Automated Project Closure</span>
              </div>
              <div>
                <i class="fas fa-chevron-right"></i>
                <span class="font-weight-light"> Top-of-the-line Data Security infrastructure</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-5 px-5">
      <h3>Release Notes</h3>
      <div class="release-notes mt-5">
  <!--release-note-patch - for pathc releases-->
  <section class="release-note release-note-minor">
    <div class="release-tag-wrapper">
      <h3 id="2.34.0">
        <span href="#2.34.0" class="release-tag or-primary-bg text-white">
          <span class="release-version">2.34.0</span>
        </span>
      </h3>
    </div>
    <div class="release-info">
      <div class="release-header">
        <h3>
          <span class="release-version">2.34.0</span>
        </h3>
        <span class="release-badge release-badge-latest  bg-primary">Latest Version</span>
      </div>
      <div class="release-published">
        <h4>December 2022</h4>
      </div>
      <div>
        <ul>
          <li><strong>CleanID:</strong> Tamper and duplication updates.</li>
          <li><strong>Alerts:</strong> Notification when assigned Secondary PM.</li>
         </ul>
      </div>
    </div>
  </section>
  <section class="release-note release-note-minor">
    <div class="release-tag-wrapper">
      <h3 id="2.33.0">
        <span href="#2.33.0" class="release-tag or-primary-bg text-white">
          <span class="release-version">2.33.0</span>
        </span>
      </h3>
    </div>
    <div class="release-info">
      <div class="release-header">
        <h3>
          <span class="release-version">2.33.0</span>
        </h3>
        <span class="release-badge  bg-secondary">minor</span>
      </div>
      <div class="release-published">
        <h4>November 2022</h4>
      </div>
      <div>
        <ul>
          <li><strong>ResponseID:</strong> Bathc Upload redesign.</li>
          <li><strong>Public API:</strong> Create Project.</li>
        </ul>
      </div>
    </div>
  </section>
  <section class="release-note release-note-minor">
    <div class="release-tag-wrapper">
      <h3 id="2.32.0">
        <span href="#2.32.0" class="release-tag or-primary-bg text-white">
          <span class="release-version">2.32.0</span>
        </span>
      </h3>
    </div>
    <div class="release-info">
      <div class="release-header">
        <h3>
          <span class="release-version">2.32.0</span>
        </h3>
        <span class="release-badge  bg-secondary">minor</span>
      </div>
      <div class="release-published">
        <h4>October 2022</h4>
      </div>
      <div>
        <ul>
          <li><strong>Dashboard:</strong> Customize your own dashboard.</li>
          <li><strong>Support:</strong> Integrated Support system.</li>
        </ul>
      </div>
    </div>
  </section>
  <section class="release-note release-note-minor">
    <div class="release-tag-wrapper">
      <h3 id="2.31.0">
        <span href="#2.31.0" class="release-tag or-primary-bg text-white">
          <span class="release-version">2.31.0</span>
        </span>
      </h3>
    </div>
    <div class="release-info">
      <div class="release-header">
        <h3>
          <span class="release-version">2.31.0</span>
        </h3>
        <span class="release-badge  bg-secondary">minor</span>
      </div>
      <div class="release-published">
        <h4>September 2022</h4>
      </div>
      <div>
        <ul>
          <li><strong>Reports:</strong> Vendor level metrics in custom reports.</li>
          <li><strong>Project List:</strong> Include financial fields in list.</li>
          <li><strong>Export:</strong> New CleanID export direct from project downloads.</li>
        </ul>
      </div>
    </div>
  </section>
  <section class="release-note release-note-minor">
    <div class="release-tag-wrapper">
      <h3 id="2.30.0">
        <span href="#2.30.0" class="release-tag or-primary-bg text-white">
          <span class="release-version">2.30.0</span>
        </span>
      </h3>
    </div>
    <div class="release-info">
      <div class="release-header">
        <h3>
          <span class="release-version">2.30.0</span>
        </h3>
        <span class="release-badge  bg-secondary">minor</span>
      </div>
      <div class="release-published">
        <h4>August 2022</h4>
      </div>
      <div>
        <ul>
          <li><strong>Closure:</strong> Vendor Reset option.</li>
          <li><strong>Client and Vendor Pages:</strong> Customize columns in project list.</li>
          <li><strong>Quotas:</strong> New Quota setup section.</li>
        </ul>
      </div>
    </div>
  </section>
  <section class="release-note release-note-minor">
    <div class="release-tag-wrapper">
      <h3 id="2.29.0">
        <span href="#2.29.0" class="release-tag or-primary-bg text-white">
          <span class="release-version">2.29.0</span>
        </span>
      </h3>
    </div>
    <div class="release-info">
      <div class="release-header">
        <h3>
          <span class="release-version">2.29.0</span>
        </h3>
        <span class="release-badge  bg-secondary">minor</span>
      </div>
      <div class="release-published">
        <h4>July 2022</h4>
      </div>
      <div>
        <ul>
          <li><strong>Client Portal:</strong> Client specific view of the console.</li>
          <li><strong>Custom Reports:</strong> Build your own personal custom reports.</li>
        </ul>
      </div>
    </div>
  </section>
  <section class="release-note release-note-minor">
    <div class="release-tag-wrapper">
      <h3 id="2.28.0">
        <span href="#2.28.0" class="release-tag or-primary-bg text-white">
          <span class="release-version">2.28.0</span>
        </span>
      </h3>
    </div>
    <div class="release-info">
      <div class="release-header">
        <h3>
          <span class="release-version">2.28.0</span>
        </h3>
        <span class="release-badge  bg-secondary">minor</span>
      </div>
      <div class="release-published">
        <h4>June 2022</h4>
      </div>
      <div>
        <ul>
          <li><strong>ResponseID:</strong> Batch upload summary and audit trail.</li>
          <li><strong>Project Health Config:</strong> Configure settings used to score project health.</li>
        </ul>
      </div>
    </div>
  </section>
  <section class="release-note release-note-minor">
    <div class="release-tag-wrapper">
      <h3 id="2.27.0">
        <span href="#2.27.0" class="release-tag or-primary-bg text-white">
          <span class="release-version">2.27.0</span>
        </span>
      </h3>
    </div>
    <div class="release-info">
      <div class="release-header">
        <h3>
          <span class="release-version">2.27.0</span>
        </h3>
        <span class="release-badge  bg-secondary">minor</span>
      </div>
      <div class="release-published">
        <h4>May 2022</h4>
      </div>
      <div>
        <ul>
          <li><strong>CleanID V3:</strong> Upgrade to new version of CleanID engine.</li>
          <li><strong>SMS Alerts:</strong> Subscribe to alerts via SMS/Text message.</li>
          <li><strong>Adding Vendor UI Updates:</strong> Simplified UI on project setup page.</li>
          <li><strong>Finance Card when Invoiced:</strong> Card updated to show invoiced figures when project is complete.</li>
        </ul>
      </div>
    </div>
  </section>
  <section class="release-note release-note-minor">
    <div class="release-tag-wrapper">
      <h3 id="2.26.0">
        <span href="#2.26.0" class="release-tag or-primary-bg text-white">
          <span class="release-version">2.26.0</span>
        </span>
      </h3>
    </div>
    <div class="release-info">
      <div class="release-header">
        <h3>
          <span class="release-version">2.26.0</span>
        </h3>
        <span class="release-badge  bg-secondary">minor</span>
      </div>
      <div class="release-published">
        <h4>April 2022</h4>
      </div>
      <div>
        <ul>
          <li><strong>User Details Updates:</strong> Removed deprecated fields and added Job Title.</li>
          <li><strong>Default Redirects:</strong> When no redirects set up for a vendor we redirect to our default pages.</li>
          <li><strong>New User Setup:</strong> Invite email sent to new user instead of setting up password in admin console.</li>
        </ul>
      </div>
    </div>
  </section>
  <section class="release-note release-note-minor">
    <div class="release-tag-wrapper">
      <h3 id="2.25.0">
        <span href="#2.25.0" class="release-tag or-primary-bg text-white">
          <span class="release-version">2.25.0</span>
        </span>
      </h3>
    </div>
    <div class="release-info">
      <div class="release-header">
        <h3>
          <span class="release-version">2.25.0</span>
        </h3>
        <span class="release-badge  bg-secondary">minor</span>
      </div>
      <div class="release-published">
        <h4>March 2022</h4>
      </div>
      <div>
        <ul>
          <li><strong>IDSuite Card:</strong> New card which combines CleandID and ResponseID.</li>
          <li><strong>Card Role Permissions:</strong> Make cards visible to obly certain roles.</li>
          <li><strong>Client and Vendor Users:</strong> Flag user as a client or vendor to give access to their data on the platform.</li>
          <li><strong>Alert Audit Log:</strong> Project notification alert infomration added to an audit log.</li>
          <li><strong>Block Logon Option:</strong> Ability to create users who can not log on but can be used for notfications etc.</li>

        </ul>
      </div>
    </div>
  </section>
  <section class="release-note release-note-minor">
    <div class="release-tag-wrapper">
      <h3 id="2.24.0">
        <span href="#2.24.0" class="release-tag or-primary-bg text-white">
          <span class="release-version">2.24.0</span>
        </span>
      </h3>
    </div>
    <div class="release-info">
      <div class="release-header">
        <h3>
          <span class="release-version">2.24.0</span>
        </h3>
        <span class="release-badge  bg-secondary">minor</span>
      </div>
      <div class="release-published">
        <h4>February 2022</h4>
      </div>
      <div>
        <ul>
          <li><strong>Status Page:</strong> New Product status page.</li>
          <li><strong>Projects:</strong> Link to Decipher quota dashboard.</li>
          <li><strong>Projects:</strong> Show PM in project header bar.</li>
          <li><strong>Table updates:</strong> fixes to export and filtering on tables.</li>
          <li><strong>Forms:</strong> Prompt when navigating away from pages where changes were made.</li>
        </ul>
      </div>
    </div>
  </section>
  <section class="release-note release-note-minor">
    <div class="release-tag-wrapper">
      <h3 id="2.23.0">
        <span href="#2.23.0" class="release-tag or-primary-bg text-white">
          <span class="release-version">2.23.0</span>
        </span>
      </h3>
    </div>
    <div class="release-info">
      <div class="release-header">
        <h3>
          <span class="release-version">2.23.0</span>
        </h3>
        <span class="release-badge  bg-secondary">minor</span>
      </div>
      <div class="release-published">
        <h4>January 31 2022</h4>
      </div>
      <div>
        <ul>
          <li><strong>Client Page:</strong> New page layout.</li>
          <li><strong>Test Traffic alert:</strong> Reminds PMs if they forget to move a project from test status.</li>
          <li><strong>ResponseID:</strong> Metrics and reports.</li>
          <li><strong>ResponseID:</strong> Updated scoring on non answers and duplicates.</li>
        </ul>
      </div>
    </div>
  </section>
  <section class="release-note release-note-minor">
    <div class="release-tag-wrapper">
      <h3 id="2.22.0">
        <span href="#2.22.0" class="release-tag or-primary-bg text-white">
          <span class="release-version">2.22.0</span>
        </span>
      </h3>
    </div>
    <div class="release-info">
      <div class="release-header">
        <h3>
          <span class="release-version">2.22.0</span>
        </h3>
        <span class="release-badge  bg-secondary">minor</span>
      </div>
      <div class="release-published">
        <h4>December 30 2021</h4>
      </div>
      <div>
        <ul>
          <li><strong>ResponseID:</strong> API and Batch process.</li>
          <li><strong>Project Settings:</strong> New options for Project layout and display settings.</li>
          <li><strong>Reconciliation:</strong> Updated messaging and notifications.</li>
        </ul>
      </div>
    </div>
  </section>
  <section class="release-note release-note-minor">
    <div class="release-tag-wrapper">
      <h3 id="2.21.0">
        <span href="#2.21.0" class="release-tag or-primary-bg text-white">
          <span class="release-version">2.21.0</span>
        </span>
      </h3>
    </div>
    <div class="release-info">
      <div class="release-header">
        <h3>
          <span class="release-version">2.21.0</span>
        </h3>
        <span class="release-badge  bg-secondary">minor</span>
      </div>
      <div class="release-published">
        <h4>November 30 2021</h4>
      </div>
      <div>
        <ul>
          <li><strong>Schedule:</strong> New Project task functionality.</li>
          <li><strong>Performance Improvement:</strong> Improved performance loading performance table and metrics card.</li>
          <li><strong>Live Alerting:</strong> You can now create live alerts for Completes.</li>
        </ul>
      </div>
    </div>
  </section>

  <section class="release-note release-note-minor">
    <div class="release-tag-wrapper">
      <h3 id="2.20.0">
        <span href="#2.20.0" class="release-tag or-primary-bg text-white">
          <span class="release-version">2.20.0</span>
        </span>
      </h3>
    </div>
    <div class="release-info">
      <div class="release-header">
        <h3>
          <span class="release-version">2.20.0</span>
        </h3>
        <span class="release-badge  bg-secondary">minor</span>
      </div>
      <div class="release-published">
        <h4>October 30 2021</h4>
      </div>
      <div>
        <ul>
          <li><strong>Related Projects:</strong> Includes list of projects with same Bid Number.</li>
          <li><strong>Status Filters:</strong> On project list card view the status filters are now multi select.</li>
          <li><strong>Delete Project:</strong> You can now delete projects.</li>
        </ul>
      </div>
    </div>
  </section>
  <section class="release-note release-note-minor">
    <div class="release-tag-wrapper">
      <h3 id="2.19.0">
        <span href="#2.19.0" class="release-tag or-primary-bg text-white">
          <span class="release-version">2.19.0</span>
        </span>
      </h3>
    </div>
    <div class="release-info">
      <div class="release-header">
        <h3>
          <span class="release-version">2.19.0</span>
        </h3>
        <span class="release-badge  bg-secondary">minor</span>
      </div>
      <div class="release-published">
        <h4>September 30 2021</h4>
      </div>
      <div>
        <ul>
          <li><strong>Auto Admin Login:</strong> When logged into main application you can open admin console without needing to renter credntials.</li>
          <li><strong>ResponseID:</strong> API Call.</li>
          <li><strong>CleanID:</strong> Report Updates.</li>
        </ul>
      </div>
    </div>
  </section>

  <section class="release-note release-note-minor">
    <div class="release-tag-wrapper">
      <h3 id="2.18.0">
        <span href="#2.18.0" class="release-tag or-primary-bg text-white">
          <span class="release-version">2.18.0</span>
        </span>
      </h3>
    </div>
    <div class="release-info">
      <div class="release-header">
        <h3>
          <span class="release-version">2.18.0</span>
        </h3>
        <span class="release-badge  bg-secondary">minor</span>
      </div>
      <div class="release-published">
        <h4>August 31 2021</h4>
      </div>
      <div>
        <ul>
          <li><strong>Add Vendor/Client:</strong> Ability to add new vendors/clients without accessing admin console.</li>
          <li><strong>Add Vendor to Closure:</strong> New vendors can be added to closure process.</li>
          <li><strong>Admin SSO:</strong> Automatically sign in to Admin console if already signed into main application.</li>
          <li><strong>Admin Console:</strong> Design updates.</li>
        </ul>
      </div>
    </div>
  </section>

  <section class="release-note release-note-minor">
    <div class="release-tag-wrapper">
      <h3 id="2.17.0">
        <span href="#2.17.0" class="release-tag or-primary-bg text-white">
          <span class="release-version">2.17.0</span>
        </span>
      </h3>
    </div>
    <div class="release-info">
      <div class="release-header">
        <h3>
          <span class="release-version">2.17.0</span>
        </h3>
        <span class="release-badge  bg-secondary">minor</span>
      </div>
      <div class="release-published">
        <h4>August 3 2021</h4>
      </div>
      <div>
        <ul>
          <li><strong>Rules Engine:</strong> Add notification for scheduled metric checks.</li>
          <li><strong>Roles:</strong> Create Roles and assign to users.</li>
          <li><strong>Vendor ID passthrough:</strong> New parameter for URLS #orvid#.</li>
        </ul>
      </div>
    </div>
  </section>


  <section class="release-note release-note-minor">
    <div class="release-tag-wrapper">
      <h3 id="2.16.0">
        <span href="#2.16.0" class="release-tag or-primary-bg text-white">
          <span class="release-version">2.16.0</span>
        </span>
      </h3>
    </div>
    <div class="release-info">
      <div class="release-header">
        <h3>
          <span class="release-version">2.16.0</span>
        </h3>
        <span class="release-badge  bg-secondary">minor</span>
      </div>
      <div class="release-published">
        <h4>July 6 2021</h4>
      </div>
      <div>
        <ul>
          <li><strong>CleanID:</strong> Geo Param Support.</li>
          <li><strong>Quotas:</strong> Chart and reporting updates.</li>
          <li><strong>Vendor External Page:</strong> Auto accept pricing.</li>
          <li><strong>Closure process:</strong> Updated business rules.</li>
        </ul>
      </div>
    </div>
  </section>

  <section class="release-note release-note-minor">
    <div class="release-tag-wrapper">
      <h3 id="2.15.0">
        <span href="#2.15.0" class="release-tag or-primary-bg text-white">
          <span class="release-version">2.15.0</span>
        </span>
      </h3>
    </div>
    <div class="release-info">
      <div class="release-header">
        <h3>
          <span class="release-version">2.15.0</span>
        </h3>
        <span class="release-badge  bg-secondary">minor</span>
      </div>
      <div class="release-published">
        <h4>June 7 2021</h4>
      </div>
      <div>
        <ul>
          <li><strong>Vendor Confirmation:</strong> External Vendor confirmation page.</li>
          <li><strong>Tool Test Page:</strong> Test page for IDSuite.</li>
          <li><strong>Alerts:</strong> Expanded Alerts page showing large table of alert details.</li>
        </ul>
      </div>
    </div>
  </section>

  <section class="release-note release-note-minor">
    <div class="release-tag-wrapper">
      <h3 id="2.14.0">
        <span href="#2.14.0" class="release-tag or-primary-bg text-white">
          <span class="release-version">2.14.0</span>
        </span>
      </h3>
    </div>
    <div class="release-info">
      <div class="release-header">
        <h3>
          <span class="release-version">2.14.0</span>
        </h3>
        <span class="release-badge  bg-secondary">minor</span>
      </div>
      <div class="release-published">
        <h4>May 10 2021</h4>
      </div>
      <div>
        <ul>
          <li><strong>CleanID:</strong> Summary Email.</li>
          <li><strong>CleanID:</strong> Transaction Reporting Email.</li>
          <li><strong>Performance Card:</strong> Date sort.</li>
        </ul>
      </div>
    </div>
  </section>


  <section class="release-note release-note-minor">
    <div class="release-tag-wrapper">
      <h3 id="2.13.0">
        <span href="#2.13.0" class="release-tag or-primary-bg text-white">
          <span class="release-version">2.13.0</span>
        </span>
      </h3>
    </div>
    <div class="release-info">
      <div class="release-header">
        <h3>
          <span class="release-version">2.13.0</span>
        </h3>
        <span class="release-badge  bg-secondary">minor</span>
      </div>
      <div class="release-published">
        <h4>April 12 2021</h4>
      </div>
      <div>
        <ul>
          <li><strong>Admin Tools:</strong> Site setup and configuration tools.</li>
          <li><strong>Alerting:</strong> Copy alert settings and live allocation alert.</li>
        </ul>
      </div>
    </div>
  </section>

  <section class="release-note release-note-minor">
    <div class="release-tag-wrapper">
      <h3 id="2.12.0">
        <span href="#2.12.0" class="release-tag or-primary-bg text-white">
          <span class="release-version">2.12.0</span>
        </span>
      </h3>
    </div>
    <div class="release-info">
      <div class="release-header">
        <h3>
          <span class="release-version">2.12.0</span>
        </h3>
        <span class="release-badge  bg-secondary">minor</span>
      </div>
      <div class="release-published">
        <h4>March 15 2021</h4>
      </div>
      <div>
        <ul>
          <li><strong>Metrics:</strong> Changes to the way alerts are applied and some graphical changes to the metrics.</li>
          <li><strong>Timezone:</strong> Users current timezone used to calculate dates for metrics and exports.</li>
          <li><strong>Invoice & Billing:</strong> Updates to Closure process.</li>
          <li><strong>Overquota Alerts:</strong> Alerts triggered when a field overquota occurs.</li>
        </ul>
      </div>
    </div>
  </section>


<section class="release-note release-note-minor">
  <div class="release-tag-wrapper">
    <h3 id="2.11.0">
      <span href="#2.11.0" class="release-tag or-primary-bg text-white">
        <span class="release-version">2.11.0</span>
      </span>
    </h3>
  </div>
  <div class="release-info">
    <div class="release-header">
      <h3>
        <span class="release-version">2.11.0</span>
      </h3>
      <span class="release-badge  bg-secondary">minor</span>
    </div>
    <div class="release-published">
      <h4>February 15 2021</h4>
    </div>
    <div>
      <ul>
        <li><strong>Finance Card:</strong> New Card showing pricing and margins.</li>
        <li><strong>Project Status:</strong> New option to change project status in the the main project header.</li>
        <li><strong>Alerts and Notifications:</strong> New feature to show latest alerts and allow users to subscribe for email notifications.</li>
        <li><strong>Closure Process:</strong> Reset closure process option to remove existing closure data and start with a new data set.</li>
      </ul>
    </div>
  </div>
</section>


<section class="release-note release-note-minor">
  <div class="release-tag-wrapper">
    <h3 id="2.10.0">
      <span href="#2.10.0" class="release-tag or-primary-bg text-white">
        <span class="release-version">2.10.0</span>
      </span>
    </h3>
  </div>
  <div class="release-info">
    <div class="release-header">
      <h3>
        <span class="release-version">2.10.0</span>
      </h3>
      <span class="release-badge  bg-secondary">minor</span>
    </div>
    <div class="release-published">
      <h4>January 18 2021</h4>
    </div>
    <div>
      <ul>
        <li><strong>Closure Process:</strong> Invoice step.</li>
        <li><strong>Sales & Billing Info:</strong> Additional data points added for client and vendors.</li>
        <li><strong>Active Performance Email:</strong> Daily email with details of all active projects.</li>
      </ul>
    </div>
  </div>
</section>

<section class="release-note release-note-minor">
  <div class="release-tag-wrapper">
    <h3 id="2.9.0">
      <span href="#2.9.0" class="release-tag or-primary-bg text-white">
        <span class="release-version">2.9.0</span>
      </span>
    </h3>
  </div>
  <div class="release-info">
    <div class="release-header">
      <h3>
        <span class="release-version">2.9.0</span>
      </h3>
      <span class="release-badge  bg-secondary">minor</span>
    </div>
    <div class="release-published">
      <h4>December 7 2020</h4>
    </div>
    <div>
      <ul>
        <li><strong>Closure Process:</strong> Client and Vendor Pricing.</li>
        <li><strong>Global Search:</strong> Find projects based on name or code using the main navigation search.</li>
        <li><strong>Performance Page:</strong> Design Updates</li>
      </ul>
    </div>
  </div>
</section>


<section class="release-note release-note-minor">
  <div class="release-tag-wrapper">
    <h3 id="2.8.0">
      <span href="#2.8.0" class="release-tag or-primary-bg text-white">
        <span class="release-version">2.8.0</span>
      </span>
    </h3>
  </div>
  <div class="release-info">
    <div class="release-header">
      <h3>
        <span class="release-version">2.8.0</span>
      </h3>
      <span class="release-badge  bg-secondary">minor</span>
    </div>
    <div class="release-published">
      <h4>November 9 2020</h4>
    </div>
    <div>
      <ul>
        <li><strong>Project Closure:</strong> New Process for project closure.</li>
        <li><strong>Session Expiry:</strong> Automatic logout after period of in-activity.</li>
        <li><strong>CleanID Landing Page:</strong> Option to show landing page before automatically running CleanID.</li>
        <li><strong>Closure Process:</strong> Finance details added to closure process.</li>
      </ul>
    </div>
  </div>
</section>

<section class="release-note release-note-minor">
  <div class="release-tag-wrapper">
    <h3 id="2.7.0">
      <span href="#2.7.0" class="release-tag or-primary-bg text-white">
        <span class="release-version">2.7.0</span>
      </span>
    </h3>
  </div>
  <div class="release-info">
    <div class="release-header">
      <h3>
        <span class="release-version">2.7.0</span>
      </h3>
      <span class="release-badge  bg-secondary">minor</span>
    </div>
    <div class="release-published">
      <h4>October 12 2020</h4>
    </div>
    <div>
      <ul>
        <li><strong>Project Info card:</strong> Inline editing.</li>
        <li><strong>Vendor Completes:</strong> Download completes of individual vendor or all as a zip file.</li>
        <li><strong>Survey Details and Pace Card:</strong> Design and data updates.</li>
        <li><strong>Export Updates:</strong> Additional columns and formatting to export data.</li>
      </ul>
    </div>
  </div>
</section>


<section class="release-note release-note-minor">
  <div class="release-tag-wrapper">
    <h3 id="2.6.0">
      <span href="#2.6.0" class="release-tag or-primary-bg text-white">
        <span class="release-version">2.6.0</span>
      </span>
    </h3>
  </div>
  <div class="release-info w-100">
    <div class="release-header">
      <h3>
        <span class="release-version">2.6.0</span>
      </h3>
      <span class="release-badge  bg-secondary">minor</span>
    </div>
    <div class="release-published">
      <h4>September 14 2020</h4>
    </div>
    <div>
      <ul>
        <li><strong>Performance Card:</strong> Performance Table design updates.</li>
        <li><strong>BIDS:</strong> Create a new BID.</li>
        <li><strong>Pre-screener:</strong> Vendor level breakdown.</li>
        <li><strong>Notes:</strong> Edit and Delete option and full screen mode.</li>
        <li><strong>Project Cards:</strong> Updates to design on project cards.</li>
      </ul>
    </div>
  </div>
</section>


<section class="release-note release-note-minor">
  <div class="release-tag-wrapper">
    <h3 id="2.5.0">
      <span href="#2.5.0" class="release-tag or-primary-bg text-white">
        <span class="release-version">2.5.0</span>
      </span>
    </h3>
  </div>
  <div class="release-info">
    <div class="release-header">
      <h3>
        <span class="release-version">2.5.0</span>
      </h3>
      <span class="release-badge bg-secondary">minor</span>
    </div>
    <div class="release-published">
      <h4>August 17 2020</h4>
    </div>
    <div>
      <ul>
        <li><strong>Project Wizard:</strong> New Project creation process.</li>
        <li><strong>Documents Card:</strong> New card to upload documents to your project.</li>
        <li><strong>Reconciliation:</strong> Roll back.</li>
        <li><strong>Alerting:</strong> Admin performance metrics.</li>
        <li><strong>Documents Card:</strong> Delete file.</li>
      </ul>
    </div>
  </div>
</section>


      <section class="release-note release-note-minor">
        <div class="release-tag-wrapper">
          <h3 id="2.4.0">
            <span href="#2.4.0" class="release-tag or-primary-bg text-white">
              <span class="release-version">2.4.0</span>
            </span>
          </h3>
        </div>
        <div class="release-info">
          <div class="release-header">
            <h3>
              <span class="release-version">2.4.0</span>
            </h3>
            <span class="release-badge bg-secondary">minor</span>
          </div>
          <div class="release-published">
            <h4>July 21 2020</h4>
          </div>
          <div>
            <ul>
              <li><strong>Reconciliation:</strong> Upload reconciliation file with status changes and vendor completes download.</li>
              <li><strong>Participant Tracking:</strong> Performance enhancements for active traffic and error tracking </li>
              <li><strong>Card Updates:</strong> Additional Info added to project info card and small layout updates </li>
              <li><strong>API:</strong> Server to Server API creation.</li>
              <li><strong>Pre Screening:</strong> Add pre-screener questions to record participant data.</li>
              <li><strong>Pace Card:</strong> New pace card to show Pace metric and daily goal.</li>
              <li><strong>Reporting and Alerting:</strong> Updates to reporting filters and recording alerts.</li>
              <li><strong>Project List Updates:</strong> New quick status filter on project list and Card grouping options.</li>
            </ul>
          </div>
        </div>
      </section>


        <section class="release-note release-note-minor">
          <div class="release-tag-wrapper">
            <h3 id="2.3.0">
              <span href="#2.3.0" class="release-tag or-primary-bg text-white">
                <span class="release-version">2.3.0</span>
              </span>
            </h3>
          </div>
          <div class="release-info">
            <div class="release-header">
              <h3>
                <span class="release-version">2.3.0</span>
              </h3>
              <span class="release-badge bg-secondary">minor</span>
            </div>
            <div class="release-published">
              <h4>June 23 2020</h4>
            </div>
            <div>
              <ul>
                <li><strong>Performance Reports:</strong> Breakdown of performance by partner and client</li>
                <li><strong>Project Health:</strong> Project health score based on metrics and a health Algorithm</li>
                <li><strong>CleanID:</strong> Version 2 of CleanID engine and token parameter security added</li>
                <li><strong>Reconciliation:</strong> Upload your Client complete file to reconcile the data</li>
                <li><strong>Project Setup:</strong> Business rules and formatting applied to project setup page</li>
                <li><strong>Bulk Actions:</strong> Copy entry URL's or download participants for multiple projects</li>
              </ul>
            </div>
          </div>
        </section>



        <section class="release-note release-note-minor">
          <div class="release-tag-wrapper">
            <h3 id="2.2.0">
              <span href="#2.2.0" class="release-tag or-primary-bg text-white">
                <span class="release-version">2.2.0</span>
              </span>
            </h3>
          </div>
          <div class="release-info">
            <div class="release-header">
              <h3>
                <span class="release-version">2.2.0</span>
              </h3>
              <span class="release-badge bg-secondary">minor</span>
            </div>
            <div class="release-published">
              <h4>May 26 2020</h4>
            </div>
            <div>
              <ul>
                <li><strong>Project List:</strong> enhanced sorting and filtering</li>
                <li><strong>Project Cards:</strong> New Design</li>
                <li><strong>Audit Trail:</strong> Full audit trail of changes to projects</li>
              </ul>
            </div>
          </div>
        </section>



        <section class="release-note release-note-minor">
          <div class="release-tag-wrapper">
            <h3 id="2.1.0">
              <span href="#2.1.0" class="release-tag or-primary-bg text-white">
                <span class="release-version">2.1.0</span>
              </span>
            </h3>
          </div>
          <div class="release-info">
            <div class="release-header">
              <h3>
                <span class="release-version">2.1.0</span>
              </h3>
              <span class="release-badge bg-secondary">minor</span>
            </div>
            <div class="release-published">
              <h4>April 28 2020</h4>
            </div>
            <div>
              <ul>
                <li><strong>Admin Console:</strong> manage users, instances and global settings</li>
                <li><strong>CleanID:</strong> configure your CleanID settings and view license and performance details</li>
                <li><strong>Team:</strong> view members of you team and their key contact information</li>
                <li><strong>Reports:</strong> vendor and client complete and QC reports</li>
              </ul>
            </div>
          </div>
        </section>

        <section class="release-note release-note-major">
          <div class="release-tag-wrapper">
            <h3 id="2.0.0">
              <span href="#2.0.0" class="release-tag or-primary-bg text-white">
                <span class="release-version">2.0.0</span>
              </span>
            </h3>
          </div>
          <div class="release-info">
            <div class="release-header">
              <h3><span class="release-version">2.0.0</span></h3>
              <span class="release-badge bg-dark">major</span>
            </div>
            <div class="release-published">
              <h4>April 01 2020</h4>
            </div>
            <div>
              <ul>
                <li>Launch of OpinionRoute's new Navigator System</li>
              </ul>
            </div>
          </div>
        </section>

      </div>
    </div>
  </div>
</div>
