import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ReportsService } from 'core';

@Component({
  selector: 'app-project-email-log',
  templateUrl: './project-email-log.component.html',
  styleUrls: ['./project-email-log.component.scss']
})
export class ProjectEmailLogComponent implements OnInit {
  @Input() projectId;
  @Output() onclose = new EventEmitter();

  emails: any[];
  columns: any = [
    { label: 'Sent By', id: 'user', sortable: true, filterable: true },
    { label: 'From', id: 'from', sortable: true, filterable: true },
    { label: 'To', id: 'to', sortable: true, filterable: true },
    { label: 'Type', id: 'emailType', sortable: true, filterable: true },
    { label: 'Subject', id: 'subject', sortable: true },
    { label: 'Timestamp', id: 'timestamp', sortable: true, type: 'datetime' },
  ];

  constructor(private reportService: ReportsService) { }

  ngOnInit(): void {
    this.getEmails();
  }

  closePage() {
    this.onclose.emit();
  }

  getEmails() {
    this.reportService.GetProjectEmailLog(this.projectId).subscribe((res) => {
      this.emails = res;
    });
  }

}
