<div class="container-fluid px-4">
  <div class="row">
    <div class="col">
      <h3 class="grey5 nav-font18 mt-2">Survey Entry Links
        <span *ngIf="project.surveySecurityKey != null && project.surveySecurityKey != ''"  class="ml-2 nav-secondary">(Survey to NavigatOR link is secure)</span>
      </h3>
    </div>
    <div class="col d-flex align-items-center justify-content-end">
      <span type="button" class="shadow-none grey4 nav-font14 font-weight-normal" (click)="copyRedirectUrls()">
        <i class="mr-2 cursor-pointer far fa-copy"></i>Copy redirect URLs
      </span>
    </div>
  </div>
  <div class="card shadow-none border">
    <div class="card-body">
      <div class="row nav-font14 grey4 pb-3">
        <div class="col d-flex align-items-center">
          <span class="mr-2 font-weight-bold grey6">
            Live URL <i class="ml-4 cursor-pointer far fa-copy" [class.grey3]="!project.projectSegments[0].surveyLink" (click)="copyUrl(project.projectSegments[0].surveyLink)" tooltip="Copy Link"></i>
          </span>
          <span class="nav-black2 w-75">
            <ng-container *ngIf="editLinkMode?.liveUrl">
              <input [(ngModel)]="project.projectSegments[0].surveyLink"
                    placeholder="Enter live URL here"
                    type="text"
                    name="surveyLink"
                    #inputLiveUrl
                    (keypress)="onKeyPress($event)"
                    (blur)="saveLinks()"
                    class="form-control form-control-sm form-control-nav">
              <div *ngIf="project.projectSegments[0].surveyLink !='' && project.projectSegments[0].surveyLink?.indexOf('#or1#') == -1" class="alert alert-warning mt-1" role="alert">You must have a variable set to #or1# so that you can track the unique IDs for this project</div>
            </ng-container>
            <ng-container *ngIf="!editLinkMode?.liveUrl">
              <div class="form-control-sm form-control-nav border-0 link" [class.border]="!project.projectSegments[0].surveyLink" (click)="enableEditLinks('liveUrl')">
                {{project.projectSegments[0].surveyLink}}
                <span class="grey3" *ngIf="!project.projectSegments[0].surveyLink">Enter test URL here</span>
              </div>
            </ng-container>
          </span>
        </div>
      </div>
      <div class="row nav-font14 grey4">
        <div class="col d-flex align-items-center">
          <span class="mr-2 font-weight-bold grey6">
            Test URL <i class="ml-4 cursor-pointer far fa-copy" [class.grey3]="!project.projectSegments[0].testSurveyLink" (click)="copyUrl(project.projectSegments[0].testSurveyLink)" tooltip="Copy Link"></i>
          </span>
          <span class="nav-black2 w-75">
            <ng-container *ngIf="editLinkMode?.testUrl">
              <input [(ngModel)]="project.projectSegments[0].testSurveyLink"
                    placeholder="Enter test URL here"
                    type="text"
                    name="testSurveyLink"
                    #inputTestUrl
                    (keypress)="onKeyPress($event)"
                    (blur)="saveLinks()"
                    class="form-control form-control-sm form-control-nav">
              <div *ngIf="project.projectSegments[0].testSurveyLink !='' && project.projectSegments[0].testSurveyLink?.indexOf('#or1#') == -1" class="alert alert-warning mt-1" role="alert">You must have a variable set to #or1# so that you can track the unique IDs for this project</div>
            </ng-container>
            <ng-container *ngIf="!editLinkMode?.testUrl">
              <div class="form-control-sm form-control-nav border-0 link" [class.border]="!project.projectSegments[0].testSurveyLink" (click)="enableEditLinks('testUrl')">
                {{project.projectSegments[0].testSurveyLink}}
                <span class="grey3" *ngIf="!project.projectSegments[0].testSurveyLink">Enter test URL here</span>
              </div>
            </ng-container>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <h3 class="grey5 nav-font18 mt-4">Vendors ({{ totalVendors }})</h3>
    </div>
    <div class="col d-flex align-items-center justify-content-end">
      <span type="button" class="btn btn-sm nav-btn-accent shadow-none font-weight-bold" (click)="addVendor()">Add Vendor</span>
    </div>
  </div>

  <!-- Existing Vendors -->
  <ng-container *ngFor="let vendor of projectVendors; let idx = index">
    <ng-container *ngIf="!vendor.isDeleted">
      <div class="card mb-4 vendors shadow-none border">
        <div class="card-body pt-2">
          <div class="row mt-1">
            <div class="ml-3 nav-font16 nav-accent-blue font-weight-bold nav-link pl-0" (click)="openPartner(vendor)">
              <span>{{vendor.partnerName}}</span>
            </div>
            <div class="col d-flex align-items-center justify-content-end">
              <div class="cursor-pointer nav-error mr-2 px-2" [class.border-right]="isORInstance" (click)="containsVendorName(vendor.partnerName) ? openDeleteVendorModal(vendor) : deleteVendor(vendor)">
                <i class="fak fa-trash fa-md mr-2"></i>
                <span class="nav-font14">Delete</span>
              </div>
              <div class="cursor-pointer grey4"  *ngIf="isORInstance" tooltip="The vendor view displays what a vendor sees in the 'Vendor Portal' " placement="left" (click)="openVendorView(vendor)">
                <i class="fak fa-frame-view fa-xl mr-1"></i>
                <span class="nav-font14 font-weight-bold id-vendor-open-view">Open Vendor's View</span>
              </div>
            </div>
          </div>

          <div class="alert alert-danger px-1 p-0 d-inline nav-font12" role="alert" *ngIf="vendor.partnerStatus == 'Test'">
            This vendor is in 'Test' mode, Deduplication and CleanID is disabled.
          </div>

          <div class="row mt-3">
            <div class="col-12 col-xxl-3 col-md-4 nav-font14">
              <div class="row mb-4">
                <div class="col-12 col-md-4 grey6 font-weight-bold">Information</div>
              </div>
              <div class="row mb-4">
                <div class="col-12 col-md-4 grey4">Project number</div>
                <div class="col nav-black2">
                  <ng-container *ngIf="vendor?.edit?.partnerProjectNumber">
                    <input type="text" #partnerProjectNumber (keypress)="onKeyPressVendors($event, vendor)" name="partnerProjectNumber" (blur)="onBlur('partnerProjectNumber', vendor, idx)" [(ngModel)]="vendor.partnerProjectNumber" class="form-control form-control-sm form-control-nav">
                  </ng-container>
                  <ng-container *ngIf="!vendor?.edit?.partnerProjectNumber">
                    <div class="form-control-sm form-control-nav border-0 link" [class.border]="!vendor.partnerProjectNumber" (click)="enableVendorField('partnerProjectNumber', vendor)">
                      {{ vendor.partnerProjectNumber }}
                      <span class="grey3" *ngIf="!vendor.partnerProjectNumber">Enter Project number</span>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12 col-md-4 grey4">Vendor status</div>
                <div class="col nav-black2 or-dropdown-status id-vendor-status">
                  <or-dropdown-status [values]="['Test', 'Soft Launch', 'Full Launch', 'Closed']"  [status]="vendor.partnerStatus" (updateProject)="projectStatusUpdated($event)" (changed)="vendor.partnerStatus = $event;" [partnerId]="vendor.partnerId"  [projectId]="project.id" [surveyLink]="project?.projectSegments[0]?.surveyLink" [autosave]="true"></or-dropdown-status>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12 col-md-4 grey4">Vendor contact</div>
                <div class="col nav-black2">
                  <ng-container *ngIf="vendor?.edit?.partnerPms">
                    <or-type-ahead  [labelField]="'name'" [multi]="true" [valueField]="'id'"
                                    [selectedValues]="vendor.partnerPms === null ? [] : vendor.partnerPms"
                                    (valueChange)="updateVendorPM('partnerPms', $event, vendor)" [data]="vendor.contacts"
                                    [class]="'w-50'"
                                    placeholder="Type to search..."></or-type-ahead>
                    <span (click)="addNewContact(vendor, addContact)" class="cursor-pointer nav-accent-blue d-block m-1">Add new contact (+)</span>
                  </ng-container>
                  <ng-container *ngIf="!vendor?.edit?.partnerPms">
                    <div class="form-control-sm form-control-nav border-0 link w-50" [class.border]="!vendor.partnerPms" (click)="enableVendorField('partnerPms', vendor)">
                      <span>{{ getPartneContactNames(vendor) }}</span>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="row mb-4" *ngIf="vendor.linkSecurity">
                <div class="col-12 col-md-4 grey4">Link security</div>
                <div class="col nav-black2">
                  <span class="badge badge-pill badge-light font-weight-normal">{{ vendor.linkSecurity }}</span>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12 col-md-4 grey4">Allocation</div>
                <div class="col nav-black2">
                  <ng-container *ngIf="vendor?.edit?.partnerFullQuota">
                    <input type="text" #partnerFullQuota (keypress)="handleUpdateSoftLaunch('partnerFullQuota', $event, vendor)" name="fullSegmentQuota"
                            (blur)="handleUpdateSoftLaunch('partnerFullQuota', null, vendor)" [(ngModel)]="vendor.partnerFullQuota" class="form-control form-control-sm form-control-nav w-50">
                  </ng-container>
                  <ng-container *ngIf="!vendor?.edit?.partnerFullQuota">
                    <div class="form-control-sm form-control-nav border-0 link w-50" [class.border]="!vendor.partnerFullQuota" (click)="enableVendorField('partnerFullQuota', vendor)">
                      {{ vendor.partnerFullQuota }}
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12 col-md-4 grey4">Soft Launch</div>
                <div class="col nav-black2">
                  <ng-container *ngIf="vendor?.edit?.partnerSoftQuota">
                    <input type="text" #partnerSoftQuota
                           (keypress)="onKeyPressVendors($event, vendor)"
                           name="softSegmentQuota"
                           (blur)="onBlur('partnerSoftQuota', vendor, idx)"
                           [(ngModel)]="vendor.partnerSoftQuota"
                           class="form-control form-control-sm form-control-nav w-50">
                  </ng-container>
                  <ng-container *ngIf="!vendor?.edit?.partnerSoftQuota">
                    <div class="form-control-sm form-control-nav border-0 link w-50" (click)="enableVendorField('partnerSoftQuota', vendor)">
                      {{ vendor.partnerSoftQuota }}
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12 col-md-4 grey4">CPI</div>
                <div class="col nav-black2">
                  <ng-container *ngIf="vendor?.edit?.buyCPI">
                    <input type="text" #buyCPI
                          (keypress)="onKeyPressVendors($event, vendor)"
                          name="softSegmentQuota"
                          currencyMask [options]="{ prefix: '$ ', thousands: ',', decimal: '.', align: 'left' }"
                          (blur)="onBlur('buyCPI', vendor, idx)"
                          [(ngModel)]="vendor.buyCPI"
                          class="form-control form-control-sm form-control-nav w-50">
                  </ng-container>
                  <ng-container *ngIf="!vendor?.edit?.buyCPI">
                    <div class="form-control-sm form-control-nav border-0 link w-50 id-vendor-cpi" [class.border]="!vendor.buyCPI" (click)="enableVendorField('buyCPI', vendor)">
                      {{ vendor.buyCPI | currency }}
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="col nav-font14">
              <div class="row mb-4">
                <div class="col-12 d-flex col-md-4 grey6 font-weight-bold">
                  Vendor Link
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12 col-md-2 grey4">Entry</div>
                <div class="col nav-black2 position-relative link-redirect">
                  <div class="form-control-sm form-control-nav border-0 link-entry">
                    {{ getEntryUrl(vendor) }}
                  </div>
                  <div class="copyRedirect position-absolute" tooltip="Copy to clipboard" (click)="copyEntryUrl(vendor)">
                    <i class="far fa-copy cursor-pointer grey4"></i>
                  </div>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12 d-flex col-md-4 grey6 font-weight-bold">
                  Redirect Links
                  <span *ngIf="showLinkSecurity(vendor)" class="ml-2 nav-secondary" tooltip="Link security hashing is enabled. Edit vendor to disable.">(Link security enabled)</span>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12 col-md-2 grey4">Complete</div>
                <div class="col nav-black2 position-relative link-redirect">
                  <textarea autosize [minRows]="2"
                    #completeRedirectURL
                    (keypress)="onKeyPressVendors($event, vendor)"
                    (mouseleave)="checkFocus('completeRedirectURL', idx)"
                    (click)="enableVendorField('completeRedirectURL', vendor)"
                    (blur)="onBlur('completeRedirectURL', vendor, idx)"
                    [placeholder]="'Enter Complete URL'"
                    [(ngModel)]="vendor.completeRedirectURL" class="form-control form-control-nav" [class.border-0]="vendor.completeRedirectURL">
                  </textarea>
                  <div class="copyRedirect position-absolute" tooltip="Copy to clipboard" (click)="copyRedirects(vendor, 'completeRedirectURL')">
                    <i class="far fa-copy cursor-pointer grey4"></i>
                  </div>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12 col-md-2 grey4">Term</div>
                <div class="col nav-black2 position-relative link-redirect">
                  <textarea autosize [minRows]="2"
                    #terminateRedirectURL
                    (keypress)="onKeyPressVendors($event, vendor)"
                    (mouseleave)="checkFocus('terminateRedirectURL', idx)"
                    (click)="enableVendorField('terminateRedirectURL', vendor)"
                    (blur)="onBlur('terminateRedirectURL', vendor, idx)"
                    [placeholder]="'Enter Term URL'"
                    [(ngModel)]="vendor.terminateRedirectURL" class="form-control form-control-nav" [class.border-0]="vendor.terminateRedirectURL">
                  </textarea>
                  <div class="copyRedirect position-absolute" tooltip="Copy to clipboard" (click)="copyRedirects(vendor, 'terminateRedirectURL')">
                    <i class="far fa-copy cursor-pointer grey4"></i>
                  </div>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12 col-md-2 grey4">Overquota</div>
                <div class="col nav-black2 position-relative link-redirect">
                  <textarea autosize [minRows]="2"
                    #overQuotaRedirectURL
                    (keypress)="onKeyPressVendors($event, vendor)"
                    (mouseleave)="checkFocus('overQuotaRedirectURL', idx)"
                    (click)="enableVendorField('overQuotaRedirectURL', vendor)"
                    (blur)="onBlur('overQuotaRedirectURL', vendor, idx)"
                    [placeholder]="'Enter Overquota URL'"
                    [(ngModel)]="vendor.overQuotaRedirectURL" class="form-control form-control-nav" [class.border-0]="vendor.overQuotaRedirectURL">
                  </textarea>
                  <div class="copyRedirect position-absolute" tooltip="Copy to clipboard" (click)="copyRedirects(vendor, 'overQuotaRedirectURL')">
                    <i class="far fa-copy cursor-pointer grey4"></i>
                  </div>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-12 col-md-2 grey4">Quality Control (Auto QC)</div>
                <div class="col nav-black2 position-relative link-redirect">
                  <textarea autosize [minRows]="2"
                    #qcRedirectURL
                    (keypress)="onKeyPressVendors($event, vendor)"
                    (mouseleave)="checkFocus('qcRedirectURL', idx)"
                    (click)="enableVendorField('qcRedirectURL', vendor)"
                    (blur)="onBlur('qcRedirectURL', vendor, idx)"
                    [placeholder]="'Enter Quality Control (Auto QC) URL'"
                    [(ngModel)]="vendor.qcRedirectURL" class="form-control form-control-nav" [class.border-0]="vendor.qcRedirectURL">
                  </textarea>
                  <div class="copyRedirect position-absolute" tooltip="Copy to clipboard" (click)="copyRedirects(vendor, 'qcRedirectURL')">
                    <i class="far fa-copy cursor-pointer grey4"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #addContact>
  <div class="modal-header d-flex align-items-center justify-content-center">
    <div class="header-grid w-100 position-relative">
      <div class="d-flex justify-content-left align-content-center mt-2 px-2">
        <h3 class="nav-secondary mb-0 font-weight-bold">Add New Contact</h3>
      </div>
      <div class="d-flex justify-content-end align-content-center position-absolute w-100 sticky-top">
        <i class="fas fa-close grey4" (click)="cancelNewContact()"></i>
      </div>
    </div>
  </div>
  <div class="modal-body px-4 py-0">
    <div class="row my-4">
      <div class="col-4 grey4 nav-font14">
        Contact First Name
      </div>
      <div class="col-8">
        <input [(ngModel)]="contactFirstName" class="form-control form-control-sm form-control-nav" placeholder="First Name">
      </div>
    </div>
    <div class="row my-4">
      <div class="col-4 grey4 nav-font14">
        Contact Last Name
      </div>
      <div class="col-8">
        <input [(ngModel)]="contactLastName" class="form-control form-control-sm form-control-nav" placeholder="Last Name">
      </div>
    </div>
    <div class="row my-4">
      <div class="col-4 grey4 nav-font14">
        Company Position
      </div>
      <div class="col-8">
        <input [(ngModel)]="companyPosition" class="form-control form-control-sm form-control-nav" placeholder="Position">
      </div>
    </div>

    <div class="row my-4">
      <div class="col-4 grey4 nav-font14">
        Phone number
      </div>
      <div class="col-8">
        <input [(ngModel)]="phoneNumber" class="form-control form-control-sm form-control-nav" placeholder="(333) 333-4444">
      </div>
    </div>
    <div class="row my-4">
      <div class="col-4 grey4 nav-font14">
        E-mail
      </div>
      <div class="col-8">
        <input [(ngModel)]="email" class="form-control form-control-sm form-control-nav" placeholder="E-mail">
      </div>
    </div>
  </div>
  <div class="modal-footer pt-0 pb-3" style="border-top: none;">
    <div class="row d-flex w-100">
      <div class="col"></div>
      <div class="col-8 text-right px-3">
        <button type="button" (click)="cancelNewContact()" class="btn btn-sm nav-btn-primary mx-2 shadow-none">Cancel</button>
        <button type="submit" (click)="saveNewContact()"  class="btn btn-sm nav-btn-accent shadow-none">Add Contact</button>
      </div>
    </div>
  </div>
</ng-template>

<!-- Modal Add new Vendor -->
<ng-template #modal>
  <div class="card shadow-none border-0">
    <div class="card-body pt-2">
      <div class="row mt-2 mb-4">
        <div class="col-5">
          <span class="nav-font18 nav-secondary font-weight-bold">Add Vendor</span>
        </div>
        <div class="col d-flex align-items-center justify-content-end">
          <i class="fas fa-close cursor-pointer grey4" (click)="closeModal()"></i>
        </div>
      </div>

      <div class="scrollbar overflow-y overflow-x-hidden pr-2">
        <ng-container *ngFor="let newVendor of newVendors; let idx = index">

          <ng-container *ngIf="newVendors.length > 1">
            <hr class="my-2 nav-border3" *ngIf="idx > 0">
            <div class="row mt-0 mb-2">
              <div class="col d-flex align-items-center justify-content-end">
                <div class="cursor-pointer nav-error mr-2 px-2" (click)="deleteNewVendor(idx)">
                  <i class="fak fa-trash fa-md mr-2"></i>
                  <span class="nav-font14">Delete</span>
                </div>
              </div>
            </div>
          </ng-container>

          <div class="row">
            <div class="col-5">
              <span class="nav-font14 grey6 font-weight-bold">Vendor Information</span>
            </div>
            <div class="col ml-3">
              <span class="nav-font14 grey6 font-weight-bold">Redirect Links</span>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-5 nav-font14">
              <div class="row mb-3">
                <div class="col-12 col-md-4 grey4"><label class="required">Vendor</label></div>
                <div class="col pl-0">
                  <or-type-ahead
                  [labelField]="'name'"
                  [valueField]="'value'"
                  placeholder="Type to search for a vendor"
                  [selectedValues]="newVendor.partnerId == null ? []: [{value: newVendor.partnerId, name: newVendor.partnerName }]"
                  dropdownMode=true
                  class="form-control-sm form-control-nav w-100 px-0" [dataCallback]="getVendors" (valueChange)="setNewVendor($event, idx)">
                  </or-type-ahead>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12 col-md-4 grey4">Project number</div>
                <div class="col nav-black2 pl-0">
                  <input type="text" [(ngModel)]="newVendor.partnerProjectNumber" placeholder="Project number" name="softSegmentQuota" class="form-control form-control-sm form-control-nav">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12 col-md-4 grey4">Vendor status</div>
                <div class="col nav-black2 or-dropdown-status pl-0">
                  <or-dropdown-status [skipProjectUpdate]="true" container="body" [partnerId]="newVendor.partnerId" [values]="['Test', 'Soft Launch', 'Full Launch', 'Closed']" [status]="newVendor.partnerStatus" (changed)="newVendor.partnerStatus = $event; " [surveyLink]="project?.projectSegments[0]?.surveyLink" [autosave]="false"></or-dropdown-status>
                </div>
              </div>
              <div class="row mb-3" *ngIf="newVendor.partnerId != null">
                <div class="col-12 col-md-4 grey4">Vendor contact</div>
                <div class="col nav-black2 pl-0">
                  <or-type-ahead
                    [labelField]="'name'"
                    [multi]="true"
                    [valueField]="'id'"
                    [selectedValues]="newVendor.partnerPms === null ? [] : newVendor.partnerPms"
                    (valueChange)="newVendor.partnerPms = $event"
                    [data]="newVendor.contacts" placeholder="Type to search...">
                  </or-type-ahead>
                  <span *ngIf="!newVendor.validatedSample" (click)="addNewContact(newVendor, addContact)" class="cursor-pointer nav-accent-blue d-block m-1">Add new contact (+)</span>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12 col-md-4 grey4"><label class="mr-2" [class.required]="isORInstance">Allocation</label></div>
                <div class="col nav-black2 pl-0">
                  <input type="text" [(ngModel)]="newVendor.partnerFullQuota" (change)="autoUpdateSoftLaunch(newVendor)" name="fullSegmentQuota" class="form-control form-control-sm form-control-nav w-50">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12 col-md-4 grey4">Soft Launch</div>
                <div class="col nav-black2 pl-0">
                  <input type="text"  [(ngModel)]="newVendor.partnerSoftQuota" name="softSegmentQuota" class="form-control form-control-sm form-control-nav w-50">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12 col-md-4 grey4"><label class="mr-2" [class.required]="isORInstance">CPI</label></div>
                <div class="col nav-black2 pl-0">
                  <input type="text" currencyMask [options]="{ prefix: '$ ', thousands: ',', decimal: '.', align: 'left' }"  [(ngModel)]="newVendor.buyCPI" class="form-control form-control-sm form-control-nav w-50">
                </div>
              </div>
            </div>
            <div class="col nav-font14 ml-3">
              <div class="row mb-3">
                <div class="col-12 col-md-3 grey4">Complete</div>
                <div class="col nav-black2 pl-0">
                  <input [(ngModel)]="newVendor.completeRedirectURL" placeholder="Enter Complete URL" class="form-control form-control-sm form-control-nav">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12 col-md-3 grey4">Term</div>
                <div class="col nav-black2 pl-0">
                  <input [(ngModel)]="newVendor.terminateRedirectURL" placeholder="Enter Term URL" class="form-control form-control-sm form-control-nav">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12 col-md-3 grey4">Overquota</div>
                <div class="col nav-black2 pl-0">
                  <input [(ngModel)]="newVendor.overQuotaRedirectURL" placeholder="Enter Overquota URL" class="form-control form-control-sm form-control-nav">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12 col-md-3 grey4">Quality Control</div>
                <div class="col nav-black2 pl-0">
                  <input [(ngModel)]="newVendor.qcRedirectURL" placeholder="Enter Quality Control URL" class="form-control form-control-sm form-control-nav">
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2" *ngIf="idx === newVendors.length-1">
            <div class="col d-flex align-items-center">
              <span (click)="addEmptyVendor()" class="cursor-pointer font-weight-bold nav-accent-blue d-block nav-font14">
                <i class="fas fa-sm fa-plus grey4 mr-1 font-weight-normal"></i>Add another vendor
              </span>
            </div>
            <div class="col d-flex align-items-center justify-content-end">
              <button type="button" class="btn btn-sm nav-btn-primary mx-1 shadow-none" (click)="closeModal()">Cancel</button>
              <button type="button" class="btn btn-sm shadow-none" [ngClass]="totalVendorsSelected < newVendors?.length ? 'nav-btn-disabled' : 'nav-btn-accent'" [attr.disabled]="totalVendorsSelected < newVendors?.length ? true : null" (click)="confirmSave()">Save</button>
            </div>
          </div>
        </ng-container>
      </div>

    </div>
  </div>
</ng-template>

<ng-template #modalDeleteVendor>
  <div class="modal-header">
    <div class="row d-flex w-100 align-items-center">
      <div class="col">
        <h3 class="font-weight-bold nav-secondary">
          <i class="fak fa-message-warning grey4 fa-lg ml-2"></i>
          Warning: Deleting Vendor
        </h3>
      </div>
      <div class="col-2 text-right px-0">
        <i class="fas fa-close grey4" (click)="closeDeleteVendorModal()"></i>
      </div>
    </div>
  </div>
  <div class="modal-body pt-0">
    <h5 class="grey5 d-block my-3">
      There is live traffic for this vendor, are you sure you want to delete this vendor?
    </h5>
  </div>
  <div class="modal-footer">
    <div class="row d-flex w-100 align-items-center">
      <div class="col text-right px-0">
        <button class="btn btn-sm st-btn-primary mx-1 shadow-none" (click)="closeDeleteVendorModal()">No</button>
        <button class="btn btn-sm nav-btn-accent shadow-none" (click)="deleteVendorModal()">Yes</button>
      </div>
    </div>
  </div>
</ng-template>
