import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AlertifyService, AuthService, SurveyTesterComments, UtilsService } from 'core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'survey-tester-mini-remote',
  templateUrl: './survey-tester-mini-remote.component.html',
  styleUrls: ['./survey-tester-mini-remote.component.scss']
})
export class SurveyTesterMiniRemoteComponent implements OnInit {

  @Input() public set data(args) {
    this.isLogged = args.isLogged || false;
  }

  @ViewChild('modal') modalRef: BsModalRef;

  @Output() callback = new EventEmitter();

  isLogged = false;
  newComment = { commentType: '' } as SurveyTesterComments;

  constructor(public auth: AuthService,
    private alertify: AlertifyService,
    public utils: UtilsService,
    private modalService: BsModalService) { }

  ngOnInit() {
  }

  openModal(template: any) {
    this.modalRef = this.modalService.show(template, {ignoreBackdropClick: true, keyboard: false });
  }

  receiveMessage($event) {
    this.newComment.commentText = $event;
  }

  sendMessageExtension(type) {
    this.callback.emit({type, data: null});
  }

  saveComment() {
    if (!this.newComment.commentType) {
      this.alertify.error('Comment Type is mandatory');
      return;
    }

    this.modalRef.hide();
    this.newComment.status = 'open';
    this.callback.emit({type: 'or_surveytester_saveComment', data: this.newComment});
  }

}
