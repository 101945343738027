import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { AlertifyService, AuthService, ProjectDataService,SurveyTesterProject, ProjectService, SurveyTesterService, SurveyTesterUser, User, UserService, UtilsService } from 'core';
import { switchMap, map } from 'rxjs';
import { NgModel } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute } from '@angular/router';
import writeXlsxFile from "write-excel-file";
import * as moment from 'moment';

@Component({
  selector: 'app-project-programming',
  templateUrl: './project-programming.component.html',
  styleUrls: ['./project-programming.component.scss']
})
export class ProjectProgrammingComponent implements OnInit {

  teamSessions = [];
  individualSessions = [];
  sessionViewed = null;
  _instanceId = '';
  _project: any;
  surveyId = '';
  surveyUrl = '';
  surveyUrlDecode = '';
  suveyProject = {} as SurveyTesterProject;
  unsavedChanges = 0;
  reviewers: SurveyTesterUser[];
  guests: SurveyTesterUser[];
  newGuest = {} as SurveyTesterUser;
  loaded = false;
  hasChanges = false;
  info: any = {};
  isLive = false;
  statusFilter = ['open'];
  surveyStatus = '';
  answerId = '';
  userSessionId = ''
  sessionFilterId = ''
  sessionFilterName = ''
  questionId = '';
  ticks: number = 0;
  notes;
  totalNewComments = 0;
  totalPendingComments = 0;
  addGuestMode = false;
  openReply = {};
  notAskAgainApproval = false;
  notAskAgainApprove = false;
  notAskAgainReopen = false;
  commentId = '';
  sessionId = '';
  errorMessage = '';
  _projectId;
  urlParams = [];
  summary = { totalOpen: 0, totalResolved: 0, totalClosed: 0, totalErrors: 0, totalChanges: 0, totalOther: 0 };
  resultType = "opensurvey";
  copyUser;
  editLinkMode = false;
  editingAny: any = {};
  selectedPlatform = '';
  programmers: SurveyTesterUser[];
  @ViewChild('name') name: NgModel;
  @ViewChild('email') email: NgModel;

  openModalRef: any;
  @ViewChild('modalConfirmApprove') modalRefApprove: BsModalRef;
  @ViewChild('modalConfirmSentForApproval') modalRefSentForApproval: BsModalRef;
  @ViewChild('modalConfirmReopen') modalRefReopen: BsModalRef;
  @ViewChild('modalConfirmCancel') modalRefCancel: BsModalRef;
  @ViewChild('modalSurveyPath') modalRefSurveyPath: BsModalRef;

  @ViewChild('modalConfirmParameters')
  modalRefmodalConfirmParameters: TemplateRef<any>;
  openModalmodalConfirmParameters: any;


  editorConfig = {
    toolbar: {
      language: 'en',
      items: [
        'bold',
        'italic',
        'underline',
        'link',
        'bulletedList',
        'numberedList'
      ]
    }
  };

  @Input() public set project(data) {
    if (data) {
      this._project = data;
      this.getProjectInfo();
      this._instanceId = this.auth.getInstance();
    }
  };

  constructor(
    public auth: AuthService,
    private projectDataService: ProjectDataService,
    private alertify: AlertifyService,
    private projectService: ProjectService,
    private modalService: BsModalService,
    private utilService: UtilsService,
    private userService: UserService,
    private service: SurveyTesterService,
    private route: ActivatedRoute,
    public utils: UtilsService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => { this._projectId = params.get('id'); });
  }
  splitParameters() {
    this.urlParams = [];
    const searchParams = new URLSearchParams(this.surveyUrlDecode.split('?')[1]);
    for (const param of searchParams) {
      this.urlParams.push({
        name: param[0],
        value: param[1]
      });
    }
  }
  launchSurvey() {
    if (this.selectedPlatform === null) {
      this.alertify.message("Select the survey platform before testing your survey");
      return;
    }

    let url = this.surveyUrlDecode.toString();
    if (url === '') {
      this.alertify.error('Test URL for survey is missing');
    } else {
      this.resultType == "opensurvey";
      this.splitParameters();
      if (this.urlParams.length == 0) {
        window.open(window.location.origin + `/survey-tester?url=${this.surveyUrl}/&client=${this.selectedPlatform}`, '_blank');
      } else {
        this.openModalmodalConfirmParameters = this.modalService.show(this.modalRefmodalConfirmParameters, { class: 'nav-modal-style' });
      }
    }
  }
  isClientview: boolean = false;
  ngAfterViewInit() { 
    setTimeout(() => {
      this.projectDataService.getClientViewSidebar.subscribe(data => {
        if (data) {        
          this.isClientview = data != '';
        }
      });
    }, 500 * 1);
    
  }
  launchSurveywithParameter() {
    let url = this.surveyUrlDecode.split('?')[0] + '?'.toString();
    for (const param of this.urlParams) {
      url = url + param.name + '=' + param.value + '&'
    }
    url = url.slice(0, -1);
    this.surveyUrl = encodeURIComponent(url);
    if (this.resultType == "copylink") {
      const guestUrl = window.location.origin + `/survey-tester/guest?token=${this.copyUser.id}&url=${this.surveyUrl}&client=${this.selectedPlatform}`;
      this.utilService.copyTextToBuffer(guestUrl);
      this.alertify.success('URL has been copied');
    } else if (this.resultType == "opensurvey") {
      window.open(window.location.origin + `/survey-tester?url=${this.surveyUrl}&client=${this.selectedPlatform}`, '_blank');
    } else if (this.resultType == "sendemail") {
      const guestUrl = window.location.origin + `/survey-tester/guest?token=${this.copyUser.id}&url=${this.surveyUrl}&client=${this.selectedPlatform}`;
      this.service.sendGuestLink(this.copyUser.id, guestUrl, this.surveyId).subscribe(data => {
        if (data) {
          this.alertify.success('Email sent succesfully');
        }
      }, error => {
        this.alertify.error(error);
      });
    }
    this.closeModalParameter();
  }

  closeModalParameter() { this.openModalmodalConfirmParameters.hide(); }

  getNotes() {
    if (this.statusFilter.includes('closed')) {
      this.statusFilter.push('approved', 'cancelled');
    }

    this.service.getComments(this.surveyId, true).subscribe((data) => {
      let result = data;
      this.totalPendingComments = result.filter(x => x.status == 'pending').length;
      this.totalNewComments = result.filter(x => x.status == 'open' && x.isNew).length;
      result = result.filter(item => this.statusFilter.includes(item.status));
      if (this.questionId != "") {
        result = result.filter(item => item.questionId == this.questionId);
      }

      if (this.answerId != "") {
        result = result.filter(item => item.answerId == this.answerId);
      }

      if (this.sessionFilterId != "") {
        result = result.filter(item => item.createdSessionId == this.sessionFilterId);
      }
      this.notes = result.map(x => {
        x.totalNewReplies = x.replies.filter(x => x.isNew).length;
        return x;
      });
    }, error => {
      this.alertify.error('There was an error getting notes');
    });
  }

  programmersNames:string
  getProjectInfo() { 
    let url = this._project.projectSegments[0]?.testSurveyLink || '';
    this.selectedPlatform = this._project.projectSegments[0]?.surveyPlatForm;
    if (!url) {
      this.errorMessage = "No Survey added to this project"
    }
    this.surveyUrlDecode = url;
    this.surveyUrl = encodeURIComponent(url);
    this.suveyProject = this.service.getSurveyProject(url, this.selectedPlatform);   
    this.service.getSurveyId(this.suveyProject).pipe(
      switchMap(data => {
        this.surveyId = data.id;
        this.getNotes();
        this.getSessions();
        this.createEmptyGuest();
        this.updateSurveyUsers();
        return this.service.getProjectdetails(this.surveyId, this._project.id);
      })
    ).subscribe(data => {
      this.info = data;
      this.surveyStatus = data.status;
      this.isLive = data?.projects.some(x => status.includes(x.projectStatus));    
      this.programmers = data.programmers.map(x => ({ id: x.id, userId: x.userId, fullName: x.fullName }));
      this.reviewers = data.reviewers.map(x => ({ id: x.id, userId: x.userId, fullName: x.fullName }));
      this.programmersNames = this.programmers.map(programmer => programmer.fullName).join(', ');
      this.guests = data.guests.map(x => ({
        id: x.id,
        fullName: x.fullName,
        email: x.email,
        expiresAt: x.expiresAt
      }));
    }, error => {
      console.log(error);
      this.alertify.error('There was an error getting survey tester id');
    });
  }


  updateProgrammers(value) { 
    this.programmers = [];
    if (value.length > 0) {
      value.forEach(e => {
        this.programmers.push({
          userId: e.userId,
          role: 'programmer',
          fullName: e.fullName,
          email: e.email,
          projectId: this._project.id,
          surveyTesterProjectId: this.surveyId
        });
      });
    }

    this.service.updateProgrammers(this.surveyId, this._project.id, this.programmers).subscribe((data) => {
      this.alertify.success('Programmers have been updated.');
      this.getProjectInfo();
    }, error => {
      this.alertify.error('There was a problem updating programmers.');
    });
  }



  updateReviewers(value) {
    this.reviewers = [];
    if (value.length > 0) {
      value.forEach(e => {
        this.reviewers.push({
          userId: e.userId,
          role: 'reviewer',
          fullName: e.fullName,
          email: e.email,
          projectId: this._project.id,
          surveyTesterProjectId: this.surveyId
        });
      });
    }

    this.service.updateReviewers(this.surveyId, this._project.id, this.reviewers).subscribe((data) => {
      this.alertify.success('Reviewers have been updated.');
    }, error => {
      this.alertify.error('There was a problem updating reviewers.');
    });
  }

  getSessions() {
    this.service.getProjectSessions(this.surveyId).subscribe((data) => {
      this.teamSessions = this.groupByUser(data);
      this.teamSessions = this.teamSessions.map((item, index) => {
        item.totalOpen = item.sessions.reduce((prev, elm) => prev + elm.commentCreatedCount, 0);
        item.totalResolved = item.sessions.reduce((prev, elm) => prev + elm.commentPendingCount, 0);
        item.totalClosed = item.sessions.reduce((prev, elm) => prev + elm.commentApprovedCount + elm.commentCancelledCount, 0);
        item.totalChanges = item.sessions.reduce((prev, elm) => prev + elm.totalClientChange, 0);
        item.totalOther = item.sessions.reduce((prev, elm) => prev + elm.totalOtherChange, 0);
        item.totalErrors = item.sessions.reduce((prev, elm) => prev + elm.totalProgramError, 0);
        item.lastSessionDate = item.sessions.map(e => { return e.lastUpdated; }).sort().reverse()[0];
        return item;
      });

      this.getTeamSummary();
    }, error => {
      this.alertify.error('There was an error getting project info');
    });
  }


  getTeamSummary() {
    this.summary.totalOpen = this.teamSessions.reduce((prev, elm) => prev + elm.totalOpen, 0);
    this.summary.totalResolved = this.teamSessions.reduce((prev, elm) => prev + elm.totalResolved, 0);
    this.summary.totalClosed = this.teamSessions.reduce((prev, elm) => prev + elm.totalClosed, 0);
    this.summary.totalChanges = this.teamSessions.reduce((prev, elm) => prev + elm.totalChanges, 0);
    this.summary.totalOther = this.teamSessions.reduce((prev, elm) => prev + elm.totalOther, 0);
    this.summary.totalErrors = this.teamSessions.reduce((prev, elm) => prev + elm.totalErrors, 0);
    this.generateCounter(this.teamSessions);
  }

  getSessionsSummary(sessions) {
    this.summary.totalOpen = sessions.reduce((prev, elm) => prev + elm.commentCreatedCount, 0);
    this.summary.totalResolved = sessions.reduce((prev, elm) => prev + elm.commentPendingCount, 0);
    this.summary.totalClosed = sessions.reduce((prev, elm) => prev + elm.commentApprovedCount + elm.commentCancelledCount, 0);
    this.summary.totalChanges = sessions.reduce((prev, elm) => prev + elm.totalClientChange, 0);
    this.summary.totalOther = sessions.reduce((prev, elm) => prev + elm.totalOtherChange, 0);
    this.summary.totalErrors = sessions.reduce((prev, elm) => prev + elm.totalProgramError, 0);
    this.generateCounter(sessions);
  }

  generateCounter(sessions) {
    sessions.map((item, index) => {
      item.counter = sessions.length - index;
      return item;
    });
  }

  groupByUser(array) {
    let result = [];
    array.reduce((prev, elm) => {
      var key = elm['updatedBy'];
      let exist = result.find(x => x.userId == key);
      if (!exist) {
        exist = { userId: key, user: elm['user'] };
        exist.sessions = [];
        result.push(exist);
      }
      exist.sessions.push(elm);
      return prev;
    }, {});
    return result;
  }

  getUserList = (filter) => {
    return this.userService.GetUsers().pipe(map((res: User[]) => res.map(user => ({ userId: user.id, fullName: user.name }))));
  }
  formChanged(event) { if (this.loaded) this.hasChanges = true; }

  saveNotes() {
    this.service.updateNotes(this.surveyId, this.info?.notes).subscribe((data) => {
      this.alertify.success('Notes have been saved.');
    }, error => {
      this.alertify.error('There was a problem saving notes.');
    });
  }

  copyLink(user) {
    this.resultType = "copylink";
    this.copyUser = user;
    this.splitParameters();
    this.openModalmodalConfirmParameters = this.modalService.show(this.modalRefmodalConfirmParameters, { class: 'nav-modal-style' });
  }

  copyUrl(url) {
    this.utils.copyTextToClipboard(url);
    this.alertify.success('Link has been copied');
  }

  deleteGuest(user) {
    this.service.removeGuest(user.id).subscribe((data) => {
      this.alertify.success('Guest has been deleted.');
      this.guests.splice(this.guests.findIndex(x => x.id === user.id), 1);
    }, error => {
      this.alertify.error('There was a problem removing guest.');
    });
  }

  saveGuest() {
    if (this.newGuest.expiresAt === null) {
      var expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 5); // add 5 days to expiry date
    }

    this.newGuest.projectId = this._projectId;
    this.newGuest.surveyTesterProjectId = this.surveyId;

    if (this.newGuest.fullName == '' || this.newGuest.email == '') {
      this.alertify.error("Please enter Name and Email");
      return;
    }

    this.service.addGuest(this.newGuest).subscribe((data) => {
      this.alertify.success('Guest has been added.');
      this.newGuest.id = data;
      this.guests.push(this.newGuest);
      //this.sendEmail(this.newGuest);
      this.newGuest = {};
      this.createEmptyGuest();
    }, error => {
      this.alertify.error('There was a problem adding guest.');
    });
  }

  addGuest() {
    if (!this.newGuest?.id) {
      if (this.newGuest.expiresAt === null) {
        var expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 5); // add 5 days to expiry date
      }

      this.newGuest = {
        id: uuidv4(),
        fullName: '',
        email: '',
        role: 'guest',
        expiresAt: new Date(expiryDate),
        projectId: this._project.id,
        surveyTesterProjectId: this.surveyId
      }
    }
  }

  viewMore(comment) { this.toggleReply(comment); }
  receiveMessage(comment, $event) { comment.replyText = $event; }
  getCount(comment, $event) { comment.commentLength = $event; }

  toggleReply(comment) {
    this.openReply[comment.id] = !this.openReply[comment.id];
    comment.expanded = !comment?.expanded;

    this.service.updateReplyViewDate(comment.id).subscribe((data) => {
      comment.totalNewReplies = 0;
    }, error => {
      this.alertify.error('There was an error updating comment view date.');
      console.log(error);
    });
  }

  saveReply(comment) {
    let newReply = { surveyTesterCommentsId: comment.id, replyText: comment.replyText };

    if (!newReply.replyText || comment?.commentLength == 0) {
      this.alertify.error('Please write a comment.');
      return;
    }

    this.service.reply(newReply).subscribe((data) => {
      this.getNotes();
      this.openReply[comment.id] = false;
    }, error => {
      this.alertify.error('There was an error commenting.');
    }, () => {
      this.alertify.success('New comment has been saved.');
    });
  }

  createEmptyGuest() {
    if (!this.newGuest?.id) {
      var expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 5); // add 5 days to expiry date

      this.newGuest = {
        id: uuidv4(),
        fullName: '',
        email: '',
        role: 'guest',
        expiresAt: new Date(expiryDate),
        projectId: this._project.id,
        surveyTesterProjectId: this.surveyId
      }
    }
  }

  openModal(template: any, classes: string = 'modal-sm nav-modal nav-modal-style') {
    this.openModalRef = this.modalService.show(template, { ignoreBackdropClick: true, keyboard: false, class: classes });
  }

  confirm(type, commentId) {
    this.commentId = commentId;

    if (!this.sessionId) {
      this.service.saveSession(this.surveyId).subscribe((data) => {
        let userSession = JSON.parse(JSON.stringify(data));
        this.sessionId = userSession.id;
        this.switchModal(type);
      }, error => {
        this.alertify.error('There was a problem saving this project.');
      });
      return;
    } else {
      this.switchModal(type);
    }
  }

  switchModal(type) {
    switch (type) {
      case 'sendForApproval':
        let keepSendForApproval = localStorage.getItem('keepSendForApproval');
        (keepSendForApproval == null) ? this.openModal(this.modalRefSentForApproval) : this.sendForApproval();
        break;
      case 'approve':
        let keepApprove = localStorage.getItem('keepApprove');
        (keepApprove == null) ? this.openModal(this.modalRefApprove) : this.approve();
        break;
      case 'reopen':
        let keepReopen = localStorage.getItem('keepReopen');
        (keepReopen == null) ? this.openModal(this.modalRefReopen) : this.reopen();
        break;
      case 'cancel':
        this.openModal(this.modalRefCancel);
        break;
    }
  }

  cancel() {
    this.service.cancel(this.commentId, this.sessionId).subscribe((data) => {
      this.getNotes();

      if (this.openModalRef) this.openModalRef.hide();
    }, error => {
      this.alertify.error('There was an error cancelling comment.');
      console.log(error);
    }, () => {
      this.alertify.success('Comment has been cancelled.');
    });
  }

  sendForApproval() {
    if (this.notAskAgainApproval) localStorage.setItem('keepSendForApproval', '1');

    this.service.sendForApproval(this.commentId, this.sessionId).subscribe((data) => {
      this.getNotes();

      if (this.openModalRef) this.openModalRef.hide();
    }, error => {
      this.alertify.error('There was an error sending comment for approval.');
      console.log(error);
    }, () => {
      this.alertify.success('Comment has been sent for approval.');
    });
  }

  approve() {
    if (this.notAskAgainApprove) localStorage.setItem('keepApprove', '1');

    this.service.approve(this.commentId, this.sessionId).subscribe((data) => {
      this.getNotes();

      if (this.openModalRef) this.openModalRef.hide();
    }, error => {
      this.alertify.error('There was an error approving comment.');
      console.log(error);
    }, () => {
      this.alertify.success('New comment has been approved.');
    });
  }

  reopen() {
    if (this.notAskAgainReopen) localStorage.setItem('keepReopen', '1');

    this.service.reopen(this.commentId, this.sessionId).subscribe((data) => {
      this.getNotes();

      if (this.openModalRef) this.openModalRef.hide();
    }, error => {
      this.alertify.error('There was an error re-opening.');
      console.log(error);
    }, () => {
      this.alertify.success('Comment has been re-opened.');
    });
  }

  sendEmail(user) {
    user.projectCode = this._project.projectCode;
    this.copyUser = user;
    this.resultType = "sendemail";
    this.splitParameters();
    this.openModalmodalConfirmParameters = this.modalService.show(this.modalRefmodalConfirmParameters, { class: 'nav-modal-style' });
  }

  export() {
    const now = new Date();
    let newFileName=this._project.projectCode+'-'+this._project.projectName+' comments '+now.toLocaleDateString()+'.xlsx'

    this.service.exportComments(this.surveyId, true).subscribe(data => {
      // Reverse order of replies
      for (var row in data) {
        if (data[row]["replies"]) {
          data[row]["replies"] = data[row]["replies"].reverse();
        }
      }

      // Filter out unwanted columns
      var dataToExport = data.map(row => ({
        "Submitted Date": (moment(row.createdDate)).format("YYYY/MM/DD HH:mm:ss"),
        "Submitted By": row.createdBy,
        "Question ID": row.questionId,
        "Answer": row.answerText,
        "Type": row.commentType,
        "Comment": row.commentText,
        "Status": row.status,
      }));

      // Add replies and addressed columns
      var numOfReplies = 0;

      for (var row in data) {
        var status = data[row]["status"];

        if (status === "pending") {
          status = "sentForApproval";
          dataToExport[row]["Closed By"] = "";
          dataToExport[row]["Closed Date"] = "";
        }
        if (status === "open") {
          dataToExport[row]["Addressed By"] = "";
          dataToExport[row]["Addressed Date"] = "";
          dataToExport[row]["Closed By"] = "";
          dataToExport[row]["Closed Date"] = "";
        } else {
          dataToExport[row]["Addressed By"] = data[row][status + "By"];
          dataToExport[row]["Addressed Date"] = (moment(data[row][status + "Date"])).format("YYYY/MM/DD HH:mm:ss");
        }
        if (status === "cancelled" || status === "approved") {
          dataToExport[row]["Closed By"] = data[row]["closedBy"];
          dataToExport[row]["Closed Date"] = (moment(data[row]["closedDate"])).format("YYYY/MM/DD HH:mm:ss");
        }
        var replyIndex = 0;
        while (data[row]["replies"][replyIndex]) {
          dataToExport[row]["Reply " + (replyIndex + 1) + " - Author"] = data[row]["replies"][replyIndex]["createdBy"];
          dataToExport[row]["Reply " + (replyIndex + 1) + " - Date"] = (moment(data[row]["replies"][replyIndex]["createdTime"])).format("YYYY/MM/DD HH:mm:ss");
          dataToExport[row]["Reply " + (replyIndex + 1) + " - Text"] = data[row]["replies"][replyIndex]["replyText"];
          replyIndex++;
        }
        if (replyIndex > numOfReplies) numOfReplies = replyIndex;
      }

      // Format data
      var getInitials = function (author) {
        var names = author.split(/[ ']/);
        var initials = '';
        for (var i = 0; i < names.length; i++) {
          if (names[i].length > 0 && names[i] !== '') {
            initials += names[i][0].toUpperCase();
          }
        }
        return initials;
      }

      for (var row in dataToExport) {
        var id = dataToExport[row]["Question ID"];
        var qtext = dataToExport[row]["Answer"]
        var comment = dataToExport[row]["Comment"];
        var submittedBy = dataToExport[row]["Submitted By"];
        var addressedBy = dataToExport[row]["Addressed By"];
        var closedBy = dataToExport[row]["Closed By"];

        if (id) dataToExport[row]["Question ID"] = id.replace("question_text_q", "");
        if (qtext) dataToExport[row]["Answer"] = qtext
        if (comment) dataToExport[row]["Comment"] = comment.replace(/<\/?p>/g, "");
        if (submittedBy) dataToExport[row]["Submitted By"] = submittedBy
        if (addressedBy) dataToExport[row]["Addressed By"] = addressedBy;
        if (closedBy) dataToExport[row]["Closed By"] = closedBy;

        for (var replyIndex = 0; replyIndex < numOfReplies; replyIndex++) {
          var replyAuthor = dataToExport[row]["Reply " + (replyIndex + 1) + " - Author"];
          var replyText = dataToExport[row]["Reply " + (replyIndex + 1) + " - Text"];

          if (replyAuthor) dataToExport[row]["Reply " + (replyIndex + 1) + " - Author"] = replyAuthor;
          if (replyText) dataToExport[row]["Reply " + (replyIndex + 1) + " - Text"] = replyText.replace(/<\/?p>/g, "");
        }
      }

      var firstRow = [
        { value: 'Submitted Date', fontWeight: 'bold' },
        { value: 'Submitted By', fontWeight: 'bold' },
        { value: 'Question ID', fontWeight: 'bold' },
        { value: 'Answer', fontWeight: 'bold' },
        { value: 'Type', fontWeight: 'bold' },
        { value: 'Comment', fontWeight: 'bold' },
        { value: 'Status', fontWeight: 'bold' },
        { value: 'Addressed By', fontWeight: 'bold' },
        { value: 'Addressed Date', fontWeight: 'bold' },
        { value: 'Closed By', fontWeight: 'bold' },
        { value: 'Closed Date', fontWeight: 'bold' }
      ];

      for (var replyIndex = 1; replyIndex < numOfReplies + 1; replyIndex++) {
        firstRow.push({ value: "Reply " + (replyIndex) + " - Author", fontWeight: 'bold' });
        firstRow.push({ value: "Reply " + (replyIndex) + " - Date", fontWeight: 'bold' });
        firstRow.push({ value: "Reply " + (replyIndex) + " - Text", fontWeight: 'bold' });
      }

      var finalData: any = [firstRow];

      for (var row in dataToExport) {
        var rowData = [
          { value: dataToExport[row]['Submitted Date'], alignVertical: "center" },
          { value: dataToExport[row]['Submitted By'], alignVertical: "center" },
          { value: dataToExport[row]['Question ID'], alignVertical: "center" },
          { value: dataToExport[row]['Answer'], alignVertical: "center" },
          { value: dataToExport[row]['Type'], alignVertical: "center"},
          { value: dataToExport[row]['Comment'], alignVertical: "center", wrap: true },
          { value: dataToExport[row]['Status'], alignVertical: "center" },
          { value: dataToExport[row]['Addressed By'], alignVertical: "center" },
          { value: dataToExport[row]['Addressed Date'], alignVertical: "center" },
          { value: dataToExport[row]['Closed By'], alignVertical: "center" },
          { value: dataToExport[row]['Closed Date'], alignVertical: "center" }
        ];

        for (var replyIndex = 1; replyIndex < numOfReplies + 1; replyIndex++) {
          rowData.push({ value: dataToExport[row]["Reply " + (replyIndex) + " - Author"], alignVertical: "center" });
          rowData.push({ value: dataToExport[row]["Reply " + (replyIndex) + " - Date"], alignVertical: "center" });
          rowData.push({ value: dataToExport[row]["Reply " + (replyIndex) + " - Text"], alignVertical: "center", wrap: true });
        }

        finalData.push(rowData);
      }

      // Add count of "Other" comments to the bottom of the exported data
      finalData.push([
        { value: 'Other Changes:', alignVertical: "center", fontWeight: 'bold' },
        { value: this.summary.totalOther, alignVertical: "center", fontWeight: 'bold', colspan: 8 + (numOfReplies * 3) }
      ]);

      // Set width for columns
      var columns = [{ width: 14 }, { width: 12 }, { width: 11 }, { width: 12 }, { width: 55 }, { width: 11 }, { width: 12 }, { width: 14 }];
      for (var replyIndex = 1; replyIndex < numOfReplies + 1; replyIndex++) {
        columns.push({ width: 14 }, { width: 12 }, { width: 35 })
      }

      writeXlsxFile(finalData, { columns, sheet: 'Comments', stickyRowsCount: 1, fileName: newFileName });
    });
  }


  showCssValidField(field) {
    const isValid = field?.valid;
    return {
      'is-invalid': !isValid,
      'is-valid': isValid
    };
  }

  // Notes
  toggleFilter(status) {
    this.statusFilter = [];
    this.statusFilter.push(status);
    this.totalNewComments = 0;
    this.totalPendingComments = 0;
    this.getNotes();
  }


  saveLinks() {    
    this.projectService.UpdateProjectSurveyLinks(this._project.id, this._project.projectSegments[0].surveyLink, this._project.projectSegments[0].testSurveyLink, this.selectedPlatform).subscribe(data => {
      this._project.projectSegments[0].surveyPlatForm = this.selectedPlatform;
      this.alertify.success('Survey Links updated');
      this.editingAny = {};
      this.editLinkMode = false;
      let url = this._project.projectSegments[0]?.testSurveyLink || '';
      this.surveyUrlDecode = url;
      this.surveyUrl = encodeURIComponent(url);     
    }, error => {
      this.alertify.error('Survey Links failed to update');
    });

  }

  updateSurveyUsers() {
    this.projectService.UpdateProjectSurveyUsers(this._project.id, this._project.projectSegments[0].surveyLink).subscribe(data => {
     
    }, error => {
     // this.alertify.error('Survey Links failed to update');
    });
  }
  onSelected(value: string): void {
    this.selectedPlatform = value;
  }

}
