import { Component, Input, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { AuthService, Client, ClientContactService,ProjectDataService, ClientService, DropdownService, LookupService, Project, ProjectService, SettingsService, SurveyTesterProject, SurveyTesterService, SurveyTesterUser, User, UserService, UtilsService } from 'core';
import { HttpEventType } from '@angular/common/http';
import { AlertifyService } from 'core';
import { SpinnerButtonComponent } from 'projects/core/src/components/spinner-button/spinner-button.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AssetUpload } from 'projects/core/src/models/assetUpload';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UploaderService } from 'projects/core/src/services/uploader.service';
import { map, switchMap, of, forkJoin } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { Router } from '@angular/router';

@Component({
  selector: 'app-project-info',
  templateUrl: './project-info.component.html',
  styleUrls: ['./project-info.component.scss']
})
export class ProjectInfoComponent implements OnInit {

  @Input() projectType;
  @Input() public set project(data: Project) {
    this._project = data;

    if (this._project.projectEndDate != null) {
      this._project.projectEndDate = new Date(this._project.projectEndDate);
      this._project.projectEndDate.setHours(12, 0, 0, 0);
    }

    if (this._project.projectStartDate != null) {
      this._project.projectStartDate = new Date(this._project.projectStartDate);
      this._project.projectStartDate.setHours(12, 0, 0, 0);
    }

    this.tags = this._project.projectTags.map(e => e.tag).join(',');
    this.countries = this._project.countries.map(e => ({ value: e, name: e }));
    this.getAssets();
    this.getTeamMembers();
    this.getProgrammer();
    this.parseSurveyUrls(this._project.projectSegments);
  }
  @Input() bidNumber = '' as string;

  _project: Project;

  userList: any = [];
  projectManager;
  projectSecondaryManager;
  tags: any = [];
  internalAccountOwner: any = [];
  projectTypes: any = [];
  litemode = false;
  defaultSettings;
  countries;
  clients;
  clientContacts = [];
  reconcileData: any;
  closureDetails: any;
  isClientview: boolean = false;
  //programmer: SurveyTesterUser;
  programmers: SurveyTesterUser[];
  surveyUrl = '';
  suveyProject = {} as SurveyTesterProject;
  surveyId = '';
  editMode = false;
  originalProjectValues: any = {};
  originalClientValues: any = {};
  hasSurveyUrl: boolean;
  surveyLink: string;
  checkDecipher: string = 'selfserve.decipherinc.com';
  isDecipherUrl: boolean;
  isVendorview: boolean = false;
  assets: any[] = [];
  assets_Files: any[] = [];
  assets_Links: any[] = [];
  @Input() directToS3 = false;
  @ViewChild('fileInput') myFileInput: ElementRef;
  @Output() uploadCompleted = new EventEmitter();
  @Output() callback = new EventEmitter();
  @Output() onChange = new EventEmitter();
  @ViewChild('modalAddLink')
  modalRefAddLink: BsModalRef;
  @ViewChild('modalConfirm')
  modalRefConfirm: BsModalRef;
  @ViewChild('spinnerLink')
  spinnerLink: SpinnerButtonComponent;
  @ViewChild('spinnerFile')
  spinnerFile: SpinnerButtonComponent;
  link: any;
  presignedUrl = '';
  downloadUrl = '';
  file: File | null = null;
  acceptTypes = '';
  fileExtension = '';
  uploading = false;
  uploadComplete = false;
  uploadProgress = 0;
  assetUpload: AssetUpload = { filename: null, url: null, assetType: null };
  FileSaver = require('file-saver');
  addLinkForm: FormGroup;
  openModalRef: any;
  isInternal;

  relatedProjects: any = [];
  notesOpen = false;


  newContact;
  contactFirstName;
  contactLastName;
  companyPosition;
  phoneNumber;
  email;
  profilePhoto;
  summaryModel = {
    clientId: '',
    clientName: '',
    clientPreference: '',
    type: '',
    countries: '',
    clientPo: '',
    clientContactId: '',
    clientContactName: '',
    clientContactEmail: '',
    accountOwnerName: '',
    accountOwnerId: '',
    pointOfContactName: '',
    assets_Files: [],
    assets_Links: []

  }
  client: Client = {
    id: '',
    name: '',
    instanceId: '',
    externalId: '',
    salesTerm: '',
    salesTermValue: 0,
    internalAccountOwnerId: null,
    notes: null,
    allNotes: [],
    address: {
      address1: '',
      address2: '',
      city: '',
      country: ''
    },
  };
  tagOptions = {};
  subjectOptions = {};
  subjects = [];


  constructor(
    private uploader: UploaderService,
    private alertify: AlertifyService,
    private settingsService: SettingsService,
    private projectService: ProjectService,
    private modalService: BsModalService,
    private service: SurveyTesterService,
    private userService: UserService,
    private projectDataService: ProjectDataService,
    private dropdownService: DropdownService,
    private clientContactService: ClientContactService,
    private clientService: ClientService,
    private surveyTesterService: SurveyTesterService,
    public auth: AuthService,
    public utils: UtilsService,
    private lookupService: LookupService,
    public router: Router) {
    this.isInternal = this.auth.isInternal();
  }

  ngOnInit(): void {
    if (this.isInternal) {
      this.getRelatedProjects();
      this.dropdownService.getClients().subscribe(data => this.clients = data);
      this.getClientDetails();
      if (this.bidNumber != '') this.getInfoSummary();
    }
 
    this.defaultSettings = this.settingsService.getSurveyLinkUrls();
    this.addLinkForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      link: new FormControl('', [Validators.required])
    });
    this.projectDataService.getVendorViewSidebar.subscribe(data => {
      if (data) {
        this.isVendorview = data != '';
      }
    });
    
    this.projectDataService.getClientViewSidebar.subscribe(data => {
      if (data) {
        this.isClientview = data != '';
      }
    });

  }



  

  getInfoSummary() {
    this.projectService.getProjectInfoSummary(this.bidNumber).subscribe(data => {
      this.summaryModel.type = data.projectType;
      this.summaryModel.countries = data.countries.join(', ');
      this.summaryModel.clientPo = data.projectNumber
      this.summaryModel.clientContactName = data.pointOfContactName;
      this.summaryModel.clientContactEmail = data.pointOfContactEmail;
      this.summaryModel.accountOwnerName = data.accountOwnerName
      this.summaryModel.accountOwnerId = data.accountOwnerId
      this.summaryModel.assets_Files = data.assets.filter(x => x.assetType === 'file')
      this.summaryModel.assets_Links = data.assets.filter(x => x.assetType === 'link')
    }, error => {
      console.log(error);
    })
  }


  openClientView(clientId){    
    const url = `/projects/${this._project.id}/clientview/${clientId}`;
    window.open(window.location.origin + url, '_blank');
  }

  showClientTab(tabToOpen: string) {   
    const clientRoute = ['/clients', this._project.clientId];

    this.router.navigate(clientRoute, {
      queryParams: { tab: tabToOpen }
    });
  }

  toggleNotes() {
    this.notesOpen = !this.notesOpen;
    this.getClient()
  }

  getUserList = (filter) => { return of(this.userList); }
  getProgrammerList = (filter) => { return this.userService.GetUsers().pipe(map((res: User[]) => res.map(user => ({ id: user.id, fullName: user.name })))); }
  getCountryList = (filter) => { return this.dropdownService.getCountries(); }

  getTeamMembers() {
    this.projectManager = this._project.projectTeam.filter(e => e.projectRole === 'PM').map(e => ({ id: e.userId, name: e.displayName, projectRole: e.projectRole }));
    this.projectSecondaryManager = this._project.projectTeam.filter(e => e.projectRole === 'Secondary PM').map(e => ({ id: e.userId, name: e.displayName, projectRole: e.projectRole }));
    this.userService.GetUsers().subscribe((users) => {
      this.userList = users;
      this.internalAccountOwner = users.filter(e => e.id === this._project.internalAccountOwnerId).map(e => ({ id: e.id, name: e.firstName + ' ' + e.lastName }))
    });
  }

  parseSurveyUrls(segments) {
    if (segments.length === 0) { this.hasSurveyUrl = false; }
    else {
      if (segments[0].surveyLink === '') this.hasSurveyUrl = false;
      else {
        this.hasSurveyUrl = true;
        this.surveyLink = this.parseUrl(segments[0].surveyLink);
      }
    }
  }
  parseUrl(link: string) {
    /**
     * An example of the parsing.
     *
     * BEFORE:
     * https://selfserve.decipherinc.com/survey/selfserve/1f07/200701?list=114&or3=#or3#&or1=#or1#
     * https://selfserve.decipherinc.com/survey/selfserve/1f07/200701/temp-edit-live?list=217&or3=#or3#&or1=#or1#
     * AFTER:
     * https://selfserve.decipherinc.com/rep/selfserve/1f07/200701:dashboard?tab=quota
     */
    if (!link.includes(this.checkDecipher)) {
      this.isDecipherUrl = false;
      return link;
    }

    this.isDecipherUrl = true;

    // These are hardcoded positions and subject to change. For now though they seem to be correct
    const removedQmark = link.split('?');
    const linkParts = removedQmark[0].split('/');
    const params = linkParts[5] + '/' + linkParts[6];
    return 'https://selfserve.decipherinc.com/rep/selfserve/' + params + ':dashboard?tab=quota';
  }
  programmersNames:string
  getProgrammer() {    
    let url = this._project.projectSegments[0]?.testSurveyLink || '';  
    if (url) {
      this.surveyUrl = encodeURIComponent(url.split('?')[0]);
      this.suveyProject = this.service.getSurveyProject(url, this._project.projectSegments[0]?.surveyPlatForm);
      this.surveyTesterService.getSurveyId(this.suveyProject).pipe(
        switchMap(data => {
          this.surveyId = data.id;
          return this.surveyTesterService.getProjectInfo(this.surveyId);
        })
      ).subscribe(data => {   
        this.programmers = data.programmers.filter(e => e.projectId === this._project.id).map(x => ({ id: x.id, userId: x.userId, fullName: x.fullName }));
        this.programmersNames = this.programmers.map(programmer => programmer.fullName).join(', ');
      }, error => {
        this.alertify.error('There was an error getting survey tester id');
      });
    }
  }
  getClientDetails() {
    if (this.bidNumber == '') {
      let clientId = this._project.clientId;
      this.clientContacts = [];
      if (clientId == null) return;

      this.clientService.GetClient(clientId).subscribe(data => {
        if (this.internalAccountOwner == null && data.internalAccountOwnerId != null) {
          this.internalAccountOwner = this.userList.filter(e => e.id === data.internalAccountOwnerId).map(e => ({ id: e.id, name: e.firstName + ' ' + e.lastName }));
        }
        this._project.clientName = data.name;
      });

      this.clientContactService.GetClientContacts(clientId).subscribe(data => { this.clientContacts = data; });
    } else if (this.bidNumber != '') {
      this.clientContacts = [];

      this.clientService.GetClientUsingBidNumber(this.bidNumber).subscribe(data => {
        this.summaryModel.clientId = data.id;
        this.summaryModel.clientName = data.name;
        this.summaryModel.clientPreference = data.clientPreference;
        if (this.internalAccountOwner == null && data.internalAccountOwnerId != null) {
          this.internalAccountOwner = this.userList.filter(e => e.id === data.internalAccountOwnerId).map(e => ({ id: e.id, name: e.firstName + ' ' + e.lastName }));
        }
        this.summaryModel.clientId = data.id;
        this.summaryModel.clientName = data.name;
        this.clientContactService.GetClientContacts(this.summaryModel.clientId).subscribe(data => {
          this.clientContacts = data;
        });
      });
    }
  }

  getRelatedProjects() {
    if (this.bidNumber == '') {
      this.projectService.GetOrderedRelatedProjects(this._project.id).subscribe(data => {
        this.relatedProjects = data;
      });
    } else if (this.bidNumber != '') {
      this.projectService.GetOrderedRelatedProjectsSummary(this.bidNumber).subscribe(data => {
        this.relatedProjects = data;
      });
    }
  }

  updateProjectField(field) {
    let fieldLabel = "Field";
    let value = this._project[field];
    if (field == 'sellCPI' || field == 'projectIR' || field == 'projectLOI') {
      value = this._project.projectSegments[0][field];
    }
    if (field == 'projectName') fieldLabel = 'Project Name';
    else if (field == 'category') fieldLabel = 'Category';
    else if (field == 'projectTags') fieldLabel = 'Tags';
    else if (field == 'countries') fieldLabel = 'Countries';
    else if (field == 'clientId') fieldLabel = 'Client';
    else if (field == 'clientContactId') fieldLabel = 'Point of Contact';
    else if (field == 'internalAccountOwnerId') fieldLabel = 'Account Owner';

    this.projectService.UpdateProjectValue(this._project.id, field, value).subscribe(data => {
      this.alertify.success(fieldLabel + ' Updated Successfully');
    }, error => { this.alertify.error('There was an issue updating ' + fieldLabel); console.log(error) });

  }
  updateInternalAccountOwner(event) {
    this._project.internalAccountOwnerId = (event == null || event.length == 0) ? null : event[0].id;
    this.updateProjectField('internalAccountOwnerId')
  }
  updateProjectCountries(field, event) {
    this._project[field] = event.map(e => e.name);
    this.updateProjectField(field);
  }
  updateProjectTags(event) {
    const tags = event;
    this.projectService.UpdateProjectTags(this._project.id, tags).subscribe(data => {
      this.alertify.success('Tags Updated Successfully');
      this._project.projectTags = tags.map((str) => ({
        tag: str
      }));
      this.onChange.emit();
    }, error => { this.alertify.error('There was an issue updating Tags'); console.log(error) });
  }

  updateProjectTeam(field, event) {
    if (field == 'projectManager') this.projectManager = event;
    else if (field == 'projectSecondaryManager') this.projectSecondaryManager = event;

    var team = this.projectSecondaryManager.map(e => ({ id: uuidv4(), userId: e.id, displayName: (e.name ?? e.firstName + ' ' + e.lastName), projectRole: 'Secondary PM' }));
    var PM = this.projectManager.map(e => ({ id: uuidv4(), userId: e.id, displayName: (e.name ?? e.firstName + ' ' + e.lastName), projectRole: 'PM' }));
    team = team.concat(PM);

    this.projectService.UpdateProjectTeam(this._project.id, team).subscribe(data => {
      this._project.projectTeam = team;
      this.onChange.emit(null);
      this.alertify.success('Team updated successfully')
    }, error => {
      this.alertify.error('There was an issue updating the project team (PM or Secondary PM).');
    });
  }

  updateProgrammers(value) { 
    this.programmers = [];
    if (value.length > 0) {
      value.forEach(e => {
        this.programmers.push({
          userId: e.userId,
          role: 'programmer',
          fullName: e.fullName,
          email: e.email,
          projectId: this._project.id,
          surveyTesterProjectId: this.surveyId
        });
      });
    }

    this.service.updateProgrammers(this.surveyId, this._project.id, this.programmers).subscribe((data) => {
      this.alertify.success('Programmers have been updated.');
      this.getProgrammer();
    }, error => {
      this.alertify.error('There was a problem updating programmers.');
    });
  }

  updateClient(evt) {
    if (evt.length > 0) {
      if (this._project !== undefined && this._project !== null) {
        this._project.clientId = evt[0].value;
        this._project.clientName = evt[0].name;
      } else {
        this.summaryModel.clientId = evt[0].value;
        this.summaryModel.clientName = evt[0].name;
      }
    } else {
      if (this._project !== undefined && this._project !== null) {
        this._project.clientId = null;
        this._project.clientName = '';
      } else {
        this.summaryModel.clientId = null;
        this.summaryModel.clientName = '';
      }
    }
    this.updateProjectField('clientId');

    this.clientContacts = [];

    if (!this._project.clientId) {
      return;
    }
    let cid = this._project.clientId ?? this.summaryModel.clientId;
    this.clientContactService.GetClientContacts(cid).subscribe(data => {
      this.clientContacts = data;
    });

    this.clientService.GetClient(cid, true).subscribe(data => {
      if (this._project.internalAccountOwnerId == null && data.internalAccountOwnerId != null) {
        this.internalAccountOwner = this.userList.filter(e => e.id == data.internalAccountOwnerId);
        this.updateInternalAccountOwner(this.internalAccountOwner);
      }
      else this.internalAccountOwner = [];
    });
  }

  getClients = () => { return of(this.clients); }

  updateProjectValue(field, value) {
    let fieldLabel = "Field";

    if (field == 'projectName') fieldLabel = 'Project Name';
    else if (field == 'projectManager') fieldLabel = 'Project Manager';
    else if (field == 'projectTeam') fieldLabel = 'Secondary PM';
    else if (field == 'category') fieldLabel = 'Category';
    else if (field == 'projectTags') fieldLabel = 'Tags';
    else if (field == 'countries') fieldLabel = 'Countries';
    else if (field == 'clientId') fieldLabel = 'Client';
    else if (field == 'clientContactId') fieldLabel = 'Point of Contact';
    else if (field == 'internalAccountOwnerId') fieldLabel = 'Account Owner';

    this.projectService.UpdateProjectValue(this._project.id, field, value).subscribe(data => {
      this.alertify.success(fieldLabel + ' Updated Successfully');
      this._project[field] = value;
    }, error => { this.alertify.error('There was an issue updating ' + fieldLabel); console.log(error) });
  }

  uploadFile(event) {
    this.spinnerFile.showSpinner = true;
    this.file = event.target.files[0] as File;
    this.fileExtension = '.' + this.file.name.split('.').pop();

    this.uploader.generatePresignedUrl(this.fileExtension, 'asset', false).subscribe(response => {
      this.presignedUrl = response.uploadUrl;
      this.downloadUrl = response.url;
      this.uploadToS3();
      this.addFile();
    });
  }

  uploadToS3() {
    this.uploader.uploadFileToS3(this.file, this.presignedUrl).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        this.uploadProgress = Math.round(event.loaded / event.total) * 100;
      } else if (event.type === HttpEventType.Response) {
        this.uploading = false;
        this.uploadComplete = true;
      }
    }, error => {
      this.uploading = false;
      this.alertify.error('Failed to upload file');
    }, () => {
      this.uploadCompleted.emit('Complete');
      this.myFileInput.nativeElement.value = '';
      this.alertify.success('File added successfully');
      this.getAssets();
      this.spinnerFile.showSpinner = false;
    });
  }

  addFile() {
    this.assetUpload.url = this.downloadUrl;
    this.assetUpload.filename = this.file.name;
    this.assetUpload.assetType = "file"
    this.uploader.addAsset(this._project.id, this.assetUpload).subscribe(response => { });
  }

  addLink() {
    if (!this.addLinkForm.valid) {
      this.alertify.error('Please fill mandatory fields');
      return;
    }

    this.assetUpload.url = this.addLinkForm.value.link;
    this.assetUpload.filename = this.addLinkForm.value.name;
    this.assetUpload.assetType = "link";

    this.uploader.addAsset(this._project.id, this.assetUpload).subscribe(response => {
      this.alertify.success('Link added successfully');
      this.closeModal();
      this.getAssets();
      this.addLinkForm.reset();
    });
  }

  getAssets() {
    this.uploader.getListOfAssets(this._project.id).subscribe(data => {
      this.assets = data;
      this.assets_Files = data.filter(x => x.assetType === 'file');
      this.assets_Links = data.filter(x => x.assetType === 'link');
    });
  }

  downloadAsset(asset) {
    if (asset.assetType !== 'file') {
      this.alertify.error('Asset is not a file!');
      return
    }

    const fileName = asset.fileName;
    this.uploader.getDownloadUrl(asset.id, fileName).subscribe(data => {
      this.FileSaver.saveAs(data, fileName);
    });
  }

  openLink(asset) {
    if (asset.assetType !== 'link') {
      this.alertify.error('Asset is not a link!');
      return;
    }
    window.open(asset.url, '_blank');
  }

  openModal(template: any, link: any, classes: string = 'modal-sm nav-modal nav-modal-style nav-sidebar-modal') {
    this.link = link;
    this.openModalRef = this.modalService.show(template, { ignoreBackdropClick: true, keyboard: false, class: classes });
  }

  closeModal() {
    if (this.openModalRef) {
      this.openModalRef.hide();
      this.openModalRef = null;
    }
  }

  goToLink(url: string) { window.open(url, '_blank'); }

  confirmDelete() {
    this.uploader.deleteAsset(this.link.id).subscribe(data => {
      if (this._project?.id) {
        this.getAssets();
      }
      else this.getInfoSummary();
      this.alertify.success('This item has been Deleted');
    }, error => {
      this.alertify.error('Failed to delete asset');
    });
    this.closeModal();
  }

  addNewContact(modal) {
    this.contactFirstName = null;
    this.contactLastName = null;
    this.companyPosition = null;
    this.phoneNumber = null;
    this.email = null;
    this.profilePhoto = null;

    this.newContact = {
      id: uuidv4(),
      mode: 'new',
      billing: false,
      firstName: null,
      lastName: '',
      phone: '',
      email: '',
      clientId: this._project.clientId,
      companyPosition: '',
      profilePhoto: '',
      address: {
        address1: '',
        address2: '',
        city: '',
        country: ''
      },
      social: {
        facebook: '',
        linkedIn: '',
        twitter: '',
        skype: ''
      }
    };

    this.openModalRef = this.modalService.show(modal, { animated: true, keyboard: false, class: 'modal-md' });

    const modals = Array.from(document.getElementsByClassName("modal-content") as HTMLCollectionOf<HTMLElement>);
    modals.forEach((modal) => {
      modal.style.width = "85%";
    });
  }

  profilePhotoUploadCompleted(img) {
    img.url = this.profilePhoto;
  }

  saveNewContact() {
    this.newContact.firstName = this.contactFirstName;
    this.newContact.lastName = this.contactLastName;

    this.newContact.companyPosition = this.companyPosition;
    this.newContact.email = this.email;
    this.newContact.profilePhoto = this.profilePhoto;

    if (this.newContact.email == null || this.newContact.email == "") {
      this.alertify.error('Email is required');
      return;
    }

    this.clientContactService.AddClientContact(this.newContact).subscribe(data => {
      this._project.clientContactId = this.newContact.id;
      this.updateProjectField('clientContactId');

      this.clientContactService.GetClientContacts(this._project.clientId).subscribe(data => {
        this.clientContacts = data;
        this.alertify.success('Contact added successfully');
        this.openModalRef.hide();
      });
    }, error => {
      this.alertify.error('Unable to add contact');
    });
  }

  cancelNewContact() {
    this.openModalRef.hide();
  }

  copyEmail() {
    const emailAddress = this._project ? this._project.clientContactEmail : this.summaryModel.clientContactEmail;

    this.utils.copyTextToClipboard(emailAddress);
    this.alertify.success('Point of Contact successfully copied');
  }

  getClient() {
    this.clientService.GetClient(this._project.clientId, true).subscribe(data => {
      this.client = data;
      this.client.notes = null;
      this.client.allNotes = [];
    }, error => {
      this.alertify.error('Unable to get client');
    }, () => {
      this.getClientNotes();
    });
    console.log("Got client and notes!")
  }

  getClientNotes() {

    forkJoin([
      this.clientService.GetClientNotes(this._project.clientId),
      this.lookupService.GetLookupTable('client-note-tag'),
      this.lookupService.GetLookupTable('client-note-subject')
    ]).subscribe(data => {
      var tagFilters = [];
      var subjectFilters = [];
      var memberFilters = [];
      // set up tag dictionary
      if (data[1].length > 0) {
        data[1].forEach(tag => {
          this.tagOptions[tag.id] = tag.value;
        });
      }

      if (data[2].length > 0) {
        var subjects = [];
        this.subjectOptions = {};
        data[2].forEach(subject => {
          this.subjectOptions[subject.id] = subject.value;
          subjects.push({ id: subject.id, name: subject.value })
        })
        this.subjects = subjects;
      }

      // set up notes
      this.client.allNotes = [];
      if (data[0].length > 0) {
        var latest = data[0].filter(x => x.isLatest)
        latest.sort(function (a, b) {
          return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
        });

        latest.forEach(note => {
          note.tagIds = (note.tags.map(tag => tag)).join(',');
          note.createdOn = new Date(note.createdOn);
          if (note.originalNoteId) {
            note.previousVersions = data[0].filter(x => x.id != note.id && (x.originalNoteId == note.originalNoteId || x.id == note.originalNoteId))
            note.previousVersions.sort(function (a, b) {
              return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
            });

            note.previousVersions.forEach(prevNote => {
              prevNote.tagIds = (prevNote.tags.map(tag => tag)).join(',');
            });
          }
          note.tags.forEach(tag => {
            if (!tagFilters.find(x => x.group === 'Tags' && x.value === tag)) {
              tagFilters.push({ label: this.tagOptions[tag], value: tag, group: 'Tags' });
            }
          });

          if (note.subject.trim() != '' && !subjectFilters.find(x => x.group === 'Subjects' && x.value === note.subject)) {
            subjectFilters.push({ label: this.subjectOptions[note.subject], value: note.subject, group: 'Subjects' });
          }

          if (!memberFilters.find(x => x.group === 'Team Members' && x.value === note.createdByName)) {
            memberFilters.push({ label: note.createdByName, value: note.createdByName, group: 'Team Members' })

          }
          this.client.allNotes.push(note);

        });

      }
      this.client.notes = this.client.allNotes;
    }, error => {
      this.alertify.error('Unable to get client Notes');
    }, () => {

    })
  }

  truncate(text: string): string {
    const maxLength = 8; // Define your desired maximum length
    if (text === undefined) return;

    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    } else {
      return text;
    }
  }

}
