import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { AlertifyService, SurveyTesterProject, SurveyTesterService, SurveyTesterUser, User, UserService, UtilsService } from 'core';
import { switchMap, map } from 'rxjs';
import { UnsavedchangesGuardService } from '../../../_guards/unsavedchanges.guard.service';

@Component({
  selector: 'app-project-card-survey-tester',
  templateUrl: './project-card-survey-tester.component.html',
  styleUrls: ['./project-card-survey-tester.component.scss']
})
export class ProjectCardSurveyTesterComponent implements OnInit, OnDestroy {

  @Input() public set project(data) {
    this._project = data;
    this.getProjectInfo();
  }

  currentDate = new Date();
  _project: any;
  surveyId = '';
  surveyUrl = '';
  suveyProject = {} as SurveyTesterProject;
  unsavedChanges = 0;
  programmer: SurveyTesterUser;
  reviewers: SurveyTesterUser[];
  guests: SurveyTesterUser[];
  newGuest = {} as SurveyTesterUser;
  loaded = false;
  hasChanges = false;
  info:any = {};

  @ViewChild('name') name: NgModel;
  @ViewChild('email') email: NgModel;

  constructor(
    private alertify: AlertifyService,
    private utilService: UtilsService,
    private userService: UserService,
    private service: SurveyTesterService,
    public utils: UtilsService,
    private unsavedChangesService: UnsavedchangesGuardService) { }

  ngOnInit() {
    this.unsavedChangesService.register(
      this,
      () => this.unsavedChanges > 0
    );
  }

  getProjectInfo() {

    let url = this._project.projectSegments[0]?.surveyLink || '';

    if (url) {
      this.suveyProject=this.service.getSurveyProject(url, 'decipher');    
      this.service.getSurveyId(this.suveyProject).pipe(
        switchMap(data => {
          this.surveyId = data.id;
          return this.service.getProjectInfo(this.surveyId);
        })
      ).subscribe(data => {
        this.info = data;
        this.programmer = {id: data.programmer?.id, fullName: data.programmer?.fullName};
        this.reviewers = data.reviewers.map(x => ({ id: x.id, fullName: x.fullName }));
        this.guests = data.guests.map(x => ({
          id: x.id,
          fullName: x.fullName,
          email: x.email,
          expiresAt: x.expiresAt
        }));
      }, error => {
        console.log(error);
        this.alertify.error('There was an error getting survey tester id');
      });
    }
  }

  ngOnDestroy(): void {
    this.unsavedChangesService.deregister([this]);
  }

  addGuest() {
    if (!this.newGuest?.id) {
      var expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate()+5); // add 5 days to expiry date

      this.newGuest = {
        id: uuidv4(),
        fullName: '',
        email: '',
        role: 'guest',
        expiresAt: new Date(expiryDate),
        projectId: this._project.id,
        surveyTesterProjectId: this.surveyId
      }
    }
  }

  updateProgrammer(value) {

    this.programmer = { projectId: this._project.id, surveyTesterProjectId: this.surveyId };

    if (value.length > 0) {
      const selected = value[0];

      this.programmer.userId = selected.id;
      this.programmer.role = 'programmer';
      this.programmer.id = selected.id;
      this.programmer.email = selected.email;
      this.programmer.fullName = selected.fullName;
      this.programmer.projectId = this._project.id;
      this.programmer.surveyTesterProjectId = this.surveyId;
    }

    this.service.updateProgrammer(this.programmer).subscribe((data) => {
      this.alertify.success('Programmer has been updated.');
    }, error => {
      this.alertify.error('There was a problem updating programmer.');
    });
  }

  updateReviewers(value) {
    this.reviewers = [];
    if (value.length > 0) {
      value.forEach(e => {
        this.reviewers.push({
          userId: e.id,
          role: 'reviewer',
          id: e.id,
          fullName: e.fullName,
          email: e.email,
          projectId: this._project.id,
          surveyTesterProjectId: this.surveyId
        });
      });
    }

    this.service.updateReviewers(this.surveyId, this._project.id, this.reviewers).subscribe((data) => {
      this.alertify.success('Reviewers have been updated.');
    }, error => {
      this.alertify.error('There was a problem updating reviewers.');
    });
  }

  getUserList = (filter) => {
    return this.userService.GetUsers().pipe(map((res: User[]) => res.map(user => ({id: user.id, fullName: user.name}))));
  }

  formChanged(event) { if (this.loaded) this.hasChanges = true; }

  launchSurvey() {
    window.open(window.location.origin + `/survey-tester?url=${this.surveyUrl}`, '_blank');
  }

  copyLink(user) {
    const guestUrl = window.location.origin + `/survey-tester/guest?token=${user.id}&url=${this.surveyUrl}`;
    this.utilService.copyTextToBuffer(guestUrl);
    this.alertify.success('URL has been copied');
  }

  deleteGuest(user) {
    this.service.removeGuest(user.id).subscribe((data) => {
      this.alertify.success('Guest has been deleted.');
      this.guests.splice(this.guests.findIndex(x => x.id === user.id), 1);
    }, error => {
      this.alertify.error('There was a problem removing guest.');
    });
  }

  saveGuest() {
    if (!this.name.valid || !this.email.valid)
      return false;

    this.service.addGuest(this.newGuest).subscribe((data) => {
      this.alertify.success('Guest has been added.');
      this.newGuest.id = data;
      this.guests.push(this.newGuest);
      this.sendEmail(this.newGuest);
      this.newGuest = {};
      this.addGuest();
    }, error => {
      this.alertify.error('There was a problem adding guest.');
    });
  }

  sendEmail(user) {
    const guestUrl = window.location.origin + `/survey-tester/guest?token=${user.id}&url=${this.surveyUrl}`;
    this.service.sendGuestLink(user, this._project.id, this.surveyId).subscribe(data => {
      if (data) {
        this.alertify.success('Email sent succesfully');
      }
    }, error => {
      this.alertify.error(error);
    });
  }

  saveNotes() {
    this.service.updateNotes(this.surveyId, this.info?.notes).subscribe((data) => {
      this.alertify.success('Notes have been saved.');
    }, error => {
      this.alertify.error('There was a problem saving notes.');
    });
  }

  showCssValidField(field) {
    const isValid = field?.valid;
    return {
      'is-invalid': !isValid,
      'is-valid': isValid
    };
  }

}
