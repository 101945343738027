import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import Highcharts from 'highcharts';
import { DashboardService } from 'projects/core/src/services/dashboard.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-widget-current-projects-tasks',
  templateUrl: './widget-current-projects-tasks.component.html',
  styleUrls: ['./widget-current-projects-tasks.component.scss']
})
export class WidgetCurrentProjectsTasksComponent implements OnInit {
  highcharts = Highcharts;
  highchartOptionsCurrentProjects: Highcharts.Options;
  highchartOptionsTasks: Highcharts.Options

  stageCount;
  taskCount;
  stageColors;
  colorsList = [];

  totalProjects = 0;
  totalTasks = 0;

  constructor(
    private elByClassName: ElementRef,
    public dashboardService: DashboardService,
    private renderer: Renderer2) { }

  ngOnInit(): void {   
    var userIds = localStorage.getItem('dashboard');
    let users: string[] = [];
    if (userIds != null && userIds != '') {
      users = userIds.split(',').map(item => item.trim());
    }
    this.dashboardService.getDashboardChartInfo(users).subscribe(data => {    
      this.stageCount = data.stageCount;
      this.taskCount = data.taskCount;
      this.stageColors = data.stageColors;
      this.setHighchartOptions();
    });
  }

  legendClick(item) {

  }

  onTasksChartLoad(event) {
    if (this.totalProjects > 0) {
      var points = document.getElementsByTagName('path');
      var colors = this.colorsList.concat(['#55BA2E', '#C72F1E']);

      for (let i = 0; i < points.length; i++) {
        this.renderer.setStyle(points[i], 'fill', colors[i]);
        this.renderer.setStyle(points[i], 'fill-opacity', 0.75);
      }
    }
  }

  setHighchartOptions() {
    // Current projects
    var chartData = [];
    for (let [stage, color] of this.stageColors) {
      if (this.stageCount[stage]) {
        chartData.push([stage, this.stageCount[stage]]);
        this.totalProjects += this.stageCount[stage];    
        this.colorsList.push(color);
      }
    }

    var totalProjects = this.totalProjects; // local variable needed for render callback function
    this.highchartOptionsCurrentProjects = {
      credits: {
        enabled: false
      },
      chart: {
        plotBorderWidth: null,
        plotShadow: false,
        margin: [0, 0, 0, 0],
        events: {
          render: function () {
            var centerX = this.plotWidth / 2;
            var centerY = this.plotHeight / 2;
            var label = this.renderer
              .label(totalProjects + ' Projects', centerX, centerY)
              .css({
                color: '#000',
                fontSize: '16px',
              })
              .attr({
                padding: 8,
                r: 5,
              })
              .add();

            var labelBox = label.getBBox();
            label.translate(centerX - (labelBox.width / 2), centerY - (labelBox.height / 2));
            // Remove all labels except for the last label
            const elements = document.getElementById("projectsChart").getElementsByClassName("highcharts-label");
            while (elements.length > 1) elements[0].parentNode.removeChild(elements[0]);
          }
        }
      },
      title: {
        text: ''
      },
      plotOptions: {
        pie: {
          shadow: false,
          center: ['50%', '50%'],
          size: '100%',
          innerSize: '60%',
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: false,
          point: {
            events: {
              legendItemClick: function () {
                return false;
              }
            }
          }
        },
        series: {
          states: {
            hover: {
              halo: null
            }
          }
        }
      },
      series: [{
        type: 'pie',
        data: chartData
      }],
      tooltip: {
        formatter: function () {
          return this.point.name + ": " + this.y + " (" + this.percentage.toFixed(2) + "%)"
        }
      },
      legend: {
        enabled: true,
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        floating: true,
        itemStyle: {
          fontSize: '1.5em',
        }
      }
    };

    this.totalTasks = this.taskCount["open"] + this.taskCount["overdue"];
    var totalTasks = this.totalTasks;

    // Tasks
    this.highchartOptionsTasks = {
      credits: {
        enabled: false
      },
      chart: {
        plotBorderWidth: null,
        plotShadow: false,
        margin: [0, 0, 0, 0],
        events: {
          render: function () {
            var centerX = this.plotWidth / 2;
            var centerY = this.plotHeight / 2;

            var label = this.renderer
              .label(totalTasks + ' Tasks', centerX, centerY)
              .css({
                color: '#000',
                fontSize: '16px',
              })
              .attr({
                padding: 8,
                r: 5,
              })
              .add();

            var labelBox = label.getBBox();
            label.translate(centerX - (labelBox.width / 2), centerY - (labelBox.height / 2));
            // Remove all labels except for the last label
            const elements = document.getElementById("tasksChart").getElementsByClassName("highcharts-label");
            while (elements.length > 1) elements[0].parentNode.removeChild(elements[0]);
          },
          load: this.onTasksChartLoad.bind(this)
        },
      },
      title: {
        text: ''
      },
      plotOptions: {
        pie: {
          shadow: false,
          center: ['50%', '50%'],
          size: '100%',
          innerSize: '60%',
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: false,
          point: {
            events: {
              legendItemClick: function () {
                console.log(this.name);
                return false;
              }
            }
          }
        },
        series: {
          states: {
            hover: {
              halo: null
            }
          }
        }
      },
      series: [{
        type: 'pie',

        data: [
          ['Open', this.taskCount["open"]],
          ['Overdue', this.taskCount["overdue"]],
        ]
      }],
      tooltip: {
        formatter: function () {
          return this.point.name + ": " + this.y + " (" + this.percentage.toFixed(2) + "%)"
        }
      },
      legend: {
        enabled: true,
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        floating: true,
        itemStyle: {
          fontSize: '1.5em',
        }
      }
    };
  }
}
