<div class="card border-0 m-0 w-auto">
    <div class="card-header">
        <div class="row align-items-center">
            <div class="col">
                <h4 class="nav-secondary font-weight-bold mb-0 d-inline">My Scorecard</h4>
            </div>
            <div class="col text-right">
                <span class="nav-font14 mb-0 font-weight-normal grey3">YTD</span>
            </div>
        </div>
    </div>
    <div class="card-body pt-2">
        <div class="row mb-3">
            <div class="col-2 text-center">
                <i class="fak fa-scorecard-tasks fa-xl grey5"></i>
            </div>
            <div class="col-10 text-left">
                <span class="m-0 align-self-center d-block font-weight-bold grey5 nav-font16">{{ this.tasksPercentage }}% Tasks</span>
                <span class="nav-font12 font-weight-normal grey4 my-5">Percentage of tasks done on time</span>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-2 text-center">
                <i class="fak fa-light-folder-clock fa-lg grey5"></i>
            </div>
            <div class="col-10 text-left">
                <span class="m-0 align-self-center d-block font-weight-bold grey5 nav-font16">{{ this.projectsPercentage }}% Projects</span>
                <span class="nav-font12 font-weight-normal grey4 my-5">Percentage of projects done on time</span>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-2 text-center">
                <i class="fak fa-scorecard-projects-completed grey5 fa-xl"></i>
            </div>
            <div class="col-10 text-left">
                <span class="m-0 align-self-center d-block font-weight-bold grey5 nav-font16">{{ this.projectsNumber }} Projects completed</span>
                <span class="nav-font12 font-weight-normal grey4 my-5">Sum of my completed projects</span>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-2 text-center">
                <i class="fa-light fa-dollar-sign fa-lg grey5"></i>
            </div>
            <div class="col-10 text-left">
                <span class="m-0 align-self-center d-block font-weight-bold grey5 nav-font16">{{ this.projectsRevenue }} Revenue</span>
                <span class="nav-font12 font-weight-normal grey4 my-5">Sum of my projects' revenue</span>
            </div>
        </div>
    </div>
</div>
