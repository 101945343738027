import { Component, OnInit, ViewChild } from '@angular/core';
import {ReportsService } from 'core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-client-report',
  templateUrl: './client-report.component.html',
  styleUrls: ['./client-report.component.scss']
})
export class ClientReportComponent implements OnInit {
  iframeUrl = '';
  reportId = '166';
  @ViewChild('myIframe') public myIframe;

  constructor(private reportService: ReportsService, private route: ActivatedRoute) {

    this.route.params.subscribe((params) => {
      this.reportId = (params.mode == 'invoice') ? "166" : "168";
      this.loadDashboard();
    });
  }
  ngOnInit() {
  }
  loadDashboard(){
    this.reportService.GenerateMetabaseEmbedToken(this.reportId, '').subscribe( data => {
      this.iframeUrl = data;
      const iframe = this.myIframe.nativeElement;
      if (iframe != null) {
        iframe.src = this.iframeUrl;
      }
     }, error => {
 
     });
  }
}
