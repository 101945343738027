import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ClientService, AlertifyService, UtilsService, AuthService } from 'core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UnsavedchangesGuardService } from '../../_guards/unsavedchanges.guard.service';
import { FilterPipe } from 'projects/core/src/helpers/filter.pipe';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
  providers: [ClientService, FilterPipe]
})
export class ClientsComponent implements OnInit {
  clients: any[] = [];
  allClients: any[] = [];
  numToLoad = 50;
  showLoadMore = false;
  filterColumns = ['name'];
  filterText = '';
  clientForm: FormGroup;
  submitted = false;
  model: any;

  filter = {
    orderBy: '',
    orderDirection: ''
  };

  @ViewChild('modalClient')
  modalRef: TemplateRef<any>;

  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;

  openModalRef: any;

  constructor(private clientService: ClientService,
              private router: Router,
              public utils: UtilsService,
              public auth: AuthService,
              private modalService: BsModalService,
              private filterPipe: FilterPipe,
              private alertify: AlertifyService,
              private unsavedChangesService: UnsavedchangesGuardService) { }

  ngOnInit() {
    this.getClients();
    this.createClientForm();
  }

  createClientForm() {
    this.clientForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      img: new FormControl(''),
      website: new FormControl(''),
      address1: new FormControl(''),
      address2: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      zip: new FormControl(''),
      country: new FormControl(''),
      instanceId: new FormControl(this.auth.getUser().instanceId)
    });
  }

  getClients() {
    this.clientService.GetClients('').subscribe(
      data => {
        this.allClients = data.sort( (a, b) => { return (a.name.toLowerCase() > b.name.toLowerCase())? 1: -1 } );
        this.updateList();
      },
      error => {
        this.alertify.error('Unable to get clients');
      }
    );
  }

  openClient(client){
    this.router.navigateByUrl('/clients/' + client.id);
  }

  showMore() {
    this.numToLoad += 10000;
    this.updateList();
  }

  SortBy(field) {
    if (field == this.filter.orderBy) {
      this.filter.orderDirection = (this.filter.orderDirection == 'ASC') ? 'DESC' : 'ASC'
    } else {
      this.filter.orderBy = field;
      this.filter.orderDirection = 'ASC';
    }
    this.sort(this.clients, this.filter.orderBy, this.filter.orderDirection);
  }

  sort(array, type, direction) {
    array.sort((a, b) => {
      var A;
      var B;
      if (type == 'name') {
        A = a.name;
        B = b.name;
      }

      if (type == 'website') {
        A = a.website;
        B = b.website;
      }
      else if (type == 'address') {
        A = a.address;
        B = b.address;
      }
      else if (type == 'contact') {
        A = a.contact;
        B = b.contact;
      }

      let comparison = 0;
      if (direction === 'ASC') {
        if (A > B) {
          comparison = 1;
        } else if (A < B) {
          comparison = -1;
        }
        return comparison;
      }
      else {
        if (A < B) {
          comparison = 1;
        } else if (A > B) {
          comparison = -1;
        }
        return comparison;
      }

    });
    return array;
  }

  updateList() {
    const filter = this.filterText.toLowerCase();
    if(filter === ''){
      this.clients = this.allClients;
    }
    else {
      this.clients = this.filterPipe.transform(this.allClients, filter, ['name']);
    }
    this.showLoadMore = (this.clients.length > this.numToLoad);
    this.clients = this.clients.slice(0, this.numToLoad);

    this.clients = this.clients.map(client => {
      let address = [
        client.address.address1,
        client.address.address2,
        client.address.city,
        client.address.state,
        client.address.zip,
        client.address.country
      ].filter(part => !!part).join(', ');

      client.fullAddress = address;
      return client;
    });
  }

  clientAdd() {
    this.model = {};
    this.createClientForm();
    this.unsavedChangesService.register(
      this.clientForm,
      (form) => form.dirty
    );
    this.openModalRef = this.modalService.show(this.modalRef, {class: 'nav-modal-style modal-md'});
  }

  clientEdit(client) {
    this.model = client;
    this.bindForm();
    this.openModalRef = this.modalService.show(this.modalRef, {class: 'nav-modal-style modal-md'});
  }

  clientDelete(client) { 
    this.model = client;
    var dayDifference = this.utils.getDialogItems('client');    
    if (dayDifference == -1) {     
      this.openModalRef = this.modalService.show(this.modalRefConfirm, { class: 'nav-modal-style' });
    }else{      
      this.clientService.DeleteClient(client).subscribe(data => {
        if (data) {
          this.alertify.success('Client deleted successfully');
          this.model = {};         
          this.getClients();
        }
      }, error => {
         this.alertify.error('Unable to delete client');
      });
    }  
  }

  bindForm() {
    if (this.model) {
        this.clientForm = new FormGroup({
          id: new FormControl(this.model.id),
          name: new FormControl(this.model.name, [Validators.required]),
          img: new FormControl(this.model.img),
          website: new FormControl(this.model.website),
          address1: new FormControl(this.model.address.address1),
          address2: new FormControl(this.model.address.address2),
          city: new FormControl(this.model.address.city),
          state: new FormControl(this.model.address.state),
          zip: new FormControl(this.model.address.zip),
          country: new FormControl(this.model.address.country),
          instanceId: new FormControl(this.auth.getUser().instanceId),
      });
    }
  }

  uploadCallback(event) {
    this.model.img = event.file;
    this.clientForm.controls['img'].setValue(event.file);
  }

  closeModal() {
    this.unsavedChangesService.deregister([this.clientForm]);
    this.openModalRef.hide()
  }

  confirmDelete() {
    this.clientService.DeleteClient(this.model).subscribe(data => {
      if (data) {
        this.alertify.success('Client deleted successfully');
        this.model = {};
        this.closeModal();
        this.getClients();
      }
    }, error => {
       this.alertify.error('Unable to delete client');
    });
  }

  clientSave() {

    this.submitted = true;

    if (!this.clientForm.valid) {
      return;
    }

    if (!this.clientForm.value.id) {
      this.clientService.AddClient(this.clientForm.value).subscribe( data => {
        if (data) {
          this.alertify.success('Client added successfully');
          this.model = {};
          this.closeModal();
          this.getClients();
          this.createClientForm();
        }
      }, error => {
         this.alertify.error('Unable to add client');
      });
    } else {
        this.clientService.EditClient(this.clientForm.value).subscribe( data => {
        if (data) {
          this.alertify.success('Client edited successfully');
          this.model = {};
          this.closeModal();
          this.getClients();
          this.createClientForm();
        }
      }, error => {
         this.alertify.error('Unable to edit client');
      });
    }

  }

}
