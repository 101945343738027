import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Project, AlertifyService, ClientService, ProjectDataService,ProjectService, UserService, AuthService, FavoritesService } from 'core';
import { v4 as uuidv4 } from 'uuid';
import { UnsavedchangesGuardService } from '../../../_guards/unsavedchanges.guard.service';

@Component({
  selector: 'app-project-card-info',
  templateUrl: './project-card-info.component.html',
  styleUrls: ['./project-card-info.component.scss']
})
export class ProjectCardInfoComponent implements OnInit, OnDestroy {

  @Input() public set project(data: Project) {
    this._project = data;  
  }
  _project: Project;
  @Input() mode = 'reduced';
  clientMode = 'view';
  editEnabled: any = {};
  projectManager;
  projectTeam;
  user;
  isFavorite = false;
  favorites: any;
  isVendorview: boolean = false;
  hasSurveyUrl: boolean;
  isDecipherUrl: boolean;
  surveyLink: string;
  type: string;

  checkDecipher: string = 'selfserve.decipherinc.com';
  hasUnsavedChanges = false;

  @Output() cardresize = new EventEmitter();

  constructor(
    private projectService: ProjectService,
    private alertify: AlertifyService,
    private clientService: ClientService,
    private projectDataService: ProjectDataService,
    private userService: UserService,
    private unsavedChangesService: UnsavedchangesGuardService,
    private favoritesService: FavoritesService,
    public auth: AuthService) { }

  ngOnInit() {
    // For now we have simple UI for PM and Secondary PM but future version will allow different roles
    this.user = this.auth.getUser();
    this.mode = 'reduced';
    this.projectManager = this._project.projectTeam.filter( e => e.projectRole === 'PM').map( e => e.displayName);
    this.projectTeam = this._project.projectTeam.filter( e => e.projectRole == 'Secondary PM').map( e => e.displayName);
    this.parseSurveyUrls(this._project.projectSegments);
    this.type = this._project.type;
    this.unsavedChangesService.register(this.editEnabled, () => this.hasUnsavedChanges);
    this.favoritesService.getProjectFavorites().subscribe(data => {
      this.favorites = data;
      const exists = data.find(x => x.projectId === this._project.id);
      this.isFavorite = exists === undefined || exists === null ? false : true;
    }, error => { console.log(error); });

    this.projectDataService.getVendorViewSidebar.subscribe(data => {
      if (data) {
        this.isVendorview = data != '';
      }
    });

  }

  ngOnDestroy(): void { this.unsavedChangesService.deregister([this.editEnabled]); }


  enablePreferenceEdit() { this.clientMode = 'edit'; }
  enableEdit(field) {
    this.hasUnsavedChanges = true;
    if (field === 'projectEndDate') {
      this.editEnabled[field] = new Date(this._project.projectEndDate);
    } else if (field === 'projectTags') {
      this.editEnabled[field] = this._project[field].map(e => e.tag).join(',');
    } else if (field === 'projectManager') {
      this.editEnabled[field] = this._project.projectTeam.filter( e => e.projectRole === 'PM').map( e => ({id: e.userId, name: e.displayName}));
    } else if (field === 'projectTeam') {
      this.editEnabled[field] = this._project.projectTeam.filter( e => e.projectRole !== 'PM').map( e => ({id: e.userId, name: e.displayName}));
    }
  }

  cancelEdit(field: string) {
    this.editEnabled[field] = null;
    this.checkForUnsavedChanges();
  }

  saveField(field) {
    if (field === 'projectManager' || field === 'projectTeam') {
      let team = (field === 'projectManager')
        ? this._project.projectTeam.filter( e => e.projectRole !== 'PM')
        : this._project.projectTeam.filter( e => e.projectRole === 'PM');
      if (this.editEnabled[field].length > 0) {
        const role = (field === 'projectManager') ? 'PM' : 'Secondary PM';
        const newTeam = this.editEnabled[field]
          .map( e => ({id: uuidv4(), userId: e.id, displayName: (e.name ?? e.firstName + ' ' + e.lastName), projectRole: role}));
        team = team.concat(newTeam);
      }
      this.projectService.UpdateProjectTeam(this._project.id, team).subscribe(data => {
        this._project.projectTeam = team;
        this.projectManager = team.filter( e => e.projectRole === 'PM').map( e => e.displayName);
        this.projectTeam = team.filter( e => e.projectRole !== 'PM').map( e => e.displayName);
        this.alertify.success('Team Updated Successfully');
      }, error => { this.alertify.error('There was an issue updating the Team'); });
    } else if (field === 'projectTags') {
      const tags = this.editEnabled[field].split(',');
      this.projectService.UpdateProjectTags(this._project.id, tags).subscribe(data => {
        let newTags = [];
        tags.forEach(e => {
          if (e !== '') newTags.push({tag: e});
        });
        if (this._project.projectTags.length !== newTags.length) this.alertify.success('Tags Updated Successfully');
        else this.alertify.warning('Tags not updated. Must hit enter button when adding a tag.');
        this._project.projectTags = newTags;
      }, error => {
        this.alertify.error('There was an issue updating the tags');
      });
    } else if (field === 'projectEndDate') {
      if (this.editEnabled[field] >= new Date(this._project.projectStartDate)) {
        this.updateProjectValues(field);
      } else {
        this.alertify.error("Cannot set end date date before start date");
      }
    } else { this.updateProjectValues(field); }
    this.editEnabled[field] = null;
    this.checkForUnsavedChanges();
  }
  updateProjectValues(field) {
    let fieldLabel = "Field";
    if(field == 'projectEndDate') fieldLabel = 'Project End Date';
    else if(field == 'projectTags') fieldLabel = 'Tags';
    else if(field == 'projectManager') fieldLabel = 'Project Manager';
    else if(field == 'projectTeam') fieldLabel = 'Project Team';

    const newValue = this.editEnabled[field];
    this.projectService.UpdateProjectValue(this._project.id, field, newValue).subscribe(data => {


      this.alertify.success(fieldLabel + ' Updated Successfully');
      this._project[field] = newValue;
    }, error => { this.alertify.error('There was an issue updating ' + fieldLabel);});
  }

  saveClient() {
    this.clientService.UpdateClientPreference(this._project.clientId, this._project.clientPreference).subscribe(data => {
    }, error => {
      this.alertify.error('Unable to update Client preference');
    }, () => {
      this.alertify.success('Client preference updated successfully');
    });
    this.clientMode = 'view';
  }

  cancelClient() {
    this.clientMode = 'view';
  }

  getUserList = (filter) => { return this.userService.GetUsers(); }

  parseSurveyUrls(segments) {
    if (segments.length === 0) { this.hasSurveyUrl = false; }
    else {
      if (segments[0].surveyLink === '') this.hasSurveyUrl = false;
      else {
        this.hasSurveyUrl = true;
        this.surveyLink = this.parseUrl(segments[0].surveyLink);
      }
    }
  }
  parseUrl(link: string) {
    /**
     * An example of the parsing.
     *
     * BEFORE:
     * https://selfserve.decipherinc.com/survey/selfserve/1f07/200701?list=114&or3=#or3#&or1=#or1#
     * https://selfserve.decipherinc.com/survey/selfserve/1f07/200701/temp-edit-live?list=217&or3=#or3#&or1=#or1#
     * AFTER:
     * https://selfserve.decipherinc.com/rep/selfserve/1f07/200701:dashboard?tab=quota
     */
    if (!link.includes(this.checkDecipher)){
      this.isDecipherUrl = false;
      return link;
    }

    this.isDecipherUrl = true;

    // These are hardcoded positions and subject to change. For now though they seem to be correct
    const removedQmark = link.split('?');
    const linkParts = removedQmark[0].split('/');
    const params = linkParts[5] + '/' + linkParts[6];
    return 'https://selfserve.decipherinc.com/rep/selfserve/' + params + ':dashboard?tab=quota';
  }
  goToLink(url: string) { window.open(url, '_blank'); }

  checkForUnsavedChanges() {
    let unsavedChanges = false;
    for (let editVal of Object.values(this.editEnabled)) {
      if (editVal) { unsavedChanges = true; }
    }
    this.hasUnsavedChanges = unsavedChanges;
  }

  toggleFavorite() {
    this.isFavorite = !this.isFavorite;
    if (this.isFavorite) {
      this.favoritesService.setProjectFavorite(this._project.id).subscribe(() => {
        this.favoriteChange();
      }, error => console.error(error));
    } else {
      this.favoritesService.removeProjectFavorite(this._project.id).subscribe(() => {
        this.favoriteChange();
      }, error => console.error(error));
    }
  }
  favoriteChange() { this.favoritesService.getProjectFavorites(true).subscribe(data => { }, error => { }); }
}
