import { Component, Input, Output, EventEmitter, OnChanges, ViewChild } from '@angular/core';
import { AlertifyService, AuthService, SurveyTesterService } from 'core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'survey-tester-comments',
  templateUrl: './survey-tester-comments.component.html',
  styleUrls: ['./survey-tester-comments.component.scss']
})
export class SurveyTesterCommentsComponent implements OnChanges {
  @Input() public allComments;


  @Input() public set data(args) {

    this.client = args.client.toLowerCase();
    this.surveyId = args.surveyId || '';
    this.sessionId = args.sessionId || '';
    this.sessionFilterId = args.sessionFilterId || '';
    this.sessionFilterName = args.sessionFilterName || '';
    this.questionId = args.questionId || '';
    this.answerId = args.answerId || '';
    this.surveyStatus = args.surveyStatus || 'open';
    this.questionName = (this.client == 'decipher') ? this.questionId.replace('question_','') : this.questionId;
    this.statusFilter = args.statusFilter;
    this.isLive = args.isLive;
  }
  comments;
  totalNewComments = 0;
  totalPendingComments = 0;
  surveyStatus;
  answerId = '';
  client = ''
  questionId = '';
  questionName = '';
  surveyId = '';
  sessionId = '';
  sessionFilterId = '';
  sessionFilterName = '';
  isLive = false;
  activities = [];
  statusFilter = ['open'];
  openReply = {};
  isCollapsed = false;
  notAskAgainApproval = false;
  notAskAgainApprove = false;
  notAskAgainReopen = false;
  commentId = '';

  layout = [
    { label: 'Question', id: 'questionID' },
    { label: 'Selected Answer', id: 'questionText' },
  ];

  openModalRef: any;
  @ViewChild('modalConfirmApprove') modalRefApprove: BsModalRef;
  @ViewChild('modalConfirmSentForApproval') modalRefSentForApproval: BsModalRef;
  @ViewChild('modalConfirmReopen') modalRefReopen: BsModalRef;
  @ViewChild('modalConfirmCancel') modalRefCancel: BsModalRef;
  @ViewChild('modalSurveyPath') modalRefSurveyPath: BsModalRef;

  editorConfig = {
    toolbar: {
      language: 'en',
      items: [
        'bold',
        'italic',
        'underline',
        'link',
        'bulletedList',
        'numberedList'
      ]
    }
  };

  @Output() callback = new EventEmitter();

  constructor(
    public auth: AuthService,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private service: SurveyTesterService) { }

  ngOnChanges() {
    this.processComments();
  }

  processComments() {

    this.totalPendingComments = this.allComments.filter(x => x.status == 'pending').length;
    this.totalNewComments = this.allComments.filter(x => x.status == 'open' && x.isNew).length;
    this.allComments = this.allComments.map(x => {
      x.totalNewReplies = x.replies.filter(x => x.isNew).length;
      return x;
    });
    this.displayComments();
  }

  displayComments() {
    if (this.statusFilter.includes('closed')) {
      this.statusFilter.push('approved', 'cancelled');
    }

    this.comments = this.allComments.filter(item => this.statusFilter.includes(item.status));

    if (this.questionId != "") {
      this.comments = this.comments.filter(item => item.questionId == this.questionId);
    }
    if (this.answerId != "") {
      this.comments = this.comments.filter(item => item.answerId == this.answerId);
    }
    if (this.sessionFilterId != "") {
      this.comments = this.comments.filter(item => item.createdSessionId == this.sessionFilterId);
    }
  }
  viewSurveyPath(comment) {
    this.service.getSessionActivities(comment?.createdSessionId).subscribe((activity) => {
      activity.forEach(item => {
        item.timeStamp = new Date(item.timeStamp);
        item.answers = JSON.parse(item.answers);
        item.respondAnswerId = JSON.parse(item.respondAnswerId);
        item.chosenAnswers = this.GetChosenAnswers(item);
        let chosenIds = [];
        let chosenText = [];
        item.chosenAnswers.forEach(a => {
          chosenIds.push(a.answerId);
          chosenText.push(a.answerText);
        });
        item.answerID = chosenIds.join(',');
        item.answerText = chosenText.join(',');
        item.questionID = item.questionID.replace('question_','');
      });
      this.activities = activity.sort((a, b) => (a.timeStamp - b.timeStamp));
    });

    this.openModal(this.modalRefSurveyPath, 'modal-sm st-modal st-modal-style survey-path');
  }

  GetChosenAnswers(item) {
    var chosenAnswers = []
    var listOfRepsonses = item.respondAnswerId;
    if (listOfRepsonses) {
      listOfRepsonses.forEach(id => {
        item.answers.forEach(answer => {
          if (answer.answers.answerId == id) {
            chosenAnswers.push({ answerId: id, answerText: answer.answers.answerText })
          }
        });
      });
    }
    return chosenAnswers;
  }

  toggleFilter(status) {
    this.statusFilter = [];
    this.statusFilter.push(status);
    this.totalNewComments = 0;
    this.totalPendingComments = 0;
    this.displayComments();
  }

  jumpToQuestion(question) {
    this.callback.emit({ type: 'or_surveytester_jump', question });
  }

  receiveMessage(comment, $event) {
    comment.replyText = $event;
  }

  getCount(comment, $event) {
    comment.commentLength = $event;
  }

  openModal(template: any, classes: string = 'modal-sm st-modal st-modal-style') {
    this.openModalRef = this.modalService.show(template, { ignoreBackdropClick: true, keyboard: false, class: classes });
  }

  export() {

  }

  cleanFilters() {
    this.questionId = '';
    this.questionName = '';
    this.answerId = '';
    this.sessionId = '';
    this.sessionFilterId = '';
    this.sessionFilterName = '';
    this.statusFilter = ['open'];
    this.displayComments();
    this.callback.emit({ type: 'clearFilters' });
  }

  confirm(type, commentId) {
    this.commentId = commentId;

    switch (type) {
      case 'sendForApproval':
        let keepSendForApproval = localStorage.getItem('keepSendForApproval');
        (keepSendForApproval == null) ? this.openModal(this.modalRefSentForApproval) : this.sendForApproval();
        break;
      case 'approve':
        let keepApprove = localStorage.getItem('keepApprove');
        (keepApprove == null) ? this.openModal(this.modalRefApprove) : this.approve();
        break;
      case 'reopen':
        let keepReopen = localStorage.getItem('keepReopen');
        (keepReopen == null) ? this.openModal(this.modalRefReopen) : this.reopen();
        break;
      case 'cancel':
        this.openModal(this.modalRefCancel);
        break;
    }
  }
  refreshComments() {
    this.callback.emit({ type: 'or_surveytester_refresh' });
  }
  sendForApproval() {
    if (this.notAskAgainApproval)
      localStorage.setItem('keepSendForApproval', '1');

    this.service.sendForApproval(this.commentId, this.sessionId).subscribe((data) => {
      this.refreshComments();

      if (this.openModalRef)
        this.openModalRef.hide();
    },
      error => {
        this.alertify.error('There was an error sending comment for approval.');
        console.log(error);
      }, () => {
        this.alertify.success('Comment has been sent for approval.');
      });
  }

  cancel() {
    this.service.cancel(this.commentId, this.sessionId).subscribe((data) => {
      this.refreshComments();

      if (this.openModalRef)
        this.openModalRef.hide();
    },
      error => {
        this.alertify.error('There was an error cancelling comment.');
        console.log(error);
      }, () => {
        this.alertify.success('Comment has been cancelled.');
      });
  }

  approve() {
    if (this.notAskAgainApprove)
      localStorage.setItem('keepApprove', '1');

    this.service.approve(this.commentId, this.sessionId).subscribe((data) => {
      this.refreshComments();

      if (this.openModalRef)
        this.openModalRef.hide();
    },
      error => {
        this.alertify.error('There was an error approving comment.');
        console.log(error);
      }, () => {
        this.alertify.success('New comment has been approved.');
      });
  }

  reopen() {
    if (this.notAskAgainReopen)
      localStorage.setItem('keepReopen', '1');

    this.service.reopen(this.commentId, this.sessionId).subscribe((data) => {
      this.refreshComments();

      if (this.openModalRef)
        this.openModalRef.hide();
    },
      error => {
        this.alertify.error('There was an error re-opening.');
        console.log(error);
      }, () => {
        this.alertify.success('Comment has been re-opened.');
      });
  }

  cancelReply(comment) {
    this.openReply[comment.id] = false;
    comment.replyText = '';
  }

  viewMore(comment) {
    this.jumpToQuestion(comment);
    this.toggleReply(comment);
  }

  toggleReply(comment) {
    this.openReply[comment.id] = !this.openReply[comment.id];
    comment.expanded = !comment?.expanded;

    this.service.updateReplyViewDate(comment.id).subscribe((data) => {
      comment.totalNewReplies = 0;
    },
      error => {
        this.alertify.error('There was an error updating comment view date.');
        console.log(error);
      });
  }

  saveReply(comment) {

    let newReply = { surveyTesterCommentsId: comment.id, replyText: comment.replyText };

    if (!newReply.replyText || comment?.commentLength == 0) {
      this.alertify.error('Please write a comment.');
      return;
    }

    this.service.reply(newReply).subscribe((data) => {
      this.refreshComments();
      this.openReply[comment.id] = false;
    },
      error => {
        this.alertify.error('There was an error commenting.');
      }, () => {
        this.alertify.success('New comment has been saved.');
      });
  }

}
