import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DashboardService } from 'projects/core/src/services/dashboard.service';

@Component({
  selector: 'app-widget-project-favourite',
  templateUrl: './widget-project-favourite.component.html',
  styleUrls: ['./widget-project-favourite.component.scss']
})
export class WidgetProjectFavouriteComponent implements OnInit {

  @Input() widget;
  @Output() onremove = new EventEmitter();
  @Output() onupdate = new EventEmitter();

  tableData = [];

  settings = {
    myProjects: false,
    maxCount: null
  };

  filterColumns = ['projectName', 'clientName'];
  filterText = '';

  layout = [
    {label: 'Code', id: 'projectCode', sortable: true, filterable: true, selectable: true},
    {label: 'Name', id: 'projectName', sortable: true, filterable: true, selectable: true},
    {label: 'Client', id: 'clientName', sortable: true, filterable: true},
    {label: 'PM', id: 'users', type: 'users'}
  ];

  constructor(public dashboardService: DashboardService) { }

  ngOnInit() { this.projectFavourites(); }

  projectFavourites() {
    this.dashboardService.getProjectFavourite(this.settings).subscribe(data => {
      if (data.length <= 0)
        return;

      this.tableData = data.map(item => {
        item.users = [];
        item.users.push({ name: item.manager });
        return item;
      });

    }, error => console.log(error));
  }
  remove() { this.onremove.emit(); }

  updateWidget() {
    this.onupdate.emit();
  }
  
  updateTitle(widgetTitle) {
    this.widget.title = widgetTitle;
    this.updateWidget();
  }
}

