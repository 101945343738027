import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {AuthService,ProjectDataService} from 'core';

@Component({
  selector: 'app-project-card-closed',
  templateUrl: './project-card-closed.component.html',
  styleUrls: ['./project-card-closed.component.scss']
})
export class ProjectCardClosedComponent implements OnInit {

  @Input() project: any;
  @Output() selected = new EventEmitter();

  stagePercentageComplete = null;
  projectManagerNames = [];
  pageName: string;
  statusVisible = true;
  constructor(
    private projectDataService: ProjectDataService,
    public auth: AuthService) {}

  ngOnInit() {
    this.project.stageDaysLeft = this.getCountdownString(this.project.stageDueDate)
    this.stagePercentageComplete = ((this.project.stageCompleteTaskCount / this.project.stageTotalTaskCount ) * 100) || 0

    if (this.project.projectManager)
      this.projectManagerNames.push({ name: this.project.projectManager, type: 'pm' });

    this.projectDataService.getPageName.subscribe(data => {
      if (data == 'ProjectPerformance') {   
        this.pageName='ProjectPerformance';
      }
    });

    //TODO: Check if map is correct on string
    //this.project.secondPm?.map(e => this.projectManagerNames.push({ name: e, type: 'secondPm' }));
    //this.project.accountOwner?.map(e => this.projectManagerNames.push({ name: e.name, type: 'accountOwner' }));
  }
  ngAfterViewInit(){
    if(this.pageName === 'ProjectPerformance' && this.project.projectStatus === 'Invoiced') {
      this.statusVisible = false;
    }
  }
  projectClicked(project) {
    this.selected.emit(project);
  }

  getCountdownString(inDate) {
    if (inDate == null) {
        return '';
    }
    const msPerDay = 1000 * 3600 * 24; // transform milliseconds to total of days (milliseconds * seconds * hours)
    const theDate = new Date(inDate).valueOf();
    const totalDaysLeft = Math.ceil((theDate - new Date().valueOf()) / msPerDay);
    return totalDaysLeft < 0 ? 0 : totalDaysLeft;
  }

  inBetween(date1, date2 ) {
    //Get 1 day in milliseconds
    var one_day=1000*60*60*24;

    // Convert both dates to milliseconds
    var date1_ms = date1.getTime();
    var date2_ms = date2.getTime();

    // Calculate the difference in milliseconds
    var difference_ms = date2_ms - date1_ms;

    // Convert back to days and return
    var result = Math.round(difference_ms/one_day);
    return result
  }

  getProgressStyle() {
    if (this.project.projectStatus === 'Closed' || this.project.projectStatus === 'Invoiced') {
      return 'nav-bg-accent-blue';
    } else if (this.project.projectStatus === 'Paused') {
      return 'or-darkgray-bg';
    }  else if (this.project.percentageComplete < (100 - this.project.percentageDaysLeft)) {
      return 'nav-bg-error';
    } else return '';
  }
}
