<div class="nav-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <a class="btn nav-btn-accent btn-sm mb-2 mr-2 shadow-none"
                    href="https://nav2.navigatorsurveys.com/onlinehelp?page=en/kb/articles/dashboards-vendor" id="help_link"
                    target="_new"><i class="fa-regular fa-book-open"></i>&nbsp;&nbsp;View Knowledge base</a>
            </div>
        </div>
        <div>
            <iframe #myIframe frameborder="0" width="100%" height="8000" allowtransparency></iframe>
        </div>
    </div>
</div>