<div class="nav-content">
  <div class="container-fluid" *ngIf="currentView != null">
    <div class="mr-4">
      <div class="row">
        <div class="col">
          <div *ngIf="views.length ==  1" class="float-left h5 or-secondary m-1 mr-3">{{ currentView.name }}</div>
          <ng-container *ngIf="views.length > 1">
            <div class="btn-group mr-3 mb-1 id-projectlist-listview-dropdown" dropdown>
              <button dropdownToggle type="button" class="bg-transparent btn-outline-secondary border-0">
                <span class="mr-2 nav-font24 grey6 font-weight-bold">{{ currentView.name }}</span>
                <i class="fak fa-down-arrow fa-xs grey4"></i>
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" style="width: 300px;">
                <li role="menuitem" *ngFor="let view of views; index as i" class="p-2">
                  <or-action-button [label]="view.name" [description]="view.description" [icon]="view.icon + ' mt-2'"
                    (selected)="switchView(i)"></or-action-button>
                </li>
              </ul>
            </div>
          </ng-container>
          <div *ngIf="statusCounts.length > 1" class="btn-group flex-sm-wrap" role="group" id="statusButtons">
            <ng-container *ngFor="let item of statusCounts">
              <button class="btn shadow-sm btn-outline-secondary px-1 mb-1"  [ngClass]="'id-projectlist-status-' + item.status.toLowerCase().replace(' ', '')"
                [class.active]="activeFilters.includes(item.status)" [attr.data-status]="item.status"
                (click)="filterStatus(item.status)">
                <div class="d-flex">
                  <div class="mr-2">
                    <i class="fa-status" [ngClass]="item.status === 'All' ? 'fas' : 'fak'"></i>
                  </div>
                  <div class="d-flex align-items-center justify-content-center vl">
                    <div class="d-flex align-items-center">
                      <span class="font-weight-bold mx-2">{{ item.count || 0 }}</span>
                      <p class="small mr-2 m-0 status">{{ item.status }}</p>
                    </div>
                  </div>
                </div>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row mt-2 mb-4">
        <div class="col d-flex align-items-center">
          <div *ngIf="currentView.showFilterText" class="ml-2">
            <div class="input-group project-search">
              <div class="input-group-prepend">
                <div class="input-group-text border-right-0">
                  <i class="fas fa-search cursor-default"></i>
                </div>
              </div>
              <input [(ngModel)]="settings.filterText" type="search" class="form-control form-control-sm"
                (ngModelChange)="updateProjectList()" [placeholder]="currentView.filterTextLabel">
            </div>
          </div>
          <div *ngIf="currentView.viewModes.length > 1">
            <span class="nav-font14 grey5 ml-3 mr-2">View</span>
            <or-view-toggle (valueChange)='switchMode($event)' [showList]="showList" [showCard]="showCard"
              [showGantt]="showGantt" [showCalendar]="showCalendar" [showCardGroup]="showCardGroup"
              [active]="mode"></or-view-toggle>
          </div>
          <ul class="list-group list-group-horizontal ml-3">
            <li *ngIf="currentView.showFavouriteButton" class="id-projectlist-favorites list-group-item border-0 p-1 bg-transparent">
              <or-toggle-button id="myFavorites" (changed)="updateProjectList()"
                [(ngModel)]="settings.views[currentView.id].myFavorites" icon="fa-star"
                iconTooltip="My Favorites"></or-toggle-button>
            </li>
            <li *ngIf="auth.isInternal() && currentView.showMyProjects"
              class="id-projectlist-myprojects list-group-item border-0 p-1 bg-transparent">
              <or-toggle-button id="myProjects" (changed)="updateProjectList()"
                [(ngModel)]="settings.views[currentView.id].myProjects" icon="fa-user"
                iconTooltip="My Projects"></or-toggle-button>
            </li>
          </ul>
          <div class="btn-group ml-auto" dropdown *ngIf="hasSettings() && this.auth.isVendor()">
            <span id="projectImportExportDropdown" dropdownToggle type="button" class="btn-lg p-2"
              aria-controls="dropdown-basic">
              <i class="fak fa-ellipsis-vertical grey4 ml-1"></i>
            </span>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
              aria-labelledby="projectImportExportDropdown" style="width: 250px;">
              <li role="menuitem" *ngIf="currentView.showDownload" class="p-2">
                <or-action-button label="Participant Export" description="Download participants across projects"
                  icon="fak fa-download" (selected)="bulkDLParticipantExport()"></or-action-button>
              </li>
            </ul>
          </div>

          <div class="btn-group ml-auto" dropdown *ngIf="hasSettings() && this.auth.isInternal()">
            <span id="projectImportExportDropdown" dropdownToggle type="button" class="btn-lg p-2"
              aria-controls="dropdown-basic">
              <i class="fak fa-ellipsis-vertical grey4 ml-1"></i>
            </span>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
              aria-labelledby="projectImportExportDropdown" style="width: 250px;">
              <li role="menuitem" *ngIf="currentView.showDownload" class="p-2">
                <or-action-button label="Participant Export" description="Download participants across projects"
                  icon="fak fa-download" (selected)="bulkDLParticipantExport()"></or-action-button>
              </li>
              <li role="menuitem" *ngIf="currentView.showCopyUrls" class="p-2">
                <or-action-button label="Copy Entry Urls" description="Get URL's for projects in list"
                  icon="fak fa-copy" (selected)="openModal(modalUrls, 'URL')"></or-action-button>
              </li>
              <ng-container *ngIf="mode == 'card' || mode == 'cardGroup'">
                <li role="menuitem" class="p-2" *ngIf="settings.views[currentView.id].groupBy != 'status'">
                  <or-action-button label="Group by Status" description="Group Projects by Status" icon="fak fa-groups"
                    (selected)="setGroupBy('status')"></or-action-button>
                </li>
                <li role="menuitem" class="p-2" *ngIf="settings.views[currentView.id].groupBy != 'client'">
                  <or-action-button label="Group by Client" description="Group Projects by Client" icon="fak fa-groups"
                    (selected)="setGroupBy('client')"></or-action-button>
                </li>
                <li role="menuitem" class="p-2" *ngIf="settings.views[currentView.id].groupBy != 'projectManager'">
                  <or-action-button label="Group by PM" description="Group Projects by PM" icon="fak fa-groups"
                    (selected)="setGroupBy('projectManager')"></or-action-button>
                </li>
                <li role="menuitem" class="p-2" *ngIf="settings.views[currentView.id].groupBy != 'accountOwner'">
                  <or-action-button label="Group by Account Owner" description="Group Projects by Account Owner"
                    icon="fak fa-groups" (selected)="setGroupBy('accountOwner')"></or-action-button>
                </li>
                <li role="menuitem" class="p-2" *ngIf="settings.views[currentView.id].groupBy != ''">
                  <or-action-button label="Remove Grouping" description="Remove Project Groups" icon="fak fa-none"
                    (selected)="setGroupBy('')"></or-action-button>
                </li>
              </ng-container>
              <!-- <a class="dropdown-item" (click)="bulkDLParticipantExport()">Bulk Participant DL</a> -->
              <!--<li class="list-group-item border-0">
                <or-toggle-button (changed)="changeTheme($event)" [(ngModel)]="darkTheme" icon="fa-grin-alt fa-lg" iconTooltip="Dark Theme"></or-toggle-button>
              </li>-->
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="loading == true">
      <div class="loading"></div>
    </div>
    <div *ngIf="loading == false && projects.length == 0">
      <div>No projects available</div>
    </div>

    <ng-container *ngIf="loading == false && mode == 'card'">
      <ng-container *ngIf="projectGroups.length > 1">
        <ng-container *ngFor="let group of projectGroups">
          <div class="row mb-3 stage py-3 mx-2">
            <div class="col">
              <div class="row">
                <div class="col d-flex align-items-center">
                  <div class="col px-0">
                    <div class="d-flex align-items-center">
                      <span class="nav-black1 font-weight-bold nav-font16 mr-3">{{ group.title }}</span>
                      <span class="nav-black1 font-weight-bold nav-font16">
                        ({{ group.projects.length }} {{ group.projects.length == 1 ? 'Project' : 'Projects' }})
                      </span>
                    </div>
                  </div>
                  <div class="col px-0 grey5 d-flex align-items-center justify-content-end">
                   
                    <i class="far fa-lg fa-angle-down grey4 ml-3" [class.fa-angle-up]="group.open"
                      [class.fa-angle-down]="!group.open" (click)="group.open = !group.open"></i>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="group.open">
                <div class="row projectcards my-3 grey4 nav-font14 font-weight-bold">
                  <div class="card-cell" *ngFor="let project of group.projects">
                    <app-project-card-survey *ngIf="currentView.cardStyle == 'survey'" [project]="project"
                      [actions]="rowActions" [isFavorite]="favorites[project.id]"
                      (favoriteChange)="FavoriteChange($event, project)" (action)="handleAction($event)">
                    </app-project-card-survey>
                    <app-project-card-idsuite *ngIf="currentView.cardStyle == 'idsuite'" [project]="project"
                      [actions]="rowActions" [isFavorite]="favorites[project.id]"
                      (favoriteChange)="FavoriteChange($event, project)" (action)="handleAction($event)">
                    </app-project-card-idsuite>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="projectGroups.length <= 1 && projects.length > 0">
        <div class="projectcards mt-3 mb-3">
          <div class="card-cell" *ngFor="let project of projects">
            <app-project-card-survey *ngIf="currentView.cardStyle == 'survey'" [project]="project"
              [actions]="rowActions" [isFavorite]="favorites[project.id]"
              (favoriteChange)="FavoriteChange($event, project)" (action)="handleAction($event)">
            </app-project-card-survey>
            <app-project-card-idsuite *ngIf="currentView.cardStyle == 'idsuite'" [project]="project"
              [actions]="rowActions" [isFavorite]="favorites[project.id]"
              (favoriteChange)="FavoriteChange($event, project)" (action)="handleAction($event)">
            </app-project-card-idsuite>
            <app-project-card-bid *ngIf="currentView.cardStyle == 'bid'" [project]="project"
              [isFavorite]="favorites[project.id]" (favoriteChange)="FavoriteChange($event, project)"
              (action)="handleAction($event)">
            </app-project-card-bid>
          </div>
        </div>
      </ng-container>
      <button class="nav-btn-primary shadow-none w-100 mb-3 mt-3" *ngIf="!this.allProjectsLoaded"
        (click)="loadAllProjects()">Load all</button>
    </ng-container>

    <ng-container *ngIf="loading == false && mode == 'cardGroup' && projectCardGroups.length > 0">
      <ng-container *ngIf="projectGroups.length > 1">
        <ng-container *ngFor="let group of projectGroups">
          <div class="row mb-3 stage py-3 mx-2">
            <div class="col">
              <div class="row">
                <div class="col d-flex align-items-center">
                  <div class="col px-0">
                    <div class="d-flex align-items-center">
                      <span class="nav-black1 font-weight-bold nav-font16 mr-3">{{ group.title }}</span>
                      <span class="nav-black1 font-weight-bold nav-font16">
                        ({{ group.projects.length }} {{ group.projects.length == 1 ? 'Project' : 'Projects' }})
                      </span>
                    </div>
                  </div>
                  <div class="col px-0 grey5 d-flex align-items-center justify-content-end">
                    <i class="far fa-lg fa-angle-down grey4 ml-3" [class.fa-angle-up]="group.open"
                      [class.fa-angle-down]="!group.open" (click)="group.open = !group.open"></i>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="group.open">
                <div class="row projectcards my-3 grey4 nav-font14 font-weight-bold">

                  <ng-container *ngFor="let group of getFilteredGroups(group)">
                    <ng-container *ngIf="group.projects.length == 1">
                      <div class="card-cell">
                        <app-project-card-survey *ngIf="currentView.cardStyle == 'survey'" [project]="group.projects[0]"
                          [actions]="rowActions" [isFavorite]="favorites[group.projects[0].id]"
                          (favoriteChange)="FavoriteChange($event, group.projects[0])" (action)="handleAction($event)">
                        </app-project-card-survey>
                        <app-project-card-idsuite *ngIf="currentView.cardStyle == 'idsuite'" [project]="group.projects[0]"
                          [actions]="rowActions" [isFavorite]="favorites[group.projects[0].id]"
                          (favoriteChange)="FavoriteChange($event, group.projects[0])" (action)="handleAction($event)">
                        </app-project-card-idsuite>
                        <app-project-card-bid *ngIf="currentView.cardStyle == 'bid'" [project]="group.projects[0]"
                          [isFavorite]="favorites[group.projects[0].id]"
                          (favoriteChange)="FavoriteChange($event, group.projects[0])" (action)="handleAction($event)">
                        </app-project-card-bid>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="group.projects.length > 1">
                      <div class="card-cell group" *ngFor="let currentProject of group.currentPage">
          
                        <ng-container *ngIf="currentView.cardStyle == 'survey'">
                          <app-project-card-survey *ngIf="currentProject?.type !== 'summary'" [project]="currentProject"
                            [actions]="rowActions" [isGroup]="true" [isFavorite]="favorites[currentProject.id]"
                            (favoriteChange)="FavoriteChange($event, currentProject)" (action)="handleAction($event)">
                          </app-project-card-survey>
                          <app-project-card-survey-summary *ngIf="currentProject?.type === 'summary'" [summary]="currentProject"
                            [isGroup]="true" (action)="handleAction($event)">
                          </app-project-card-survey-summary>
                        </ng-container>
          
                        <app-project-card-idsuite *ngIf="currentView.cardStyle == 'idsuite'" [project]="currentProject"
                          [actions]="rowActions" [isFavorite]="favorites[currentProject.id]"
                          (favoriteChange)="FavoriteChange($event, currentProject)" (action)="handleAction($event)">
                        </app-project-card-idsuite>
                        <app-project-card-bid *ngIf="currentView.cardStyle == 'bid'" [project]="currentProject"
                          [isFavorite]="favorites[currentProject.id]" (favoriteChange)="FavoriteChange($event, currentProject)"
                          (action)="handleAction($event)">
                        </app-project-card-bid>
          
                        <ng-template *ngTemplateOutlet="tplPagination; context:{ $implicit: group}"></ng-template>
          
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>


      <ng-container *ngIf="projectGroups.length <= 1">
        <div class="projectcards mt-3 mb-3">
          <ng-container *ngFor="let group of projectCardGroups">
            <ng-container *ngIf="group.projects.length == 1">
              <div class="card-cell">
                <app-project-card-survey *ngIf="currentView.cardStyle == 'survey'" [project]="group.projects[0]"
                  [actions]="rowActions" [isFavorite]="favorites[group.projects[0].id]"
                  (favoriteChange)="FavoriteChange($event, group.projects[0])" (action)="handleAction($event)">
                </app-project-card-survey>
                <app-project-card-idsuite *ngIf="currentView.cardStyle == 'idsuite'" [project]="group.projects[0]"
                  [actions]="rowActions" [isFavorite]="favorites[group.projects[0].id]"
                  (favoriteChange)="FavoriteChange($event, group.projects[0])" (action)="handleAction($event)">
                </app-project-card-idsuite>
                <app-project-card-bid *ngIf="currentView.cardStyle == 'bid'" [project]="group.projects[0]"
                  [isFavorite]="favorites[group.projects[0].id]"
                  (favoriteChange)="FavoriteChange($event, group.projects[0])" (action)="handleAction($event)">
                </app-project-card-bid>
              </div>
            </ng-container>
            <ng-container *ngIf="group.projects.length > 1">
              <div class="card-cell group" *ngFor="let currentProject of group.currentPage">

                <ng-container *ngIf="currentView.cardStyle == 'survey'">
                  <app-project-card-survey *ngIf="currentProject?.type !== 'summary'" [project]="currentProject"
                    [actions]="rowActions" [isGroup]="true" [isFavorite]="favorites[currentProject.id]"
                    (favoriteChange)="FavoriteChange($event, currentProject)" (action)="handleAction($event)">
                  </app-project-card-survey>
                  <app-project-card-survey-summary *ngIf="currentProject?.type === 'summary'" [summary]="currentProject"
                    [isGroup]="true" (action)="handleAction($event)">
                  </app-project-card-survey-summary>
                </ng-container>

                <app-project-card-idsuite *ngIf="currentView.cardStyle == 'idsuite'" [project]="currentProject"
                  [actions]="rowActions" [isFavorite]="favorites[currentProject.id]"
                  (favoriteChange)="FavoriteChange($event, currentProject)" (action)="handleAction($event)">
                </app-project-card-idsuite>
                <app-project-card-bid *ngIf="currentView.cardStyle == 'bid'" [project]="currentProject"
                  [isFavorite]="favorites[currentProject.id]" (favoriteChange)="FavoriteChange($event, currentProject)"
                  (action)="handleAction($event)">
                </app-project-card-bid>

                <ng-template *ngTemplateOutlet="tplPagination; context:{ $implicit: group}"></ng-template>

              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>


      <button class="nav-btn-primary shadow-none w-100 mb-3 mt-3" *ngIf="!this.allProjectsLoaded"
        (click)="loadAllProjects()">Load all</button>
    </ng-container>

    <div class="mr-3 mt-3" *ngIf="loading == false && mode == 'list' && allProjects.length > 0">
      <or-list [items]="allProjects" [loadcount]="100" [layout]="currentView.tableColumns" [actions]="rowActions"
        (action)="handleAction($event)" [listsettings]="layoutListSettings" (changed)="saveProjectChange($event)"
        (settingsupdate)="saveProjectsListSetting($event)" rowid="id" [clickroute]="clickroute">
      </or-list>
    </div>

    <div *ngIf="loading == false && mode == 'calendar' && projects.length > 0" class="container-fluid mb-4">
      <or-calendar [data]="allTasks" idField="id" dateField="projectStartDate" titleField="projectName"
        subtitleField="clientName" profileField="projectManager" footerField="projectStatus"
        (selected)="openProjectFromCalendar($event)"></or-calendar>
    </div>

    <div class="mr-3 mt-3" *ngIf="loading == false && mode == 'gantt' && projects.length > 0">
      <div class="container-fluid mb-4">
        <ng-container *ngIf="ganttHighchartOptions != null">
          <or-project-gantt-view [projectList]="projects"></or-project-gantt-view>
        </ng-container>
      </div>
    </div>

  </div>
</div>

<!-- pagination -->
<ng-template #tplPagination let-group>
  <div class="pagination position-relative">
    <div class="row w-100 position-absolute">
      <div class="col left-arrow d-flex justify-content-start">
        <i class="fa-duotone fa-xl cursor-pointer fa-circle-chevron-left" (click)="pageGroupChanged('previous', group)"
          style="--fa-primary-color: #ffffff; --fa-secondary-color: #b5b8bd; --fa-secondary-opacity: 0.5;">
        </i>
      </div>
      <div class="col right-arrow d-flex justify-content-end">
        <i class="fa-duotone fa-xl cursor-pointer fa-circle-chevron-right" (click)="pageGroupChanged('next', group)"
          style="--fa-primary-color: #ffffff; --fa-secondary-color: #b5b8bd; --fa-secondary-opacity: 0.5;">
        </i>
      </div>
    </div>
  </div>
</ng-template>


<!-- MODAL COPY ENTRY URLS -->
<ng-template #modalUrls>
  <div class="modal-header">
    <p class="h4 mr-3 mb-0">
      Entry Urls
    </p>
    <button type="button" class="btn btn-sm btn-light" (click)="copyAllUrls()"><i class="far fa-copy"></i> Copy All
      Urls</button>
    <button type="button" class="close pull-right text-white" aria-label="Close" (click)="openModalRef.hide();">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card border-secondary mb-3">
      <div class="card-body text-secondary">
        <h5 class="display-6 mb-3">
          Redirects
          <i class="far fa-copy float-right" (click)="copyRedirectUrls()"></i>
        </h5>
        <p class="card-text">
          <small class="d-block font-weight-bold">Complete</small>
          <small class="d-block">{{ redirectUrls.completeRedirectURL }}</small>
        </p>
        <p class="card-text">
          <small class="d-block font-weight-bold">Terminate</small>
          <small class="d-block">{{ redirectUrls.terminateRedirectURL }}</small>
        </p>
        <p class="card-text">
          <small class="d-block font-weight-bold">Overquota</small>
          <small class="d-block">{{ redirectUrls.overQuotaRedirectURL }}</small>
        </p>
        <p class="card-text">
          <small class="d-block font-weight-bold">QC</small>
          <small class="d-block">{{ redirectUrls.qcRedirectURL }}</small>
        </p>
      </div>
    </div>

    <div class="card border-secondary mb-3" *ngFor="let item of listUrls">
      <div class="card-body text-secondary">
        <h5 class="display-6 mb-3">
          {{ item.vendor }}
          <i class="far fa-copy float-right" (click)="copyProjectsUrls(item)"></i>
        </h5>
        <p class="card-text" *ngFor="let url of item.urls">
          <small class="d-block font-weight-bold">{{ url.projectName }}</small>
          <small class="d-block">{{ url.surveyEntryUrl }}</small>
        </p>
      </div>
    </div>
  </div>
</ng-template>

<!-- MODAL CONFIRM DELETE -->
<ng-template #modalConfirm>
  <div class="modal-header">
    <p class="h5">Confirm Delete</p>
  </div>
  <div class="modal-body text-center">
    <p class="h6">Are you sure you want to delete '{{this.selectedProject.projectName}}'?</p>
    <button type="button" class="btn btn-outline-secondary m-2" (click)="confirmDelete()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="openModalRef.hide()">No</button>
  </div>
</ng-template>