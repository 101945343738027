import { Component, OnInit } from '@angular/core';
import { AudienceService} from 'core';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-audience',
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.css']
})
export class AudienceComponent implements OnInit {
  ready = false;
  countries;
  categories;


  audience: any = {
    name: 'Brand Awareness',
    quota: 1000,
    ir:40,
    inField : 7,
    loi: 12,
    country: 'USA',
    group: [
      {
        name: 'Main',
        quota: 800,
        filter:'',
        target: [
          {
            category: {name:'Gender'},
            quotas: [
              {value: 'Male', n: 376, percent: 47},
              {value: 'Female', n: 424, percent: 53}
            ]
          }
        ]
      }
    ]
  };

  // categories = ['Gender', 'Age', 'Region', 'Hispanic Origin', 'Race', 'Device'].sort();
  frequentCategories = [{id:null, name:'Gender', type:'Gender'}, {id:null, name:'Age', type:'Age'}];
  templates = [{name: 'template1', description: 'male/female 60/40 - NE region'}, {name: 'template2', description: 'description of template'}, {name: 'template3', description: 'description of template'}];
  regions = [];
  datapoints:any = {
    Gender: [
      {
          "id": 1,
          "name": "Male"
      },
      {
          "id": 2,
          "name": "Female"
      }
    ],
    Device:[
        {
            "id": 1,
            "name": "Standard"
        },
        {
            "id": 2,
            "name": "Mobile"
        },
        {
            "id": 3,
            "name": "Tablet"
        }
      ]
     
  };


  constructor(private audienceService: AudienceService) { }

  ngOnInit() {

    forkJoin(
      this.audienceService.GetCategories(),
      this.audienceService.GetCountries()
    )
    .subscribe(([categories, countries]) => {
      this.categories = categories;

      this.countries = countries;

      this.updateRegions();
      this.ready = true;
    });

   /* this.audienceService.GetQuestions().subscribe(data => {
      this.categories = data.map( e =>  e.categoryName);

    })*/

  }
  updateRegions(){
    let regions = this.countries.find( e => e.name == this.audience.country).regionTypes;
    regions.forEach( e=> e.type = 'Region');
    this.regions = regions;
  }
  addGroup() {
    this.audience.group.push({ name: '', filter:'', target: []});
  }
  cloneGroup(group, othergroup) {
    const clone = JSON.parse(JSON.stringify(othergroup));
    group.target = clone.target;
  }
  removeGroup(groupIdx) {
    this.audience.group.splice(groupIdx, 1);
  }

  addTarget(group, category) {
    
    if(category.type == 'Region')
    {
      this.datapoints[category.name] = this.regions.find( e => e.name == category.name).regions;
    }

    group.target.push({ category, quotas: [{}]});
  }
  removeTarget(group, targetIdx) {
    group.target.splice(targetIdx, 1);
  }
  addNewQuotaRow(target) {
    if (this.datapoints[target.category.name] != null && this.datapoints[target.category.name].length <= target.quotas.length ) {return; }
    target.quotas.push({});
  }
  removeQuota(target, quotaIdx) {
    target.quotas.splice(quotaIdx, 1);
    if(quotaIdx == target.quotas.length){
      this.addNewQuotaRow(target);
    }
  }


  interlock(group, target, idx) {
    const items = target.lockedItems != null ? [...target.lockedItems] : [];
    items.push(target);
    target.lockedItems = null;
    target.interlock = true;

    for (let i = idx - 1; i >= 0; i--) {
      if (group.target[i].interlock != true) {
        group.target[i].interlock = true;
        group.target[i].lockedItems = items;
        group.target[i].interlockData = this.getInterlockedData(group, group.target[i]);
        this.validateTotals(group, group.target[i]);
        break;
      } else if (group.target[i].lockedItems != null) {
        group.target[i].lockedItems = [...group.target[i].lockedItems, ...items];
        group.target[i].interlockData = this.getInterlockedData(group, group.target[i]);
        this.validateTotals(group, group.target[i]);
        break;
      }
    }
    this.validateTotals(group, target);
    
  }
  removeInterlock(group, target, idx, itemIdx) {
    const items = [...target.lockedItems];
    const start = items.slice(0, idx);
    const end = items.slice(idx + 1);

    if (start.length === 0) {
      target.lockedItems = null;
      target.interlock = false;
    } else {
      target.lockedItems = start;
      target.interlockData = this.getInterlockedData(group, target);
    }
    if (end.length === 0) {
      group.target[itemIdx].interlock = false;
    } else {
      group.target[itemIdx].interlock = true;
      group.target[itemIdx].lockedItems = end;
      group.target[itemIdx].interlockData = this.getInterlockedData(group, group.target[itemIdx]);
    }

    this.validateTotals(group, target);
    this.validateTotals(group, group.target[itemIdx]);
  }
  getInterlockedData(group, target) {
    let data = [];
    target.quotas.forEach(e => {
      if(e.value != null) this.buildRow(target, [e], 0, data);
    });

    data.forEach(row => {
      let percent = 1;
      row.forEach(item => {
        percent = percent * (item.percent/100);
      })
      row.percent = Math.round(percent*100);
      row.n = Math.floor(group.quota * row.percent/100);

    })
    return data;
  }
   buildRow(target, row, index, data) {
    if (index >= target.lockedItems.length) {
      data.push(row);
      return;
    }
    target.lockedItems[index].quotas.forEach(e => {
      if(e.value != null) {
        const newRow = [...row, ...[e]];
        this.buildRow(target, newRow, index + 1, data);
      }
    });
  }
  handleQuotaValueChange(target, index) {
    if (target.quotas.length === index + 1) {
      this.addNewQuotaRow(target);
    }
  }
  handleQuotaChange(group, target, quota, type) {
    if (group.quota === 0) { return; }

    if (type === '%') {
      quota.n = group.quota * (quota.percent / 100);
    } else {
      quota.percent = Math.round(quota.n * 100 / group.quota);
    }
    this.validateTotals(group, target);
  }
  validateTotals(group, target) {
    target.errors = [];
    if(target.interlock != true){
      let totalN = 0;
      let totalPercent = 0;
      target.quotas.forEach(row => {
        totalPercent += row.percent | 0;
        totalN += row.n | 0;
      })
      //We don't really care about % as long as quotas match
      /*if(totalPercent != 100){
        target.errors.push("Percent is not 100%")
      }*/
      if(totalN != group.quota) {
        target.errors.push('Total ' + totalN + ' does not match Quota');
      }
    }
    else if(target.interlockData != null){
      
    }

  }

  distribute(group, target, type) {
    const count = target.quotas.filter( e => e.value != null && e.value != '').length;
    if(count === 1) {
      target.quotas[0].n = group.quota;
      target.quotas[0].percent = 100;
    }
    else if( count > 1 ) { 
      if(type === 'equal') {
        const n = Math.floor(group.quota/count);
        const percent =  Math.floor(100/count);
        target.quotas.filter( e => e.value != null && e.value != '').forEach( e => {
          e.n = n;
          e.percent = percent;
        })
      }
      this.validateTotals(group, target);
    }

  }
  addAll(group, target) {
    const data = this.datapoints[target.category.name];
    if(data){
      target.quotas = target.quotas.filter(e => e.value != null && e.value != '');

      var existing = {};
      target.quotas.forEach( e => existing[e.value] = true);

      data.forEach(e => {
        if(existing[e.name]) return true;

        target.quotas.push({ value: e.name });
      })

      this.validateTotals(group, target);
    }
  }
  autoFix(group, target) {

  }


  updateCountToMatchPercent(target, total) {
    target.quotas.forEach( quota => {
      quota.n = Math.round(total * (quota.percent / 100));
    });
  }
  loadTemplate(group) {
    group.target = [{category: {name:'Gender'}, quotas: [{value: 'Male', percent: 60}, {value: 'Female', percent: 40}]}];
    group.target.forEach( target => {
      this.updateCountToMatchPercent(target, group.quota);
      this.validateTotals(group, target);
      target.quotas.push({});
    });
  }
  getInputStyle(value) {
    return value == null || value === '' ? 'form-control' : 'form-control-plaintext';
  }

  loadCensus(target, group) {
    let found = false;
    if (target.category.name === 'Gender') {
      found = true;
      target.quotas = [{value: 'Male', percent: 49}, {value: 'Female', percent: 51}];
    }
    if (target.category.name === 'Main regions' && this.audience.country == 'USA') {
      found = true;
      target.quotas = [{value: 'Midwest', percent: 25}, {value: 'Northeast', percent: 30}, {value: 'South', percent: 20}, {value: 'West', percent: 25}];
    }
    if (target.category.name === 'Age') {
      found = true;
      target.quotas = [{value: '18', value2: '24', percent: 30}, {value: '25', value2: '34', percent: 45}, {value: '34', value2: '45', percent: 25}];
    }
    if (found) {
      this.updateCountToMatchPercent(target, group.quota);
      this.addNewQuotaRow(target);
    }
  }

  save() {
    console.log(this.audience);
  }

}
