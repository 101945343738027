import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HighchartsChartModule } from 'highcharts-angular';

import { JwtModule } from '@auth0/angular-jwt';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';

import { AppRoutingModule } from './app-routing.module';
import { InterceptorProviders} from './_interceptors/interceptors';
import { AppComponent } from './app.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HelpComponent } from './sections/help/help.component';
import { OnlineHelpComponent } from './sections/onlinehelp/onlinehelp.component';
import { SurveyTesterInfoComponent } from './components/survey-tester/survey-tester-info/survey-tester-info.component';
import { SurveyTesterActivityComponent } from './components/survey-tester/survey-tester-activity/survey-tester-activity.component';
import { SurveyTesterComponent } from './components/survey-tester/survey-tester.component';
import { SurveyTesterCommentsComponent } from './components/survey-tester/survey-tester-comments/survey-tester-comments.component';
import { SurveyTesterDecipherComponent } from './components/survey-tester/survey-tester-decipher/survey-tester-decipher.component';
import { SurveyTesterMiniRemoteComponent } from './components/survey-tester/survey-tester-mini-remote/survey-tester-mini-remote.component';

import { TeamModule } from './sections/team/team.module';
import { PartnersModule } from './sections/partners/partners.module';
import { ClientsModule } from './sections/clients/clients.module';
import { DashboardModule } from './sections/dashboard/dashboard.module';
import { ProjectsModule } from './sections/projects/projects.module';
import { ReportsModule } from './sections/reports/reports.module';
import { LoginModule } from './sections/login/login.module';
import { ResetPasswordModule } from './sections/reset-password/reset-password.module';
import { ResetLinkModule } from './sections/reset-link/reset-link.module';
import { ChangePasswordModule } from './sections/change-password/change-password.module';
import { AccountEditModule } from './sections/account-edit/account-edit.module';
import { HelpModule } from './sections/help/help.module';
import { OnlineHelpModule } from './sections/onlinehelp/onlinehelp.module';
import { AboutModule } from './sections/about/about.module';
import { ComponentsModule, HelpersModule } from 'core';
import { AudienceComponent } from './sections/audience/audience.component';
import { ScheduleComponent } from './sections/schedule/schedule.component';
import { ProjectThirdpartyStatusComponent } from './sections/projects/project-thirdparty-status/project-thirdparty-status.component';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup

import { AlertSettingsComponent } from './sections/alert-settings/alert-settings.component';
import { CanDeactivateGuard } from './_guards/candeactivate.guard.service';
import { AuthGuestGuard } from './_guards/auth-guest.guard';
import { AuthGuard } from './_guards/auth.guard';
import { ResponseIdSettingsComponent } from './sections/response-id/response-id-settings.component';
import { ConfirmationLinkModule } from './sections/confirmation-link/confirmation-link.module';
import { KtdGridModule } from '@katoid/angular-grid-layout';
import { environment } from 'projects/core/src/environments/environment';

export function tokenGetter() {
  return localStorage.getItem('TOKEN_' + environment.apiEndpoint);
 }

@NgModule({
   declarations: [
      AppComponent,
      NavBarComponent,
      SidebarComponent,
      HelpComponent,
      OnlineHelpComponent,
      SurveyTesterComponent,
      SurveyTesterInfoComponent,
      SurveyTesterCommentsComponent,
      SurveyTesterActivityComponent,
      SurveyTesterDecipherComponent,
      SurveyTesterMiniRemoteComponent,
      AudienceComponent,
      AlertSettingsComponent,
      ResponseIdSettingsComponent
   ],
   imports: [
      BrowserModule,
      AppRoutingModule,
      HttpClientModule,
      BrowserAnimationsModule,
      HelpersModule,
      TeamModule,
      FormsModule,
      ReactiveFormsModule,
      ClientsModule,
      PartnersModule,
      DashboardModule,
      ProjectsModule,
      ReportsModule,
      LoginModule,
      ResetPasswordModule,
      ResetLinkModule,
      ConfirmationLinkModule,
      ChangePasswordModule,
      AccountEditModule,
      HelpModule,
      AboutModule,
      ComponentsModule,
      KtdGridModule,
      HighchartsChartModule,
      ModalModule.forRoot(),
      NgIdleKeepaliveModule.forRoot(),
      JwtModule.forRoot({
         config: {
           tokenGetter,
           allowedDomains: ['localhost:44349',
                                'api.navigatorsurveys.com',
                                'api-staging.navigatorsurveys.com',
                                'api-dev.navigatorsurveys.com'],
           disallowedRoutes:  ['localhost:44349/api/auth',
                                'api.navigatorsurveys.com/api/auth',
                                'api-staging.navigatorsurveys.com/api/auth',
                                'api-dev.navigatorsurveys.com/api/auth']
         }
      })
  ],
   providers: [InterceptorProviders, BsModalRef, CanDeactivateGuard, AuthGuard, AuthGuestGuard],
   bootstrap: [AppComponent]
})
export class AppModule { }
