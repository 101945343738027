import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { AuthService} from 'core';
import { DashboardService } from 'projects/core/src/services/dashboard.service';
import Highcharts from 'highcharts';

@Component({
  selector: 'app-widget-partner-fraud-rate',
  templateUrl: './widget-partner-fraud-rate.component.html',
  styleUrls: ['./widget-partner-fraud-rate.component.scss']
})
export class WidgetPartnerFraudRateComponent implements OnInit {

  @Input() widget;
  @Output() onremove = new EventEmitter();

  // Highcharts
  highcharts = Highcharts;
  highchartOptions: Highcharts.Options;

  projectByTypeMyProjects = true;
  settings = {};

  constructor(
    public http: HttpClient,
    public auth: AuthService,
    public dashboardService: DashboardService) { }

  ngOnInit() {
    this.getpartnerFraudrateDataData();
  }
  getpartnerFraudrateDataData() {
    this.dashboardService.GetpartnerFraudRate(this.settings).subscribe({
      next: (data) => this.projectByManagerChart(data),
      error: (e) => console.error(e)
  })
 }
  projectByManagerChart(data) {
      if (data.length <= 0) return;
      let channels = data.map(function(project) { return project.channel; });
      let uniquechannels = [...new Set(channels)].sort();
      const header = [...new Set(uniquechannels)].sort();

      var chartData = [];
      let itemValid = {
        name: 'valid',
        color: "#3fbd93",
        data: []
       }

       data.forEach((allData: any) => {
        itemValid.data.push(allData.valid);
      });


      let iteminvalid = {
        name: 'invalid',
        color: "#e02d38",
        data: []
       }

       data.forEach((allData: any) => {
        iteminvalid.data.push(allData.invalid);
       });

       chartData.push(iteminvalid);
       chartData.push(itemValid);

      this.drawHighChartsChart(header, chartData);
  }


  drawHighChartsChart(header, chartData) {
    this.highchartOptions = {
      credits: {
        enabled: false
      },
      chart: {
        type: 'column',
        events: {
          render: function() {
            this.reflow();
          }
        }
      }, title: {
        text: ''
      }, xAxis: {
        categories: header
      }, yAxis: {
        min: 0,
        title: {
          text: 'CleanID'
        }, stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: ( // theme
              Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color
            ) || 'gray',
            textOutline: 'none'
          }
        }
      }, legend: {
        align: 'center',
        verticalAlign: 'bottom',
        y:10,
        floating: true,
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      }, tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      }, plotOptions: {
        column: {
          stacking: 'percent',
          dataLabels: {
            enabled: true
          }
        }
      }, series: chartData
    };
  }

  remove() {
    this.onremove.emit();
  }
}
