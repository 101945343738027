<div class="container-fluid" style="z-index: 5;">
  <div class="col d-flex align-items-center px-0 mb-3">
    <span class="nav-secondary nav-font16 font-weight-bold mr-auto mb-0">{{stage?.name}}</span>
  </div>
  <div class="cards">
    @if (stage) {
      @for (card of stage?.homepageCards; track card?.id) {
        @if (card?.type === 'dates') {
          <app-project-homepage-dates [project]="_project" (callback)="cardCallback($event)"></app-project-homepage-dates>
        }
        @if (card?.type === 'team') {
          <app-project-homepage-team [project]="_project" (callback)="cardCallback($event)"></app-project-homepage-team>
        }
        @if (card?.type === 'sample-specification') {
          <app-project-homepage-sample [project]="_project" (callback)="cardCallback($event)"></app-project-homepage-sample>
        }
        @if (card?.type === 'schedule') {
          <!-- <app-project-homepage-schedule [card]="card" [project]="_project"></app-project-homepage-schedule> -->
        }
        @if (card?.type === 'client') {
          <!-- <app-project-homepage-client [card]="card" [project]="_project"></app-project-homepage-client> -->
        }
        @if (card?.type === 'datacleaning') {
          <!-- <app-project-homepage-datacleaning [card]="card" [project]="_project"></app-project-homepage-datacleaning> -->
        }
        @if (card?.type === 'quality') {
          <!-- <app-project-homepage-quality [card]="card" [project]="_project"></app-project-homepage-quality> -->
        }
        @if (card?.type === 'survey-links-vendors') {
          <!-- <app-project-homepage-survey-links-vendors [card]="card" [project]="_project"></app-project-homepage-survey-links-vendors> -->
        }
        @if (card?.type === 'closure') {
          <!-- <app-project-homepage-closure [card]="card" [project]="_project"></app-project-homepage-closure> -->
        }
        @if (card?.type === 'closure') {
          <!-- <app-project-homepage-closure [card]="card" [project]="_project"></app-project-homepage-closure> -->
        }
        @if (card?.type === 'field-management') {
          <!-- <app-project-homepage-field-management [card]="card" [project]="_project"></app-project-homepage-field-management> -->
        }
        @if (card?.type === 'survey-testing') {
          <!-- <app-project-homepage-survey-testing [card]="card" [project]="_project"></app-project-homepage-survey-testing> -->
        }
      }
    }
  </div>
</div>
