<div class="card border-0">
  <div class="card-header border-bottom" *ngIf="widget">
    <app-widget-title [widget]="widget" (remove)="remove()" (update)="updateTitle($event)" (openConfig)="configWidget()"></app-widget-title>
  </div>
  <div class="card-body">
    <div class="row nav-font16 text-center">
      <div class="col-4 border-right">
        <span class="d-block grey5 mb-2 font-weight-bold">{{data?.projectsInTrouble ?? 0}}</span>
        <span class="d-block grey4">Projects in Trouble</span>
      </div>
      <div class="col-4 border-right" (click)="goToTaskList('overdue')">
        <span class="d-block grey5 mb-2 font-weight-bold">{{data?.tasksOverdue ?? 0}}</span>
        <span  class="d-block grey4">Tasks Overdue</span>
      </div>
      <div class="col-4" (click)="goToTaskList('todo')">
        <span class="d-block grey5 mb-2 font-weight-bold">{{data?.tasksToDo ?? 0}}</span>
        <span class="d-block grey4">Tasks to Do</span>
      </div>
    </div>
  </div>
</div>
