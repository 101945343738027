import {HTTP_INTERCEPTORS} from '@angular/common/http';

import { ErrorInterceptor } from './error.interceptor';
import { CacheInterceptor } from './cache.interceptor';

export const InterceptorProviders =
   [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true }
];
