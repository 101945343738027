import { OnInit, Component, ViewChild, Input, OnChanges, Renderer2, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as Highcharts from "highcharts/highcharts-gantt";

interface Custom extends Highcharts.PointOptionsObject {
  start: number;
  end: number;
  isStage;
  color: string;
  projectTypeStageId;
  showInlineTask: boolean;
  milestone: boolean;
  completeDate;
  totalTasks;
  completedTasks;
  percentCompleted;
  latestCompletedTask;
  link;
  projectCode;
}

@Component({
  selector: 'or-project-gantt-view',
  templateUrl: './project-gantt-view.component.html',
  styleUrls: ['./project-gantt-view.component.scss']
})
export class ProjectGanttViewComponent implements OnInit, OnChanges {

  highcharts: typeof Highcharts = Highcharts;
  highchartOptions: Highcharts.Options;
  chartData;
  projectNames;
  stageList;
  updateFlag;
  firstLoad = true;
  ganntMinDate;
  ganntMaxDate;
  dataMinDate;
  dataMaxDate;
  rangeSelector = false;
  @Input() projectList;
  @ViewChild('chart') chart;

  constructor(private renderer: Renderer2, private cdref: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.setupData();
    this.drawGanttChart();
  }
  extractContent(s) {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };

  ngOnChanges(changes: SimpleChanges) {
    if (!this.firstLoad) {
      if (changes?.projectList) {
        if (JSON.stringify(changes.projectList.previousValue) !== JSON.stringify(changes.projectList.currentValue)) {
          this.projectList = changes.projectList.currentValue;
          this.setupData(true);
        }
      }
    }
    //else this.firstLoad = false;
  }

  minDateChange(event) {
      this.ganntMinDate = event;
      this.chart.chart.xAxis[0].setExtremes(Date.UTC(event.getFullYear(), event.getMonth(), event.getDate(), 12),
       Date.UTC(this.ganntMaxDate.getFullYear(), this.ganntMaxDate.getMonth(), this.ganntMaxDate.getDate(), 12));
  }
  maxDateChange(event) {
      this.ganntMaxDate = event;
      this.chart.chart.xAxis[0].setExtremes(Date.UTC(this.ganntMinDate.getFullYear(), this.ganntMinDate.getMonth(), this.ganntMinDate.getDate(), 12), 
        Date.UTC(event.getFullYear(), event.getMonth(), event.getDate(), 12));
  }

  setupData(redraw = false) {
    var chartData = [];
    let projectList = {};
    this.projectNames = [];
    this.stageList = {};
    var baseUrl = window.location.origin;

    if (this.projectList) {
      var y = 0;
      this.projectList.forEach((p) => {
        this.projectNames.push(p.projectName);
        var bothNull = (p.projectStartDate == null && p.projectEndDate == null)
        var projectStartDate = p.projectStartDate != null ? new Date(p.projectStartDate) : (bothNull ? null : new Date(p.projectEndDate));
        var projectEndDate = p.projectEndDate != null ? new Date(p.projectEndDate) : (bothNull ? null : new Date(p.projectStartDate));

        var startDateUTC = projectStartDate != null ? Date.UTC(projectStartDate.getFullYear(), projectStartDate.getMonth(), projectStartDate.getDate(), 12) : null;
        var endDateUTC = projectEndDate != null ? Date.UTC(projectEndDate.getFullYear(), projectEndDate.getMonth(), projectEndDate.getDate(), 12) : null;

        var projectCompleteDate = p.projectCompleteDate != null ? new Date(p.projectCompleteDate) : null;
        var completeDateUTC = projectCompleteDate != null ? Date.UTC(projectCompleteDate.getFullYear(), projectCompleteDate.getMonth(), projectCompleteDate.getDate(), 12) : null;

        var totalTasks = p.projectStages.reduce((sum, task) => sum + task.totalTasks, 0);
        var completedTasks = p.projectStages.reduce((sum, task) => sum + task.completedTasks, 0);
        var shortName = p.projectName;
        if(p.projectName.length > 30 )
        {
          shortName= p.projectName.slice(0, 30) + '...';
        }
        let project: any = {
          name: shortName +'<br><a href="' + baseUrl + '/projects/' + p.id + '" style="font-size:12px;color:#0077bc">' +  p.projectCode + '</a>',
          id: p.id,
          className: 'project',
          totalTasks: totalTasks ?? 0,
          completedTasks: completedTasks ?? 0,
          percentCompleted: p.totalTasks > 0 ? Math.round(p.completedTasks / p.totalTasks * 100) : 0,
          completeDate: projectCompleteDate != null ? completeDateUTC : null,
          isStage: false,
          milestone: startDateUTC != null && (startDateUTC == endDateUTC),
          link: '<a href="' + baseUrl + '/projects/' + p.id + '" >' + p.projectName + '</a>',
          projectCode: p.projectCode,
          start: startDateUTC,
          end: endDateUTC,
          // y: y
        };

        chartData.push(project);

        var x = 0;
        p.projectS
        p.projectStages = p.projectStages.sort((a, b) => { return (a.sortOrder > b.sortOrder) ? 1 : -1 });

        p.projectStages.filter(x => x.startDate != null || x.endDate != null).forEach(s => {
          var stageStart = s.startDate != null ? new Date(s.startDate) : new Date(s.endDate);
          var stageEnd = s.endDate != null ? new Date(s.endDate) : new Date(s.startDate);
          var latestCompletedTask = s.latestCompletedTask != null ? new Date(s.latestCompletedTask) : null;
          var latestCompletedTaskUTC = latestCompletedTask != null ? Date.UTC(latestCompletedTask.getFullYear(), latestCompletedTask.getMonth(), latestCompletedTask.getDate()) : null;
          var startUTC = Date.UTC(stageStart.getFullYear(), stageStart.getMonth(), stageStart.getDate(), 12)
          var endUTC = Date.UTC(stageEnd.getFullYear(), stageEnd.getMonth(), stageEnd.getDate(), 12)

          let stage: any = {
            // parent: p.id,
            id: s.id,
            parent: p.id,
            name: s.name,
            isStage: true,
            stageName: s.name,
            start: startUTC,
            end: endUTC, //s.endDate != null ? new Date(s.endDate) : null,
            color: s.color,
            percentCompleted: s.totalTasks > 0 ? Math.round(s.completedTasks / s.totalTasks * 100) : 0,
            latestCompletedTask: latestCompletedTaskUTC,
            totalTasks: s.totalTasks ?? 0,
            completedTasks: s.completedTasks ?? 0,
            milestone: startUTC == endUTC,
            projectTypeStageId: s.projectTypeStageId,
            className: 'stage-' + s.projectTypeStageId,
            link: '<a href="' + baseUrl + '/projects/' + p.id + '/schedule" >' + s.name + '</a>',
            // x: x

          }
          chartData.push(stage);
          if (this.stageList[s.projectTypeStageId] == null) this.stageList[s.projectTypeStageId] = s.color;
          x++;
        });



        if (projectList[p.projectName] != null) p.name = '';
        projectList[p.projectName] = true;
        y++;
      });
    }
    this.chartData = chartData;
    if (redraw) {
      const currentMinDate = Date.UTC(this.ganntMinDate.getFullYear(), this.ganntMinDate.getMonth(), this.ganntMinDate.getDate(), 12);
      const currentMaxDate = Date.UTC(this.ganntMaxDate.getFullYear(), this.ganntMaxDate.getMonth(), this.ganntMaxDate.getDate(), 12);

      var series = (this.chart.chart.series as Highcharts.Series);
      series[0].setData([]);
      series[0].setData(this.chartData);
      (this.chart.chart as Highcharts.Chart).redraw();

      this.chart.chart.xAxis[0].setExtremes(currentMinDate, currentMaxDate);
    }

  }

  drawGanttChart() {

    this.highchartOptions = {
      credits: {
        enabled: false
      },
      chart: {
        type: 'gantt',
        styledMode: true,
        events: {
          load: this.onChartLoad.bind(this),
          redraw: this.onChartLoad.bind(this),
        },

      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        currentDateIndicator: true,
        gridLineWidth: 0,
        events: {
          afterSetExtremes: (e) => {
            if (this.rangeSelector) {
              this.ganntMinDate = new Date(e.min);
              this.ganntMaxDate = new Date(e.max);
            } else {
              var newMin = Date.UTC(this.ganntMinDate.getFullYear(), this.ganntMinDate.getMonth(), this.ganntMinDate.getDate(), 12);
              var newMax = Date.UTC(this.ganntMaxDate.getFullYear(), this.ganntMaxDate.getMonth(), this.ganntMaxDate.getDate(), 12);

              if (e.min !== newMin || e.max !== newMax) {
                this.chart.chart.xAxis[0].setExtremes(newMin, newMax, true, false);
              }
            }
          }
        }
      },
      yAxis: {
        labels: {
          formatter: function (): string {
            var text = this.value.toString()
            return text;
          },
          style: {
            textOverflow: "auto"
          }
        },
        title: {
          text: 'Projects'
        },
      },
      accessibility: {
        enabled: false
      },
      navigator: {

        enabled: true,
        // liveRedraw: true,
        series: {
          type: 'gantt',
          pointPadding: 0.25,
          accessibility: {
            enabled: false
          }
        },
        yAxis: {
          min: 0,
          max: 3,
          // reversed: true,
          categories: []
        }
      },
      noData: {
        attr: this.chartData
      },
      scrollbar: {
        enabled: true
      },
      rangeSelector: {
        enabled: true,
        selected: 0,
        inputDateFormat: '%m/%d/%Y',
        inputEditDateFormat: '%m/%d/%Y',
        buttons: [{
          type: 'month',
          count: 1,
          text: '1m',
          events: {
            click: (e) => {
              this.rangeSelector = true;
            }
          }
        }, {
            type: 'month',
            count: 3,
            text: '3m',
            events: {
              click: (e) => {
                this.rangeSelector = true;
              }
            }
        }, {
            type: 'month',
            count: 6,
            text: '6m',
            events: {
              click: (e) => {
                this.rangeSelector = true;
              }
            }
        }, {
            type: 'ytd',
            text: 'YTD',
            events: {
              click: (e) => {
                this.rangeSelector = true;
              }
            }
        }, {
            type: 'year',
            count: 1,
            text: '1y',
            events: {
              click: (e) => {
                this.rangeSelector = true;
              }
            }
        }, {
            type: 'all',
            text: 'All',
            events: {
              click: (e) => {
                this.rangeSelector = true;
              }
            }
        }]
      },
      tooltip: {
        outside: true,
        // stickOnContact: true,
        useHTML: true,
        // hideDelay: 1500,
        style: {
          pointerEvents: 'auto'
        },

        pointFormatter: function () {
          var point = this,
            format = '%A, %m/%d/%Y',
            options = point.options as Custom,
            lines = [];

          if (options.projectCode) {
            lines.push({ value: options.projectCode, style: ' font-weight: 600; font-size: 14px;' })
          }

          if (options.link) {
            lines.push({ value: options.link + '<br>', style: 'pointer-events: auto; font-weight: bold; font-size: 14px;' })
          }
          // lines.push({value: ''})
          if (options.completeDate && !options.isStage) {
            lines.push({ title: 'Completed', value: Highcharts.dateFormat(format, options.completeDate), style: 'font-weight: 600; font-size: 14px;' });
          }

          else if (options.percentCompleted == 100 && options.latestCompletedTask) {
            lines.push({ title: 'Completed', value: Highcharts.dateFormat(format, options.latestCompletedTask), style: 'font-weight: 600; font-size: 14px;' });
          }
          else if (options.isStage && options.completedTasks != null && options.totalTasks != null) {
            lines.push({ value: options.completedTasks + '/' + options.totalTasks + ' tasks completed', style: 'font-weight: 600; font-size: 14px;' });
          }


          if (options.start) {
            lines.push({ title: options.milestone ? 'End Date' : 'Start Date', value: Highcharts.dateFormat(format, options.start), style: 'font-size: 14px' });
          }

          if (options.end) {
            lines.push({ title: 'Due Date', visible: !options.milestone, value: Highcharts.dateFormat(format, options.end), style: 'font-size: 14px' });
          }

          return lines.reduce(function (str, line) {
            var s = '',
              style = (Highcharts.defined(line.style) ? line.style : 'font-size: 11px;');

            if (line.visible !== false) {
              s = (
                '<span style="' + style + '">' +
                (Highcharts.defined(line.title) ? line.title + ': ' : '') +
                (Highcharts.defined(line.value) ? line.value : '') +
                '</span><br/>'
              );
            }
            return str + s;
          }, '');
        }
      },
      plotOptions: {
        gantt: {
          events: {
          },
        },
        series: {
          animation: true,
          dataLabels: {
            useHTML: true,
            enabled: false,
            formatter: function (): string {
              var options = (this.point.options as Custom)
              if (options.isStage) {
                if (options.percentCompleted == 100) return '<span class="nav-font16 grey6 ">' + this.point.name + '</span>' + ' &nbsp;<i class="grey6 fak fa-light fa-circle-check"></i>'
                else return '<span class="nav-font16 grey6 ">' + options.name + " &nbsp;" + options.percentCompleted + "%</span>";
              }
              return '';
            }
          },
          allowPointSelect: true,

        }
      },
      series: [{
        name: '',
        type: 'gantt',
        data: this.chartData,

      }],
    };
  }

  onChartLoad(event) {

    // when loaded map shows last months data
    if (this.firstLoad) {

      if (!this.ganntMaxDate && !this.ganntMinDate) {
        var maxDate = new Date(event.target.xAxis[0].max);
        this.ganntMaxDate = maxDate;

        var monthPrior = new Date(JSON.parse(JSON.stringify(maxDate)));
        monthPrior = new Date( monthPrior.setMonth(monthPrior.getMonth() - 1));

        if (monthPrior >= new Date(event.target.xAxis[0].min))
          this.ganntMinDate = monthPrior;
        else
          this.ganntMinDate = new Date(event.target.xAxis[0].min);

        this.dataMinDate = new Date(event.target.xAxis[0].dataMin)
        this.dataMaxDate = new Date(event.target.xAxis[0].dataMax)
        this.cdref.detectChanges();
      } else
        this.firstLoad = false;
    }

    // hide the original date input and datepicker so we can use custom one
    const rangeInput = event.target.renderer.box.querySelector('.highcharts-range-selector-group .highcharts-input-group');
    rangeInput.style.display = 'none';

    const projectPoint = document.getElementsByClassName('project');

    if (projectPoint) {
      for (let j = 0; j < projectPoint.length; j++) {
        this.renderer.setStyle(projectPoint[j], 'fill', '#0096FF');
        this.renderer.setStyle(projectPoint[j], 'fill-opacity', 0.7);
      }
    }

    for (const key in this.stageList) {
      const stagePoint = document.getElementsByClassName('stage-' + key);

      if (stagePoint) {
        for (let j = 0; j < stagePoint.length; j++) {
          this.renderer.setStyle(stagePoint[j], 'fill', this.stageList[key]);
          this.renderer.setStyle(stagePoint[j], 'fill-opacity', 0.7);
        }
      }
    }
  }




}
