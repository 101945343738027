import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-project-card-team',
  templateUrl: './project-card-team.component.html',
  styleUrls: ['./project-card-team.component.scss']
})
export class ProjectCardTeamComponent implements OnInit {
  
  @Input() project: any;

  constructor() { }

  ngOnInit() {
  }

}
