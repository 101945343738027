<div class="card">
  <div class="card-header border-bottom">
    <app-widget-title [widget]="widget" (remove)="remove()" (update)="updateTitle($event)" (openConfig)="configWidget()"></app-widget-title>
  </div>
  <div class="card-body">
    <div class="row align-content-center justify-content-center">
      <div *ngIf="dataEstimated.length > 0" class="d-flex margin-card-grid">
        <div> 
          <ngx-charts-number-card
          [view]="view"
          [innerPadding]="0"
          [scheme]="colorScheme1"
          [results]="dataEstimated"
          [cardColor]="cardColor"
          [valueFormatting]="formator"
          [labelFormatting]="labelFormator"
          (select)="onSelect($event)">
          </ngx-charts-number-card></div>
        <div >  
          <ngx-charts-number-card
          [view]="view"
          [innerPadding]="0"
          [scheme]="colorScheme2"
          [results]="dataInvoiced"
          [cardColor]="cardColor"
          [valueFormatting]="formator"
          [labelFormatting]="labelFormator"
          (select)="onSelect($event)">
          </ngx-charts-number-card></div>
        <div><ngx-charts-number-card
          [view]="view"
          [innerPadding]="0"
          [scheme]="colorScheme3"
          [results]="dataMargin"
          [cardColor]="cardColor"
          [valueFormatting]="formator"
          [labelFormatting]="labelFormator"
          (select)="onSelect($event)">
          </ngx-charts-number-card></div>
       

      

        
      </div>
    </div>
    <div class="row align-content-center justify-content-center">
      <ng-container *ngIf="highchartOptions != null">
        <highcharts-chart
          [Highcharts]="highcharts"
          [options]="highchartOptions"
          style="width: 100%; height: 100%; display: block;">
        </highcharts-chart>
      </ng-container>
    </div>
  </div>

</div>

