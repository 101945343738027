import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { UploaderService } from 'projects/core/src/services/uploader.service';
import { HttpEventType } from '@angular/common/http';
import { AlertifyService, ThemeService } from 'core';
import { SpinnerButtonComponent } from 'projects/core/src/components/spinner-button/spinner-button.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AssetUpload } from 'projects/core/src/models/assetUpload';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-project-card-assets',
  templateUrl: './project-card-assets.component.html',
  styleUrls: ['./project-card-assets.component.scss']
})
export class ProjectCardAssetsComponent implements OnInit {

  @Input() public set project(data) {
    this._project = data;
    this.getAssets();
  }

  @Input() directToS3 = false;
  @ViewChild('fileInput') myFileInput: ElementRef;
  @Output() uploadCompleted = new EventEmitter();

  @ViewChild('modalAddLink')
  modalRefAddLink: BsModalRef;

  @ViewChild('modalConfirm')
  modalRefConfirm: BsModalRef;

  @ViewChild('spinnerLink')
  spinnerLink: SpinnerButtonComponent;
  @ViewChild('spinnerFile')
  spinnerFile: SpinnerButtonComponent;

  _project;
  link: any;
  assets: any[] = [];
  assets_Files: any[] = [];
  assets_Links: any[] = [];
  presignedUrl = '';
  downloadUrl = '';
  onChange: (url) => void;
  file: File | null = null;
  acceptTypes = '';
  fileExtension = '';
  uploading = false;
  uploadComplete = false;
  uploadProgress = 0;
  assetUpload: AssetUpload = { filename: null, url: null, assetType: null  };
  FileSaver = require('file-saver');
  addLinkForm: FormGroup;
  

  openModalRef: any;

  constructor(
    private uploader: UploaderService,
    private alertify: AlertifyService,
    private modalService: BsModalService) { }

  ngOnInit() {

    this.addLinkForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      link: new FormControl(null, [Validators.required])
    });
  }

  uploadLink() {

    this.spinnerLink.showSpinner = true;
    //this.addFile();
    this.spinnerLink.showSpinner = false;
  }
  uploadFile(event) {
    this.spinnerFile.showSpinner = true;
    this.file = event.target.files[0] as File;
    this.fileExtension = '.' + this.file.name.split('.').pop();

    this.uploader.generatePresignedUrl(this.fileExtension, 'asset', false).subscribe(response => {
      this.presignedUrl = response.uploadUrl;
      this.downloadUrl = response.url;
      this.uploadToS3();
      this.addFile();
    });
  }
  uploadToS3() {
    this.uploader.uploadFileToS3(this.file, this.presignedUrl).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        this.uploadProgress = Math.round(event.loaded / event.total) * 100;
      } else if (event.type === HttpEventType.Response) {
        this.uploading = false;
        this.uploadComplete = true;
      }
    }, error => {
        this.uploading = false;
        this.alertify.error('Failed to upload file');
    }, () => {
      this.uploadCompleted.emit('Complete');
      this.myFileInput.nativeElement.value = '';
      this.alertify.success('File uploaded');
      this.getAssets();
      this.spinnerFile.showSpinner = false;
    });
  }

  addFile() {
    this.assetUpload.url = this.downloadUrl;
    this.assetUpload.filename = this.file.name;
    this.assetUpload.assetType = "file"
    this.uploader.addAsset(this._project.id, this.assetUpload).subscribe(response => {
    });
  }
  addLink() {
    const reg = /^(?:http(s)?:\/\/)/;

    if (!this.addLinkForm.value.link.match(reg)) {
      this.addLinkForm.value.link = "http://" + this.addLinkForm.value.link
    }

    this.assetUpload.url = this.addLinkForm.value.link;
    this.assetUpload.filename = this.addLinkForm.value.name;
    this.assetUpload.assetType = "link";

    this.uploader.addAsset(this._project.id, this.assetUpload).subscribe(response => {
      this.openModalRef.hide();
      this.getAssets();
      this.addLinkForm.reset();
    });
  }

  getAssets() {
    this.uploader.getListOfAssets(this._project.id).subscribe(data => {
      this.assets = data;
      this.assets_Files = data.filter(x => x.assetType === 'file');
      this.assets_Links = data.filter(x => x.assetType === 'link');
    });
  }

  downloadAsset(asset) {
    if (asset.assetType !== 'file') {
      this.alertify.warning('Asset is not a file!');
      return
    }

    const fileName = asset.fileName;
    this.uploader.getDownloadUrl(asset.id, fileName).subscribe(data => {
      this.FileSaver.saveAs(data, fileName);
    });
  }
  openLink(asset) {
    if (asset.assetType !== 'link') {
      this.alertify.warning('Asset is not a link!');
      return
    }

    window.open(asset.url, "_blank");
  }

  public openModal(template: any, link: any) {
    this.link = link;
    this.openModalRef = this.modalService.show(template, {ignoreBackdropClick: true, keyboard: false, class: 'modal-md' });
  }

  confirmDelete() {
    this.uploader.deleteAsset(this.link.id).subscribe(data => {
      this.getAssets();
      this.alertify.success('This item has been Deleted');
    }, error => {
      this.alertify.error('Failed to delete asset');
    });
    this.openModalRef.hide();
  }
}
