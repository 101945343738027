import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertifyService, ProjectService } from 'core';
import { UnsavedchangesGuardService } from '../../../_guards/unsavedchanges.guard.service';
import { ClosureService } from 'projects/core/src/services/closure.service';

@Component({
  selector: 'app-project-card-finance',
  templateUrl: './project-card-finance.component.html',
  styleUrls: ['./project-card-finance.component.scss']
})
export class ProjectCardFinanceComponent implements OnInit, OnDestroy {

  @Input() public set project(data) {
    this._project = data;
    this.getFinances();
  }
  @Input() public set respondents(data) {
    this._respondents = data;
    this.getFinances();
  }

  @Input() mode = 'reduced';
  closureComplete = false;
  vendorSatisfaction: any;
  closureDetails: any;
  _project: any;
  _respondents;
  loading = false;
  questions: any[] = [];
  segment;
  totals: any = {};
  fee = 0;
  completes = 0;
  expense = 0;
  unsavedChanges = 0;
  totalClientAmount=0;
  vtotalOut =0;
  vtotalExpense =0;
  clentCPI=0;
  vendorTotal =0;
  vCompletes=0;
  margin=0;
  totalCPI=0;
  vendorList = []

  constructor(
    private projectService: ProjectService,
    private alertify: AlertifyService,
    private unsavedChangesService: UnsavedchangesGuardService,
    private closureService: ClosureService) {}

  ngOnInit() {
    this.unsavedChangesService.register(this, () => this.unsavedChanges > 0);
    this.closureService.GetClosureDetails(this._project.id).subscribe(data => {
      this.processClosureDetails(data);
    }, error => { });
  } 

  ngOnDestroy(): void {
    this.unsavedChangesService.deregister([this]);
  }

  processClosureDetails(data) {
    this.closureDetails = data;
    if (this.closureDetails.client !== null) {
      this.closureDetails.client.pricing.forEach(e => {
        this.fee += (e.completes * e.sellCpi );
        this.completes += e.completes;
      });
      this.closureDetails.client.expenses.forEach(e => {
        if (e.completes > 0 && e.expenseCpi > 0) {
          e.amount = (e.completes * e.expenseCpi );
        }
        this.expense += e.amount ;
      });
    }

    this.totalClientAmount=this.fee + this.expense;
    this.clentCPI=this.totalClientAmount/this.completes;
    if (this.closureDetails.vendors != null) {
      this.closureDetails.vendors.forEach(vendor => {
        let moneyOut = 0;
        let moneyIn = 0;
        let name=vendor.vendorName;
        let t4= 0;
        vendor.pricing.forEach(e => {
          moneyOut += (e.completes * e.buyCpi );
          moneyIn += (e.completes * e.sellCpi );
          this.vCompletes += e.completes;
          t4+=e.completes;
        });
        let expense = 0;
        vendor.expenses.forEach(e => {
          if (e.completes > 0 && e.expenseCpi > 0) {
            e.amount = (e.completes * e.expenseCpi);
          }
          expense += e.amount;
        });
        this.vtotalOut += moneyOut;
        this.vtotalExpense += expense;
        let t1=moneyOut +expense;
        let t2= expense;
        let t3= moneyOut;
        let t5= moneyOut+expense;
        let data = {Vendor : name, CPI : t4 === 0 ? 0 : (t5/t4), N: t4,Sub:t3,Expense:t2,Total:t1};
        this.vendorList.push(data);
        t1=0,t2=0,t3=0,t4=0,t5=0;
      });
    }
    this.vendorTotal = this.vtotalOut + this.vtotalExpense;
    this.totalCPI= (this.vCompletes === 0) ? 0 : this.vendorTotal/this.vCompletes;
    this.margin = this.totalClientAmount === 0 ? 0 : (this.totalClientAmount - this.vendorTotal) / this.totalClientAmount;
  }

  getFinances() {
    if(this._project == null) return;

    this.segment = this._project.projectSegments[0];
    if (//this.segment.segmentStatus === 'Closed' ||
      this.segment.segmentStatus === 'Invoiced')
      this.mode = 'full';

    let totalCost = 0;
    let totalCompletes = 0;
    this.segment.projectSurveyPartners.forEach(partner => {
      const partnerRecords = this._respondents == null ? null : this._respondents.filter(e => e.partnerId.toLowerCase() === partner.partnerId.toLowerCase());

      const partnerCompletes = partnerRecords == null ? 0 : partnerRecords.reduce((sum, current) => sum + current.complete, 0);
      partner.currentTotal = partnerCompletes * partner.buyCPI;
      totalCost += partner.currentTotal;
      totalCompletes += partnerCompletes;

      if (partner.isDeleted) {
        //we still show deleted partners if they had starts
        const partnerStarts = partnerRecords == null ? 0 : partnerRecords.reduce((sum, current) => sum + current.starts, 0);
        partner.show = (partnerStarts > 0);
      } else {
        partner.show = true;
      }
    });

    this.totals.expectedProjectValue = (this._project.fullLaunchQuota * this.segment.sellCPI);
    this.totals.currentProjectValue = (totalCompletes * this.segment.sellCPI);
    this.totals.clientCPI = this.segment.sellCPI;
    this.totals.partnersAvgCPI = (totalCompletes === 0 ) ? 0 : totalCost / totalCompletes;

    this.totals.partnersTotal = totalCost;
    this.totals.currentGM = this.totals.currentProjectValue === 0 ? 0 : (this.totals.currentProjectValue - totalCost) / this.totals.currentProjectValue;
  }

  enableEdit(item, attr) {
    this.unsavedChanges++;
    let val = item[attr];
    if (item.editMode == null) {
      item.editMode = {};
    }
    item.editMode[attr] = { enabled: true, value: val };
  }

  cancelProjectSegmentCPI(segment) {
    this.unsavedChanges--;
    segment.editMode.sellCPI.value = segment.sellCPI;
    segment.editMode.sellCPI = null;
  }

  saveProjectSegmentCPI(segment) {
    const newValue = segment.editMode.sellCPI.value;
    const oldValue = segment.sellCPI;

    segment.editMode.sellCPI = null;
    segment.sellCPI = newValue;

    this.projectService.UpdateProjectSegmentCPI(this._project.id, this.segment.id, newValue).subscribe((data) => {
      this.getFinances();
      this.alertify.success('Client CPI updated');
    }, (error) => {
      this.alertify.error('There was an issue updating client CPI');
      segment.sellCPI = oldValue;
    });
    this.unsavedChanges--;
  }

  cancelProjectPartnerCPI(partner) {
    partner.editMode.buyCPI.value = partner.buyCPI;
    partner.editMode.buyCPI = null;
    this.unsavedChanges--;
  }

  saveProjectPartnerCPI(partner) {
    const newValue = partner.editMode.buyCPI.value;
    const oldValue = partner.buyCPI;

    partner.editMode.buyCPI = null;
    partner.buyCPI = newValue;

    this.projectService.UpdateProjectPartnerCPI(this._project.id, partner.partnerId, newValue).subscribe((data) => {
      this.getFinances();
      this.alertify.success('Vendor CPI updated');
    }, (error) => {
      this.alertify.error('There was an issue updating Vendor CPI');
      partner.buyCPI = oldValue;
    });
    this.unsavedChanges--;
  }
}
