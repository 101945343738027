<div class="card m-2" [class.standalone-rid-card]="projectType.name == 'ResponseID'">
    <div class="card-header">
        <div class="row align-items-center">
            <div class="col-8">
                <h3 class="nav-secondary font-weight-bold">ResponseID</h3>
            </div>
            <div class="col text-right" *ngIf="batch">
                <i (click)="openModal()" *ngIf="auth.isInternal()" tooltip="Open ResponseID Settings" class="fak nav-font16 fa-message-info grey3 cursor-pointer"></i>
            </div>
        </div>
    </div>

    <ng-container *ngIf="!batch || showUpload">
        <div class="card-body">
            <div class="m-1" *ngIf="auth.isInternal()">
                <or-upload smallMsg=".csv files only" isCsv=true (uploadCompleted)="validateBatch()" publicBucket=false [includeProgressBar]=true acceptTypes=".csv" [instanceId]="auth.getUser().instanceId" [S3Folder]="s3buckPath" directToS3=true [(ngModel)]="uploadedFile" (change)="handleFileInput($event.target.files)"></or-upload>
            </div>

            <div class="m-1" *ngIf="!auth.isInternal()">
                <p class="h5 mb-0 font-weight-normal or-secondary">No ResponseID Data!</p>
            </div>

            <div class="col-12 d-flex justify-content-end" *ngIf="batch">
                <button type="button" class="btn btn-sm btn-outline-secondary mt-2 mr-0" (click)="cancel()"> Cancel</button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="batch && batch.status.toLowerCase() === 'complete' && metrics && showUpload==false">
        <div class="card-body">
            <div class="row">
                <div class="col d-flex justify-content-center">
                    <div class="d-inline-flex w-75 p-2" style="flex-grow: 1">
                        <div class="text-center w-100">
                            <span class="mb-3 pb-2 h4 w-100 d-block">Overall Respondent Score Categorization</span>
                            <div class="row">
                                <div class="col-1"></div>
                                <div class="col-4">
                                    <div id="rid-chart"></div>
                                </div>
                                <div class="col-6">
                                    <table class="table">
                                        <thead>
                                            <tr class="table-primary">
                                                <th class="py-1">Categorization</th>
                                                <th class="py-1">Respondents</th>
                                                <th class="py-1">Percentage</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="or-gray-bg">
                                                <td class="pt-1 pb-1">Critical</td>
                                                <td class="pt-1 pb-1">{{metrics.criticalCount}}</td>
                                                <td class="pt-1 pb-1">{{metrics.criticalCount / batch.totalRecords * 100 | number: '1.1-2'}}</td>
                                            </tr>
                                            <tr>
                                                <td class="pt-1 pb-1">High</td>
                                                <td class="pt-1 pb-1">{{metrics.highCount}}</td>
                                                <td class="pt-1 pb-1">{{metrics.highCount / batch.totalRecords * 100 | number: '1.1-2'}}</td>
                                            </tr>
                                            <tr class="or-gray-bg">
                                                <td class="pt-1 pb-1">Medium</td>
                                                <td class="pt-1 pb-1">{{metrics.mediumCount}}</td>
                                                <td class="pt-1 pb-1">{{metrics.mediumCount / batch.totalRecords * 100 | number: '1.1-2'}}</td>
                                            </tr>
                                            <tr>
                                                <td class="pt-1 pb-1">Low</td>
                                                <td class="pt-1 pb-1">{{metrics.lowCount}}</td>
                                                <td class="pt-1 pb-1">{{metrics.lowCount / batch.totalRecords * 100 | number: '1.1-2'}}</td>
                                            </tr>
                                            <tr class="or-gray-bg border-bottom">
                                                <td class="pt-1 pb-1"><b>Total</b></td>
                                                <td class="pt-1 pb-1"><b>{{batch.totalRecords}}</b></td>
                                                <td class="pt-1 pb-1"></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-1"></div>
                            </div>
                            <div class="row">
                                <div class="col-5 d-flex justify-content-end">
                                  <div *ngIf="this.largeFile" class="alert alert-primary py-0 px-1 ml-auto small d-inline float-right" role="alert">This file is large so we will send an email with file Link</div>
                                </div>
                                <div class="col-6 d-flex justify-content-end" *ngIf="this.largeFile==false">
                                    <button type="button" class="btn btn-sm btn-outline-secondary mt-2 mx-2" (click)="Upload()"><em class="fa fa-upload"></em> Upload</button>
                                    <button type="button" class="btn btn-sm btn-outline-secondary mt-2 mr-0" (click)="download()"><em class="fa fa-download"></em> Download</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </ng-container>

    <ng-container *ngIf="batch && batch.status.toLowerCase() !== 'complete' && batch.status.toLowerCase() !== 'reset'">
        <div class="card-body">
            <div class="row mt-5">
                <div class="col d-flex justify-content-center">
                    <div class="d-inline-flex or-gray-bg border w-75 p-2">
                        <div class="text-center w-100">
                            <div class="mb-3 pb-1 border-bottom w-100 d-block">
                                Processing Batch
                                <ng-container *ngIf="batch.status.toLowerCase() == 'in progress'">
                                    <span class="float-right small font-weight-bold">{{percentage}} % <div class="loading ml-2"></div></span>
                                    <div class="rule mt-2">
                                        <div class="progress or-gray-bg">
                                            <div class="progress-bar" role="progressbar" [style.width.%]="percentage" aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </ng-container>

                                <div *ngIf="batch.status.toLowerCase() == 'queued'" class="alert alert-primary py-0 px-1 ml-auto small d-inline float-right" role="alert">QUEUED</div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <p class="mb-1">
                                        Submitted On: <span class="small font-weight-bold">{{ batch.submittedOn | date: 'short' }}</span>
                                    </p>
                                    <p class="mb-1">
                                        Submitted By: <span class="small font-weight-bold">{{ batch.submittedBy }}</span>
                                    </p>
                                    <p class="mb-1">
                                        Total Rows: <span class="small font-weight-bold">{{ batch.totalRecords }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

  </div>
