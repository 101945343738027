import { map } from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProjectService, AlertifyService, QuotaService, ProjectQuota } from 'core';

@Component({
  selector: 'app-project-card-quota',
  templateUrl: './project-card-quota.component.html',
  styleUrls: ['./project-card-quota.component.scss']
})
export class ProjectCardQuotaComponent implements OnInit {

  @Input() public set project(data) {
    this._project = data;
  }
  @Input()  public set respondents(data) {
    this.processData(data);
  }

  @Output() cardresize = new EventEmitter();
  @Output() openSetup = new EventEmitter();

  @Input() mode = 'reduced';
  _project;
  loading = false;
  quotaDetails: any;
  limitMode = 'start';
  limitType = 'percent';
  limitCheck = 'enforced';
  vendorLimitsEnabled = false;
  metrics = { starts: 0, completes: 0}

  pages;
  pageIndex = 1;
  currentPage;

  constructor(
    private projectService: ProjectService,
    private alertify: AlertifyService) {}

  ngOnInit() {
  }

  processData(data) {
    
    let summary = {starts: 0, completes : 0};

    data.forEach(element => {
      if (element.complete != null) summary.completes += element.complete
      if (element.starts != null) summary.starts += element.starts
    });

    this.metrics = summary;
    this.getResults();
  }

  getBgColor(group, quota): string {
    let diff = ((quota.starts * 100) / group.starts) - quota.limit;

    if (this.limitCheck == 'tracking') {
      if (diff >= -3 && diff <= 3) {
        return 'bg-green';
      } else
        return 'bg-info';
    } else {
      if (diff >= -3 && diff <= 3 && diff !== 0) {
        return 'bg-green';
      } else if (diff == 0)
        return 'bg-secondary';
      else
        return 'bg-info';
    }
  }

  changePage(evt) {
      this.pageIndex = (evt == '+') ? this.pageIndex+1 : this.pageIndex-1;
      if (this.pageIndex > this.pages.length) {
        this.pageIndex = 1;
      }
      if (this.pageIndex <= 0) {
        this.pageIndex = this.pages.length;
      }
      this.currentPage = this.pages[this.pageIndex-1];
  }

  getResults() {
    this.projectService.GetQuota(this._project.id).subscribe(data => {
      if(data == null) {
        this.loading = false;
        return;
      }
      this.quotaDetails = data;
      this.pages = [];

      this.limitType = data.limitType;
      this.limitMode = data.limitMode;
      this.limitCheck = data.limitCheck;
      this.vendorLimitsEnabled = data.enableVendorLimits;
      
      this.quotaDetails.quotaGroups = this.quotaDetails.quotaGroups.filter(qg => qg.isDeleted == false)
      this.sort(this.quotaDetails.quotaGroups);


    


      this.quotaDetails.quotaGroups.filter(x => x.isDeleted == false).forEach(group => {
        group.completes = 0;
        group.starts = 0;

        let totalRemovedStarts = group.quotas.filter(x => x.isDeleted).reduce((count, elm) => count + elm.starts, 0);
        let totalRemovedCompletes = group.quotas.filter(x => x.isDeleted).reduce((count, elm) => count + elm.completes, 0);

        if (group.name == "Age") this.sort(group.quotas, 'minAge')
        else if (group.name == "Household Income") this.sort(group.quotas, 'minHHI')
        else this.sort(group.quotas)

        group.quotas = group.quotas.filter(x => x.isDeleted == false);
        group.quotas.forEach(q => {

          q.label = (q.targetGroup.division == null) ? null : q.targetGroup.division;
          q.label = (q.label == null) ? ((q.targetGroup.ethnicity == null) ? null : q.targetGroup.ethnicity) : q.label;
          q.label = (q.label == null) ? ((q.targetGroup.gender == null) ? null : q.targetGroup.gender) : q.label;
          q.label = (q.label == null) ? ((q.targetGroup.hispanic == null) ? null : q.targetGroup.hispanic) : q.label;
          q.label = (q.label == null) ? ((q.targetGroup.minAge == null) ? null : q.targetGroup.minAge + ' - ' + q.targetGroup.maxAge) : q.label;
          q.label = (q.label == null) ? ((q.targetGroup.minHHI == null) ? null : q.targetGroup.minHHI + ' - ' + q.targetGroup.maxHHI) : q.label;
          q.label = (q.label == null) ? ((q.targetGroup.region == null) ? null : q.targetGroup.region) : q.label;

          group.completes += q.completes;
          group.starts += q.starts;

          if (q.label == null) {
            if (q.targetGroup?.states == null || q.targetGroup.states?.length == 0)
              q.label == null;
            else {
              q.label = q.targetGroup.states.join(' - ');
            }
          }
        });



        if ((totalRemovedStarts + totalRemovedCompletes) > 0) {
          group.completes += totalRemovedCompletes;
          group.starts += totalRemovedStarts;

          group.quotas.push({
            label: 'removed group',
            limit: 0,
            starts: totalRemovedStarts,
            completes: totalRemovedCompletes,
            vendorLimits: []
           });
        }


        if (this.metrics.starts > group.starts) {
          var notAsked = {
            label: 'not asked',
            limit: 0,
            starts: this.metrics.starts - group.starts,
            completes: this.metrics.completes - group.completes,
            vendorLimits: []
          }

          group.quotas.push(notAsked);
          group.completes = this.metrics.completes;
        }
      });

      if (this.quotaDetails.quotaGroups.length > 0) {
        // Create Vendors array
        this.quotaDetails.vendors = [];
        var vendors = this._project.projectSegments[0].projectSurveyPartners
          .filter(x => x.isDeleted == false)
          .sort((a, b) => a.partnerName.localeCompare(b.partnerName));
        
        vendors.forEach(v => {
            v.quotaGroups = [];

            this.quotaDetails.quotaGroups.forEach(qg => {
              var vendorQG = {
                name: qg.name,
                starts: qg.starts,
                completes: qg.completes,
                quotas: []
              };
              

              qg.quotas.forEach(q => {
                if (q.label == 'not asked') {
                  q.vendorLimits = []
                }
                const existing = q.vendorLimits?.filter(x => x.vendorId.toLowerCase() == v.partnerId.toLowerCase())[0];
                vendorQG.quotas.push({
                  completes: (existing ? existing.completes : 0),
                  starts: (existing ? existing.starts : 0),
                  limit: (existing ? existing.limit : 0),
                  label: q.label, // undefined
                })
              }); 

          
              v.quotaGroups.push(vendorQG)

              });
              this.quotaDetails.vendors.push({
                id: v.partnerId,
                name: v.partnerName,
                quotaGroups: v.quotaGroups
              });
            });         

          this.sort( this.quotaDetails.vendors, 'name');
  
        }

      // First Overall page
      this.pages.push({
        label: 'Overall',
        type: 'overall',
        groups: this.quotaDetails.quotaGroups
      });

      // Create Vendor pages
      this.quotaDetails.vendors?.forEach(vendor => {
        this.pages.push({
          label: vendor.name,
          type: 'vendor',
          groups: vendor.quotaGroups
        });
      });
      this.setQuotaData(this.limitMode);
      this.loading = false;
    }, error => {
      this.loading = false;
      this.quotaDetails = null;
     // this.alertify.error('Unable to get quota details!');
    }, () => {
      if (this.pages === undefined) return;
      this.currentPage = this.pages[0]; // select first page (Overall)
    });
  }

  public openQuotaPage() {
    this.openSetup.emit(null);
  }

  resizeCard() {
    this.mode = this.mode === 'reduced' ? 'expanded' : 'reduced';
    this.cardresize.emit(this.mode);
  }

  changeSetting(evt, field) {
    this.loading = true;
    this.projectService.UpdateProjectValue(this._project.id, 'isQuotaEnabled', evt).subscribe( data => {
      this.alertify.success('Quota updated for this project');
      this._project.isQuotaEnabled = evt;
      this.loading = false;
    } , error => {
      this.alertify.error('Failed to Update Quota');
      this.loading = false;
    });
  }

  setQuotaData(mode) {
    this.limitMode = mode;
  }

  get ProgressStyle(): string {
    if(this.project.projectStatus === 'Paused' || this.project.projectStatus === 'Closed' || this.project.projectStatus === 'Invoiced') {
      return ' or-darkgray-bg';
    }
    else if (0 > (10 * 100 / this.project.fullLaunchQuota)) {
      return 'bg-danger';
    }
    return '';
  }

      // sort by 'order' property
      sort(array, sortBy = "") {
        array.sort((a, b) => {
          var A;
          var B;

          if (sortBy === 'order'|| sortBy === '')
          {
            A = a.order;
            B = b.order;
          }
          else if (sortBy === 'name')
          {
            A = a.name
            B = b.name
          }
          else if (sortBy === 'minAge')
          {
            A = a.targetGroup.minAge
            B = b.targetGroup.minAge
          }
          else if (sortBy === 'minHHI')
          {
            A = a.targetGroup.minHHI
            B = b.targetGroup.minHHI
          }
          let comparison = 0;
          if (A > B) {
            comparison = 1;
          } else if (A < B) {
            comparison = -1;
          }
          return comparison;
        });
      }

}
