<div class="nav-content container-fluid">
  <div class="d-flex mb-3 mt-1 border-bottom">
    <div class="text-truncate or-secondary h4 m-2"> Performance </div>
    <div class="vl ml-2"></div>
    <div class="sectionbtn nav-item">
      <a class="nav-link or-primary mt-1 cursor-pointer" [class.font-weight-bold]="tab == 'client'" (click)="showReport('client')">Client </a>
    </div>
    <div class="sectionbtn nav-item">
      <a class="nav-link or-primary mt-1 cursor-pointer" [class.font-weight-bold]="tab == 'vendor'" (click)="showReport('vendor')">Vendor</a>
    </div>
  </div>

    <div style="max-width:1400px;">
      <iframe #zohoreport frameborder=0 width="100%" height="5000" src=""></iframe>
    </div>

</div>

