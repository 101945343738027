<div class="card">
  <div class="card-header border-bottom">
    <app-widget-title [widget]="widget" (remove)="remove()" (update)="updateTitle($event)"></app-widget-title>
  </div>
  <div class="card-body">
    <div class="news-grid scrollbar overflow-y">
      <div class="card" *ngFor="let news of source">
        <div class="card-header p-0">
          <img [src]="news.content[0].src" class="img-fluid" [alt]="news.source">
        </div>
        <div class="card-body">
          <h6 class="mb-3">{{news.title}}</h6>
          <small class="d-block mb-3 font-italic" [innerHTML]="news.description.length > 500 ? news.description.substring(0,500) + ' […]' : news.description"></small>
          <small class="d-block font-italic">{{news.pubDate | date : 'EE, MMM d, yyyy h:mm a'}}</small>
          <a href={{news.link}}>Read More</a>
        </div>
      </div>
    </div>
  </div>

</div>
