import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { AuthService} from 'core';
import { DashboardService } from 'projects/core/src/services/dashboard.service';
import Highcharts from 'highcharts';

@Component({
  selector: 'app-widget-project-by-category',
  templateUrl: './widget-project-by-category.component.html',
  styleUrls: ['./widget-project-by-category.component.scss']
})
export class WidgetProjectByCategoryComponent implements OnInit {

  @Input() widget;
  @Input() updated;

  @Output() onremove = new EventEmitter();
  @Output() onupdate = new EventEmitter();
  @Output() onopenconfig = new EventEmitter();

  highcharts = Highcharts;
  highchartOptions: Highcharts.Options;
  projectTypes = ['FullService', 'SampleOnly', 'Blank'];

  projectByTypeMyProjects = true;

  constructor(
    public http: HttpClient,
    public auth: AuthService,
    public dashboardService: DashboardService) { }

  ngOnInit() {
  }

  getChartData() {
    this.dashboardService.getProjectByCategory(this.widget.settings).subscribe(data => {
    //  if (data.length <= 0) return;
      data = this.buildDataCountsArray(data);
      this.drawHighChartsChart(data.header, data.dataCounts);

    }, error => console.log(error));
  }

  ngOnChanges(changes: SimpleChanges) {
    this.widget = changes.widget.currentValue
    this.getChartData();
  }

  buildDataCountsArray(data) {
    let header = []; //'B2B', 'Consumer', 'Healthcare'
    let dataCounts: any = { };
    data.forEach(row => {
      delete row.id;
      if(row.category === '') row.category = 'Blank';
      if(row.type === '') row.type = 'Blank';
      if(dataCounts[row.category] == null){
        header.push(row.category);
        dataCounts[row.category] = {};
      }
      dataCounts[row.category][row.type] = row.count;
    });
    return {header, dataCounts};
  }

  drawHighChartsChart(header, dataCounts) {
    let chartData = [];

    this.projectTypes.forEach((type: string) => {
      var item = {
        name: type,
        data: []
      };
      header.forEach(h => {
        item.data.push(dataCounts[h][type] ?? 0)
      });
      chartData.push(item);
    });

    this.highchartOptions = {
      credits: {
        enabled: false
      },
      chart: {
        type: 'column',
        events: {
          render: function() {
            this.reflow();
          }
        }
      }, title: {
        text: ''
      }, xAxis: {
        categories: header
      }, yAxis: {
        min: 0,
        title: {
          text: 'Projects'
        }, stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: ( // theme
              Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color
            ) || 'gray',
            textOutline: 'none'
          }
        }
      }, legend: {
        align: 'right',
        x: 0,
        verticalAlign: 'top',
        y: 0,
        floating: true,
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      }, tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      }, plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      }, series: chartData
    };
  }

  remove() {
    this.onremove.emit();
  }

  updateWidget() {
    this.onupdate.emit();
  }

  configWidget() {
    this.onopenconfig.emit();
  }

  updateTitle(widgetTitle) {
    this.widget.title = widgetTitle;
    this.updateWidget();
  }
}
