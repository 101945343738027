<div class="container-fluid" style="z-index: 5;">
  <div class="col d-flex align-items-center px-0 mb-3">
    <h3 class="grey4 mr-auto mb-0">Survey Testing Portal</h3>
    <button type="button" class="btn nav-btn-accent btn-sm mr-2 shadow-none" (click)="launchSurvey()">
      <span>Go to Test Survey</span>
      <i class="fak fa-arrow-right-outlined ml-2"></i>
    </button>
  </div>

  <div class="programming-grid mb-4">
    <div class="left">
      <div class="card overview">
        <div class="card-header px-3 pt-3">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="nav-secondary font-weight-bold pb-0">Overview</h3>
              <!-- <div *ngIf="info.projects?.length < 1"  class="col nav-secondary">No Survey added to this project</div> -->
              @if (errorMessage!=='') {
                <div class="col nav-secondary nav-font14 pl-0 py-2">{{errorMessage}}</div>
              }
            </div>
          </div>
        </div>
        <div class="card-body pt-0 pb-4 nav-font14">
          @if (info.projects?.length > 0) {
            <h5 class="grey5 font-weight-bold">Team</h5>
            <div class="row mb-2 align-items-center mx-0">
              <div class="team-sub-title">
                <span class="grey4 mr-2 text-nowrap">Programmers</span>
              </div>
              @if (this.auth.isInternal() && !isClientview) {
                <div class="col grey5 nav-black2 ml-3">
                  <or-type-ahead
                    [labelField]="'fullName'"
                    [multi]="true"
                    [valueField]="'userId'"
                    (input)="formChanged($event)"
                    [dataCallback]="getUserList"
                    placeholder="Type to search Programmers"
                    (valueChange)="updateProgrammers($event)"
                    [selectedValues]="programmers">
                  </or-type-ahead>
                </div>
              }
              @if (this.auth.isClient()) {
                <div class="col grey5 nav-black2">
                  {{ programmersNames }}
                </div>
              }
            </div>
            <div class="row mb-0 align-items-center mx-0">
              <div class="team-sub-title">
                <span class="grey4 mr-2 text-nowrap">Reviewers</span>
              </div>
              @if (this.auth.isInternal() && !isClientview) {
                <div class="col grey5 nav-black2 ml-3">
                  <or-type-ahead [multi]="true"
                    [labelField]="'fullName'"
                    [valueField]="'userId'"
                    (input)="formChanged($event)"
                    [dataCallback]="getUserList"
                    placeholder="Type name and press enter"
                    (valueChange)="updateReviewers($event)"
                    [selectedValues]="reviewers">
                  </or-type-ahead>
                </div>
              }
              @if (this.auth.isClient()) {
                <div class="col grey5 nav-black2">
                  <!-- add a comma between each one -->
                  @for (reviewer of reviewers; track reviewer; let last = $last) {
                    <span>
                      {{ reviewer.fullName }}
                      @if (!last) {
                        ,
                      }
                    </span>
                  }
                </div>
              }
            </div>
          }

          <div class="row align-items-center">
            <div class="col">
              <h3 class="grey5 nav-font14 font-weight-bold mt-4 mb-3">Survey Links</h3>
            </div>
            @if (this.auth.isInternal() && !isClientview) {
              <div>
                <div class="col text-right mt-4 mb-3">
                  @if (!editLinkMode) {
                    <i class="fak fa-edit-square grey4 cursor-pointer" (click)="editLinkMode=true"
                    tooltip="Edit"></i>
                  }
                  @if (editLinkMode) {
                    <i class="fak fa-save fa-lg nav-accent-blue cursor-pointer" (click)="saveLinks()"
                    tooltip="Save"></i>
                  }
                  @if (editLinkMode) {
                    <i class="fas fa-close fa-lg cursor-pointer grey4 ml-2" (click)="editLinkMode=false;"
                    tooltip="Cancel"></i>
                  }
                </div>
              </div>
            }
          </div>


          <div class="row nav-font14 grey4 pb-3 mx-0">
            <div class=" d-flex align-items-center pr-4 mr-3">
              <span class="">
                Live URL <i class="ml-2 cursor-pointer fak fa-copy"
              (click)="copyUrl(_project.projectSegments[0].surveyLink)" tooltip="Copy Link"></i>
            </span>
          </div>
          <div class="url-text d-flex align-items-center">
            <span class="nav-black2 d-block text-truncate w-100" [tooltip]="(!editLinkMode) ? _project.projectSegments[0].surveyLink : ''">
              @if (editLinkMode) {
                <input [(ngModel)]="_project.projectSegments[0].surveyLink" name="surveyLink"
                  class="form-control form-control-sm form-control-nav">
                  @if (_project.projectSegments[0].surveyLink !='' && _project.projectSegments[0].surveyLink?.indexOf('#or1#') == -1) {
                    <div
                      class="alert alert-warning mt-1" role="alert">You must have a variable set to #or1# so that you can
                    track the unique IDs for this project</div>
                  }
                }
                @if (!editLinkMode) {
                  {{_project.projectSegments[0].surveyLink}}
                }
              </span>
            </div>
          </div>
          <div class="row nav-font14 grey4 pb-3 mx-0">
            <div class="d-flex align-items-center pr-4 mr-3">
              <span class="">
                Test URL <i class="ml-2 cursor-pointer fak fa-copy"
              (click)="copyUrl(_project.projectSegments[0].testSurveyLink)" tooltip="Copy Link"></i>
            </span>
          </div>
          <div class="url-text d-flex align-items-center">
            <span class="nav-black2 d-block text-truncate w-100" [tooltip]="(!editLinkMode) ? _project.projectSegments[0].testSurveyLink : ''">
              @if (editLinkMode) {
                <input [(ngModel)]="_project.projectSegments[0].testSurveyLink" name="testSurveyLink"
                  class="form-control form-control-sm form-control-nav">
                  @if (_project.projectSegments[0].testSurveyLink !='' && _project.projectSegments[0].testSurveyLink?.indexOf('#or1#') == -1) {
                    <div
                      class="alert alert-warning mt-1" role="alert">You must have a variable set to #or1# so that you can
                    track the unique IDs for this project</div>
                  }
                }
                @if (!editLinkMode) {
                  {{_project.projectSegments[0].testSurveyLink}}
                }
              </span>
            </div>
          </div>

          <div class="row nav-font14 grey4 mx-0">
            <div class=" d-flex align-items-center text-nowrap pr-4 mr-3">
              <span class="survey-platform-text">
                Survey Platform
              </span>
            </div>
            <div class="d-flex align-items-center platform-text">
              <span class=" nav-black2 d-block text-truncate w-100">
                @if (editLinkMode) {
                  <select [(ngModel)]='this._project.projectSegments[0].surveyPlatForm'
                    class="form-control form-control-sm form-control-nav" #teams (change)="onSelected(teams.value)">
                    <option default>-Select-</option>
                    <option>Decipher</option>
                    <option>Confirmit</option>
                    <option>Other</option>
                  </select>
                }
                @if (!editLinkMode) {
                  {{_project.projectSegments[0].surveyPlatForm}}
                }
              </span>
            </div>
          </div>
        </div>
      </div>

      @if (auth.isInternal() && !isClientview) {
        <div class="card guests">
          <div class="card-header px-3 pt-3">
            <div class="row align-items-center">
              <div class="col">
                <h3 class="nav-secondary font-weight-bold pb-1">Guest List</h3>
                @if (info.projects?.length < 1 && surveyId !== null && surveyId !== '') {
                  <div class="col nav-secondary">
                  No Data available</div>
                }
                @if (info.projects?.length < 1 && (surveyId === null || surveyId === '')) {
                  <div
                  class="col nav-secondary">Survey Link must be added first</div>
                }
              </div>
              <div class="col text-right">
                @if (!addGuestMode && surveyId !== null && surveyId !== '') {
                  <i class="fak fa-edit-square grey4 cursor-pointer"
                  (click)="addGuestMode=true"></i>
                }
                @if (addGuestMode) {
                  <i class="fas fa-close fa-lg cursor-pointer grey4 ml-2"
                  (click)="addGuestMode=false"></i>
                }
              </div>
            </div>
          </div>
          <div class="card-body px-3 scrollbar overflow-y pt-0">
            @if (addGuestMode || guests?.length > 0) {
              <div class="grid nav-font14 grey4 mb-3">
                <div class="">Name</div>
                <div class="">E-mail</div>
                <div class=" text-center">Expires</div>
                <div class="actions">&nbsp;</div>
              </div>
            }
            @if (guests?.length > 0) {
              @for (user of guests; track user) {
                <div class="grid nav-black2 mb-2">
                  <div class="nav-font12">{{user.fullName}}</div>
                  <div class="nav-font12">{{user.email}}</div>
                  <div class="nav-font12 text-center">{{user.expiresAt | date: 'MMM d, y'}}</div>
                  <div class="actions text-right">
                    <i class="fak fa-copy cursor-pointer" (click)="copyLink(user)" tooltip="Copy guest link"
                    container="body"></i>
                    <i class="fa fa-envelope cursor-pointer grey4" (click)="sendEmail(user)" tooltip="Send link by email"
                    container="body"></i>
                    <i class="fak fa-trash cursor-pointer nav-error" (click)="deleteGuest(user)" tooltip="Delete guest"
                    container="body"></i>
                  </div>
                </div>
              }
            }
            <!-- Add Guest -->
            @if (addGuestMode) {
              <div class="grid save nav-black2 mt-3">
                <div class="nav-font12">
                  <input type="text" required class="form-control form-control-sm form-control-nav" #name="ngModel"
                    [(ngModel)]="newGuest.fullName" [ngClass]="showCssValidField(name)">
                  </div>
                  <div class="nav-font12">
                    <input type="email" email required class="form-control form-control-sm form-control-nav"
                      #email="ngModel" [(ngModel)]="newGuest.email" [ngClass]="showCssValidField(email)">
                    </div>
                    <div class="nav-font12 text-center">
                      <input type="text" autocomplete="off" readonly bsDatepicker [bsValue]="newGuest.expiresAt"
                        (bsValueChange)="newGuest.expiresAt = $event"
                        [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', isAnimated: true, isOpen:true, showWeekNumbers:false, customTodayClass: 'todays-date' }"
                        class="form-control form-control-sm form-control-nav date d-inline bg-white">
                      </div>
                      <div class="actions d-flex align-items-center">
                        <i class="fak fa-lg fa-save nav-accent-blue cursor-pointer" (click)="saveGuest()" tooltip="Save guest"
                        container="body"></i>
                      </div>
                    </div>
                  }
                </div>
              </div>
            }
          </div>
          <div class="card comment-preview-card">
            <div class="card-header px-3 pt-3 mx-3">
              <div class="row align-items-center">
                <div class="col pl-0">
                  <h3 class="nav-secondary font-weight-bold pb-0 mb-0">Comment Preview</h3>
                  @if (info.projects?.length < 1 && notes?.length == 0) {
                    <div class="col nav-secondary">No Data available
                    </div>
                  }
                </div>
              </div>
            </div>

            @if (info.projects?.length > 0) {
              <div class="filters btn-group w-100 border-bottom mb-0">
                <div class="border-bottom-0">
                  <button type="button" class="btn btn-sm px-1 mx-3 text-left"
                    [class.active]="statusFilter.indexOf('open') > -1" (click)="toggleFilter('open')">
                    New @if (totalNewComments > 0 && !sessionFilterId && !questionId) {
                    <span class="badge badge-pill ml-2 font-weight-normal new"
                    >{{totalNewComments}}</span>
                  }
                </button>
                <button type="button" class="btn btn-sm px-1 mx-3 text-left"
                  [class.active]="statusFilter.indexOf('pending') > -1" (click)="toggleFilter('pending')">
                  In Progress @if (totalPendingComments > 0 && !sessionFilterId && !questionId) {
                  <span class="badge badge-pill ml-2 font-weight-normal pending"
                  >{{totalPendingComments}}</span>
                }
              </button>
              <button type="button" class="btn btn-sm px-1 mx-3 text-left"
                [class.active]="statusFilter.indexOf('closed') > -1" (click)="toggleFilter('closed')">
                Closed
              </button>
            </div>
          </div>
        }

        <div class="card-body px-3 py-3 scrollbar overflow-y pt-2">




          @for (item of notes; track item) {
            <div class="card mb-2 mr-2 comment-preview">
              <div class="card-body p-3">
                <div class="d-flex align-items-center">
                  <h5 class="grey5 w-100 font-weight-bold">{{item.createdBy}}</h5>
                </div>
                <h6 class="mb-2 pb-3 grey3">
                  {{ item.commentType == 'client-change' ? 'Client change submitted ' + (item.createdDate | timeAgo) :
                  'Error submitted ' + (item.createdDate | timeAgo) }}
                </h6>
                <div class="row nav-font14 mb-3">
                  <div class="col-3 pr-0">
                    <span class="font-weight-bold mb-2 grey4">Question:</span>
                  </div>
                  <div class="col">
                    @if (item.questionId || item.anwerId) {
                      <span class="font-weight-bold nav-black2">
                        ({{item.questionId.replace('question_','') | uppercase}}{{ (item.answerId ? ', ' + item.answerId : '') |
                        uppercase}})
                      </span>
                    }
                    <span class="font-weight-normal">{{item.questionText}}</span>
                  </div>
                </div>
                <div class="row nav-font14">
                  <div class="col-3 pr-0">
                    <span class="font-weight-bold mb-2 grey4">Comment:</span>
                  </div>
                  <div class="col">
                    <span class="font-weight-normal comment-text nav-black2" [innerHTML]="item.commentText"></span>
                  </div>
                </div>
                <div class="card-footer actions text-right d-flex px-0 pb-0 mt-2">
                  @if (surveyStatus != 'finalized') {
                    <a class="btn btn-sm text-secondary d-flex align-items-center px-0"
                      [ngClass]="{'text-secondary' : item.totalNewReplies == 0, 'text-success' : item.totalNewReplies > 0}"
                      (click)="toggleReply(item)">
                      <i class="fak fa-lg fa-messages d-block"></i>
                      <span class="mx-2">{{item.replies?.length}}</span>
                    </a>
                    @if (item.status == 'open') {
                      <div class="grid-open w-100 d-flex align-items-center justify-content-end">
                        <div>
                          <span [tooltip]="item.totalNewReplies > 0 ? 'View unread replies' : ''" container="body">
                            <button type="button" class="btn btn-sm shadow-none" [disabled]="item.totalNewReplies > 0"
                              [ngClass]="{'st-btn-primary': item.totalNewReplies == 0, 'nav-btn-disabled': item.totalNewReplies > 0}"
                              (click)="confirm('sendForApproval', item.id)">Resolved
                            </button>
                          </span>
                        </div>
                        <div>
                          <button type="button" class="btn btn-sm nav-btn-accent shadow-none ml-2"
                            (click)="viewMore(item)">
                            {{ !openReply[item.id] ? 'Update comment' : 'Cancel' }}
                          </button>
                        </div>
                      </div>
                    }
                    @if (item.status != 'open') {
                      <div class="grid-open w-100 d-flex align-items-center justify-content-end">
                        @if (item.status == 'pending') {
                          <div class="d-flex align-items-center position-relative user">
                            <span tooltip="Submitted for approval by {{item.sentForApprovalBy}}" placement="bottom"
                              container="body">
                              <or-profile-image [name]="item.sentForApprovalBy" size="small"
                              showInitials=true></or-profile-image>
                            </span>
                          </div>
                        }
                        @if (item.status == 'approved') {
                          <div class="d-flex align-items-center position-relative user">
                            <span tooltip="Approved by {{item.approvedBy}}" placement="bottom" container="body">
                              <or-profile-image [name]="item.approvedBy" size="small" showInitials=true></or-profile-image>
                            </span>
                          </div>
                        }
                        @if (item.status == 'cancelled') {
                          <div class="d-flex align-items-center position-relative user">
                            <span tooltip="Cancelled by {{item?.cancelledBy}}" placement="bottom" container="body">
                              <or-profile-image [name]="item?.cancelledBy" size="small"
                              showInitials=true></or-profile-image>
                            </span>
                          </div>
                        }
                        <div>
                          <button type="button" class="btn btn-sm shadow-none ml-2"
                            [ngClass]="{'st-btn-primary': item.totalNewReplies == 0, 'nav-btn-disabled': item.totalNewReplies > 0}"
                            (click)="confirm('reopen', item.id)">Reopen
                          </button>
                        </div>
                        @if (item.status == 'pending') {
                          <div>
                            <button type="button" class="btn btn-sm shadow-none ml-2"
                              [ngClass]="{'nav-btn-accent': item.totalNewReplies == 0, 'nav-btn-disabled': item.totalNewReplies > 0}"
                              (click)="confirm('approve', item.id)">Approve
                            </button>
                          </div>
                        }
                      </div>
                    }
                  }
                </div>
                @if (openReply[item.id]) {
                  <div class="replies">
                    <hr class="px-3 nav-border1 mx-1">
                    @for (r of item?.replies; track r) {
                      <div class="row text-right mt-2 m-1">
                        <div class="col text-left">
                          <div class="row">
                            <div class="col px-0 text-left">
                              <span class="font-weight-bold nav-secondary">{{r.createdBy}}</span>
                            </div>
                            <div class="col-5 px-0 text-right">
                              <span class="grey4 nav-font12">{{r.createdTime | timeAgo}}</span>
                            </div>
                          </div>
                          <div class="row mt-2 nav-font14">
                            <span [innerHTML]="r.replyText"></span>
                          </div>
                        </div>
                      </div>
                      <hr class="nav-border-white mx-1 my-0">
                    }
                    <div>
                      <div class="ckeditor position-relative">
                        <or-wysiwyg (messageEvent)="receiveMessage(item, $event)" (messageCount)="getCount(item, $event)"
                        [config]="editorConfig" [data]="item?.replyText == null ? '' : item.replyText"></or-wysiwyg>
                        <div class="save-reply cursor-pointer">
                          <i class="fa-regular fa-paper-plane-top" (click)="saveReply(item)"></i>
                        </div>
                      </div>
                      <div class="col mt-2 px-0">
                        <div class="ckeditor position-relative">
                          <h6 class="grey5">
                            <h6 class="d-inline">{{item?.commentLength || 0}}
                              </h6> / <span style="font-size: larger; font-weight: bolder;">∞</span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            }
          </div>
        </div>
        @if (auth.isInternal() && !isClientview) {
          <div class="card session-log-preview-card">
            <div class="card-header pt-3 px-3">
              <div class="row">
                <div class="col-8">
                  <h3 class="nav-secondary font-weight-bold pb-1">Session Log Preview</h3>
                  @if (teamSessions?.length == 0) {
                    <div class="col nav-secondary pl-0 mt-4">No Data available</div>
                  }
                </div>
                <div class="col-4 text-right">
                  <button type="button" class="btn nav-btn-accent btn-sm mr-2 shadow-none" (click)="export()">
                    <span>Export</span>
                    <i class="fak fa-export ml-1"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body px-3 pt-1 scrollbar overflow-y">
              @if (teamSessions?.length > 0) {
                <div class="row">
                  <h5 class="col grey5 font-weight-bold mb-3">Comment Summary</h5>
                </div>
                <div class="w-100 mb-2">
                  <div class="row mb-2 nav-font14">
                    <div class="col-3">
                      <span class="grey4">New</span>
                    </div>
                    <div class="col nav-black2">
                      {{summary.totalOpen}}
                    </div>
                    <div class="col-4">
                      <span class="grey4">Program errors</span>
                    </div>
                    <div class="col nav-black2">
                      {{summary.totalErrors}}
                    </div>
                  </div>
                  <div class="row mb-2 nav-font14">
                    <div class="col-3">
                      <span class="grey4">In progress</span>
                    </div>
                    <div class="col nav-black2">
                      {{summary.totalResolved}}
                    </div>
                    <div class="col-4">
                      <span class="grey4">Client changes</span>
                    </div>
                    <div class="col nav-black2">
                      {{summary.totalChanges}}
                    </div>
                  </div>
                  <div class="row mb-3 nav-font14">
                    <div class="col-3">
                      <span class="grey4">Closed</span>
                    </div>
                    <div class="col nav-black2">
                      {{summary.totalClosed}}
                    </div>
                    <div class="col-4">
                      <span class="grey4">Other changes</span>
                    </div>
                    <div class="col nav-black2">
                      {{summary.totalOther}}
                    </div>
                  </div>
                  <hr class="nav-border1">
                  <div class="row mt-4">
                    <h5 class="col grey5 font-weight-bold mb-3">Team Member Summary</h5>
                  </div>
                  @for (item of teamSessions; track item) {
                    <div class="card w-100 mb-2 summary">
                      <div class="card-body p-3">
                        <h5 class="grey5 w-100 font-weight-bold">{{item.user || "Name"}}</h5>
                        <h6 class="mb-2 grey2">Last session {{item.lastSessionDate | timeAgo}}</h6>
                        <div class="w-100 mt-3 grey4">
                          <h5 class="w-100">Comment Summary</h5>
                          <div class="col nav-font14">
                            <div class="row border-bottom text-center">
                              <div class="col border-right font-weight-bold py-2">
                                <span>New</span>
                              </div>
                              <div class="col border-right font-weight-bold py-2">
                                <span>In progress</span>
                              </div>
                              <div class="col font-weight-bold py-2">
                                <span>Closed</span>
                              </div>
                            </div>
                            <div class="row text-center nav-black2">
                              <div class="col border-right">
                                <span>{{item?.totalOpen}}</span>
                              </div>
                              <div class="col border-right">
                                <span>{{item?.totalResolved}}</span>
                              </div>
                              <div class="col">
                                <span>{{item?.totalClosed}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        }


      </div>

      <ng-template #modalConfirmApprove>
        <div class="modal-header d-flex align-items-center justify-content-center">
          <div class="w-100">
            <div class="d-flex justify-content-end align-content-center">
              <i class="fas fa-close grey4" (click)="openModalRef.hide();"></i>
            </div>
          </div>
        </div>
        <div class="modal-body pt-0">
          <h3 class="font-weight-bold nav-secondary text-center">Are you sure you want to approve this comment?</h3>
          <h5 class="grey5 text-center d-block my-3">
            <i class="fak fa-info grey4 fa-md" (click)="openModalRef.hide();"></i>
            You can reopen a comment after it's approved.
          </h5>
          @if (isLive) {
            <h5 class="nav-error d-block">
              <i class="fak fa-warning fa-md" (click)="openModalRef.hide();"></i>
              This testing session is occurring while you are in live mode. The testing data may end up in the live dataset.
            </h5>
          }
        </div>
        <div class="modal-footer">
          <div class="row d-flex w-100 align-items-center">
            <div class="col-5 px-0 or-checkbox d-flex justify-content-start">
              <input [(ngModel)]="notAskAgainApprove" type="checkbox" name="doNotAskAgain" id="doNotAskAgain">
              <label for="doNotAskAgain"></label>
              <small class="pl-4 grey4">Don't ask again</small>
            </div>
            <div class="col text-right px-0">
              <button class="btn btn-sm st-btn-primary mx-1 shadow-none" (click)="openModalRef.hide();">Cancel</button>
              <button class="btn btn-sm nav-btn-accent shadow-none" (click)="approve()">Approve</button>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #modalConfirmSentForApproval>
        <div class="modal-header d-flex align-items-center justify-content-center">
          <div class="w-100">
            <div class="d-flex justify-content-end align-content-center">
              <i class="fas fa-close grey4" (click)="openModalRef.hide();"></i>
            </div>
          </div>
        </div>
        <div class="modal-body pt-0">
          <h3 class="font-weight-bold nav-secondary text-center">Are you sure you want to resolve this comment?</h3>
          @if (isLive) {
            <h5 class="nav-error d-block">
              <i class="fak fa-warning fa-md" (click)="openModalRef.hide();"></i>
              This testing session is occurring while you are in live mode. The testing data may end up in the live dataset.
            </h5>
          }
        </div>
        <div class="modal-footer">
          <div class="row d-flex w-100 align-items-center">
            <div class="col-5 px-0 or-checkbox d-flex justify-content-start">
              <input [(ngModel)]="notAskAgainApproval" type="checkbox" name="doNotAskAgain" id="doNotAskAgain">
              <label for="doNotAskAgain"></label>
              <small class="pl-4 grey4">Don't ask again</small>
            </div>
            <div class="col text-right pr-0">
              <button class="btn btn-sm st-btn-primary mx-1 shadow-none" (click)="openModalRef.hide();">No</button>
              <button class="btn btn-sm nav-btn-accent shadow-none" (click)="sendForApproval()">Yes, Resolve</button>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #modalConfirmCancel>
        <div class="modal-header d-flex align-items-center justify-content-center">
          <div class="w-100">
            <div class="d-flex justify-content-end align-content-center">
              <i class="fas fa-close grey4" (click)="openModalRef.hide();"></i>
            </div>
          </div>
        </div>
        <div class="modal-body pt-0">
          <h3 class="font-weight-bold nav-secondary text-center">Are you sure you want to cancel this comment?</h3>
          <h5 class="grey5 text-center d-block my-3">
            <i class="fak fa-info grey4 fa-md" (click)="openModalRef.hide();"></i>
            You can reopen a comment after it's cancelled.
          </h5>
          @if (isLive) {
            <h5 class="nav-error d-block">
              <i class="fak fa-warning fa-md" (click)="openModalRef.hide();"></i>
              This testing session is occurring while you are in live mode. The testing data may end up in the live dataset.
            </h5>
          }
        </div>
        <div class="modal-footer">
          <div class="row d-flex w-100">
            <div class="col text-right pr-0">
              <button class="btn btn-sm st-btn-primary mx-1 shadow-none" (click)="openModalRef.hide();">No</button>
              <button class="btn btn-sm nav-btn-accent shadow-none" (click)="cancel()">Yes, cancel</button>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #modalConfirmReopen>
        <div class="modal-header d-flex align-items-center justify-content-center">
          <div class="w-100">
            <div class="d-flex justify-content-end align-content-center">
              <i class="fas fa-close grey4" (click)="openModalRef.hide();"></i>
            </div>
          </div>
        </div>
        <div class="modal-body pt-0">
          <h3 class="font-weight-bold nav-secondary text-center">Are you sure you want to reopen this comment?</h3>
          @if (isLive) {
            <h5 class="nav-error d-block my-3">
              <i class="fak fa-warning fa-md" (click)="openModalRef.hide();"></i>
              This testing session is occurring while you are in live mode. The testing data may end up in the live dataset.
            </h5>
          }
        </div>
        <div class="modal-footer">
          <div class="row d-flex w-100 align-items-center">
            <div class="col-5 px-0 or-checkbox d-flex justify-content-start">
              <input [(ngModel)]="notAskAgainReopen" type="checkbox" name="doNotAskAgain" id="doNotAskAgain">
              <label for="doNotAskAgain"></label>
              <small class="pl-4 grey4">Don't ask again</small>
            </div>
            <div class="col text-right pr-0">
              <button class="btn btn-sm st-btn-primary mx-1 shadow-none" (click)="openModalRef.hide();">No</button>
              <button class="btn btn-sm nav-btn-accent shadow-none" (click)="reopen()">Yes, reopen</button>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #modalConfirmParameters>
        <div class="modal-header">
          <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18">Assign Parameters</h2>
          <div class="d-flex justify-content-end">
            <i class="fas fa-close grey4" (click)="closeModalParameter()"></i>
          </div>
        </div>
        <div class="modal-body grey4 nav-font14">
          <!-- <div class="row mb-4">
          <div class="col-9">Select a user to reassign the following tasks</div>
          <div class="col">Current assignee</div>
        </div> -->
        <div class="scrollbar overflow-y overflow-x-hidden">
          @for (param of urlParams; track param) {
            <div class="row mb-2">
              <div class="col d-flex align-items-center">
                <span class="grey5">{{param.name}}</span>
              </div>
              <div class="col-9">
                <!-- {{param.value}} -->
                <input type="text" required class="form-control form-control-sm form-control-nav" [(ngModel)]="param.value">
              </div>
            </div>
          }
        </div>

        <div class="col text-right pr-0 mt-4">
          <button type="button" class="btn nav-btn-primary mx-2 shadow-none font-weight-bold"
          (click)="closeModalParameter()">Cancel</button>
          <button type="button" class="btn nav-btn-accent shadow-none font-weight-bold"
          (click)="launchSurveywithParameter()">Go</button>
        </div>
      </div>
    </ng-template>
