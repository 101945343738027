<div class="nav-content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header text-white  or-primary-bg">
        <span class="h4"><i class="fas fa-chart-bar"></i> Report Builder</span>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col mb-4">
            <div class="card step-{{step}}">
              <div class="card-header bg-white border-bottom-0 pb-0">
                <span class="h5 or-primary mb-0" *ngIf="step < 2">Create New Report</span>

                <ng-container *ngIf="step > 1">
                  <div class="row">
                    <div class="col">
                      <div class="or-secondary">
                        <small class="cursor-pointer" *ngIf="step > 1"><u (click)="step1()">Reports</u></small>
                        <small class="cursor-pointer" *ngIf="step == 2"> / Fields</small>
                        <small class="cursor-pointer" *ngIf="step > 2"> / <span (click)="step2(report.reportType)"><u>Fields</u></span></small>
                        <small class="cursor-pointer" *ngIf="step == 3"> / Filters</small>
                        <small class="cursor-pointer" *ngIf="step > 3"> / <span (click)="step3()"><u>Filters</u></span></small>
                        <small class="cursor-pointer" *ngIf="step > 3"> / Export</small>
                      </div>
                    </div>
                    <div class="col text-right">
                      <button type="button" class="btn btn-sm btn-outline-secondary mx-1" (click)="back()">Back</button>
                      <button type="button" class="btn btn-sm btn-secondary nav-btn-accent text-white mx-1" (click)="next()" *ngIf="step < 4">
                        <ng-container *ngIf="step < 3">Next</ng-container>
                        <ng-container *ngIf="step == 3">Run</ng-container>
                      </button>
                      <button type="button" class="btn btn-sm btn-secondary nav-btn-accent text-white mx-1" (click)="saveReport()" *ngIf="step == 4"> {{ myReport ? 'Save Report' : 'Save As New Report' }} </button>
                      <button type="button" class="btn btn-sm btn-success text-white mx-1" (click)="exportReport()" *ngIf="step == 4">Export Report</button>
                    </div>
                  </div>
                </ng-container>

              </div>
              <div class="card-body">
                <!-- First Page (Step 1) -->
                <ng-container *ngIf="step <= 1">
                  <div class="row">
                    <div class="col">
                      <div class="alert alert-info w-100 px-3">
                        <h5 class="font-weight-normal or-primary mb-1">Start your report setup here!</h5>
                        <small class="m-0">Select a new report type below to setup or pick from a saved reports to load:</small>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center mt-2">
                    <div class="col-9">
                      <div class="justify-content-center w-100 mb-2" *ngFor="let type of reportTypes">
                        <or-action-button [label]="type.displayName" size="large" [description]="type.info" [icon]="type.icon" (selected)="createNewReport(type.name)"></or-action-button>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <!-- Creating the Report -->
                <ng-container *ngIf="step > 1">
                  <div class="row">
                    <div class="col-5 d-flex align-items-center">
                      <label for="firstName" class="h5 or-primary mb-0">Report Name</label>
                      <input type="text" id="reportName" class="form-control form-control-sm w-50 ml-4" [(ngModel)]="report.reportName" />
                    </div>
                  </div>
                  <div class="row mt-2" *ngIf="step == 4 && !myReport" >
                    <span class="badge warn font-weight-normal grey4 p-2"><i class="far fa-square-info mr-2 fa-lg" aria-hidden="true"></i>This is not your report so if you save any changes it will create a new report </span>                    
                  </div>
                  <div class="row">
                    <div class="col">

                      <!-- Step 2 (Fields) -->
                      <div *ngIf="step == 2">

                        <p class="h6 required or-primary mt-4"><i class="fas fa-table fa-sm"></i> Fields</p>

                        <or-listbox
                          [showCount]="true"
                          [items]="moduleData"
                          [headers]="headers"
                          [loadSelected]="report.columns"
                          (selected)="handleSelectedFields($event)">
                        </or-listbox>
                      </div>

                      <!-- Step 3 (Filters) -->
                      <div *ngIf="step == 3" class="mt-4">
                        <ng-container *ngTemplateOutlet="filters"></ng-container>
                        <ng-container *ngIf="loadingData">
                            <div class="loading"></div>
                            <div>Please wait while the preview is loading</div>
                        </ng-container>
                        <ng-container *ngIf="!loadingData">
                          <or-list [items]="tableData | filter : filterText : reportTableFilterColumns" [layout]="reportTableColumns"></or-list>
                        </ng-container>

                      </div>

                      <!-- Step 4 (View Report) -->
                      <div *ngIf="step == 4" class="mt-4">
                        <ng-container *ngIf="loadingData">
                          <div class="loading"></div>
                          <div>Please wait while the report is loading</div>
                        </ng-container>
                        <ng-container *ngIf="!loadingData">

                          <div class="table-responsive" *ngIf="!loading">
                            <or-list [items]="tableData | filter : filterText : reportTableFilterColumns" [enableExport]=true [layout]="reportTableColumns"></or-list>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-8" *ngIf="step == 1">
            <div class="card step-{{step}}">
              <div class="card-header bg-white border-bottom-0">
                <span class="h5 or-primary mb-0">Saved Reports</span>
                <span class="loading float-right" *ngIf="loading"></span>
              </div>
              <div class="p-4" *ngIf="!loading">
                <or-list
                  [items]="savedReports | filter : filterText : filterColumns"
                  [layout]="layout"
                  rowid="id"
                  [actions]="actions"
                  (action)="handleAction($event)"
                  clickroute="/reports/builder/">
                </or-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #filters>

  <p class="h6 required or-primary mt-4" *ngIf="filtersList.length > 0"><i class="fas fa-filter fa-xs"></i> Filters</p>

  <div *ngFor="let filter of filtersList" class="filter-grid ml-3">
    <div class="d-flex align-items-center">
      <small>{{filter.label}}</small>
    </div>
    <div>
      <div class="grid-{{filter.type}}">

        <ng-container *ngIf="filter.type === 'text'">
          <div>
            <select [(ngModel)]="filter?.options.operator" class="form-control form-control-xs">
              <option [ngValue]="'contains'">Contains</option>
              <option [ngValue]="'doesntContain'">Doesn't Contain</option>
              <option [ngValue]="'equals'">Equals</option>
            </select>
          </div>
          <div>
            <input type="text" class="form-control form-control-xs" [(ngModel)]="filter?.options.searchText" />
          </div>
        </ng-container>

        <ng-container *ngIf="filter.type === 'number'">
          <div>
            <select [(ngModel)]="filter?.options.operator" class="form-control form-control-xs">
              <option [ngValue]="'greaterThan'">Greater Than</option>
              <option [ngValue]="'lessThan'">Less Than</option>
              <option [ngValue]="'equals'">Equals</option>
            </select>
          </div>
          <div>
            <input type="number" class="form-control form-control-xs" [(ngModel)]="filter?.options.value" />
          </div>
        </ng-container>

        <ng-container *ngIf="filter.type === 'date'">
          <div>
            <select [(ngModel)]="filter?.options.operator" class="form-control form-control-xs">
              <option [ngValue]="''">None</option>
              <option [ngValue]="'between'">Between</option>
              <option [ngValue]="'before'">Before</option>
              <option [ngValue]="'after'">After</option>
              <option [ngValue]="'week'">This week</option>
              <option [ngValue]="'month'">This month</option>
              <option [ngValue]="'quarter'">This quarter</option>

            </select>
          </div>
          <div>
            <div *ngIf="filter?.options.operator === 'between'">
              <input
                type="text" autocomplete="off"
                class="form-control form-control-xs"
                placeholder="Enter a date range"
                [(ngModel)]="filter.options.dateRange"
                [value]="dateRange"
                #drp="bsDaterangepicker"
                bsDaterangepicker
                [bsConfig]="{
                  showPreviousMonth: false,
                  dateInputFormat: 'MMM Do, YYYY',
                  displayOneMonthRange: true,
                  isAnimated: false,
                  isOpen: true,
                  containerClass: 'theme-blue',
                  showWeekNumbers:false
                }">
            </div>
            <div *ngIf="filter?.options.operator === 'before' || filter?.options.operator === 'after'">
              <input
                type="text" autocomplete="off"
                class="form-control form-control-xs"
                placeholder="Enter a date"
                [(ngModel)]="filter.options.date"
                [value]="date"
                #drp="bsDatepicker"
                bsDatepicker
                [bsConfig]="{
                  showPreviousMonth: false,
                  dateInputFormat: 'MMM Do, YYYY',
                  displayOneMonthRange: true,
                  isAnimated: false,
                  isOpen: true,
                  containerClass: 'theme-blue',
                  showWeekNumbers:false,
                  customTodayClass: 'todays-date'
                }">
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="filter.type === 'status'">
          <div>
            <select [(ngModel)]="filter?.options.searchText" class="form-control form-control-xs">
              <option [ngValue]="'Closed'">Closed</option>
              <option [ngValue]="'Full Launch'">Full Launch</option>
              <option [ngValue]="'Invoiced'">Invoiced</option>
              <option [ngValue]="'Paused'">Paused</option>
              <option [ngValue]="'Soft Launch'">Soft Launch</option>
              <option [ngValue]="'Test'">Test</option>
            </select>
          </div>
          <div>
          </div>
        </ng-container>

        <div *ngIf="report.reportType != 'summary'">
          <button class="btn btn-sm px-0" (click)="removeFilter(filter.id)">
            <i class="fa fa-minus-circle text-danger"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="btn-group mt-3" role="group" *ngIf="report.reportType != 'summary'">
    <button type="button" class="btn btn-sm btn-secondary">
      <or-dropdown [values]="filterableData" text="Add Filter" (selected)="changeFilter($event)"></or-dropdown>
    </button>
  </div>

  <hr class="or-dottedline">

</ng-template>
