import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable, forkJoin } from 'rxjs';
import { QCFlowDetails, QCFlowFile } from '../models/qc-flow-details';
import { QCFlowBulkAction, QCFlowRecord, MatchedRuleRecords} from '../models/qc-flow-records';

@Injectable({
  providedIn: 'root'
})
export class QCFlowService {

  baseUrl = environment.apiEndpoint + '/qcflow/';

  constructor(private http: HttpClient) { }

  GetDetails(projectId: string): Observable<QCFlowDetails> {
    return this.http.get<QCFlowDetails>(this.baseUrl + projectId + '/details');
  }

  SaveDetails(projectId: string, details: QCFlowDetails): Observable<any> {
    return this.http.post(this.baseUrl + projectId + '/details', details);
  }
  SaveRules(projectId: string, details: QCFlowDetails): Observable<any> {
    return this.http.put(this.baseUrl + projectId + '/rules', details);
  }
  SaveSetting(projectId: string, type: string, value: boolean): Observable<any> {
    return this.http.post(this.baseUrl + projectId + `/details/settings/${type}/${value}`, {});
  }
  AddAnotherFile(projectId: string, fileDto: QCFlowFile): Observable<any> {
    return this.http.post(this.baseUrl + projectId + '/details/file', fileDto);
  }
  GetRecords(projectId: string): Observable<QCFlowRecord[]> {
    return this.http.get<any>(this.baseUrl + projectId + '/records');
  }
  SaveRecords(projectId: string, records: QCFlowBulkAction[]): Observable<any> {
    return this.http.put(this.baseUrl + projectId + '/records', records);
  }
  
  ClearData(projectId: string): Observable<any> {
    return this.http.delete(this.baseUrl + projectId);
  }
  DeleteFile(projectId: string, id: string): Observable<any> {
    return this.http.delete(this.baseUrl + projectId + '/' + id);
  }
  CancelLastFile(projectId: string): Observable<any> {
    return this.http.delete(this.baseUrl + projectId + '/lastfile');
  }
  GetFile(projectId, fileId = ''): Observable<any> {
    return this.http.get(this.baseUrl + projectId + '/export?fileId='+fileId, {responseType: 'text'});
  }
  UploadFile(projectId: string, awsfileName: string,url:string): Observable<any> {
    return this.http.post(this.baseUrl + projectId + '/file/' + awsfileName + '/' + encodeURIComponent(url), null);
  }
  RetryQCFlow(projectId: string): Observable<any> {
    return this.http.put(this.baseUrl + projectId + '/retry', {});
  }
  ReconcileIds(projectId: string): Observable<any> {
    return this.http.put(this.baseUrl + projectId + '/reconcile', {});
  }
}

