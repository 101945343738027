<div class="card">
  <div class="card-header d-flex align-items-center">
    <span class="nav-secondary nav-font16 font-weight-bold mr-auto mb-0"><i class="fak fa-settings fa-lg mr-2 grey4 font-weight-normal"></i>Team</span>
  </div>
  <div class="card-body">
    <div class="d-flex align-items-center">

    </div>
  </div>
</div>
