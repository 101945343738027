<div class="card border-0">
  <div class="card-header border-bottom" *ngIf="widget">
    <app-widget-title [widget]="widget" (remove)="remove()" (update)="updateTitle($event)"
      (openConfig)="configWidget()"></app-widget-title>
  </div>
  <div class="card-body">
     <div class="row">
      <div class="col-6">
        <span class="nav-secondary font-weight-bold mb-0 d-inline">My Task List</span>
      </div>
      <div class="col-6 text-right nav-font14">
        <span class="grey4 font-weight-bold mr-2">{{ completeToday }}/{{ totalToday }}</span>
        <span class="grey3"> tasks complete</span>
      </div>
    </div>
    <div class="col tabs mt-2 mb-4 nav-font14 px-0 border-bottom">
      <div class="btn text-left nav-font14 px-0" [class.active]="currentListType === 'current'" (click)="LoadList('current')">Today</div>
      <div class="btn text-left nav-font14 ml-4 px-0" [class.active]="currentListType === 'upcoming'" (click)="LoadList('upcoming')">Upcoming</div>
      <div class="btn text-left nav-font14 ml-4 px-0" [class.active]="currentListType === 'complete'" (click)="LoadList('complete')">Complete</div>
    </div>
    <ng-container *ngIf="tasks !=null && tasks.length == 0">
      <ng-container *ngIf="currentListType === 'current'">
        <div class="col text-center">
          <img class="img-empty-comments my-3" src="/assets/imgs/nav-home-tasks-today.png" />
          <p class="grey3 nav-font16 mt-2">No tasks today</p>
        </div>
      </ng-container>
      <ng-container *ngIf="currentListType === 'upcoming'">
        <div class="col text-center">
          <img class="img-empty-comments my-3" src="/assets/imgs/nav-home-tasks-complete.png" />
          <p class="grey3 nav-font16 mt-2">No upcoming tasks</p>
        </div>
      </ng-container>
      <ng-container *ngIf="currentListType === 'complete'">
        <div class="col text-center">
          <p class="grey3 nav-font16 mt-2">No tasks complete today</p>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="tasks !=null && tasks.length > 0">
      <div class="scrollbar overflow-y overflow-x-hidden tasks" style="max-height: 300px;">
        <div *ngFor="let task of tasks" class="item my-2 p-2 mb-3" [style]="'border-left: 5px solid '+ task.stageColor+'; padding-left: 10px;'">
          <div class="row">
            <div class="col-9 ">
              <h5 class="grey5 font-weight-bold mb-1 text-nowrap">{{ task.taskName }}</h5>
              <a [routerLink]="['/projects', task.projectId]" class="grey4 nav-font12" container="body" placement="right" [tooltip]="task.projectName.length > 50 ? task.projectName : ''"><span class=" text-uppercase">{{ task.projectCode }} </span> {{shortenProjectName(task.projectName)}}</a>
            </div>
            <div class="col d-flex align-items-center">
              <div class="or-checkbox justify-content-end col">
                <span class="font-weight-normal nav-font14 text-nowrap mr-3 mt-2"  [ngStyle]="{'color': (task.dueDate < today ? '#D02325' : 'grey2') }">{{ task.dueDate | date:"d MMM" }}</span>
                <span tooltip="Mark as complete" container="body" *ngIf="currentListType != 'complete'">
                  <input type="checkbox" [id]="'task_'+task.id" (change)="CompleteTask(task.id)">
                  <label [for]="'task_'+task.id"></label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    </div>
</div>
