import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService, AlertifyService, AuthService, User } from 'core';

@Component({
  selector: 'or-onlinehelp',
  templateUrl: './onlinehelp.component.html',
  styleUrls: ['./onlinehelp.component.scss'],
  providers: [MessageService]
})

export class OnlineHelpComponent implements OnInit {

  samlData:any = {};
  constructor(public auth: AuthService, public alertify: AlertifyService, private route: ActivatedRoute) { }

  ngOnInit() {
    let page = this.route.snapshot.queryParams['page'] ?? '';

    if(this.auth.isVendor()){
      //Vendor users only have access to vendor portal
      page = "en/kb/articles/what-is-the-vendor-portal"
    }
    this.auth.supportLogon(page).subscribe(next => {
       this.samlData = next;
       setTimeout(()=>{
        var form:any = document.getElementById('supportSSOForm');
        form.submit();
       }, 10)
    }, error => {
      this.alertify.error(error);
    }, () => {
    });
  }

}
