<div class="btn-group" dropdown #dropdownFilter="bs-dropdown" container="body" >
    <div id="button-basic" dropdownToggle controls="dropdown-basic" class="d-flex buttonContainer">
        <i class="fak fa-solid-filter nav-font16 grey4 text-center cursor-pointer"></i>
        <div *ngIf="filters.length > 0" class="filterNumber cursor-pointer">
            <span class="">
              {{filters.length}}
            </span>
        </div>
    </div>
    <div id="dropdown-filter" *dropdownMenu class="dropdownFilterMenu dropdown-menu dropdown-menu-right py-0 nav-font14" role="menu" aria-labelledby="button-basic" (click)="$event.stopPropagation()">
        <div class="p-2 nav-font14 grey4 font-weight-bold">
            <div class="header">
                <div class="font-weight-bold nav-secondary pl-2 mb-1 d-inline">Filter by</div>
                <div class="float-right mx-1" (click)="dropdownFilter.toggle(true)"><i class="far fa-times nav-font18 grey4"></i></div>
            </div>
            <div class="font-weight-normal nav-font12 nav-accent-blue px-2 py-1 mb-1"><span class="cursor-pointer" (click)="resetFilters()">Reset Filters</span></div>
            <ng-container *ngFor="let option of options">
                <div class=" font-weight-normal w-100 px-2 py-1">
                    <div class="or-checkbox d-inline">
                        <input type="checkbox" [name]="option.value" [id]="option.value" [(ngModel)]="option.selected" (change)="applyFilter()">
                        <label [for]="option.value"></label>
                    </div>
                    <div class="d-inline margin-left-26">
                        <div *ngIf="option.icon" class="icon-container d-inline-block">
                            <i class="{{option.icon}}"></i>
                        </div>
                        <span>{{option.label}}</span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
