<div class="nav-content">
  <div class="container-fluid">
    <div class="d-flex mb-3 mt-1 border-bottom">
      <div class="text-truncate or-secondary h4 m-2">Notifications</div>
      <div class="vl ml-2"></div>
      <ng-container *ngIf="auth.isInternal()">
        <div class="sectionbtn nav-item mt-1">
          <a class="nav-link or-primary cursor-pointer" [class.font-weight-bold]="tab == 'settings'" (click)="changeTabs('settings')">Settings </a>
        </div>
        <div class="sectionbtn nav-item mt-1">
          <a class="nav-link or-primary cursor-pointer" [class.font-weight-bold]="tab == 'alerts'" (click)="changeTabs('alerts')">Alerts </a>
        </div>
      </ng-container>
    </div>
    <div class="alert-settings mt-3" *ngIf="tab == 'settings'">
      <div class="card shadow-sm">
        <div class="m-3">
          <div class="table-responsive" *ngIf="loading == false">
            <or-list [items]="tableData | filter : filterText : filterColumns" [layout]="columns" [actions]="actions" [enableExport]="false" (action)="handleAction($event)"></or-list>
          </div>
        </div>
      </div>
      <div>
        <div class="card shadow-sm contact-box">
          <div class="card-header">
            <div class="h6 or-title"><i class="fa fa-plus or-title"></i> Add Notification</div>
          </div>
          <div class="mb-3 px-2">
            <form [formGroup]="settingsForm" (ngSubmit)="submit()" novalidate>
              <div class="form-group">
                <label for="event" class="required">Event</label>
                <select class="custom-select d-block w-100" formControlName="event" id="event">
                  <option value="">-Select-</option>
                  <ng-container  *ngIf="auth.isInternal()">
                    <optgroup label="Project Status">
                      <option value="ProjectCreated">{{ eventLabels['ProjectCreated'] }}</option>
                      <option value="ProjectStatusPaused">{{ eventLabels['ProjectStatusPaused'] }}</option>
                      <option value="ProjectStatusLive">{{ eventLabels['ProjectStatusLive'] }}</option>
                      <option value="ProjectStatusInvoiced">{{ eventLabels['ProjectStatusInvoiced'] }}</option>
                      <option value="ProjectStatusClosed">{{ eventLabels['ProjectStatusClosed'] }}</option>
                    </optgroup>
                    <optgroup label="Performance Alerts">
                      <option value="ProjectAllocation">{{ eventLabels['ProjectAllocation'] }}</option>
                      <option value="ProjectOverQuota">{{ eventLabels['ProjectOverQuota'] }}</option>
                      <option value="ProjectDOR">{{ eventLabels['ProjectDOR'] }}</option>
                      <option value="OverQuotaGroup">{{ eventLabels['OverQuotaGroup'] }}</option>
                    </optgroup>
                    <optgroup label="Task Alerts">
                      <option value="TasksAssignedToMe">{{ eventLabels['TasksAssignedToMe'] }}</option>
                      <option value="OverdueTasksAssignedToMe">{{ eventLabels['OverdueTasksAssignedToMe'] }}</option>
                    </optgroup>
                    <optgroup label="Other Alerts">
                      <option value="NoteAdded">{{ eventLabels['NoteAdded'] }}</option>
                      <option value="SecondaryPmChange">Secondary PM Change</option>
                    </optgroup>
                  </ng-container>
                  <ng-container  *ngIf="auth.isVendor()">
                    <optgroup label="Project Status">
                      <option value="VendorStatus">{{ eventLabels['VendorStatus'] }}</option>
                      <option value="VendorProjectStop">{{ eventLabels['VendorProjectStop'] }}</option>
                      <option value="VendorCostConfirmed">{{ eventLabels['VendorCostConfirmed'] }}</option>
                      <option value="VendorRecRemoveComplete">{{ eventLabels['VendorRecRemoveComplete'] }}</option>
                      <option value="VendorReconciledID">{{ eventLabels['VendorReconciledID'] }}</option>
                    </optgroup>
                    <optgroup label="Quotas">
                      <option value="VendorOverQuota">{{ eventLabels['VendorOverQuota'] }}</option>
                      <option value="VendorOverQuotaGroup">{{ eventLabels['VendorOverQuotaGroup'] }}</option>
                    </optgroup>
                  </ng-container>
                </select>
              </div>
              <div class="form-group">
                <label for="project" class="required">Project</label>
                <select class="custom-select d-block w-100" formControlName="project" id="project">
                  <option value="">-Select-</option>
                  <option value="All">All Projects</option>
                  <option value="MyProjects">My Projects</option>
                  <option *ngIf="auth.isInternal()" value="Favorite">Favorite Projects</option>
                </select>
              </div>
              <div class="form-group">
                <label for="contactEmail" class="required">Action</label>
                <select class="custom-select d-block w-100" formControlName="action" id="action">
                  <option value="">-Select-</option>
                  <option value="EmailMe">E-mail Me</option>
                  <option value="Slack" *ngIf="this.userModel.social.slackUsername !== null">Slack</option>
                </select>
              </div>
              <div>
                <div class="text-right mb-3 px-2">
                  <button type="button" (click)="reset()" class="mt-2 btn btn-sm btn-outline-secondary mx-1">Cancel</button>
                  <button type="submit" class="mt-2 btn btn-sm btn-secondary" [disabled]="!settingsForm.valid">Save</button>
                </div>
              </div>
            </form>
          </div>
          <div class="card-footer py-2 or-primary position-absolute">
            <div class="row">
              <div class="col">
                <h5 class="d-inline"></h5>
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow-sm contact-box mt-3" *ngIf="notificationUsers.length > 0 && auth.isInternal()">
          <div class="card-header">
            <div class="h6 or-title"><i class="fas fa-file-import or-title"></i> Import Notification</div>
          </div>
          <div class="mb-3 px-2">
            <div class="form-group">
              <label for="event">Copy Notifications from this User:</label>
              <select class="custom-select d-block w-100" [(ngModel)]="copyUser">
                <option value="">-Select-</option>
                <ng-container *ngFor="let user of notificationUsers">
                  <option [value]="user.value">{{user.name}}</option>
                </ng-container>
              </select>
            </div>
            <div>
              <div class="text-right mb-3 px-2">
                <button type="submit" class="mt-2 btn btn-sm btn-secondary" (click)="copyNotifications()">Copy</button>
              </div>
            </div>
          </div>
          <div class="card-footer py-2 or-primary position-absolute">
            <div class="row">
              <div class="col">
                <h5 class="d-inline"></h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3" *ngIf="tab == 'alerts'">
      <div class="card shadow-sm">
        <div class="m-3">
          <div class="loading" *ngIf="loading"></div>
          <div class="table-responsive" *ngIf="loading == false">
            <or-list [items]="tableAlerts | filter : filterText : filterColumnsAlert" rowid="projectId" [clickroute]="clickroute" [layout]="columnsAlert"></or-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #modalConfirm>
  <div class="modal-header">
    <p class="h5">Confirm Delete</p>
  </div>
  <div class="modal-body text-center">
    <p class="h6">Are you sure you want to delete this setting?</p>
    <button type="button" class="btn btn-outline-secondary m-2" (click)="confirmDelete()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="openModalRef.hide()">No</button>
  </div>
</ng-template>
