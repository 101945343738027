import { Injectable } from '@angular/core';

declare var pendo: any;

@Injectable({
  providedIn: 'root'
})
export class PendoService {

pendoInit = false;

  constructor() {
  }
  init (id, email, name, accountId, accountName, roles, sectionNames) {
        if(this.pendoInit) return;

        this.pendoInit = true;
        
        pendo.initialize({
            visitor: {
                id: id,
                email: email,
                full_name:   name,
                // You can add any additional visitor level key-values here,
                roles: roles,
                sectionNames: sectionNames
            },
            account: {
                id: accountId,
                name: accountName
                // You can add any additional account level key-values here,
                // as long as it's not one of the above reserved names.
            }
        });
    }
    track(trackType){
        pendo.track(trackType);
    }
}