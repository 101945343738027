<div class="card surveycard" [class.group]="isGroup">
  <div class="card-header pb-0 pt-3">
    <div class="row">
      <div class="col-5 font-weight-light">
        <div class="nav-font16 d-inline nav-secondary font-weight-bold">
          <a [routerLink]="['/projects/summary', summary?.bidNumber]" class="font-weight-bold nav-secondary">{{ summary.prefix }}</a>
        </div>
      </div>
      <div class="col d-flex">
        <div class="ml-auto text-right">
          <span class="nav-font14 nav-accent-blue cursor-pointer" [routerLink]="['/projects/summary', summary?.bidNumber]">View Summary</span>
        </div>
      </div>
    </div>
  </div>

  <div class="card-body py-0">
    <div class="d-flex mt-2">
      <div class="d-block mr-2">
        <span class="nav-font14 grey4">{{ summary.groupName }}</span>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-11">
        <div class="d-block text-truncate">
          <span class="h6 text-truncate cursor-pointer grey5 dotted-underline" [routerLink]="['/clients', summary.clientId]" tooltip="{{ summary.clientName }}">{{ summary.clientName }}</span>
        </div>
      </div>
    </div>
    <div class="row py-0 mt-3 invisible">
      <div class="col-11">
        <div class="d-block">
          <span class="nav-font14 cursor-pointer grey3">Task overview</span>
        </div>
      </div>
    </div>
  </div>

  <div class="card-body pb-0">
    <div class="row py-0 mt-3 invisible">
      <div class="col-3">
        <span class="nav-font14 cursor-pointer grey4 d-block">2/3 complete</span>
      </div>
      <div class="col">
        <div class="rule">
          <div class="progress">
            <div class="progress-bar" role="progressbar" [style.width.%]="summary.percentageTasksComplete"
                  aria-valuenow="" aria-valuemin="0" aria-valuemax="100">
            </div>
          </div>
        </div>
      </div>
      <div class="col-3 p-0 m-0">
        <small class="d-block nav-font14 grey4 text-center nav-error">1 Overdue</small>
      </div>
    </div>
    <div class="row mt-4 pt-2 pb-2 px-3 border-top footer">
      <div class="col-4 px-0">
        <span class="nav-font14 mb-1 grey3">Projects in trouble</span>
        <div class="grey4">
          <span class="mr-2">{{summary.projectsInTrouble}}/{{summary.totalProjects}}</span>
        </div>
      </div>
      <div class="col d-flex justify-content-end px-0 pr-2">
        <div class="mt-2">
          <or-profile-image [names]="summary.users" showInitials="true"></or-profile-image>
        </div>
      </div>
    </div>
  </div>

</div>

<div *ngIf="isGroup" class="group-background first-layer">
  <div class="group-background second-layer"></div>
</div>




