import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService, AlertifyService, ProjectService, DropdownService, ProjectDataService, UtilsService, ClientService, PartnerService, UserService, AlertMessageService } from 'core';
import { NavigationEnd, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FilterPipe } from 'projects/core/src/helpers/filter.pipe';
import { Observable, Observer, filter, forkJoin, map, of, tap } from 'rxjs';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  providers: [FilterPipe]
})
export class NavBarComponent implements OnInit {

  _project: any = {};
  _projectFamily: any = {};
  _newProject: any = {};
  creatingProject = false;
  initialUsers: any[] = [];
  breadcrumb: any = { icon: 'fak fa-home', label: 'Home', url: '', childs: [] };
  mode = 'new';
  isCollapsed = true;
  isSuperAdmin = false;
  isAdmin = false;
  isInternal = false;
  isClient = false;
  isGuest = false;
  isVendor = false;
  isProjectEnable = false;
  projectTypes = [];
  relatedProjects: any = [];
  selectedProject: any = {};
  hasChanges = false;
  loaded = false;
  clients;
  clientContacts = [];
  clientLoading = false;
  accountOwner;
  projectSideBarOpen = false;
  sidebarOpen = false;
  projectType = null;
  step = 0;
  createType = 'quick';
  hasQuotasSetup = false;
  hasSchedule = false;
  cloneProjectName = "";
  cloneProjectBidNumber = null
  cloneProjectClient = null
  allCopyOptionsSelected = false;
  loading = true;
  matches = [];
  projectManager;
  selectedMatch = null;
  integerPattern = { 9: { pattern: new RegExp('^[0-9]*$'), optional: true } };
  isORInstance = false;
  asyncSearch: string = '';
  dataSource: Observable<any>;
  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;
  addingFromFamily = false;
  isVendorview: boolean = false;
  isClientview: boolean = false;
  allowMobileConfirm: boolean = false;

  @Output() valueChange = new EventEmitter();

  @ViewChild('modal') modalRef: any;
  @ViewChild('modalSuccess') modalRefConfirm: any;

  openModalRef: BsModalRef;

  @Output() onSave = new EventEmitter();

  constructor(
    public auth: AuthService,
    private alertify: AlertifyService,
    private projectService: ProjectService,
    public router: Router,
    private userService: UserService,
    private partnerService: PartnerService,
    private dropdownService: DropdownService,
    private utils: UtilsService,
    private clientService: ClientService,
    private projectDataService: ProjectDataService,
    private alertService: AlertMessageService,
    private modalService: BsModalService) { }

  ngOnInit() {

    this.alertService.connectWebSocket();

    this.loading= true;
    this.isSuperAdmin = this.auth.isSuperAdmin();
    this.isAdmin = this.auth.isAdmin();
    this.isInternal = this.auth.isInternal();
    this.isClient = this.auth.isClient();
    this.isGuest = this.auth.isGuest();
    this.isVendor = this.auth.isVendor();
    this.isORInstance = this.auth.isORInstance();
    this.initialUsers = [];

    let user = this.auth.getUser()
    this.initialUsers.push(user);
    this._newProject.userId = user.id;

    // handle breadcrumb
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) => event as NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        this.updateBreadcrumb(event.urlAfterRedirects);
      });

    //subscribe to any open project
    this.projectDataService.getProject.subscribe(data => {
      this._project = data?.project;
      this.projectSideBarOpen = data?.sidebarSectionOpen || false;

      if (this._project?.id) {
        const [shortCode,] = this._project?.projectCode?.split('-');
        this._project.shortCode = shortCode;
        this.getRelatedByBidNumber();
      }
    });

    //subscribe to any open family
    this.projectDataService.getFamily.subscribe(data => {
      if(data!==null){
      if (data?.familyCode !== null) {
        this.projectDataService.setPageName('ProjectPerformance');
      }else{
        this.projectDataService.setPageName('NotSet');
      }
    }

      if (data?.familyCode) {
        this._projectFamily = data;
      }
      else this._projectFamily = { familyCode: null }
    });

    //subscribe to sidebar mode
    this.projectDataService.getSidebarMode.subscribe(data => {
      data = data || 'reduced';
      this.sidebarOpen = (data === 'reduced' ? false : true);
    });

    //subscribe to any action within navbar (from anywhere)
    this.projectDataService.getActionNavBar.subscribe(data => {
      if (!data) return;

      const type = data?.type;

      // clone project from project list
      if (type == 'clone') {
        this.createProject('inlineClone', data?.project)
      }
      else if (type == 'addToFamily') {
        if (data?.familyConfig) {
          this.addProjectToFamily(data.familyConfig.familyCode, data.familyConfig.bidNumber)
        }
      }
    });

    //typeahead to copy project
    this.dataSource = new Observable((observer: Observer<any>) => {
      return this.copySearch(this.asyncSearch).subscribe((items: any) => {
        observer.next(items);
      });
    });
    this.loading = false;
  }


  ngAfterViewInit() {
    setTimeout(() => {
      this.projectDataService.getVendorViewSidebar.subscribe(data => {
        if (data) {
          this.isVendorview = data != '';
        }
      });

      this.projectDataService.getClientViewSidebar.subscribe(data => {
        if (data) {
          this.isClientview = data != '';
        }
      });



    }, 500 * 1);
  }


  updateBreadcrumb(url) {
    let sections = localStorage.getItem('sections');
    let sectionsLinks = localStorage.getItem('sectionsLinks');

    if (sections !== null) {
      let siteSections = JSON.parse(sections);
      let siteSectionsLinks = JSON.parse(sectionsLinks);

      let arr = url.split('/');
      let path = '/' + arr[1].toLowerCase();

      const id = (path === '/clients' || path === '/vendors' || path === '/account') ? arr[2] : '';

      if (!id && arr.length === 3 && path !== '/projects') {
        path = url;
      }

      let currentPage = siteSectionsLinks.find(x => x.url === path)?.type;


      if (!currentPage) {
        currentPage = siteSectionsLinks.find(x => x.url === url)?.type;
      }
      if (!currentPage) {
        currentPage = siteSectionsLinks.find(x => x.url?.includes(path))?.type;
      }

      if (!currentPage) {
        const arrReturnUrl = decodeURIComponent(path).replace('?', '').split('=');
        if (arrReturnUrl.length > 0 && arrReturnUrl[1] !== null) {
          path = arrReturnUrl[1];
          currentPage = siteSectionsLinks.find(x => x.url?.includes(path))?.type;
        }
      }

      if (!id && path !== '/account') {
        const currentSection = !currentPage ? siteSections[0] : siteSections.find(x => x.type === currentPage);

        if (currentSection)
          this.setBreadcumb(currentSection);

      } else {

        if (path === '/clients') {
          this.clientService.GetClient(id).subscribe(data => {
            const currentSection = !currentPage ? siteSections[0] : siteSections.find(x => x.type === currentPage);
            if (currentSection) {
              currentSection.childs = [];
              currentSection.childs.push({ label: data.name });
              this.setBreadcumb(currentSection);
            }
          });
        } else if (path === '/vendors') {
          this.partnerService.GetPartner(id).subscribe(data => {
            const currentSection = !currentPage ? siteSections[0] : siteSections.find(x => x.type === currentPage);
            if (currentSection) {
              currentSection.childs = [];
              currentSection.childs.push({ label: data.name });
              this.setBreadcumb(currentSection);
            }
          });
        } else if (path === '/account') {
            const currentSection = !currentPage ? siteSections[0] : siteSections.find(x => x.type === 'Home');
            if (currentSection) {
              currentSection.childs = [];

              if (id) {
                currentSection.childs.push({ label: (id === 'support' ? 'Support Access' : 'User Settings') });
                this.setBreadcumb(currentSection);
              } else {
                currentSection.childs.push({ label: 'User Settings' });
                this.setBreadcumb(currentSection);
              }

            }
        }
      }

      this.isProjectEnable = this.auth.getUser().access?.projectEnable;
      if (siteSections)
        this.isProjectEnable = (this.isProjectEnable && siteSections?.some(x => x.type === 'ProjectList'));
    }
  }

  setBreadcumb(currentSection) {
    this.breadcrumb.icon = currentSection.icon || 'fak fa-projects';
    this.breadcrumb.label = currentSection.label || 'Projects';
    this.breadcrumb.url = currentSection.url || '/';
    this.breadcrumb.childs = currentSection.childs || [];
  }

  getMatches(preSelected = false) {
    this.copySearch(this.asyncSearch).subscribe((items: any) => {
      this.matches = items
      if (!preSelected) this.selectedMatch = null
    });
  }

  formChanged(event) { if (this.loaded) this.hasChanges = true; }

  globalSearch = (filter) => {
    return this.projectService.GlobalSearch(filter)
      .pipe(
        tap(results => {
          const uniqueProjectCodes = [];
          const handledCodes = {};
          // Add existing codes to the set if unqiue
          results.filter(e => e.bidNumber != '').forEach(e => {
            let summary = { ...e };
            if (handledCodes[summary.bidNumber] != true) {
              var projects = results.filter(e => e.bidNumber == summary.bidNumber);
              var name = this.utils.longestCommonSubstring(projects?.map(p => p?.projectName), null);

              handledCodes[summary.bidNumber] = true;
              summary.projectName = 'Summary: ' + name;
              summary.projectCode = summary.bidNumber;
              summary.type = "summary";
              uniqueProjectCodes.push(summary);
            }
          }
          );

          // Filter and add new codes to the set
          Array.prototype.unshift.apply(results, uniqueProjectCodes);

        }),
        map(results => results)
      );
  }

  selectedSearch(value) {
    if (value.type == 'summary') {
      this.openProjectFamily(value?.bidNumber);
    }
    else {
      const projectId = value?.id;
      const navigateUrl = '/projects/' + projectId;
      this.router.navigate([navigateUrl]);
    }
  }

  selectMatch(match, event = null) {
    if (event != null) {
      this.selectedMatch = match.id;
      const projectToClone = match;
      this._newProject.cloneProjectid = projectToClone.id;
      this.hasQuotasSetup = projectToClone.hasQuotasSetup;
      this.hasSchedule = projectToClone.totalStages > 0;
      this.cloneProjectName = projectToClone.projectName + ' - Copy'
      const client = this.clients.filter(x => x.name == projectToClone.clientName);
      this.cloneProjectClient = (client != null && client.length > 0) ? [{ name: projectToClone.clientName, value: client[0].value }] : null;
    }
  }

  copySearch = (filter) => { return this.projectService.CopySearch(filter); }

  // check if all clone options are selected
  checkIfAllSelected(checked) {
    if (this.allCopyOptionsSelected && !checked) this.allCopyOptionsSelected = false
    else if (checked &&
      (!this.hasSchedule || this._newProject.copySchedule) &&
      (!this.hasQuotasSetup || this._newProject.copyQuotas) &&
      this._newProject.copySurveyLinks &&
      this._newProject.copyVendors &&
      this._newProject.copyTeam &&
      this._newProject.copyDedupeProjects &&
      this._newProject.includeSourceProjectInDedupe
    ) {
      this.allCopyOptionsSelected = true
    }
  }

  // select all clone options
  selectAllCopyOptions(checked) {
    if (this.hasSchedule) this._newProject.copySchedule = checked;
    if (this.hasQuotasSetup) this._newProject.copyQuotas = checked;
    this._newProject.copySurveyLinks = checked;
    this._newProject.copyVendors = checked;
    this._newProject.copyTeam = checked;
    this._newProject.copyDedupeProjects = checked;
    this._newProject.includeSourceProjectInDedupe = checked;
  }

  onTypeaheadNoResults(event: boolean): void {
    this.typeaheadNoResults = event;
  }

  onTypeaheadLoading(event: boolean): void {
    this.typeaheadLoading = event;
  }

  createProject(type = "", sourceProject = null) {
    if (this.step != 0) return;

    this.step = 1;
    this.getMatches()
    forkJoin([
      this.projectService.GetProjectTypes(null, false),
      this.dropdownService.getClients()
    ]).subscribe((responseList) => {
      this.projectTypes = responseList[0].sort((a, b) => a.name.localeCompare(b.name));
      this.clients = responseList[1];

      if (type == 'inlineClone' && sourceProject != null) {
        this.cloneStepTwo(sourceProject)
      }

      if (this.projectTypes.length === 1 || type == 'inlineClone') {
        this.projectType = this.projectTypes[0];
      };

      this.showCreateProjectDialog();

    }, error => {
      this.step = 0;
      this.alertify.error('There was a problem getting project details.');
    });
  }

  openProjectFamily(bidNumber) {
    const navigateUrl = '/projects/summary/' + bidNumber;
    this.router.navigate([navigateUrl]);
  }

  cloneStepTwo(sourceProject) {
    this.step = 2;
    this.createType = 'clone';
    this.asyncSearch = sourceProject?.projectCode;
    this.selectedMatch = sourceProject?.id;

    this.getMatches(true);

    this._newProject.projectTypeId = sourceProject.projectTypeId;
    this._newProject.projectType = sourceProject.projectType;
    this._newProject.cloneProjectid = sourceProject.id;
    this.hasQuotasSetup = sourceProject.hasQuotasSetup;
    this.hasSchedule = (sourceProject.totalStages > 0 || sourceProject.currentStage != '');
    this.cloneProjectName = sourceProject.projectName + " - Copy";
    const client = this.clients.filter(x => x.name == sourceProject.clientName);
    this.cloneProjectClient = (client != null && client.length > 0) ? [{ name: sourceProject.clientName, value: client[0].value }] : null;

  }

  openRelated(project) {
    this.selectedProject = {
      id: project.id,
      projectName: project.projectName,
      projectCode: project.projectCode,
      code: project.code,
      order: project.order,
      projectStatus: project.projectStatus,
      projectStartDate: project.projectStartDate
    };
    const navigateUrl = '/projects/' + project?.id;
    this.router.navigate([navigateUrl]);
  }

  // add project to family from bredcrumb
  addProjectToFamily(projectShortCode, projectBid) {
    this.addingFromFamily = true;
    this.createType = 'clone'
    this.asyncSearch = projectShortCode;
    this.cloneProjectBidNumber = projectBid;
    this.getMatches();
    this.createProject();
  }

  getRelatedByBidNumber() {
    this.projectService.GetRelatedByBidNumber(this._project.id, this._project.bidNumber).subscribe(data => {

      let familyName = '';

      if (data) {
        let array = [...data];
        array?.push(this._project);
        familyName = this.utils.longestCommonSubstring(array?.map(p => p?.projectName), 8);
      }

      this.relatedProjects = data?.map((item, index) => {
        const [, code] = item.projectCode?.split('-');
        item.code = code;
        item.order = index + 2;
        item.projectNameDisplay = this.shortenedProjectName(item.projectName);
        return item;
      });

      const [, code] = this._project?.projectCode?.split('-');

      this.selectedProject = {
        id: this._project.id,
        projectName: this._project.projectName,
        projectNameDisplay: this.shortenedProjectName(this._project.projectName),
        projectCode: this._project.projectCode,
        code,
        order: 1,
        projectStatus: this._project.projectStatus,
        projectStartDate: this._project.projectStartDate
      };

    }, error => {
      this.alertify.error('There was a problem getting related projects by bidNumber.');
    }, () => {
      this.loading = false;
    })
  }
  shortenedProjectName(name) {
    return name.length > 50 ? `${name.slice(0, 20)}...${name.slice(-20)}` : name;
  }

  selectProjectType(type, createType) {
    this.projectType = null;
    this.createType = createType;

    this.projectTypes.map(item => {
      item.selected = false;
    });

    if (type !== null) {
      type.selected = true;
      this.projectType = type;
    }
    if (createType == 'clone') {
      this.asyncSearch = this._project.projectName;
      this.getMatches();
    }
  }

  getClients = () => {
    return of(this.clients);
  }

  assignClient(evt) {
    if (evt.length > 0) {
      this._newProject.clientId = evt[0].value;
    } else
      this._newProject.clientId = null;
  }

  showCreateProjectDialog() {
    this._newProject.userId = this.auth.getUser().id;
    this.openModalRef = this.modalService.show(this.modalRef as any, { ignoreBackdropClick: true, keyboard: false, class: 'project-setup-step1 nav-modal-style' });
  }

  closeModal() {
    this.openModalRef.hide();
    this.openModalRef = null;
    this.createType = 'quick';
    this.asyncSearch = '';
    this.projectType = null;
    this.cloneProjectBidNumber = null;
    this.cloneProjectClient = null;
    this.cloneProjectName = null;
    this.allCopyOptionsSelected = false;
    this.selectAllCopyOptions(false)
    this.getMatches();
    this._newProject = {};
    this.step = 0;
  }

  createProjectNext() {

    if (this.createType == 'quick') {
      if (!this._newProject.projectName) {
        this.alertify.error('Please fill in all required fields');
        return;
      }

      // validation for OR instance
      if (this.isORInstance) {

        if (this.step === 1 && !this._newProject.clientId) {
          this.alertify.error('Please fill in all required fields');
          return;
        } else if (
          this.step === 2 &&
          (
            !this._newProject.projectStartDate ||
            !this._newProject.projectEndDate ||
            !this._newProject.fullLaunchQuota || this._newProject.fullLaunchQuota <= 0 ||
            !this._newProject.projectLOI || this._newProject.projectLOI <= 0 ||
            !this._newProject.projectIR || this._newProject.projectIR <= 0
          )
        ) {
          this.alertify.error('Please fill in all required fields');
          return;
        }
      }
    }

    if (this.createType == 'quick')
      this._newProject.projectTypeId = this.projectType.id;

    if (this.step === 1) {
      this.step = 2;

      const modalClass = (this.createType == 'quick') ? 'project-setup-quick-step2' : 'project-setup-clone-step2';
      this.openModalRef?.setClass(`${modalClass} nav-modal-style`);
    }
    else
      this.createNewProject();
  }

  back() {
    this.step = 1;
    this.openModalRef?.setClass('project-setup-step1 nav-modal-style');
  }

  cloneProject() {
    this.allowMobileConfirm = false;
    if (this.step == 1) {

      if (!this._newProject.cloneProjectid) {
        this.alertify.error('Please search and select a existing project to copy');
        return;
      }
      this.step = 2;
    } else if (this.step == 2) {
      this.createNewProject();
    }
  }

  createNewProject() {
    if (this.creatingProject == true)
      return;

    this.creatingProject = true;

    if (this.createType == 'clone') {
      this._newProject.projectName = this.cloneProjectName;

      if (!this._newProject.clientId && this.cloneProjectClient != null) {
        this._newProject.clientId = this.cloneProjectClient[0].value
      }

      if (!this._newProject.cloneProjectBidNumber && this.addProjectToFamily) {
        this._newProject.bidNumber = this.cloneProjectBidNumber;
      }
    }
    else {
      this._newProject.cloneProjectid = null;
    }

    if (this._newProject.projectStartDate_dt != null) {
      this._newProject.projectStartDate_dt.setHours(12);
      this._newProject.projectStartDate = new Date(this._newProject.projectStartDate_dt.getUTCFullYear(), this._newProject.projectStartDate_dt.getUTCMonth(), this._newProject.projectStartDate_dt.getUTCDate(), 12).toISOString()
    }

    this.projectService.CreateProject(this._newProject).subscribe(data => {
      if (data) {
        this._newProject = data;
        this.router.navigateByUrl('/projects/' + data.id + '/schedule');
      } else {
        this.alertify.error('There was a problem saving this project.');
      }
    }, error => {
      this.creatingProject = false;
      this.alertify.error('There was a problem saving this project.');
    }, () => {
      this.creatingProject = false;
      this.openModalRef.hide();
      this.openModalRef = null;

      setTimeout(() => {
        this.openModalRef = this.modalService.show(this.modalRefConfirm, { class: 'nav-modal-style' });
      }, 100);

    });
  }
  updateAllowMobile(value)
  {
    //call service to update the project field allowMobile
    this.allowMobileConfirm = true;
    this.projectService.UpdateProjectValue(this._newProject.id, 'allowMobile', value).subscribe(data => {
    }, error => {
      this.alertify.error('There was a problem updating the allow mobile setting.');
    });

  }

  assignPM(pm) {
    this._newProject.userId = null;
    if (pm?.length > 0) {
      this._newProject.userId = pm[0]?.id;
    }
  }

  goToSettings() {
    this.router.navigateByUrl('/projects/' + this._newProject.id + '/settings');
    this.closeModal();
  }

  getUserList = (filter) => { return this.userService.GetUsers(); }

}



