import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'core';

@Component({
  selector: 'app-project-card-survey-summary',
  templateUrl: './project-card-survey-summary.component.html',
  styleUrls: ['./project-card-survey-summary.component.scss']
})
export class ProjectCardSurveySummaryComponent implements OnInit {

  @Input() summary: any;
  @Input() isGroup: false;

  constructor(public auth: AuthService) {}

  ngOnInit() {

  }
}
