import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertifyService, AuthService, RulesService, ProjectDataService, UserService, RuleHeader, ScheduleService, Rules, RuleAction } from 'core';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent implements OnInit {

  @Input() public set project(data) {
    this._project = data;
    this.getRules();
  }
  @Input() public set bidNumber(data) {
    if (data != null) {
      this._bidNumber = data;
      this.getRulesSummary();
    }
  };

  _project;
  _bidNumber = '';
  addMode = false;
  editMode = false;
  viewMode = 'pending';
  rules: any[] = null;
  isVendorview: boolean = false;
  fullList: any[] = [];
  users: any[];
  editRule: any = {};
  rollbackRule;
  emailTemplateSaved = '';
  filtersApplied = [];
  viewEmail = false;
  editEmail = false;
  userChangedEmail = false;
  projectUrl = window.location.origin + '/projects/';
  isClientview: boolean = false;
  filterValues: any = [];
  isClient: boolean = false;
  _tasks: any[] = [];

  editorConfig = {
    startupFocus: true,
    toolbar: {
      language: 'en',
      items: [
        'bold',
        'italic',
        'underline',
        'link',
        'bulletedList',
        'numberedList'
      ]
    }
  };

  @Output() callback = new EventEmitter();

  constructor(
    public auth: AuthService,
    private scheduleService: ScheduleService,
    private alertify: AlertifyService,
    private userService: UserService,
    private projectDataService: ProjectDataService,
    private ruleService: RulesService) { }

  ngOnInit(): void {
    if (this._bidNumber == '') this.getTasks();
    else if (this._bidNumber != '') this.getTasksSummary();

    this.projectDataService.getVendorViewSidebar.subscribe(data => {
      if (data) {
        this.isVendorview = data != '';
      }
    });
    this.projectDataService.getClientViewSidebar.subscribe(data => {
      if (data) {
        this.isClientview = data != '';
      }
    });
    if (this.auth.isClient()) {
      this.isClient = true;
    }
  }

  getTasks() {
    if (!this.auth.isVendor()) {
      this.scheduleService.GetProjectSchedule(this._project.id).subscribe(data => {
        this._tasks = [];
        for (let i = 0; i < data.length; i++) {
          let stage = data[i];
          let tasks = stage.projectTasks;
          for (let j = 0; j < tasks.length; j++) {
            let task = tasks[j];
            this._tasks.push({ id: task.id, name: task.taskName, stage: stage.stageName });
          }
        }
      }, error => {
        console.log(error);
      });
    }
  }
  getTasksSummary() {
    if (!this.auth.isVendor()) {
      this.scheduleService.GetFamilySchedule(this._bidNumber).subscribe(data => {
        this._tasks = [];
        for (let i = 0; i < data.length; i++) {
          let stage = data[i];
          let tasks = stage.projectTasks;
          if (tasks === undefined || tasks === null) continue;
          for (let j = 0; j < tasks.length; j++) {
            let task = tasks[j];
            this._tasks.push({ id: task.id, name: task.taskName, stage: stage.stageName });
          }
        }
      }, error => {
        console.log(error);
      });
    }
  }

  changeViewMode(mode) {
    this.viewMode = mode;
    if (!this._bidNumber)
      this.filterList();
    else
      this.filterListSummary();
  }

  applyFilter(filters) {
    this.filterList();
  }

  applyFilterSummary(filters) {
    this.filtersApplied = filters?.filter(x => x?.selected).map(x => x.value);
    this.filterListSummary();
  }

  getRules() {
    forkJoin([this.ruleService.GetRules(this._project.id), this.userService.GetUsers()]).subscribe((responseList) => {
      this.rules = responseList[0];
      this.users = responseList[1];
    }, error => {
      this.alertify.error('There was a problem getting Alerts.');
    }, () => {
      this.rules = this.rules.map(item => {
        item.isMine = item.createdBy === this.auth.getUser().id ? true : false;
        item.processed = item.ruleActions[0].processedTime !== null;
        item.triggered = item.ruleActions[0].triggered === true;
        item.name = item.ruleType == 'task' ? 'Task Alert' : `${item?.scopeType} ${item?.rules[0].metricType}`;
        const createdBy = this.users.filter(e => e.id === item.createdBy)
        const creationLabel = item?.isEdited ? 'edited' : 'created';
        const createdByName = (createdBy == null || createdBy.length == 0) ? '' : createdBy[0].firstName;
        item.lastUpdatedText = item.ruleType == 'scheduled' ? `Timed alert ${creationLabel} by ${createdByName} on` : (item.ruleType == 'live' ? `Live alert ${creationLabel} by ${createdByName} on` : `Task alert ${creationLabel} by ${createdByName} on`);
        return item;
      });
      this.fullList = this.rules;
      this.filterList();
    });
  }
  getRulesSummary() {
    forkJoin([

      this.ruleService.GetRulesSummary(this._bidNumber),
      this.userService.GetUsers()]).subscribe((responseList) => {
        this.rules = responseList[0];
        this.users = responseList[1];
      }, error => {
        this.alertify.error('There was a problem getting Alerts.');
      }, () => {
        this.filterValues = [];
        this.rules = this.rules.map(ruleGroup => {
          ruleGroup = ruleGroup.map(item => {
            item.isMine = item.createdBy === this.auth.getUser().id ? true : false;
            item.processed = item.ruleActions[0]?.processedTime !== null;
            item.triggered = item.ruleActions[0]?.triggered === true;
            item.name = item.ruleType == 'task' ? 'Task Alert' : `${item?.scopeType} ${item?.rules[0].metricType}`;
            const createdBy = this.users.filter(e => e.id === item.createdBy)
            const createdByName = (createdBy == null || createdBy.length == 0) ? '' : createdBy[0]?.firstName;
            const creationLabel = item?.isEdited ? 'edited' : 'created';
            item.lastUpdatedText = item.ruleType == 'scheduled' ? `Timed alert ${creationLabel} by ${createdByName} on` : (item.ruleType == 'scheduled' ? `Live alert ${creationLabel} by ${createdByName} on` : `Task alert ${creationLabel} by ${createdByName} on`);
            return item;
          });
          this.filterValues.push({ label: ruleGroup[0].projectCode, value: ruleGroup[0].projectId, group: 'Project Number', selected: true });
          this.filtersApplied.push(ruleGroup[0].projectId);
          return ruleGroup;
        });
        this.fullList = this.rules;
        this.filterListSummary();
      });
  }

  filterList() {
    let rules = [...this.fullList];

    if (this.viewMode === 'pending') {
      rules = rules.filter(x => !x.isExpired);
    } else if (this.viewMode === 'closed') {
      rules = rules.filter(x => x.isExpired);
    }

    this.rules = rules;
  }

  filterListSummary() {
    let ruleGroup = [...this.fullList];
    if (this.viewMode === 'pending') {
      ruleGroup = ruleGroup.filter(item => {
        return item.some(rule => !rule.isExpired && this.filtersApplied.includes(rule.projectId));
      });
    } else if (this.viewMode === 'closed') {
      ruleGroup = ruleGroup.filter(item => {
        return item.some(rule => rule.isExpired && this.filtersApplied.includes(rule.projectId));
      });
    }
    this.rules = ruleGroup;
  }

  addRule(type) {
    this.editRule = {} as RuleHeader;
    this.editRule.id = '';
    this.editRule.name = 'Alert ' + (this.fullList.length + 1).toString();
    this.editRule.projectId = this._project.id;
    this.editRule.createdByName = this.auth.getUser().name;
    this.editRule.sortKey = 0;
    this.editRule.scopeType = 'Project';
    this.editRule.ruleType = type;
    this.editRule.scopeId = this._project.id;
    this.editRule.rules = [];
    this.editRule.ruleActions = [];

    let rule: Rules = {};
    rule.sortKey = 0;
    rule.operator = '<';

    // Live Alert
    if (type == 'live') {
      rule.metricType = 'Completes';
      rule.operator = '=';
    }

    this.editRule.rules.push(rule);

    let ruleAction: RuleAction = {};
    ruleAction.sortKey = 0;
    ruleAction.action = 'Alert';
    ruleAction.alertMethod = 'Email';
    ruleAction.alertRecipientType = 'Someone';
    this.editRule.ruleActions.push(ruleAction);

    this.editRule.editing = true;
    this.addMode = true;
    this.editMode = false;
    this.callback.emit({ editing: true });
  }

  confirm() {
    if (!this.editRule) return;
    if (!this.editRule.scopeType || this.editRule.scopeType == undefined) {
      this.alertify.error('Please select a Scope for the Alert');
      return;
    }
    if (this.editRule.ruleType === 'task') {

    } else { // Original path before introduction of Task scope
      if (!this.editRule.rules[0].metricType || this.editRule.rules[0].metricType == undefined) {
        this.alertify.error('Please select a Metric for the Alert');
        return;
      }
      if (this.editRule.rules[0].value == undefined) this.editRule.rules[0].value = 0;
      if (this.editRule.rules[0].value == null) {
        this.alertify.error('Please provide a Metric Number for the Alert');
        return;
      } else {
        if (this.editRule.rules[0].value > 100) {
          let OK = true;
          switch (this.editRule.rules[0].metricType) {
            case 'IR':
            case 'DOR':
            case 'CID Blocks':
            case 'QCs':
            case 'Dupes':
              this.alertify.error('Please provide a Metric Percentage between 1 and 100!');
              OK = false;
              break;
          }
          if (!OK) return;
        }
      }
    }
    if (this.editRule.ruleType == 'scheduled' && (!this.editRule.ruleActions[0].scheduledTime || this.editRule.ruleActions[0].scheduledTime == null)) {
      this.alertify.error('Please provide a Date and Time for the Alert');
      return;
    }
    if (!this.editRule.rules[0].operator || this.editRule.rules[0].operator == undefined) {
      this.alertify.error('Please select an Operator for the Alert');
      return;
    }
    if (!this.editRule.ruleActions[0].alertMethod || this.editRule.ruleActions[0].alertMethod == undefined) {
      this.alertify.error('Please select an Alert Method for the Alert');
      return;
    }
    if (!this.editRule.ruleActions[0].alertRecipientType || this.editRule.ruleActions[0].alertRecipientType == 'Someone' || this.editRule.ruleActions[0].alertRecipientType == 'Select individual(s)') {
      this.alertify.error('Please select an Alert Recipient for the Alert');
      return;
    }

    if (!this.userChangedEmail) {
      this.editRule.emailTemplate = null;
    }

    // Add Rule
    if (!this.editRule.id) {
      this.ruleService.AddRule(this.editRule).subscribe(() => {
        this.editRule = {} as RuleHeader;
        this.changeViewMode('pending');
        this.getRules();
      }, error => {
        console.error(error);
        this.alertify.error('Unable to add Alert');
      }, () => {
        this.viewEmail = false;
        this.editEmail = false;
        this.addMode = false;
        this.editMode = false;
        this.userChangedEmail = false;
        this.callback.emit({ editing: false });
        this.alertify.success('Alert added successfully');
      });
    } else {
      this.ruleService.EditRule(this.editRule).subscribe(() => {
        this.editRule = {} as RuleHeader;
        this.getRules();
      }, error => {
        console.error(error);
        this.alertify.error('Unable to edit Alert');
      }, () => {
        this.viewEmail = false;
        this.editEmail = false;
        this.editMode = false;
        this.userChangedEmail = false;
        this.callback.emit({ editing: false });
        this.alertify.success('Alert edited successfully');
      });
    }
  }

  setChangesEvent(event) {

    let ruleChanged = event;

    if (ruleChanged.condition === 'scope') {
      this.editRule.scopeType = ruleChanged.newValue.label;
      this.editRule.scopeId = ruleChanged.newValue.value;
    }
    if (ruleChanged.condition === 'metric') {
      this.editRule.rules[0].metricType = ruleChanged.newValue;
    }
    if (ruleChanged.condition === 'metric-number') {
      this.editRule.rules[0].value = parseInt(ruleChanged.newValue.value);
    }
    if (ruleChanged.condition === 'scheduled-date') {
      this.editRule.ruleActions[0].scheduledTime = ruleChanged.newValue.value;
    }
    if (ruleChanged.condition === 'operator') {
      this.editRule.rules[0].operator = ruleChanged.newValue.value;
    }
    if (ruleChanged.condition === 'alertMethod') {
      this.editRule.ruleActions[0].alertMethod = ruleChanged.newValue;
    }
    if (ruleChanged.condition === 'alertRecipient') {
      this.editRule.ruleActions[0].alertRecipientType = ruleChanged.newValue;
    }

    if (!this.userChangedEmail && this.editRule?.ruleType !== 'task' && this.editRule.ruleActions[0].alertMethod === 'Email')
      this.handleEmailTemplate(this.editRule.rules[0]);
  }

  handleEmailTemplate(rule) {

    let body: string;
    let stringOperator = rule.operator;

    if (stringOperator === "<") {
      stringOperator = "below";
    } else if (stringOperator === ">") {
      stringOperator = "above";
    }

    let metricType = rule.metricType?.toLowerCase() || '[metric]';

    const vendorName = this._project?.projectSegments[0].projectSurveyPartners.find(x => x.partnerId.toLowerCase() === this.editRule.scopeId.toLowerCase())?.partnerName;

    if (this.editRule.ruleActions[0].alertRecipientType.split(',').some(x => x.toLowerCase() === 'vendor')) {
      body = `Hello ${vendorName}, <br><br> On Project <a href='${this.projectUrl + this.editRule.projectId} '>${this._project.projectCode} - ${this._project.projectName}</a>, your goal of ${metricType} is ${stringOperator} ${rule.value || 0}. <br>Please contact ${this._project.pm} at ${this._project.pmEmail} referencing ${this._project.projectCode} for any questions`;
    } else {
      body = `Hello, <br><br> On Project <a href='${this.projectUrl + this.editRule.projectId} '>${this._project.projectCode} - ${this._project.projectName}</a>, your goal of ${metricType} is ${stringOperator} ${rule.value || 0}`;
    }

    if (vendorName && !this.editRule.ruleActions[0].alertRecipientType.split(',').some(x => x.toLowerCase() === 'vendor')) {
      body += ` for ${vendorName}.`;
    }

    this.editRule.emailTemplate = body;
    this.emailTemplateSaved = body;
  }

  saveEditEmail() {
    if (!this.editRule.emailTemplate) {
      this.handleEmailTemplate(this.editRule.rules[0]);
    }
    this.emailTemplateSaved = this.editRule.emailTemplate;
    this.userChangedEmail = true;
    this.editEmail = false;
  }

  cancelEditEmail() {
    this.editRule.emailTemplate = this.emailTemplateSaved;
    this.editEmail = false;
  }

  viewEmailMode() {
    if (!this.userChangedEmail)
      this.handleEmailTemplate(this.editRule.rules[0]);

    this.viewEmail = !this.viewEmail;
  }

  cancelAlert(rule) {
    this.ruleService.DeleteRule(rule.id).subscribe(() => {
      this.getRules();
    }, error => {
      this.alertify.error('Unable to delete Alert');
    }, () => {
      this.alertify.success('Alert deleted successfully');
    });
  }

  cancel() {
    this.editRule = {} as RuleHeader;
    this.addMode = false;
    this.editMode = false;
    this.viewEmail = false;
    this.editEmail = false;
    this.userChangedEmail = false;
    this.emailTemplateSaved = '';
    this.callback.emit({ editing: false });
  }

  cancelEdit(rule) {
    if (!this.editRule.id) { // Cancel Add Action
      if (!this.editRule.copy) {
        this.editRule = {} as RuleHeader;
        this.addMode = false;
      }
      else
        this.rules.splice(this.editRule.index, 1); // Cancel Copy Action
    } else {
      this.editRule = this.rollbackRule;
      this.editMode = false;
    }
  }

  edit(rule) {
    this.editRule = JSON.parse(JSON.stringify(rule));
    this.editRule.editing = true;
    this.editMode = true;
    this.rollbackRule = this.editRule;
    this.userChangedEmail = this.editRule?.emailTemplate ? true : false;
    this.callback.emit({ editing: true });
  }

  copy(rule) {
    this.editRule = { ...rule };
    this.editRule.id = '';
    this.editRule.name = rule.ruleType == 'task' ? 'Task Alert' : `${rule?.scopeType} ${rule?.rules[0].metricType}`;
    this.editRule.createdByName = this.auth.getUser().name;
    this.editRule.editing = true;
    this.editRule.copy = true;
    this.editMode = true;
    this.emailTemplateSaved = this.editRule.emailTemplate;
    this.userChangedEmail = this.editRule?.emailTemplate ? true : false;
    this.callback.emit({ editing: true });
  }
}
