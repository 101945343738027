import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard.component';
import { ComponentsModule, HelpersModule } from 'core';

import { WidgetTitleComponent } from './widget-title/widget-title.component';
import { WidgetProjectByCategoryComponent } from './widget-project-by-category/widget-project-by-category.component';
import { WidgetProjectHealthByClient } from './widget-project-health-by-client/widget-project-health-by-client.component';
import { WidgetProjectByManagerComponent } from './widget-project-by-manager/widget-project-by-manager.component';
import { WidgetPartnerFraudRateComponent } from './widget-partner-fraud-rate/widget-partner-fraud-rate.component';
import { WidgetNewsFeedComponent } from './widget-newsfeed/widget-newsfeed.component';
import { WidgetProjectHealthComponent } from './widget-project-health/widget-project-health.component';
import { WidgetProjectFavouriteComponent } from './widget-project-favourite/widget-project-favourite.component';
import { WidgetRevenueByManagerComponent } from './widget-revenue-by-manager/widget-revenue-by-manager.component';
import { WidgetMarginComponent } from './widget-margin/widget-margin.component';
import { KtdGridModule } from '@katoid/angular-grid-layout';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { HighchartsChartModule } from 'highcharts-angular';
import { RouterModule } from '@angular/router';
import { WidgetCustomReportComponent } from './widget-custom-report/widget-custom-report.component';
import { DashboardSetupComponent } from './dashboard-setup/dashboard-setup.component';
import { WidgetConfigComponent } from './widget-config/widget-config.component';
import { WidgetJobsToBeDoneComponent } from './widget-jobs-to-be-done/widget-jobs-to-be-done.component';
import { WidgetYtdComponent } from './widget-ytd/widget-ytd.component';
import { WidgetMyPrioritiesComponent } from './widget-my-priorities/widget-my-priorities.component';
import { WidgetUpcomingProjectsComponent } from './widget-upcoming-projects/widget-upcoming-projects.component';
import { WidgetCurrentProjectsTasksComponent } from './widget-current-projects-tasks/widget-current-projects-tasks.component';
import { WidgetFilterUsersComponent } from './widget-filter-users/widget-filter-users.component';


@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    KtdGridModule,
    NgxChartsModule,
    RouterModule,
    HighchartsChartModule,
    HelpersModule,
    ReactiveFormsModule
  ],
  declarations: [
    DashboardComponent,
    WidgetCustomReportComponent,
    WidgetJobsToBeDoneComponent,
    WidgetProjectByCategoryComponent,
    WidgetNewsFeedComponent,
    WidgetProjectHealthComponent,
    WidgetProjectByManagerComponent,
    WidgetPartnerFraudRateComponent,
    WidgetProjectFavouriteComponent,
    WidgetMarginComponent,
    WidgetRevenueByManagerComponent,
    WidgetTitleComponent,
    DashboardSetupComponent,
    WidgetConfigComponent,
    WidgetProjectHealthByClient,
    WidgetYtdComponent,
    WidgetMyPrioritiesComponent,
    WidgetUpcomingProjectsComponent,
    WidgetCurrentProjectsTasksComponent,
    WidgetFilterUsersComponent
  ]
})
export class DashboardModule { }
