import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertifyService, ReportsService } from 'core';
import { DashboardService } from 'projects/core/src/services/dashboard.service';
import * as moment from 'moment';
import { ListComponent } from 'projects/core/src/components/list/list.component';

@Component({
  selector: 'app-widget-custom-report',
  templateUrl: './widget-custom-report.component.html',
  styleUrls: ['./widget-custom-report.component.scss']
})
export class WidgetCustomReportComponent implements OnInit {

  @Input() widget;
  @Output() onremove = new EventEmitter();
  @Output() onupdate = new EventEmitter();

  loadingData = false;
  tableData = [];
  reportTableColumns = [];
  reportTableFilterColumns = [];

  filterColumns = ['reportName', 'reportType', 'createdBy', 'lastRun', 'lastModified'];
  filterText = '';
  savedReports:any = [];
  selectedReport = null;
  settings = {
    myProjects: true,
    maxCount: null
  };
  layout: any = [
    {
      label: 'Report Name',
      id: 'reportName',
      sortable: true,
      selectable: true
    },{
      label: 'Type',
      id: 'reportType',
      sortable: true
    },{
      label: 'Created By',
      id: 'userName',
      sortable: true
    },{
      label: 'Last Run',
      id: 'lastRun',
      sortable: true,
      type: 'datetime'
    },{
      label: 'Last Updated',
      id: 'lastUpdated',
      sortable: true,
      type: 'datetime'
    }
  ];

  constructor(
    public dashboardService: DashboardService,
    private alertService: AlertifyService,
    private reportService: ReportsService,
    private router: Router) { }

  ngOnInit() {
    this.lookForReportInfo(this.widget.settings);
    this.selectedReport === null
      ? this.getCustomReports()
      : this.getCustomReport(this.selectedReport.id);
  }
  lookForReportInfo(settings) {
    if (settings?.selectedReport === undefined || settings?.selectedReport === null) return;
    this.selectedReport = settings.selectedReport;
  }
  getCustomReports() {
    this.dashboardService.getCustomReports(this.settings).subscribe(data => {
      if (data.length <= 0) return;
      this.savedReports = data;
    }, error => {
      this.selectedReport = null;
      this.alertService.error("Unable to load report!");
      console.log(error);
    });
  }
  getCustomReport(reportId) {
    this.loadingData = true;

    let columns = JSON.parse(this.selectedReport.columns);
    this.dashboardService.getCustomReport(reportId).subscribe(result => {
      this.reportTableColumns = columns;
      this.reportTableFilterColumns = columns.map(x => x.id);
      this.tableData = result.data[0];
      this.loadingData = false;
    }, error => {
      this.alertService.error("Could not get the report data. An error occurred!")
    }, 
    () => {}
    );
  }
  selectReport(item) {
    this.selectedReport = item;
    this.updateTitle(this.selectedReport.reportName);
    this.getCustomReport(this.selectedReport.id);
  }
  @ViewChild(ListComponent) child: ListComponent;
  exportReport() {
    if (this.tableData.length <= 0) {
      this.alertService.warning("Could not export as no data received!")
    } else {
      let columns = this.selectedReport.columns;
      this.selectedReport.columns = JSON.stringify(this.selectedReport.columns);
      this.reportService.ExportReportData(this.selectedReport).subscribe(_ => {}); // Update last run if config exists
      this.selectedReport.columns = columns;

      let date = new Date();
      this.child.exportFilename = this.selectedReport.reportName + '-' + moment(date).format('YYYYMMDD HHmmss') + '.csv';
      this.child.exportTable();
    }
  }
  linkToBuildReportPage() { this.router.navigateByUrl('/reports/builder'); }

  remove() { this.onremove.emit(); }
  
  updateWidget() {
    this.widget.settings.selectedReport = this.selectedReport;
    this.widget = {... this.widget};
    this.onupdate.emit();
  }
  
  updateTitle(widgetTitle) {
    this.widget.title = widgetTitle;
    this.updateWidget();
  }
}

