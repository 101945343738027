<div class="container-fluid">
  <div class="m-3">
    <div class="row">
      <div class="col px-0">
        <div class="h3 or-secondary">Audit Report</div>
      </div>
      <div class="col">
        <div class="row d-flex justify-content-end">
          <div *ngIf="this.projectId!=''" class="col-6 px-0">
            <input [(ngModel)]="filterText" type="search" class="form-control" placeholder="Type to filter...">
          </div>
          <ng-container *ngIf="this.projectId==''">
            <div class="col-3 d-flex justify-content-end align-items-center px-0">
              <input type="text"  autocomplete="off" class="form-control" [style.visibility]="dateRange == null ? 'hidden' : 'visible'" [(ngModel)]="dateRange" #drp="bsDaterangepicker"  bsDaterangepicker
              [bsConfig]="{ showPreviousMonth:false, dateInputFormat: 'MMM Do, YYYY',displayOneMonthRange: true, isAnimated: false, isOpen:true, containerClass: 'theme-blue', showWeekNumbers:false }">
              <button *ngIf="dateRange == null" type="button" class="btn btn-sm btn-outline-secondary ng-star-inserted" (click)="drp.show()">
                <i class="fas fa-calendar-alt"></i>
              </button>
            </div>
            <div class="col users">
              <or-type-ahead [labelField]="'name'" [valueField]="'id'" [dataCallback]="getUserList" placeholder="User..." (valueChange)="updateUser($event)" ></or-type-ahead>
            </div>
            <div class="col px-0">
              <div class="d-flex">
                <input type="text" placeholder="Project Code, Project Name" class="form-control" [(ngModel)]="auditFilter">
                <button type="button" class="ml-1 btn btn-sm btn-secondary" (click)="getAuditDetails()">Go</button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="loading == true">
    <div class="loading"></div>
  </div>

  <div class="table-responsive audit-table" *ngIf="loading == false">
    <or-list [items]="tableData | filter : filterText : filterColumns"  [layout]="columns"></or-list>
  </div>

</div>

