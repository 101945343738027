<div class="card m-2">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h3 class="nav-secondary font-weight-bold">Survey Tester</h3>
      </div>
      <div class="col text-right">
        <button type="button" class="btn nav-btn-accent btn-sm opacity-90 mr-2" (click)="launchSurvey()">
          <i class="fas fa-rocket mr-1"></i>
          <span>Launch</span>
        </button>
      </div>
    </div>
  </div>
  <div class="card-body scrollbar overflow-y">
    <div class="row">
      <div class="form-group col">
        <label class="h6 font-weight-normal">Programmer</label>
        <or-type-ahead
            [labelField]="'fullName'"
            [valueField]="'id'"
            (input)="formChanged($event)"
            [dataCallback]="getUserList"
            placeholder="Type to search..."
            (valueChange)="updateProgrammer($event)"
            [selectedValues]="programmer?.id == null ? [] : [{id: programmer.id, fullName: programmer.fullName}]">
        </or-type-ahead>
      </div>
    </div>
    <div class="row">
      <div class="form-group col">
        <label class="h6 font-weight-normal">Reviewers</label>
        <or-type-ahead
            [multi]="true"
            [labelField]="'fullName'"
            [valueField]="'id'"
            (input)="formChanged($event)"
            [dataCallback]="getUserList"
            placeholder="Type to search..."
            (valueChange)="updateReviewers($event)"
            [selectedValues]="reviewers">
        </or-type-ahead>
      </div>
    </div>
    <div class="row mb-4">
      <div class="form-group col-12">
        <label class="h6 font-weight-normal">Testing Note</label>
        <textarea type="text" class="form-control form-control-sm" [(ngModel)]="info.notes"></textarea>
      </div>
      <div class="col">
        <button type="button" class="btn btn-xs btn-outline-secondary" (click)="saveNotes()">
          <em class="fa fa-save"></em> Save
        </button>
      </div>
    </div>
    <div class="row mx-1 mt-2">
      <div class="h6 or-title">Invite Reviewers (Guests)</div>
    </div>
    <div class="reviewer-item p-2 or-primary or-gray-bg">
      <div><small class="or-title">Reviewer</small></div>
      <div class="text-center"><small class="or-title">Email</small></div>
      <div class="text-center"><small class="or-title">Expires</small></div>
    </div>
    <div class="scrollbar overflow-y">
      <div class="reviewer-item p-2" *ngFor="let user of guests">
        <small class="or-title">
          {{user.fullName}}
        </small>
        <small [tooltip]="user.email" placement="right" class="or-title text-center">
          {{user.email}}
        </small>
        <small class="or-title text-center">
          {{user.expiresAt | date: 'MM/dd/yyyy'}}
        </small>
        <span class="or-title d-flex align-items-center justify-content-center" (click)="copyLink(user)" tooltip="Copy guest URL" placement="left">
          <i class="fas fa-sm fa-link cursor-pointer"></i>
        </span>
        <span class="or-title d-flex align-items-center justify-content-center" tooltip="Email has been sent" placement="left" (click)="sendEmail(user)">
          <i class="fas fa-sm fa-envelope text-success"></i>
        </span>
        <span class="or-title d-flex align-items-center justify-content-center" (click)="deleteGuest(user)">
          <i class="fas fa-sm fa-remove cursor-pointer"></i>
        </span>
      </div>

      <ng-container *ngIf="newGuest?.id">
        <div class="reviewer-item add p-2">
          <small class="or-title">
            <input type="text" required class="form-control form-control-sm" #name="ngModel" [(ngModel)]="newGuest.fullName" [ngClass]="showCssValidField(name)">
          </small>
          <small class="or-title text-center">
            <input type="email" email required  class="form-control form-control-sm" #email="ngModel" [(ngModel)]="newGuest.email" [ngClass]="showCssValidField(email)">
          </small>
          <small class="or-title text-center">
            <input
              type="text"  autocomplete="off"
              readonly
              bsDatepicker
              [minDate]="currentDate"
              [bsValue]="newGuest.expiresAt"
              (bsValueChange)="newGuest.expiresAt = $event"
              [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', isAnimated: true, isOpen:true, showWeekNumbers:false, customTodayClass: 'todays-date' }"
              class="form-control form-control-sm date d-inline bg-white">
          </small>
          <span class="or-title d-flex align-items-center justify-content-center" (click)="newGuest = {}">
            <i class="far fa-sm fa-window-close cursor-pointer"></i>
          </span>
          <span class="or-title d-flex align-items-center justify-content-center" (click)="saveGuest()">
            <i class="fas fa-sm fa-save cursor-pointer"></i>
          </span>
        </div>
      </ng-container>

    </div>
    <button type="button" class="btn btn-xs btn-outline-secondary m-2" (click)="addGuest()">
      <em class="fa fa-plus"></em> Invite
    </button>
    <div class="row mx-1 mt-4">
      <div class="h6 or-title">Summary</div>
    </div>
     <table class="table mb-4">
      <thead>
        <tr>
          <th scope="col" class="text-center p-1 small">OPEN</th>
          <th scope="col" class="text-center p-1 small">RESOLVED</th>
          <th scope="col" class="text-center p-1 small">CLOSED</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-center table-danger p-1">{{info?.totalOpen}}</td>
          <td class="text-center table-success p-1">{{info?.totalPending}}</td>
          <td class="text-center p-1">{{info?.totalClosed}}</td>
        </tr>
      </tbody>
     </table>
     <h6 class="w-100 font-weight-light"># of Corrections: {{info?.totalByType?.corrections}}</h6>
     <h6 class="w-100 font-weight-light"># of Added Questions: {{info?.totalByType?.addedQuestions}}</h6>
     <h6 class="w-100 font-weight-light"># of Deleted Questions: {{info?.totalByType?.deletedQuestions}}</h6>
     <h6 class="w-100 font-weight-light"># of Changes: {{info?.totalByType?.changed}}</h6>
     <h6 class="w-100 font-weight-light"># of Logic Changes: {{info?.totalByType?.logicChanges}}</h6>
     <h6 class="w-100 font-weight-light"># of Text Changes: {{info?.totalByType?.textChanges}}</h6>
     <h6 class="w-100 font-weight-light"># of Added Elements: {{info?.totalByType?.addedElements}}</h6>
  </div>
</div>
