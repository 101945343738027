<div class="row mt-1">
  <div class="col">
    <div class="d-flex justify-content-end mb-4 or-secondary" *ngIf="batch">
      <i (click)="openModal()" tooltip="Open ResponseID Settings" class="fas fa-cog fa-lg cursor-pointer ml-2 opacity-90"></i>
    </div>
  </div>
</div>

<ng-container *ngIf="!batch">
  <div class="m-1">
    <or-upload smallMsg=".csv files only" isCsv=true (uploadCompleted)="validateBatch()" publicBucket=false [includeProgressBar]=true acceptTypes=".csv" [instanceId]="auth.getUser().instanceId" [S3Folder]="s3buckPath" directToS3=true [(ngModel)]="uploadedFile"  (change)="handleFileInput($event.target.files)"></or-upload>
  </div>
</ng-container>

<ng-container *ngIf="showUpload">
  <div class="card-body">
    <div class="m-1" *ngIf="auth.isInternal()">
      <or-upload smallMsg=".csv files only" isCsv=true (uploadCompleted)="validateBatch()" publicBucket=false [includeProgressBar]=true acceptTypes=".csv" [instanceId]="auth.getUser().instanceId" [S3Folder]="s3buckPath" directToS3=true [(ngModel)]="uploadedFile" (change)="handleFileInput($event.target.files)"></or-upload>
    </div>

    <div class="m-1" *ngIf="!auth.isInternal()">
      <p class="h5 mb-0 font-weight-normal or-secondary">No ResponseID Data!</p>
    </div>

    <div class="col-12 d-flex justify-content-end" *ngIf="batch">
      <button type="button" class="btn btn-sm btn-outline-secondary mt-2 mr-0" (click)="cancel()"> cancel</button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="batch && batch.status.toLowerCase() === 'complete' && metrics && showUpload==false">
  <div class="row">
    <div class="col px-4 pr-5">
      <div class="row grid">
        <div>
          <div id="rid-chart"></div>
        </div>
        <div class="nav-leftborder"></div>
        <div>
          <table class="table">
            <thead>
              <tr class="table-primary">
                <th class="py-1">Categorization</th>
                <th class="py-1">Respondents</th>
                <th class="py-1">Percentage</th>
              </tr>
            </thead>
            <tbody>
              <tr class="or-gray-bg">
                <td class="pt-1 pb-1">Critical</td>
                <td class="pt-1 pb-1">{{metrics.criticalCount}}</td>
                <td class="pt-1 pb-1">{{metrics.criticalCount / batch.totalRecords * 100 | number: '1.1-2'}}</td>
              </tr>
              <tr>
                <td class="pt-1 pb-1">High</td>
                <td class="pt-1 pb-1">{{metrics.highCount}}</td>
                <td class="pt-1 pb-1">{{metrics.highCount / batch.totalRecords * 100 | number: '1.1-2'}}</td>
              </tr>
              <tr class="or-gray-bg">
                <td class="pt-1 pb-1">Medium</td>
                <td class="pt-1 pb-1">{{metrics.mediumCount}}</td>
                <td class="pt-1 pb-1">{{metrics.mediumCount / batch.totalRecords * 100 | number: '1.1-2'}}</td>
              </tr>
              <tr>
                <td class="pt-1 pb-1">Low</td>
                <td class="pt-1 pb-1">{{metrics.lowCount}}</td>
                <td class="pt-1 pb-1">{{metrics.lowCount / batch.totalRecords * 100 | number: '1.1-2'}}</td>
              </tr>
              <tr class="or-gray-bg border-bottom">
                <td class="pt-1 pb-1"><b>Total</b></td>
                <td class="pt-1 pb-1"><b>{{batch.totalRecords}}</b></td>
                <td class="pt-1 pb-1"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col text-right pr-0  mt-2">
          <button type="button" class="btn btn-sm btn-outline-secondary mr-2" (click)="Upload()"><em class="fa fa-upload"></em> Upload</button>
          <button type="button" class="btn btn-sm btn-outline-secondary" (click)="download()"><em class="fa fa-download"></em> Download</button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="batch && batch.status.toLowerCase() !== 'complete' && batch.status.toLowerCase() !== 'reset'">
  <div class="row mt-5">
    <div class="col d-flex justify-content-center">
      <div class="d-inline-flex or-gray-bg border w-75 p-2">
        <div class="text-center w-100">
          <div class="mb-3 pb-1 border-bottom w-100 d-block">
            Processing Batch
            <ng-container *ngIf="batch.status.toLowerCase() == 'in progress'">
              <span class="float-right small font-weight-bold">{{percentage}} % <div class="loading ml-2"></div></span>
              <div class="rule mt-2">
                <div class="progress or-gray-bg">
                  <div class="progress-bar" role="progressbar" [style.width.%]="percentage" aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </ng-container>
            <div *ngIf="batch.status.toLowerCase() == 'queued'" class="alert alert-primary py-0 px-1 ml-auto small d-inline float-right" role="alert">QUEUED</div>
          </div>
          <div class="row">
            <div class="col">
              <p class="mb-1">
                Submitted On: <span class="small font-weight-bold">{{ batch.submittedOn | date: 'short' }}</span>
              </p>
              <p class="mb-1">
                Submitted By: <span class="small font-weight-bold">{{ batch.submittedBy }}</span>
              </p>
              <p class="mb-1">
                Total Rows: <span class="small font-weight-bold">{{ batch.totalRecords }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
