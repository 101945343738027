
<div class="card mb-3" [ngClass]="group.formMode == 'setup' ? 'shadow' : ''">
  <div class="card-header nav-secondary  py-2">
    <div class="row">
      <div class="text-left px-2 col-4" > <!--[ngClass]="group.formMode=='setup' ? 'col-5' : 'col-4'"-->
        @if (group.formMode == 'setup' && !newQuota) {
          <span
            tabindex="-1" class="p-0 btn m-0 mr-2 "
            (click)="removeQuotaGroup()">
            <i class=" fak fa-trash fa-sm nav-error cursor-pointer grey4"></i>
          </span>
        }
        {{group.name}}
      </div>
      <div class="col-5 text-center"> <!--[ngClass]="group.formMode=='setup' ? 'col-4' : 'col-5'"-->
        @if (limitCheck != 'tracking only') {
          @if (group.formMode != 'setup') {
            {{limitMode | titlecase}}s
          }
          @if (group.formMode == 'setup') {
            {{limitType == 'percent' ? '#' : '%' }} of demographic
          }
        }
      </div>
      <div class="col-3 text-center">
        {{limitCheck != 'tracking only' ? 'Limit' : ''}} <!-- (limitType == 'percent' ? '%' : 'n') -->
      </div>
    </div>
  </div>
  <div class="card-body pb-2">
    @for (quota of group.quotas; track quota; let i = $index) {
      @if (quota.isDeleted == false) {
        <ng-container class="">
          <div
            (click)="enableEdit(quota)"
            [class.inlineEdit]="quota.edit == true"
            class="row quotaRow pt-2 clickable">
            <div class="colHeight24 col-4 text-nowrap quotaNameCol px-2 clickable"
              [class.text-truncate]="questions[group.questionId].type != 'HouseholdIncome' && questions[group.questionId].type != 'Age' && ((limitCheck == 'tracking only' && group.formMode != 'setup') || limitCheck != 'tracking only')"> <!--[ngClass]="group.formMode == 'setup' ? 'col-5' : 'col-4'"-->
              @if (group.formMode == 'setup') {
                <span tabindex="-1" class="p-0 btn m-0 mr-2" (click)="removeQuota(group, quota.id)">
                  <i class=" fa fa-times fa-sm cursor-pointer grey4"></i>
                </span>
              }
              @if (quota.targetGroup[questions[group.questionId].type.toLowerCase()]; as value) {
                <span class="clickable" [class.font-italic]="value === 'pnta'">{{ value === 'pnta' ? 'Prefer not to answer' : value}}</span>
              }
              <!-- <div class="col-4"  *ngIf="questions[q].type == 'State'">
              <div class="d-flex align-items-center ">
                <div>
                  <or-type-ahead [multi]="true" [dataCallback]="getStates" (valueChange)="updateQuotaStates(quota, $event)" [selectedValues]="quota.targetGroup.states"></or-type-ahead>
                </div>
              </div>
            </div> -->
            @if (questions[group.questionId].type == 'HouseholdIncome') {
              @if (quota.targetGroup.minHHI > -1) {
                <div class="d-inline">
                  @if (quota.edit != true && group.formMode != 'setup') {
                    <div class="d-inline">
                      <div class="d-inline">
                        <u class="clickable">{{ quota.targetGroup.minHHI |  currency :'USD':'symbol':'1.0-0'}}</u>
                      </div>
                      <div class="mx-1 mt-1 d-inline"> to </div>
                      <div class="d-inline">
                        @if (quota.targetGroup.maxHHI <= 150000) {
                          <u class="clickable">{{ quota.targetGroup.maxHHI |  currency :'USD':'symbol':'1.0-0'}}</u>
                        }
                        @if (quota.targetGroup.maxHHI > 150000) {
                          <u class="clickable">and above</u>
                        }
                      </div>
                    </div>
                  }
                  @if (quota.edit == true || group.formMode == 'setup') {
                    <div class="d-inline">
                      <select
                        (change)="minChanged(quota, group, quota.targetGroup)"
                        class=" form-control form-control-sm form-control-nav nav-font12 hhiSelect inlineInput clickable d-inline"
                        [(ngModel)]="quota.targetGroup.minHHI">
                        <option  [ngValue]="0"> {{0 | currency :'USD':'symbol':'1.0-0' }}</option>
                        @for (n of hhiList; track n) {
                          <option [ngValue]="n">{{n/1000 | currency :'USD':'symbol':'1.0-1' }}k</option>
                        }
                      </select>
                    </div>
                    <div  class="mr-2 ml-2 mt-1 d-inline"> to </div>
                    <div class="d-inline">
                      <select
                        class=" form-control form-control-sm form-control-nav nav-font12 hhiSelect hhiInlineInput clickable d-inline"
                        [(ngModel)]="quota.targetGroup.maxHHI"
                        (change)="quota.changesMade = true;">
                        @for (n of hhiList; track n) {
                          @if (quota.targetGroup.minHHI < n-1) {
                            <option  [ngValue]="n-1">{{n-1 | currency :'USD':'symbol':'1.0-0' }}</option>
                          }
                        }
                        <option [ngValue]="hhiMax+1">and above</option>
                      </select>
                    </div>
                  }
                </div>
              }
              @if (quota.targetGroup.minHHI == -1) {
                <div class="d-inline font-italic clickable">
                  Prefer not to answer
                </div>
              }
            }
            @if (group.name == 'Age') {
              <div class="d-inline">
                @if (quota.edit != true && group.formMode != 'setup') {
                  <div class="d-inline">
                    <div class="d-inline">
                      <u class="clickable">{{ quota.targetGroup.minAge || 0}}</u>
                    </div>
                    <div class="mx-1 mt-1 d-inline"> to </div>
                    <div class="d-inline">
                      <u class="clickable">{{ quota.targetGroup.maxAge || 0}}</u>
                    </div>
                  </div>
                }
                @if (quota.edit == true || group.formMode == 'setup') {
                  <div class="d-inline">
                    <input #inputMin
                      type="text"
                      mask="999"
                      [specialCharacters]="[]"
                      class="text-left form-control form-control-nav form-control-sm ageInlineInput clickable d-inline"
                      [(ngModel)]="quota.targetGroup.minAge"
                      (keyup.enter)="inputMin.blur()"
                      (blur)="minChanged(quota, group, quota.targetGroup)">
                    </div>
                    <div class="mr-2 ml-2 mt-1 d-inline"> to </div>
                    <div class="d-inline">
                      <input #inputMax
                        type="text"
                        mask="999"
                        [specialCharacters]="[]"
                        class="text-left form-control form-control-nav form-control-sm ageInlineInput clickable d-inline"
                        [(ngModel)]="quota.targetGroup.maxAge"
                        (keyup.enter)="inputMax.blur()"
                        (blur)="quota.changesMade = true;">
                      </div>
                    }
                  </div>
                }
              </div>
              @if (limitType == 'percent') {
                <div class="col-5 clickable" > <!--[ngClass]="group.formMode == 'setup' ? 'col-4' : 'col-5'"-->
                  @if (group.formMode != 'setup') {
                    <div class="rule clickable">
                      <!-- question progress guage -->
                      <!-- {{quota.completes}} {{group.completes}} {{quota.limit}} -->
                      @if (limitCheck == 'tracking' || limitCheck == 'enforced') {
                        @if (quota.limit < 99999) {
                          @if (limitMode == 'start') {
                            <or-progress-guage [value]="(quota.starts * 100) / quota.limitN" [expected]="daysPercentagePast" [bgColor]="getBgColor((quota.starts * 100) / quota.limitN)" [bgDanger]="getBgColor((quota.starts * 100) / quota.limitN)" mode="quota"></or-progress-guage>
                          }
                          @if (limitMode == 'complete') {
                            <or-progress-guage [value]="(quota.completes * 100) / quota.limitN" [expected]="daysPercentagePast" [bgColor]="getBgColor((quota.completes * 100) / quota.limitN)" [bgDanger]="getBgColor((quota.completes * 100) / quota.limitN)" mode="quota"></or-progress-guage>
                          }
                        }
                        @if (quota.limit >= 99999) {
                          @if ( limitMode == 'start') {
                            <or-progress-guage [value]="(quota.starts * 100) / group.starts" bgColor="'nav-success'" mode="quota"></or-progress-guage>
                          }
                          @if ( limitMode == 'complete') {
                            <or-progress-guage [value]="(quota.completes * 100) / group.completes" bgColor="'nav-success'"  mode="quota"></or-progress-guage>
                          }
                        }
                      }
                      @if (limitCheck == 'tracking only') {
                        @if (limitMode == 'start') {
                          <or-progress-guage [value]="(quota.starts * 100) / group.starts"  [bgColor]="'nav-success'"  mode="quota"></or-progress-guage>
                        }
                        @if (limitMode == 'complete') {
                          <or-progress-guage [value]="(quota.completes * 100) / group.completes"  [bgColor]="'nav-success'"  mode="quota"></or-progress-guage>
                        }
                      }
                    </div>
                  }
                  @if (group.formMode == 'setup' && limitCheck != 'tracking only') {
                    <div class="text-center clickable">
                      {{(quota.limitN >= 99999 ? '*' : quota.limitN) || 0}}
                    </div>
                  }
                </div>
                <div
                  class="pl-0 colHeight24 col-3 clickable"
                  [ngClass]="limitCheck == 'tracking only' ? 'text-center' : 'text-right'">
                  <!-- enforced and tracking -->
                  @if ((limitCheck == 'enforced' || limitCheck == 'tracking') && quota.edit != true && group.formMode != 'setup') {
                    <div class="clickable"
                      >
                      @if (limitMode == 'start'   ) {
                        <div class="d-inline">{{quota.limit < 99999 ? ((quota.starts * 100 / fullLaunchQuota || 0)  | number: '1.0-0') : (quota.starts * 100 / group.starts | number: '1.0-0') }}/</div>
                        <span  class="editable clickable">{{ quota.limit >= 99999 ? "*" : quota.limit || 0 }} % </span>
                      }
                      @if (limitMode == 'complete'   ) {
                        <div class="d-inline">{{quota.limit < 99999 ? ((quota.completes * 100 / fullLaunchQuota || 0)  | number: '1.0-0') : (quota.completes * 100 / group.completes | number: '1.0-0') }}/</div>
                        <span class="editable clickable">{{ quota.limit >= 99999 ? "*" : quota.limit || 0 }} % </span>
                      }
                    </div>
                  }
                  <!-- tracking only -->
                  @if (limitCheck == 'tracking only' && quota.edit != true  && group.formMode != 'setup') {
                    <div class="clickable"
                      >
                      @if (limitMode == 'start'   ) {
                        <span class="clickable">{{ (quota.starts / group.starts || 0) | percent }}</span>
                      }
                      @if (limitMode == 'complete'   ) {
                        <span  class="clickable">{{ (quota.completes / group.completes || 0) | percent}}</span>
                      }
                    </div>
                  }
                  <!-- inline edit -->
                  @if ((quota.edit == true || group.formMode == 'setup') && limitCheck != 'tracking only') {
                    <div  class="pl-1 d-inline clickable">
                      @if (!newQuota) {
                        @if (limitMode === 'start') {
                          <span class="clickable">{{quota.limit < 99999 ? ((quota.starts * 100 / fullLaunchQuota || 0)  | number: '1.0-0') : (quota.starts * 100 / group.starts | number: '1.0-0') }}/ </span>
                        }
                        @if (limitMode === 'complete') {
                          <span class="clickable">{{quota.limit < 99999 ? ((quota.completes * 100 / fullLaunchQuota || 0)  | number: '1.0-0') : (quota.completes * 100 / group.completes | number: '1.0-0') }}/ </span>
                        }
                      }
                      <input #inputLimit (keyup.enter)="inputLimit.blur()" (blur)="handleLimitChange(quota, '%', null, $event)" type="text"
                        class="form-control form-control-nav form-control-sm d-inline nav-font14 inlineInput clickable"
                        oninput="this.value = this.value.replace(/[^0-9*.]/g, '').replace(/(\..*)\./g, '$1');"
                        [value]="quota.limitPercent.toString() ? ( quota.limitPercent < 99999 ?  quota.limitPercent  : '*') : ''"
                        >
                        <span class="pl-1">%</span>
                      </div>
                    }
                  </div>
                }
                @if (limitType == 'number') {
                  <div class="col-5 clickable">
                    @if (group.formMode != 'setup') {
                      <div>
                        <div class="rule clickable">
                          <!-- question progress guage -->
                          @if (limitCheck == 'tracking' || limitCheck == 'enforced') {
                            @if (quota.limit < 99999) {
                              @if ( limitMode == 'start') {
                                <or-progress-guage [value]="(quota.starts * 100)/ quota.limitN" [expected]="daysPercentagePast" [bgColor]="getBgColor((quota.starts * 100)/ quota.limitN)" [bgDanger]="getBgColor((quota.starts * 100)/ quota.limitN)" mode="quota"></or-progress-guage>
                              }
                              @if ( limitMode == 'complete') {
                                <or-progress-guage [value]="(quota.completes * 100) / quota.limitN" [expected]="daysPercentagePast" [bgColor]="getBgColor((quota.completes * 100) / quota.limitN)" [bgDanger]="getBgColor((quota.completes * 100) / quota.limitN)" mode="quota"></or-progress-guage>
                              }
                            }
                            @if (quota.limit >= 99999) {
                              @if ( limitMode == 'start') {
                                <or-progress-guage [value]="(quota.starts * 100) / group.starts" bgColor="'nav-success'" mode="quota"></or-progress-guage>
                              }
                              @if ( limitMode == 'complete') {
                                <or-progress-guage [value]="(quota.completes * 100) / group.completes" bgColor="'nav-success'" mode="quota"></or-progress-guage>
                              }
                            }
                          }
                        </div>
                        <!-- <or-progress-guage class="" [value]="(( limitMode == 'complete' ? quota.completes : quota.starts) * 100) / quota.limitN" [expected]="100"></or-progress-guage> -->
                      </div>
                    }
                    @if (group.formMode == 'setup' && limitCheck != 'tracking only') {
                      <div class="text-center clickable">
                        {{(quota.limitPercent >= 99999 ? '*' : quota.limitPercent) || 0}}
                      </div>
                    }
                  </div>
                  <div  class="col-3 text-right text-nowrap colHeight24 clickable">
                    <!-- tracking and enforced -->
                    @if (limitCheck != 'tracking only' && quota.edit != true && group.formMode != 'setup') {
                      <div
                        class="clickable"
                        >
                        @if ( limitMode == 'start'   ) {
                          <div class="d-inline clickable">{{ quota.starts}}/</div>
                          <span class="editable clickable" >{{ quota.limitN >= 99999 ? "*" : quota.limitN || 0 }} n</span>
                        }
                        @if ( limitMode == 'complete'   ) {
                          <div class="d-inline clickable">{{ quota.completes}}/</div>
                          <span class="editable clickable">{{ quota.limitN >= 99999 ? "*" : quota.limitN || 0 }} n</span>
                        }
                      </div>
                    }
                    <!-- inline edit -->
                    @if ((quota.edit == true || group.formMode == 'setup') && limitCheck != 'tracking only') {
                      <div  class="pl-1 d-inline clickable">
                        @if (!newQuota) {
                          <span class="clickable">{{limitMode === 'start' ? quota.starts : quota.completes}}/ </span>
                        }
                        <input #inputLimit (keyup.enter)="inputLimit.blur()" (blur)="handleLimitChange(quota, 'n', null, $event)" type="text"
                          class="form-control form-control-nav form-control-sm d-inline nav-font14 inlineInput clickable"
                          oninput="this.value = this.value.replace(/[^0-9*.]/g, '').replace(/(\..*)\./g, '$1');"
                          [value]="quota.limitN?.toString() ? ( quota.limitN < 99999 ?  quota.limitN  : '*') : 0"                    >
                          <span class="pl-1">n</span>
                        </div>
                      }
                    </div>
                  }
                </div>
              </ng-container>
            }
          }

          @if (group.nonValueStarts) {
            <div class="row quotaRow pt-2" >
              <div class="colHeight24 col-4 text-nowrap quotaNameCol px-2 " >
                Other
              </div>
              <div class="col-5"></div>
              <div  class="col-3 text-nowrap colHeight24" [ngClass]="limitCheck === 'tracking only' ? 'text-center pl-0' : 'text-right'">
                <div class="">
                  @if (limitCheck != 'tracking only') {
                    @if (limitType=='number') {
                      <div class="d-inline">{{ limitMode == 'complete' ? group.nonValueCompletes : group.nonValueStarts}} n</div>
                    }
                    @if (limitType=='percent') {
                      <div class="d-inline">{{ (limitMode == 'complete' ? ((group.nonValueCompletes*100)/ fullLaunchQuota || 0)   : ((group.nonValueStarts*100)/ fullLaunchQuota || 0)) | number: '1.0-0' }} %</div>
                    }
                  }
                  @if (limitCheck == 'tracking only') {
                    <div class="d-inline">{{ (limitMode == 'complete' ? ((group.nonValueCompletes*100)/ group.completes || 0)   : ((group.nonValueStarts*100)/ group.starts || 0)) | number: '1.0-0' }} %</div>
                  }
                </div>
              </div>
            </div>
          }


          <!-- last row: adding quotas and totals -->
          <div class="row totalRow mt-2 " [class.totalRowTall]="questions[group.questionId].preferNotToAnswer && !group.preferNotToAnswerAdded && group.deletedOptions.length > 0">
            <!-- adding quotas -->
            <div class="col-4 pl-0 text-nowrap mt-1" [style.visibility]="(auth.isClient() || auth.isVendor() || isVendorview) ? 'hidden' : 'visible'">
              @if (questions[group.questionId].type == 'HouseholdIncome') {
                <span tabindex="-1" class="font-weight-bold nav-accent-blue p-0 pl-2 pr-2 clickable" type="button" (click)="addBlankQuota(group, 'HouseholdIncome')">+ &nbsp; Add income range</span>
              }
              @if (questions[group.questionId].type == 'Age') {
                <span tabindex="-1" class="font-weight-bold nav-accent-blue p-0 pl-2 clickable " type="button" (click)="addBlankQuota(group, 'Age')">+ &nbsp; Add age range</span>
              }
              @if (group.name !== 'Income' && group.name !== 'Age') {
                @if (group.deletedOptions?.length > 0) {
                  <span class="font-weight-bold nav-accent-blue nav-font14 clickable p-0 pl-2">
                    <or-dropdown class="clickable" [values]="group.deletedOptions" [text]="'+ &nbsp;&nbsp;Add'" [showBtn]="false" [addClickableClass]="true" (selected)="addBackQuota(group, $event)">
                      <div class="p-5  clickable"></div>
                    </or-dropdown>
                  </span>
                }
              }
              @if (questions[group.questionId].preferNotToAnswer && !group.preferNotToAnswerAdded) {
                @if (group.name === 'Income') {
                  <br>
                  }
                  <span class="font-weight-bold nav-accent-blue nav-font14 cursor-pointer p-0 pl-2 clickable" (click)="addBackPreferNotToAnswer(group)">+ &nbsp; Prefer not to answer</span>
                }
              </div>


              <!-- total -->
              @if (limitCheck != 'tracking only') {
                @if (group.formMode != 'setup') {
                  <div class="text-right grey5 text-nowrap mt-1" [ngClass]="group.formMode != 'setup' ? 'col-6' : 'col-5'">
                    Total
                  </div>
                }
                <!-- <div class="col-5 text-right grey5" *ngIf="group.formMode != 'setup'">
                Total
              </div> -->
              @if (group.formMode == 'setup') {
                <div class="col-5 text-center nav-black1 pr-0">
                  <hr class="grey1 m-0 ml-5 mb-1">
                  <span class="float-left grey5 mb-1">Total</span>
                  <span class="mr-5">
                    @if (limitType=='percent') {
                      {{quotaGroupTotals[group.id] ? quotaGroupTotals[group.id].n : ''}} n
                    }
                    @if (limitType=='number') {
                      {{quotaGroupTotals[group.id] ? quotaGroupTotals[group.id].percent : ''}} %
                    }
                  </span>
                </div>
              }
              <ng-container>
                <div class="text-right nav-black1 pl-0" [ngClass]="group.formMode != 'setup' ? 'mt-1 col-2' : 'col-3'">
                  @if (group.formMode == 'setup') {
                    <hr class="grey1 m-0 mb-1">
                  }
                  @if (limitType=='percent') {
                    @if (!newQuota) {
                      {{limitMode=='complete' ? ((group.completes/fullLaunchQuota) *100 | number: '1.0-0') : ((group.starts/fullLaunchQuota)*100 | number: '1.0-0') }}/
                    }
                    {{quotaGroupTotals[group.id] ? quotaGroupTotals[group.id].percent : ''}} %
                  }
                  @if (limitType=='number') {
                    @if (!newQuota) {
                      {{limitMode=='complete' ? group.completes : group.starts}}/
                    }
                    {{quotaGroupTotals[group.id] ? quotaGroupTotals[group.id].n : ''}} n
                  }
                </div>
              </ng-container>
            }
          </div>
        </div>
      </div>
