import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AlertifyService } from 'core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { type } from 'os';

import { DashboardService } from 'projects/core/src/services/dashboard.service';

@Component({
  selector: 'app-widget-config',
  templateUrl: './widget-config.component.html',
  styleUrls: ['./widget-config.component.scss'],

})
export class WidgetConfigComponent implements OnInit {

  @Input() public set editWidget(data) {
    this.widget = data;
    this.widgetEdit = JSON.parse(JSON.stringify(data));
    this.editSettings = []

    if (this.widget.type == 'project-by-category') {
      var toggleProjects = this.settingsTypes.toggle;
      toggleProjects.label = "My Projects Only";
      toggleProjects.model = 'myProjects';
      toggleProjects.value = this.widgetEdit.settings.myProjects;
      this.editSettings.push(toggleProjects);

      var timeSpan = this.settingsTypes.text;
      timeSpan.label = 'Days Since Project Creation';
      timeSpan.model = 'timeSpan';
      timeSpan.value = this.widgetEdit.settings.timeSpan;
      this.editSettings.push(timeSpan)
    }
    
    else if (this.widget.type == 'project-margin') {
      var toggleProjects = this.settingsTypes.toggle;
      toggleProjects.label = "My Projects Only";
      toggleProjects.model = 'myProjects';
      toggleProjects.value = this.widgetEdit.settings.myProjects;
      this.editSettings.push(toggleProjects);

      var dateRangeDropdown = this.settingsTypes.dropdown;
      dateRangeDropdown.label = "Date Range"
      dateRangeDropdown.options = this.dateRangeOptions;
      dateRangeDropdown.model = 'dateRange'
      dateRangeDropdown.value = this.widgetEdit.settings.dateRange
      this.editSettings.push(dateRangeDropdown);

    }
  }

  @Output() saveEvent = new EventEmitter();
  @Output() callbackEvent = new EventEmitter();

  widgetEdit;
  widget;
  editSettings;
  limitEnabled = false;

 settingsTypes = {
    dropdown : {
      type: 'dropdown',
      options: null,
      label: "",
      model: null,
      value: null
    },
    text : {
      type: 'text',
      inputType: 'text',
      label: '',
      model: null,
      value: null
    },
    toggle : {
      type: 'toggle',
      label: '',
      model: null,
      value: null
    }
  }

  dateRangeOptions = {
    1: {label: 'This Month', value: 'this month'},
    2: {label :'Last Month', value: 'last month'},
    3: {label: 'This Quarter', value:'this quarter'},
    4: {label: 'Last Quarter', value: 'last quarter'},
    5: {label: 'Year to Date', value: 'year to date'},
    6: {label: 'Last 30 Days', value: 'last 30 days'},
    7: {label: 'Last 60 Days', value: 'last 60 days'},
    8: {label: 'Last 90 Days', value: 'last 90 days'}
  }


  constructor(
    public dashboardService: DashboardService,
    public bsModalRef: BsModalRef,
    private alertify: AlertifyService,
    ) {};


  ngOnInit() {
  }

  saveWidgetConfig() {
    // this.editConfig.myProjects = this.configForm.value.myProjectsEnable
    this.editSettings.forEach(set => {
      if (set.model === 'myProjects') this.widgetEdit.settings.myProjects = set.value;
      else if (set.model === 'timeSpan') this.widgetEdit.settings.timeSpan = set.value;
      else if (set.model === 'dateRange') this.widgetEdit.settings.dateRange = set.value;
      else if (set.model === 'maxCount') this.widgetEdit.settings.maxCount = set.value;
      else if (set.model === 'maxCount') this.widgetEdit.settings.maxCount = set.value;

    });

    this.callbackEvent.emit({data: this.widgetEdit.settings });

    this.alertify.success('Your Widget has been saved succesfully!');
    this.bsModalRef.hide();
  }

  cancel()
 {
    this.bsModalRef.hide();
 }
}
