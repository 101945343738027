import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef, HostListener, ViewChildren } from '@angular/core';
import { AlertifyService, ProjectService, AuthService, QuotaService, ProjectQuota, QuotaGroup, Quota, DropdownQuota, UserService, QuotaVendorLimit, DropdownService, UtilsService, ProjectDataService } from 'core';
import { v4 as uuidv4 } from 'uuid';
import {  forkJoin } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { cl } from '@fullcalendar/core/internal-common';

@Component({
  selector: 'app-project-quota',
  templateUrl: './project-quota.component.html',
  styleUrls: ['./project-quota.component.scss']
})
export class ProjectQuotaComponent implements OnInit {
  @ViewChild('modal')
  modalRefConfirm: BsModalRef;
  openModalRef: any;
  modalType = "";

  @Input() project;
  @Input() respondents;
  @Output() onclose = new EventEmitter();
  @Output() redirectToSection = new EventEmitter();

  @ViewChild('dropdownDemos', {static: false}) dropdownDemo;
  listenerFn: () => void;
  propagateChange: (_: any) => {};

  @ViewChild('dropdownSettings', {static: false}) dropdownSettings;

  isVendorview: boolean;
  quotaEnabled
  instanceId: string;
  optionFilterArgs = {isDeleted: true};
  metrics = {starts: 0, completes: 0}
  justSaved = true; // to know if changes to quota were saved
  formMode = 'edit';
  showSettings= false;
  newQuota = null;
  s3buckPath;
  importFileName;
  loadingImport = false;

  questions;
  questionIDs = [];
  quotaDetails: ProjectQuota = null;
  quotaGroupTotals = {};
  vendorGroupTotals = {};
  vendors;
  deletedQGOptions = []
  groupWithNoLimitExists = false;
  overlapExists = false;
  overTotalExists = false;
  overTotalError = false;
  overlapError = false;
  actionsOpen = false;
  editOverallN = false;
  daysPercentagePast = 0;

  incomeHasPNTA = false;

  questionTypes = [
    {label:"Age", value:"Age"},
    {label:"Gender",value:"Gender"},
    {label:"Income", value:"HouseholdIncome"},
    {label:"Region", value:"Region", type: "location"},
    {label: "Division", value:"Division", type: "location"},
    {label: "Ethnicity", value: "Ethnicity"},
    {label: "Hispanic", value: "Hispanic"}]
  addedTypes = {}; // added Q types
  typesEmpty = true; // true if no question type selected
  typesFull = false; // true if every type of question selected

  // hhi
  hhiInterval = 5000;
  hhiMin = 15000;
  hhiMax = 150000;
  hhiList = [];

  // for vendor carousel
  vendorList = [];
  vendorIndexLeft = 0;
  vendorIndexRight = 1;
  leftVendorComing = false;
  rightVendorComing = false;
  swapColor: boolean = false;

  // for importing quota template
  quotaTemplateId: string;
  quotaTemplateSearch: string;
  quotaTemplates = [];
  actions = [];
  projectList = [];
  projectIds = [];


  constructor(
    private alertify: AlertifyService,
    private dropdownService: DropdownService,
    public auth: AuthService,
    private projectService: ProjectService,
    private quotaService: QuotaService,
    private userService: UserService,
    private modalService: BsModalService,
    private utils: UtilsService,
    private projectDataService: ProjectDataService) { }

  ngOnInit() {
    this.instanceId = this.auth.getInstance();
    this.s3buckPath =  "quota/" + this.project.id;

    window.scroll(0, 0);

    this.quotaEnabled = this.project.isQuotaEnabled;
    this.getQuota();

    const daysLeft = (this.project.totalDaysLeft < 0 ) ? 0 : this.project.totalDaysLeft;
    const totalDays = this.project.totalDays;
    this.daysPercentagePast = 100 - Math.round(daysLeft * 100 / totalDays)

    this.projectDataService.getVendorViewSidebar.subscribe(data => {
      if (data) {
        this.isVendorview = data != '';
      }
    });
  }

  SaveOverallN()
  {
    var fullQuota = Number(this.project.fullLaunchQuota)
    if (!Number.isNaN(fullQuota)) {
      this.projectService.UpdateProjectValue(this.project.id, 'fullLaunchQuota', fullQuota).subscribe({
        complete: () => {
          this.alertify.success('Full launch quota updated successfully.');
          this.editOverallN = false;
          this.processQuota(this.quotaDetails);
        },
        error: () => {
          this.alertify.error('There was an issue updating the full launch quota value.');
        },
      });
    }
  }

  toggleSettings() {
    if (this.auth.isClient() || this.auth.isVendor() || this.isVendorview) return;

    this.showSettings = !this.showSettings;
  }

  setToEditMode() {
    if (this.auth.isClient() || this.auth.isVendor() || this.isVendorview) return;

    this.showSettings = false;
    this.quotaDetails.quotaGroups.forEach( qg => {
      qg.formMode = 'setup'
    })
    this.formMode = 'setup';
    this.editOverallN = true;
  }

  leaveSetupMode(){
    this.getQuota();

    this.quotaDetails.quotaGroups.forEach( qg => {
      qg.formMode = 'edit'
    })
    this.newQuota = null;
    this.formMode = 'edit';
    this.editOverallN = false;
  }

  processData(data) {
    if (data.length > 0) {
      let summary = {starts: 0, completes : 0};
      data.forEach(element => {
  
        summary.completes += element.complete
        summary.starts += element.starts
      });
  
      // I also want to count the number of completes and starts per vendor
      this.vendors.forEach(vendor => {
        vendor.starts = 0;
        vendor.completes = 0;
        data.filter(x => x.partnerId === vendor.partnerId).forEach(element => {
          vendor.starts += element.starts;
          vendor.completes += element.complete;
        });
      });
      this.metrics = summary;
    }
    if(this.project.id == "d583bf3a-ab7e-441f-b1a1-07c96bb3e2e6") {
      //Dummy data for demo
      this.metrics.starts = 23513;
      this.metrics.completes = 4000;
      this.vendors.forEach(vendor => {
        if(vendor.partnerName == "Lucid Holdings, Inc") {
          vendor.starts = 10616;
          vendor.completes = 1921;
        }
        else  if(vendor.partnerName == "Cint") {
          vendor.starts = 6465;
          vendor.completes = 1217;
        }
        else  if(vendor.partnerName == "TapResearch Inc") {
          vendor.starts = 6383;
          vendor.completes = 852;
        }
        else  if(vendor.partnerName == "Prodege POD") {
          vendor.starts = 49;
          vendor.completes = 10;
        }
      });


    }
  }

  getQuota()
  {
    forkJoin([this.projectService.GetQuota(this.project.id), this.quotaService.ListQuestions()]).subscribe((data) => {
      
      this.vendors = this.project.projectSegments[0].projectSurveyPartners
      .filter(x => x.isDeleted == false)
      .sort((a, b) => a.partnerName.localeCompare(b.partnerName));

      this.processData(this.respondents);

      this.questions = {};
      this.questionIDs = [];

      data[1].forEach(q => {
        this.questions[q.id] = q;
        this.questionIDs.push(q.id);
      })

      if (data[0] != null) {
        // if (data[0].quotaGroups.filter( x=> x.starts > 0 || x.completes > 0) != null) this.formMode = 'edit'
        this.processQuota(data[0]);
      }

      if (this.quotaDetails.quotaGroups.length > 0) {
        this.updateAddedQuotaGroupList();
      };
      this.justSaved = true;
      if (this.quotaDetails.limitMode == null) this.quotaDetails.limitMode = 'start'
      this.handleQuotaChange();

    },
      error => {
        this.alertify.error('There was an error getting quota details');
      },);
  }



  processQuota(quotaDetails: ProjectQuota) {

    if(this.project.id == "d583bf3a-ab7e-441f-b1a1-07c96bb3e2e6") {
      quotaDetails.quotaGroups.forEach(qg => {
        qg.quotas.forEach(q => {
          let percent = Math.floor(Math.random() * 25 + 80)/100;
          q.completes = q.limit * percent;
          
          //random allocation to each vendor
          let arr = Array.from({length: 5}, () => Math.floor(Math.random() * 100));
          let sum = arr.reduce((a, b) => a + b, 0);
          arr = arr.map(x => Math.floor(x / sum * 100));
          let idx = 0;
          q.vendorLimits.forEach(vendor => {
            vendor.completes = Math.floor(q.completes * arr[idx++]/100);
          });
        }
        );
      }
      );
    }
    //console.log("QUOTA DETAILS:", quotaDetails);
    this.quotaDetails = quotaDetails;
    this.sort(this.quotaDetails.quotaGroups, 'order')
    var quotasWithOptionAnswers = ['Gender', 'Ethnicity', 'Hispanic']
    var zipQuotas = ['Division', 'Region'];
    var i = 0;
    this.quotaDetails.quotaGroups.filter(x => !x.isDeleted).forEach(qg => {
      qg.completes = 0;
      qg.starts = 0;

      qg.quotas.filter(x=> !x.isDeleted).forEach(q => {
        q.groupId = qg.id;
        qg.completes += q.completes;
        qg.starts += q.starts;

        var quotaValue;

        if (quotasWithOptionAnswers.includes(qg.name)) {
        
          quotaValue = q.targetGroup[qg.name.toLocaleLowerCase()];
          q.order = this.questions[qg.questionId].options.find(x => x.value === quotaValue)?.order;
        }
        else {
          if (qg.name === 'Income' && q.targetGroup.minHHI == -1) quotaValue = 'pnta';
          for (let qId in this.questions) {
            if (this.questions[qId].type === qg.name) {
              if (zipQuotas.includes(qg.name)) q.order =  this.questions[qId].defaults.find(x => x.value === q.targetGroup[qg.name.toLocaleLowerCase()]).order
            }
          }
        }

        if (quotaValue === 'pnta') {
          if (this.questions[qg.questionId].preferNotToAnswer) // if enabled in admin console
          {
            qg.preferNotToAnswerAdded = true;
          }
          else {
            q.isDeleted = true;
          }
        }

        if (q.limit == 99999) {
          q.limitN = 99999;
          q.limitPercent = 99999;
        } else {
          q.limitN = (quotaDetails.limitType === 'number') ? q.limit : Math.ceil(this.project.fullLaunchQuota * q.limit / 100);
          q.limitPercent = (quotaDetails.limitType === 'percent') ? q.limit : Math.ceil(q.limit * 100 / this.project.fullLaunchQuota);
        }
        i++;
        this.addVendorsToQuota(q);
      });

      var pntaExistsInQuotas;
      var preferNotToAnswerEnabled = this.questions[qg.questionId].preferNotToAnswer;
      if (qg.name == 'Income' || quotasWithOptionAnswers.includes(qg.name)) {
        if (quotasWithOptionAnswers.includes(qg.name)) {
          this.questions[qg.questionId].options.forEach(option => {
            if (option.value === 'pnta') {
              pntaExistsInQuotas = qg.quotas.find(q => q.targetGroup[qg.name.toLowerCase()] === option.value);
            }
          });
        }
        else { // if income
          pntaExistsInQuotas = qg.quotas.find(q => q.targetGroup.minHHI === -1);
        }

        if (!pntaExistsInQuotas && preferNotToAnswerEnabled) {
          var quota = this.blankQuota();
          quota.isDeleted = true;
          if (qg.name === 'Income') quota.targetGroup.minHHI = -1;
          else quota.targetGroup[qg.name.toLowerCase()] = 'pnta';
          quota.limitPercent = 0;
          quota.limitN = 0;
          quota.limit = 0;
          this.addVendorsToQuota(quota);
          qg.quotas.push(quota);
          qg.preferNotToAnswerAdded = false;
        }
      }

      if (qg.name === 'Income') this.sort(qg.quotas, 'minHHI');
      else if (qg.name === 'Age') this.sort(qg.quotas, 'minAge');
      else this.sort(qg.quotas, 'order');

      qg.nonValueStarts = this.metrics.starts > 0 ? (this.metrics.starts - qg.starts) || 0 : 0;
      qg.nonValueCompletes = this.metrics.completes ? (this.metrics.completes - qg.completes) || 0 : 0;

      qg.starts = qg.starts + qg.nonValueStarts;
      qg.completes = qg.completes + qg.nonValueCompletes;

    });

    if (!this.quotaDetails.limitCheck) {
      this.quotaDetails.limitCheck = 'tracking'
    }
    this.handleQuotaChange();
  }

  closeQuota() {
    this.onclose.emit(null);
  }


  handleQuotaChange() {
    if (this.newQuota != null) this.newQuota = JSON.parse(JSON.stringify(this.newQuota))
    else this.quotaDetails = JSON.parse(JSON.stringify(this.quotaDetails))

  }

  distributeAllocation() {

  }


  addQuotaGroup(type, groupName) {
    if (this.auth.isClient() || this.auth.isVendor() || this.isVendorview) return;
    else if ((type == 'Division' && this.addedTypes['Region'] != null) || (type == 'Region' && this.addedTypes['Division'] != null)) return;

    this.showSettings = false;
    const qs = [];

    for (const q in this.questions) {
      if (this.questions[q].type === type) {
        qs.push(this.questions[q]);
      }
    }

    if (qs.length === 0) {
      this.alertify.error('This Category has no questions configured');
      return;
    }
    
    const question = qs[0];

    const qg: QuotaGroup = {
      id: uuidv4(),
      isDeleted: false,
      order: this.quotaDetails.quotaGroups.filter(x => x.isDeleted == false).length,
      name: groupName,
      questionId: question.id,
      quotas: [],
      completes: 0,
      starts: 0,
      deletedOptions: [],
      preferNotToAnswerAdded: false
    };

    if (type === 'HouseholdIncome' || type === 'Age' || type === 'Region' || type === 'Division') {

      if (type === 'HouseholdIncome' || type === 'Age') {
        if (type === 'HouseholdIncome' && question.preferNotToAnswer) {
          var hasPNTA = question.options.find(x => x.value === 'pnta');
          if (hasPNTA) {
            qg.preferNotToAnswerAdded = true;
          }
        }
        this.sort(question.defaults, 'min')
      }
      else this.sort(question.defaults, 'order')

      question.defaults.forEach(option => {
        const quota: Quota = this.blankQuota();

        if (type === 'HouseholdIncome') {
          quota.targetGroup.minHHI = option.min;
          quota.targetGroup.maxHHI = option.max;
        } else if (type === 'Age') {
          quota.targetGroup.minAge = option.min;
          quota.targetGroup.maxAge = option.max;
        } else if (type === 'Region') {
          quota.targetGroup.region = option.value;
        } else if (type === 'Division') {
          quota.targetGroup.division = option.value;
        }

        quota.limitPercent = this.project.fullLaunchQuota == 0 ? 0 : option.percent;
        quota.limitN = this.project.fullLaunchQuota == 0 ? 0 : Math.ceil((this.project.fullLaunchQuota * (option.percent / 100)));
        quota.limit = ((this.quotaDetails.limitType === 'number') ? quota.limitN : quota.limitPercent)
        this.addVendorsToQuota(quota);
        qg.quotas.push(quota);
      });


    }  else {
      this.sort(question.options, 'order')
 
      question.options.forEach(option => {
        const quota: Quota = this.blankQuota()        
        quota.targetGroup[type.toLowerCase()] = option.value;

        if (option.value === 'pnta' && question.preferNotToAnswer) qg.preferNotToAnswerAdded = true; 

        const defaultItem = question.defaults.find(e => e.value === option.value);
        if (defaultItem != null) {
          quota.limitPercent = defaultItem.percent;
          quota.limitN = Math.ceil((this.project.fullLaunchQuota * (defaultItem.percent / 100)));
          quota.limit = ((this.quotaDetails.limitType === 'number') ? quota.limitN : quota.limitPercent)
        }
        if (this.quotaDetails.enableVendorLimits) {
          this.addVendorsToQuota(quota);
        }
        qg.quotas.push(quota);
      });
    }


    if (type === 'HouseholdIncome' && qg.preferNotToAnswerAdded)  {
      const quota: Quota = this.blankQuota();
      quota.targetGroup.minHHI = -1;
      quota.limitPercent = 0;
      quota.limitN = 0;
      quota.limit = 0;
      this.addVendorsToQuota(quota);
      qg.quotas.push(quota);  
    }

    this.quotaDetails.quotaGroups.forEach(group => {
      if (group.isDeleted == false) {
        group.formMode = 'disabled'
      }
    })

    qg.formMode = 'setup';
    this.formMode = 'setup';

    this.newQuota = qg
    this.handleQuotaChange();
    this.updateAddedQuotaGroupList();

  }

  blankQuota() {
    const quota: Quota = {
      id: uuidv4(),
      isDeleted: false,
      limit: 0,
      starts: 0,
      completes: 0,
      limitPercent: 0,
      limitN: 0,
      order:999,
      targetGroup: {
        id: uuidv4(),
        gender: null,
        ethnicity: null,
        hispanic: null,
        minHHI: null,
        maxHHI: null,
        minAge: null,
        maxAge: null,
        region: null,
        division: null,
        states: '',
      },
      vendorLimits: []
    };

    return quota;
  }

  removeQuotaGroup(id) {
    var index = this.quotaDetails.quotaGroups.findIndex(e => e.id === id);
    this.quotaDetails.quotaGroups[index].isDeleted = true;

    this.updateAddedQuotaGroupList();
    this.handleQuotaChange();
    this.sort(this.quotaDetails.quotaGroups, 'order')
  }

  updateAddedQuotaGroupList() {
    // Update added quotas
    this.typesEmpty = true;
    const addedTypes: any = {};
    var count = 0;

    this.quotaDetails.quotaGroups.filter(qg => qg.isDeleted === false).forEach(e => {
      if (this.questions[e.questionId]) {
        addedTypes[this.questions[e.questionId]?.type] = true;
        this.typesEmpty = false;
        count += 1
      }
    });

    if (count >= 6) this.typesFull = true;
    else this.typesFull = false;

    addedTypes.location = (addedTypes.Region == null && addedTypes.Division == null && addedTypes.State == null) ? null : true;
    this.addedTypes = addedTypes;
  }



  removeQuota(group, id) {
    group.quotas.filter(e => e.id === id).forEach(q => {
      q.isDeleted = true

    });
    this.handleQuotaChange();
  }


  enableQuota() {
    this.showSettings = false;
    this.projectService.UpdateProjectValue(this.project.id, 'isQuotaEnabled', !this.quotaEnabled).subscribe( data => {
    },
    error => {
      this.alertify.error('Failed to enable or disable Quota');
    },
    () => {
      // this.quotaEnabled = !this.quotaEnabled;
      this.project.isQuotaEnabled = !this.quotaEnabled;
      this.quotaEnabled = !this.quotaEnabled;

      if (this.quotaDetails == null && this.quotaEnabled) {
        // saving quota for the first time
        this.quotaDetails = {
          id: uuidv4(),
          limitType: 'number',
          limitMode: 'complete',
          limitCheck: 'tracking',
          enableVendorLimits: false,
          quotaGroups: []
        };

        this.saveQuota();
      }

      this.getQuota();
    });
  }

  changeVendorQuotaSetting(val) {
    this.quotaDetails.enableVendorLimits = val;
    if (this.quotaDetails.enableVendorLimits) {
      this.quotaDetails.quotaGroups.filter(e => e.isDeleted === false).forEach(qg => {
        qg.quotas.filter(e => e.isDeleted === false).forEach(q => {
          this.addVendorsToQuota(q);
        });
      });
    }
    this.handleQuotaChange();
    this.saveQuota();
  }

  addVendorsToQuota(quota) {
    const vendorLimits = [];
    var vendorAllocationTotal = this.vendors.filter(x => !x.isDeleted).reduce((sum, item) => sum + item.partnerFullQuota, 0);

    this.vendors.forEach(vendor => {
      if (!vendor.starts) vendor.starts = 0;
      if (!vendor.completes) vendor.completes = 0;
      // For every vendor on this project we add the vendor limits if specified
      const existing = quota.vendorLimits.find(v => v.vendorId === vendor.partnerId);
      
      if (existing) {
        existing.limitN = (quota.limit >= 99999 ? 99999 : (this.quotaDetails.limitType === 'number') ? existing.limit : Math.ceil(quota.limitN * existing.limit / 100));
        existing.limitPercent = (quota.limit >= 99999 ? 99999 : (quota.limit === 0) ? 0 : (this.quotaDetails.limitType === 'percent') ? existing.limit : Math.ceil(existing.limit * 100 / quota.limitN));
        vendorLimits.push(existing);

      } else {
        var vendorRatio;
        if (this.vendors.length > 1) {
          if (!vendor.partnerFullQuota) vendorRatio = 1/this.vendors.length
          else vendorRatio = vendor.partnerFullQuota/vendorAllocationTotal
        }
        else vendorRatio = 1; // if one vendor only, give it full allocation
        
        const qv: QuotaVendorLimit = {
          id: uuidv4(),
          vendorId: vendor.partnerId,
          vendorName: vendor.partnerName,
          limitN: quota.limit ? (quota.limit >= 99999 ? 99999 : Math.ceil(quota.limitN * vendorRatio) ) : 0,
          limitPercent: quota.limit ? (quota.limit >= 99999 ? 99999 : vendorRatio*100) : 0,
          limit: 0,
          completes: 0,
          starts: 0
        };
        
        qv.limit = ((this.quotaDetails.limitType === 'number') ? qv.limitN : qv.limitPercent);
        vendorLimits.push(qv);
      }
    });

    quota.vendorLimits = vendorLimits;
  }


  saveQuota(inlineSaveQuota = null) {
    if (this.formMode == 'setup' && this.newQuota != null) {
     this.quotaDetails.quotaGroups.push(this.newQuota);
     this.updateAddedQuotaGroupList();
     this.handleQuotaChange();
    }

    //we do not save the dummy project we use for demo
    if(this.project.id == "d583bf3a-ab7e-441f-b1a1-07c96bb3e2e6")
      return;

    if (!inlineSaveQuota) { //!this.overlapExists && !this.overTotalExists
      this.overlapError = false;
      this.overTotalError = false;
      var x = 0;
      this.quotaDetails.quotaGroups.forEach(qg => {
        if (qg.isDeleted == false) {
          qg.order = x;
          x ++;
        }
        else {
          qg.order = 999;
        }
      qg.quotas.forEach(q => {
        if (q.newQuota) {
          q.newQuota = false;
          q.changesMade = false;
        }
        if (this.quotaDetails.limitCheck != 'tracking only') {
          q.limit = (this.quotaDetails.limitType === 'number') ? q.limitN || 0: q.limitPercent || 0;
          q.vendorLimits.forEach(qv => {
            if (q.limit < 99999) qv.limit = (this.quotaDetails.limitType === 'number') ? qv.limitN : qv.limitPercent;
            else qv.limit = 99999
          });
        }
        q.targetGroup.minAge = (q.targetGroup.minAge == null) ? null : Number(q.targetGroup.minAge);
        q.targetGroup.maxAge = (q.targetGroup.minAge == null) ? null : Number(q.targetGroup.maxAge);
        q.targetGroup.minHHI = (q.targetGroup.minHHI == null) ? null : Number(q.targetGroup.minHHI);
        q.targetGroup.maxHHI = (q.targetGroup.maxHHI == null) ? null : Number(q.targetGroup.maxHHI);

      });
    });

    this.projectService.SaveQuota(this.project.id, this.quotaDetails).subscribe(data => {
    }, error => {
      this.alertify.error('Failed to Update Quota');
      if (this.formMode == 'setup' && this.newQuota) {
        var index = this.quotaDetails.quotaGroups.findIndex(e => e.id === this.newQuota.id);
        this.quotaDetails.quotaGroups[index].isDeleted = true;
        this.handleQuotaChange();
        }

      }, () => {
        this.projectService.UpdateProjectValue(this.project.id, 'fullLaunchQuota', this.project.fullLaunchQuota).subscribe((data) => {
        }, (error) => {
          this.alertify.error('There was an issue updating the full launch quota value.');
        });
        this.justSaved = true;
        this.formMode = 'edit';
        this.newQuota = null;
        this.editOverallN = false;
        this.alertify.success('Quota updated for this project');
        this.getQuota();

      });
      // Need to ensure Vendor limits are correct if enabled
      this.quotaDetails.quotaGroups.forEach(group => {
        group.formMode = 'edit'
      });
      this.updateAddedQuotaGroupList();
    }
    // if inline save
    else {
      var q = inlineSaveQuota;

      if (q.newQuota) {
        q.newQuota = false;
        q.changesMade = false;
      }
      if (this.quotaDetails.limitCheck != 'tracking only') {
        q.limit = (this.quotaDetails.limitType === 'number') ? q.limitN || 0: q.limitPercent || 0;
        q.vendorLimits.forEach(qv => {
          if (q.limit < 99999) qv.limit = (this.quotaDetails.limitType === 'number') ? qv.limitN : qv.limitPercent;
          else qv.limit = 99999
        });
      }
      q.targetGroup.minAge = (q.targetGroup.minAge == null) ? null : Number(q.targetGroup.minAge);
      q.targetGroup.maxAge = (q.targetGroup.minAge == null) ? null : Number(q.targetGroup.maxAge);
      q.targetGroup.minHHI = (q.targetGroup.minHHI == null) ? null : Number(q.targetGroup.minHHI);
      q.targetGroup.maxHHI = (q.targetGroup.maxHHI == null) ? null : Number(q.targetGroup.maxHHI);
      this.projectService.SaveSingleQuota(this.project.id, inlineSaveQuota).subscribe(data => {
        this.alertify.success('Quota updated for this project');
      }, (error)=> {
        this.alertify.error('Failed to Update Quota');
      });

    }
    
  }

  inlineChange(quota = null) { // triggered by event emitted from child components
    this.handleQuotaChange()

    if (this.formMode == 'edit' && quota != null) {
      this.saveQuota(quota)
    }
  }
  

  // PHASE 2 STUFF PROOF OF CONCEPTS
  interlockQuotas(destGroup, srcGroup) {
    const newGroup = destGroup;
    const newQuotas = [];
    newGroup.quotas.forEach(quota => {
      srcGroup.quotas.forEach(src => {
        const newQuota = {
          id: uuidv4(),
          limit: quota.limit, // TO DO:  UPDATE LIMIT TO BE PERCENTAGE OF OVERALL BASED ON SPLIT
          targetGroup: {
            gender: src.targetGroup.gender ?? quota.targetGroup.gender,
            ethnicity: src.targetGroup.ethnicity ?? quota.targetGroup.ethnicity,
            hispanic: src.targetGroup.hispanic ?? quota.targetGroup.hispanic,
            minHHI: src.targetGroup.minHHI ?? quota.targetGroup.minHHI,
            maxHHI: src.targetGroup.maxHHI ?? quota.targetGroup.maxHHI,
            minAge: src.targetGroup.minAge ?? quota.targetGroup.minAge,
            maxAge: src.targetGroup.maxAge ?? quota.targetGroup.maxAge
          },
          vendorLimits: []
        };
        newQuotas.push(newQuota);
      });
    });


    newGroup.questions = newGroup.questions.concat(srcGroup.questions);

    this.quotaDetails.quotaGroups = this.quotaDetails.quotaGroups.filter(e => e.id != srcGroup.id);
  }

  toggleLimitType(trigger : string = "") {
    if (trigger == "limitCheckChanged" ) {
      if (this.quotaDetails.limitCheck == 'tracking only') {
        this.quotaDetails.limitType = 'percent';
      }
    }
    else if (trigger == 'percent' && this.quotaDetails.limitType === 'number'){
      this.quotaDetails.limitType =  'percent'
    }
    else if (trigger == 'number' && this.quotaDetails.limitType === 'percent'){
      this.quotaDetails.limitType =  'number'
    }

    if (!this.auth.isClient() && !this.auth.isVendor() && !this.isVendorview) {
      if (this.formMode !== 'setup') this.saveQuota();
    }
  }

  // change which vendors are shown in carousel
  changeVendorIndex(toAdd) {

    this.swapColor = !this.swapColor;
    this.vendorIndexLeft += toAdd;
    this.vendorIndexRight += toAdd;

      if (this.vendorIndexRight >= this.vendors.length) {
        this.vendorIndexRight = 0;
      }
      if (this.vendorIndexLeft >= this.vendors.length) {
        this.vendorIndexLeft = 0;
      }

      if (this.vendorIndexLeft < 0) {
        this.vendorIndexLeft = this.vendors.length - 1
      }
      if (this.vendorIndexRight < 0) {
        this.vendorIndexRight = this.vendors.length - 1
      }

      if (toAdd < 0) {
        this.leftVendorComing = true;
      }
      else {
        this.rightVendorComing = true;
      }

      this.quotaDetails = JSON.parse(JSON.stringify(this.quotaDetails))
  }


  handleAction(event) {
    if (event.type == "import") {
      this.closeModal();
      this.updateProject(event.row)
    }
  }

  updateProject(value) {
    this.showSettings = false;
    this.quotaTemplateId = value.value
    this.cloneQuota();
  }

  // get project list to import a quota
  getProjectList = (filter) => {
    this.quotaTemplates = [
      { label: 'ID', id: 'code', sortable: false }, { label: 'Name', id: 'name', sortable: false }, { label: 'Client', id: 'client', sortable: true }, { label: 'End Date', id: 'closeDate', sortable: true }, {label: 'Import', type: 'actions-inline'}
    ];
    this.actions = [ {label: 'Import', type: 'import', icon: 'file-import'}];
    // this.dropdownService.projects(filter, '', 'survey');
    var list = this.dropdownService.getQuotaTemplates(filter, this.project.id, 'survey')
    list.forEach(element => {
      this.projectList = element
    });
    if (filter == '' || filter == null) {
      this.projectList = [];
    }
    return list
  }

  cloneQuota() {
    var oldQuotaID = this.quotaDetails.id;

    this.quotaService.ListQuestions().subscribe((questionList) => {
      this.questions = {};
      this.questionIDs = [];
      questionList.forEach(q => {
        this.questions[q.id] = q;
        this.questionIDs.push(q.id);
        if (q.type == 'Gender' || q.type == 'Ethnicity' || q.type == 'Hispanic') {
          this.sort(this.questions[q.id].options, 'order');
        }
      })

    }, error => {
      this.alertify.error('There was an error getting quota details');
    }, () => {
      this.projectService.GetQuota(this.quotaTemplateId).subscribe((quota) => {
        if (quota != null)  {
          this.processQuota(quota);
        }
        this.updateAddedQuotaGroupList();
        // give new IDs to all of the new copied quota settings
        this.quotaDetails.quotaGroups.filter(e => e.isDeleted === false).forEach(qg => {
          qg.completes = 0;
          qg.starts = 0;
          qg.id = uuidv4();
          qg.quotas.filter(e => e.isDeleted === false).forEach(q => {
            q.starts = 0;
            q.completes = 0;
            q.id = uuidv4();
            q.targetGroup.id = uuidv4();
            q.vendorLimits.forEach( v => {
              v.id = uuidv4();
              v.completes = 0;
              v.starts = 0;
            })
          });
        });

        this.quotaDetails.id = oldQuotaID;
      });
    })
  }

  openModal(modal, type) {

    if (this.auth.isClient() || this.auth.isVendor() || this.isVendorview) return;

    if (type == "onClose") {
      if (this.justSaved) {
        this.closeQuota();
      }
      else {
        this.modalType = type;
        this.openModalRef = this.modalService.show(modal, {keyboard: false, ignoreBackdropClick: true, class: 'nav-modal-style'});
      }
    }
    else if (type == 'errorSaving') {
      this.modalType = type;
      this.openModalRef = this.modalService.show(modal, {keyboard: false, ignoreBackdropClick: true, class: 'nav-modal-style'});
    }
    else if (type == 'templateClone' && this.project.fullLaunchQuota > 0) {
      this.modalType = type;
      this.openModalRef = this.modalService.show(modal, {keyboard: false, ignoreBackdropClick: true, class:'modal-lg nav-modal-style' });
    }
    else if (type == 'templateImport' && this.project.fullLaunchQuota > 0) {
      this.modalType = type;
      this.openModalRef = this.modalService.show(modal, {keyboard: false, ignoreBackdropClick: true, class:'modal-lg nav-modal-style' });
    }
  }

  closeModal(type: string = "") {
    if (type == "onClose") {
      this.closeQuota();
    }
    else {
      this.projectList = [];
    }
    this.openModalRef.hide()
  }

  sort(array, sortBy) {
    var A;
    var B
    array.sort((a, b) => {

      if (sortBy === 'order')
      {
        A = a.order;
        B = b.order;
      }
      else if (sortBy === 'min')
      {
        A = a.min
        B = b.min
      }
      else if (sortBy === 'minAge')
      {
        A = a.targetGroup.minAge
        B = b.targetGroup.minAge
      }
      else if (sortBy === 'minHHI')
      {
        A = a.targetGroup.minHHI
        B = b.targetGroup.minHHI
        if (A == -1) A =999999999999
        if (B == -1) B =999999999999
      }

      let comparison = 0;
      if (A > B) {
        comparison = 1;
      } else if (A < B) {
        comparison = -1;
      }
      return comparison;
    });
  }

  getBgColor(value): string {
    if ( this.daysPercentagePast > value) {
      return 'nav-bg-error';
    }
    else {
      return 'nav-success';
    }
  }

  navigateToLinksAndVendors() {
    this.redirectToSection.emit('links-and-vendors');
  }

  importFileUploaded(data) {
    this.importFileName = data?.origFile?.name;
  }

  importQuotaFromFile() {
    if (this.importFileName) {
      this.loadingImport = true;
      this.projectService.ImportQuotaFromFile(this.project.id, this.importFileName).subscribe((data) => {
        this.loadingImport = false;
        this.importFileName = null;
        this.openModalRef.hide();
        this.alertify.success("Quota succefully imported from file")
        this.getQuota();
      }, error => {
        this.alertify.error("Unable to import quota from file - " + error);
        this.loadingImport = false;
      }) 
    }
  }

  downloadQuotaTemplate() {
    if (this.auth.isClient() || this.auth.isVendor() || this.isVendorview) return;

    let csvContent = 'Quota Label,Attribute,Allocation\n';

    let data = [
      { label: 'Age', attribute: '18-24', allocation: 100 },
      { label: '\n', attribute: '25-34', allocation: 100 },
      { label: '\n', attribute: '35-44', allocation: 100 },
      { label: '\n', attribute: '45-54', allocation: 100 },
      { label: '\n', attribute: '55-64', allocation: 100 },
      { label: '\n', attribute: '65+', allocation: 100 },
      { label: 'Gender', attribute: 'Male', allocation: 300 },
      { label: '\n', attribute: 'Female', allocation: 300 },
      { label: 'Region', attribute: 'Northeast', allocation: 150 },
      { label: '\n', attribute: 'Midwest', allocation: 150 },
      { label: '\n', attribute: 'South', allocation: 150 },
      { label: '\n', attribute: 'West', allocation: 150 },
      { label: 'Income', attribute: '0-14999', allocation: 125 },
      { label: '\n', attribute: '25000-49999', allocation: 125 },
      { label: '\n', attribute: '50000-99999', allocation: 125 },
      { label: '\n', attribute: '100000-149999', allocation: 125 },
      { label: '\n', attribute: '150000+', allocation: 125 },
      { label: 'Ethnicity', attribute: 'White/Caucasian', allocation: 125 },
      { label: '\n', attribute: 'Black or African American', allocation: 125 },
      { label: '\n', attribute: 'Asian or Pacific Islander', allocation: 125 },
      { label: '\n', attribute: 'Native American Indian or Alaska Native', allocation: 125 },
      { label: '\n', attribute: 'Other/Prefer to Self-Describe', allocation: 125 },
      { label: 'Hispanic', attribute: 'Yes, Hispanic', allocation: 300 },
      { label: '\n', attribute: 'No, Not Hispanic', allocation: 300 },
    ];
    
    data.forEach((row) => {
      csvContent += `"${row.label}","${row.attribute}","${row.allocation}"\n`;
    });

    this.utils.generateXLSX(csvContent, 'quota-template.csv');



  }

  downloadQuota() {
    if (this.auth.isClient() || this.auth.isVendor() || this.isVendorview) return;

    let csvContent = '';
   
    // Colum headers
    csvContent = 'Quota Label,Attribute,Allocation\n'

    // Data
    const groups = this.quotaDetails?.quotaGroups.filter(x => !x.isDeleted);

    groups.forEach( (qg: QuotaGroup) => {
      csvContent += qg.name + ',';

      var i = 0;
      qg.quotas.filter(x => !x.isDeleted).forEach((quota: Quota) => {
        const targetGroup = quota.targetGroup;
        
        if (qg.name != 'Income' && qg.name != 'Age') {
          var value = targetGroup[qg.name.toLowerCase()];
          if (value != 'pnta') {
            if (i > 0) csvContent += ',' // skip quota label if not first row
            csvContent += `"${targetGroup[qg.name.toLowerCase()]}"`
            csvContent += ', ' + quota.limitN + '\n'
          }
        }
        else {
          if (qg.name == 'Income') {
            if ((targetGroup.minHHI || targetGroup.maxHHI) && targetGroup.minHHI != -1 ) {
              if (i > 0) csvContent += ',' // skip quota label if not first row
              csvContent += !targetGroup.minHHI ? 0 : targetGroup.minHHI
              if (targetGroup.maxHHI.toString()) {
                csvContent += targetGroup.maxHHI > 150000 ? '+' : '-' + targetGroup.maxHHI;
              }
              csvContent += ', ' + quota.limitN + '\n'
            }
          }
          else if (targetGroup.minAge.toString()) {
            if (i > 0) csvContent += ',' // skip quota label if not first row
            csvContent += (targetGroup.minAge + (targetGroup.maxAge.toString() ? '-' + targetGroup.maxAge : ''));
            csvContent += ', ' + quota.limitN + '\n'
          }
        }
        i++;
      })
      
    });

    this.utils.generateXLSX(csvContent, this.project.projectCode+'-quota-allocation.csv');
  }

  enableEditOfOverall() {
    if (this.auth.isClient() || this.auth.isVendor() || this.isVendorview) return;
    else this.editOverallN = true
  }

  endInline(quota, group) {
    if (quota.edit == true) {
      var blankQuota = false;
      if (group.name === 'Income' || group.name === 'Age') {
        if (group.name === 'Income') {
          if (!quota.targetGroup.minHHI && !quota.targetGroup.maxHHI && !quota.limitN && !quota.limitPercent) {
            blankQuota = true;
          }
        }
        else if (group.name === 'Age') {
          if (!quota.targetGroup.minAge && !quota.targetGroup.maxAge && !quota.limitN && !quota.limitPercent) {
            blankQuota = true;
          }
        }
        if (blankQuota && quota.newQuota) {
          group.quotas = group.quotas.filter(e => e.id != quota.id);
        }
      }
      quota.edit = false;
      quota.vendorLimits.forEach(vq => {
        vq.edit = false;
      });

      if (quota.changesMade && !(blankQuota && quota.newQuota)) this.saveQuota(quota);
    }
  }
  

  removeInlineEdit() {
    // for each row make quota.edit = false
    var inlineEditQuota = null;
    this.quotaDetails.quotaGroups.forEach(qg => {
      qg.quotas.forEach(q => {
        if (q.edit == true) {
          inlineEditQuota = q;
        }
        this.endInline(q, qg);

      });
    })
    //if (inlineEditQuota && inlineEditQuota.newQuota) this.saveQuota();
    this.quotaDetails = JSON.parse(JSON.stringify(this.quotaDetails));
  }

  clickedOnQuotaRow(quota) {
    if (this.formMode == 'setup') return;

    if (quota.edit == true) return; // if already in edit mode, do nothing
    this.quotaDetails.quotaGroups.forEach(qg => {
      qg.quotas.forEach(q => {
        if (q.id === quota.id) {
          q.edit = true;
          q.vendorLimits.forEach(vendor => {
            vendor.edit = true;
          });
        }
        else {
          this.endInline(q, qg);
        }
      });
    });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.formMode == 'setup') return;
    const clickedElement = event.target as HTMLElement;
    if (clickedElement.classList.contains('clickable') || clickedElement.classList.contains('carousel')) {
      return;
    }
    else {
      if (this.quotaDetails?.quotaGroups) this.removeInlineEdit();
    }
  }
}
