import { v4 as uuidv4 } from 'uuid';
import { Injectable} from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Note } from 'core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class NotesService {

  baseUrl = environment.apiEndpoint + '/notes/';

  private messageSource = new BehaviorSubject<string>('');
  currentMessage = this.messageSource.asObservable();

  constructor(private http: HttpClient) {  }

  getProjectNotes(projectId: string) {
    return this.http.get<Note[]>(this.baseUrl + projectId);
  }
  getProjectNotesForSummary(bidNumber: string) {
    return this.http.get<Note[]>(this.baseUrl + 'summary/' + encodeURI(bidNumber));
  }

  addNote(note: Note) {
    return this.http.post<Note>(this.baseUrl, note);
  }

  editNote(model: any): Observable<any> {
    return this.http.put(this.baseUrl  + model.id, model);
  }

  deleteNote(id: uuidv4) {
    return this.http.delete(this.baseUrl + id);
  }
}
