import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-project-homepage-dates',
  templateUrl: './project-homepage-dates.component.html',
  styleUrls: ['./project-homepage-dates.component.scss']
})
export class ProjectHomepageDatesComponent implements OnInit {

  @Input() public set project(data) {
    this._project = data;
    console.log(data);
  }

  @Output() callback = new EventEmitter();

  _project:any = {};

  constructor() { }

  ngOnInit() {

  }

  openSettings(type) {
    this.callback.emit({section: 'settings', type: type});
  }

}
