import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
@Injectable()
export class CacheInterceptor implements HttpInterceptor {
    cache$ = new Map();

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      if(req.method === 'GET' && req.headers.get('cache') === '1' ) {

          // TO DO: HANDLE IF PARAMS IN BODY BUT FOR GET THIS SHOULD BE OK IF IN URL
          const refresh = req.headers.get('refresh') == '1';

          if(!refresh) {
              const cached = this.cache$.get(req.url);
              if (cached) {
                  return of(cached);
              }
          }

          return next.handle(req).pipe(
          tap(evt => {
              if (evt instanceof HttpResponse) {
                if (evt.body && evt.ok) {
                  this.cache$.set(req.url, evt)
                }
              }
          }))
      }
      else {
          return next.handle(req);
      }
    }

    clearCache(){

    }
}
