<div class="modal-header text-white">
  <p class="h4 position-absolute">Vendor Contacts to Notify</p>
  <button type="button" class="close pull-right text-white" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="card-body scrollbar overflow-y">
    <ng-container *ngIf="vendors?.length > 0">
      <ng-container *ngFor="let vendor of checkVendorIsDeleted(vendors)">
        <div class="row mb-3 d-flex align-items-center" *ngIf="!addContactMode">
          <div class="col">{{ vendor.vendorName }}</div>
          <ng-container *ngIf="!vendor.accepted">
            <div class="col px-1 d-flex">
              <or-type-ahead
                [id]="vendor.id"
                [(ngModel)]="selectedPartnerContacts[vendor.partnerId]"
                name="selectedPartnerContacts[vendor.partnerId]"
                [labelField]="'name'"
                [valueField]="'id'"
                (click)="setVendorIdToGetList(vendor.partnerId)"
                [multi]="true"
                [containerBody]="true"
                class="form-control-nav"
                [dataCallback]="getPartnerContactList"
                (valueChange)="updatePartnerContacts(vendor.partnerId, $event)"
                placeholder="Search Contact"
                [selectedValues]="selectedPartnerContacts[vendor.partnerId]">
              </or-type-ahead>
              <!-- Add New Contact -->
              <div class="d-flex align-items-center">
                <a (click)="addNewContact(vendor.partnerId)" class="px-1">
                  <i class="fa fa-plus or-secondary cursor-pointer"></i>
                </a>
              </div>
            </div>
            <div class="col-3 px-1">
              <select class="form-control form-control-sm form-control-nav inline" [(ngModel)]="vendor.action">
                <option>Send</option>
                <option>NA</option>
                <option>Don't Send</option>
                <option>Confirmed</option>
              </select>
            </div>
          </ng-container>
          <div class="col-2 px-1" *ngIf="vendor.accepted && vendor.latestDecisionStatus === 'Accepted'">
            <small class="or-title text-right">
              <div class="alert alert-success text-center p-1 mb-0" role="alert">Accepted</div>
            </small>
          </div>
          <div class="col-2 px-1" *ngIf="vendor.accepted && vendor.latestDecisionStatus === 'Auto-Accepted'">
            <small class="or-title text-right">
              <div class="alert alert-success text-center p-1 mb-0" role="alert">Auto-Accepted</div>
            </small>
          </div>
          <div class="col-2 px-1" *ngIf="vendor.accepted && vendor.latestDecisionStatus === 'Confirmed'">
            <small class="or-title text-right">
              <div class="alert alert-success text-center p-1 mb-0" role="alert">Confirmed</div>
            </small>
          </div>
        </div>
        <!-- New Contact Card -->
        <div class="row my-1" *ngIf="addContact[vendor.partnerId]">
          <div class="col-12">
              <div class="overlay-contact">
                <or-contact-card [contact]="newContact[vendor.partnerId]" mode="new" (new)="saveNewContact(vendor.partnerId)" (cancelled)='cancelNewContact(vendor.partnerId)'></or-contact-card>
              </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="card-footer or-title d-flex pb-0" *ngIf="!allAccepted && !addContactMode" >
    <div class="ml-auto">
      <or-spinner-button label="Submit" #spinner customClass="btn-secondary nav-btn-accent text-white float-right btn-sm ml-1" showSpinnerOnCallback=true (callback)="sendEmails()"></or-spinner-button>
    </div>
  </div>
</div>
