import { Component, OnInit, ViewChild } from '@angular/core';
import {ReportsService } from 'core';

@Component({
  selector: 'app-vendor-report',
  templateUrl: './vendor-report.component.html',
  styleUrls: ['./vendor-report.component.scss']
})
export class VendorReportComponent implements OnInit {
  iframeUrl = '';
  reportId = '177';
  @ViewChild('myIframe') public myIframe;

  constructor(private reportService: ReportsService) { }

  ngOnInit() {
    this.loadDashboard();
   
  }
  loadDashboard(){
    this.reportService.GenerateMetabaseEmbedToken(this.reportId, '').subscribe( data => {
      this.iframeUrl = data;
      const iframe = this.myIframe.nativeElement;
      if (iframe != null) {
        iframe.src = this.iframeUrl;
      }
     }, error => {
 
     });
  }
}
