@if (instanceService.siteSections.length!=0) {
  <nav id="navbar" class="fixed-top navbar p-0 overflow-x scrollbar">
    <div class="mx-4 w-100 d-flex align-items-center" style="min-width: 1115px">
      <!-- LOGO -->
      <a class="cursor-pointer mr-4"  routerLink="{{defaultSectionUrl}}" title="OpinionRoute - Navigator">
        <img height="32" [src]="logoSrc" />
      </a>
      <!-- Site Sections -->
      <ul class="mx-0 nav row align-items-center">
        @for (section of instanceService.siteSections; track section) {
          @if (section.children.length == 0) {
            <li class="cursor-pointer grey5 menu-item" [class.active]="currentSection && (section.label == currentSection || section.siteLabel == currentSection)"
              [routerLink]="section.url">
              {{ section.label }}
            </li>
          }
          @if (section.children.length > 0) {
            <li dropdown #dropdown="bs-dropdown" dropdownToggle container="body" aria-controls="dropdown-basic" class="nav-item grey5 cursor-pointer menu-item has-dropdown position-relative d-flex align-items-center"
              [class.active]="dropdown.isOpen || (currentSection && (section.label == currentSection || section.siteLabel == currentSection))"
              (click)="navbarDropdownOpen = dropdown.isOpen ? null : dropdown;">
              {{ section.label }}
              <i  class="fak fa-down-arrow fa-xs"></i>
              <div class="menu-navbar-dropdown dropdown-menu position-absolute" role="menu" *dropdownMenu>
                <div class="menu-container scrollbar">
                  @for (child of section.children; track child) {
                    <div class="d-flex align-items-center menu-item-child cursor-pointer" [routerLink]="child.url" >
                      <div class="{{child.icon}} icon grey4"></div>
                      <div class="menu-item-child-text text-truncate">{{ child.label }}</div>
                    </div>
                  }
                </div>
              </div>
            </li>
          }
        }
        @if (isProjectEnable && auth.isInternal() && !isVendorView && !isClientView) {
          <li (click)="openCreateProjectDialog()" class="start-project text-white cursor-pointer">
            <div class="px-2 d-flex align-items-center">
              <i  class="fas fa-plus fa-xs pr-1"></i>
              <div class="create-project-text">Create Project</div>
            </div>
          </li>
        }
      </ul>
      <div class="d-flex align-items-center ml-auto">
        <!-- GLOBAL SEARCH -->
        @if (isProjectEnable) {
          <div class="ml-auto global-search-container">
            <or-global-search [labelField]="'projectName'" [dataCallback]="globalSearch" (valueChange)="selectedSearch($event)"></or-global-search>
          </div>
        }
        <!-- NOTIFICATIONS - for now we only show if projects enabled-->
        @if (auth.getUser().access.projectEnable) {
          <or-notifications></or-notifications>
        }
        @if (showHelp) {
          <or-help (closeHelp)="showHelp=false"></or-help>
        }
        <!-- User Info -->
        <span class="nav-link d-flex align-items-center">
          <div (click)="showHelp = !showHelp">
            <img width="32" height="32" src="assets/imgs/help.png" />
          </div>
        </span>
        <a type="button" class="avatar d-flex align-items-center" (click)="dropdown.toggle(true)">
          <or-profile-image [url]="this.auth.getUser()?.image" [name]="this.auth.getUser()?.name" showInitials="true"></or-profile-image>
          <span class="menu"><i class="far fa-solid fa-bars grey5"></i></span>
          @if (supportStatus === 'on') {
            <span class="supportOn" [tooltip]="'Support access on until ' + supportExpiresOn + ' UTC'+ supportRequest?.accessExpiresTimeZone"
            container="body" placement="top" containerClass="tooltip-supportOn"></span>
          }
        </a>
        <!-- User profile dropdown menu -->
        <div class="profile-dropdown" dropdown #dropdown="bs-dropdown">
          <ul class="dropdown-menu position-relative grey4 cursor-pointer" *dropdownMenu role="menu" aria-labelledby="button-triggers-manual" (mouseleave)="dropdown.hide()">
            <a class="dropdown-item" [routerLink]="['/changepassword']" href="#" (click)="false"><i class="fas fa-key or-secondary"></i> Change Password</a>
            <a class="dropdown-item" [routerLink]="['/account']" href="#" (click)="false"><i class="fas fa-user-circle or-secondary"></i> User Settings</a>
            @if (auth.isInternal()) {
              <a class="dropdown-item" [routerLink]="['/reports/audit']" href="#" (click)="false"><i class="fa-file-alt far"></i> Audit Log</a>
            }
            @if (isSuperAdmin) {
              <a class="dropdown-item" (click)="openImpersonate(modalImpersonate)"><i class="fas fas fa-user-secret or-secondary"></i> Impersonate</a>
            }
            @if (auth.isAdmin()) {
              <a class="dropdown-item" href="#" (click)="goToAdminConsole($event)"><i class="fas fa-cog or-secondary"></i> Admin Console</a>
            }
            <a class="dropdown-item" (click)="logout()"><i class="fas fa-sign-out-alt or-secondary"></i> Log Out</a>
          </ul>
        </div>
      </div>
    </div>
  </nav>
}


<!-- MODAL Impersonate -->
<ng-template #modalImpersonate>
  <div class="modal-header">
    <p class="h5">Impersonate Users</p>
    <button type="button" class="close pull-right text-white" aria-label="Close" (click)="openModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div  class="float-right ml-2">
      <input [(ngModel)]="impersonateFilterText" placeholder="Filter by Name or Email" type="search" class="form-control">
    </div>
    <div class="mt-0">
      <or-list [items]="impersonateUsers | filter : impersonateFilterText : ['name', 'email']" [layout]="[
      { label: 'Instance', id: 'instanceName', sortable: true },
      { label: 'Name', id: 'name', selectable: true, sortable: true },
      { label: 'Email', id: 'email', sortable: true },
      { label: 'User Type', id: 'userType', sortable: true }
    ]" (selected)="impersonate($event)"></or-list>
    </div>
  </div>
</ng-template>


<!-- Modal New Project-->

<ng-template #modal>
  @if (auth.isInternal()) {
    @if (projectCreateStep == 1) {
      <div class="modal-header d-flex align-items-center justify-content-center">
        <div class="header-grid w-100 position-relative">
          <div class="d-flex justify-content-center align-content-center mt-3">
            <h2 class="nav-secondary mb-0 font-weight-bold">Create a project</h2>
          </div>
          <div class="d-flex justify-content-end align-content-center position-absolute w-100 sticky-top">
            <i class="fas fa-close grey4" (click)="closeCreateProjectDialog()"></i>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col mx-4">
            <div class="row mb-3 mt-4 nav-font16 font-weight-bold grey5">
              Step 1: Select your project type
            </div>
            <div class="row mb-5" [ngClass]="{'mt-2': projectTypes?.length <= 2, 'mt-3': projectTypes?.length > 2}">
              <div class="col px-0" [ngClass]="{'d-flex' : projectTypes?.length <= 2, 'projectTypes scrollbar overflow-y py-3': projectTypes?.length > 3}">
                @if (projectTypes?.length <= 2) {
                  @for (type of projectTypes; track type; let idx = $index) {
                    @if (idx === 0) {
                      <div class="nav-border1 mr-4 nav-rounded d-flex align-items-center justify-content-center cursor-pointer" style="height: 237px; width: 232px"
                        [class.active-option]="type?.selected" (click)="selectProjectType(type, 'quick');">
                        <div class="col-12">
                          <div class="text-center">
                            <img class="" src="/assets/imgs/nav-quantitative.png" />
                          </div>
                          <div>
                            <div class="col mt-3 px-0">
                              <span class="d-block nav-font16 font-weight-bold mb-1" [ngClass]="type?.selected ? 'grey6' : 'grey5'">{{ type.name }}</span>
                              <span class="d-block nav-font14" [ngClass]="type?.selected ? 'grey5' : 'grey4'">{{ type.description }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    @if (idx === 1) {
                      <div class="nav-border1 mr-4 nav-rounded d-flex align-items-center justify-content-center cursor-pointer" style="height: 237px; width: 232px"
                        [class.active-option]="type?.selected" (click)="selectProjectType(type, 'quick')">
                        <div class="col-12">
                          <div class="text-center">
                            <img class="" src="/assets/imgs/nav-qualitative.png" />
                          </div>
                          <div>
                            <div class="col mt-3 px-0">
                              <span class="d-block nav-font16 font-weight-bold mb-1" [ngClass]="type?.selected ? 'grey6' : 'grey5'">{{ type.name }}</span>
                              <span class="d-block nav-font14" [ngClass]="type?.selected ? 'grey5' : 'grey4'">{{ type.description }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  }
                  <div #cloneOption class="nav-border1 mr-4 nav-rounded d-flex align-items-center justify-content-center cursor-pointer" style="height: 237px; width: 232px"
                    [class.active-option]="createType =='clone'" (click)="selectProjectType(null, 'clone')" (ngModuleChange)="cloneOption.blur()">
                    <div class="col-12">
                      <div class="text-center">
                        <img class="" src="/assets/imgs/nav-screenshot.png" />
                      </div>
                      <div>
                        <div class="col mt-3 px-0">
                          <span class="d-block nav-font16 font-weight-bold mb-1" [ngClass]="createType =='clone' ? 'grey6' : 'grey5'">Clone an existing project</span>
                          <span class="d-block nav-font14" [ngClass]="createType =='clone' ? 'grey5' : 'grey4'">Save time by cloning a pre-existing project. You can modify the settings after cloning.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                @if (projectTypes?.length > 2) {
                  @for (type of projectTypes; track type; let idx = $index) {
                    <div class="row mb-3 mx-2">
                      <div class="col d-flex">
                        <div class="mr-3">
                          <input type="radio" name="projectType" (change)="selectProjectType(type, 'quick')">
                        </div>
                        <div>
                          <span class="d-block nav-font16 grey6" style="font-weight: 500;">{{ type.name }}</span>
                          <span class="d-block nav-font14 grey4">{{ type.description }}</span>
                        </div>
                      </div>
                    </div>
                  }
                  <div class="row mx-2">
                    <div class="col d-flex">
                      <div class="mr-3">
                        <input type="radio" name="projectType"  (change)="selectProjectType(null, 'clone')">
                      </div>
                      <div>
                        <span class="d-block nav-font16 grey6">Clone an existing project</span>
                        <span class="d-block nav-font14 grey4">Save time by cloning a pre-existing project. You can modify the settings after cloning.</span>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
            @if (createType=='quick') {
              <div class="row mb-3 nav-font16 font-weight-bold grey5">
                Step 2: Enter your project's details
              </div>
              <div class="row mb-3">
                <div class="col-12 col-md-2 px-0">
                  <label class="required nav-font14 grey4 mt-2">Project Name</label>
                </div>
                <div class="col-12 col-md-10 d-flex align-content-center">
                  <input
                    [(ngModel)]="_newProject.projectName"
                    class="form-control form-control-sm form-control-nav"
                    name="projectName"
                    placeholder="Type project name"
                    required>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-12 col-md-2 px-0">
                    <label class="nav-font14 grey4 mt-2">Project Number</label>
                  </div>
                  <div class="col-12 col-md-10 d-flex align-content-center">
                    <input
                      [(ngModel)]="_newProject.bidNumber"
                      class="form-control form-control-sm form-control-nav"
                      name="bidNumber"
                      placeholder="Assign a project number">
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-12 col-md-2 px-0">
                      <label class="nav-font14 grey4 mt-2" [class.required]="isORInstance">Client</label>
                    </div>
                    <div class="col-12 col-md-10 d-flex">
                      <or-type-ahead [labelField]="'name'" [valueField]="'value'" placeholder="Type to search client" dropdownMode=true
                        class="form-control-sm form-control-nav w-100 px-0" [dataCallback]="getClients" (valueChange)="assignClient($event)">
                      </or-type-ahead>
                    </div>
                  </div>
                }
                @if (createType=='clone') {
                  <div class="row mb-3 nav-font16 font-weight-bold grey5">
                    Step 2: Select an existing project to clone
                  </div>
                  <div class="row mb-3">
                    <div class="col d-flex align-content-center px-0">
                      <div class="input-group copy-search">
                        <div class="input-group-prepend">
                          <div class="input-group-text border-right-0">
                            <i class="fas fa-search grey4 cursor-default"></i>
                          </div>
                        </div>
                        <input type="search" #cloneProjectList
                          [(ngModel)]="asyncSearch"
                          (ngModelChange)="getMatches()"
                          matches="[]"
                          placeholder="Search project name or project number"
                          class="form-control form-control-sm cloneProjectDropdown"
                          autocomplete="off"
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col px-0">
                        <div class="scrollbar overflow-y cloneProjectList" style="height: 400px;">
                          <table class="table table-hover mb-0">
                            <thead>
                              <tr class="grey6 nav-font14 font-weight-bold nav-bg-snow-white">
                                <th scope="col" class="px-4 border-bottom-0 border-top-0">Project name</th>
                                <th scope="col" class="px-4 border-bottom-0 border-top-0">ProjectID</th>
                                <th scope="col" class="px-4 border-bottom-0 border-top-0">Client</th>
                                <th scope="col" class="px-4 border-bottom-0 border-top-0">Start Date</th>
                                <th scope="col" class="px-4 border-bottom-0 border-top-0">End Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              @for (match of matches; track match; let idx = $index) {
                                <tr class="grey5 nav-font14 cursor-pointer"
                                  (click)="selectProjectToClone(match, $event)">
                                  <th scope="row" class="px-4 nav-accent-blue font-weight-normal" [ngClass]="match?.id == projectToClone?.id ? 'selectedMatch selectedLeft' : ''" [class.border-top-0]="idx == 0">{{match?.projectName}}</th>
                                  <td class="px-4" [ngClass]="match?.id == projectToClone?.id ? 'selectedMatch' : ''" [class.border-top-0]="idx == 0">{{match?.projectCode}}</td>
                                  <td class="px-4 " [ngClass]="match?.id == projectToClone?.id ? 'selectedMatch' : ''" [class.border-top-0]="idx == 0">{{match?.clientName}}</td>
                                  <td class="px-4 " [ngClass]="match?.id == projectToClone?.id ? 'selectedMatch' : ''" [class.border-top-0]="idx == 0">{{match?.projectStartDate | date: 'MM/dd/yyyy' }}</td>
                                  <td class="px-4 " [ngClass]="match?.id == projectToClone?.id ? 'selectedMatch selectedRight' : ''" [class.border-top-0]="idx == 0">{{match?.projectEndDate | date: 'MM/dd/yyyy' }}</td>
                                </tr>
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="row d-flex w-100" >
                <div class="col text-right pr-0">
                  <button class="btn nav-btn-primary mx-2 shadow-none font-weight-bold" (click)="closeCreateProjectDialog()">Cancel</button>
                  @if (createType=='quick') {
                    <button class="btn nav-btn-accent shadow-none font-weight-bold" [ngClass]="creatingProject ? 'nav-btn-disabled' : (!_newProject.projectName || !projectType ? 'nav-btn-disabled' : 'nav-btn-accent')" [attr.disabled]="creatingProject ? true : (!_newProject.projectName || !projectType ? true : null)" (click)="createProjectNext();">Next</button>
                  }
                  @if (createType=='clone') {
                    <button class="btn nav-btn-accent shadow-none font-weight-bold" [ngClass]="creatingProject ? 'nav-btn-disabled' : (!projectToClone ? 'nav-btn-disabled' : 'nav-btn-accent')" [attr.disabled]="creatingProject ? true : (!projectToClone ? true : null)" (click)="createProjectNext();">Next</button>
                  }
                </div>
              </div>
            </div>
          }
        }

        @if (projectCreateStep == 2) {
          <div class="modal-header d-flex align-items-center justify-content-center">
            <div class="header-grid w-100 position-relative">
              <div class="d-block text-center mt-3">
                <h2 class="nav-secondary mb-0 font-weight-bold">Create a project</h2>
              </div>
              <div class="d-flex justify-content-end align-content-center position-absolute w-100 sticky-top">
                <div class="grey4 mr-auto cursor-pointer" (click)="createProjectBack();"><i class="fak fa-lg fa-left-arrow" ></i> Back</div>
                <i class="fas fa-close grey4 cursor-pointer" (click)="closeCreateProjectDialog()"></i>
              </div>
            </div>
          </div>
          <div class="modal-body nav-font14">
            <div class="row">
              <div class="col mx-4">
                @if (createType=='clone') {
                  <div class="text-center row mb-3 grey5 mt-4 nav-font16 font-weight-bold">
                    Step 3: Update your project's details
                  </div>
                  <div class="row mb-3">
                    <div class="col-12 col-md-3 px-0">
                      <label class="nav-font14 required grey4 mt-2">Project Name</label>
                    </div>
                    <div class="col-12 col-md-9 d-flex align-content-center pr-0">
                      <input
                        [(ngModel)]="_newProject.projectName"
                        class="form-control form-control-sm form-control-nav"
                        name="projectName"
                        placeholder="Type project name"
                        required>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12 col-md-3 px-0">
                        <label class="nav-font14 grey4 mt-2">Project Number</label>
                      </div>
                      <div class="col-12 col-md-9 d-flex align-content-center pr-0">
                        <input
                          [(ngModel)]="_newProject.bidNumber"
                          class="form-control form-control-sm form-control-nav"
                          name="bidNumber"
                          placeholder="Type project number">
                        </div>
                      </div>
                      <div class="row mb-3 pb-3">
                        <div class="col-12 col-md-3 px-0">
                          <label class="nav-font14 grey4 mt-2" [class.required]="isORInstance">Client</label>
                        </div>
                        <div class="col-12 col-md-9 d-flex pr-0">
                          <or-type-ahead [labelField]="'name'" [valueField]="'value'" placeholder="Type to search client" dropdownMode=true [(ngModel)]="cloneProjectClient"
                            class="form-control-sm form-control-nav w-100 px-0" [dataCallback]="getClients" (valueChange)="assignClient($event)">
                          </or-type-ahead>
                        </div>
                      </div>
                      <div class="text-center row mb-3 grey5 mt-4 nav-font16 font-weight-bold">
                        Step 4: Select project details to copy from the cloned project
                      </div>
                      <div class="row mb-3">
                        <div class="or-checkbox pl-0 pr-0 d-flex justify-content-start col-1">
                          <input type="checkbox" name="allCopyOptionsSelected" id="allCopyOptionsSelected" [(ngModel)]="allCopyOptionsSelected" (change)="selectAllCopyOptions($event.target.checked)">
                          <label for="allCopyOptionsSelected"></label>
                        </div>
                        <div class="col-10 nav-secondary pl-0 font-weight-bold">Select all</div>
                      </div>
                      @if (this.projectToClone.hasSchedule) {
                        <div class="row mb-2">
                          <div class="or-checkbox pl-0 pr-0 d-flex justify-content-start col-1">
                            <input type="checkbox" name="copySchedule" id="copySchedule" [(ngModel)]="_newProject.copySchedule" (change)="checkIfAllSelected($event.target.checked)">
                            <label for="copySchedule"></label>
                          </div>
                          <div class="col-5 px-0">Schedule - Select a start date</div>
                          <div class="col-6 px-0">
                            <input type="text" autocomplete="off" placeholder="MM/DD/YYYY" [(ngModel)]="_newProject.projectStartDate_dt" [bsValue]="_newProject.projectStartDate_dt" class="form-control form-control-sm form-control-nav bg-white" bsDatepicker readonly
                              [bsConfig]="{ dateInputFormat: 'MMM Do, YYYY', isAnimated: true, isOpen: true, showWeekNumbers:false, customTodayClass: 'todays-date' }">
                            </div>
                          </div>
                        }
                        <div class="row mb-3">
                          <div class="or-checkbox pl-0 pr-0 d-flex justify-content-start col-1">
                            <input type="checkbox" name="copyVendors" id="copyVendors" [(ngModel)]="_newProject.copyVendors" (change)="checkIfAllSelected($event.target.checked)">
                            <label for="copyVendors"></label>
                          </div>
                          <div class="col-10 pl-0">Vendors</div>
                        </div>
                        <div class="row mb-3">
                          <div class="or-checkbox pl-0 pr-0 d-flex justify-content-start col-1">
                            <input type="checkbox" name="copySurveyLinks" id="copySurveyLinks" [(ngModel)]="_newProject.copySurveyLinks" (change)="checkIfAllSelected($event.target.checked)">
                            <label for="copySurveyLinks"></label>
                          </div>
                          <div class="col-10 pl-0">Survey Links</div>
                        </div>
                        <div class="row mb-3">
                          <div class="or-checkbox pl-0 pr-0 d-flex justify-content-start col-1" [class.disabled-check]="this.projectToClone.hasQuotasSetup == false">
                            <input type="checkbox" name="copyQuotas" id="copyQuotas" [disabled]="this.projectToClone.hasQuotasSetup == false" [(ngModel)]="_newProject.copyQuotas" (change)="checkIfAllSelected($event.target.checked)">
                            <label for="copyQuotas" [tooltip]="this.projectToClone.hasQuotasSetup == false ? 'Quotas are not able to be cloned because the job being duplicated did not have quotas added.' : null" placement="right"></label>
                          </div>
                          <div class="col-10 pl-0">Quotas
                            <i class="far fa-info-circle opacity-40 or-secondary nav-font12 cursor-default ml-1" tooltip="Vendors must also be cloned if vendor-level quotas are required to be cloned into the new project." placement="bottom"></i>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="or-checkbox pl-0 pr-0 d-flex justify-content-start col-1">
                            <input type="checkbox" name="copyTeam" id="copyTeam" [(ngModel)]="_newProject.copyTeam" (change)="checkIfAllSelected($event.target.checked)">
                            <label for="copyTeam"></label>
                          </div>
                          <div class="col-10 pl-0">Team members</div>
                        </div>
                        <div class="row mb-3">
                          <div class="or-checkbox pl-0 pr-0 d-flex justify-content-start col-1">
                            <input type="checkbox" name="copyDedupeProjects" id="copyDedupeProjects" [(ngModel)]="_newProject.copyDedupeProjects" (change)="checkIfAllSelected($event.target.checked)">
                            <label for="copyDedupeProjects"></label>
                          </div>
                          <div class="col-10 pl-0">De-duplicated project(s) list</div>
                        </div>
                        <div class="row mb-3">
                          <div class="or-checkbox pl-0 pr-0 d-flex justify-content-start col-1">
                            <input type="checkbox" name="includeSourceProjectInDedupe" id="includeSourceProjectInDedupe" [(ngModel)]="_newProject.includeSourceProjectInDedupe" (change)="checkIfAllSelected($event.target.checked)">
                            <label for="includeSourceProjectInDedupe"></label>
                          </div>
                          <div class="col-10 pl-0">Include this cloned project in the de-duplication project list</div>
                        </div>
                      }
                      @if (createType=='quick') {
                        <div class="text-center row mb-3 grey5 mt-4 nav-font16 font-weight-bold">
                          Step 3: Enter your project's information
                        </div>
                        <div class="row nav-font14 my-4">
                          <div class="col d-flex align-items-center grey4">Primary Project Manager</div>
                          <div class="col">
                            <or-type-ahead
                              name="projectManager"
                              [selectedValues]="currentUser"
                              (valueChange)="assignPM($event)"
                              [labelField]="'name'"
                              [valueField]="'id'"
                              [dataCallback]="getUserList"
                              placeholder="Type to search PM"
                              class="form-control-nav px-0 nav-font14">
                            </or-type-ahead>
                          </div>
                        </div>
                        <div class="row nav-font14 my-4">
                          <div class="col d-flex align-items-center grey4"><label class="mr-2" [class.required]="isORInstance">Start Date</label></div>
                          <div class="col">
                            <div class="input-group form-control-date">
                              <input
                                type="text" autocomplete="off"
                                name="projectStartDate"
                                readonly
                                [(ngModel)]="_newProject.projectStartDate"
                                bsDatepicker
                                placeholder="Select"
                                #dpStartDate="bsDatepicker"
                                [bsConfig]="{ dateInputFormat: 'MMM Do, YYYY', isAnimated: true, isOpen: true, showWeekNumbers:false, customTodayClass: 'todays-date' }"
                                class="form-control nav-font14 date d-inline bg-white px-2">
                                <span class="input-group-text bg-white" (click)="dpStartDate.toggle()"><i class="fak fa-calendar grey4"></i></span>
                              </div>
                            </div>
                          </div>
                          <div class="row nav-font14 my-4">
                            <div class="col d-flex align-items-center grey4"><label class="mr-2" [class.required]="isORInstance">Deadline</label></div>
                            <div class="col">
                              <div class="input-group form-control-date">
                                <input
                                  type="text" autocomplete="off"
                                  name="projectEndDate"
                                  readonly
                                  [(ngModel)]="_newProject.projectEndDate"
                                  bsDatepicker
                                  placeholder="Select"
                                  #dpEndDate="bsDatepicker"
                                  [bsConfig]="{ dateInputFormat: 'MMM Do, YYYY', isAnimated: true, isOpen: true, showWeekNumbers:false, customTodayClass: 'todays-date' }"
                                  class="form-control nav-font14 date d-inline bg-white px-2">
                                  <span class="input-group-text bg-white" (click)="dpEndDate.toggle()"><i class="fak fa-calendar grey4"></i></span>
                                </div>
                              </div>
                            </div>
                            <div class="row nav-font14 my-4">
                              <div class="col d-flex align-items-center grey4"><label class="mr-2" [class.required]="isORInstance">Completes</label>
                              <span data-html="true" tooltip="The total goal of survey completes">
                                <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
                              </span>
                            </div>
                            <div class="col">
                              <input
                                [(ngModel)]="_newProject.fullLaunchQuota"
                                placeholder="Type number of completes"
                                class="form-control form-control-sm form-control-nav"
                                name="fullLaunchQuota"
                                mask="99999"
                                [patterns]="integerPattern">
                              </div>
                            </div>
                            <div class="row nav-font14 my-4">
                              <div class="col d-flex align-items-center grey4"><label class="mr-2" [class.required]="isORInstance">Length of Interview</label>
                              <span data-html="true" tooltip="The median length of interview is the estimated amount of time it takes for a participant to begin and end a survey">
                                <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
                              </span>
                            </div>
                            <div class="col">
                              <input
                                [(ngModel)]="_newProject.projectLOI"
                                class="form-control form-control-sm form-control-nav"
                                name="projectLOI"
                                mask="99999"
                                [patterns]="integerPattern" >
                              </div>
                            </div>
                            <div class="row nav-font14 my-4">
                              <div class="col d-flex align-items-center grey4"><label class="mr-2" [class.required]="isORInstance">Incidence Rate</label>
                              <span data-html="true" tooltip="The percentage of respondents who qualify to complete the project survey">
                                <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
                              </span>
                            </div>
                            <div class="col">
                              <input
                                [(ngModel)]="_newProject.projectIR"
                                class="form-control form-control-sm form-control-nav"
                                name="projectIR"
                                placeholder="Type percentage"
                                suffix="%"
                                mask="percent">
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <div class="row d-flex w-100" >
                        <div class="col text-right pr-0">
                          <button class="btn nav-btn-primary mx-2 shadow-none font-weight-bold" (click)="closeCreateProjectDialog()">Cancel</button>
                          @if (createType=='quick') {
                            <button class="btn nav-btn-accent shadow-none font-weight-bold" [ngClass]="creatingProject ? 'nav-btn-disabled' : (!_newProject.projectName || !projectType ? 'nav-btn-disabled' : 'nav-btn-accent')" [attr.disabled]="creatingProject ? true : (!_newProject.projectName || !projectType ? true : null)" (click)="createProjectNext();">Create</button>
                          }
                          @if (createType=='clone') {
                            <button class="btn nav-btn-accent shadow-none font-weight-bold" [ngClass]="creatingProject ? 'nav-btn-disabled' : (!_newProject.projectName ? 'nav-btn-disabled' : 'nav-btn-accent')" [attr.disabled]="creatingProject ? true : (!_newProject.projectName ? true : null)" (click)="cloneProject();">Create</button>
                          }
                        </div>
                      </div>
                    </div>
                  }
                </ng-template>


                <ng-template #modalSuccess>
                  <div class="modal-header">
                    <div class="row d-flex w-100 align-items-center">
                      <div class="col">
                        <h3 class="font-weight-bold nav-secondary mb-0 mt-2">
                          <div class="mx-2 d-inline">
                            <img class="" src="/assets/imgs/icon-waving-hand.png" />
                          </div>
                          <span class="mt-1">Welcome to your new project</span>
                        </h3>
                      </div>
                      <div class="col-2 text-right px-0">
                        <i class="fas fa-close grey4" (click)="closeCreateProjectDialog()"></i>
                      </div>
                    </div>
                  </div>
                  @if (_newProject.allowMobile == true || allowMobileConfirm) {
                    <div class="modal-body pt-0">
                      <h5 class="grey5 d-block my-3">
                        For a fully optimized experience, we highly recommend customizing your project's <a [href]="'/projects/' + _newProject.id + '/settings'" class="nav-accent-blue text-decoration-none font-weight-bold">settings</a> to best suit your needs.
                      </h5>
                    </div>
                    <div class="modal-footer">
                      <div class="row d-flex w-100 align-items-center">
                        <div class="col text-right px-0">
                          <button class="btn btn-sm nav-btn-primary mx-2 shadow-none font-weight-bold nav-font14" (click)="closeCreateProjectDialog()">Exit</button>
                          <button class="btn btn-sm nav-btn-accent shadow-none" (click)="goToSettings()">Go to settings</button>
                        </div>
                      </div>
                    </div>
                  }

                  @if (_newProject.allowMobile == false && allowMobileConfirm == false) {
                    <div class="modal-body pt-0">
                      <h5 class="grey5 d-block my-3">
                        The project you are cloning is set up to block mobile traffic. Do you want to carry this setting over to your cloned project?
                      </h5>
                    </div>
                    <div class="modal-footer">
                      <div class="row d-flex w-100 align-items-center">
                        <div class="col text-right px-0">
                          <button class="btn btn-sm nav-btn-primary mx-2 shadow-none font-weight-bold nav-font14" (click)="updateAllowMobile(true)">Turn on Allow Mobile</button>
                          <button class="btn btn-sm nav-btn-accent shadow-none" (click)="allowMobileConfirm = true">Continue</button>
                        </div>
                      </div>
                    </div>
                  }

                </ng-template>


                <ng-template #modalSupportAccessOn>
                  <div class="modal-header">
                    <div class="d-flex w-100 align-items-center">
                      <i class="fak fa-send-email mr-2 grey4 nav-font24"></i><h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18">Support access on</h2>
                    </div>
                    <div class="d-flex justify-content-end editable">
                      <i class="fas fa-close grey4 editable" (click)="openModalRef.hide()"></i>
                    </div>
                  </div>
                  <div class="modal-body nav-font14 grey5 pr-3 pb-3">
                    <div class="row align-items-start mb-2">
                      <div class="col grey5">
                        <span class="d-block">Support access is on and is set to expire at {{supportExpiresOn}} </span>
                        <span class="d-block">UTC{{supportRequest?.accessExpiresTimeZone}}. To edit or revoke access, click the 'Edit support access' button below.</span>
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col d-flex justify-content-end">
                        <button type="button" class="btn nav-btn-primary mx-2 shadow-none font-weight-bold nav-font14" [routerLink]="['/account/support']" href="#" (click)="openModalRef.hide()">Edit support access</button>
                        <button type="button" class="btn shadow-none font-weight-bold nav-font14 nav-btn-accent" (click)="openModalRef.hide()">Dismiss</button>
                      </div>
                    </div>
                  </div>
                </ng-template>

                <ng-template #modalSupportAccessNew>
                  <div class="modal-header">
                    <div class="d-flex w-100 align-items-center">
                      <i class="fak fa-send-email mr-2 grey4 nav-font24"></i><h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18">New support access request</h2>
                    </div>
                    <div class="d-flex justify-content-end editable">
                      <i class="fas fa-close grey4 editable" (click)="openModalRef.hide()"></i>
                    </div>
                  </div>
                  <div class="modal-body nav-font14 grey5 pr-3 pb-3">
                    <div class="row align-items-start mb-2">
                      <div class="col grey5">
                        <span class="d-block mb-3">You have a new support access request from {{supportRequest?.requestSentFrom}}.</span>
                        <span class="d-block">To allow or deny support access access, navigate to the support access page by clicking the 'Go to support access' button below.</span>
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col d-flex justify-content-end">
                        <button type="button" class="btn nav-btn-primary mx-2 shadow-none font-weight-bold nav-font14" (click)="openModalRef.hide()">Cancel</button>
                        <button type="button" class="btn shadow-none font-weight-bold nav-font14 nav-btn-accent" [routerLink]="['/account/support']" href="#" (click)="openModalRef.hide()">Go to support access</button>
                      </div>
                    </div>
                  </div>
                </ng-template>
