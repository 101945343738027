import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AlertifyService, AuthService, SettingsService } from 'core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DashboardService } from 'projects/core/src/services/dashboard.service';
import { ktdTrackById, KtdGridLayout, KtdGridLayoutItem  } from '@katoid/angular-grid-layout';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-dashboard-setup',
  templateUrl: './dashboard-setup.component.html',
  styleUrls: ['./dashboard-setup.component.scss']
})
export class DashboardSetupComponent implements OnInit {

  @Input() public set editDashboard(data) {
    this._editDashboard = data;
  }

  @Input() public set mode(data) {
    this._mode = data;
  }

  _editDashboard;
  _mode;
  editDashName;
  availableWidgets;

  @Output() saveEvent = new EventEmitter();
  @Output() callbackEvent = new EventEmitter();

  constructor(
    public dashboardService: DashboardService,
    public bsModalRef: BsModalRef,
    public settingService: SettingsService,
    public auth: AuthService,
    private alertify: AlertifyService) { }

  ngOnInit() {
    if (this._mode !== 'new') {
      this.editDashName = this._editDashboard.name;
    }
    this.getDashboardGroups();
  }

  getDashboardGroups() {
    this.dashboardService.getDashboardGroups().subscribe(data => {

      data.forEach(group => {
        group.widgets.forEach(widget => {
          if (widget.settings !== null)
            widget.settings = JSON.parse(widget.settings);
          if (widget.roles !== null)
            widget.roles = JSON.parse(widget.roles);
        });
      });
      this.availableWidgets = data;
    }, error => console.log(error));
  }

  saveDashboard() {
    var selectedWidgets = [];

    this.availableWidgets.forEach(group => {
      selectedWidgets = selectedWidgets.concat(group.widgets.filter( e => e.selected));
    });

    if (this.editDashName == null || this.editDashName.trim() == '') {
      this.alertify.error('Please add a name for your Dashboard.');
      return;
    }

    if(this._mode === 'new'){
      let newDash = {
        id: uuidv4(),
        name: this.editDashName,
        widgets: [],
        layout: []
      }
      this.addWidgets(newDash, selectedWidgets);
      this.callbackEvent.emit({ new: true, data: newDash });
    } else {
      this.addWidgets(this._editDashboard, selectedWidgets);
      this._editDashboard.name = this.editDashName;
      this.callbackEvent.emit({ new: false, data: this._editDashboard });
    }

    this.availableWidgets.forEach(group => {
      group.widgets.map( e => e.selected = false);
    });

    this.alertify.success('Your Dashboard has been saved succesfully');
    this.bsModalRef.hide();
  }

  updateDashboard() {
    this.editDashName = this._editDashboard.name;
  }

  saveDashboardForUser(){
    this.saveEvent.emit(true);
  }

  addWidgets(dash, selectedWidgets){
    let layout = [];
    let widgets = [];
    selectedWidgets.forEach( (widget:any) => {
      let newWidget = {...widget};
      newWidget.id = uuidv4();
      const newLayoutItem: KtdGridLayoutItem = {
        id: newWidget.id,
        x: 0,
        y: 0,
        w: widget.w ?? 2,
        h: widget.h ?? 2,
        minW: widget.minW, minH: widget.minH, maxW: widget.maxW, maxH: widget.maxH
      };
      layout.push(newLayoutItem);
      widgets.push(newWidget);
    });
    let arrayCopy = [...dash.layout];
    arrayCopy = arrayCopy.concat(layout);
    dash.layout = arrayCopy;
    dash.widgets = widgets.concat(dash.widgets);
  }

  updateWidget(widget) {
    this.saveDashboardForUser();
  }

}
