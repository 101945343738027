<div class="container-fluid px-4 mb-5 nav-content">
  <div class="row mb-4">
    <div class="col d-flex align-items-center">
      <button class="btn nav-btn-accent shadow-none font-weight-bold mr-3 nav-font14" (click)="clientAdd()">Add Client</button>
      <div class="input-group copy-search">
        <div class="input-group-prepend">
          <div class="input-group-text border-right-0">
            <i class="fas fa-search cursor-default"></i>
          </div>
        </div>
        <input [(ngModel)]="filterText" type="search" class="form-control form-control-sm" (ngModelChange)="updateList()"
              placeholder="Search client or primary contact">
      </div>
    </div>
  </div>

  <div class="scrollbar overflow-x" width="100%">
    <table class="table clients table-hover table-light nav-font14 familyTable mb-0">
      <thead class="grey5">
        <tr>
          <th width="260" (click)="SortBy('name')"><span class="ml-3">Client </span><i class="fas fa-sm or-secondary fa-sort"></i></th>
          <th width="260" (click)="SortBy('website')" >Website <i class="fas fa-sm or-secondary fa-sort"></i></th>
          <th width="200" (click)="SortBy('address')">Address <i class="fas fa-sm or-secondary fa-sort"></i></th>
          <th width="72" class=" text-center">Options </th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <ng-container *ngFor="let client of clients" class="row">
          <tr>
            <td>
              <div class="d-flex align-items-center ml-3">
                <or-profile-image showOnHover="true" [name]="client.name" [url]="client.img" showInitials="true"></or-profile-image>
                <a class="nav-accent-blue font-weight-bold nav-link p-0 text-truncate ml-3"  [routerLink]="['/clients', client.id]"> {{ client.name }}</a>
              </div>
            </td>
            <td>
              <a class="p-0 nav-accent-blue font-weight-bold text-nowrap" [href]="client.website" target="_blank">{{ client.website }}</a>
            </td>
            <td>
              <span class="p-0 text-truncate grey5">{{ client.fullAddress }}</span>
            </td>
            <td class=" text-center">
              <div class="btn-group btn-group-sm" dropdown container="body">
                <button dropdownToggle type="button" class="btn btn-outline-none" controls="dropdown-basic">
                  <i class="fak fa-lg fa-ellipsis-vertical grey4"></i>
                </button>
                <div *dropdownMenu class="dropdown-menu dropdown-menu-right cursor-pointer" role="menu" aria-labelledby="button-basic">
                  <a class="dropdown-item" role="menuitem" (click)="openClient(client)">Open client page</a>
                  <a class="dropdown-item" role="menuitem" (click)="clientEdit(client)">Edit client info</a>
                  <a class="dropdown-item" role="menuitem" (click)="clientDelete(client)">Delete client</a>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <button class="nav-btn-primary shadow-none w-100 mb-3 mt-3" *ngIf="showLoadMore" (click)="showMore()">Load all</button>

</div>

<ng-template #modalClient>
  <div class="modal-header">
    <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18">{{ model?.id ? 'Edit Client' : 'Add New Client' }}</h2>
    <div class="d-flex justify-content-end">
      <i class="fas fa-close grey4" (click)="closeModal()"></i>
    </div>
  </div>
  <div class="modal-body nav-font14">
    <form [formGroup]="clientForm" novalidate>
      <div class="row align-items-center mb-4">
        <div class="col-4 d-flex justify-content-center pr-5">
          <img [src]="model?.img || '../../../assets/imgs/user-instagram.png'" height="64px" width="64px" class="rounded-circle" alt="">
        </div>
        <div class="col upload">
          <or-upload
            mainMsg="Select profile photo to upload"
            smallMsg="or drag and drop .JPEG or .PNG here"
            [includeProgressBar]=false
            acceptImages=true
            showFileName=false
            directToS3=true
            showFileIcon=false
            class="upload"
            (uploadCompleted)="uploadCallback($event)">
          </or-upload>
        </div>
      </div>
      <div class="row align-items-center mb-4">
        <div class="col-4 grey4">
          <label class="required">Company name</label>
        </div>
        <div class="col">
          <input type="text" class="form-control form-control-sm form-control-nav" placeholder="Client" formControlName="name" id="name"
          [ngClass]="submitted && utils.showCssValidField(clientForm, 'name')" />
        </div>
      </div>
      <div class="row align-items-center mb-4">
        <div class="col-4 grey4">Website</div>
        <div class="col">
          <input type="text" class="form-control form-control-sm form-control-nav" placeholder="http://www.website.com" formControlName="website" id="website"
          [ngClass]="submitted && utils.showCssValidField(clientForm, 'website')" />
        </div>
      </div>
      <div class="row align-items-start mb-4">
        <div class="col-4 grey4">Address</div>
        <div class="col">
          <div class="row align-items-center mb-4">
            <div class="col">
              <input type="text" class="form-control form-control-sm form-control-nav" placeholder="123 Street" formControlName="address1" id="address1" />
            </div>
          </div>
          <div class="row align-items-center mb-4">
            <div class="col grey4">
              <label>Apt, suite, etc.</label>
              <input type="text" class="form-control form-control-sm form-control-nav" placeholder="Optional" formControlName="address2" id="address2" />
            </div>
          </div>
          <div class="row align-items-center mb-4">
            <div class="col grey4">
              <label>City</label>
              <input type="text" class="form-control form-control-sm form-control-nav" placeholder="City" formControlName="city" id="city" />
            </div>
            <div class="col grey4">
              <label>State/province</label>
              <input type="text" class="form-control form-control-sm form-control-nav" placeholder="State/province" formControlName="state" id="state" />
            </div>
          </div>
          <div class="row align-items-center mb-4">
            <div class="col grey4">
              <label>ZIP/postal code</label>
              <input type="text" class="form-control form-control-sm form-control-nav" placeholder="00000" formControlName="zip" id="zip" />
            </div>
            <div class="col grey4">
              <label>Country</label>
              <input type="text" class="form-control form-control-sm form-control-nav" placeholder="Country" formControlName="country" id="country" />
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row align-items-center mb-2">
        <div class="col-4 grey4 font-weight-bold"><label class="required">Primary contact</label></div>
        <div class="col">
          <select [(ngModel)]="newTask.stageId"  class="form-control form-control-sm form-control-nav d-block w-100">
            <option *ngFor="let stage of this.projectSchedule" value="{{ stage.id }}">{{ stage.stageName }}
            </option>
          </select>
        </div>
      </div> -->
      <div class="col text-right pr-0 mt-4">
        <button type="button" class="btn nav-btn-primary mx-2 shadow-none font-weight-bold nav-font14" (click)="closeModal()">Cancel</button>
        <button type="button"
                [disabled]="!clientForm.valid"
                [ngClass]="clientForm.valid ? 'nav-btn-accent' : 'nav-btn-disabled'"
                class="btn shadow-none font-weight-bold nav-font14"
                (click)="clientSave()">{{ model?.id ? 'Edit' : 'Add' }} Client</button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #modalConfirm>
  <div class="modal-header">
    <div class="row d-flex w-100 align-items-center">
      <div class="col">
        <h3 class="font-weight-bold nav-secondary">
          <i class="fak fa-message-warning grey4 fa-lg ml-2"></i>
          Warning: Deleting Client
        </h3>
      </div>
      <div class="col-2 text-right px-0">
        <i class="fas fa-close grey4" (click)="closeModal()"></i>
      </div>
    </div>
  </div>
  <div class="modal-body pt-0">
    <h5 class="grey5 d-block my-3">
      Are you sure you want to delete the client <span class="font-weight-bold grey5">{{model?.name}}?</span> This action is irreversible, and the client will be permanently lost.
    </h5>
  </div>
  <div class="modal-footer">
    <div class="row d-flex w-100 align-items-center">
      <div class="col-5 px-0 or-checkbox d-flex justify-content-start">
        <input type="checkbox" name="notAskAgainDeleteClient" (change)="this.utils.createDialogList($event.target.checked, 'client')" id="notAskAgainDeleteClient">
        <label for="notAskAgainDeleteClient"></label>
        <small class="pl-4 grey4">Don't ask again</small>
      </div>
      <div class="col text-right px-0">
        <button class="btn btn-sm st-btn-primary mx-1 shadow-none" (click)="closeModal()">Cancel</button>
        <button class="btn btn-sm nav-btn-accent shadow-none" (click)="confirmDelete()">Delete</button>
      </div>
    </div>
  </div>
</ng-template>
