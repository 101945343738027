<div class="card m-2">
  <div class="card-header">
    <div class="row align-items-center">
      <div [class]="mode == 'reduced' ? 'col' : 'col-8'">
        <h3 class="nav-secondary font-weight-bold">CleanID</h3>
      </div>
      <div class="text-right" [class]="mode == 'reduced' ? 'col-4' : 'col'">
        <!-- *ngIf="_project.fraudDetectionVersion != 3" -->
        <i (click)="sendEmailMode = true; resizeCard('reduced')" class="fas fa-envelope fa-lg cursor-pointer" tooltip="Email CleanID Report"></i>
        <i (click)="previewReport(modalPreview)" class="fas fa-eye fa-lg cursor-pointer ml-2" tooltip="Preview CleanID Report"></i>
        <i (click)="resizeCard()" class="fas {{ mode == 'reduced' ? 'fa-expand-alt' : 'fa-compress-alt' }} fa-lg cursor-pointer ml-2"></i>
      </div>
    </div>
  </div>
  <ng-container *ngIf="_project.isFraudDetectionEnabled && mode == 'reduced' && sendEmailMode == false">
    <div class="card-body">
      <div class="row pb-2">
        <div class="col">
          <p class="h4 font-weight-bold or-secondary">{{ fraud / total | percent:'1.1-1' }} Fraud Rate</p>
        </div>
      </div>
      <div class="row chart or-gray-bg" *ngIf="trendData.length > 0">
        <div class="col">
          <or-trend-chart [data]="trendData"></or-trend-chart>
        </div>
      </div>
      <div class="row py-3">
        <div class="col">
          <p class="h5 mb-0 font-weight-normal or-secondary">{{ fraud }} Fraudulent Devices Blocked</p>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="_project.isFraudDetectionEnabled && mode == 'reduced' && sendEmailMode == true">
      <div class="card-body">
        <form #sendForm="ngForm" novalidate>
          <div class="row pb-2">
            <div class="col">
              <p class="h4 font-weight-bold or-secondary">Send CleanID Report</p>
            </div>
          </div>
          <div class="row or-gray-bg">
            <div class="col col-sm-12 pb-4">
              <label for="name" class="required">To:</label>
              <input type="text" class="form-control" [(ngModel)]="emailTo" name="emailTo" required [ngClass]="{'is-invalid': submitted && sendForm.controls['emailTo'].invalid }" />
            </div>
          </div>
          <div class="col text-right mt-3 pr-0">
            <div class="btn btn-sm btn-light mr-2" (click)="sendEmailMode = false; emailTo = null" type="button">Cancel</div>
            <button class="btn btn-sm nav-btn-accent text-white" type="submit" (click)="sendReport()">Send</button>
          </div>
        </form>
    </div>
  </ng-container>

  <ng-container *ngIf="!_project.isFraudDetectionEnabled  && mode == 'reduced'">
    <div class="card-body">
      <div class="row">
        <div class="col"></div>
      </div>
      <div class="row my-2 or-gray-bg">
        <div class="col p-3 text-center">
          <div class="row m-2">
            <div class="col">
              <i class="fas fa-shield-alt fa-2x or-secondary"></i>
            </div>
          </div>
          <div class="row m-2">
            <div class="col">
              <p class="font-weight-bold or-secondary">Fraud detection disabled, your responses might be at risk</p>
              <small class="d-block mb-3 or-secondary">
                Our award-winning Fraud Detection mechanism catches 99% of fraudulent responses.
                Improve your sample quality by enabling Fraud Detection.
              </small>
            </div>
          </div>
          <!--<div class="row m-2">
            <div class="col">
              <button class="btn btn-sm btn-secondary text-white border-0">Learn more</button>
            </div>
          </div>-->
        </div>
      </div>
      <div class="row">
        <div class="col"></div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="mode == 'expanded'">
    <div class="card-body">
      <div class="row" *ngIf="chartType == 'timeline'">
        <div class="col pt-3">
          <div class="row">
            <div *ngIf="this.thresholdOn == false" class="col">
              <span class="h6">Summary of Markers Over Time</span>
            </div>
            <div *ngIf="this.thresholdOn == true" class="col">
              <span class="h6">Blocked Participants Over Time</span>
            </div>
            <div class="col text-right">
              <button type="button" (click)="switchMode('piechart')" tooltip="Pie Chart" class="btn btn-outline-secondary btn-sm opacity-90">
                <i class="fas fa-chart-pie"></i>
              </button>
            </div>
          </div>
          <div class="row mt-3 chart or-gray-bg">
            <div class="col pt-2" *ngIf="markerData != null">
              <or-cleanid-timeline-chart [data]="markerData"></or-cleanid-timeline-chart>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="chartType == 'piechart'">
        <div class="col-7 pt-3">
          <div class="row">
            <div class="col-9"  *ngIf="this.thresholdOn == true">
              <span class="h6 or-secondary" *ngIf="selectedPartner.name"> {{ selectedPartner.name }} Markers For Blocked IDs</span>
              <span class="h6 or-secondary" *ngIf="selectedPartner.name == null">Summary of Markers for Blocked IDs
              </span>
            </div>
            <div class="col-9"  *ngIf="this.thresholdOn == false">
              <span class="h6 or-secondary" *ngIf="selectedPartner.name"> {{ selectedPartner.name }} Summary of Markers</span>
              <span class="h6 or-secondary" *ngIf="selectedPartner.name == null">Summary of Markers</span>
            </div>
            <div class="col-s3 text-right">
              <button type="button" (click)="switchMode('timeline')" tooltip="Timeline" class="btn btn-outline-secondary btn-sm opacity-90">
                <i class="fas fa-chart-line"></i>
              </button>
            </div>
          </div>
          <div class="row mt-4 chart mr-n3 or-gray-bg">
            <div class="col pt-2" *ngIf="markerData != null">
              <or-cleanid-summary-chart [data]="markerData"></or-cleanid-summary-chart>
            </div>
          </div>
        </div>
        <div class="col-5 information nav-leftborder">
          <div class="settingsrow pt-1">
            <div class="">
              <div class="or-secondary">
                <span tooltip="Configure what participants you want to block based on the score">
                  <i class="fak nav-font16 fa-message-info grey3"></i>
                </span>
                Fraud Threshold
              </div>
            </div>
            <div class="justify-content-center or-dropdown-status">
              <div class="loading" *ngIf="loading.fraudDetectionThreshold"></div>
              <or-dropdown *ngIf="!loading.fraudDetectionThreshold" [values]="thresholdList" [value]="_project.fraudDetectionThreshold" (selected)="changeThreshold($event)"></or-dropdown>
            </div>
          </div>
          <div class="settingsrow mt-2">
            <div class="or-secondary">
              <span tooltip="Allow mobile devices to enter this Survey.">
                <i class="fak nav-font16 fa-message-info grey3"></i>
              </span>
              Allow Mobile
            </div>
            <div class="d-flex justify-content-center">
              <div class="loading" *ngIf="loading.allowMobile"></div>
              <or-toggle-button *ngIf="!loading.allowMobile" (changed)='changeAllowMobile($event)' msgON="On" msgOFF="Off" size="small"  [(ngModel)]="_project.allowMobile" name="allowMobile"></or-toggle-button>
            </div>
          </div>
          <div class="settingsrow mt-2">
            <div class="or-secondary">
              <span tooltip="Instead of running CleanID when the page opens the participant will be required to press continue first.">
                <i class="fak nav-font16 fa-message-info grey3"></i>
              </span>
              E-mail Security Page
            </div>
            <div class="d-flex justify-content-center">
              <div class="loading" *ngIf="loading.showLandingPage"></div>
              <or-toggle-button  *ngIf="!loading.showLandingPage" (changed)='changeShowLandingPage($event)' msgON="On" msgOFF="Off" size="small"  [(ngModel)]="_project.showLandingPage" name="showLandingPage"></or-toggle-button>
            </div>
          </div>

          <hr class="or-dottedline">
          <div class="row">
            <div class="col pb-1">
              <span class="h6 or-secondary">Blocked Starts</span>
            </div>
            <div class="col-2">
              <span tooltip="Number of participants blocked due to Fraud detection per Vendor">
                <i class="fak nav-font16 fa-message-info grey3"></i>
              </span>
            </div>
          </div>
          <div class="partnerlist m-1 w-100 or-secondary">
            <div>Vendor</div>
            <div>%</div>
            <div>Blocks</div>

            <ng-container class="vendor" *ngFor="let partner of partnerCounts">
              <div>
                <div class="dotted-underline border-info" [class.border]="partner.name == this.selectedPartner.name">
                  <small (click)="filterOnPartner(partner)">{{ partner.name }}</small>
                </div>
              </div>
              <div>
                <div class="mt-2 progress w-90 " style="height: 6px;">
                  <div class="progress-bar or-primary-bg" role="progressbar" [style.width]="(partner.value * 100 / total) + '%'" ></div>
                </div>
              </div>
              <div class="text-nowrap text-center">
                <small>{{ partner.value }}</small>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="card-footer py-2 or-title">
    <div class="d-flex">

      <div
      class="d-flex clean-id-report-sent-check"
      [class.cursor-na]="!_project.cleanIdReportEmailedOnce"
      [tooltip]="_project.cleanIdReportEmailedOnce ? '' : 'This report has not been emailed'">
        <label class="form-check-label mr-1" [class.cursor-na]="!_project.cleanIdReportEmailedOnce">
          Report sent to client?
        </label>
        <div class="or-checkbox mx-1" [class.disabled]="!_project.cleanIdReportEmailedOnce">
          <input type="checkbox" id="clean-id-report-sent" [(ngModel)]="_project.cleanIdReportEmailedToClient" (change)="onToggleReportSentToClient()" [disabled]="!_project.cleanIdReportEmailedOnce">
          <label for="clean-id-report-sent" [class.cursor-na]="!_project.cleanIdReportEmailedOnce"></label>
        </div>
      </div>
    </div>
  </div>

</div>

<ng-template #modalPreview>
  <div class="modal-header">
    <p class="h5">CleanID Report Preview</p>
    <button type="button" class="close pull-right text-white" aria-label="Close" (click)="openModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <iframe [srcdoc]="cleanIdPreviewReport" width="100%" height="750px" sandbox></iframe>
  </div>
</ng-template>
