<div class="container-fluid">
  <div class="row d-flex align-items-center mx-0 mb-4">
    <div class="col p-0">
      <span class="nav-secondary nav-font18 font-weight-bold ">Quotas</span>
    </div>
    @if (!auth.isClient() && !auth.isVendor() && !isVendorview) {
      <div class="col-4">
        <div class="text-right">
          <div class="btn-group" dropdown #dropdownSettings="bs-dropdown" container="body">
            <div id="button-basic" dropdownToggle type="button" controls="dropdown-basic" class="px-2 mt-1">
              <i class="fak fa-lg fa-ellipsis-vertical fa-md cursor-pointer grey4"></i>
            </div>
            <ul id="dropdown-settings" *dropdownMenu class="dropdown-menu dropdown-menu-right py-0" role="menu" aria-labelledby="button-basic">
              @if (project.isQuotaEnabled) {
                <a class="dropdown-item nav-font14 cursor-pointer"
                  container="body"
                (click)="downloadQuota()">Download Quota Setup</a>
                <hr class="mx-2 my-0">
              }
              <a class="dropdown-item nav-font14 cursor-pointer" (click)="enableQuota()">Turn Quotas {{quotaEnabled ? 'Off' : 'On'}}</a>
            </ul>
            <or-view-toggle (valueChange)='toggleLimitType($event)' [showNumber]=true [showPercent]=true [active]="projectQuota?.limitType" [class]="'btn-sm'"></or-view-toggle>
          </div>
        </div>
      </div>
    }
  </div>

  @if (!auth.isClient() && !auth.isVendor() && !isVendorview) {
    <div class="row mx-0 mb-4 d-flex align-items-center quotas-header">
      <!-- add quotas button dropdown -->
      <div class="btn shadow-none nav-btn-accent quota-add-btn px-2 py-0 btn-group position-relative mr-3" dropdown  #dropdownDemos="bs-dropdown"
        id="button-basic" dropdownToggle controls="dropdown-basic"
        [class.active]="dropdownDemos.isOpen"
        [class.disabledBtn]="project.fullLaunchQuota == 0"
        placement="bottom"
        [tooltip]="project.fullLaunchQuota == 0 ? 'Cannot add quotas while project completes allocation is 0' : ''" >
        <!-- dropdonw btn -->
        <span class="nav-font14 font-weight-bold">Add Quotas</span>
        <i class="px-1 ml-1 fa fa-light fa-pipe"></i>
        <i class="fas fa-chevron-down ml-1 font-weight-normal nav-font16"></i>
        <!-- dropdown menu -->
        <div *dropdownMenu class="dropdown-menu dropdown-menu-right nav-font14 position-absolute" role="menu" aria-labelledby="button-basic">
          <div class="section">
            <div class="section-name">Add new</div>
            <div class="option mx-2 px-2 my-1 pt-1" (click)="openModalQuotaConfig('add')">
              <i class="icon fak fa-groups"></i>Demographics</div>
            </div>
            <hr class="seperator">
            <div class="section">
              <div class="section-name">Add existing</div>
              <div class="option px-1 pt-1 m-1" (click)="openModal(modal, 'templateClone')">
                <i class="icon far fa-copy"></i>Clone from project</div>
                <div class="option px-1 pt-1 m-1" (click)="openModal(modal, 'templateImport')">
                  <i class="icon fa-regular fa-file-import" ></i>Import from file</div>
                </div>
              </div>
            </div>
            @if (projectQuota?.quotaGroups.length >= 2) {
              <div class="btn shadow-none nav-btn-primary quota-settings px-2 py-0 font-weight-bold
                nav-font14 d-flex align-items-center mr-3" (click)="openModalQuotaConfig('nest')">
                <i class="fak fa-nest grey4 nav-font20 font-weight-normal icon mr-2"></i>
                Nest Quotas
              </div>
            }
            <div class="btn shadow-none nav-btn-primary quota-download-template px-2 py-0 font-weight-bold
              nav-font14 d-flex align-items-center mr-3" (click)="downloadQuotaTemplate()">
              <i class="fa-kit fa-download-solid font-weight-normal grey4 nav-font16 icon mr-2 p-1"></i>
              Download template
            </div>
            <div class="btn shadow-none nav-btn-primary quota-settings px-2 py-0 font-weight-bold
              nav-font14 d-flex align-items-center" (click)="openModalQuotaConfig('edit')">
              <i class="fak fa-gear-solid grey4 nav-font20 font-weight-normal icon mr-2"></i>
              Settings
            </div>
          </div>
        }

        <!-- quotas not enabled -->
        @if (projectQuota?.quotaGroups.length <= 0) {
          <div class="grey5 nav-font16 mt-3 pt-2">Add quota to begin.</div>
        }

        <!-- Quota Group Cards -->
        @if (project.isQuotaEnabled) {
          <div class="quota-cards card-columns">
            @if (this.projectQuota?.quotaGroups?.length > 0 && project.isQuotaEnabled && !typesEmpty) {
              @for (group of projectQuota.quotaGroups; track group; let i = $index) {
                @if ((questionIDs.includes(group.questionId) || 
                  (questionIDs.includes(group.questionIdArray[0]) && questionIDs.includes(group.questionIdArray[1]) && (group.questionIdArray.length == 2 || questionIDs.includes(group.questionIdArray[2])))) && 
                  !group.isDeleted) {
                  <ng-template *ngTemplateOutlet="quotaCard; context:{ $implicit: group}"></ng-template>
                }
              }
            }
          </div>
        }

        <div>
          <ng-template #modal>
            @if (modalType==='onClose') {
              <div class="modal-header">
                <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18">Confirm Close </h2>
              </div>
              <div class="modal-body text-center">
                <p class="h6">Are you sure you want to close without saving?</p>
                <button type="button" class="btn btn-outline-secondary m-2" (click)="closeModal('onClose')">Yes</button>
                <button type="button" class="btn btn-secondary" (click)="openModalRef.hide()">No</button>
              </div>
            }
            @if (modalType==='templateClone') {
              <div class="modal-header">
                <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18">Clone Quota </h2>
                <button tabindex="-1" type="button" class="close pull-right grey4" aria-label="Close" (click)="closeModal()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="px-1">Search for a quota to clone from existing projects:</div>
                <div class="pr-0  mr-3 mb-0 pb-0 mt-2 templateSearch">
                  <ng-container>
                    <or-type-ahead  [labelField]="'name'" [multi]="false" [valueField]="'value'" [showLoading]="false" class=""
                      [dataCallback]="getProjectList"
                      cleanAfterSelect="true"
                      placeholder="Search by ID, Name or Client"
                      (valueChange)="updateProject($event)" >
                    </or-type-ahead>
                    <div class="mt-5 mb-3">
                      @if (projectList.length > 0) {
                        <or-list class="quotaTemplateList" [items]="projectList" [layout]="quotaTemplates" [actions]="actions" (action)="handleAction($event)" [enableExport]="false"></or-list>
                      }
                    </div>
                  </ng-container>
                </div>
              </div>
            }
            @if (modalType==='templateImport') {
              <div class="modal-header">
                <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18">Import Quota </h2>
                <button tabindex="-1" type="button" class="close pull-right grey4" aria-label="Close" (click)="closeModal(); importFileName = null">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p class="grey5 mb-5">Import quota from a .xlsx or .csv file. This will override any existing quota setup on this project.</p>
                <div class="pr-0 mr-3 mb-0 pb-0 mt-2 templateSearch d-flex justify-content-center">
                  <ng-container>
                    <div class="col-md-12 col-lg-6">
                      <or-upload
                        mainMsg="Select a CSV file to upload"
                        smallMsg="or drag and drop the file here to begin"
                        [includeProgressBar]=true
                        acceptTypes=".csv,.xlsx"
                        [includeFileName]="true"
                        [instanceId]="auth.getUser().instanceId"
                        directToS3=true
                        [S3Folder]="s3buckPath"
                        publicBucket=true
                        (uploadCompleted)="importFileUploaded($event)"
                      id="img" ></or-upload>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="modal-footer text-right">
                @if (loadingImport) {
                  <span class="loading mx-2 mt-2"></span>
                }
                <button type="button" class="btn nav-btn-primary shadow-none m-2" (click)="openModalRef.hide(); importFileName = null">Cancel</button>
                <button type="button" class="btn nav-btn-accent shadow-none" (click)="importQuotaFromFile()" [disabled]="!importFileName || loadingImport">Import Quota</button>
              </div>
            }
          </ng-template>
        </div>

        <!-- Vendor Quota Card -->
        @if (projectQuota?.enableVendorLimits && projectQuota?.quotaGroups.length > 0) {
          <div class="vendor-quota-container mb-5 mt-2" [class.overflow-y]="vendorCardOverflowY" [class.overflow-x]="vendorCardOverflowX">
            <div class="vendor-quotas-card first-row border-bottom border-top zIndex2" [style.min-width]="vendorCardGridMinWidth" [style.grid-template-columns]="vendorCardGridTemplate">
              <div class="header grey-bg border-right border-left nav-font16 zIndex2">Quotas</div>
              @for (vendor of vendors; track vendor; let isLast = $last) {
                <div class="header grey-bg zIndex1" [class.border-right]="!isLast">{{vendor.partnerName}}</div>
              }
            </div>
            <div class="vendor-quotas-card second-row border-bottom zIndex2" [style.min-width]="vendorCardGridMinWidth" [style.grid-template-columns]="vendorCardGridTemplate">
              <div class="header grey-bg border-right border-left zIndex2"></div>
              @for (vendor of vendors; track vendor; let isLastVendor = $last) {
                <div class="header grey-bg zIndex1" [class.border-right]="!isLastVendor">
                  <div class="vendor-goal">Goal</div>
                  <div class="vendor-percentage">%</div>
                </div>
              }
            </div>
            <div class="vendor-quotas-card third-row zIndex2" [class.border-bottom]="projectQuota.quotaGroups.length > 0" [style.min-width]="vendorCardGridMinWidth" [style.grid-template-columns]="vendorCardGridTemplate">
              <div class="header white-bg border-right border-left zIndex2">Total</div>
              @for (vendor of vendors; track vendor; let isLastVendor = $last) {
                <div class="header white-bg zIndex1" [class.border-right]="!isLastVendor">
                  <div class="vendor-goal">
                    <div class="inline-input d-flex justify-content-center">
                      <div class="input-group" [class.pl-0]="vendor.partnerFullQuota > 9999">
                        <input #vendorLimitN
                          class="form-control form-control-nav nav-secondary font-weight-bold"
                          type="text"
                          [(ngModel)]="vendor.partnerFullQuota">
                        <div class="input-group-append nav-secondary">n</div>
                      </div>
                    </div>
                  </div>
                  <div class="vendor-percentage">
                    <div class="inline-input d-flex justify-content-center">
                      <div class="input-group"
                        [class.pl-0]="vendor.totalFullQuotaPercent > 9999">
                        <input #vendorLimitPercent
                          class="form-control form-control-nav nav-secondary font-weight-bold"
                          type="text"
                          [(ngModel)]="vendor.totalFullQuotaPercent">
                        <div class="input-group-append nav-secondary">%</div>
                      </div>
                    </div>
                    <!-- {{((projectQuota.limitMode == 'complete' ? vendor.completes : vendor.starts)/vendor.partnerFullQuota * 100) | number: '1.0-0'}} -->
                  </div>
                </div>
              }
            </div>
            @for (group of projectQuota.quotaGroups; track group; let isLastQuotaGroup = $last; let isFirstQuotaGroup = $first) {
              <div class="vendor-quotas-card quota-name-row border-bottom border-top" [class.border-top]="!isFirstQuotaGroup" [style.min-width]="vendorCardGridMinWidth" [style.grid-template-columns]="vendorCardGridTemplate">
                <div class="header grey-bg border-right border-left zIndex1">{{group.name}}</div>
                @for (vendor of vendors; track vendor; let isLast = $last) {
                  <div class="header grey-bg" [class.border-right]="!isLast"></div>
                }
              </div>
              @for (quota of group.quotas; track quota; let isLastQuota = $last) {
                @if (!quota.isDeleted) {
                  <div class="vendor-quotas-card" [class.border-bottom-half]="!isLastQuota" [style.min-width]="vendorCardGridMinWidth" [style.grid-template-columns]="vendorCardGridTemplate">
                    @if (group.questionId.length <= 36) {
                      @if (quota.targetGroup[group.name.toLowerCase()]; as value) {
                        <div class="quota white-bg border-right-half border-left zIndex1" [class.font-italic]="value === 'pnta'">
                          {{value == 'pnta' ? 'Prefer not to answer' : value}}
                        </div>
                      } @else {
                        <div class="quota white-bg border-right-half border-left d-flex zIndex1" [class.font-italic]="quota.targetGroup['minHHI'] == -1">
                          @if (group.name === 'Income') {
                            <div>{{quota.targetGroup['minHHI'] == -1 ? 'Prefer not to answer' : (quota.targetGroup['minHHI'] | currency :'USD':'symbol':'1.0-1' )}}</div>
                            @if (quota.targetGroup['minHHI'] != -1) {
                              @if (quota.targetGroup['maxHHI'] <= hhiMax) {
                                <span class="mx-1">to</span>
                              }
                              <div>{{quota.targetGroup['maxHHI'] > hhiMax ? '&nbsp;and above' : (quota.targetGroup['maxHHI'] | currency :'USD':'symbol':'1.0-0')}}</div>
                            }
                          }
                          @if (group.name === 'Age') {
                            <div>{{quota.targetGroup['minAge']}}</div>
                            <span class="mx-1">to</span>
                            <div>{{quota.targetGroup['maxAge']}}</div>
                          }
                        </div>
                      }                    
                    }
                    @else {
                      <div class="quota white-bg border-right-half border-left zIndex1">
                        @for (qId of group.questionIdArray; track qId) {
                          @if (quota.targetGroup[questions[qId].type.toLowerCase()]; as value) {
                            <span [class.font-italic]="value === 'pnta'">{{value == 'pnta' ? 'Prefer not to answer' : value}}</span>
                          }
                          @else {
                            @if (questions[qId].type == 'HouseholdIncome') {
                              <span>
                                <span [class.font-italic]="quota.targetGroup['minHHI'] == -1">{{quota.targetGroup['minHHI'] == -1 ? 'Prefer not to answer' : (quota.targetGroup['minHHI'] | currency :'USD':'symbol':'1.0-1' )}}</span>
                                @if (quota.targetGroup['minHHI'] != -1) {
                                  @if (quota.targetGroup['maxHHI'] <= hhiMax) {
                                    <span class="mx-1">to</span>
                                  }
                                  <span>{{quota.targetGroup['maxHHI'] > hhiMax ? '&nbsp;and above' : (quota.targetGroup['maxHHI'] | currency :'USD':'symbol':'1.0-0')}}</span>
                                }
                              </span>
                            }
                            @else if (questions[qId].type === 'Age') {
                              <span>
                                <span>{{quota.targetGroup['minAge']}}</span>
                                <span class="mx-1">to</span>
                                <span>{{quota.targetGroup['maxAge']}}</span>
                              </span>
                            }
                          }
                          @if (group.questionIdArray[$index+1]){
                            ,&nbsp;
                          }
                        }
                      </div>
                    }

                    @for (vendor of vendors; track vendor; let isLast = $last) {
                      @for (quotaVendor of quota.vendorLimits; track quotaVendor) {
                        @if (quotaVendor.vendorId == vendor.partnerId) {
                          <div class="quota white-bg" [class.border-right-half]="!isLast">
                            <div class="vendor-goal">
                              <div class="inline-input">
                                <div class="input-group" (click)="vendorLimitN.focus()">
                                  <input #vendorLimitN
                                    class="form-control form-control-nav"
                                    type="text"
                                    mask="9999"
                                    [specialCharacters]="[]"
                                    (keyup.enter)="vendorLimitN.blur()"
                                    (blur)="vendorLimitChanged(quota, quotaVendor, 'n', $event)"
                                    [(ngModel)]="quotaVendor.limitN">
                                  <div class="input-group-append grey6">n</div>
                                </div>
                              </div>
                            </div>
                            <div class="vendor-percentage">
                              <div class="inline-input">
                                <div class="input-group" (click)="vendorLimitPercent.focus()">
                                  <input #vendorLimitPercent
                                    class="form-control form-control-nav"
                                    type="text"
                                    mask="999"
                                    [specialCharacters]="[]"
                                    (keyup.enter)="vendorLimitPercent.blur()"
                                    (blur)="vendorLimitChanged(quota, quotaVendor, '%', $event)"
                                    [(ngModel)]="quotaVendor.limitPercent">
                                  <div class="input-group-append grey6">%</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      }
                    }
                  </div>
                }
              }
            }
          </div>
        }
      </div>


<ng-template #quotaCard let-group>
  <div class="card p-4">
    <div class="card-header nav-font18 grey6 font-weight-bold nav-secondary p-0 mb-3 pb-1">
      <div class="quota-row align-items-center">
        @if (group.questionId.length <= 36) {
          <i (click)="openRemoveQuotaGroupModal(group)" class="fak fa-trash p-1 mr-2 nav-error nav-font18 font-weight-normal icon cursor-pointer"></i>
        }
        @else {
          <i (click)="undoNest(group)" tooltip="Undo nest" placement="bottom" class="fa-sharp fa-light fa-object-ungroup pt-1 mr-2 nav-error nav-font18 icon cursor-pointer"></i>
        }
        <span></span>
        <span class="quota-name-col">{{group.name}}</span>
        <span></span>
        <span class="goal-col text-center">Goal</span>
        <span></span>
        <span class="percent-col text-center">%</span>
      </div>
    </div>  
    <div class="card-body p-0">
      @for (quota of group.quotas; track quota; let i = $index) {
        @if (quota.isDeleted == false) {
          <ng-container class="">
            <div class="quota-row align-items-center mb-3">
              <i class="far fa-times nav-font18 icon p-1 cursor-pointer grey4" [style.visibility]="group.questionId.length > 36 ? 'hidden' : 'visible'" (click)="newQuota?.id !== quota.id ? removeQuota(group, quota.id) : null"></i>
              <span></span>
              <!-- if not nested -->
              @if (group.questionId.length <= 36) {
                @if (quota.targetGroup[questions[group.questionId].type.toLowerCase()]; as value) {
                  <span class="grey5 nav-font14 text-nowrap text-truncate" [class.font-italic]="value === 'pnta'">{{ value === 'pnta' ? 'Prefer not to answer' : value}}</span>
                }
                @if (questions[group.questionId].type == 'HouseholdIncome') {
                  @if (quota.targetGroup.minHHI > -1) {
                    <div class="d-flex nav-font14">
                      <select
                        (change)="minMaxChanged(quota, group, quota.targetGroup)"
                        class=" form-control form-control-nav nav-font14 grey5 p-0 pl-1"
                        [class.clickable]="quota.id ===  newQuota?.id"
                        [(ngModel)]="quota.targetGroup.minHHI">
                        <option  [ngValue]="0"> {{0 | currency :'USD':'symbol':'1.0-0' }}</option>
                        @for (n of hhiList; track n) {
                          <option [class.clickable]="quota.newQuota" [ngValue]="n">{{n | currency :'USD':'symbol':'1.0-1' }}</option>
                        }
                      </select>
                      <div class="nav-font14 mr-2 ml-2 mt-1 d-inline"> to </div>
                      <select
                        class=" form-control form-control-nav nav-font14 grey5 p-0 pl-1"
                        [class.clickable]="quota.id ===  newQuota?.id"
                        [(ngModel)]="quota.targetGroup.maxHHI"
                        (change)="minMaxChanged(quota, group, quota.targetGroup)">
                        @for (n of hhiList; track n) {
                          @if (quota.targetGroup.minHHI < n-1) {
                            <option [class.clickable]="quota.id ===  newQuota?.id" [ngValue]="n-1">{{n-1 | currency :'USD':'symbol':'1.0-0' }}</option>
                          }
                        }
                        <option [ngValue]="hhiMax+1">and above</option>
                      </select>
                    </div>
                  }
                  @if (quota.targetGroup.minHHI == -1) {
                    <div class="d-inline font-italic nav-font14 grey5">
                      Prefer not to answer
                    </div>
                  }
                }
                @if (group.name == 'Age') {
                  <div class="d-flex">
                    <input #inputMin
                      type="text"
                      mask="999"
                      [specialCharacters]="[]"
                      class="text-left form-control form-control-nav nav-font14 grey5"
                      [class.clickable]="quota.id ===  newQuota?.id"
                      [(ngModel)]="quota.targetGroup.minAge"
                      (keyup.enter)="inputMin.blur()"
                      (blur)="newQuota?.id !== quota.id ? minMaxChanged(quota, group, quota.targetGroup) : null">
                    <div class="nav-font14 mr-2 ml-2 mt-1 d-inline"> to </div>
                    <input #inputMax
                      type="text"
                      mask="999"
                      [specialCharacters]="[]"
                      class="text-left form-control form-control-nav nav-font14 grey5"
                      [class.clickable]="quota.id ===  newQuota?.id"
                      [(ngModel)]="quota.targetGroup.maxAge"
                      (keyup.enter)="inputMax.blur()"
                      (blur)="newQuota?.id !== quota.id ? minMaxChanged(quota, group, quota.targetGroup) : null">
                  </div>
                }
              }
              @else { <!-- if nested -->
                <span class="text-nowrap grey5 nav-font14 text-truncate">
                  @for (qId of group.questionIdArray; track qId) {
                    @if (quota.targetGroup[questions[qId].type.toLowerCase()]; as value1) {
                      <span  [class.font-italic]="value1 === 'pnta'">{{ value1 === 'pnta' ? 'Prefer not to answer' : value1}}</span>
                    }
                    @else {
                      @if (questions[qId].type == 'HouseholdIncome') {
                        <!-- <span [class.font-italic]="quota.targetGroup.minHHI == -1">
                          {{quota.targetGroup.minHHI == -1 ? 'Prefer not to answer' : (quota.targetGroup.minHHI | currency :'USD':'symbol':'1.0-1' )}}
                        </span> -->
                        <span>
                          <span [class.font-italic]="quota.targetGroup['minHHI'] == -1">{{quota.targetGroup['minHHI'] == -1 ? 'Prefer not to answer' : (quota.targetGroup['minHHI'] | currency :'USD':'symbol':'1.0-1' )}}</span>
                          @if (quota.targetGroup['minHHI'] != -1) {
                            @if (quota.targetGroup['maxHHI'] <= hhiMax) {
                              <span class="mx-1">to</span>
                            }
                            <span>{{quota.targetGroup['maxHHI'] > hhiMax ? '&nbsp;and above' : (quota.targetGroup['maxHHI'] | currency :'USD':'symbol':'1.0-0')}}</span>
                          }
                        </span>
                      }
                      @else if (questions[qId].type == 'Age') {
                        <span>
                          {{quota.targetGroup.minAge}}
                        </span>
                        to
                        <span>
                          {{quota.targetGroup.maxAge}}
                        </span>
                      }
                    }
                    @if (group.questionIdArray[$index+1]){
                      ,&nbsp;
                    }
                  }
                </span>
              }
              <span></span>
              <!-- limit number -->
              <div class="d-flex justify-content-center">
                <div class="input-group goal-input" (click)="inputLimitN.focus()">
                  <input #inputLimitN (keyup.enter)="inputLimitN.blur()" (blur)="handleLimitChange(quota, 'n', null, $event)" type="text"
                    class="form-control form-control-nav nav-font14 text-left" [class.clickable]="quota.id ===  newQuota?.id"
                    oninput="this.value = this.value.replace(/[^0-9*.]/g, '').replace(/(\..*)\./g, '$1');"
                    [value]="quota.limitN">
                    <div class="input-group-append nav-font14 grey6 px-1">n</div>
                </div>
              </div>
              <span></span>
              <!-- limit percentage -->
              <div class="d-flex justify-content-center">
                <div class="input-group percentage-input" (click)="inputLimitP.focus()">
                  <input #inputLimitP (keyup.enter)="inputLimitP.blur()" (blur)="handleLimitChange(quota, '%', null, $event)" type="text"
                    class="form-control form-control-nav nav-font14 text-left" [class.clickable]="quota.id ===  newQuota?.id"
                    oninput="this.value = this.value.replace(/[^0-9*.]/g, '').replace(/(\..*)\./g, '$1');"
                    [value]="quota.limitPercent">
                    <div class="input-group-append nav-font14 grey6 px-1">%</div>
                </div>
              </div>
            </div>
          </ng-container>
        }
      }
      <!-- if not nested -->
      @if (group.questionId.length <= 36) {
        <!-- btn to add back deleted quotas -->
        <div class="d-flex align-items-center mt-3">
          <div class="d-flex align-items-center nav-font14 text-nowrap ml-4">
            @if (questions[group.questionId].type == 'HouseholdIncome') {
              <span tabindex="-1" class="font-weight-bold nav-accent-blue p-0 pl-2 pr-2 d-flex align-items-center" [ngClass]="newQuota?.groupId === group.id ? 'clickable disabledBtn' : ''" type="button" (click)="newQuota?.groupId !== group.id ? addBlankQuota(group, 'HouseholdIncome') : null">
                <i class="far fa-plus icon nav-font14 mr-2" [ngClass]="newQuota?.groupId === group.id ? 'clickable disabledBtn' : ''"></i>Add
              </span>
            }
            @if (questions[group.questionId].type == 'Age') {
              <span tabindex="-1" class="font-weight-bold nav-accent-blue p-0 pl-2 d-flex align-items-center" [ngClass]="newQuota?.groupId === group.id ? 'clickable disabledBtn' : ''" type="button" (click)="newQuota?.groupId !== group.id ? addBlankQuota(group, 'Age') : null">
                <i class="far fa-plus icon nav-font14 mr-2" [ngClass]="newQuota?.groupId === group.id ? 'clickable disabledBtn' : ''" ></i>Add
              </span>
            }
            @if (group.deletedOptions?.length > 0 && group.name !== 'Income' && group.name !== 'Age') {
              <span dropdown dropdownToggle #addBackDropdown="bs-dropdown" tabindex="-1" class="font-weight-bold nav-accent-blue p-0 d-flex align-items-center position-relative addBackBtn" type="button">
                <i class="far fa-plus icon nav-font14 mr-2"></i>Add
                <div *dropdownMenu class="dropdown-menu dropdown-menu-left nav-font14" role="menu" aria-labelledby="button-basic">
                  @for (option of group.deletedOptions; track option; let isLast = $last) {
                    <div class="dropdown-item text-truncate my-1 px-3" (click)="option.name === 'pnta' ? addBackPNTA(group) : addBackQuota(group, option)"
                      [class.font-italic]="option.name === 'pnta'">{{option.name === 'pnta' ? 'Prefer not to answer' : option.name}}
                    </div>
                    @if (!isLast) {
                      <div class="seperator px-1"><hr class="my-0"></div>
                    }
                  }
                </div>
              </span>
            }
            @if (group.name == 'Income' && group.deletedOptions.length > 0) {
              <span class="font-weight-bold nav-accent-blue cursor-pointer p-0 clickable" [ngClass]="newQuota?.groupId === group.id ? 'clickable disabledBtn' : ''"  (click)="newQuota?.groupId !== group.id ? addBackPNTA(group) : null">
                <i class="far fa-plus icon nav-font14 mr-2"  [ngClass]="newQuota?.groupId === group.id ? 'clickable disabledBtn' : ''" ></i>Prefer not to answer
              </span>
            }
            @if (group.deletedOptions?.length > 0 || group.name === 'Income' || group.name === 'Age') {
              <div class="seperator ml-3"></div>
            }
            @if (group.deletedOptions?.length == 0) {
              <div class="seperator ml-4"></div>
            }
          </div>
        </div>
      }
        <!-- 'Other' (if quota was removed or added mid project) -->
        <!-- <div *ngIf="group.nonValueStarts" class="quota-row align-items-center nav-font14 grey5">
        <span></span><span></span>
        <div class="">Other</div>
        <span></span>
        <div class="d-flex justify-content-center">
          <div class="goal-input px-2">
            {{ projectQuota.limitMode == 'complete' ? group.nonValueCompletes : group.nonValueStarts}} n
          </div>
        </div>
        <span></span>
        <div class="d-flex justify-content-center">
          <div class="percentage-input px-2">
          {{ (projectQuota.limitMode == 'complete' ? ((group.nonValueCompletes*100)/ project.fullLaunchQuota || 0)   : ((group.nonValueStarts*100)/ project.fullLaunchQuota || 0)) | number: '1.0-0' }} %            </div>
          </div>
        </div> -->
    
      <!-- last row: adding quotas and totals -->
      <div class="quota-row mt-3 nav-secondary nav-font16 font-weight-bold">
        <!-- adding quotas -->
        <span></span><span></span>
        <div class="d-flex justify-content-end">
          Total
        </div>
        <span></span>
        <div class="d-flex justify-content-center">
          <div class="goal-input text-right">
            {{quotaGroupTotals[group.id] ? quotaGroupTotals[group.id].n : ''}} n
          </div>
        </div>
        <span></span>
        <div class="d-flex justify-content-center">
          <div class="percentage-input text-right">
            {{quotaGroupTotals[group.id] ? quotaGroupTotals[group.id].percent : ''}} %
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #quotaConfig>
  @if (quotaConfigEditDetails; as config) {
    <div class="card py-4 pl-4 pr-3">
    <div class="card-header nav-font24 nav-secondary font-weight-bold p-0 mb-4" [ngClass]="config.mode == 'add' && config.step == 1 ? 'pb-2' : ''">
      <div class="d-flex align-items-center position-relative">
        <span class="">{{config.mode === 'add' ? 'Add Quotas' : (config.mode === 'edit' ? 'Quota Settings' : 'Nest Quotas')}}</span>
        <i class="fa fa-times nav-font20 grey4 cursor-pointer position-absolute" (click)="openModalRefQuotaConfig.hide()"></i>
      </div>
    </div>
    <div class="card-body nav-font16 font-weight-bold grey5 p-0">
      @if (config.mode == 'add' && config.step == 1) {
        <div class="mb-4">Select the demographic quotas needed for your survey then click 'Next'
          <span class="grey3 font-weight-normal ml-2">(step 1 of 2)</span>
        </div>
        <div class="nav-font14 grey5 font-weight-normal ml-1">
          <div class="or-checkbox d-flex p-1 mb-2">
            <input type="checkbox" name="quotas-select-all" id="quotas-select-all" [checked]="quotaConfigEditDetails.selectAllQuotas" (change)="quotaConfigEditDetails.selectAllQuotas = $event.target.checked; selectAllQuotasInConfig($event)" >
            <label for="quotas-select-all" class="select-all"></label>
            <span class="ml-4">Select all</span>
          </div>
          @for (quota of config.quotasNotAdded; track quota.id) {
            <div class="or-checkbox d-flex p-1 mb-2">
              <input type="checkbox" name="quota-{{quota.value}}" id="quota-{{quota.value}}"
                [checked]="quota.selected" (change)="quota.selected = $event.target.checked; quotaSelected()">
                <label for="quota-{{quota.value}}"></label>
                <span class="ml-4">{{quota.value == 'HouseholdIncome' ? 'Income' : quota.value}}</span>
            </div>
          }
        </div>
      }
      @else if (config.mode == 'nest') {
        <div class="mb-3">Select up to three quotas to nest together. Once selected, click 'Nest'.
        </div>
        <div class="font-weight-normal nav-font14 mb-4 pb-2">You can use drag and drop to change the order of the quotas. Once nested, the top quota in the list will appear first in the card and table.</div>
        <div class="nav-font14 grey5 font-weight-normal ml-1 nestList"
          [dndDropzone]="['quota']"
          (dndDrop)="onDrop($event)">
          <div class="d-flex align-items-center mb-3" dndPlaceholderRef>
            <i class="fas fa-grip-lines opacity-20 pl-3"></i>
          </div>
          @for (quota of config.quotasAdded; track quota.id) {
            <div class="d-flex align-items-center mb-3"
                [dndDraggable]="quota"
                dndType="quota"
                dndEffectAllowed="move">
              <i class="fak fa-drag grey4 nav-font24 font-weight-normal clickable mr-1" dndHandle></i>
              <div class="or-checkbox d-flex p-1">
                <input type="checkbox" name="quota-{{quota.value}}" id="quota-{{quota.value}}"
                  [disabled]="!quota.selected && config.atLeastThreeSelected"
                  [checked]="quota.selected" (change)="quota.selected = $event.target.checked; quotaSelectedForNest()">
                  <label for="quota-{{quota.value}}"></label>
                  <span class="ml-4 nav-font16">{{quota.value == 'HouseholdIncome' ? 'Income' : quota.value}}</span>
              </div>
            </div>
          }
        </div>
      }
      @if (config.mode == 'edit' || config.step == 2) {
        <div>Select how you want to control your quotas, then click '{{config.mode == 'add' ? 'Add' : 'Save'}}'
          @if (config.step == 2) {
            <span class="grey3 font-weight-normal ml-2">(step 2 of 2)</span>
          }
        </div>
        <div class="font-weight-normal mt-3 mb-4 pb-2">
          @if (config.mode == 'add') {
            The control options you select will apply to all quotas in this project. You may later edit your control options in quota settings.
          }
          @if (config.mode == 'edit') {
            The control options you select will apply to all quotas in this project.
          }
        </div>
        <div class="nav-font14 grey5">
          <div class="row mb-4 pb-3">
            <div class="col-4 font-weight-bold pr-0">Base quota goals on</div>
            <div class="col font-weight-normal">
              <div class="radio-group">
                <div class="or-radio mb-4">
                  <input type="radio" name="limitModeComplete" id="limitModeComplete" [checked]="config.limitMode == 'complete'" (change)="config.limitMode = 'complete'">
                  <label for="limitModeComplete"></label>
                  Completes
                  <i class="far fa-light fa-info-circle nav-font14 grey4 cursor-default ml-2" placement="bottom" tooltip="This will set the number of starts as the goal/limit post-CleanID for live traffic in the survey"></i>
                </div>
                <div class="or-radio">
                  <input type="radio" name="limitModeStart" id="limitModeStart" [checked]="config.limitMode == 'start'" (change)="config.limitMode = 'start'">
                  <label for="limitModeStart"></label>
                  Starts
                  <i class="far fa-light fa-info-circle nav-font14 grey4 cursor-default ml-2" placement="bottom" tooltip="This will set the number of completes as the goal/limit that return from the survey"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-4 pb-3">
            <div class="col-4 font-weight-bold pr-0">Distribute quotas by using</div>
            <div class="col font-weight-normal">
              <div class="radio-group">
                <div class="or-radio mb-4">
                  <input type="radio" name="distributeByCensus" id="distributeByCensus" [checked]="config.distributeBy == 'census'" (change)="config.distributeBy = 'census'">
                  <label for="distributeByCensus"></label>
                  Census
                </div>
                <div class="or-radio mb-4">
                  <input type="radio" name="distibuteByCustom" id="distibuteByCustom" [checked]="config.distributeBy == 'custom'" (change)="config.distributeBy = 'custom'">
                  <label for="distibuteByCustom"></label>
                  Custom
                </div>
                <div class="or-radio">
                  <input type="radio" name="distibuteByEqual" id="distibuteByEqual" [checked]="config.distributeBy == 'equal'" (change)="config.distributeBy = 'equal'">
                  <label for="distibuteByEqual"></label>
                  Equal distribution
                </div>
              </div>
            </div>
          </div>
          <div class="row d-flex align-items-center mb-4 pb-3">
            <div class="col-4 font-weight-bold pr-0">Utilize vendor level quotas</div>
            <div class="col font-weight-normal">
              <or-toggle-button  class="light" size="small" [isChecked]="config.enableVendorLimits" (changed)="config.enableVendorLimits=$event"></or-toggle-button>
            </div>
          </div>
          <div class="row mb-4 pb-3">
            <div class="col-4 font-weight-bold pr-0">Select your quota control</div>
            <div class="col font-weight-normal">
              <div class="radio-group">
                <div class="or-radio mb-4">
                  <input type="radio" name="limitCheckTracking" id="limitCheckTracking" [checked]="config.limitCheck == 'tracking'" (change)="config.limitCheck = 'tracking'; config.applyFlexibility = false; config.distributionFlexibility = null">
                  <label for="limitCheckTracking"></label>
                  Tracking only
                  <i class="far fa-light fa-info-circle nav-font14 grey4 cursor-default ml-2" placement="bottom" tooltip="Allow traffic to continue after a goal is met"></i>
                </div>
                <div class="or-radio">
                  <input type="radio" name="limitCheckEnforced" id="limitCheckEnforced" [checked]="config.limitCheck == 'enforced'" (change)="config.limitCheck = 'enforced'">
                  <label for="limitCheckEnforced"></label>
                  Enforce goals
                  <i class="far fa-light fa-info-circle nav-font14 grey4 cursor-default ml-2" placement="bottom" tooltip="Pause traffic for the quota selection once the limit is reached"></i>
                </div>
              </div>
            </div>
          </div>
          <div [style.visibility]="config.limitCheck == 'enforced' ? 'visible' : 'hidden'" class="row d-flex align-items-center mb-4 pb-3 height25">
            <div class="col-4 font-weight-bold pr-0">Apply flexibility to distibution</div>
            <div class="col-1 font-weight-normal">
              @if (config.limitCheck == 'enforced') {
                <or-toggle-button class="light" size="small" [isChecked]="config.applyFlexibility"
                (changed)="config.applyFlexibility=$event; config.distributionFlexibility = $event == false ? null : 5"></or-toggle-button>
              }
            </div>
            @if (config.applyFlexibility) {
              <div class="col font-weight-normal d-flex align-items-center text-nowrap pl-1 ml-3">
                Apply limit flexibility by
                <div class="input-group ml-2" (click)="distributionFlexibility.focus()">
                  <input #distributionFlexibility
                    class="form-control form-control-nav nav-font14 grey5"
                    type="text"
                    mask="99"
                    [specialCharacters]="[]"
                    [(ngModel)]="config.distributionFlexibility">
                    <div class="input-group-append grey4">%</div>
                  </div>
                </div>
              }
            </div>
          </div>
        }
      </div>
      <div class="card-footer p-0">
        <div class="float-right">
          <button type="button" class="btn nav-btn-primary shadow-none font-weight-bold py-1 mx-2" (click)="openModalRefQuotaConfig.hide()">Cancel</button>
          <button type="button" class="btn nav-btn-accent shadow-none font-weight-bold py-1"
            [class.disabledBtn]="(config.mode == 'add' && !config.atLeastOneQuotaSelected) || (config.mode == 'nest' && !config.atLeastTwoQuotasSelected)" (click)="(config.mode !== 'add' || config.atLeastOneQuotaSelected) ? nextInQuotaConfigModal() : null">
            @if (config.mode == 'add' && config.step == 1) {
              Next
            }
            @else if (config.mode == 'add' && config.step == 2) {
              Add
            }
            @else if (config.mode == 'nest') {
              Nest
            }
            @else {
              Save
            }
          </button>
        </div>
      </div>
    </div>
  }
</ng-template>