import { NgModule  } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TeamComponent } from './team.component';
import { ComponentsModule, HelpersModule } from 'core';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    HelpersModule
  ],
  declarations: [TeamComponent]
})
export class TeamModule { }
