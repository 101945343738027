<div class="card m-2">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h3 class="nav-secondary font-weight-bold text-truncate">Documents</h3>
      </div>
      <div class="col text-right">
        <or-spinner-button label="Upload" #spinner customClass="btn-outline-light btn-sm opacity-90" showSpinnerOnCallback=true icon="fas fa-upload" (callback)="fileInput.click()"></or-spinner-button>
        <input #fileInput type="file" (change)="uploadFile($event)" onclick="this.value = null" style="display:none;"/>
      </div>
    </div>
  </div>
  <div class="card-body scrollbar overflow-y">
    <div class="row" *ngIf="files.length > 0">
      <ul class="navs-icon list-unstyled p-2 px-4">
        <li>
          <i class="fas fa-folder-open or-secondary opacity-90 cursor-auto"></i>
        </li>
        <li class="pl-4" *ngFor="let link of files">
          <div id="files">

            <a (click)="downloadFile(link)">
              <i class="fas fa-angle-right"></i>
              <span class="font-weight-normal ml-1 cursor-pointer"> {{link.fileName}}
              </span>
            </a>
            <button (click)="openModal(modalConfirm, link)" id="deleteFile" label="Delete" type="button" icon="fas fa-trash" class="btn btn-outline-secondary shadow-sm mx-1">
              <i class="fas fa-times fa-xs"></i>
            </button>
        </div>
        </li>
      </ul>
    </div>

    <div class="row" *ngIf="files.length === 0">
      <div class="col or-secondary small">No documents available</div>
    </div>

  </div>
</div>

<ng-template #modalConfirm>
  <div class="modal-header">
    <p class="h5">Confirm Delete</p>
  </div>
  <div class="modal-body text-center">
    <p class="h6">Are you sure you want to delete ?</p>
    <button type="button" class="btn btn-outline-secondary m-2" (click)="confirmDelete()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="openModalRef.hide()">No</button>
  </div>
</ng-template>




