<div class="card border-0">
  <div class="card-header border-bottom">
    <app-widget-title [widget]="widget" (remove)="remove()"></app-widget-title>
  </div>
  <div class="card-body">
    <ng-container *ngIf="highchartOptions != null">
      <highcharts-chart
        [Highcharts]="highcharts"
        [options]="highchartOptions"
        style="width: 100%; height: 100%; display: block;">
      </highcharts-chart>
    </ng-container>
  </div>
</div>
