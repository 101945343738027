<div class="sidebar {{mode}}">

  <!-- Sidebar toggle -->
  <ng-container *ngIf="!_isSurveyTester && !isProjectSectionOpen">
    <div (click)="toggleSidebar()" class="sidebar-toggle d-flex align-items-center justify-content-center cursor-pointer">
      <i *ngIf="mode === 'reduced'" class="fak fa-xs fa-right-arrow text-white"></i>
      <i *ngIf="mode !== 'reduced'" class="fak fa-xs fa-right-arrow text-white fa-rotate-180"></i>
    </div>
  </ng-container>

  <a class="d-block p-2 mt-3 ml-3 cursor-pointer"  routerLink="{{defaultSectionUrl}}" title="OpinionRoute - Navigator">
    <img class="or-logo" [src]="logoSrc" />
  </a>
  <ul class="nav nav-pills main-menu flex-column mb-auto mt-4 {{mode}}">
    <ng-container *ngFor="let section of siteSections">
      <!-- parent group -->
      <ng-container *ngIf="section?.type == null">
        <a (click)="handleSection(section)"
            class="nav-item d-flex align-items-center cursor-pointer text-center text-truncate ml-3 mx-3 px-2"
            [class.justify-content-center]="mode == 'reduced'"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div  class="icon d-flex align-items-center justify-content-center"
              [tooltip]="mode == 'reduced' ? section.label : ''"
              placement="right"
              container="body">
            <i [class]="section.icon"></i>
          </div>
          <span [class.d-none]="mode === 'reduced'" class="nav-link nav-font14 py-1 text-truncate">{{section.label}}</span>
        </a>
        <ng-container  *ngIf="section.open==true">
          <div class="group-menu">
            <ng-container *ngFor="let subsection of siteSections">
              <ng-container *ngIf="subsection.parentId == section.id">
                <a
                  [routerLink]="subsection.url"
                  href="#"
                  class="sub-item d-flex align-items-center cursor-pointer text-center text-truncate px-1"
                  [class.justify-content-center]="mode == 'reduced'"
                  routerLinkActive="active-link"
                  [routerLinkActiveOptions]="{exact: false}">
                <div class="icon d-flex align-items-center justify-content-center"
                    [tooltip]="mode == 'reduced' ? subsection.label : ''"
                    placement="right"
                    container="body">
                  <i [class]="subsection.icon"></i>
                </div>
                <span [class.d-none]="mode === 'reduced'" class="nav-link nav-font14 py-1 text-truncate" >{{subsection.label}}</span>
              </a>
              </ng-container>
            </ng-container>
          </div>
         </ng-container>
      </ng-container>

      <!-- regular item -->
      <ng-container *ngIf="section?.parentId == null && section?.type != null">
        <ng-container *ngIf="section?.type != 'ProjectList' && !_isSurveyTester">
          <a
              [routerLink]="section.url"
              href="#"
              class="nav-item d-flex align-items-center cursor-pointer text-center text-truncate ml-3 mx-3 px-2"
              [class.justify-content-center]="mode == 'reduced'"
              routerLinkActive="active-link"
              [routerLinkActiveOptions]="{exact: true}">
            <div class="icon d-flex align-items-center justify-content-center"
                [tooltip]="mode == 'reduced' ? section.label : ''"
                placement="right"
                container="body">
              <i [class]="section.icon"></i>
            </div>
            <span [class.d-none]="mode === 'reduced'" class="nav-link nav-font14 py-1 text-truncate" >{{section.label}}</span>
          </a>
        </ng-container>
        <ng-container *ngIf="!projectOpen && (section?.type == 'ProjectList' || _isSurveyTester)">
          <a
              [routerLink]="section.url"
              href="#"
              class="nav-item d-flex align-items-center cursor-pointer text-center text-truncate ml-3 mx-3 px-2"
              [class.justify-content-center]="mode == 'reduced'"
              routerLinkActive="active-link"
              [routerLinkActiveOptions]="{exact: true}">
            <div class="icon d-flex align-items-center justify-content-center"
                [tooltip]="mode == 'reduced' ? section.label : ''"
                placement="right"
                container="body">
              <i [class]="section.icon"></i>
            </div>
            <span [class.d-none]="mode === 'reduced'" class="nav-link nav-font14 py-1 text-truncate" >{{section.label}}</span>
          </a>
        </ng-container>
      </ng-container>

      <!-- project sidebar opened -->
      <ng-container *ngIf="projectOpen && section?.type == 'ProjectList'">
        <a class="nav-item d-flex align-items-center cursor-pointer text-center text-truncate ml-3 mx-3 px-2"
            [routerLink]="section.url"
            href="#"
            [class.justify-content-center]="mode == 'reduced'"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="icon d-flex align-items-center justify-content-center"
              [tooltip]="mode == 'reduced' ? 'Project List' : ''"
              placement="right"
              container="body">
              <i [class]="section.icon"></i>
          </div>
          <span [class.d-none]="mode === 'reduced'" class="nav-link nav-font14 py-1 text-truncate">Project List</span>
        </a>
        <div class="project-menu">
          <a class="sub-item d-flex align-items-center cursor-pointer text-center text-truncate px-1" (click)="changeProjectSidebar('info')"
              [class.justify-content-center]="mode == 'reduced'"
              [class.active]="mode=='reduced' && sidebarProject=='info'"
              routerLinkActive="active-link"
              [routerLinkActiveOptions]="{exact: false}">
            <div class="icon d-flex align-items-center justify-content-center"
                [tooltip]="mode == 'reduced' ? 'Information' : ''"
                placement="right"
                container="body">
              <i class="fak fa-info fa-md"></i>
            </div>
            <span [class.d-none]="mode === 'reduced'" class="nav-link nav-font14 py-1 text-truncate">Information</span>
          </a>
          <!-- <hr class="my-0 mx-2"> -->
          <ng-container *ngIf="isInternal && !isVendorview && !isClientview && !(_project.projectStages == null || _project.projectStages.length == 0)">
            <a class="sub-item d-flex align-items-center cursor-pointer text-center text-truncate px-1" (click)="changeProjectSidebar('my-tasks')"
              [class.justify-content-center]="mode == 'reduced'"
              [class.active]="mode=='reduced' && sidebarProject=='my-tasks'"
              routerLinkActive="active-link"
              [routerLinkActiveOptions]="{exact: false}">
              <div class="icon d-flex align-items-center justify-content-center"
                  [tooltip]="mode == 'reduced' ? 'My Tasks' : ''"
                  placement="right"
                  container="body">
                <i class="fak fa-my-tasks fa-md"></i>
              </div>
              <span [class.d-none]="mode === 'reduced'" class="nav-link nav-font14 py-1 text-truncate">My Tasks</span>
            </a>
          </ng-container>
          <ng-container *ngIf="isInternal && !isVendorview && !isClientview">
            <a class="sub-item d-flex align-items-center cursor-pointer text-center text-truncate px-1" (click)="changeProjectSidebar('notes')"
            [class.justify-content-center]="mode == 'reduced'"
            [class.active]="mode=='reduced' && sidebarProject=='notes'"
            *ngIf="_projectType?.showNotes"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: false}">
              <div class="icon d-flex align-items-center justify-content-center"
                  [tooltip]="mode == 'reduced' ? 'Notes' : ''"
                  placement="right"
                  container="body">
                <i class="fak fa-notes fa-md"></i>
              </div>
              <span [class.d-none]="mode === 'reduced'" class="nav-link nav-font14 py-1 text-truncate">Notes</span>
            </a>
          </ng-container>
          <a class="sub-item d-flex align-items-center cursor-pointer text-center text-truncate px-1" (click)="changeProjectSidebar('project-alerts')"
              [class.justify-content-center]="mode == 'reduced'"
              [class.active]="mode=='reduced' && sidebarProject=='project-alerts'"
              routerLinkActive="active-link"
              [routerLinkActiveOptions]="{exact: false}">
            <div class="icon d-flex align-items-center justify-content-center"
                [tooltip]="mode == 'reduced' ? 'Project Alerts' : ''"
                placement="right"
                container="body">
              <i class="fak fa-project-alerts fa-md"></i>
            </div>
            <span [class.d-none]="mode === 'reduced'" class="nav-link nav-font14 py-1 text-truncate">Project Alerts</span>
          </a>
          <ng-container *ngIf="isInternal && !isVendorview && !isClientview">
            <a class="sub-item d-flex align-items-center cursor-pointer text-center text-truncate px-1" (click)="changeProjectSidebar('tools')"
              [class.justify-content-center]="mode == 'reduced'"
              [class.active]="mode=='reduced' && sidebarProject=='tools'"
              *ngIf="_projectType?.showTools"
              routerLinkActive="active-link"
              [routerLinkActiveOptions]="{exact: false}">
            <div class="icon d-flex align-items-center justify-content-center"
                [tooltip]="mode == 'reduced' ? 'Tools' : ''"
                placement="right"
                container="body">
              <i class="fak fa-tools fa-md"></i>
            </div>
            <span [class.d-none]="mode === 'reduced'" class="nav-link nav-font14 py-1 text-truncate">Tools</span>
            </a>
            <a class="sub-item d-flex align-items-center cursor-pointer text-center text-truncate px-1" (click)="changeProjectSidebar('downloads')"
                [class.justify-content-center]="mode == 'reduced'"
                [class.active]="mode=='reduced' && sidebarProject=='downloads'"
                *ngIf="_projectType?.showDownload"
                routerLinkActive="active-link"
                [routerLinkActiveOptions]="{exact: false}">
              <div class="icon d-flex align-items-center justify-content-center"
                  [tooltip]="mode == 'reduced' ? 'Data Exports' : ''"
                  placement="right"
                  container="body">
                <i class="fak fa-downloads fa-md"></i>
              </div>
              <span [class.d-none]="mode === 'reduced'" class="nav-link nav-font14 py-1 text-truncate">Data Exports</span>
            </a>
          </ng-container>
        </div>
      </ng-container>
      <!-- project summary sidebar opened -->
      <ng-container *ngIf="projectSummaryOpen && section?.type == 'ProjectList'">
        <div class="project-menu">
          <!-- Information -->
          <a
            class="sub-item d-flex align-items-center cursor-pointer text-center px-1"
            (click)="changeProjectSidebar('info')"
            [class.justify-content-center]="mode == 'reduced'"
            [class.active]="mode=='reduced' && sidebarProject=='info'"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
            <div
              class="icon d-flex align-items-center justify-content-center"
              [tooltip]="mode == 'reduced' ? 'Information' : ''"
              placement="right"
              container="body">
              <i class="fak fa-info fa-md"></i>
            </div>
            <span *ngIf="mode !== 'reduced'" class="nav-link nav-font14 py-1">Information</span>
          </a>
          <!-- My Tasks -->
          <ng-container *ngIf="isInternal && tasks != null && !isClientview">
            <a
              class="sub-item d-flex align-items-center cursor-pointer text-center px-1"
              (click)="changeProjectSidebar('my-tasks')"
              [class.justify-content-center]="mode == 'reduced'"
              [class.active]="mode=='reduced' && sidebarProject=='my-tasks'"
              routerLinkActive="active-link"
              [routerLinkActiveOptions]="{exact: true}">
              <div
                class="icon d-flex align-items-center justify-content-center"
                [tooltip]="mode == 'reduced' ? 'My Tasks' : ''"
                placement="right"
                container="body">
                <i class="fak fa-my-tasks fa-md"></i>
              </div>
              <span *ngIf="mode !== 'reduced'" class="nav-link nav-font14 py-1">My Tasks</span>
            </a>
          </ng-container>
          <!-- Notes -->
          <ng-container *ngIf="isInternal && notes != null && !isClientview">
            <a
              class="sub-item d-flex align-items-center cursor-pointer text-center px-1"
              (click)="changeProjectSidebar('notes')"
              [class.justify-content-center]="mode == 'reduced'"
              [class.active]="mode=='reduced' && sidebarProject=='notes'"
              *ngIf="_projectType?.showNotes"
              routerLinkActive="active-link"
              [routerLinkActiveOptions]="{exact: true}">
              <div
                class="icon d-flex align-items-center justify-content-center"
                [tooltip]="mode == 'reduced' ? 'Notes' : ''"
                placement="right"
                container="body">
                <i class="fak fa-notes fa-md"></i>
              </div>
              <span *ngIf="mode !== 'reduced'" class="nav-link nav-font14 py-1">Notes</span>
            </a>
          </ng-container>
          <!-- Project Alerts -->
          <ng-container *ngIf="rules != null">
            <a
              class="sub-item d-flex align-items-center cursor-pointer text-center px-1"
              (click)="changeProjectSidebar('project-alerts')"
              [class.justify-content-center]="mode == 'reduced'"
              [class.active]="mode=='reduced' && sidebarProject=='project-alerts'"
              routerLinkActive="active-link"
              [routerLinkActiveOptions]="{exact: true}">
              <div
                class="icon d-flex align-items-center justify-content-center"
                [tooltip]="mode == 'reduced' ? 'Project Alerts' : ''"
                placement="right"
                container="body">
                <i class="fak fa-project-alerts fa-md"></i>
              </div>
              <span *ngIf="mode !== 'reduced'" class="nav-link nav-font14 py-1">Project Alerts</span>
            </a>
          </ng-container>
          <!-- Downloads -->
          <ng-container *ngIf="isInternal">
            <a
              class="sub-item d-flex align-items-center cursor-pointer text-center px-1"
              (click)="changeProjectSidebar('downloads')"
              [class.justify-content-center]="mode == 'reduced'"
              [class.active]="mode=='reduced' && sidebarProject=='downloads'"
              *ngIf="_projectType?.showDownload"
              routerLinkActive="active-link"
              [routerLinkActiveOptions]="{exact: true}">
              <div
                class="icon d-flex align-items-center justify-content-center"
                [tooltip]="mode == 'reduced' ? 'Downloads' : ''"
                placement="right"
                container="body">
                <i class="fak fa-downloads fa-md"></i>
              </div>
              <span *ngIf="mode !== 'reduced'" class="nav-link nav-font14 py-1">Downloads</span>
            </a>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ul>

  <or-help *ngIf="showHelp" (closeHelp)="showHelp=false"></or-help>
  <!-- User Info -->
  <ul class="nav nav-pills bottom-menu flex-column mb-auto fixed-bottom position-absolute">
    <li class="nav-item cursor-pointer text-center px-2 mt-3">
      <span class="nav-link d-flex align-items-center">
        <div (click)="showHelp = !showHelp">
          <img src="assets/imgs/help.png" />
          <span *ngIf="mode !== 'reduced'" class="nav-font14 px-2 grey4">Help</span>
        </div>
      </span>
    </li>
    <li class="profile d-flex justify-content-start align-items-center px-4 py-2">
      <a type="button" class="avatar d-flex align-items-center dropdown-toggle" (click)="dropdown.toggle(true)">
        <or-profile-image [url]="this.auth.getUser()?.image" [name]="this.auth.getUser()?.name" showInitials="true"></or-profile-image>
        <span *ngIf="mode !== 'reduced'" class="nav-font12 grey5 pr-0 px-2 text-wrap">{{this.auth.getUser()?.name}}</span>
      </a>
    </li>
  </ul>
  <!-- User profile dropdown menu -->
  <div class="fixed-bottom position-absolute profile-dropdown" dropdown #dropdown="bs-dropdown">
    <ul class="dropdown-menu position-relative grey4 cursor-pointer" *dropdownMenu role="menu" aria-labelledby="button-triggers-manual" (mouseleave)="dropdown.hide()">
      <a class="dropdown-item" [routerLink]="['/changepassword']" href="#" (click)="false"><i class="fas fa-key or-secondary"></i> Change Password</a>
      <a class="dropdown-item" [routerLink]="['/account']" href="#" (click)="false"><i class="fas fa-user-circle or-secondary"></i> User Settings</a>
      <a class="dropdown-item" *ngIf="isInternal" [routerLink]="['/reports/audit']" href="#" (click)="false"><i class="fa-file-alt far"></i> Audit Log</a>
      <a class="dropdown-item" (click)="openImpersonate(modalImpersonate)" *ngIf="isSuperAdmin || isAbleToImpersonate"><i class="fas fas fa-user-secret or-secondary"></i> Impersonate</a>
      <a class="dropdown-item" href="#" (click)="goToAdminConsole($event)" *ngIf="isAdmin"><i class="fas fa-cog or-secondary"></i> Admin Console</a>
      <a class="dropdown-item" (click)="logout()"><i class="fas fa-sign-out-alt or-secondary"></i> Log Out</a>
    </ul>
  </div>
</div>

<!-- MODAL Export Reports -->
<ng-template #modalImpersonate>
  <div class="modal-header">
    <p class="h5">Impersonate Users</p>
    <button type="button" class="close pull-right text-white" aria-label="Close" (click)="openModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div  class="float-right ml-2">
      <input [(ngModel)]="filterText" (ngModelChange)='updateimpersonateUsersList()' placeholder="Filter by Name or Email" type="search" class="form-control">
    </div>
    <div class="mt-0">
      <or-list [items]="impersonateUsers | filter : filterText : filterColumns" [layout]="layout" (selected)="impersonate($event)"></or-list>
    </div>
  </div>
</ng-template>
