import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ScheduleService, AlertifyService, UtilsService, AuthService } from 'core';

@Component({
  selector: 'app-mytask',
  templateUrl: './mytask.component.html',
  styleUrls: ['./mytask.component.scss'],
})
export class MytaskComponent implements OnChanges {

  @Input() refresh: number = 0; // used to force component refresh

  @Input() public set project(data) {
    if (data) {
      this._project = data;
      this.getMyTask();
    }
  };
  @Input() public set bidNumber(data) {
    if (data) {
      this._bidNumber = data;
      this.getMyTaskSummary();
    }
  };

  @Output() callback = new EventEmitter();

  tasks: any = {};
  taskCount = 0;
  _project;
  _bidNumber = '';
  today = new Date().toISOString();

  showAll = true;
  fullList: any[] = [];
  filtersApplied = [];
  filterValues:any = [];

  sortBy = 'date';
  sortByValues = [
    {label: 'Sort by date', value: 'date'},
    {label: 'Sort by project', value: 'project'}
  ];

  constructor(
    private scheduleService: ScheduleService,
    private alertify: AlertifyService,
    public utils: UtilsService,
    public auth: AuthService) { }

  ngOnChanges() {
    //this.getMyTask();
    this._bidNumber == ''
      ? this.getMyTask()
      : this.getMyTaskSummary();
  }

  applyFilterSummary(filters) {
    this.filtersApplied = filters?.filter(x => x.value !== 'showAll' && x?.selected).map(x => x.value);
    this.showAll = filters?.some(x => x.value == 'showAll' && x?.selected);
    this.filterListSummary();
  }

  applySorting(value) {
    this.sortBy = value
  }

  filterListSummary() {
    let tasks = [...this.fullList];

    tasks = tasks.filter(item => {
      if (!this.showAll)
        return item.projectTasks.some(task => this.filtersApplied.includes(task.projectId));
      else
        return item;
    });

    this.handleTasks(tasks);
    //this.tasks = tasks;
  }

  getMyTask() {
    this.scheduleService.GetProjectSchedule(this._project.id).subscribe(data => {
      this.handleTasks(data);
    }, error => {
        this.alertify.error('Unable to get tasks');
    });
  }
  getMyTaskSummary() {
    this.scheduleService.GetProjectScheduleSummary(this._bidNumber).subscribe(data => {
      this.handleTasks(data);

      let distinctProjects = [...new Set(this.tasks.projects.map(item => item.projectCode))];

      this.filterValues = [];
      distinctProjects.map(projectCode => {
        const projectId = this.tasks.projects.find(x => x.projectCode == projectCode)?.projectId;
        this.filterValues.push({ label: projectCode, value: projectId, group: 'Project Number' });
      });

      this.filterValues.unshift({ label: 'Show All', value: 'showAll', selected:true });
      this.fullList = data;

    }, error => {
        this.alertify.error('Unable to get tasks');
    });
  }
  private handleTasks(data) {
    const dateGroups = this.groupByDate(data);
    this.tasks.today = this.sortByDueDate(dateGroups.today);
    this.tasks.upcoming = this.sortByDueDate(dateGroups.upcoming);
    this.tasks.projects = this.groupByProject(data);
    this.taskCount = this.tasks.today.length + this.tasks.upcoming.length;
  }

  CompleteTask(taskId) {
    this.scheduleService.CompleteTask(taskId).subscribe((data) => {
      this.callback.emit(true);
      this.getMyTask();
    }, error => {
      this.alertify.error(error);
    });
  }

  groupByDate(stages:any) {
    const today = new Date();
    const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const userId = this.auth.getUser().id;

    const dateGroup = {
      today: [],
      upcoming: []
    };
    stages.forEach(stage => {
      if (stage.projectTasks !== undefined && stage.projectTasks !== null) {
        stage.projectTasks.forEach(task => {
          if(task.status == 'Complete' || task.assignedToId != userId)
            return;

          task.stageName = stage.stageName;
          task.stageColor = stage.color;

          const date = new Date(task.dueDate);
          const dateCopy = new Date(date.getFullYear(), date.getMonth(), date.getDate());

          if (dateCopy.getTime() <= todayDate.getTime()) {
            dateGroup.today.push(task);
          } else if (dateCopy > todayDate) {
            dateGroup.upcoming.push(task);
          }
        });
      }
    });
    return dateGroup;
  }

  groupByProject(stages: any) {
    const userId = this.auth.getUser().id;

    const projectArray = [];

    stages.forEach(stage => {
      if (stage.projectTasks !== undefined && stage.projectTasks !== null) {
        stage.projectTasks.forEach(task => {
          if (task.status === 'Complete' || task.assignedToId !== userId)
            return;

          task.stageName = stage.stageName;
          task.stageColor = stage.color;

          const projectCode = task.projectCode;
          const projectId = task.projectId;

          const existingProjectIndex = projectArray.findIndex(project => project.projectCode === projectCode);

          if (existingProjectIndex === -1) {
            const newProject = {
              projectId: projectId,
              projectCode: projectCode,
              today: [],
              upcoming: [],
            };
            newProject.today.push(task);
            projectArray.push(newProject);
          } else {
            const existingProject = projectArray[existingProjectIndex];
            const date = new Date(task.dueDate);
            const dateCopy = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            const today = new Date();
            const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

            if (dateCopy.getTime() <= todayDate.getTime()) {
              existingProject.today.push(task);
            } else {
              existingProject.upcoming.push(task);
            }
          }
        });
      }
    });
    return projectArray;
  }

  dateOnly(date) { return new Date(date).setHours(0,0,0,0); }
  sortByDueDate(array) {
    return array.sort((a, b) => {
      if (a.dueDate === null) return 1;
      if (b.dueDate === null) return -1;
      if (a.dueDate === b.dueDate) return 0;

      return new Date(a.dueDate) < new Date(b.dueDate) ? -1 : 1;
    });
  }
}

