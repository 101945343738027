<div class="container-fluid mb-2">
  <div class="card border-0 shadow-none">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="nav-secondary font-weight-bold">Settings</h3>
        </div>
      </div>
    </div>
    <div class="card-body pt-2 nav-font14">
      <div class="settings-container grey4" *ngIf="ready && auth.isInternal()">
        <div class="settings-menu">
          <a class="mb-3 d-block" (click)="scrollTo('#projectDetails')" [ngClass]="(currentSession == '#projectDetails') ? 'nav-secondary' : 'grey3'">Project Information</a>
          <a class="mb-3 d-block" (click)="scrollTo('#projectDates')" [ngClass]="(currentSession == '#projectDates') ? 'nav-secondary' : 'grey3'">Dates</a>
          <a class="mb-3 d-block" (click)="scrollTo('#clientDetails')" [ngClass]="(currentSession == '#clientDetails') ? 'nav-secondary' : 'grey3'">Client</a>
          <a class="mb-3 d-block" (click)="scrollTo('#projectTeam')" [ngClass]="(currentSession == '#projectTeam') ? 'nav-secondary' : 'grey3'">Team</a>
          <a class="mb-3 d-block" (click)="scrollTo('#sampleSpec')" [ngClass]="(currentSession == '#sampleSpec') ? 'nav-secondary' : 'grey3'">Sample Specifications</a>
          <a class="mb-3 d-block" (click)="scrollTo('#projectQuality')" [ngClass]="(currentSession == '#projectQuality') ? 'nav-secondary' : 'grey3'">Quality</a>
          <a class="mb-3 d-block" (click)="scrollTo('#projectHealth')" [ngClass]="(currentSession == '#projectHealth') ? 'nav-secondary' : 'grey3'">Project Health</a>
        </div>
        <div class="settings-content px-3" #scrollableContent>
          <div id="projectDetails">
            <h4 class="nav-secondary mb-4 font-weight-bold">Project Details</h4>

            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">Project Number</div>
              <div class="col-4">
                <input [(ngModel)]="_project.bidNumber" (change)="updateProjectField('bidNumber')" class="form-control form-control-sm form-control-nav" name="bidNumber">
              </div>
            </div>
            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">Project Name</div>
              <div class="col-4">
                <input [(ngModel)]="_project.projectName" (change)="updateProjectField('projectName')" class="form-control form-control-sm form-control-nav" name="projectName">
              </div>
            </div>

            <ng-container *ngIf="projectType?.showFSSO">
              <div class="row nav-font14 my-2">
                <div class="col-3 d-flex align-items-center">Project Type</div>
                <div class="col-4">
                  <input [(ngModel)]="projectTypeValue" readonly class="form-control form-control-sm form-control-nav" name="projectName">
                </div>
              </div>
            </ng-container>

            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">Programming Complexity</div>
              <div class="col-4">
                <div class="btn-group w-100" dropdown #dropdownProgrammingComplexity="bs-dropdown">
                  <div id="button-basic" dropdownToggle controls="dropdown-basic" class="d-flex button-container" [ngClass]="dropdownProgrammingComplexity.isOpen ? 'open' : 'closed'">
                      <span class="nav-font14 cursor-default" [class.grey2]="_project.programmingComplexity == null || _project.programmingComplexity == ''">{{_project.programmingComplexity == null || _project.programmingComplexity == '' ? 'Select complexity' : _project.programmingComplexity}}</span>
                      <div class="cursor-pointer d-flex align-items-center grey6 ml-auto">
                          <i class="far grey4 float-right nav-font16 ml-auto"
                              [class.fa-angle-up]="dropdownProgrammingComplexity.isOpen"
                              [class.mt-1]="!dropdownProgrammingComplexity.isOpen"
                              [class.fa-angle-down]="!dropdownProgrammingComplexity.isOpen">
                          </i>
                      </div>
                  </div>
                  <div *dropdownMenu  class="  dropdown-menu dropdown-menu-right py-0 nav-font14 m-0 w-100" role="menu" aria-labelledby="button-basic" >
                      <div class="menu-container overflow-y">
                          <ul class=" py-0 px-0 my-0 noShadow">
                            <li role="menuitem" class="expandable grey4 nav-font14 d-flex align-items-center px-0" (click)="_project.programmingComplexity='None'; updateProjectField('programmingComplexity')">
                              <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
                                    <span>None</span>
                                </div>
                            </li>
                            <hr class="p-0 my-0 mx-1">
                            <li role="menuitem" class="expandable grey4 nav-font14 d-flex align-items-center px-0" (click)="_project.programmingComplexity='Standard'; updateProjectField('programmingComplexity')">
                              <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
                                    <span>Standard</span>
                                </div>
                            </li>
                            <hr class="p-0 my-0 mx-1">
                            <li role="menuitem" class="expandable grey4 nav-font14 d-flex align-items-center px-0" (click)="_project.programmingComplexity='Advanced'; updateProjectField('programmingComplexity')">
                              <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
                                    <span>Advanced</span>
                                </div>
                            </li>
                          </ul>
                      </div>
                  </div>
                </div>
              </div>
            </div>



            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">Respondent Type</div>
              <div class="col-4">
                <div class="btn-group w-100" dropdown #dropdownCategory="bs-dropdown">
                  <div id="button-basic" dropdownToggle controls="dropdown-basic" class="d-flex button-container" [ngClass]="dropdownCategory.isOpen ? 'open' : 'closed'">
                      <span class="nav-font14 cursor-default" [class.grey2]="_project.category == null || _project.category == ''">{{_project.category == null || _project.category == '' ? 'Select type' : _project.category}}</span>
                      <div class="cursor-pointer d-flex align-items-center grey6 ml-auto">
                          <i class="far grey4 float-right nav-font16 ml-auto"
                              [class.fa-angle-up]="dropdownCategory.isOpen"
                              [class.mt-1]="!dropdownCategory.isOpen"
                              [class.fa-angle-down]="!dropdownCategory.isOpen">
                          </i>
                      </div>
                  </div>
                  <div *dropdownMenu  class="  dropdown-menu dropdown-menu-right py-0 nav-font14 m-0 w-100" role="menu" aria-labelledby="button-basic" >
                      <div class="menu-container overflow-y">
                          <ul class=" py-0 px-0 my-0 noShadow">
                            <li role="menuitem" class="expandable grey4 nav-font14 d-flex align-items-center px-0" (click)="_project.category='Consumer'; updateProjectField('category')">
                              <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
                                    <span>Consumer</span>
                                </div>
                            </li>
                            <hr class="p-0 my-0 mx-1">
                            <li role="menuitem" class="expandable grey4 nav-font14 d-flex align-items-center px-0" (click)="_project.category='B2B'; updateProjectField('category')">
                              <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
                                    <span>B2B</span>
                                </div>
                            </li>
                            <hr class="p-0 my-0 mx-1">
                            <li role="menuitem" class="expandable grey4 nav-font14 d-flex align-items-center px-0" (click)="_project.category='Healthcare'; updateProjectField('category')">
                              <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
                                    <span>Healthcare</span>
                                </div>
                            </li>
                          </ul>
                      </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row nav-font14 my-2 target-audience">
              <div class="col-3 d-flex mt-1">Target Audience</div>
              <div class="col-4 pl-0">
                <ng-container *ngFor="let audience of audienceItems; let idx = index;">  
                  <div *ngIf="!audience.isOther" class="btn-group w-100 mb-1" dropdown #audienceDropdown="bs-dropdown" [class.pl-3]="idx == 0 && audience.value == ''">
                    <i class="fak fa-trash nav-error cursor-pointer mt-2 mr-2" (click)="removeAudience(idx)" *ngIf="audience.value != '' || idx>0"></i>
                      <div id="button-basic" dropdownToggle controls="dropdown-basic" class="d-flex button-container" [ngClass]="audienceDropdown.isOpen == true ? 'open' : 'closed'">
                        <span class="nav-font14 cursor-default" [class.grey2]="audience.value == null || audience.value == ''">{{audience.value == null || audience.value == '' ? 'Select Audience' : audience.value}}</span>
                        <div class="cursor-pointer d-flex align-items-center grey6 ml-auto">
                            <i class="far grey4 float-right nav-font16 ml-auto"
                                [class.fa-angle-up]="audienceDropdown.isOpen"
                                [class.mt-1]="!audienceDropdown.isOpen"
                                [class.fa-angle-down]="!audienceDropdown.isOpen">
                            </i>
                        </div>
                    </div>
                    <div *dropdownMenu  class="dropdown-menu dropdown-menu-right py-0 nav-font14 m-0 w-100" role="menu" aria-labelledby="button-basic" >
                        <div class="menu-container scrollbar overflow-y">
                            <ul class=" py-0 px-0 my-0 noShadow">

                              <ng-container *ngFor="let label of audienceList">
                                <li role="menuitem" class="expandable grey4 nav-font14 d-flex align-items-center px-0" (click)="updateAudience(audience, label)">
                                  <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
                                        <span>{{ label }}</span>
                                    </div>
                                </li>
                                <hr class="p-0 my-0 mx-1">
                              </ng-container>
                            
                              <li role="menuitem" class="expandable grey4 nav-font14 d-flex align-items-center px-0" (click)="audienceIsOther(audience)">
                                <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
                                      <span>Other</span>
                                  </div>
                              </li>
          
                            </ul>
                        </div>
                    </div>
                  </div>
                  <div *ngIf="audience.isOther" class="btn-group w-100 mb-1">
                    <i class="fak fa-trash nav-error cursor-pointer mt-2 mr-2" (click)="removeAudience(idx)" ></i>
                    <div class="d-flex w-100">
                      <input [(ngModel)]="audience.otherValue" (change)="saveAudience()" class="form-control form-control-sm form-control-nav" name="audience">
                    </div>
                  </div>
                </ng-container>
                <span class="nav-font14 font-weight-bold" [ngClass]="audienceItems[audienceItems.length-1].value != '' ? 'nav-accent-blue cursor-pointer': 'grey2 cursor-default'" (click)="addNewAudienceItem()">
                  <i class="far fa-plus mr-2 ml-3"></i> Add Audience</span>
              </div>
            </div>

            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">Country
                <span data-html="true" container="body" containerClass="settings-tooltip" tooltip="This field is used specifically to allow ‘tagging’ of this study so that a user can review all studies with this country ‘tagged’.  This does NOT provide any other capabilities or filtering of traffic in any way.  If you want to filter your traffic on a specific country, please go to ‘Quality Settings’.">
                  <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
                </span>
              </div>
              <div class="col-4">
                <or-type-ahead
                  [selectedValues]="countries"
                  (valueChange)="updateProjectCountries('countries', $event)"
                  [labelField]="'Country'"
                  [multi]="true"
                  [valueField]="'value'"
                  [labelField]="'value'"
                  [showLoading]="false"
                  class="form-control-nav px-0 nav-font14"
                  [dataCallback]="getDropdownCountryList"
                  placeholder="Type to find a country">
                </or-type-ahead>
              </div>
            </div>
            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">Tags</div>
              <div class="col-4">
                <or-input-multiple (changed)="updateProjectTags($event)" class="form-control-nav px-0 nav-font14" placeholder="Type and press enter to create tags" [selectedValuesString]="tags"></or-input-multiple>
              </div>
            </div>
          </div>
          <div id="projectDates">
            <h4 class="nav-secondary mb-4 mt-5 font-weight-bold">Dates</h4>

            <div class="nav-font14 grey3">
              Project
              <span data-html="true" container="body" containerClass="settings-tooltip" tooltip="The project dates determine the length of your project">
                <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
              </span>
            </div>

            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">Start Date</div>
              <div class="col-4">
                <div class="input-group w-50 mb-3 form-control-date">
                  <input
                    type="text" autocomplete="off"
                    name="projectStartDate"
                    readonly
                    [(ngModel)]="_project.projectStartDate"
                    (ngModelChange)="updateProjectDate('projectStartDate', $event)"
                    bsDatepicker
                    placeholder="Select"
                    #dpStartDate="bsDatepicker"
                    [bsConfig]="{ dateInputFormat: 'MMM Do, YYYY', isAnimated: true, isOpen: true, showWeekNumbers:false, customTodayClass: 'todays-date' }"
                    class="form-control nav-font14 date d-inline bg-white w-50 px-2">
                  <span class="input-group-text bg-white" (click)="dpStartDate.toggle()"><i class="fak fa-calendar grey4"></i></span>
                </div>

              </div>
            </div>
            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">Deadline</div>
              <div class="col-4">
                <div class="input-group w-50 mb-3 form-control-date">
                  <input
                    type="text" autocomplete="off"
                    name="projectEndDate"
                    readonly
                    [(ngModel)]="_project.projectEndDate"
                    (ngModelChange)="updateProjectDate('projectEndDate', $event)"
                    bsDatepicker
                    placeholder="Select"
                    #dpEndDate="bsDatepicker"
                    [bsConfig]="{ dateInputFormat: 'MMM Do, YYYY', isAnimated: true, isOpen: true, showWeekNumbers:false, customTodayClass: 'todays-date' }"
                    class="form-control nav-font14 date d-inline bg-white w-50 px-2">
                  <span class="input-group-text bg-white" (click)="dpEndDate.toggle()"><i class="fak fa-calendar grey4"></i></span>
                </div>
              </div>
            </div>
            <div class="mt-2 nav-font14 grey3">
              Field
              <span data-html="true" container="body" containerClass="settings-tooltip" tooltip="The field dates determine when your project is in field">
                <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
              </span>
            </div>
            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">Start Date</div>
              <div class="col-4">
                <div class="input-group w-50 mb-3 form-control-date">
                  <input
                    type="text" autocomplete="off"
                    name="fieldStartDate"
                    readonly
                    [(ngModel)]="_project.fieldStartDate"
                    (ngModelChange)="updateProjectDate('fieldStartDate', $event)"
                    bsDatepicker
                    placeholder="Select"
                    #dpFieldStartDate="bsDatepicker"
                    [bsConfig]="{ dateInputFormat: 'MMM Do, YYYY', isAnimated: true, isOpen: true, showWeekNumbers:false, customTodayClass: 'todays-date' }"
                    class="form-control nav-font14 date d-inline bg-white w-50 px-2">
                  <span class="input-group-text bg-white" (click)="dpFieldStartDate.toggle()"><i class="fak fa-calendar grey4"></i></span>
                </div>

              </div>
            </div>
            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">End Date</div>
              <div class="col-4">
                <div class="input-group w-50 mb-3 form-control-date">
                  <input
                    type="text" autocomplete="off"
                    name="fieldEndDate"
                    readonly
                    [(ngModel)]="_project.fieldEndDate"
                    (ngModelChange)="updateProjectDate('fieldEndDate', $event)"
                    bsDatepicker
                    placeholder="Select"
                    #dpFieldEndDate="bsDatepicker"
                    [bsConfig]="{ dateInputFormat: 'MMM Do, YYYY', isAnimated: true, isOpen: true, showWeekNumbers:false, customTodayClass: 'todays-date' }"
                    class="form-control nav-font14 date d-inline bg-white w-50 px-2">
                    <span class="input-group-text bg-white" (click)="dpFieldEndDate.toggle()"><i class="fak fa-calendar grey4"></i></span>
                </div>
              </div>
            </div>
          </div>
          <div id="clientDetails">
            <h4 class="nav-secondary mb-4 mt-5 font-weight-bold">Client</h4>

            <div class="row nav-font14 my-2">
              <div class="col-3">Client</div>
              <div class="col-4">
                <or-type-ahead
                  [labelField]="'name'"
                  [valueField]="'value'"
                  [selectedValues]="_project.clientId == null ? []: [{value: _project.clientId, name: _project.clientName }]"
                  dropdownMode=true
                  class="form-control-sm form-control-nav w-100 px-0 nav-font14"
                  [dataCallback]="getClients"
                  (valueChange)="updateClient($event)">
                </or-type-ahead>
              </div>
            </div>

            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">
                <span>Point of Contact</span>
                <a (click)="addNewContact(addContact)" class="mx-2">
                  <i class="far fa-plus grey4 cursor-pointer"></i>
                </a>
              </div>
              <div class="col-4">
                <div class="btn-group w-100" dropdown #dropdownClientContact="bs-dropdown">
                  <div id="button-basic" dropdownToggle controls="dropdown-basic" class="d-flex button-container" [ngClass]="dropdownClientContact.isOpen ? 'open' : 'closed'">
                      <span class="nav-font14 cursor-default" [class.grey2]="_project.clientContactId == null || _project.clientContactId == ''">{{_project.clientContactId == null || _project.clientContactId == '' ? 'Select contact' : _project.clientContactName}}</span>
                      <div class="cursor-pointer d-flex align-items-center grey6 ml-auto">
                          <i class="far grey4 float-right nav-font16 ml-auto"
                              [class.fa-angle-up]="dropdownClientContact.isOpen"
                              [class.mt-1]="!dropdownClientContact.isOpen"
                              [class.fa-angle-down]="!dropdownClientContact.isOpen">
                          </i>
                      </div>
                  </div>
                  <div *dropdownMenu  class="dropdown-menu dropdown-menu-right py-0 nav-font14 m-0 w-100" role="menu" aria-labelledby="button-basic" >
                      <div class="menu-container overflow-y">
                          <ul class=" py-0 px-0 my-0 noShadow">
                            <ng-container *ngFor="let contact of clientContacts; let c = index">
                              <li role="menuitem" class="expandable grey4 nav-font14 d-flex align-items-center px-0" (click)="_project.clientContactId=contact.id; updateProjectField('clientContactId')">
                                <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
                                      <span>{{contact.firstName}} {{contact.lastName}}</span>
                                  </div>
                              </li>
                              <hr *ngIf="c < clientContacts.length-1" class="p-0 my-0 mx-1">
                            </ng-container>
                          </ul>
                      </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">Purchase Order Number</div>
              <div class="col-4">
                <input [(ngModel)]="_project.clientPo" (change)="updateProjectField('clientPo')" class="form-control form-control-sm form-control-nav" name="clientPo">
              </div>
            </div>
          </div>

          <div id="projectTeam">
            <h4 class="nav-secondary mb-4 mt-5 font-weight-bold">Team</h4>

            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">Primary Project Manager</div>
              <div class="col-4">
                <or-type-ahead
                  [(ngModel)]="projectManager"
                  name="projectManager"
                  [labelField]="'name'"
                  [valueField]="'id'" [dataCallback]="getUserList"
                  (valueChange)="updateProjectTeam('projectManager', $event)"
                  placeholder="Type to search PM"
                  class="form-control-nav px-0 nav-font14"
                  [selectedValues]="projectManager">
                </or-type-ahead>
              </div>
            </div>

            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">Secondary Project Manager</div>
              <div class="col-4">
                <or-type-ahead
                  [(ngModel)]="projectSecondaryManager"
                  name="projectSecondaryManager"
                  [labelField]="'name'"
                  [valueField]="'id'"
                  [multi]="true"
                  [dataCallback]="getUserList"
                  class="form-control-nav px-0 nav-font14"
                  (valueChange)="updateProjectTeam('projectSecondaryManager', $event)"
                  placeholder="Type to search secondary PM"
                  [selectedValues]="projectSecondaryManager">
                </or-type-ahead>
              </div>
            </div>

            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">
                Account Owner
                <span data-html="true" container="body" containerClass="settings-tooltip" tooltip="The account owner is the account manager or sales person for this client">
                  <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
                </span>
              </div>
              <div class="col-4">
                <or-type-ahead
                  [(ngModel)]="internalAccountOwner"
                  name="accountOwner"
                  [labelField]="'name'"
                  [valueField]="'id'"
                  [dataCallback]="getUserList"
                  (valueChange)="updateInternalAccountOwner($event)"
                  class="form-control-nav px-0 nav-font14"
                  placeholder="Type to search Account owner"
                  [selectedValues]="internalAccountOwner">
                </or-type-ahead>
              </div>
            </div>

          <div class="row nav-font14 my-2">
            <div class="col-3 d-flex align-items-center">
              Programmer
              <span data-html="true" container="body" containerClass="settings-tooltip" tooltip="The programmer is the editor of the survey">
                <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
              </span>
            </div>
            <div class="col-4">
              <or-type-ahead
                [labelField]="'fullName'"
                [valueField]="'userId'"
                [multi]="true"
                [dataCallback]="getUserProgrammerList"
                (input)="formChanged($event)"
                (valueChange)="updateProgrammers($event)"
                class="form-control-nav px-0 nav-font14"
                placeholder="Type to search Programmers"
                [selectedValues]="programmers">
              </or-type-ahead>
            </div>
          </div>

          <div id="sampleSpec">
            <h4 class="nav-secondary mb-4 mt-5 font-weight-bold">Sample Specifications</h4>
            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">Sell CPI
                <span data-html="true" container="body" containerClass="settings-tooltip" tooltip="Sell CPI is the 'cost per interview' price sold">
                  <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
                </span>
              </div>
              <div class="col-4">
                <input
                  [(ngModel)]="_project.projectSegments[0].sellCPI"
                  (blur)="updateProjectField('sellCPI')"
                  class="form-control form-control-sm form-control-nav"
                  name="sellCPI"
                  currencyMask [options]="{ prefix: '$ ', thousands: ',', decimal: '.', align: 'left' }">
              </div>
            </div>
            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">Estimated Project Cost
                <span data-html="true" container="body" containerClass="settings-tooltip" tooltip="The estimate cost of this project">
                  <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
                </span>
              </div>
              <div class="col-4">
                <input
                  [(ngModel)]="_project.projectRevenue"
                  (blur)="updateProjectField('projectRevenue')"
                  class="form-control form-control-sm form-control-nav"
                  name="projectRevenue"
                  currencyMask [options]="{ prefix: '$ ', thousands: ',', decimal: '.', align: 'left' }">
              </div>
            </div>
            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">Incidence Rate
                <span data-html="true" container="body" containerClass="settings-tooltip" tooltip="The percentage of respondents who qualify to complete the project survey">
                  <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
                </span>
              </div>
              <div class="col-4">
                <input
                  [(ngModel)]="_project.projectSegments[0].projectIR"
                  (blur)="updateProjectField('projectIR')"
                  class="form-control form-control-sm form-control-nav"
                  name="projectIR"
                  suffix="%"
                  mask="percent">
              </div>
            </div>
            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">Length of Interview
                <span data-html="true" container="body" containerClass="settings-tooltip" tooltip="The median length of interview is the estimated amount of time it takes for a participant to begin and end a survey">
                  <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
                </span>
              </div>
              <div class="col-4">
                <input
                  [(ngModel)]="_project.projectSegments[0].projectLOI"
                  (blur)="updateProjectField('projectLOI')"
                  class="form-control form-control-sm form-control-nav"
                  name="projectLOI"
                  mask="99999"
                  [patterns]="integerPattern" >
              </div>
            </div>
            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">Completes
                <span data-html="true" container="body" containerClass="settings-tooltip" tooltip="The total goal of survey completes">
                  <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
                </span>
              </div>
              <div class="col-4">
                <input
                  [(ngModel)]="_project.fullLaunchQuota"
                  (blur)="updateProjectField('fullLaunchQuota')"
                  placeholder="Type number of completes"
                  class="form-control form-control-sm form-control-nav"
                  name="fullLaunchQuota"
                  mask="99999"
                  [patterns]="integerPattern">
              </div>
            </div>
            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">Soft Launch Goal
                <span data-html="true" container="body" containerClass="settings-tooltip" tooltip="The amount of completes needed to meet the soft launch goal (typically 10% of the completes goal)">
                  <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
                </span>
              </div>
              <div class="col-4">
                <input
                  [(ngModel)]="_project.softLaunchQuota"
                  (blur)="updateProjectField('softLaunchQuota')"
                  placeholder="Type soft launch number"
                  class="form-control form-control-sm form-control-nav"
                  name="softLaunchQuota"
                  mask="99999"
                  [patterns]="integerPattern">
              </div>
            </div>
            <div class="row px-3" *ngIf="_project.fullLaunchQuota < _project.softLaunchQuota">
              <span class="badge warn font-weight-normal grey4 p-2">
                <i class="far fa-square-info mr-2 fa-lg"></i>Nav Overquotas will occur when the completes allocation is reached, even in Soft Launch as it is currently lower.
              </span>
            </div>
            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">Project and Vendor Limit Enforcement
                <span data-html="true" container="body" containerClass="settings-tooltip" tooltip="When enabled, this feature stops traffic once a project or a specific vendor's limit is reached, automatically redirecting new traffic to the 'Overquota' links. In the event of an overall project limit being reached, all traffic will be directed to the respective vendor's 'Overquota' redirect links, bypassing the survey.">
                  <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
                </span>
              </div>
              <div class="col-4 d-flex justify-content-end">
                <or-toggle-button class="light" (changed)="updateProjectToggleField('quotaMode', $event)"  size="small" [isChecked]="!(_project.quotaMode === 'Off')"></or-toggle-button>
              </div>
            </div>
            <div class="row px-3">
              <span class="badge font-weight-normal grey4 p-2">
                <i class="far fa-square-info mr-2 fa-lg"></i>When enabled, this feature stops traffic once a project or a specific vendor's limit is reached, automatically redirecting new traffic to the 'Overquota' links.
              </span>
            </div>
          </div>

          <div id="projectQuality">
            <h4 class="nav-secondary mb-4 mt-5 font-weight-bold">Quality</h4>

            <div class="row px-3" *ngIf="_project.projectStatus == 'Test'">
              <span class="badge font-weight-normal error grey4 p-2">
                <i class="far fa-square-info mr-2 fa-lg"></i>This project is currently in <strong>test mode</strong>. De-duplication and CleanID will not block traffic.
              </span>
            </div>

            <div class="row nav-font14 my-2 mt-4" *ngIf="!_project.isDeDupeDeviceEnabled">
              <div class="col-3 d-flex align-items-center">De-Duplication is currently disabled</div>
              <div class="col-4 d-flex justify-content-end">
                <or-toggle-button class="light" size="small" [isChecked]="_project.isDeDupeDeviceEnabled" (changed)="updateProjectToggleField('isDeDupeDeviceEnabled', $event)" ></or-toggle-button>
              </div>
            </div>

            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">De-Duplication</div>
              <div class="col-4 d-flex justify-content-end">
                <input type="radio" name="radioGroup" [(ngModel)]="_project.isDupeTrackingOnly" [value]="false" (change)="updateProjectField('isDupeTrackingOnly')">
              </div>
            </div>
            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">Track Duplications Only
                <span data-html="true" container="body" containerClass="settings-tooltip" tooltip="Duplication will only record dupes but will not block participants.">
                  <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
                </span>
              </div>
              <div class="col-4 d-flex justify-content-end">
                <input type="radio" name="radioGroup" [(ngModel)]="_project.isDupeTrackingOnly" [value]="true" (change)="updateProjectField('isDupeTrackingOnly')">
              </div>
            </div>
            <div class="row nav-font14 mt-5 mb-4">
              <div class="col-12 grey4">Select the status to apply the duplication settings to by project</div>
            </div>
            <div class="row">
              <div class="col">
                <div class="dedupeStatus">
                  <div class="nav-font14 mb-3">Project</div>
                  <div class="nav-font14 mb-3 text-center">Start</div>
                  <div class="nav-font14 mb-3 text-center">Complete</div>
                  <div class="nav-font14 mb-3 text-center">Termination</div>
                  <div class="nav-font14 mb-3 text-center">Overquota</div>
                  <div class="nav-font14 mb-3 text-center">Quality Control</div>

                  <ng-container *ngFor="let dedupe of _project.projectDedupeStatus; let i = index">
                    <div class="grey5" *ngIf="i == 0">
                      Current Project
                    </div>
                    <div class="mb-1 grey5" *ngIf="i > 0">
                      {{ dedupe.otherName }}
                      <i *ngIf="i > 0" class="fak fa-trash nav-error fa-md mx-2 cursor-pointer"  (click)="removeOtherDedupeRow(dedupe)"></i>
                    </div>
                    <div class="text-center">
                      <div class="or-checkbox">
                        <input type="checkbox" [name]="'dupeStart'+i" [id]="'dupeStart'+i" [(ngModel)]="dedupe.start" (change)="changeDedupeStatus(dedupe)">
                        <label [for]="'dupeStart'+i"></label>
                      </div>
                    </div>
                    <div class="text-center">
                      <div class="or-checkbox">
                        <input [disabled]="dedupe.start" type="checkbox" [id]="'dupeComplete'+i"  [(ngModel)]="dedupe.complete" class="form-control form-control-sm form-control-nav"  (change)="changeDedupeStatus(dedupe)">
                        <label [for]="'dupeComplete'+i"></label>
                      </div>
                    </div>
                    <div class="text-center">
                      <div class="or-checkbox">
                        <input [disabled]="dedupe.start" type="checkbox" [id]="'dupeTerm'+i" class="form-control form-control-sm form-control-nav" [(ngModel)]="dedupe.terminate"  (change)="changeDedupeStatus(dedupe)">
                        <label [for]="'dupeTerm'+i"></label>
                      </div>
                    </div>
                    <div class="text-center">
                      <div class="or-checkbox">
                        <input [disabled]="dedupe.start" type="checkbox" [id]="'dupeOQ'+i" [(ngModel)]="dedupe.oq" class="form-control form-control-sm form-control-nav" (change)="changeDedupeStatus(dedupe)">
                        <label [for]="'dupeOQ'+i"></label>
                      </div>
                    </div>
                    <div class="text-center">
                      <div class="or-checkbox">
                        <input [disabled]="dedupe.start" type="checkbox" [id]="'dupeQC'+i" [(ngModel)]="dedupe.qc" class="form-control form-control-sm form-control-nav" (change)="changeDedupeStatus(dedupe)">
                        <label [for]="'dupeQC'+i"></label>
                      </div>
                    </div>
                    <!-- <div  *ngIf="i == 0" class="seperator"><hr></div> -->
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3"></div>
              <div class="col-4 text-right">
                <span type="button" class="nav-accent-blue nav-font-14 font-weight-bold mt-3 mb-4" routerLinkActive="active" (click)="openAddDedupeProjectModal(addDedupeProjectModal)">
                  <i class="fas fa-plus fa-md nav-accent-blue mr-2"></i>Add Project
                </span>
              </div>
            </div>
            <!-- We cannot turn off CleanID anymore so we only show this if for some reason it was turned off in the past so we can turn it on -->
            <div class="row nav-font14 my-2 mt-4" *ngIf="!_project.isFraudDetectionEnabled">
              <div class="col-3 d-flex align-items-center">CleanID</div>
              <div class="col-4 d-flex justify-content-end">
                <or-toggle-button class="light" size="small" [isChecked]="_project.isFraudDetectionEnabled" (changed)="updateProjectToggleField('isFraudDetectionEnabled', $event)" ></or-toggle-button>
              </div>
            </div>
            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">CleanID threshold</div>
              <div class="col-4">
                <div class="btn-group w-100" dropdown #dropdownFraudDetectionThreshold="bs-dropdown">
                  <div id="button-basic" dropdownToggle controls="dropdown-basic" class="d-flex button-container" [ngClass]="dropdownFraudDetectionThreshold.isOpen ? 'open' : 'closed'">
                      <span class="nav-font14 cursor-default" [class.grey2]="_project.fraudDetectionThreshold == null">{{_project.fraudDetectionThreshold == null  ? 'Select threshold' : cleanIdThresholds[_project.fraudDetectionThreshold]}}</span>
                      <div class="cursor-pointer d-flex align-items-center grey6 ml-auto">
                          <i class="far grey4 float-right nav-font16 ml-auto"
                              [class.fa-angle-up]="dropdownFraudDetectionThreshold.isOpen"
                              [class.mt-1]="!dropdownFraudDetectionThreshold.isOpen"
                              [class.fa-angle-down]="!dropdownFraudDetectionThreshold.isOpen">
                          </i>
                      </div>
                  </div>
                  <div *dropdownMenu  class="dropdown-menu dropdown-menu-right py-0 nav-font14 m-0 w-100" role="menu" aria-labelledby="button-basic" >
                      <div class="menu-container overflow-y">
                          <ul class=" py-0 px-0 my-0 noShadow">
                            <ng-container *ngFor="let threshold of cleanIdThresholds | keyvalue : asIsOrder; let t = index">
                              <li role="menuitem" class="expandable grey4 nav-font14 d-flex align-items-center px-0" (click)="_project.fraudDetectionThreshold=threshold.key; updateProjectField('fraudDetectionThreshold')">
                                <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
                                      <span>{{threshold.value}}</span>
                                  </div>
                              </li>
                              <hr *ngIf="threshold.value != 'Using API'" class="p-0 my-0 mx-1">
                            </ng-container>
                          </ul>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <ng-container *ngIf="_project.fraudDetectionThreshold > 0">
              <div class="row nav-font14 my-2">
                <div class="col-3 d-flex align-items-center">Allow Mobile
                  <span data-html="true" container="body" containerClass="settings-tooltip" tooltip="Allow mobile devices to enter this survey">
                    <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
                  </span>
                </div>
                <div class="col-4 d-flex justify-content-end">
                  <or-toggle-button class="light" size="small" [isChecked]="_project.allowMobile" (changed)="updateProjectToggleField('allowMobile', $event)" ></or-toggle-button>
                </div>
              </div>
              <div class="row nav-font14 my-2">
                <div class="col-3 d-flex align-items-center">Email Security Page
                  <span data-html="true" container="body" containerClass="settings-tooltip" tooltip="This page facilitates security verifications, like those from Barracuda Firewall, to ensure our site is recognized as safe.  It prevents legitimate security tools from blocking survey access, ensuring a seamless respondent experience while adhering to your organization's security protocols.  Contact support if you would like additional information.">
                    <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
                  </span>
                </div>
                <div class="col-4 d-flex justify-content-end">
                  <ng-container *ngIf="_project.category == 'Consumer' || _project.category == ''">
                    <or-toggle-button class="light" size="small" [isChecked]="_project.showLandingPage" (changed)="updateProjectToggleField('showLandingPage', $event)" ></or-toggle-button>
                  </ng-container>
                  <ng-container *ngIf="_project.category == 'Healthcare' || _project.category == 'B2B'">
                    <span class="badge font-weight-normal grey4 p-2">
                      <i class="far fa-square-info mr-2 fa-lg"></i>This is enabled automatically for '{{ _project.category }}' Projects.
                    </span>
                  </ng-container>
                </div>
              </div>
              <div class="row nav-font14 my-2">
                <div class="col-3 d-flex align-items-center">Geo Restrictions
                  <span data-html="true" container="body" containerClass="settings-tooltip" tooltip="Geo restrictions is a feature that limits access to the the survey based on a participant's geographic location">
                    <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
                  </span>
                </div>
                <div class="col-4">
                  <or-type-ahead
                    [selectedValues]="displayCountries"
                    (valueChange)="updateProjectCountries('fraudDetectionGeoCountry', $event)"
                    [labelField]="'Country'"
                    [multi]="true"
                    [valueField]="'value'"
                    [labelField]="'value'"
                    [showLoading]="false"
                    class="form-control-nav px-0 nav-font14"
                    [dataCallback]="getDropdownCountryList"
                    placeholder="Type country and press enter">
                  </or-type-ahead>
                </div>
              </div>

            </ng-container>
           <div class="row nav-font14">
              <div class="col-3 d-flex align-items-center">Survey to NavigatOR link secure</div>
              <div class="col-4 d-flex align-items-center pr-0">
                <span class="mx-2">
                  <or-toggle-button class="light" (changed)="toggleSurveySecurityKey()"  size="small" [isChecked]="showSurveySecurityKey"></or-toggle-button>
                </span>
                <span class="mx-2" *ngIf="showSurveySecurityKey">Add Key</span>
                <div class="col" *ngIf="showSurveySecurityKey">
                  <div class="btn-group w-100" dropdown #dropdownSecurityConfig="bs-dropdown">
                    <div id="button-basic" dropdownToggle controls="dropdown-basic" class="d-flex button-container" [ngClass]="dropdownSecurityConfig.isOpen ? 'open' : 'closed'">
                        <span class="nav-font14 cursor-default" [class.grey2]="_project.surveySecurityKey == null || _project.surveySecurityKey == ''">{{_project.surveySecurityKey == null || _project.surveySecurityKey == '' ? 'Select key' : _project.surveySecurityKeyName}}</span>
                        <div class="cursor-pointer d-flex align-items-center grey6 ml-auto">
                            <i class="far grey4 float-right nav-font16 ml-auto"
                                [class.fa-angle-up]="dropdownSecurityConfig.isOpen"
                                [class.mt-1]="!dropdownSecurityConfig.isOpen"
                                [class.fa-angle-down]="!dropdownSecurityConfig.isOpen">
                            </i>
                        </div>
                    </div>
                    <div *dropdownMenu  class=" dropdown-menu dropdown-menu-right py-0 nav-font14 m-0 w-100" role="menu" aria-labelledby="button-basic" >
                        <div class="menu-container overflow-y">
                            <ul class=" py-0 px-0 my-0 noShadow">
                              <ng-container *ngFor="let config of securityConfigs; let c = index">
                                <li role="menuitem" class="expandable grey4 nav-font14 d-flex align-items-center px-0" (click)="_project.surveySecurityKey=config.id; updateProjectField('surveySecurityKey')">
                                  <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
                                        <span>{{config.name}}</span>
                                    </div>
                                </li>
                                <hr *ngIf="c < securityConfigs.length-1" class="p-0 my-0 mx-1">
                              </ng-container>
                            </ul>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="projectHealth">
            <h4 class="nav-secondary mb-4 mt-5 font-weight-bold">Project Health</h4>
            <div class="grey4 naf-font14 mb-4">The settings below will affect your project's health score.</div>

            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">NavigatOR Overquota
                <span data-html="true" container="body" containerClass="settings-tooltip" tooltip="An Overquota occurs when the NavigatOR blocks traffic due to the set threshold.">
                  <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
                </span>
              </div>
              <div class="col-4">
                <input
                  [(ngModel)]="_project.alertNavOQ"
                  (blur)="updateProjectField('alertNavOQ')"
                  class="form-control form-control-sm form-control-nav"
                  name="alertNavOQ"
                  suffix="%"
                  mask="percent">
              </div>
            </div>
            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">Drop Out Rate
                <span data-html="true" container="body" containerClass="settings-tooltip" tooltip="The drop out rate aids in calculating your project's health score">
                  <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
                </span>
              </div>
              <div class="col-4">
                <input
                  [(ngModel)]="_project.alertDropoutRate"
                  (blur)="updateProjectField('alertDropoutRate')"
                  class="form-control form-control-sm form-control-nav"
                  name="alertDropoutRate"
                  suffix="%"
                  mask="percent">
              </div>
            </div>
            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">Manual QCs
                <span data-html="true" container="body" containerClass="settings-tooltip" tooltip="This occurs when a respondent's survey responses are evaluated by researchers as poor quality, without triggering in-survey or fraudulent evaluations">
                  <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
                </span>
              </div>
              <div class="col-4">
                <input
                  [(ngModel)]="_project.alertQCRate"
                  (blur)="updateProjectField('alertQCRate')"
                  class="form-control form-control-sm form-control-nav"
                  name="alertQCRate"
                  suffix="%"
                  mask="percent">
              </div>
            </div>
            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">Pace
                <span data-html="true" container="body" containerClass="settings-tooltip" tooltip="The pace setting will aid in determining if your project's number of completes is off track">
                  <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
                </span>
              </div>
              <div class="col-4">
                <input
                  [(ngModel)]="_project.alertPaceMetric"
                  (change)="updateProjectField('alertPaceMetric')"
                  class="form-control form-control-sm form-control-nav"
                  name="alertPaceMetric"
                  type="number">
              </div>
            </div>
            <div class="row nav-font14 my-2">
              <div class="col-3 d-flex align-items-center">Days Remaining
                <span data-html="true" container="body" containerClass="settings-tooltip" tooltip="The project's days remaining is used to calculate your project health's score">
                  <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
                </span>
              </div>
              <div class="col-4">
                <input
                  [(ngModel)]="_project.alertDeadlineRemainingDays"
                  (change)="updateProjectField('alertDeadlineRemainingDays')"
                  class="form-control form-control-sm form-control-nav"
                  name="alertDeadlineRemainingDays"
                  type="number">
              </div>
            </div>
          </div>

          <div style="height:520px;">&nbsp;</div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal New Project-->
<ng-template #addDedupeProjectModal>
  <div class="modal-header d-flex align-items-center justify-content-center">
    <div class="header-grid w-100 position-relative">
      <div class="d-block text-center mt-3">
        <h2 class="nav-secondary mb-0 font-weight-bold">Select an existing project to apply duplication settings to</h2>
        <div class="text-center mb-3 nav-font18">
          <span class="nav-font16 mb-0 mt-2 grey5">You can remove a project from duplication settings at any time</span>
        </div>
      </div>
      <div class="d-flex justify-content-end align-content-center position-absolute w-100 sticky-top">
        <i class="fas fa-close grey4 cursor-pointer" (click)="closeAddDedupeProjectModal()"></i>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col"></div>
      <div class="col-10">
        <div class="row mb-3">
          <div class="col d-flex align-content-center">
            <div class="input-group copy-search">
              <div class="input-group-prepend">
                <div class="input-group-text border-right-0">
                  <i class="fas fa-search cursor-default"></i>
                </div>
              </div>
              <input
                type="search"
                #typeaheadDirective="bs-typeahead"
                [(ngModel)]="asyncSearchDupeProjects"
                [typeahead]="dupeProjectDataSource"
                [typeaheadAsync]="true"
                [optionsListTemplate]="customListTemplate"
                [typeaheadOptionField]="'projectName'"
                typeaheadOptionsLimit="10"
                (typeaheadOnSelect)="addDedupeProject($event)"
                (keydown.enter)="$event.preventDefault()"
                [isAnimated]="true"
                placeholder="Search for a project"
                class="form-control form-control-sm"
                autocomplete="off">
            </div>
          </div>
        </div>
      </div>
      <div class="col"></div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row d-flex w-100" >
      <div class="col"></div>
      <div class="col-10 text-right px-2">
        <button class="btn nav-btn-primary mx-2 shadow-none font-weight-bold" (click)="closeAddDedupeProjectModal()">Cancel</button>
      </div>
      <div class="col"></div>
    </div>
  </div>
</ng-template>

<ng-template #customListTemplate let-matches="matches" let-query="query" let-typeaheadTemplateMethods>
  <table class="table table-hover mb-0">
    <thead>
      <tr class="grey6 nav-font14 font-weight-bold nav-bg-snow-white">
        <th scope="col" class="px-4 border-bottom-0 border-top-0">Project name</th>
        <th scope="col" class="px-4 border-bottom-0 border-top-0">ProjectID</th>
        <th scope="col" class="px-4 border-bottom-0 border-top-0">Client</th>
        <th scope="col" class="px-4 border-bottom-0 border-top-0">End Date</th>
      </tr>
    </thead>
    <tbody>
      <tr
        class="grey5 nav-font14 cursor-pointer"
        *ngFor="let match of matches; let idx = index"
        (mouseenter)="typeaheadTemplateMethods.selectActive(match)"
        (click)="typeaheadTemplateMethods.selectMatch(match, $event)">
        <th scope="row" class="px-4 nav-accent-blue font-weight-normal" [class.border-top-0]="idx == 0">{{match.item.projectName}}</th>
        <td class="px-4" [class.border-top-0]="idx == 0">{{match.item.projectCode}}</td>
        <td class="px-4" [class.border-top-0]="idx == 0">{{match.item?.clientName}}</td>
        <td class="px-4" [class.border-top-0]="idx == 0">{{match.item.projectEndDate | date: 'MM/dd/yyyy' }}</td>
      </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #addContact>
  <div class="modal-header d-flex align-items-center justify-content-center" style="background-color: white; border-bottom: none">
    <div class="header-grid w-100 position-relative">
      <div class="d-flex justify-content-left align-content-center mt-2 px-2">
        <h3 class="nav-secondary mb-0 font-weight-bold">Add New Contact</h3>
      </div>
      <div class="d-flex justify-content-end align-content-center position-absolute w-100 sticky-top">
        <i class="fas fa-close grey4" (click)="cancelNewContact()"></i>
      </div>
    </div>
  </div>

  <div class="modal-body px-4 py-0">
  <!--   <div class="row my-4 row-upload" id="upload">
      <div class="col-4 d-flex justify-content-center">
          <or-profile-image #img size="large"></or-profile-image>
      </div>
      <div class="col-8">
        <or-upload
          mainMsg="Select profile photo to upload"
          smallMsg="or drag and drop .JPEG or .PNG here"
          [includeProgressBar]=false
          acceptImages=true
          showFileName=false
          directToS3=true
          showFileIcon=false
          class="upload"
          [(ngModel)]="profilePhoto"
          (uploadCompleted)="profilePhotoUploadCompleted(img)"
        >
        </or-upload>
      </div>
    </div>
 -->
    <div class="row my-4">
      <div class="col-4 grey4 nav-font14">
        Contact First Name
      </div>
      <div class="col-8">
        <input [(ngModel)]="contactFirstName" class="form-control form-control-sm form-control-nav" placeholder="First Name">
      </div>
    </div>
    <div class="row my-4">
      <div class="col-4 grey4 nav-font14">
        Contact Last Name
      </div>
      <div class="col-8">
        <input [(ngModel)]="contactLastName" class="form-control form-control-sm form-control-nav" placeholder="Last Name">
      </div>
    </div>

    <div class="row my-4">
      <div class="col-4 grey4 nav-font14">
        Company Position
      </div>
      <div class="col-8">
        <input [(ngModel)]="companyPosition" class="form-control form-control-sm form-control-nav" placeholder="Position">
      </div>
    </div>

    <div class="row my-4">
      <div class="col-4 grey4 nav-font14">
        Phone number
      </div>
      <div class="col-8">
        <input [(ngModel)]="phoneNumber" class="form-control form-control-sm form-control-nav" placeholder="(333) 333-4444">
      </div>
    </div>

    <div class="row my-4">
      <div class="col-4 grey4 nav-font14">
        E-mail
      </div>
      <div class="col-8">
        <input [(ngModel)]="email" class="form-control form-control-sm form-control-nav" placeholder="E-mail">
      </div>
    </div>
  </div>

  <div class="modal-footer pt-0 pb-3" style="border-top: none;">
    <div class="row d-flex w-100">
      <div class="col"></div>
      <div class="col-8 text-right px-3">
        <button type="button" (click)="cancelNewContact()" class="btn btn-sm nav-btn-primary mx-2 shadow-none">Cancel</button>
        <button type="submit" (click)="saveNewContact()"  class="btn btn-sm nav-btn-accent shadow-none">Add Contact</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #modalFieldTracking>
  <div class="modal-header nav-error pt-2 pr-2">
    <div class="col pt-2 px-0">
        <div class="row d-flex align-items-center mx-0">
          <i class="fak fa-edit-square grey4 nav-font20 mx-2"></i>
          <span class="font-weight-bold nav-font16 nav-secondary">Change field end date</span>
        </div>
    </div>
    <div class="col-2 text-right px-0">
      <i class="far fa-close grey4 nav-font18 p-1 pr-2" (click)="closeFieldTrackingModal()"></i>
    </div>
</div>
<div class="modal-body pt-0">
  <div class="row">
    <div class="col mx-4">
      <h5 class="grey5 d-block mb-3 ml-1">Please select a reason for changing the field end date:</h5>
    </div>
  </div>
  <div class="row">
    <div class="col mx-4">
      <div class="btn-group ml-1 w-75" dropdown #dropdownEndDateChangeReason="bs-dropdown">
        <div id="button-basic" dropdownToggle controls="dropdown-basic" class="d-flex button-container" [ngClass]="dropdownEndDateChangeReason.isOpen ? 'open' : 'closed'">
            <span class="nav-font14 cursor-default" [class.grey2]="fieldTrackingModel.reasonType == null || fieldTrackingModel.reasonType == ''">{{fieldTrackingModel.reasonType == null || fieldTrackingModel.reasonType == '' ? 'Select reason' : fieldTrackingModel.reasonType}}</span>
            <div class="cursor-pointer d-flex align-items-center grey6 ml-auto">
                <i class="far grey4 float-right nav-font16 ml-auto"
                    [class.fa-angle-up]="dropdownEndDateChangeReason.isOpen"
                    [class.mt-1]="!dropdownEndDateChangeReason.isOpen"
                    [class.fa-angle-down]="!dropdownEndDateChangeReason.isOpen">
                </i>
            </div>
        </div>
        <div *dropdownMenu  class=" dropdown-menu dropdown-menu-right py-0 nav-font14 m-0 w-100" role="menu" aria-labelledby="button-basic" >
            <div class="menu-container overflow-y">
                <ul class=" py-0 px-0 my-0 noShadow">
                  <ng-container *ngFor="let reason of endDateChangeReasons; let r = index">
                    <li role="menuitem" class="expandable grey4 nav-font14 d-flex align-items-center px-0" (click)="fieldTrackingModel.reasonType=reason">
                      <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
                            <span>{{reason}}</span>
                        </div>
                    </li>
                    <hr *ngIf="r < endDateChangeReasons.length-1" class="p-0 my-0 mx-1">
                  </ng-container>
                </ul>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="fieldTrackingModel?.reasonType === 'Other'">
    <div class="col mx-4">
      <textarea autosize="false" rows="2" class="form-control form-control-nav form-control-sm d-block w-75 mt-3 ml-1" [(ngModel)]="fieldTrackingModel.reason" placeholder="Type in the other reason" required></textarea>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="row d-flex w-100 align-items-center">
    <div class="col text-right px-0">
      <button class="btn btn-sm nav-btn-primary mx-2 shadow-none font-weight-bold" (click)="closeFieldTrackingModal()">Cancel</button>
      <button class="btn btn-sm shadow-none pb-1" [disabled]="!fieldTrackingModel.reasonType" [ngClass]="!fieldTrackingModel.reasonType ? 'nav-btn-disabled' : 'nav-btn-accent'" (click)="!fieldTrackingModel.reasonType ? null : AddProjectFieldTracking('fieldEndDate')">Save</button>
    </div>
  </div>
</div>
</ng-template>
