<div class="nav-content" *ngIf="ready">
  <div class="project-details-container">
    <div class="project-sidebar fixed-top" [class.sidebar-project-open]="sidebarSectionOpen == true" [class.sidebar-open]="sidebarOpen == true" [class.editing-rule]="editingRule">
        <div class="col text-right mt-2">
            <i class="fas fa-close fa-lg cursor-pointer grey4 ml-3" (click)="closeSideBar()"></i>
        </div>
        <div>

            <ng-container *ngIf="currentSideSection == 'info'">
              <app-project-info [project]="project" [projectType]="projectType" (onChange)="handleSettingsChange()" (callback)="infoCallback($event)"></app-project-info>
            </ng-container>

            <ng-container *ngIf="currentSideSection == 'notes'">
              <app-notes class="app-notes {{ cardNotes.expanded ? 'expanded' : '' }}" [project]="project"></app-notes>
            </ng-container>

            <ng-container *ngIf="currentSideSection == 'my-tasks'" class="taskover">
              <app-mytask [project]="project" (callback)="callbackMyTasks($event)" [refresh]="refreshMyTasks"></app-mytask>
            </ng-container>

            <ng-container *ngIf="currentSideSection == 'project-alerts'">
              <app-rules #rules [project]="project" (callback)="callbackRules($event)"></app-rules>
            </ng-container>

            <ng-container *ngIf="currentSideSection == 'downloads'">
              <app-project-download [data]="{ project: project, projectType: projectType }"></app-project-download>
            </ng-container>
        </div>
    </div>

    <div class="surveydetail">
        <div class="subsection" *ngIf="subsection == 'audit'">
            <app-project-audit (onclose)="showSubsection(null)"></app-project-audit>
        </div>
        <div class="subsection" *ngIf="subsection == 'email'">
            <app-project-email-log [projectId]="project.id" (onclose)="showSubsection(null)"></app-project-email-log>
        </div>
        <div class="subsection" *ngIf="subsection == 'reconciliation'">
            <app-project-reconciliation (onclose)="showSubsection(null)"  [project]="project"></app-project-reconciliation>
        </div>
        <div class="subsection" *ngIf="subsection == 'quota'">
            <app-project-quota (onclose)="showSubsection(null)" [respondents]="projectRespondents" [project]="project"></app-project-quota>
        </div>

        <ng-container *ngIf="subsection == null">
            <div class="project-menu mb-3" [class.sidebar-project-open]="sidebarSectionOpen == true" [class.sidebar-open]="sidebarOpen == true">
                <div class="items d-flex mt-3 pr-4 align-items-center">
                    <ng-container *ngFor="let section of sections; index as i">
                      <div class="mx-4 menu-item pb-1 cursor-pointer"  (click)="switchSection(i)" [ngClass]="{'active': i == currentSection && !showSettings }" >
                          <span class="nav-font14">
                              {{ section.title }}
                          </span>
                      </div>
                    </ng-container>
                    <ul class="nav alerts d-flex align-items-center ml-auto mt-n2" *ngIf="auth.isVendor() || auth.isInternal() || auth.isClient()">
                      <li class="or-dropdown-status" *ngIf="auth.isInternal() || auth.isClient()">
                        <or-project-stage [stage]="project?.currentStage"
                          [color]="project?.currentStageColor"
                          [percentComplete]="(project?.stageCompleteTaskCount / project?.stageTotalTaskCount)*100">
                        </or-project-stage>
                      </li>
                      <ng-container *ngIf="!auth.isClient() && !isClientview">
                        <li *ngIf="projectType.showDownload" class="nav-item ml-3 cursor-pointer" tooltip="Download Participant details" placement="left" container="body">
                          <i class="fak fa-download fa-lg grey4" (click)="download('participant')"></i>
                      </li>
                      </ng-container>
                      <li *ngIf="auth.isInternal() && vendorViewId == '' && !isClientview" (click)="openSettings()" class="nav-item mx-1 pl-2 cursor-pointer" tooltip="Open Settings" placement="left" container="body">
                        <i class=" fa-lg grey4" [ngClass]="showSettings ? 'fa-solid fa-gear': 'fak fa-settings'"  ></i>
                      </li>
                    </ul>
                </div>
            </div>
            <ng-container *ngIf="showSettings == true">
              <app-project-settings [project]="project" (onChange)="handleSettingsChange()" [projectType]="projectType" (closeCallback)="closeSettings()" ></app-project-settings>
            </ng-container>
            <ng-container *ngIf="showSettings == false">
              <ng-container *ngIf="sections[currentSection].type == 'setup'">
                <app-project-setup #setup [project]="project" (onSave)="handleSave($event)" [step]="openSetupTab" *ngIf="!(cardNotes.open  && cardNotes.expanded)"></app-project-setup>
              </ng-container>

              <ng-container *ngIf="sections[currentSection].type == 'setup-lite'">
                <app-project-setup #setup [project]="project" (onSave)="handleSave($event)" [step]="openSetupTab" [litemode]="true" *ngIf="!(cardNotes.open  && cardNotes.expanded)"></app-project-setup>
              </ng-container>

              <div *ngIf="sections[currentSection].type == 'finance'">
                  <div class="h4">Finance Coming soon</div>
              </div>

              <div *ngIf="sections[currentSection].type == 'vendor-finance'">
                <app-project-vendor-finance [project]="project"></app-project-vendor-finance>
              </div>

              <ng-container *ngIf="sections[currentSection].type == 'programming'">
                <app-project-programming [project]="project"></app-project-programming>
              </ng-container>

              <ng-container *ngIf="sections[currentSection].type == 'links-and-vendors'">
                <app-project-links-and-vendors (projectStatusChange)="project.projectStatus =  $event" (onChange)="refreshProject()" [project]="project" [respondents]="projectPerformance"></app-project-links-and-vendors>
              </ng-container>

              <ng-container *ngIf="sections[currentSection].type == 'schedule' && project!=null" >
                <app-schedule [project]="project" (callback)="callbackTasks($event)" [refresh]="refreshTasks"></app-schedule>
              </ng-container>

              <ng-container *ngIf="sections[currentSection].type == 'close' || sections[currentSection].type == 'close-external'">
                <app-project-close [project]="project" [closureType]="sections[currentSection].type"></app-project-close>
              </ng-container>

              <ng-container *ngIf="sections[currentSection].type == 'quotas'">
                <app-project-quota [project]="project"  [respondents]="projectRespondents" (redirectToSection)="redirectToSection($event)"></app-project-quota>
              </ng-container>

              <ng-container *ngIf="sections[currentSection].type == 'id-suite'">
                <app-project-id-suite [project]="project"  (onChange)="refreshPerformance()"></app-project-id-suite>
              </ng-container>

              <ng-container *ngIf="project != null && (sections[currentSection].type == null || sections[currentSection].type == 'card')">
                  <div class="paddingForProjectHeading project-container container-fluid {{ cardNotes.open ? 'notes-open ' + (cardNotes.expanded ? 'expanded' : '') : '' }}">
                      <div class="project-grid" *ngIf="!cardNotes.expanded">

                          <ng-container *ngFor="let card of sections[currentSection].cards">
                              <app-project-card-reconciliation class="expanded" *ngIf="card.cardType == 'reconciliation'" [project]="project" (onChange)="refreshPerformance()"
                                                    [mode]="card.mode">
                              </app-project-card-reconciliation>

                              <app-project-card-info class="" *ngIf="card.cardType == 'project-info'" [project]="project"
                                                    (cardresize)="cardResize($event, card)"
                                                    [mode]="card.mode"
                                                    [ngClass]="{'reduced' : card.mode=='reduced', 'expanded' : card.mode=='expanded'}">
                              </app-project-card-info>

                              <app-project-card-related class="" *ngIf="card.cardType == 'project-related'" [project]="project" class="reduced">
                              </app-project-card-related>

                              <app-project-card-survey-tester class="reduced" *ngIf="card.cardType == 'survey-tester'" [project]="project">
                              </app-project-card-survey-tester>

                                <app-project-card-finance class="reduced" *ngIf="card.cardType == 'survey-finance'" [project]="project" [respondents]="projectRespondents"></app-project-card-finance>

                              <app-project-card-documents class="reduced" *ngIf="card.cardType == 'project-documents'" [project]="project">
                              </app-project-card-documents>

                              <app-project-card-assets class="reduced" *ngIf="card.cardType == 'project-assets'" [project]="project">
                              </app-project-card-assets>

                              <app-project-card-field-plan *ngIf="card.cardType == 'survey-field-plan'" [details]="{data:projectRespondents, project:project}"
                                                          (openSetup)="openSetup(2)"
                                                          (cardresize)="cardResize($event, card)"
                                                          (redirectToSection)="redirectToSection($event)"
                                                          [mode]="card.mode"
                                                          [ngClass]="{'reduced' : card.mode=='reduced', 'settings-card-expanded' : card.mode=='expanded'}">
                              </app-project-card-field-plan>

                              <app-project-card-survey-performance-chart *ngIf="card.cardType == 'survey-performance-chart'" [respondents]="projectRespondents" [project]="project"
                                                                        class="performance-card-reduced">
                              </app-project-card-survey-performance-chart>

                              <app-project-card-survey-performance-table *ngIf="card.cardType == 'survey-performance-table'" [respondents]="projectPerformance" [project]="project"
                                                           class="performance-card-expanded" >
                              </app-project-card-survey-performance-table>

                              <app-project-card-cleanid *ngIf="card.cardType == 'survey-cleanid'" [respondents]="projectRespondents" [project]="project"
                                                        (cardresize)="cardResize($event, card)"
                                                        [mode]="card.mode"
                                                        (onChange)="handleIdSuiteChange($event)"
                                                        [ngClass]="{'reduced' : card.mode=='reduced', 'expanded' : card.mode=='expanded'}">
                              </app-project-card-cleanid>

                              <app-project-card-dedupe *ngIf="card.cardType == 'survey-dedupe'" [respondents]="" [project]="project" class="reduced"
                                                      (cardresize)="cardResize($event, card)"
                                                      [mode]="card.mode">
                              </app-project-card-dedupe>

                              <app-project-card-team *ngIf="card.cardType == 'project-team'" [project]="project" [title]="card.title" class="reduced">
                              </app-project-card-team>

                              <app-project-card-response-id (cardresize)="cardResize($event, card)"
                                                            [mode]="card.mode"
                                                            [ngClass]="{'reduced' : card.mode=='reduced', 'expanded' : card.mode=='expanded', 'd-flex': projectType.name == 'ResponseID', 'justify-content-center': projectType.name == 'ResponseID'}"
                                                            *ngIf="card.cardType == 'response-id'"
                                                            [project]="project"
                                                            [projectType]="projectType">
                              </app-project-card-response-id>

                              <app-project-card-idsuite-settings *ngIf="card.cardType == 'idsuite'" [project]="project" class="expanded"></app-project-card-idsuite-settings>
                              <app-project-card-idsuite-settings *ngIf="card.cardType == 'idsuite-client'" [clientMode]="true" [project]="project" class="expanded"></app-project-card-idsuite-settings>

                              <app-project-card-vendor *ngIf="card.cardType == 'vendor'"
                                                      class="expanded"
                                                      [respondents]="projectRespondents" [project]="project">
                              </app-project-card-vendor>

                          </ng-container>

                      </div>

                  </div>
              </ng-container>

            </ng-container>

        </ng-container>

    </div>
  </div>
</div>




