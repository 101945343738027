<div class="card">
  <div class="card-header border-bottom">
    <app-widget-title [widget]="widget" (remove)="remove()" (update)="updateTitle($event)"></app-widget-title>
  </div>
  <div class="card-body">
    <ng-container *ngIf="tableData?.length > 0">
      <div class="scrollbar overflow-y">
        <or-list
          [items]="tableData | filter : filterText : filterColumns"
          [enableExport]=true
          [layout]="layout"
          rowid="id"
          clickroute="/projects">
        </or-list>
      </div>
    </ng-container>
    <ng-container *ngIf="tableData?.length <= 0">
      <div>No data found for {{widget.title}}!</div>
    </ng-container>
  </div>

</div>
