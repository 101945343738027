import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PartnerService, AlertifyService, UtilsService, AuthService, LookupService } from 'core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UnsavedchangesGuardService } from '../../_guards/unsavedchanges.guard.service';
import { forkJoin } from 'rxjs';
import { FilterPipe } from 'projects/core/src/helpers/filter.pipe';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss'],
  providers: [PartnerService, FilterPipe]
})
export class PartnersComponent implements OnInit {
  partners: any[];
  allPartners: any[] = [];
  numToLoad = 50;
  showLoadMore = false;
  filterText = '';
  partnerForm: FormGroup;
  model: any

  audienceLabel = {};
  regionsLabel = {};
  tagsLabel = {};

  filter = {
    orderBy: '',
    orderDirection: ''
  };

  @ViewChild('modalPartner')
  modalRef: TemplateRef<any>;

  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;

  openModalRef: any;

  @ViewChild('img') imgAvatar: ElementRef;

  constructor(private partnerService: PartnerService,
    private alertify: AlertifyService,
    private lookupService: LookupService,
    public utils: UtilsService,
    public auth: AuthService,
    private filterPipe: FilterPipe,
    private modalService: BsModalService,
    private router: Router,
    private unsavedChangesService: UnsavedchangesGuardService) { }

  ngOnInit() {
    this.getPartners();
    this.createPartnerForm();
  }

  createPartnerForm() {
    this.partnerForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      instanceId: new FormControl(this.auth.getUser().instanceId),
      img: new FormControl(''),
      website: new FormControl(''),
      address1: new FormControl(''),
      address2: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      zip: new FormControl(''),
      country: new FormControl(''),
      connectionId: new FormControl(null),
      userDomain: new FormControl(null),
      completeRedirectURL: new FormControl(''),
      terminateRedirectURL: new FormControl(''),
      overQuotaRedirectURL: new FormControl(''),
      qcRedirectURL: new FormControl(''),
      audienceType: new FormControl([]),
      regions: new FormControl([]),
      tags: new FormControl([]),
    });
  }
  getPartners() {

    forkJoin([
      this.partnerService.GetPartners(),
      this.lookupService.GetLookupTable('vendor-audience-type'),
      this.lookupService.GetLookupTable('vendor-regions-served'),
      this.lookupService.GetLookupTable('vendor-tags'),
    ]).subscribe(data => {

      const audience = data[1];
      const regions = data[2];
      const tags = data[3];

      if (tags.length > 0) {
        tags.forEach(item => {
          this.tagsLabel[item.id] = item.value;
        });
      }


      let allPartners = data[0];
      if (allPartners.length > 0) {
        this.allPartners = allPartners.sort((a, b) => { return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1 });
        this.allPartners = this.allPartners.map(item => {

          //audience
          let arrAudience = [];
          item.audienceType?.map(aud => {
            arrAudience.push(audience.find(a => a.id === aud).value);
          });
          item.audienceLabel = arrAudience.map(item => item).join(', ');

          //regions
          let arrRegions = [];
          item.regions?.map(reg => {
            arrRegions.push(regions.find(a => a.id === reg).value);
          });
          item.regionsLabel = arrRegions.map(item => item).join(', ');

          return item;
        });
      }
    },
      error => {
        this.alertify.error('Unable to get vendors');
      }, () => {
        this.updateList();
      });
  }

  openPartner(partner, redirectTab = false) {
    var tab = redirectTab ? 'redirects' : null;
    this.router.navigateByUrl('/vendors/' + partner.id, {state: {tab: tab}});
  }

  showMore() {
    this.numToLoad += 10000;
    this.updateList();
  }

  SortBy(field) {
    if (field == this.filter.orderBy) {
      this.filter.orderDirection = (this.filter.orderDirection == 'ASC') ? 'DESC' : 'ASC'
    } else {
      this.filter.orderBy = field;
      this.filter.orderDirection = 'ASC';
    }
    this.sort(this.partners, this.filter.orderBy, this.filter.orderDirection);
  }

  sort(array, type, direction) {
    array.sort((a, b) => {
      var A;
      var B;
      if (type == 'name') {
        A = a.name;
        B = b.name;
      }

      if (type == 'website') {
        A = a.website;
        B = b.website;
      }
      else if (type == 'address') {
        A = a.address;
        B = b.address;
      }
      else if (type == 'contact') {
        A = a.contact;
        B = b.contact;
      }

      let comparison = 0;
      if (direction === 'ASC') {
        if (A > B) {
          comparison = 1;
        } else if (A < B) {
          comparison = -1;
        }
        return comparison;
      }
      else {
        if (A < B) {
          comparison = 1;
        } else if (A > B) {
          comparison = -1;
        }
        return comparison;
      }

    });
    return array;
  }

  updateList() {
    const filter = this.filterText.toLowerCase();
    if (filter === '') {
      this.partners = this.allPartners;
    }
    else {
      this.partners = this.filterPipe.transform(this.allPartners, filter, ['name']);
    }
    this.showLoadMore = (this.partners.length > this.numToLoad);
    this.partners = this.partners.slice(0, this.numToLoad);
  }

  partnerAdd() {
    this.model = {};
    this.createPartnerForm();
    this.openModalRef = this.modalService.show(this.modalRef, { class: 'nav-modal-style modal-md modal-partners' });
    this.unsavedChangesService.register(
      this.partnerForm,
      (form) => form.dirty
    );
  }

  partnerEdit(partner) {
    this.partnerService.GetPartner(partner.id, true).subscribe(data => {
      if (data) {
        this.model = {...data};
        this.model.audienceTypeIds = data.audienceType.map(item => item).join(',');
        this.model.regionIds = data.regions.map(item => item).join(',');
        this.model.tagIds = data.tags.map(item => item).join(',');

        this.bindForm();
        this.openModalRef = this.modalService.show(this.modalRef, { class: 'nav-modal-style modal-md modal-partners' });
      }
    }, error => {
      this.alertify.error('Unable to get partner');
    });
  }

  removeHashFromUrl(url, hashData, statusString ) {

    var hashDetails = url.match(/\[\[(.*?)\]\]/g);
    if(hashDetails == null) {
      hashData[statusString] = '';
      return url;
    }

    var hashItems = hashDetails[0].replace('[[', '').replace(']]', '').split('][');
   
    if(hashItems.length == 0) return url;

    //save the hash string for the relevent status
    hashData[statusString] = hashItems[1];

    //remove the hash from the url
    url = url.replace(hashData.linkHashParam + '=' + hashDetails[0],'');
    
    //remove the trailing & or ? from the url
    if(url[url.length - 1] == '&' || url[url.length - 1] == '?')
      url = url.slice(0, -1);

    return url;
  }
  partnerDelete(partner) {  
    this.model = partner;
    var dayDifference = this.utils.getDialogItems('partner');    
    if (dayDifference == -1) {     
      this.openModalRef = this.modalService.show(this.modalRefConfirm, { class: 'nav-modal-style' });
    }else{      
      this.partnerService.DeletePartner(partner.id).subscribe(data => {
        if (data) {
          this.alertify.success('Vendor deleted successfully');
          this.model = {};          
          this.getPartners();
        }
      }, error => {
        this.alertify.error('Unable to delete vendor');
      });
    } 
  }


  bindForm() {
    if (this.model) {
      this.partnerForm = new FormGroup({
        id: new FormControl(this.model.id),
        name: new FormControl(this.model.name, [Validators.required]),
        img: new FormControl(this.model.img),
        website: new FormControl(this.model.website),
        address1: new FormControl(this.model.address.address1),
        address2: new FormControl(this.model.address.address2),
        city: new FormControl(this.model.address.city),
        state: new FormControl(this.model.address.state),
        zip: new FormControl(this.model.address.zip),
        country: new FormControl(this.model.address.country),
        instanceId: new FormControl(this.auth.getUser().instanceId),
        connectionId: new FormControl(null),
        userDomain: new FormControl(null),
        completeRedirectURL: new FormControl(this.model.completeRedirectURL),
        terminateRedirectURL: new FormControl(this.model.terminateRedirectURL),
        overQuotaRedirectURL: new FormControl(this.model.overQuotaRedirectURL),
        qcRedirectURL: new FormControl(this.model.qcRedirectURL),
        audienceType: new FormControl([]),
        regions: new FormControl([]),
        tags: new FormControl([]),
      });
    }
  }

  confirmDelete() {
    this.partnerService.DeletePartner(this.model.id).subscribe(data => {
      if (data) {
        this.alertify.success('Vendor deleted successfully');
        this.model = {};
        this.closeModal();
        this.getPartners();
      }
    }, error => {
      this.alertify.error('Unable to delete vendor');
    });
  }

  addAudienceType(partner, event) {
    var t = [];
    event.forEach(item => {
      t.push(item.id);
    });
    partner.audienceType = t
  }

  addRegions(partner, event) {
    var t = [];
    event.forEach(item => {
      t.push(item.id);
    });
    partner.regions = t
  }

  addTags(partner, event) {
    var t = [];
    event.forEach(item => {
      t.push(item.id);
    });
    partner.tags = t
  }

  closeModal() {
    this.unsavedChangesService.deregister([this.partnerForm]);
    this.openModalRef.hide()
  }

  copyToClipboard(text: string) {
    // Create a new <textarea> element
    const textarea = document.createElement('textarea');

    // Set the value of the textarea to the text to be copied
    textarea.value = text;

    // Append the textarea to the document body
    document.body.appendChild(textarea);

    // Select the text within the textarea
    textarea.select();

    try {
      // Copy the selected text to the clipboard using the Clipboard API
      navigator.clipboard.writeText(text);
      this.alertify.success('Text Copied to Clipboard');
    } catch (err) {
      this.alertify.error('Failed to copy text to Clipboard');
    }

    // Remove the textarea from the document body
    document.body.removeChild(textarea);
  }

  getPartnerFormEvent(partnerDetails) {
    var partnerForm = partnerDetails.form;
    var goToRedirects = partnerDetails.goToRedirects;
    if (!partnerForm.value.id) {
      this.partnerService.AddPartner(partnerForm.value).subscribe(newPartner => {
        if (newPartner) {
          this.alertify.success('Vendor added successfully');
          this.model = {};
          this.closeModal();
          this.getPartners();
          this.createPartnerForm();

          if (goToRedirects && newPartner.id) {
            this.openPartner(newPartner, true);
          }
        }
      }, error => {
        this.alertify.error('Unable to add vendor');
      });

    } else {
      this.partnerService.EditPartner(partnerForm.value, false).subscribe(updatedPartner => {
        if (updatedPartner) {
          this.alertify.success('Vendor edited successfully');
          this.model = {};
          this.closeModal();
          this.getPartners();
          this.createPartnerForm();
          
          if (goToRedirects && updatedPartner.id) {
            this.openPartner(updatedPartner, true);
          }
        }
      }, error => {
        this.alertify.error('Unable to edit vendor');
      });
    }
  }
}
