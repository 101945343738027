import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertifyService, AuthService, ProjectService, ResponseIdService } from 'core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SpinnerButtonComponent } from 'projects/core/src/components/spinner-button/spinner-button.component';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-response-id-settings',
  templateUrl: './response-id-settings.component.html',
  styleUrls: ['./response-id-settings.component.scss']
})
export class ResponseIdSettingsComponent implements OnInit {
  @ViewChild('staticTabs', { static: false }) staticTabs?: TabsetComponent;
  @Input() public set project(data) {
    this._project = data;
    this.getSettings();
    this.getBatchList();
    this._newQuestionCount = 0;
    this._newRespondentCount = 0;
  }

  @Input() public set newQuestionCount(data) {
    this._newQuestionCount = data;
  }

  @Input() public set newRespondentCount(data) {
    this._newRespondentCount = data;
  }

  _project;
  _settings;
  uploadFileExist: boolean;
  _newQuestionCount: number;
  _newRespondentCount: number;
  batchList = null;
  batchId = null;
  loading = false;
  settingsForm: FormGroup;
  questionControls: FormArray;
  errorMsg: string;
  enableAgreement = false;
  FileSaver = require('file-saver');
  Questions: any[] = [];
  stab2 = false;
  stab1 = true;
  mode: string;
  currentQuestion: string;
  allCheck:boolean=false;

  questionTypeOptions: any[] = [
    { name: 'General', value: 'GENERAL', icon: 'fas fa-tasks', description: 'For General questions that do not fall under any of the other types' },
    { name: 'General Noun', value: 'GENERAL_NOUN', icon: 'fas fa-tasks', description: 'When the respondent is asked to describe a scenario or situation around a specific or several people, places or things.' },
    { name: 'Trademark', value: 'TRADEMARK', icon: 'fas fa-tasks', description: 'When the respondent is asked to provide a brand name. This brand can be any type of brand, good or service, but is specifically looking for the name of the company.' },
    { name: 'Item List', value: 'ITEM_LIST', icon: 'fas fa-tasks', description: 'When the respondent is asked to provide a list of items.' },
    { name: 'Descriptors', value: 'DESCRIPTORS', icon: 'fas fa-tasks', description: 'Looks for one or more descriptive words to be entered. This should be used when the respondent is not asked to provide a single word but words or phrases to describe something.' }, // This also includes evaluation of single words as well but is not limited to, which description_single is restricted to a single word.
    { name: 'Positive Sentiment', value: 'POSITIVE', icon: 'fas fa-tasks', description: 'When the respondent is asked to provide sentiment, specifically positive sentiment.' },
    { name: 'Negative Sentiment', value: 'NEGATIVE', icon: 'fas fa-tasks', description: 'When the respondent is asked to provide sentiment, specifically negative sentiment.' },
    { name: 'Action', value: 'ACTION', icon: 'fas fa-tasks', description: 'When the respondent is asked to describe an action they took, was taken, or will take.' },
    { name: 'Single Word Description', icon: 'fas fa-tasks', value: 'DESCRIPTION_SINGLE', description: 'When the respondent is asked to provide a single word used to describe something.' }
  ];

  @ViewChild('spinner')
  spinner: SpinnerButtonComponent;
  @Output() saveevent = new EventEmitter();
  @Output() cancelevent = new EventEmitter();

  languageOptions: { name: string, shortCode: string }[] = [
    { name: 'German', shortCode: 'de' },
    { name: 'English', shortCode: 'en' },
    { name: 'Spanish (Spain)', shortCode: 'es_spain' },
    { name: 'Spanish (Mexico)', shortCode: 'es_mexico' },
    { name: 'French', shortCode: 'fr' },
    { name: 'Italian', shortCode: 'it' },
    { name: 'Portuguese', shortCode: 'pt' },
  ];
  layout = [
    { label: 'Question Identifier', id: 'questionIdentifier', sortable: true },
    { label: '', id: 'actions', type: 'actions-checkbox' }
  ];

  actionOptions = [
    { label: 'select', type: 'selectc', icon: 'pen-square' }
  ];
  layout1 = [
    { label: 'Question Identifier', id: 'questionIdentifier', sortable: true },
    { label: 'Actions', type: 'actions-inline' }
  ];

  constructor(
    private projectService: ProjectService,
    private responseIdService: ResponseIdService,
    private alertify: AlertifyService,
    public auth: AuthService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder) { }

  ngOnInit() { }

  getSettings() {
    this.batchId = this._project?.batchId;
    this.errorMsg = this._project?.errorMessage || "";
    this.uploadFileExist = false;

    if (this.errorMsg == ""){
      this.responseIdService.GetResponseIdSettings(this._project.id).subscribe((data) => {
        this.mode = 'new';
        this._settings = data != null
          ? data
          : this._project.settings;

        if (this._settings != null) {
          // check if settings comes from DB or Uploaded Batch file
          if (!this._settings && this.batchId) {
            this._settings = this._project.settings;
            this.mode = 'new'
          } else {
            if (!this.uploadFileExist) {
              this.mode = 'edit';
            }
          }
          this.Questions = this._settings.questions;
          // select first question by default and show green tick
          const selectedQuestions = this.Questions.filter(e => e.Type !== 'SKIP');
          this.currentQuestion = (selectedQuestions.length > 0)?  selectedQuestions[0].questionIdentifier : '';
          this.loadSettings();
        }
      }, error => {
        this._settings = null;
      }, () => {
      });
    }
  }

  setActive(question, qType) {
    if(!this.uploadFileExist) return;

    this.questionTypeOptions = this.questionTypeOptions.map(q => { q.selected = false; return q });
    qType.selected = !qType?.selected;
    question.questionType = qType.value;
    question.icon = 'fas fa-check text-success';
  }

  downloadFile(url: string, fileN: string, awsFileName: string) {
    this.alertify.message('Your download will start when the report is available');
    this.responseIdService.GetResponseIdBatchFile(this._project.id, awsFileName).subscribe(url1 => {
      this.FileSaver.saveAs(url1, fileN);
    }, error => {
      this.alertify.error('There was an error generating the report.');
    });
  }

  getBatchList() {
    this.batchId = this._project?.batchId;
    this.errorMsg = this._project?.errorMessage;
    this.uploadFileExist = false;
    this.responseIdService.GetProjectResponseIdBatchList(this._project.id).subscribe((data) => {
      this.batchList = data;
      if (this.batchList.length >= 1) {
        this.stab1 = false;
        this.stab2 = true;
      } else {
        this.stab1 = true;
      }
    }, error => {
      console.log(error);
      this._settings = null;
    }, () => {
    });
  }

  loadSettings() {
    if (this._settings) {
      this.questionControls = this.formBuilder.array(
        this._settings.questions.map((rawQData) => {
          return this.formBuilder.group({
            questionIdentifier: [rawQData.questionIdentifier, Validators.required],
            questionType: rawQData.questionType,
            questionText: rawQData.questionText,
            separator: rawQData.separator,
            classification: rawQData.classification
          });
        })
      );
      this.settingsForm = this.formBuilder.group({
        language: [this._settings.language, Validators.required],
        resetData : [this._settings.reset],
        questions: this.questionControls
      });
    }
  }

  termsChange(question): void { question.questionType = (question.questionType === 'SKIP') ? '' : 'SKIP'; }

  onChange(isChecked: boolean) {
    this.allCheck=isChecked;
    if (isChecked) {
      for (let i = 0; i < this.Questions.length; i++) {
        if (this.Questions[i].questionType == 'SKIP') {
          this.Questions[i].questionType = "";
        }
      }
    } else {
      for (let i = 0; i < this.Questions.length; i++) {
        if (this.Questions[i].questionType != 'SKIP' && this.Questions[i].questionType == '') {
          this.Questions[i].questionType = 'SKIP';
        }
      }
    }
  }

  handleAction(action) { action.row.questionType = (action.evt.currentTarget.checked) ? '' : 'SKIP'; }
  get selectedQuestions() { return this.Questions.filter(e => e.questionType !== 'SKIP'); }

  saveQuestions() {
    let checkv = false;
    for (let i = 0; i < this.Questions.length; i++) {
      if (this.Questions[i].questionType == null || this.Questions[i].questionType === '') {
        checkv = true;
      }
    }
    if (checkv) {
      this.alertify.error('Please select Question Type for all Questions.');
      return;
    }
    this.settingsForm.value.questions = this.Questions;
    if (!this.batchId) {
      this.responseIdService.UpdateResponseIdSettings(this._project.id, this.settingsForm.value).subscribe((res) => {
        this.alertify.success('ResponseID Settings updated');
        this.bsModalRef.hide();
      }, (err) => {
        this.alertify.error('Unable to update ResponseID Settings!');
      });
    } else {
      // Save settings and Process batch file
      this.responseIdService.ProcessBatch(this._project.id, this.batchId, this.settingsForm.value).subscribe((res) => {
        this.alertify.success('ResponseID batch file is being processed');
        this.saveevent.emit(this._project);
        this.bsModalRef.hide();
        this.uploadFileExist = false;
      }, (err) => {
        this.saveevent.emit(this._project);
        this.alertify.error('Unable to Save & Process ResponseID batch');
      });
    }
  }

  openQuestionTypePicker() {
    this.stab2 = false;
    const selectedQuestions = this.Questions.filter(e => e.questionType !== 'SKIP');
    if (selectedQuestions.length === 0) {
      this.alertify.error('Please select some questions you want to process');
      return;
    }
    if (this.enableAgreement) {
      this.staticTabs.tabs[1].active = true;
      this.stab2 = true;
      // select first question by default and show green tick
      this.currentQuestion = selectedQuestions[0].questionIdentifier;
    } else {
      this.alertify.error('Please agree to the terms of use regarding PII before continuing');
    }
  }

  cancel() {
    this.cancelevent.emit(false);
    this.bsModalRef.hide();
  }
  back() {
    this.staticTabs.tabs[0].active = true;
    this.stab1 = true;
  }

  changeSetting(evt, setting) {
    const rollback = this._project[setting];
    this.loading = true;

    this.projectService.UpdateProjectValue(this._project.id, setting, evt).subscribe(data => {
      this.alertify.success('ResponseID Updated for this project');
      this.loading = false;
    }, error => {
      this.alertify.error('Failed to Update ResponseID');
      // this.project[setting] = rollback;
      this.loading = false;
    });
  }
}
