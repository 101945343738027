import { Component, OnInit, Input } from '@angular/core';
import { ProjectService } from 'core';

@Component({
  selector: 'app-project-card-related',
  templateUrl: './project-card-related.component.html',
  styleUrls: ['./project-card-related.component.scss']
})
export class ProjectCardRelatedComponent implements OnInit {
  @Input() project: any;

  relatedProjects: any = [];

  constructor(private projectService: ProjectService) { }

  ngOnInit() {
    this.projectService.GetRelatedProjects(this.project.id).subscribe(data => {
      this.relatedProjects = data;
    },
      error => {
    });
  }
}
