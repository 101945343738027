import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'survey-tester-decipher',
  templateUrl: './survey-tester-decipher.component.html',
  styleUrls: ['./survey-tester-decipher.component.scss']
})
export class SurveyTesterDecipherComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
