<div class="px-4">
  <!-- <div class="col d-flex align-items-center px-0 mb-3">
    <h3 class="grey4 mr-auto mb-0">Quotas</h3>
  </div> -->
  
<div class=" mainCard p-3 card" >
  <div class="card-header">
    <div class="row d-flex align-items-center">
      <div class="col-8 p-0">
        <h3 class="nav-secondary font-weight-bold">Quotas</h3>
      </div>
      <div *ngIf="!auth.isClient() && !auth.isVendor() && !isVendorview" class="col-4">
        <div class="text-right">
          <div class="btn-group" dropdown #dropdownSettings="bs-dropdown" container="body">
            <div id="button-basic" dropdownToggle type="button" controls="dropdown-basic" class="px-2">
              <i class="fak fa-lg fa-ellipsis-vertical fa-md cursor-pointer grey4"></i>
            </div>
            <ul id="dropdown-settings" *dropdownMenu class="dropdown-menu dropdown-menu-right py-0" role="menu" aria-labelledby="button-basic">
              <ng-container *ngIf="project.isQuotaEnabled">
                <a class="dropdown-item nav-font14 cursor-pointer" (click)="setToEditMode()">Edit</a>
                <hr class="mx-2 my-0">
                <ng-container *ngIf="!showSettings">
                  <a class="dropdown-item nav-font14 cursor-pointer" (click)="toggleSettings()">Settings</a>
                  <hr class="mx-2 my-0">
                </ng-container>
                <a class="dropdown-item nav-font14 cursor-pointer" 
                  container="body"
                  placement="bottom"
                  [tooltip]="project.fullLaunchQuota == 0 ? 'Cannot add quotas while project completes allocation is 0' : ''" 
                  [class.disabledBtn]="project.fullLaunchQuota == 0"
                  (click)="openModal(modal, 'templateImport')">Import Quota</a>
                <hr class="mx-2 my-0">
                <a class="dropdown-item nav-font14 cursor-pointer" 
                  container="body"
                  placement="bottom"
                  [tooltip]="project.fullLaunchQuota == 0 ? 'Cannot add quotas while project completes allocation is 0' : ''" 
                  [class.disabledBtn]="project.fullLaunchQuota == 0"
                  (click)="openModal(modal, 'templateClone')">Clone Quota</a>
                <hr class="mx-2 my-0">
                <a class="dropdown-item nav-font14 cursor-pointer" 
                  container="body"
                  (click)="downloadQuota()">Download Quota Setup</a>
                <hr class="mx-2 my-0">
                <a class="dropdown-item nav-font14 cursor-pointer" 
                  container="body"
                  (click)="downloadQuotaTemplate()">Download Quota Template</a>
                <hr class="mx-2 my-0">
              </ng-container>
              <a class="dropdown-item nav-font14 cursor-pointer" (click)="enableQuota()">Turn Quotas {{quotaEnabled ? 'Off' : 'On'}}</a>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="formMode != 'setup' || newQuota == null" class="px-3 row">
    <ng-container *ngIf="project.isQuotaEnabled">
      <!-- add demographic button dropdown -->
      <span 
        *ngIf="!auth.isClient() && !auth.isVendor() && !isVendorview"
        class="btn shadow-none nav-btn-accent px-2 pb-1 pt-0 mt-3 mr-3" 
        placement="bottom" 
        [tooltip]="project.fullLaunchQuota == 0 ? 'Cannot add quotas while project completes allocation is 0' : ''" 
        [ngClass]="!dropdownDemos.isOpen ? (project.fullLaunchQuota == 0 ? 'addDemoBtnClosed disabledBtn' : 'addDemoBtnClosed') : 'addDemoBtn'">
        <div class="btn-group" dropdown  #dropdownDemos="bs-dropdown" container="body" [ngClass]="project.fullLaunchQuota == 0 ? 'disabled' : ''">
          <div id="button-basic" dropdownToggle controls="dropdown-basic">
            <span class=" text-nowrap nav-font14" [ngClass]="project.fullLaunchQuota > 0 ? 'cursor-pointer' : 'cursor-default'">
              Add Demographic 
              <i class="px-1 fa fa-light fa-pipe"></i>
              <i class="fak fa-down-arrow fa-s ml-1" style="font-size:9px;"></i>
            </span>
          </div>
          <ul id="dropdown-status" *dropdownMenu class="dropdown-menu dropdown-menu-right py-0 nav-font14" role="menu" aria-labelledby="button-basic">
            <div class="d-block text-right px-2">
            </div>
            <li role="menuitem" *ngFor="let question of questionTypes" class="expandable">
              <a 

                class="dropdown-item" 
                container="body"
                [tooltip]="addedTypes[question.value] != null || 
                  (question.value == 'Division' && addedTypes['Region'] != null) || 
                  (question.value == 'Region' && addedTypes['Division'] != null) ? question.label + ' has been added': ''"
                  placement="bottom"

                [ngClass]="addedTypes[question.value] != null || 
                  (question.value == 'Division' && addedTypes['Region'] != null) || 
                  (question.value == 'Region' && addedTypes['Division'] != null)
                  ? 'disabledItem' : 'cursor-pointer'" 
                (click)="!addedTypes[question.value] ? addQuotaGroup(question.value, question.label) : ''">
                {{ question.label }}
              </a>
            </li>
          </ul>
        </div>
      </span>

      <!-- view limit by toggle -->
      <ng-container *ngIf="quotaDetails?.limitCheck != 'tracking only'">
        <div class="pl-2 pt-4 grey5 nav-font14">View limit by</div>
        <div class="">
          <span class=" mt-1 toggleButton mt-3">
            <or-view-toggle (valueChange)='toggleLimitType($event)' [showNumber]=true [showPercent]=true [active]="quotaDetails?.limitType" [class]="'btn-sm'"></or-view-toggle>
          </span>
        </div>
      </ng-container>
    </ng-container>
        
   
   
  </div>
  <!-- quotas not enabled -->
  <ng-container *ngIf="!project.isQuotaEnabled">
    <div class="card-body">
      <div class="row">
        <div class="col"></div>
      </div>
      <div class="row my-2 nav-bg-primary">
        <div class="col p-3 text-center">
          <div class="row m-2">
            <div class="col">
              <i class="far fa-question-circle fa-2x or-secondary"></i>
            </div>
          </div>
          <div class="row m-2">
            <div class="col">
              <p class="font-weight-bold or-secondary">Quotas disabled.</p>
              <small class="d-block mb-3 or-secondary">
              Quotas are not enabled for this Project.
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col"></div>
      </div>
    </div>
  </ng-container>
  <div class="card shadow px-3 mt-4" *ngIf="formMode == 'setup' && newQuota" >
    <div class="card-header p-0 px-1 pt-3 nav-font16 nav-secondary font-weight-bold">
      Add new Quota
    </div>
    <div  
      class="mainCardHeader m-1" 
      style=" min-height:100px !important;">
      
      <div class="card-body mainCardBody px-0">
        <div class="card border-0 overallHeaderRow"
            style="min-height:100px !important;">

          <div class="card-header overallCard radius-bottom-none grayOne nav-secondary text-center py-1 pt-2"> 
            Overall 
          </div>
          <div class="card-body grayOneBorder nav-font14 grey4 px-2 py-4 m-0">
            <div _ngcontent-cji-c270="" class="row px-2 mb-4 overallRow">
            </div>
            <app-quota-infield-overall-card
            [group]="newQuota" 
            [metrics]="metrics"
            [questions]="questions" 
            [vendors]="vendors" 
            [enableVendorLimits]="quotaDetails?.enableVendorLimits"
            [fullLaunchQuota]="project.fullLaunchQuota"
            [limitType]="quotaDetails?.limitType" 
            [limitCheck]="quotaDetails?.limitCheck" 
            [limitMode]="quotaDetails?.limitMode"
            [newQuota]="newQuota != null"
            [enableVendorLimits]="quotaDetails?.enableVendorLimits"
            [isVendorview]="isVendorview"
            (onChange)="inlineChange($event)"
            (clickedOnQuota)="clickedOnQuotaRow($event)"
            >
          </app-quota-infield-overall-card>   
          </div>
        </div> 
        <div class="card border-0 vendorHeaderHidden vendorHeaderRow grayOneBorder " *ngIf="quotaDetails.enableVendorLimits"
            style="min-height:100px !important;">
          <div class="card-header overallCard radius-bottom-none grayOne nav-secondary text-center py-1 pt-2">
            <ng-container *ngIf="vendors?.length > 2">
              <button class="vendorArrowBtn float-left carousel" (click)="changeVendorIndex(-1)"><i class=" fa fa-angle-left fa-lg text-secondary carousel"></i></button> 
            </ng-container>
            Vendors
            <ng-container *ngIf="vendors?.length > 2">
              <button class="vendorArrowBtn float-right carousel" (click)="changeVendorIndex(1)"><i class=" fa fa-angle-right fa-lg text-secondary carousel"></i></button>
            </ng-container>
            
          </div>
          <!-- no vendor exists -->
          <ng-container *ngIf="!vendors || vendors?.length <= 0">
            <h3 class="grey2 text-center py-5 px-4 overallRow">Add a vendor to the project to begin viewing vendor quota data

              <a (click)="navigateToLinksAndVendors()" class="navfont-14 nav-accent-blue font-weight-bold my-2 mr-2 cursor-pointer partner-name">
                <div class="row d-flex justify-content-center my-3 nav-font16">
                  <div class="col">
                    <i class="far fa-plus pr-2"></i>
                    Add vendor to begin
  
                  </div>
                </div>
              </a>
            </h3>
 
          </ng-container>
    
          <div class="row card-body p-0 mx-0 grey4 nav-font14 ">
    
            <!-- only one vendor exists -->
            <ng-container *ngIf="vendors && vendors?.length == 1">
              <div class="col-12 p-0 py-4 nav-secondary text-center grayOneBorder">
                <div class="mb-4 nav-font16 overallRow">
                  {{vendors[0].partnerName}}
                </div>
                
                <ng-container *ngIf="questionIDs.includes(newQuota.questionId) && !newQuota.isDeleted;">
                  <div class="vendorHeaderRow">
                    <app-quota-infield-vendor-card 
                      [group]="newQuota" 
                      [vendor]="vendors[vendorIndexLeft]" 
                      [metrics]="metrics" 
                      [fullLaunchQuota]="project.fullLaunchQuota"
                      [questions]="questions" 
                      [limitType]="quotaDetails?.limitType" 
                      [limitCheck]="quotaDetails?.limitCheck" 
                      [limitMode]="quotaDetails?.limitMode"
                      [enableVendorLimits]="quotaDetails?.enableVendorLimits"
                      [isVendorview]="isVendorview"
                      (onChange)="inlineChange($event)"
                      (clickedOnQuota)="clickedOnQuotaRow($event)">
                    </app-quota-infield-vendor-card>
                  </div>
                </ng-container>
              </div>
            </ng-container>
    
            <!-- more than one vendor exists -->
            <ng-container *ngIf="vendors && vendors?.length > 1">
              <div
                class="col-6 p-0 py-4 nav-secondary text-center grayOneBorder vendor1"
                [ngClass]="{'halfBackgroundOne' : !swapColor && vendors?.length > 1, 'halfBackgroundTwo' : swapColor && vendors?.length > 1 }">
                
                <div class="mb-4 nav-font16 overallRow">
                  {{ vendors[vendorIndexLeft].partnerName }}
                </div>
    
                <!-- vendor card carousel left -->
                <ng-container *ngIf="questionIDs.includes(newQuota.questionId) && !newQuota.isDeleted;">
                  <div class="vendorHeaderRow">
                    <app-quota-infield-vendor-card 
                      [group]="newQuota" 
                      [vendor]="vendors[vendorIndexLeft]" 
                      [metrics]="metrics" 
                      [fullLaunchQuota]="project.fullLaunchQuota"
                      [questions]="questions" 
                      [limitType]="quotaDetails?.limitType" 
                      [limitCheck]="quotaDetails?.limitCheck" 
                      [limitMode]="quotaDetails?.limitMode"
                      [enableVendorLimits]="quotaDetails?.enableVendorLimits"
                      [isVendorview]="isVendorview"
                      (onChange)="inlineChange($event)"
                      (clickedOnQuota)="clickedOnQuotaRow($event)">
                    </app-quota-infield-vendor-card>
                  </div>
                </ng-container>
    
              </div>
              <div class="col-6 p-0 py-4 nav-secondary text-center grayOneBorder vendor2"  [ngClass]="{'halfBackgroundOne' : swapColor && vendors?.length > 1, 'halfBackgroundTwo' : !swapColor && vendors?.length > 1 }">
                
                <div class="mb-4 nav-font16 overallRow">
                  {{ vendors[vendorIndexRight].partnerName }}
                </div>
                
                <!-- vendor card carousel right -->
                  <ng-container *ngIf="questionIDs.includes(newQuota.questionId) && !newQuota.isDeleted;">
                    <div class="vendorHeaderRow">
                      <app-quota-infield-vendor-card 
                        [group]="newQuota" 
                        [vendor]="vendors[vendorIndexRight]" 
                        [metrics]="metrics"
                        [questions]="questions" 
                        [fullLaunchQuota]="project.fullLaunchQuota"
                        [limitType]="quotaDetails?.limitType" 
                        [limitCheck]="quotaDetails?.limitCheck" 
                        [limitMode]="quotaDetails?.limitMode"
                        [enableVendorLimits]="quotaDetails?.enableVendorLimits"
                        [isVendorview]="isVendorview"
                        (onChange)="inlineChange($event)"
                        (clickedOnQuota)="clickedOnQuotaRow($event)">
                      </app-quota-infield-vendor-card>
    
                    </div>
                  </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div *ngIf="project.isQuotaEnabled" class="d-flex align-items-center justify-content-end mx-2 mb-3">
        <button 
          class="btn btn-sm nav-btn-primary mx-1 shadow-none" 
          (click)="leaveSetupMode()">Cancel</button>
        <!-- <button class="btn btn-secondary btn-sm mr-3" (click)="openModal(modal, 'onClose')">Cancel</button> -->
        <button class="btn btn-sm nav-btn-accent shadow-none" (click)="saveQuota()">
          Save Quota
        </button>
      </div>
    </div>
  </div>

  <!-- Quota Settings -->
  <div  *ngIf="showSettings" class="settingsCard card border mainCardHeader m-1 mt-4">
    <div class="card-header px-3">
      <span class="nav-secondary d-inline">
        Settings
      </span>
      <span class="float-right">
        <i class="fa fa-times fa-lg grey4 cursor-pointer" (click)="toggleSettings()"></i>
      </span>
    </div>
    <div class="card-body nav-font14 grey4 px-3 pt-0 pb-4">
      <div class="row mt-3">
        <div class="col-4 px-3 d-inline text-left settingsSeperatorRight">
          <div class="w-50">
            <span class="grey4 nav-font14 font-weight-bold">Select setting option</span>
            <div class="row mt-3">
              <div class=" col-10">Starts &nbsp;                 
                <i class="fak fa-info grey4" tooltip="This will set the number of starts as the goal/limit post-CleanID for live traffic in the survey"></i>
              </div>
              <div class="col-2">
                <input type="radio" class="radioButton"
                  (click)="quotaDetails.limitMode = 'start'"          
                  (change)="handleQuotaChange(); saveQuota()"
                  [checked]="quotaDetails.limitMode == 'start'">
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-10">Completes &nbsp;
                <i class="fak fa-info grey4" tooltip="This will set the number of completes as the goal/limit that return from the survey"></i>
              </div>
              <div class="col-2 text-nowrap">
                <input type="radio" class="radioButton" 
                (click)="quotaDetails.limitMode = 'complete'"                 
                [checked]="quotaDetails.limitMode == 'complete'"
                (change)="handleQuotaChange(); saveQuota()">
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 px-3 d-inline d-flex justify-content-center">
          <div class="mx-5 w-75">
            <span class="grey4 nav-font14 font-weight-bold">Select quota control option</span>
            <div class="row mt-3">
              <div class="text-left col-10">Enforced quotas &nbsp;                 
                <i class="fak fa-info grey4" tooltip="Pause traffic for the quota selection once the limit is reached"></i>
              </div>
              <div class="col-2 text-nowrap">
                <input type="radio" class="radioButton"
                  (click)="quotaDetails.limitCheck = 'enforced'"          
                  (change)="saveQuota()"
                  [checked]="quotaDetails.limitCheck == 'enforced'">
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-10 text-nowrap text-left">Tracking with goals &nbsp;
                <i class="fak fa-info grey4" tooltip="Allow traffic to continue after a goal is met"></i>
              </div>
              <div class="col-2">
      
                <input type="radio" class="radioButton"
                (click)="quotaDetails.limitCheck = 'tracking'"                 
                [checked]="quotaDetails.limitCheck == 'tracking'"
                (change)="saveQuota()">
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-10 text-left">Tracking only &nbsp;
                <i class="fak fa-info grey4" tooltip="Allow all traffic to continue and monitor without goals"></i>
              </div>
              <div class="col-2">
                <input type="radio" class="radioButton"
                (click)="quotaDetails.limitCheck = 'tracking only'"                 
                [checked]="quotaDetails.limitCheck == 'tracking only'"
                (change)="toggleLimitType('limitCheckChanged')">
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 px-3 d-inline d-flex justify-content-center settingsSeperatorLeft">
          <div class="mx-5">
            <span class="grey4 nav-font14 font-weight-bold">Select view option</span>
            <div class="row mt-3">
              <div class="col-10 text-nowrap text-left">Overall quotas only &nbsp;
              </div>
              <div class="col-2">
                <input type="radio" class="radioButton"
                (click)="changeVendorQuotaSetting(false)"
                [checked]="!this.quotaDetails.enableVendorLimits">
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-10 text-nowrap text-left">Overall and vendor quotas &nbsp;
              </div>
              <div class="col-2">
                <input type="radio" class="radioButton"
                (click)="changeVendorQuotaSetting(true)"                 
                [checked]="this.quotaDetails.enableVendorLimits == true">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 
  <div *ngIf="project.isQuotaEnabled && formMode == 'setup' && newQuota == null" class="d-flex align-items-center justify-content-end mx-2 mb-3">
    <button 
      class="btn btn-sm nav-btn-primary mx-1 shadow-none" 
      (click)="leaveSetupMode()">Cancel</button>
    <!-- <button class="btn btn-secondary btn-sm mr-3" (click)="openModal(modal, 'onClose')">Cancel</button> -->
    <button class="btn btn-sm nav-btn-accent shadow-none" (click)="saveQuota()">
      Save Quota
    </button>
  </div>

  <div *ngIf="project.isQuotaEnabled" class="card-body mainCardBody px-0" [ngClass]="(formMode == 'setup' && newQuota != null) ? 'editing-mode': ''"> 

    <!-- Question Cards -->
    <ng-container *ngIf="project.isQuotaEnabled">
      <ng-container class="quotaDetailsCards">
        <div class="card border-0 overallHeaderRow">
          <div class="card-header overallCard radius-bottom-none grayOne nav-secondary text-center py-1 pt-2">
            Overall
          </div>
          <div class="card-body grayOneBorder nav-font14 grey4 px-2 py-4 m-0">

            <!-- overall progress -->
            <ng-container *ngIf="this.quotaDetails?.quotaGroups?.length > 0 && !typesEmpty">
              <div class="row px-2 mb-4 overallRow" *ngIf="metrics">
                <div class="col-3 text-center text-nowrap">
                  <span>Overall {{quotaDetails.limitMode}}s </span>
                  
                </div>
                <ng-container *ngIf=" this.quotaDetails?.quotaGroups?.length > 0 && project.isQuotaEnabled">
                  <div class="col-5">
                    <div class="rule">
                      <!-- overall heading progress guage for wide screen -->
                      <ng-container *ngIf="quotaDetails.limitMode == 'complete'">
                        <or-progress-guage class="" mode="quota" [value]="(metrics.completes*100)/project.fullLaunchQuota" [expected]="daysPercentagePast"  [bgColor]="getBgColor((metrics.completes*100)/project.fullLaunchQuota)"  [bgDanger]="getBgColor((metrics.completes*100)/project.fullLaunchQuota)" ></or-progress-guage>
                      </ng-container>
                      <ng-container *ngIf="quotaDetails.limitMode == 'start'">
                        <or-progress-guage class="" mode="quota" [value]="(metrics.starts*100) / project.fullLaunchQuota" [expected]="daysPercentagePast" [bgColor]="getBgColor((metrics.starts*100) / project.fullLaunchQuota)" [bgDanger]="getBgColor((metrics.starts*100) / project.fullLaunchQuota)"></or-progress-guage>
                      </ng-container>
                    </div>
                  </div>
                  <div class="col-1 px-0 text-left">
                    <ng-container *ngIf="quotaDetails.limitMode == 'complete'">
                      <span>{{metrics.completes/project.fullLaunchQuota | percent}}</span>
                    </ng-container>
                    <ng-container *ngIf="quotaDetails.limitMode == 'start'">
                      <span>{{metrics.starts/project.fullLaunchQuota | percent}}</span>
                    </ng-container>
                  </div>
                  <div class="col-3 text-center pl-0 text-nowrap">
                    <div class="">
                      <div class="d-inline">{{ quotaDetails.limitMode == 'complete' ? metrics.completes : metrics.starts }}/</div>
                      <ng-container *ngIf="editOverallN == false">
                        <span class="editable" (click)="enableEditOfOverall();">{{ project.fullLaunchQuota }} n</span>
                      </ng-container>
                      <div class="pl-1 d-inline" *ngIf="editOverallN != false">
                        <input (keyup.enter)="SaveOverallN()" (blur)="SaveOverallN()" type="text"
                          (focus)="$event.target.select()"
                          class="form-control form-control-nav form-control-sm d-inline inlineInput"
                          [(ngModel)]="project.fullLaunchQuota">
                        <span class="pl-1">n</span>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>

            <!-- if quota enabled and no quotas added -->
            <ng-container *ngIf="typesEmpty && project.isQuotaEnabled">
              <h3 class="text-center grey2 py-4 px-5">
                Add a demographic question to begin viewing quota data
              </h3>
            </ng-container>

            <!-- if quota enabled and quotas added - overall quota cards -->
            <ng-container *ngIf="this.quotaDetails?.quotaGroups?.length > 0 && project.isQuotaEnabled && !typesEmpty">
              <ng-container  *ngFor="let group of quotaDetails.quotaGroups; index as i" >     
                <ng-container *ngIf="questionIDs.includes(group.questionId) && !group.isDeleted;">
                  <div class="mb-1">
                    <div class="overallHeaderRow">
        
                      <app-quota-infield-overall-card
                        *ngIf="group.show != false"
                        [group]="group" 
                        [metrics]="metrics"
                        [questions]="questions" 
                        [vendors]="vendors" 
                        [enableVendorLimits]="quotaDetails?.enableVendorLimits"
                        [fullLaunchQuota]="project.fullLaunchQuota"
                        [limitType]="quotaDetails?.limitType" 
                        [limitCheck]="quotaDetails?.limitCheck" 
                        [limitMode]="quotaDetails?.limitMode"
                        [newQuota]="newQuota != null"
                        [enableVendorLimits]="quotaDetails?.enableVendorLimits"
                        [daysPercentagePast]="daysPercentagePast"
                        [isVendorview]="isVendorview"
                        (onChange)="inlineChange($event)"
                        (clickedOnQuota)="clickedOnQuotaRow($event)"
                        >
                      </app-quota-infield-overall-card>                        
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container> 
          </div>
        </div>
       
        <div class="card border-0 vendorHeaderHidden vendorHeaderRow" *ngIf="quotaDetails?.enableVendorLimits">
          <div class="card-header overallCard radius-bottom-none grayOne nav-secondary text-center py-1 pt-2">
            <ng-container *ngIf="vendors?.length > 2">
              <button class="vendorArrowBtn float-left carousel" (click)="changeVendorIndex(-1)"><i class=" fa fa-angle-left fa-lg text-secondary carousel"></i></button> 
            </ng-container>
            Vendors
            <ng-container *ngIf="vendors?.length > 2">
              <button class="vendorArrowBtn float-right carousel" (click)="changeVendorIndex(1)"><i class=" fa fa-angle-right fa-lg text-secondary carousel"></i></button>
            </ng-container>
            
          </div>
          <!-- no vendor exists -->
          <ng-container *ngIf="!vendors || vendors?.length <= 0">
            <h3 class="grey2 text-center py-5 px-4 overallRow">Add a vendor to the project to begin viewing vendor quota data
              <a (click)="navigateToLinksAndVendors()" class="navfont-14 nav-accent-blue font-weight-bold my-2 mr-2 cursor-pointer partner-name">
                <div class="row d-flex justify-content-center my-3 nav-font16">
                  <div class="col">
                    <i class="far fa-plus pr-2"></i>
                    Add vendor to begin
  
                  </div>
                </div>
              </a>
            </h3>

          </ng-container>

          <div class="row card-body p-0 mx-0 grey4 nav-font14 ">

            <!-- only one vendor exists -->
            <ng-container *ngIf="vendors && vendors?.length == 1">
              <div class="col-12 p-0 py-4 nav-secondary text-center grayOneBorder">
                <div class="mb-4 nav-font16 overallRow">
                  {{vendors[0].partnerName}}
                </div>
                
                <ng-container  *ngFor="let group of quotaDetails.quotaGroups; index as i" >     
                  <ng-container *ngIf="questionIDs.includes(group.questionId) && !group.isDeleted;">
                    <div class="vendorHeaderRow">
                      <app-quota-infield-vendor-card 
                        [group]="group" 
                        [vendor]="vendors[vendorIndexLeft]" 
                        [metrics]="metrics"
                        [fullLaunchQuota]="project.fullLaunchQuota"
                        [questions]="questions" 
                        [limitType]="quotaDetails?.limitType" 
                        [limitCheck]="quotaDetails?.limitCheck" 
                        [limitMode]="quotaDetails?.limitMode"
                        [enableVendorLimits]="quotaDetails?.enableVendorLimits"
                        [isVendorview]="isVendorview"
                        (onChange)="inlineChange($event)"
                        (clickedOnQuota)="clickedOnQuotaRow($event)">
                      </app-quota-infield-vendor-card>
                    </div>
                  </ng-container>
                </ng-container>                
              </div>
            </ng-container>

            <!-- more than one vendor exists -->
            <ng-container *ngIf="vendors && vendors?.length > 1">
              <div
                class="col-6 p-0 py-4 nav-secondary text-center grayOneBorder vendor1"
                [ngClass]="{'halfBackgroundOne' : !swapColor && vendors?.length > 1, 'halfBackgroundTwo' : swapColor && vendors?.length > 1 }">
                
                <div class="mb-4 nav-font16 overallRow">
                  {{ vendors[vendorIndexLeft].partnerName }}
                </div>

                <!-- vendor card carousel left -->
                <ng-container  *ngFor="let group of quotaDetails.quotaGroups; index as i" >     
                  <ng-container *ngIf="questionIDs.includes(group.questionId) && !group.isDeleted;">
                    <div class="vendorHeaderRow">
                      <app-quota-infield-vendor-card 
                        [group]="group" 
                        [vendor]="vendors[vendorIndexLeft]" 
                        [metrics]="metrics" 
                        [fullLaunchQuota]="project.fullLaunchQuota"
                        [questions]="questions" 
                        [limitType]="quotaDetails?.limitType" 
                        [limitCheck]="quotaDetails?.limitCheck" 
                        [limitMode]="quotaDetails?.limitMode"
                        [enableVendorLimits]="quotaDetails?.enableVendorLimits"
                        [isVendorview]="isVendorview"
                        (onChange)="inlineChange($event)"
                        (clickedOnQuota)="clickedOnQuotaRow($event)">
                      </app-quota-infield-vendor-card>
                    </div>
                  </ng-container>
                </ng-container>

              </div>
              <div class="col-6 p-0 py-4 nav-secondary text-center grayOneBorder vendor2"  [ngClass]="{'halfBackgroundOne' : swapColor && vendors?.length > 1, 'halfBackgroundTwo' : !swapColor && vendors.length > 1 }">
                
                <div class="mb-4 nav-font16 overallRow">
                  {{ vendors[vendorIndexRight].partnerName }}
                </div>
                
                <!-- vendor card carousel right -->
                <ng-container  *ngFor="let group of quotaDetails.quotaGroups; index as i" >     
                  <ng-container *ngIf="questionIDs.includes(group.questionId) && !group.isDeleted;">
                    <div class="vendorHeaderRow">
                      <app-quota-infield-vendor-card 
                        [group]="group" 
                        [vendor]="vendors[vendorIndexRight]" 
                        [metrics]="metrics"
                        [questions]="questions" 
                        [fullLaunchQuota]="project.fullLaunchQuota"
                        [limitType]="quotaDetails?.limitType" 
                        [limitCheck]="quotaDetails?.limitCheck" 
                        [limitMode]="quotaDetails?.limitMode"
                        [enableVendorLimits]="quotaDetails?.enableVendorLimits"
                        [isVendorview]="isVendorview"
                        (onChange)="inlineChange($event)"
                        (clickedOnQuota)="clickedOnQuotaRow($event)">
                      </app-quota-infield-vendor-card>

                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </div>
          
        </div>
        
      </ng-container>
      
    </ng-container>
  </div>
</div>

<div>
  <ng-template #modal>
    <ng-container *ngIf="modalType==='onClose'">
      <div class="modal-header">
        <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18">Confirm Close </h2>
      </div>
      <div class="modal-body text-center">
        <p class="h6">Are you sure you want to close without saving?</p>
        <button type="button" class="btn btn-outline-secondary m-2" (click)="closeModal('onClose')">Yes</button>
        <button type="button" class="btn btn-secondary" (click)="openModalRef.hide()">No</button>
      </div>
    </ng-container>
    <ng-container *ngIf="modalType==='templateClone'">
      <div class="modal-header">
        <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18">Clone Quota </h2>
        <button tabindex="-1" type="button" class="close pull-right grey4" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="px-1">Search for a quota to clone from existing projects:</div>
        <div class="pr-0  mr-3 mb-0 pb-0 mt-2 templateSearch">
          <ng-container>
            <or-type-ahead  [labelField]="'name'" [multi]="false" [valueField]="'value'" [showLoading]="false" class=""
              [dataCallback]="getProjectList"
              cleanAfterSelect="true"
              placeholder="Search by ID, Name or Client"
              (valueChange)="updateProject($event)" >
            </or-type-ahead>
            <div class="mt-5 mb-3">
              <or-list class="quotaTemplateList" *ngIf="projectList.length > 0" [items]="projectList" [layout]="quotaTemplates" [actions]="actions" (action)="handleAction($event)" [enableExport]="false"></or-list>
            </div>
          </ng-container>
        </div>
    
      </div>
    </ng-container>    
    <ng-container *ngIf="modalType==='templateImport'">
      <div class="modal-header">
        <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18">Import Quota </h2>
        <button tabindex="-1" type="button" class="close pull-right grey4" aria-label="Close" (click)="closeModal(); importFileName = null">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="grey5 mb-5">Import quota from a .xlsx or .csv file. This will override any existing quota setup on this project.</p>
        <div class="pr-0 mr-3 mb-0 pb-0 mt-2 templateSearch d-flex justify-content-center">
          <ng-container>
            <div class="col-md-12 col-lg-6">
              <or-upload  
                mainMsg="Select a CSV file to upload"
                smallMsg="or drag and drop the file here to begin"
                [includeProgressBar]=true
                acceptTypes=".csv,.xlsx"
                [includeFileName]="true"
                [instanceId]="auth.getUser().instanceId"
                directToS3=true
                [S3Folder]="s3buckPath"
                publicBucket=true
                (uploadCompleted)="importFileUploaded($event)"
                id="img" ></or-upload>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="modal-footer text-right">
        <span *ngIf="loadingImport" class="loading mx-2 mt-2"></span>
        <button type="button" class="btn nav-btn-primary shadow-none m-2" (click)="openModalRef.hide(); importFileName = null">Cancel</button>
        <button type="button" class="btn nav-btn-accent shadow-none" (click)="importQuotaFromFile()" [disabled]="!importFileName || loadingImport">Import Quota</button>
      </div>
    </ng-container>    
  </ng-template>
</div>

</div>