import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { PerformanceReportComponent } from './performance-report/performance-report.component';
import { AuditReportComponent } from './audit-report/audit-report.component';
import { ComponentsModule, HelpersModule } from 'core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CleanidReportComponent } from './cleanid-report/cleanid-report.component';
import { ReportBuilderComponent } from './builder/report-builder.component';
import { WorkloadReportComponent } from './workload-report/workload-report.component';
import { ClientReportComponent } from './client-report/client-report.component';
import { VendorReportComponent } from './vendor-report/vendor-report.component';
import { LicenseeReportComponent } from './licensee-report/licensee-report.component';
import { ResponseIdApiReportComponent } from './responseid-api-report/responseid-api-report.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    BsDropdownModule,
    FormsModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    HelpersModule
  ],
  exports: [AuditReportComponent],
  declarations: [
    PerformanceReportComponent,
    LicenseeReportComponent,
    AuditReportComponent,
    CleanidReportComponent,
    ReportBuilderComponent,
    WorkloadReportComponent,
    ClientReportComponent,
    VendorReportComponent,
    ResponseIdApiReportComponent
  ]
})
export class ReportsModule { }
