import { map } from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges, HostListener } from '@angular/core';
import { ProjectService, AlertifyService, QuotaService, ProjectQuota, QuotaGroup, QuotaQuestion, Quota, DropdownQuota, QuotaVendorLimit, AuthService } from 'core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-quota-infield-overall-card',
  templateUrl: './quota-infield-overall-card.component.html',
  styleUrls: ['./quota-infield-overall-card.component.scss']
})
export class QuotaInfieldOverallCardComponent implements OnInit, OnChanges {

  @Input() group: QuotaGroup;
  @Input() limitMode: string;
  @Input() limitType: string;
  @Input() limitCheck: string;
  @Input() newQuota: boolean;
  @Input() metrics: any;
  @Input() vendors: any;
  @Input() fullLaunchQuota: number;
  @Input() enableVendorLimits: boolean;
  @Input() questions: QuotaQuestion[];
  @Input() daysPercentagePast: number;
  @Input() isVendorview: boolean;

  @Output() clickedOnQuota = new EventEmitter();
  @Output() onChange = new EventEmitter();

  // hhi
  hhiList = [];
  hhiInterval = 5000;
  hhiMin = 15000;
  hhiMax = 150000;

  quotaGroupTotals = {};


  constructor(
    private projectService: ProjectService,
    private alertify: AlertifyService,
    public auth: AuthService) {}

  ngOnInit() {
    for (var n = this.hhiMin; n <= this.hhiMax; n += this.hhiInterval) this.hhiList.push(n);

    this.group.quotas.forEach(quota => {

      if (quota.edit != true) {
        quota.edit = false;
      }

    });
    if (this.group.name == 'Age') {
      this.sort(this.group.quotas, 'minAge')
    }
    else if (this.group.name == 'Income') {
      this.sort(this.group.quotas, 'minHHI')
    }
    else {
      this.sort(this.group.quotas, 'order')
    }

    const quotas = this.group.quotas;
    const totalN = quotas.filter(x => x.isDeleted == false && x.limitN != 99999).reduce((a, b) => a + (Number(b.limitN) || 0), 0);
    const totalPercent = quotas.filter(x => x.isDeleted == false && x.limitPercent != 99999).reduce((a, b) => a + (Number(b.limitPercent) || 0), 0);

    this.quotaGroupTotals[this.group.id] = { n: totalN, percent: totalPercent, vendorTotals: {} };
  }

  ngOnChanges(changes: SimpleChanges) {
    this.handleDeletedQuotas();
  }


  saveQuota(quota) {
    this.onChange.emit(quota)
  }

  removeQuotaGroup() {

    this.group.isDeleted = true;
    
    this.onChange.emit()
  }


  populateHHIList() {
      for (var n = this.hhiMin; n <= this.hhiMax; n += this.hhiInterval) this.hhiList.push(n);
  }


  addBlankQuota(group, type) {
    // limitN and limitPercent are only needed for UI we do not need to save these
    if (this.auth.isClient() || this.auth.isVendor() || this.isVendorview) return;

    const quota = this.blankQuota();
    quota.newQuota = true;
    quota.groupId = group.id;
    if (type =='HouseholdIncome') {
      var lastIndex = null;
      for (let i = group.quotas.filter(e => e.isDeleted == false).length-1; i >= 0; i--) {
        var maxHHI = group.quotas.filter(e => e.isDeleted == false)[i].targetGroup.maxHHI
        if (maxHHI != null) {
          lastIndex = i;
          break;
        }
      }

      if (lastIndex != null)
      {
        var lastMax = group.quotas.filter(e => e.isDeleted == false)[lastIndex].targetGroup.maxHHI
        if (lastMax < this.hhiMax -1)
        {
          quota.targetGroup.minHHI = lastMax + 1;
          quota.targetGroup.maxHHI = lastMax + 5000;
        }
        else if (lastMax == this.hhiMax - 1)
        {
          quota.targetGroup.minHHI = this.hhiMax;
          quota.targetGroup.maxHHI = this.hhiMax + 1;
        }
        else if (lastMax == this.hhiMax + 1)
        {
          quota.targetGroup.minHHI = null;
          quota.targetGroup.maxHHI = null;
        }
      }
      else
      {
        quota.targetGroup.minHHI = 0;
        quota.targetGroup.maxHHI = this.hhiMin-1;
      }
    }

    this.addVendorsToQuota(quota);
    // quota.edit = true;
    group.quotas.push(quota);
    this.clickedOnQuota.emit(quota);
    // this.handleQuotaChange();
  }


  blankQuota() {
    const quota: Quota = {
      id: uuidv4(),
      isDeleted: false,
      limit: 0,
      starts: 0,
      completes: 0,
      limitPercent: 0,
      limitN: 0,
      order:999,
      targetGroup: {
        id: uuidv4(),
        gender: null,
        ethnicity: null,
        hispanic: null,
        minHHI: null,
        maxHHI: null,
        minAge: null,
        maxAge: null,
        region: null,
        division: null,
        states: '',
      },
      vendorLimits: [],
      edit: false
    };

    return quota;
  }

  addVendorsToQuota(quota) {
    const vendorLimits = [];
    var vendorAllocationTotal = this.vendors.filter(x => !x.isDeleted).reduce((sum, item) => sum + item.partnerFullQuota, 0);

    this.vendors.forEach(vendor => {
      // For every vendor on this project we add the vendor limits if specified
      const existing = quota.vendorLimits.find(v => v.vendorId === vendor.partnerId);
      if (existing) {
        existing.limitN = (quota.limit >= 99999 ? 99999 : (this.limitType === 'number') ? existing.limit : Math.ceil(quota.limitN * existing.limit / 100));
        existing.limitPercent = (quota.limit >= 99999 ? 99999 : (quota.limit === 0) ? 0 : (this.limitType === 'percent') ? existing.limit : Math.ceil(existing.limit * 100 / quota.limitN));
        vendorLimits.push(existing);
      } else {
        var vendorRatio;
        if (this.vendors.length > 1) {
          if (!vendor.partnerFullQuota) vendorRatio = 1/this.vendors.length
          else vendorRatio = vendor.partnerFullQuota/vendorAllocationTotal
        }
        else vendorRatio = 1; // if one vendor only, give it full allocation
        const qv: QuotaVendorLimit = {
          id: uuidv4(),
          vendorId: vendor.partnerId,
          vendorName: vendor.partnerName,
          limitN: quota.limit ? (quota.limit >= 99999 ? 99999 : Math.ceil(quota.limitN * vendorRatio) ) : 0,
          limitPercent: quota.limit ? (quota.limit >= 99999 ? 99999 : vendorRatio*100) : 0,
          limit: 0,
          completes: 0,
          starts: 0,
          edit: true
        };
        if (quota.limit) {
          qv.limit = (quota.limit >= 99999 ? 99999 : (this.limitType === 'number') ? qv.limitN : qv.limitPercent);
        }
        else qv.limit = 0
        vendorLimits.push(qv);
      }
    });
    quota.vendorLimits = vendorLimits;
  }

  addBackQuota(group, quota) {
    if (quota.id == null) {

      group.deletedOptions.forEach((element,index)=>{
        if(element.label==quota.name) group.deletedOptions.splice(index,1);
     });
      const question = this.questions[group.questionId];
      const newQuota: Quota = this.blankQuota()        
      newQuota.targetGroup[group.name.toLowerCase()] = quota.name;
      newQuota.order = question.options.find(x => x.value === quota.name).order || 999;

      const defaultItem = question.defaults.find(e => e.value === quota.name);
      if (defaultItem != null) {
        newQuota.limitPercent = defaultItem.percent;
        newQuota.limitN = Math.ceil((this.fullLaunchQuota * (defaultItem.percent / 100)));
        newQuota.limit = ((this.limitType === 'number') ? newQuota.limitN : newQuota.limitPercent)
      }
      newQuota.groupId = group.id;
      this.addVendorsToQuota(newQuota);
      group.quotas.push(newQuota);
    }
    else {
      var quota = group.quotas.find(e => e.id === quota.id);
      quota.groupId = group.id;
      quota.isDeleted = false;
      group.deletedOptions.forEach((element,index)=>{
        if(element.id==quota.id) group.deletedOptions.splice(index,1);
     });
     if (group.name === 'Gender' || group.name === 'Ethnicity' || group.name === 'Hispanic') {
      quota.order = this.questions[group.questionId].options.find(x => x.value === quota.targetGroup[group.name.toLowerCase()]).order
     }
      else {
        for (let qId in this.questions) {
          var question = this.questions[qId]
          if (question.type === group.name) {
            if (group.name === 'Division' || group.name === 'Region') quota.order = question.defaults.find(x => x.value === quota.targetGroup[group.name.toLowerCase()]).order
          }
        }
      }
    }
    this.sort(group.quotas, 'order')
    var saveQuota = false;
    if (group.name === 'Income' && quota.targetGroup['minHHI'] === -1) {
      saveQuota = true;
    }
    else if (quota.targetGroup[group.name.toLowerCase()] === 'pnta') saveQuota = true;
    else if (group.name !== 'Income' && group.name !== 'Age') saveQuota = true;
    if (saveQuota) this.saveQuota(quota);
  }

 addBackPreferNotToAnswer(group) {
    var quota;
    if (group.name != 'Income') {
      quota = group.quotas.find(e => e.targetGroup[group.name.toLowerCase()] === 'pnta');
    }
    else quota = group.quotas.find(e => e.targetGroup.minHHI === -1);
     
    if (quota) {
      quota.groupId = group.id;
      quota.order = 9999999;
      group.preferNotToAnswerAdded = true;
      this.addBackQuota(group, quota);
    }

    
  }

  removeQuota(group, id) {
    group.quotas.filter(e => e.id === id).forEach(q => {
      if (group.preferNotToAnswerAdded) {
        if ((group.name == 'Income' && q.targetGroup.minHHI == -1) || 
          (group.name != 'Income' && q.targetGroup[group.name.toLowerCase()]) === 'pnta') {
            group.preferNotToAnswerAdded = false;
        }
      }
      q.isDeleted = true
      this.handleQuotaChange(q)
      this.handleDeletedQuotas();

    });
  }


  minChanged(quota, group, targetGroup) {
    quota.changesMade = true;

    if (group.name == 'Income') {
      if ((targetGroup.minHHI > targetGroup.maxHHI) || targetGroup.maxHHI == null) {
        var nextAmount = null;
        var index = 0;
        while (!nextAmount && index < this.hhiList.length) {
          var amount = this.hhiList[index];

          if (amount > targetGroup.minHHI){
            nextAmount = amount -1
          }

          index++;
        }
        targetGroup.maxHHI = nextAmount ?? this.hhiMax - 1;
      }
    }
  }
    

 


  sort(array, sortBy) {
    if (array == null) return null;
    var A;
    var B
    array.sort((a, b) => {

      if (sortBy === 'order')
      {
        A = a.order;
        B = b.order;
      }
      else if (sortBy === 'min')
      {
        A = a.min
        B = b.min
      }
      else if (sortBy === 'minAge')
      {
        A = a.targetGroup.minAge
        B = b.targetGroup.minAge
      }
      else if (sortBy === 'minHHI')
      {
        A = a.targetGroup.minHHI
        B = b.targetGroup.minHHI
        if (A == -1) A =999999999999
        if (B == -1) B =999999999999
      }

      let comparison = 0;
      if (A > B) {
        comparison = 1;
      } else if (A < B) {
        comparison = -1;
      }
      return comparison;
    });
  }


  handleQuotaChange(quota) {
    var overlapExists = false;
    const quotaGroupTotals = {};
    const vendorTotals = {n: 0, percent: 0}
    const vendorGroupTotals = {};

    const totalN = this.group.quotas.filter(x => x.isDeleted == false && x.limitN != 99999).reduce((a, b) => a + (Number(b.limitN) || 0), 0);
    const totalPercent = this.group.quotas.filter(x => x.isDeleted == false && x.limitPercent != 99999).reduce((a, b) => a + (Number(b.limitPercent) || 0), 0);

    quotaGroupTotals[this.group.id] = { n: totalN, percent: totalPercent, vendorTotals: {} };

      var vendorN;
      var vendorPercent;

      if ((this.limitType == 'number' && quota.limitN >= 99999) || ( this.limitType == 'percent' && quota.limitPercent >= 99999)) {
        quota.limitN = 99999;
        quota.limitPercent = 99999;
        vendorN = 99999;
        vendorPercent = 99999;
      }
      else {
        if (this.enableVendorLimits) {
          vendorN = quota.vendorLimits.filter(x => x.limitN != 99999).reduce((a, b) => Number(a) + (Number(b.limitN) || 0), 0);
          vendorPercent = quota.vendorLimits.filter(x => x.limitPercent != 99999).reduce((a, b) => Number(a) + (Number(b.limitPercent) || 0), 0);
        }
      }
      quotaGroupTotals[quota.id] = { n: vendorN, percent: vendorPercent };
      if (((quota.limitN < 99999 && this.limitType == 'number') ||
        (quota.limitPercent < 99999 && this.limitType == 'percent')) &&
        quotaGroupTotals[quota.id].n > quota.limitN && quota.limitN > 0 &&
        this.limitCheck !='tracking only' && this.enableVendorLimits)
      {
        overlapExists = true;
      }

        quota.vendorLimits.filter(x => x.limitN != 99999 && x.limitPercent != 99999).forEach(v => {
          if (quotaGroupTotals[v.id + '_' + this.group.id] == null) { quotaGroupTotals[v.id + '_' +  this.group.id] = 0; }
          quotaGroupTotals[v.id + '_' +  this.group.id] += v.limitN;
          if (!vendorGroupTotals[v.vendorId + "_" +  this.group.id]) {
            vendorGroupTotals[v.vendorId + "_" +  this.group.id] = {n: 0, percent: 0}
          }
          vendorGroupTotals[v.vendorId + "_" +  this.group.id].n += Number(v.limitN);
          vendorGroupTotals[v.vendorId + "_" +  this.group.id].percent += Number(v.limitPercent);

        });
    this.quotaGroupTotals = quotaGroupTotals;

  }

  
  handleDeletedQuotas() {
    // called whenever we add/remove/change quotas so we can recalculate everything
    if (this.group.isDeleted == false) {
      var totalNVendorGroup = 0;
      this.group.deletedOptions = [];
      const quotas = this.group.quotas;
      const totalN = quotas.filter(x => x.isDeleted == false && x.limitN != 99999).reduce((a, b) => a + (Number(b.limitN) || 0), 0);
      const totalPercent = quotas.filter(x => x.isDeleted == false && x.limitPercent != 99999).reduce((a, b) => a + (Number(b.limitPercent) || 0), 0);

      var questions = this.questions;

      quotas.forEach(q => {
        if (q.isDeleted && q.targetGroup[this.group.name.toLowerCase()] != 'pnta') {
          var deletedQ: DropdownQuota = {type: "", name: "", id: ""};
          deletedQ.type = this.group.name.toLowerCase()
          deletedQ.id = q.id
          if (this.group.name === 'Ethnicity') deletedQ.name = q.targetGroup.ethnicity
          else if (this.group.name === 'Gender') deletedQ.name = q.targetGroup.gender
          else if (this.group.name === 'Hispanic') deletedQ.name = q.targetGroup.hispanic
          else if (this.group.name === 'Region') deletedQ.name = q.targetGroup.region
          else if (this.group.name === 'Division') deletedQ.name = q.targetGroup.division

          this.group.deletedOptions.push(deletedQ)
        }
      });
      console.log(this.group.deletedOptions)


        if (['Ethnicity', 'Gender', 'Hispanic'].includes(this.group.name)) {
          this.questions[this.group.questionId].options.forEach(option => {
            var optionExistsInQuotas = quotas.find(q => q.targetGroup[this.group.name.toLowerCase()] === option.value);
            if (!optionExistsInQuotas) {
              var deletedQ: DropdownQuota = {type: this.group.name.toLowerCase(), name: option.value, id: null};
              deletedQ.type = this.group.name.toLowerCase()
              deletedQ.name = option.value
              this.group.deletedOptions.push(deletedQ)
            }
          });
        }
      }
    }


  handleLimitChange(quota, type, vendor, event) {
    quota.changesMade = true;
    var val = event.target.value;
    if (this.fullLaunchQuota === 0) {
      this.alertify.error("Cannot update quota limit as overall project completes allocation is 0")
      return 0; }

    if (type === '%') {
      if ( val === '*' || val === '99999') {
        quota.limitPercent = 99999;
        quota.limitN = 99999;
        quota.limit = 99999;
      }
      else {
        val = Number(val)
        if (!isNaN(val)) {

          quota.limitPercent = val
          quota.limitN = Math.ceil(this.fullLaunchQuota * (quota.limitPercent / 100));
        }
      }
    }
    else {
      if ( val[0] === '*' || val === '99999') {
        quota.limitPercent = 99999;
        quota.limitN = 99999;
      }
      else {
        val = Number(val)
        if (!isNaN(val)) {
          quota.limitN = val;
          quota.limitPercent = Math.ceil(quota.limitN * 100 / this.fullLaunchQuota);
        }
      }
    }

    // Update all vendor values for this row
    quota.vendorLimits.forEach(v => {
      if (!vendor && val[0]!=='*' && val !== '99999') {
        if (v.limitPercent === '*' || v.limitPercent === '99999' || v.limitPercent >= 99999) {
          v.limitN = 99999
        }
        else if (v.limitN === '*' || v.limitN === '*' || v.limitN >= 99999) {
          v.limitPercent = 99999
        }
        else if (this.limitType == 'number') {
          v.limitPercent = Math.ceil(( v.limitN / quota.limitN) * 100);
        }
        else if (this.limitType == 'percent') {
          v.limitN = Math.ceil(quota.limitN * v.limitPercent / 100);
        }
      }
      else if (!vendor && (val[0]==='*' || val === '99999')) {
        v.limitN = 99999;
        v.limitPercent = 99999;
      }
    });
    this.handleQuotaChange(quota);
  }
  

  getBgColor(value): string {
    if ( this.daysPercentagePast > value) {
      return 'nav-bg-error';
    }
    else {
      return 'nav-success';
    }
  }

  enableEdit(quota) {
    if (this.auth.isClient() || this.auth.isVendor() || this.isVendorview) return;
    this.clickedOnQuota.emit(quota);
  }
}
