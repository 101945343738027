import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportsService } from 'core';
import { AuthService } from 'projects/core/src/services/auth.service';

@Component({
  selector: 'app-workload-report',
  templateUrl: './workload-report.component.html',
  styleUrls: ['./workload-report.component.scss']
})
export class WorkloadReportComponent implements OnInit {
  iframeUrl = '';

  @ViewChild('myIframe') public myIframe;

  currentIdx = 0;
  dashbaordIds = ["111", "110", "109"];

  constructor(private reportService: ReportsService) { }

  ngOnInit() {

    this.loadDashboard(this.currentIdx);
   
  }
  loadDashboard(idx){
    this.currentIdx = idx;
    this.reportService.GenerateMetabaseEmbedToken(this.dashbaordIds[idx], '').subscribe( data => {
      this.iframeUrl = data;
      const iframe = this.myIframe.nativeElement;
      if (iframe != null) {
        iframe.src = this.iframeUrl;
      }
     }, error => {
 
     });
  }
}
