import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DashboardService } from 'projects/core/src/services/dashboard.service';

@Component({
  selector: 'app-widget-newsfeed',
  templateUrl: './widget-newsfeed.component.html',
  styleUrls: ['./widget-newsfeed.component.scss']
})
export class WidgetNewsFeedComponent implements OnInit {

  @Input() widget;
  source = [];
  @Output() onremove = new EventEmitter();
  @Output() onupdate = new EventEmitter();

  constructor(
    public dashboardService: DashboardService) { }

  ngOnInit() {

    this.getDataFromRSS(this.widget.settings);
  }

  getDataFromRSS(settings) {
      this.dashboardService.getDataFromRSS(settings).subscribe(data => {
      if (data.length <= 0) return;
      else
        data.forEach(article => {
          var a =
            {
              id: article.id,
              source: article.source,
              title: article.title,
              author: article.authors,
              description: article.summary,
              link: article.link,
              pubDate: new Date(article.publishDate),
              content:  article.content,
              categories: article.categories,
              language: 'en-us'
            }



            this.source.push(a);

      });
    }, error => console.log(error));
  }
  remove() {
    this.onremove.emit();
  }

  updateWidget() {
    this.onupdate.emit();
  }

  updateTitle(widgetTitle) {
    this.widget.title = widgetTitle;
    this.updateWidget();
  }
}

