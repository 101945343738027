<div class="card m-2">
  <div class="card-header">
    <div class="row align-items-center">
      <div [class]="mode == 'reduced' ? 'col' : 'col-2'">
        <h3 class="nav-secondary font-weight-bold">Quotas</h3>
      </div>
      <div class="col text-right pr-0">
        <span *ngIf="mode == 'expanded'" >view by &nbsp;</span>
        <div class="btn-group" role="group" *ngIf="quotaDetails?.quotaGroups?.length > 0">
          <button type="button" (click)="setQuotaData('start')" class="btn {{ limitMode=='start' ? 'bg-secondary text-white' : 'bg-light border' }} {{mode == 'reduced' ? 'btn-xs' : 'btn-sm' }}">
            Starts
          </button>
          <button type="button" (click)="setQuotaData('complete')" class="btn {{ limitMode=='complete' ? 'bg-secondary text-white' : 'bg-light border' }} {{mode == 'reduced' ? 'btn-xs' : 'btn-sm' }}">
            Completes
          </button>
        </div>
      </div>
      <div class="text-right pl-0" [class]="mode == 'reduced' ? 'col-3' : 'col-1'">
        <i (click)="openQuotaPage()" class="fas fa-cog fa-lg cursor-pointer"></i>
      </div>
    </div>
  </div>

  <!-- card body for when reduced mode-->
  <ng-container *ngIf="!_project.isQuotaEnabled && mode == 'reduced'">
    <div class="card-body">
      <div class="row">
        <div class="col"></div>
      </div>
      <div class="row my-2 nav-bg-primary">
        <div class="col p-3 text-center">
          <div class="row m-2">
            <div class="col">
              <i class="far fa-question-circle fa-2x or-secondary"></i>
            </div>
          </div>
          <div class="row m-2">
            <div class="col">
              <p class="font-weight-bold or-secondary">Quotas disabled.</p>
              <small class="d-block mb-3 or-secondary">
              Quotas are not enabled for this Project.
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col"></div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="_project.isQuotaEnabled && mode == 'reduced'">
    <div class="card-body scrollbar overflow-y">

      <div *ngIf="!loading && quotaDetails?.quotaGroups?.length > 0 && (currentPage == null)" class="row">
        <div class="col or-secondary text-center">
          <div class="h6">No Quota Data available.</div>
        </div>
      </div>
      <div *ngIf="quotaDetails?.quotaGroups?.length <= 0" class="row">
        <div class="col or-secondary text-center">
          <div class="h6">No Quota Questions set up.</div>
        </div>
      </div>

      <div class="mb-4" *ngFor="let group of currentPage?.groups">
        <div class="row mb-3">
          <div class="col">
            <div class="h6 or-title">{{ group.name }}</div>
          </div>
          <div class="col-3 text-center">
            <ng-container *ngIf="vendorLimitsEnabled || currentPage?.label == 'Overall'">
              <small class="font-weight-bold or-secondary opacity-70">{{ limitType | titlecase }}</small>
            </ng-container>
            <ng-container *ngIf="!vendorLimitsEnabled && currentPage?.label != 'Overall'">
              <small class="font-weight-bold or-secondary opacity-70">Percent</small>
            </ng-container>
          </div>
        </div>
        <div class="row mb-3" *ngFor="let quota of group.quotas">
          <ng-container *ngIf="quota?.label != 'not asked' || currentPage?.label == 'Overall'">
            <div class="col-4">
              <small *ngIf="quota.label.length>24" class="text-nowrap d-block" tooltip="{{quota.label}}">{{ (quota.label  | slice:0:20)+'...'}}</small>
              <small  *ngIf="quota.label.length<=24"class="text-nowrap d-block">{{quota.label}}</small>
            </div>
            <div class="col ml-3 pl-0">
              <div class="rule">
                <hr class="or-line" />
              <ng-container *ngIf="quota?.label != 'not asked'">
                <!-- enforced -->
                <ng-container *ngIf="limitCheck == 'enforced'">
                  <!-- vendors enabled -->
                  <ng-container *ngIf="vendorLimitsEnabled || currentPage?.label == 'Overall'">
                    <ng-container *ngIf="limitType == 'number'">
                      <ng-container *ngIf="limitMode == 'start' && metrics.starts > 0">
                        <or-progress-guage [value]="(quota.starts * 100) / quota.limit" [expected]="100" bgColor="bg-info" bgDanger="bg-secondary" mode="quota"></or-progress-guage>
                      </ng-container>
                      <ng-container *ngIf="limitMode == 'complete' && metrics.completes > 0">
                        <or-progress-guage [value]="(quota.completes * 100) / quota.limit" [expected]="100" bgColor="bg-info" bgDanger="bg-secondary" mode="quota"></or-progress-guage>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="limitType == 'percent'">
                      <ng-container *ngIf="limitMode == 'start' && metrics.starts > 0">
                        <or-progress-guage [value]="(quota.starts * 100) / group.starts" [expected]="quota.limit" [bgColor]="getBgColor(group, quota)" [bgDanger]="getBgColor(group, quota)" mode="quota"></or-progress-guage>
                      </ng-container>
                      <ng-container *ngIf="limitMode == 'complete' && metrics.completes > 0">
                        <or-progress-guage [value]="(quota.completes * 100) / group.completes" [expected]="quota.limit" [bgColor]="getBgColor(group, quota)" [bgDanger]="getBgColor(group, quota)" mode="quota"></or-progress-guage>
                      </ng-container>
                    </ng-container>
                  </ng-container>

                  <!-- vendors NOT enabled -->
                  <ng-container *ngIf="!vendorLimitsEnabled && currentPage?.label != 'Overall'">
                    <ng-container *ngIf="limitMode == 'start' && metrics.starts > 0">
                      <or-progress-guage [value]="(quota.starts * 100) / group.starts"  [bgColor]="getBgColor(group, quota)" [bgDanger]="getBgColor(group, quota)" mode="quota"></or-progress-guage>
                    </ng-container>
                    <ng-container *ngIf="limitMode == 'complete' && metrics.completes > 0">
                      <or-progress-guage [value]="(quota.completes * 100) / group.completes"  [bgColor]="getBgColor(group, quota)" [bgDanger]="getBgColor(group, quota)" mode="quota"></or-progress-guage>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <!-- tracking -->
                <ng-container *ngIf="limitCheck == 'tracking'">

                  <!-- vendors enabled -->
                  <ng-container *ngIf="vendorLimitsEnabled || currentPage?.label == 'Overall'">

                    <ng-container *ngIf="limitType == 'number'">
                      <ng-container *ngIf="limitMode == 'start' && metrics.starts > 0">
                        <or-progress-guage [value]="(quota.starts * 100) / quota.limit" [expected]="100" bgColor="bg-info" bgDanger="bg-info" mode="quota"></or-progress-guage>
                      </ng-container>
                      <ng-container *ngIf="limitMode == 'complete' && metrics.completes > 0">
                        <or-progress-guage [value]="(quota.completes * 100) / quota.limit" [expected]="100" bgColor="bg-info" bgDanger="bg-info" mode="quota"></or-progress-guage>
                      </ng-container>
                    </ng-container>
    
                    <ng-container *ngIf="limitType == 'percent'">
                      <ng-container *ngIf="limitMode == 'start' && metrics.starts > 0">
                        <or-progress-guage [value]="(quota.starts * 100) / group.starts" [expected]="quota.limit" [bgColor]="getBgColor(group, quota)" [bgDanger]="getBgColor(group, quota)" mode="quota"></or-progress-guage>
                      </ng-container>
                      <ng-container *ngIf="limitMode == 'complete' && metrics.completes > 0">
                        <or-progress-guage [value]="(quota.completes * 100) / group.completes" [expected]="quota.limit" [bgColor]="getBgColor(group, quota)" [bgDanger]="getBgColor(group, quota)" mode="quota"></or-progress-guage>
                      </ng-container>
                    </ng-container>
                  </ng-container>

                  <!-- vendors NOT enabled -->
                  <ng-container *ngIf="!vendorLimitsEnabled && currentPage?.label != 'Overall'">
                    <ng-container *ngIf="limitMode == 'start' && metrics.starts > 0">
                      <or-progress-guage [value]="(quota.starts * 100) / group.starts"  [bgColor]="getBgColor(group, quota)" [bgDanger]="getBgColor(group, quota)" mode="quota"></or-progress-guage>
                    </ng-container>
                    <ng-container *ngIf="limitMode == 'complete' && metrics.completes > 0">
                      <or-progress-guage [value]="(quota.completes * 100) / group.completes"  [bgColor]="getBgColor(group, quota)" [bgDanger]="getBgColor(group, quota)" mode="quota"></or-progress-guage>
                    </ng-container>
                  </ng-container>

                </ng-container>
              </ng-container>
  
                <!-- tracking only -->
                <ng-container *ngIf="limitCheck == 'tracking only' || quota?.label == 'not asked'">
                  <ng-container *ngIf="limitMode == 'start' && metrics.starts > 0">
                    <or-progress-guage [value]="(quota.starts * 100) / group.starts"  [bgColor]="getBgColor(group, quota)" [bgDanger]="getBgColor(group, quota)" mode="quota"></or-progress-guage>
                  </ng-container>
                  <ng-container *ngIf="limitMode == 'complete' && metrics.completes > 0">
                    <or-progress-guage [value]="(quota.completes * 100) / group.completes"  [bgColor]="getBgColor(group, quota)" [bgDanger]="getBgColor(group, quota)" mode="quota"></or-progress-guage>
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <div class="col-3 px-0 text-center">
              <small class="d-block or-secondary">
                <ng-container *ngIf="quota?.label != 'not asked'">
                  <!-- enforced -->
                  <ng-container *ngIf="limitCheck == 'enforced'">
                    <!-- vendors enabled -->
                    <ng-container *ngIf="vendorLimitsEnabled || currentPage?.label == 'Overall'">
                      <ng-container *ngIf="limitType == 'number'">
                        <ng-container *ngIf="limitMode == 'start' && metrics.starts > 0">
                          <strong>{{ quota.starts }}</strong> / {{ quota.limit < 99999 ? quota.limit : '*' }}
                        </ng-container>
                        <ng-container *ngIf="limitMode == 'complete' && metrics.starts > 0">
                          <strong>{{ quota.completes }}</strong> / {{ quota.limit < 99999 ? quota.limit : '*'}}
                        </ng-container>
                      </ng-container>
      
                      <ng-container *ngIf="limitType == 'percent'">
                        <ng-container *ngIf="limitMode == 'start' && metrics.starts > 0">
                          <strong>{{ quota.starts / group.starts | percent }}</strong> / {{ quota.limit < 99999 ? quota.limit + '%': '*'}}
                        </ng-container>
                        <ng-container *ngIf="limitMode == 'complete' && metrics.starts > 0">
                          <strong>{{ quota.completes / group.completes | percent }}</strong> / {{ quota.limit < 99999 ? quota.limit + '%' : '*'}}
                        </ng-container>
                      </ng-container>  
                    </ng-container>
    
                    <!-- vendors NOT enabled -->
                    <ng-container *ngIf="!vendorLimitsEnabled && currentPage?.label != 'Overall'">
                      <ng-container *ngIf="limitMode == 'start' && metrics.starts > 0">
                        <strong class="ml-2">{{ (quota.starts  / group.starts || 0) | percent }}</strong>
                      </ng-container>
                      <ng-container *ngIf="limitMode == 'complete' && metrics.completes > 0">
                        <strong  class="ml-2">{{ (quota.completes / group.completes || 0) | percent }}</strong>
                      </ng-container>
                    </ng-container>
                    
                  </ng-container>
    
                  <!-- tracking -->
                  <ng-container *ngIf="limitCheck == 'tracking'">
                     <!-- vendors enabled -->
                     <ng-container *ngIf="vendorLimitsEnabled || currentPage?.label == 'Overall'">
                      <ng-container *ngIf="limitType == 'number'">
                        <ng-container *ngIf="limitMode == 'start' && metrics.starts > 0">
                          <strong>{{ quota.starts }}</strong> / {{ quota.limit < 99999 ? quota.limit : '*'}}
                        </ng-container>
                        <ng-container *ngIf="limitMode == 'complete' && metrics.completes > 0">
                          <strong>{{ quota.completes }}</strong> / {{ quota.limit < 99999 ? quota.limit : '*'}}
                        </ng-container>
                      </ng-container>
                    

                      <ng-container *ngIf="limitType == 'percent'">
                        <ng-container *ngIf="limitMode == 'start' && metrics.starts > 0">
                          <strong>{{ quota.starts / group.starts | percent }}</strong> / {{ quota.limit < 99999 ? quota.limit + '%' : '*'}}
                        </ng-container>
                        <ng-container *ngIf="limitMode == 'complete' && metrics.completes > 0">
                          <strong>{{ quota.completes / group.completes | percent }}</strong> / {{ quota.limit < 99999 ? quota.limit + '%': '*'}}
                        </ng-container>
                      </ng-container>
                     </ng-container>
                     <!-- vendors NOT enabled -->
                    <ng-container *ngIf="!vendorLimitsEnabled && currentPage?.label != 'Overall'">
                      <ng-container *ngIf="limitMode == 'start' && metrics.starts > 0">
                        <strong class="ml-2">{{ (quota.starts  / group.starts || 0) | percent }}</strong>
                      </ng-container>
                      <ng-container *ngIf="limitMode == 'complete' && metrics.completes > 0">
                        <strong  class="ml-2">{{ (quota.completes / group.completes || 0) | percent }}</strong>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="limitCheck == 'tracking only' || quota?.label == 'not asked'">
                  <ng-container *ngIf="limitMode == 'start' && metrics.starts > 0">
                    <strong class="ml-2">{{ (quota.starts  / group.starts || 0) | percent }}</strong>
                  </ng-container>
                  <ng-container *ngIf="limitMode == 'complete' && metrics.completes > 0">
                    <strong  class="ml-2">{{ (quota.completes / group.completes || 0) | percent }}</strong>
                  </ng-container>
                </ng-container>
  
  
              </small>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="card-footer py-2 or-title" *ngIf="quotaDetails?.quotaGroups?.length > 0">
    <div class="row d-flex" *ngIf="_project.isQuotaEnabled && mode == 'reduced'">
      <div class="col-3 text-right">
        <i (click)="changePage('-')" class="fas fa-arrow-left fa-lg cursor-pointer or-title opacity-90"></i>
      </div>
      <div class="col">
        <div class="text-center">
          <span class="h6 or-title">{{currentPage?.label}}</span>
        </div>
      </div>
      <div class="col-3 text-left">
        <i (click)="changePage('+')" class="fas fa-arrow-right fa-lg cursor-pointer or-title opacity-90"></i>
      </div>
    </div>
  </div>

</div>
