import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AlertifyService } from 'core';
import { DashboardService } from 'projects/core/src/services/dashboard.service';

@Component({
  selector: 'app-widget-title',
  templateUrl: './widget-title.component.html',
  styleUrls: ['./widget-title.component.scss']
})
export class WidgetTitleComponent implements OnInit {

  @Input()  public set widget(data) {
    this._widget = data;
    this.title = data?.title;
  }

  @Output() remove = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() openConfig = new EventEmitter();

  title;
  editEnabled = null;
  _widget = null;

  constructor(public dashboardService: DashboardService,
    private alertify: AlertifyService,) { }


  ngOnInit() {

  }

  cancel() {
    this.editEnabled = null;
  }

  save() {
    if (this.editEnabled == null || this.editEnabled == '') {
      this.alertify.error('Please add a title');
      return;
    }

    this.updateTitle();
    this.editEnabled = null;
  }

  updateTitle() {
    const newValue = this.editEnabled;
    this.title = newValue;
    this._widget.title = newValue;
    this.update.emit(this._widget.title);
    // this.dashboardService.updateTitle(this.widget.id, newValue).subscribe(data => {
    //   this.alertify.success('Widget title updated successfully');
    //   this.widget.title = newValue;
    //   this.emit()
    // }, error => {
    //   this.alertify.error('There was an issue updating the widget title');
    // });
  }

  enableEdit() {
    this.editEnabled = this._widget.title;
  }

  config() {
    this.openConfig.emit()
  }

  removed() {
    this.remove.emit();
  }

}
