import { SpinnerButtonComponent } from 'projects/core/src/components/spinner-button/spinner-button.component';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter, TemplateRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AlertifyService, ReconciliationService } from 'core';
import { ExportFilesService } from 'projects/core/src/services/exportFiles.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-project-reconciliation',
  templateUrl: './project-reconciliation.component.html',
  styleUrls: ['./project-reconciliation.component.scss']
})
export class ProjectReconciliationComponent implements OnInit {

  @Input() project: any;
  @Output() onclose = new EventEmitter();
 
  reconciliation: any = [];
  summary: any;
  filterColumns = ['name'];
  filterText = '';
  rowActions = [{label: 'Errors', type: 'open', description: 'Click to export all erros', icon: 'download'}];

  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;

  @ViewChild('spinner')
  spinner: SpinnerButtonComponent;

  openModalRef: any;

  layout = [
    { label: 'Date', id: 'lastUpdated', type: 'datetime' },
    { label: 'User', id: 'userName' },
    { label: 'Type', id: 'reconciliationType' },
    { label: '', id: 'health', type: 'alert' },
    { label: 'File', id: 'fileName', sortable: true },
    { label: 'Records', id: 'total', sortable: true },
    { label: 'Matched', id: 'matched', sortable: true },
    { label: 'Completes', id: 'successfulCompletes' },
    { label: 'QC', id: 'successfulQC' },
    { label: 'Errors', id: 'failed', sortable: true }
  ];

  constructor(
    private reconciliationService: ReconciliationService,
    private alertify: AlertifyService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
     this.reconciliationService.GetReconciliationFiles(this.project.id).subscribe(data => {
        const offset = new Date().getTimezoneOffset();

        var rollbackItem;

        data.forEach(e => {
          const d = new Date(e.lastUpdated);
          e.lastUpdated = d.setMinutes(d.getMinutes() - offset);

          e.health_icons = [];

          if(e.reconciliationType == "Rollback")
            rollbackItem = e;

          if (rollbackItem && e.reconciliationType !== "Rollback") {
            e.health_icons.push({icon: 'fas fa-exclamation-triangle text-warning', message: `Rolled back by ${rollbackItem.userName} on ${ new DatePipe('en-US').transform(rollbackItem.lastUpdated, 'MMM d, y HH:mm')}`});
          }
        });

        this.reconciliation = data;

      },
      error => {
        this.alertify.error('Unable to get reconciliation files');
      }
    );

    this.reconciliationService.GetSummary(this.project.id).subscribe(
      data => {
        this.summary = data;
      },
      error => {
        this.alertify.error('Unable to get reconciliation summary');
      }
    );
  }

  public openModal(template: any) {
    this.openModalRef = this.modalService.show(template, {ignoreBackdropClick: true, keyboard: false });
  }

  // recUpload(files) {
  //   this.spinner.showSpinner = true;
  //   const projectId = this.project.id;
  //   // const formData = this.reconciliationService.uploadLink(files, projectId, '');
  //   this.reconciliationService.setReconciliaton(formData).subscribe(() => {
  //     this.spinner.showSpinner = false;
  //     this.loadData();
  //     this.alertify.success('Reconciliation Uploaded');
  //   }, error => {
  //     this.alertify.error('File error ' + error);
  //     this.spinner.showSpinner = false;
  //   });
  // }

  confirmQC() {
    const projectId = this.project.id;
    this.reconciliationService.UploadQC(this.project.id, this.summary.fileId).subscribe(
      data => {
        this.summary = data;
      },
      error => {
        this.alertify.error('Unable to get reconciliation summary');
      }
    );
    this.openModalRef.hide();
  }

  rollbackAll() {
    this.reconciliationService.RollbackAll(this.project.id).subscribe(
      data => {
        this.alertify.success('Project Rolled Back succesfully');
      },
      error => {
        this.alertify.error('Unable to rollback for this project');
      },() => {
        this.loadData();
        this.openModalRef.hide();
      }
    );
  }

  rollbackAll2() {
    this.reconciliationService.RollbackAll(this.project.id).subscribe(
      data => {
        this.alertify.success('Project Rolled Back succesfully');
      },
      error => {
        this.alertify.error('Unable to rollback for this project');
      }, () => {
        this.loadData();
        this.openModalRef.hide();
      }
    )
  }

  closePage() {
    this.onclose.emit(null);
  }


 }
