<h3 class="font-weight-bold nav-font18 grey5 px-4">
  <i class="fak fa-my-tasks grey4 mr-2 font-weight-normal"></i>Tasks
</h3>

@if (_bidNumber != '') {
  <div class="col d-flex justify-content-end px-4 my-2">
    <span class="mx-3">
      @if (!this.auth.isVendor()) {
        <or-filter [values]="filterValues" position="left" positionDistance="140" (filter)="applyFilterSummary($event)"></or-filter>
      }
    </span>
    @if (!this.auth.isVendor()) {
      <or-sortby [values]="sortByValues" (sorting)="applySorting($event)"></or-sortby>
    }
  </div>
}

<div class="mt-3 tasks scrollbar overflow-y px-3 w-100">

  @if (sortBy === 'date') {
    @if (tasks.today?.length > 0) {
      <span class="grey4 nav-font14">Today</span>
      @for (task of tasks?.today; track task) {
        <div class="item my-2 p-2" [style]="'border-left: 8px solid '+ task.stageColor+'; padding-left: 10px;'">
          <div class="d-flex align-items-center justify-content-center">
            <h5 class="grey5 font-weight-bold mb-1 text-nowrap">{{ task.taskName }}</h5>
            <div class="or-checkbox d-flex justify-content-end px-3">
              <span class="font-weight-normal nav-font12 text-nowrap mr-3 mt-2 pt-1" [ngStyle]="{'color': (dateOnly(task.dueDate) < dateOnly(today) ? '#D02325' : 'grey2') }">{{ task.dueDate | date:"d MMM" }}</span>
              <span tooltip="Mark as complete" container="body">
                <input type="checkbox" [id]="'task_'+task.id" (change)="CompleteTask(task.id)">
                <label [for]="'task_'+task.id"></label>
              </span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <span class="grey4 font-weight-normal nav-font14 d-block w-100">{{ task.stageName }}</span>
            </div>
            <div class="col text-right">
              <span class="grey4 font-weight-normal nav-font14 d-block w-100">{{ task.projectCode }}</span>
            </div>
          </div>
        </div>
      }
      <hr class="border-0 my-4">
    }
    @if (tasks.upcoming?.length > 0) {
      <span class="grey4 nav-font14">Upcoming</span>
      @for (task of tasks?.upcoming; track task) {
        <div class="item my-2 p-2" [style]="'border-left: 8px solid '+ task.stageColor+'; padding-left: 10px;'">
          <div class="d-flex align-items-center justify-content-center">
            <h5 class="grey5 font-weight-bold mb-1 text-nowrap">{{ task.taskName }}</h5>
            <div class="or-checkbox d-flex justify-content-end px-3">
              <span class="font-weight-normal nav-font12 text-nowrap mr-3 mt-2 pt-1" [ngStyle]="{'color': (dateOnly(task.dueDate) < dateOnly(today) ? '#D02325' : 'grey2') }">{{ task.dueDate | date:"d MMM" }}</span>
              <span tooltip="Mark as complete" container="body">
                <input type="checkbox" [id]="'task_'+task.id" (change)="CompleteTask(task.id)">
                <label [for]="'task_'+task.id"></label>
              </span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <span class="grey4 font-weight-normal nav-font14 d-block w-100">{{ task.stageName }}</span>
            </div>
            <div class="col text-right">
              <span class="grey4 font-weight-normal nav-font14 d-block w-100">{{ task.projectCode }}</span>
            </div>
          </div>
        </div>
      }
    }
  }

  @if (sortBy === 'project') {
    @if (tasks.projects?.length > 0) {
      @for (project of tasks?.projects; track project; let i = $index) {
        <span class="grey4 nav-font14 mb-3 d-block font-weight-bold" [class.mt-5]="i > 0">{{project.projectCode}}</span>
        @if (project.today?.length > 0) {
          <span class="grey4 nav-font14 d-block">Today</span>
          @for (task of project.today; track task) {
            <div class="item my-2 p-2" [style]="'border-left: 8px solid '+ task.stageColor+'; padding-left: 10px;'">
              <div class="d-flex align-items-center justify-content-center">
                <h5 class="grey5 font-weight-bold mb-1 text-nowrap">{{ task.taskName }}</h5>
                <div class="or-checkbox d-flex justify-content-end px-3">
                  <span class="font-weight-normal nav-font12 text-nowrap mr-3 mt-2 pt-1" [ngStyle]="{'color': (task.dueDate < today ? '#D02325' : 'grey2') }">{{ task.dueDate | date:"d MMM" }}</span>
                  <span tooltip="Mark as complete" container="body">
                    <input type="checkbox" [id]="'task_'+task.id" (change)="CompleteTask(task.id)">
                    <label [for]="'task_'+task.id"></label>
                  </span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col">
                  <span class="grey4 font-weight-normal nav-font14 d-block w-100">{{ task.stageName }}</span>
                </div>
                <div class="col text-right">
                  <span class="grey4 font-weight-normal nav-font14 d-block w-100">{{ task.projectCode }}</span>
                </div>
              </div>
            </div>
          }
          <hr class="border-0 my-4">
        }
        @if (project.upcoming?.length > 0) {
          <span class="grey4 nav-font14 d-block">Upcoming</span>
          @for (task of project.upcoming; track task) {
            <div class="item my-2 p-2" [style]="'border-left: 8px solid '+ task.stageColor+'; padding-left: 10px;'">
              <div class="d-flex align-items-center justify-content-center">
                <h5 class="grey5 font-weight-bold mb-1 text-nowrap">{{ task.taskName }}</h5>
                <div class="or-checkbox d-flex justify-content-end px-3">
                  <span class="font-weight-normal nav-font12 text-nowrap mr-3 mt-2 pt-1" [ngStyle]="{'color': (task.dueDate < today ? '#D02325' : 'grey2') }">{{ task.dueDate | date:"d MMM" }}</span>
                  <span tooltip="Mark as complete" container="body">
                    <input type="checkbox" [id]="'task_'+task.id" (change)="CompleteTask(task.id)">
                    <label [for]="'task_'+task.id"></label>
                  </span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col">
                  <span class="grey4 font-weight-normal nav-font14 d-block w-100">{{ task.stageName }}</span>
                </div>
                <div class="col text-right">
                  <span class="grey4 font-weight-normal nav-font14 d-block w-100">{{ task.projectCode }}</span>
                </div>
              </div>
            </div>
          }
        }
      }
    }
  }

</div>

<!-- Add Task Box -->
<div class="bottom-menu fixed-bottom position-absolute">
  <div class="nav nav-pills flex-column mb-auto">
    <div class="d-flex align-items-center px-3 py-2">
      <span class="grey4 nav-font14">({{taskCount}}) {{ taskCount == 1 ? 'Task' : 'Tasks' }}</span>
    </div>
  </div>
</div>
