import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { UploaderService } from 'projects/core/src/services/uploader.service';
import { HttpEventType } from '@angular/common/http';
import { AlertifyService } from 'core';
import { FileUpload } from 'projects/core/src/models/fileUpload';
import { SpinnerButtonComponent } from 'projects/core/src/components/spinner-button/spinner-button.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-project-card-documents',
  templateUrl: './project-card-documents.component.html',
  styleUrls: ['./project-card-documents.component.scss']
})
export class ProjectCardDocumentsComponent implements OnInit {

  @Input() public set project(data) {
    this._project = data;
    this.getFiles();
  }

  @Input() directToS3 = false;
  @ViewChild('fileInput') myFileInput: ElementRef;
  @Output() uploadCompleted = new EventEmitter();

  @ViewChild('modalConfirm')
  modalRefConfirm: BsModalRef;

  @ViewChild('spinner')
  spinner: SpinnerButtonComponent;

  _project;
  link: any;
  files: any[] = [];
  presignedUrl = '';
  downloadUrl = '';
  onChange: (url) => void;
  file: File | null = null;
  acceptTypes = '';
  fileExtension = '';
  uploading = false;
  uploadComplete = false;
  uploadProgress = 0;
  fileUpload: FileUpload = {filename: null, url: null};
  FileSaver = require('file-saver');

  openModalRef: any;

  constructor(private uploader: UploaderService, private alertify: AlertifyService, private modalService: BsModalService) { }

  ngOnInit() {
  }

  uploadFile(event) {
    this.spinner.showSpinner = true;
    this.file = event.target.files[0] as File;
    this.fileExtension = '.' + this.file.name.split('.').pop();

    this.uploader.generatePresignedUrl(this.fileExtension, 'asset', false).subscribe(response => {
      this.presignedUrl = response.uploadUrl;
      this.downloadUrl = response.url;
      this.uploadToS3();
      this.addFile();
    });
  }

  uploadToS3() {
    this.uploader.uploadFileToS3(this.file, this.presignedUrl).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        this.uploadProgress = Math.round(event.loaded / event.total) * 100;

      } else if (event.type === HttpEventType.Response) {

        this.uploading = false;
        this.uploadComplete = true;
      }
    }, error => {
        this.uploading = false;
        this.alertify.error('Failed to upload file');
    }, () => {
      this.uploadCompleted.emit('Complete');
      this.myFileInput.nativeElement.value = '';
      this.alertify.success('File uploaded');
      this.getFiles();
      this.spinner.showSpinner = false;
    });
  }

  addFile() {
    this.fileUpload.url = this.downloadUrl;
    this.fileUpload.filename = this.file.name;
    this.uploader.addFile(this._project.id, this.fileUpload).subscribe(response => {
    });
  }

  getFiles() {
    this.uploader.downloadListOfFiles(this._project.id).subscribe(data => {
      this.files = data;
    });
  }

  downloadFile(file) {
    const fileName = file.fileName;

    this.uploader.getDownloadUrl(file.id, fileName).subscribe(data => {
      this.FileSaver.saveAs(data, fileName);
    });
  }

  public openModal(template: any, link) {
    this.link = link;
    this.openModalRef = this.modalService.show(template, {ignoreBackdropClick: true, keyboard: false, class: 'modal-lg' });
  }

  confirmDelete() {
    this.uploader.deleteFile(this.link.id).subscribe(data => {
      this.getFiles();
      this.alertify.success('File Deleted');
    }, error => {
      this.alertify.error('Failed to delete file');
  });
    this.openModalRef.hide();
  }
}
