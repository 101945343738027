<nav id="navbar" class="navbar navbar-expand-lg fixed-top pl-2  overflow-x scrollbar" [class.project-open]="(_project?.id !== null || _projectFamily?.familyCode !== '') && projectSideBarOpen" [class.sidebar-open]="sidebarOpen">

  <div class="pr-4 collapse d-flex navbar-collapse" style="min-width: 1000px;">

    <!-- BREADCRUMBS -->
    <div class="breadcrumb mr-auto bg-transparent">
      <div class="d-flex align-items-center">
        <div class="grey4 d-flex align-items-center">
          <div [routerLink]="[breadcrumb.url]">
            <i class="{{breadcrumb.icon}} grey4 fa-lg"></i>
            <span class="nav-font-16 mx-2 cursor-pointer">{{breadcrumb.label}}</span>
          </div>

          <ng-container *ngIf="_projectFamily?.familyCode || _project?.shortCode || breadcrumb?.childs?.length > 0">

            <i class="fak fa-right-arrow grey4 fa-xs mt-1" *ngIf="_projectFamily?.familyCode || _project?.shortCode"></i>
            <ng-container *ngIf="breadcrumb?.childs?.length > 0">
              <ng-container *ngFor="let child of breadcrumb?.childs">
                <i class="fak fa-right-arrow grey4 fa-xs mt-1" ></i>
                <span  class="nav-font-16 font-weight-bold nav-secondary mx-2">{{child.label}}</span>
              </ng-container>
            </ng-container>

          </ng-container>

        </div>
        <div class="nav-font-16 mx-2">
          <ng-container *ngIf="isInternal && ((!loading && _project?.id) || (!loading && _projectFamily?.familyCode)) || isClient">
            <span *ngIf="!_projectFamily?.familyCode && _project?.shortCode" class="font-weight-bold grey4 text-uppercase cursor-pointer" (click)="openProjectFamily(_project?.bidNumber)">{{_project?.shortCode}}</span>
            <span *ngIf="_projectFamily?.familyCode && !_project?.shortCode" class="font-weight-bold nav-secondary text-uppercase cursor-pointer" (click)="openProjectFamily(_projectFamily?.bidNumber)">{{_projectFamily?.familyCode}}</span>
            <i *ngIf="_project?.id" class="fak fa-right-arrow grey4 fa-xs mx-2"></i>
          </ng-container>
          <ng-container *ngIf="!loading && _project?.id">
            <!-- dropdown related projects -->
            <div class="d-inline cursor-pointer position-relative" dropdown #dropdown="bs-dropdown" container="body">
              <span class="loading" *ngIf="loading"></span>
              <ng-container *ngIf="!loading">
                <div class="cursor-pointer d-inline" (click)="dropdown.toggle(true);">
                  <span class="font-weight-bold nav-secondary mr-2">{{ (isInternal)?  selectedProject?.code : _project?.projectCode}}</span>
                  <span tooltip="{{selectedProject?.projectName}}" container="body" class="font-weight-bold nav-secondary">{{selectedProject?.projectNameDisplay}}</span>
                </div>
                <ul *dropdownMenu class="dropdown-related-projects mx-2 position-absolute py-0" role="menu" (mouseleave)="dropdown.hide();" [class.open]="dropdown.isOpen">
                  <div class="scrollbar overflow-y h-75">
                    <div class="dropdown-item cursor-pointer border-bottom px-3" *ngFor="let related of relatedProjects"
                      (click)="dropdown.hide(); openRelated(related)">
                      <span class="font-weight-bold grey4 mr-2">{{related?.code}}</span>
                      <span class="font-weight-normal grey4">{{related?.projectNameDisplay}}</span>
                    </div>
                  </div>
                  <div class="dropdown-item cursor-pointer px-3" *ngIf="isInternal" (click)="dropdown.hide(); addProjectToFamily(_project?.shortCode, _project?.bidNumber)">
                    <span class="font-weight-bold nav-accent-blue">
                      <i class="fas fa-plus fa-md nav-accent-blue mr-2"></i>Add project
                    </span>
                  </div>
                </ul>
                <i class="fak fa-down-arrow grey4 fa-xs mx-2" (click)="dropdown.toggle(true);" [class.open]="dropdown.isOpen"></i>
              </ng-container>
            </div>
          </ng-container>

        </div>
      </div>
      <div class="position-absolute client-name">
        <ng-container *ngIf="(_project && isInternal || _project && isClient) && !isVendorview">
          <a *ngIf="_project?.clientName" class="nav-font-16 nav-accent-blue font-weight-bold mr-2 cursor-pointer" href="#" [routerLink]="['/clients', _project?.clientId]">{{_project?.clientName}}</a>
          <span *ngIf="_project?.clientPo" class="nav-font-16 grey4">({{_project?.clientPo}})</span>
        </ng-container>
        <ng-container *ngIf="_projectFamily">
          <a *ngIf="_projectFamily?.familyClient" class="nav-font-16 nav-accent-blue font-weight-bold mr-2 cursor-pointer" href="#" [routerLink]="['/clients', _projectFamily?.familyClientId]">{{_projectFamily?.familyClient}}</a>
          <span *ngIf="_projectFamily?.familyClientPo" class="nav-font-16 grey4">({{_projectFamily?.familyClientPo}})</span>
        </ng-container>
      </div>
    </div>

    <!-- GLOBAL SEARCH -->
    <div class="ml-auto mr-2" *ngIf="isProjectEnable">
      <or-global-search [labelField]="'projectName'" [dataCallback]="globalSearch" (valueChange)="selectedSearch($event)"></or-global-search>
    </div>

    <!-- NOTIFICATIONS - for now we only show if projects enabled-->
    <ng-container *ngIf="auth.getUser().access.projectEnable">
      <or-notifications></or-notifications>
    </ng-container>

    <ul class="navbar-nav" *ngIf="!isVendorview && !isClientview"  tooltip="Click here to create a new project!" placement="left">
      <li *ngIf="isProjectEnable && isInternal" class="d-flex align-items-center justify-content-center start-project position-relative">
        <div class="d-flex align-items-center justify-content-center cursor-pointer shadow-lg" (click)="createProject()">
          <i class="fa fa-plus text-white fa-md"></i>
        </div>
      </li>
    </ul>

  </div>
</nav>

<!-- Modal New Project-->
<ng-template #modal>
  <ng-container *ngIf="step == 1 && isInternal">
    <div class="modal-header d-flex align-items-center justify-content-center">
      <div class="header-grid w-100 position-relative">
        <div class="d-flex justify-content-center align-content-center mt-3">
          <h2 class="nav-secondary mb-0 font-weight-bold">Create a project</h2>
        </div>
        <div class="d-flex justify-content-end align-content-center position-absolute w-100 sticky-top">
          <i class="fas fa-close grey4" (click)="closeModal()"></i>
        </div>
      </div>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col mx-4">
          <div class="row mb-3 mt-4 nav-font16 font-weight-bold grey5">
            Step 1: Select your project type
          </div>
          <div class="row mb-5" [ngClass]="{'mt-2': projectTypes?.length <= 2, 'mt-3': projectTypes?.length > 2}">
            <div class="col px-0" [ngClass]="{'d-flex' : projectTypes?.length <= 2, 'projectTypes scrollbar overflow-y py-3': projectTypes?.length > 3}">

              <!-- Less than 3 project types -->
              <ng-container *ngIf="projectTypes?.length <= 2">
                <ng-container *ngFor="let type of projectTypes; let idx = index">
                  <div *ngIf="idx === 0" class="nav-border1 mr-4 nav-rounded d-flex align-items-center justify-content-center cursor-pointer" style="height: 237px; width: 232px"
                    [class.active-option]="type?.selected" (click)="selectProjectType(type, 'quick');">
                    <div class="col-12">
                      <div class="text-center">
                        <img class="" src="/assets/imgs/nav-quantitative.png" />
                      </div>
                      <div>
                        <div class="col mt-3 px-0">
                          <span class="d-block nav-font16 font-weight-bold mb-1" [ngClass]="type?.selected ? 'grey6' : 'grey5'">{{ type.name }}</span>
                          <span class="d-block nav-font14" [ngClass]="type?.selected ? 'grey5' : 'grey4'">{{ type.description }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="idx === 1" class="nav-border1 mr-4 nav-rounded d-flex align-items-center justify-content-center cursor-pointer" style="height: 237px; width: 232px"
                    [class.active-option]="type?.selected" (click)="selectProjectType(type, 'quick')">
                    <div class="col-12">
                      <div class="text-center">
                        <img class="" src="/assets/imgs/nav-qualitative.png" />
                      </div>
                      <div>
                        <div class="col mt-3 px-0">
                          <span class="d-block nav-font16 font-weight-bold mb-1" [ngClass]="type?.selected ? 'grey6' : 'grey5'">{{ type.name }}</span>
                          <span class="d-block nav-font14" [ngClass]="type?.selected ? 'grey5' : 'grey4'">{{ type.description }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div #cloneOption class="nav-border1 mr-4 nav-rounded d-flex align-items-center justify-content-center cursor-pointer" style="height: 237px; width: 232px"
                    [class.active-option]="createType =='clone'" (click)="selectProjectType(null, 'clone')" (ngModuleChange)="cloneOption.blur()">
                    <div class="col-12">
                      <div class="text-center">
                        <img class="" src="/assets/imgs/nav-screenshot.png" />
                      </div>
                      <div>
                        <div class="col mt-3 px-0">
                          <span class="d-block nav-font16 font-weight-bold mb-1" [ngClass]="createType =='clone' ? 'grey6' : 'grey5'">Clone an existing project</span>
                          <span class="d-block nav-font14" [ngClass]="createType =='clone' ? 'grey5' : 'grey4'">Save time by cloning a pre-existing project. You can modify the settings after cloning.</span>
                        </div>
                      </div>
                    </div>
                  </div>
              </ng-container>

              <!-- More than 3 project types -->
              <ng-container *ngIf="projectTypes?.length > 2">
                <ng-container *ngFor="let type of projectTypes; let idx = index">
                  <div class="row mb-3 mx-2">
                    <div class="col d-flex">
                      <div class="mr-3">
                        <input type="radio" name="projectType" (change)="selectProjectType(type, 'quick')">
                      </div>
                      <div>
                        <span class="d-block nav-font16 grey6" style="font-weight: 500;">{{ type.name }}</span>
                        <span class="d-block nav-font14 grey4">{{ type.description }}</span>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="row mx-2">
                  <div class="col d-flex">
                    <div class="mr-3">
                      <input type="radio" name="projectType"  (change)="selectProjectType(null, 'clone')">
                    </div>
                    <div>
                      <span class="d-block nav-font16 grey6">Clone an existing project</span>
                      <span class="d-block nav-font14 grey4">Save time by cloning a pre-existing project. You can modify the settings after cloning.</span>
                    </div>
                  </div>
                </div>
              </ng-container>

            </div>
          </div>
          <ng-container *ngIf="createType=='quick'">
            <div class="row mb-3 nav-font16 font-weight-bold grey5">
              Step 2: Enter your project's details
            </div>
            <div class="row mb-3">
              <div class="col-12 col-md-2 px-0">
                <label class="required nav-font14 grey4 mt-2">Project Name</label>
              </div>
              <div class="col-12 col-md-10 d-flex align-content-center">
                <input
                  [(ngModel)]="_newProject.projectName"
                  (input)="formChanged($event)"
                  class="form-control form-control-sm form-control-nav"
                  name="projectName"
                  placeholder="Type project name"
                  required>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12 col-md-2 px-0">
                <label class="nav-font14 grey4 mt-2">Project Number</label>
              </div>
              <div class="col-12 col-md-10 d-flex align-content-center">
                <input
                  [(ngModel)]="_newProject.bidNumber"
                  (input)="formChanged($event)"
                  class="form-control form-control-sm form-control-nav"
                  name="bidNumber"
                  placeholder="Assign a project number">
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12 col-md-2 px-0">
                <label class="nav-font14 grey4 mt-2" [class.required]="isORInstance">Client</label>
              </div>
              <div class="col-12 col-md-10 d-flex">
                <or-type-ahead [labelField]="'name'" [valueField]="'value'" placeholder="Type to search client" dropdownMode=true
                  class="form-control-sm form-control-nav w-100 px-0" [dataCallback]="getClients" (valueChange)="assignClient($event)">
                </or-type-ahead>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="createType=='clone'">
            <div class="row mb-3 nav-font16 font-weight-bold grey5">
              Step 2: Select an existing project to clone
            </div>
            <div class="row mb-3">
              <div class="col d-flex align-content-center px-0">
                <div class="input-group copy-search">
                  <div class="input-group-prepend">
                    <div class="input-group-text border-right-0">
                      <i class="fas fa-search grey4 cursor-default"></i>
                    </div>
                  </div>
                  <input type="search" #cloneProjectList
                    [(ngModel)]="asyncSearch"
                    (ngModelChange)="getMatches()"
                    matches="[]"
                    placeholder="Search project name or project number"
                    class="form-control form-control-sm cloneProjectDropdown"
                    autocomplete="off"
                    >
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col px-0">
                <div class="scrollbar overflow-y cloneProjectList" style="height: 400px;">
                  <table class="table table-hover mb-0">
                    <thead>
                      <tr class="grey6 nav-font14 font-weight-bold nav-bg-snow-white">
                        <th scope="col" class="px-4 border-bottom-0 border-top-0">Project name</th>
                        <th scope="col" class="px-4 border-bottom-0 border-top-0">ProjectID</th>
                        <th scope="col" class="px-4 border-bottom-0 border-top-0">Client</th>
                        <th scope="col" class="px-4 border-bottom-0 border-top-0">Start Date</th>
                        <th scope="col" class="px-4 border-bottom-0 border-top-0">End Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="grey5 nav-font14 cursor-pointer" *ngFor="let match of matches; let idx = index"
                        (click)="selectMatch(match, $event)">
                        <th scope="row" class="px-4 nav-accent-blue font-weight-normal" [ngClass]="match?.id == selectedMatch ? 'selectedMatch selectedLeft' : ''" [class.border-top-0]="idx == 0">{{match?.projectName}}</th>
                        <td class="px-4" [ngClass]="match?.id == selectedMatch ? 'selectedMatch' : ''" [class.border-top-0]="idx == 0">{{match?.projectCode}}</td>
                        <td class="px-4 " [ngClass]="match?.id == selectedMatch ? 'selectedMatch' : ''" [class.border-top-0]="idx == 0">{{match?.clientName}}</td>
                        <td class="px-4 " [ngClass]="match?.id == selectedMatch ? 'selectedMatch' : ''" [class.border-top-0]="idx == 0">{{match?.projectStartDate | date: 'MM/dd/yyyy' }}</td>
                        <td class="px-4 " [ngClass]="match?.id == selectedMatch ? 'selectedMatch selectedRight' : ''" [class.border-top-0]="idx == 0">{{match?.projectEndDate | date: 'MM/dd/yyyy' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="row d-flex w-100" >
        <div class="col text-right pr-0">
          <button class="btn nav-btn-primary mx-2 shadow-none font-weight-bold" (click)="closeModal()">Cancel</button>
          <button *ngIf="createType=='quick'" class="btn nav-btn-accent shadow-none font-weight-bold" [ngClass]="creatingProject ? 'nav-btn-disabled' : (!_newProject.projectName || !projectType ? 'nav-btn-disabled' : 'nav-btn-accent')" [attr.disabled]="creatingProject ? true : (!_newProject.projectName || !projectType ? true : null)" (click)="createProjectNext();">Next</button>
          <button *ngIf="createType=='clone'" class="btn nav-btn-accent shadow-none font-weight-bold" [ngClass]="creatingProject ? 'nav-btn-disabled' : (!selectedMatch ? 'nav-btn-disabled' : 'nav-btn-accent')" [attr.disabled]="creatingProject ? true : (!selectedMatch ? true : null)" (click)="createProjectNext();">Next</button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="step == 2  && isInternal">
    <div class="modal-header d-flex align-items-center justify-content-center">
      <div class="header-grid w-100 position-relative">
        <div class="d-block text-center mt-3">
          <h2 class="nav-secondary mb-0 font-weight-bold">Create a project</h2>
        </div>
        <div class="d-flex justify-content-end align-content-center position-absolute w-100 sticky-top">
          <div class="grey4 mr-auto cursor-pointer" (click)="back();"><i class="fak fa-lg fa-left-arrow" ></i> Back</div>
          <i class="fas fa-close grey4 cursor-pointer" (click)="closeModal()"></i>
        </div>
      </div>
    </div>
    <div class="modal-body nav-font14">
      <div class="row">
        <div class="col mx-4">
          <ng-container *ngIf="createType=='clone'">
            <div class="text-center row mb-3 grey5 mt-4 nav-font16 font-weight-bold">
              Step 3: Update your project's details
            </div>
            <div class="row mb-3">
              <div class="col-12 col-md-3 px-0">
                <label class="nav-font14 required grey4 mt-2">Project Name</label>
              </div>
              <div class="col-12 col-md-9 d-flex align-content-center pr-0">
                <input
                  [(ngModel)]="cloneProjectName"
                  (input)="formChanged($event)"
                  class="form-control form-control-sm form-control-nav"
                  name="projectName"
                  placeholder="Type project name"
                  required>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12 col-md-3 px-0">
                <label class="nav-font14 grey4 mt-2">Project Number</label>
              </div>
              <div class="col-12 col-md-9 d-flex align-content-center pr-0">
                <input
                  [(ngModel)]="cloneProjectBidNumber"
                  (input)="formChanged($event)"
                  class="form-control form-control-sm form-control-nav"
                  name="bidNumber"
                  placeholder="Type project number">
              </div>
            </div>
            <div class="row mb-3 pb-3">
              <div class="col-12 col-md-3 px-0">
                <label class="nav-font14 grey4 mt-2" [class.required]="isORInstance">Client</label>
              </div>
              <div class="col-12 col-md-9 d-flex pr-0">
                <or-type-ahead [labelField]="'name'" [valueField]="'value'" placeholder="Type to search client" dropdownMode=true [(ngModel)]="cloneProjectClient"
                  class="form-control-sm form-control-nav w-100 px-0" [dataCallback]="getClients" (valueChange)="assignClient($event)">
                </or-type-ahead>
              </div>
            </div>
            <div class="text-center row mb-3 grey5 mt-4 nav-font16 font-weight-bold">
              Step 4: Select project details to copy from the cloned project
            </div>
            <div class="row mb-3">
              <div class="or-checkbox pl-0 pr-0 d-flex justify-content-start col-1">
                <input type="checkbox" name="allCopyOptionsSelected" id="allCopyOptionsSelected" [(ngModel)]="allCopyOptionsSelected" (change)="selectAllCopyOptions($event.target.checked)">
                <label for="allCopyOptionsSelected"></label>
              </div>
              <div class="col-10 nav-secondary pl-0 font-weight-bold">Select all</div>
            </div>
            <div class="row mb-2" *ngIf="hasSchedule">
              <div class="or-checkbox pl-0 pr-0 d-flex justify-content-start col-1">
                <input type="checkbox" name="copySchedule" id="copySchedule" [(ngModel)]="_newProject.copySchedule" (change)="checkIfAllSelected($event.target.checked)">
                <label for="copySchedule"></label>
              </div>
              <div class="col-5 px-0">Schedule - Select a start date</div>
              <div class="col-6 px-0">
                <input type="text" autocomplete="off" placeholder="MM/DD/YYYY" [(ngModel)]="_newProject.projectStartDate_dt" [bsValue]="_newProject.projectStartDate_dt" class="form-control form-control-sm form-control-nav bg-white" bsDatepicker readonly
                [bsConfig]="{ dateInputFormat: 'MMM Do, YYYY', isAnimated: true, isOpen: true, showWeekNumbers:false, customTodayClass: 'todays-date' }">
              </div>
            </div>
            <div class="row mb-3">
              <div class="or-checkbox pl-0 pr-0 d-flex justify-content-start col-1">
                <input type="checkbox" name="copyVendors" id="copyVendors" [(ngModel)]="_newProject.copyVendors" (change)="checkIfAllSelected($event.target.checked)">
                <label for="copyVendors"></label>
              </div>
              <div class="col-10 pl-0">Vendors</div>
            </div>
            <div class="row mb-3">
              <div class="or-checkbox pl-0 pr-0 d-flex justify-content-start col-1">
                <input type="checkbox" name="copySurveyLinks" id="copySurveyLinks" [(ngModel)]="_newProject.copySurveyLinks" (change)="checkIfAllSelected($event.target.checked)">
                <label for="copySurveyLinks"></label>
              </div>
              <div class="col-10 pl-0">Survey Links</div>
            </div>
            <div class="row mb-3">
              <div class="or-checkbox pl-0 pr-0 d-flex justify-content-start col-1" [class.disabled-check]="hasQuotasSetup == false">
                <input type="checkbox" name="copyQuotas" id="copyQuotas" [disabled]="hasQuotasSetup == false" [(ngModel)]="_newProject.copyQuotas" (change)="checkIfAllSelected($event.target.checked)">
                <label for="copyQuotas" [tooltip]="hasQuotasSetup == false ? 'Quotas are not able to be cloned because the job being duplicated did not have quotas added.' : null" placement="right"></label>
              </div>
              <div class="col-10 pl-0">Quotas
                <i class="far fa-info-circle opacity-40 or-secondary nav-font12 cursor-default ml-1" tooltip="Vendors must also be cloned if vendor-level quotas are required to be cloned into the new project." placement="bottom"></i>
              </div>
            </div>
            <div class="row mb-3">
              <div class="or-checkbox pl-0 pr-0 d-flex justify-content-start col-1">
                <input type="checkbox" name="copyTeam" id="copyTeam" [(ngModel)]="_newProject.copyTeam" (change)="checkIfAllSelected($event.target.checked)">
                <label for="copyTeam"></label>
              </div>
              <div class="col-10 pl-0">Team members</div>
            </div>
            <div class="row mb-3">
              <div class="or-checkbox pl-0 pr-0 d-flex justify-content-start col-1">
                <input type="checkbox" name="copyDedupeProjects" id="copyDedupeProjects" [(ngModel)]="_newProject.copyDedupeProjects" (change)="checkIfAllSelected($event.target.checked)">
                <label for="copyDedupeProjects"></label>
              </div>
              <div class="col-10 pl-0">De-duplicated project(s) list</div>
            </div>
            <div class="row mb-3">
              <div class="or-checkbox pl-0 pr-0 d-flex justify-content-start col-1">
                <input type="checkbox" name="includeSourceProjectInDedupe" id="includeSourceProjectInDedupe" [(ngModel)]="_newProject.includeSourceProjectInDedupe" (change)="checkIfAllSelected($event.target.checked)">
                <label for="includeSourceProjectInDedupe"></label>
              </div>
              <div class="col-10 pl-0">Include this cloned project in the de-duplication project list</div>
            </div>
          </ng-container>
          <ng-container *ngIf="createType=='quick'">
            <div class="text-center row mb-3 grey5 mt-4 nav-font16 font-weight-bold">
              Step 3: Enter your project's information
            </div>
            <div class="row nav-font14 my-4">
              <div class="col d-flex align-items-center grey4">Primary Project Manager</div>
              <div class="col">
                <or-type-ahead
                  name="projectManager"
                  [selectedValues]="initialUsers"
                  (valueChange)="assignPM($event)"
                  [labelField]="'name'"
                  [valueField]="'id'"
                  [dataCallback]="getUserList"
                  placeholder="Type to search PM"
                  class="form-control-nav px-0 nav-font14">
                </or-type-ahead>
              </div>
            </div>
            <div class="row nav-font14 my-4">
              <div class="col d-flex align-items-center grey4"><label class="mr-2" [class.required]="isORInstance">Start Date</label></div>
              <div class="col">
                <div class="input-group form-control-date">
                  <input
                    type="text" autocomplete="off"
                    name="projectStartDate"
                    readonly
                    [(ngModel)]="_newProject.projectStartDate"
                    bsDatepicker
                    placeholder="Select"
                    #dpStartDate="bsDatepicker"
                    [bsConfig]="{ dateInputFormat: 'MMM Do, YYYY', isAnimated: true, isOpen: true, showWeekNumbers:false, customTodayClass: 'todays-date' }"
                    class="form-control nav-font14 date d-inline bg-white px-2">
                  <span class="input-group-text bg-white" (click)="dpStartDate.toggle()"><i class="fak fa-calendar grey4"></i></span>
                </div>
              </div>
            </div>
            <div class="row nav-font14 my-4">
              <div class="col d-flex align-items-center grey4"><label class="mr-2" [class.required]="isORInstance">Deadline</label></div>
              <div class="col">
                <div class="input-group form-control-date">
                  <input
                    type="text" autocomplete="off"
                    name="projectEndDate"
                    readonly
                    [(ngModel)]="_newProject.projectEndDate"
                    bsDatepicker
                    placeholder="Select"
                    #dpEndDate="bsDatepicker"
                    [bsConfig]="{ dateInputFormat: 'MMM Do, YYYY', isAnimated: true, isOpen: true, showWeekNumbers:false, customTodayClass: 'todays-date' }"
                    class="form-control nav-font14 date d-inline bg-white px-2">
                  <span class="input-group-text bg-white" (click)="dpEndDate.toggle()"><i class="fak fa-calendar grey4"></i></span>
                </div>
              </div>
            </div>
            <div class="row nav-font14 my-4">
              <div class="col d-flex align-items-center grey4"><label class="mr-2" [class.required]="isORInstance">Completes</label>
                <span data-html="true" tooltip="The total goal of survey completes">
                  <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
                </span>
              </div>
              <div class="col">
                <input
                  [(ngModel)]="_newProject.fullLaunchQuota"
                  placeholder="Type number of completes"
                  class="form-control form-control-sm form-control-nav"
                  name="fullLaunchQuota"
                  mask="99999"
                  [patterns]="integerPattern">
              </div>
            </div>
            <div class="row nav-font14 my-4">
              <div class="col d-flex align-items-center grey4"><label class="mr-2" [class.required]="isORInstance">Length of Interview</label>
                <span data-html="true" tooltip="The median length of interview is the estimated amount of time it takes for a participant to begin and end a survey">
                  <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
                </span>
              </div>
              <div class="col">
                <input
                  [(ngModel)]="_newProject.projectLOI"
                  class="form-control form-control-sm form-control-nav"
                  name="projectLOI"
                  mask="99999"
                  [patterns]="integerPattern" >
              </div>
            </div>
            <div class="row nav-font14 my-4">
              <div class="col d-flex align-items-center grey4"><label class="mr-2" [class.required]="isORInstance">Incidence Rate</label>
                <span data-html="true" tooltip="The percentage of respondents who qualify to complete the project survey">
                  <i class="fak nav-font16 fa-message-info grey3 ml-2"></i>
                </span>
              </div>
              <div class="col">
                <input
                  [(ngModel)]="_newProject.projectIR"
                  class="form-control form-control-sm form-control-nav"
                  name="projectIR"
                  placeholder="Type percentage"
                  suffix="%"
                  mask="percent">
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="row d-flex w-100" >
        <div class="col text-right pr-0">
          <button class="btn nav-btn-primary mx-2 shadow-none font-weight-bold" (click)="closeModal()">Cancel</button>
          <button *ngIf="createType=='quick'" class="btn nav-btn-accent shadow-none font-weight-bold" [ngClass]="creatingProject ? 'nav-btn-disabled' : (!_newProject.projectName || !projectType ? 'nav-btn-disabled' : 'nav-btn-accent')" [attr.disabled]="creatingProject ? true : (!_newProject.projectName || !projectType ? true : null)" (click)="createProjectNext();">Create</button>
          <button *ngIf="createType=='clone'" class="btn nav-btn-accent shadow-none font-weight-bold" [ngClass]="creatingProject ? 'nav-btn-disabled' : (!cloneProjectName ? 'nav-btn-disabled' : 'nav-btn-accent')" [attr.disabled]="creatingProject ? true : (!cloneProjectName ? true : null)" (click)="cloneProject();">Create</button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>


<ng-template #modalSuccess>
  <div class="modal-header">
    <div class="row d-flex w-100 align-items-center">
      <div class="col">
        <h3 class="font-weight-bold nav-secondary mb-0 mt-2">
          <div class="mx-2 d-inline">
            <img class="" src="/assets/imgs/icon-waving-hand.png" />
          </div>
          <span class="mt-1">Welcome to your new project</span>
        </h3>
      </div>
      <div class="col-2 text-right px-0">
        <i class="fas fa-close grey4" (click)="closeModal()"></i>
      </div>
    </div>
  </div>
  <ng-container *ngIf="_newProject.allowMobile == true || allowMobileConfirm">
    <div class="modal-body pt-0">
      <h5 class="grey5 d-block my-3">
        For a fully optimized experience, we highly recommend customizing your project's <a [href]="'/projects/' + _newProject.id + '/settings'" class="nav-accent-blue text-decoration-none font-weight-bold">settings</a> to best suit your needs.
      </h5>
    </div>
    <div class="modal-footer">
      <div class="row d-flex w-100 align-items-center">
        <div class="col text-right px-0">
          <button class="btn btn-sm nav-btn-primary mx-2 shadow-none font-weight-bold nav-font14" (click)="closeModal()">Exit</button>
          <button class="btn btn-sm nav-btn-accent shadow-none" (click)="goToSettings()">Go to settings</button>
        </div>
      </div>
    </div>
  </ng-container> 

  <ng-container *ngIf="_newProject.allowMobile == false && allowMobileConfirm == false">
    <div class="modal-body pt-0">
      <h5 class="grey5 d-block my-3">
        The project you are cloning is set up to block mobile traffic. Do you want to carry this setting over to your cloned project?
      </h5>
    </div>
    <div class="modal-footer">
      <div class="row d-flex w-100 align-items-center">
        <div class="col text-right px-0">
          <button class="btn btn-sm nav-btn-primary mx-2 shadow-none font-weight-bold nav-font14" (click)="updateAllowMobile(true)">Turn on Allow Mobile</button>
          <button class="btn btn-sm nav-btn-accent shadow-none" (click)="allowMobileConfirm = true">Continue</button>
        </div>
      </div>
    </div>
  </ng-container> 

  
</ng-template>

