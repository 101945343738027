import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-project-homepage-team',
  templateUrl: './project-homepage-team.component.html',
  styleUrls: ['./project-homepage-team.component.scss']
})
export class ProjectHomepageTeamComponent implements OnInit {

  @Input() public set project(data) {
    this._project = data;
  }

  @Input() stage: any;

  @Output() onChange = new EventEmitter();

  _project = {};

  constructor() { }

  ngOnInit() {
    //console.log(this.stage);
  }

  openStage(stage) {
    this.onChange.emit();
    //this.currentStage = stage;
    //console.log(this.currentStage );
  }

}
