import { map } from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ProjectService, AlertifyService, QuotaService, ProjectQuota, QuotaGroup, QuotaQuestion, DropdownQuota, AuthService } from 'core';

@Component({
  selector: 'app-quota-infield-vendor-card',
  templateUrl: './quota-infield-vendor-card.component.html',
  styleUrls: ['./quota-infield-vendor-card.component.scss']
})
export class QuotaInfieldVendorCardComponent implements OnInit, OnChanges {

  @Input() group: QuotaGroup;
  @Input() limitMode: string;
  @Input() limitType: string;
  @Input() fullLaunchQuota: number;
  @Input() vendor: any;
  @Input() metrics: any;
  @Input() limitCheck: string;
  @Input () questions: QuotaQuestion[];
  @Input() enableVendorLimits: boolean;
  @Output() onChange = new EventEmitter();
  @Output() clickedOnQuota = new EventEmitter();

  @Input() isVendorview: boolean;

  vendorGroupTotals = {};
  vendorQuotaStarts = {n:0, percent: 0};
  vendorQuotaCompletes = {n:0, percent: 0};
  groupTotals = {};

  // hhi
  hhiList = [];
  hhiInterval = 5000;
  hhiMin = 15000;
  hhiMax = 150000;

  formMode= 'edit'


  
  constructor(
    private projectService: ProjectService,
    private alertify: AlertifyService,
    private auth: AuthService) { }

  ngOnInit() {

    for (var n = this.hhiMin; n <= this.hhiMax; n += this.hhiInterval) this.hhiList.push(n);

    this.group.quotas.forEach(q => {
      q.vendorLimits.forEach(v => {
        {
          if (v.edit != true) {
            v.edit = false;
          }
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.handleQuotaChange();
  }

  populateHHIList() { 
      for (var n = this.hhiMin; n <= this.hhiMax; n += this.hhiInterval) this.hhiList.push(n);
  }

  
  getBgColor(group, quota): string {
  
    return 'nav-success';
  }

  




  handleLimitChange(quota, type, vendor, event) {
    quota.changesMade = true;
    const vendorGroupTotals = {};
    var val = event.target.value;

    if (this.fullLaunchQuota === 0) { 
      return 0; }

    if ( val[0] === '*' || val === '99999' || val == 99999) {
      vendor.limitPercent = 99999;
      vendor.limitN = 99999;
    }
    else {
      val = Number(val)
      if (!isNaN(val)) {
        if (type === '%') {
          vendor.limitPercent = val;
          //vendor.limitPercent = (vendor.limitPercent < 0) ? 0 : (vendor.limitPercent > 100) ? 100 : vendor.limitPercent;
          vendor.limitN = Math.ceil(quota.limitN * vendor.limitPercent / 100);
        } else {
          vendor.limitN = val;
          vendor.limitPercent = Math.ceil(vendor.limitN * 100 / quota.limitN);
        }
      }
    }

    this.handleQuotaChange();
  }

  calculateTotals() {

  }
  
  handleQuotaChange() {
    // called whenever we add/remove/change quotas so we can recalculate everything
    // this.overlapExists = false;
    // this.overTotalExists = false;
    // this.groupWithNoLimitExists = false;
    // this.justSaved = false;
    const vendorTotals = {n: 0, percent: 0}
    const vendorGroupTotals = {};
    var vendorN;
    var vendorPercent;
    this.vendorQuotaStarts = {n: 0, percent: 0};
    this.vendorQuotaCompletes = {n: 0, percent: 0};

    if (this.group.isDeleted == false) {
      var totalNVendorGroup = 0;
      const quotas = this.group.quotas;
      const totalN = quotas.filter(x => x.isDeleted == false && x.limitN != 99999).reduce((a, b) => a + (Number(b.limitN) || 0), 0);
      const totalPercent = quotas.filter(x => x.isDeleted == false && x.limitPercent != 99999).reduce((a, b) => a + (Number(b.limitPercent) || 0), 0);
      if (totalPercent > 100) {
        // this.overTotalExists = true
      }
      


      quotas.forEach(q => {
        if ((this.limitType == 'number' && q.limitN >= 99999) || ( this.limitType == 'percent' && q.limitPercent >= 99999)) {
          q.limitN = 99999;
          q.limitPercent = 99999;
            vendorN = 99999;
            vendorPercent = 99999;
        }
        else {
          if (this.enableVendorLimits) {
            vendorN = q.vendorLimits.filter(x => x.limitN != 99999).reduce((a, b) => Number(a) + (Number(b.limitN) || 0), 0);
            vendorPercent = q.vendorLimits.filter(x => x.limitPercent != 99999).reduce((a, b) => Number(a) + (Number(b.limitPercent) || 0), 0);
          }
        }


        if (this.enableVendorLimits && !q.isDeleted) {
          q.vendorLimits.forEach(v => {
            if (v.limitN != 99999 && v.limitN != 99999 ) {
          
              if (!vendorGroupTotals[v.vendorId + "_" + this.group.id]) {
                vendorGroupTotals[v.vendorId + "_" + this.group.id] = {n: 0, percent: 0}
              }
              vendorGroupTotals[v.vendorId + "_" + this.group.id].n += Number(v.limitN);
              vendorGroupTotals[v.vendorId + "_" + this.group.id].percent = Math.round((vendorGroupTotals[v.vendorId + "_" + this.group.id].n * 100) / totalN)
            }
           
            if (v.vendorId == this.vendor.partnerId){
              this.vendorQuotaStarts.n += v.starts
              this.vendorQuotaCompletes.n += v.completes
            }
            // vendorGroupTotals[v.id].n += v.limitN
          });
        }
      });
    
      // this.vendorQuotaStarts = vendorQuotaStarts
      // this.vendorQuotaCompletes = vendorQuotaCompletes

    this.vendorGroupTotals = vendorGroupTotals;
    }
  }

  enableEdit(quota) {
    if (this.auth.isClient() || this.auth.isVendor() || this.isVendorview) return;
    this.clickedOnQuota.emit(quota);
  }
}
