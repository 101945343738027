<div class="card surveycard" [class.group]="isGroup">
  <div class="card-header pb-0 pt-3">
    <div class="row">
      <div class="col-5 font-weight-light">
        <div class="h6 d-inline" [ngClass]="project.projectCode.length > 11 ? 'h6' : 'h5'">
          <a [routerLink]="['/projects', project.id]" class="font-weight-bold nav-secondary">{{ project.projectCode }}</a>
        </div>
      </div>
      <div class="col d-flex pr-0 pl-0">
        <div class="ml-auto text-right">
          <or-project-stage [stage]="project['currentStage']" [color]="project['currentStageColor']" [percentComplete]="(project?.stageCompleteTaskCount / project?.stageTotalTaskCount)*100"></or-project-stage>
        </div>
        <div class="text-center px-0 mx-2 d-flex align-items-center">
          <div *ngIf="auth.isInternal()">
            <i (click)="toggleFavorite()" class="far fa-star fa-lg cursor-pointer mx-1 mb-1" [ngClass]="{'active': isFavorite}" ></i>
          </div>
          <div class="btn-group btn-group-sm" dropdown>
            <button id="button-basic" dropdownToggle type="button" class="btn" controls="dropdown-basic" *ngIf="auth.isInternal()">
              <span><i class="fak fa-ellipsis-vertical fa-lg grey4"></i></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
              aria-labelledby="button-basic">
              <li *ngFor="let action of actions" role="menuitem">
                <or-action-button
                  [label]="action.label"
                  [description]="action.description"
                  [icon]="action.icon"
                  (selected)="actionSelected(action.type)">
                </or-action-button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body py-0">
    <div class="d-flex mt-2">
      <div class="d-block text-truncate mr-2">
        <a class="h6 nav-secondary" [routerLink]="['/projects', project.id]" tooltip="{{ project.projectName }}">{{ project.projectName }}</a>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-11">
        <div class="d-block text-truncate">
          <span class="h6 text-truncate cursor-pointer grey5 dotted-underline" [routerLink]="['/clients', project.clientId]" tooltip="{{ project.clientName }}">{{ project.clientName }}</span>
        </div>
      </div>
    </div>
  </div>

    <!-- PROJECT INFIELD -->
    <app-project-card-infield
    *ngIf="project?.projectStatus?.includes('Full Launch') || project?.projectStatus?.includes('Soft Launch') || project?.projectStatus?.includes('Paused')"
    [project]="project">
  </app-project-card-infield>

    <!-- <app-project-card-test-mode
    *ngIf="project.projectStatus == 'Test'"
    [project]="project">
  </app-project-card-test-mode> -->

  <!-- PROJECT CLOSED OR TEST MODE -->
  <app-project-card-closed
    *ngIf="project.projectStatus == 'Closed' || project.projectStatus == 'Invoiced' || project.projectStatus == 'Test'"
    [project]="project">
  </app-project-card-closed>
</div>

<div *ngIf="isGroup" class="group-background first-layer">
  <div class="group-background second-layer"></div>
</div>




