<div class="modal-header text-white">
  <p class="h4 position-absolute">ResponseID Settings</p>
  <button type="button" class="close pull-right text-white" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body responseid-settings">
  <div class="row">
    <div class="alert alert-danger small p-2" role="alert" *ngIf="errorMsg">
      {{ errorMsg }}
    </div>
  </div>
  <div *ngIf="settingsForm" class="information">
    <div class="text" *ngIf="_newRespondentCount > 0 || _newQuestionCount > 0">
      <div class="h6 or-primary">Upload File Report</div>
      <p>Changes Made: <br>{{ _newRespondentCount }} New Records <br> {{ _newQuestionCount}} New variables</p>
    </div>
    <div>
      <tabset>
        <tab heading="Questions" id="tab3">
          <form [formGroup]="settingsForm">
            <div class="card-body scrollbar overflow-y">
              <div class="response-id-settings-wrap row">
                <div class="form-group col-12 col-md-3">
                  <label data-html="true" tooltip="Select the language to be used for all respondents.">
                    Language
                    <i class="fak nav-font16 fa-message-info grey3"></i>
                  </label>
                  <select class="form-control" formControlName="language">
                    <option [disabled]="!uploadFileExist" *ngFor="let lang of languageOptions" value="{{ lang.shortCode }}">{{ lang.name }}</option>
                  </select>
                </div>
                <div class="form-group col-12 col-md-3">
                  <label>Project Identifier</label>
                  <input readonly class="form-control" value="{{ _project.id }}">
                </div>
                <div class="form-group col-12 col-md-3" *ngIf="_project.settings?.idColumn != null">
                  <label>Respondent Identifier Column</label>
                  <input readonly class="form-control" value="{{ _project.settings?.idColumn }}">
                </div>
                <div class="form-group col-12 col-md-3" *ngIf="uploadFileExist && _project.settings">
                  <label data-html="true" tooltip="Enable this to delete any existing data and update with just this files contents.">
                    Reset Existing Data
                    <i class="fak nav-font16 fa-message-info grey3"></i>
                  </label>
                  <or-toggle-button formControlName="resetData" [(ngModel)]="_project.settings.reset" class="light"></or-toggle-button>
                </div>
              </div>
              <hr>
              <tabset #staticTabs>
                <tab heading="Questions" id="tab4"  *ngIf="mode === 'new'">
                  <div class="alert alert-info py-3" role="alert">
                    <h5 class="alert-heading">Select Questions for Review</h5>
                    <p class="m-0">The questions selected here will be run through ResponseID</p>
                  </div>
                  <div class="row mx-1">
                    <div class="col-12 col-md"></div>
                    <div class="col-12 col-md-1"></div>
                    <div class="col-12 col-md pl-0"><label for="_projectclientName" class="required small">Selected for Review</label></div>
                  </div>
                  <div class="row mx-1">
                    <div class="form-group col-12 col-md border scrollbar overflow-y">
                      <table class="table table-hover table-light">
                        <thead><th>Question Identifier</th>
                          <th>
                            <input type="checkbox" (change)="onChange($event.target.checked)" id="clean-id-report-sent23"> Select All
                          </th>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let question of Questions">
                            <tr (click)="termsChange(question)">
                              <td class="align-middle nowrap">
                                <a class="or-primary-link"> {{ question.questionIdentifier }} </a>
                              </td>
                              <td class="align-middle nowrap">
                                <input [checked]="allCheck || question.questionType != 'SKIP'" type="checkbox" id="clean-id-report-sent">
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                    <div class="form-group col-12 col-md-1"></div>
                    <div class="form-group col-12 col-md border scrollbar overflow-y">
                      <ng-container>
                        <or-list [items]="selectedQuestions" [layout]="layout1" [enableExport]=false></or-list>
                      </ng-container>
                    </div>
                  </div>
                  <div class="card-footer or-title bg-white" *ngIf="settingsForm.valid">
                    <div class="row">
                      <div class="col-8  text-right" *ngIf="uploadFileExist">
                        <div class="or-checkbox d-block">
                          <input type="checkbox" name="aggrement" id="aggrement" [(ngModel)]="enableAgreement" [ngModelOptions]="{standalone: true}">
                          <label for="aggrement" class="text-secondary">
                            By clicking the check box, you agree to the OpinionRoutes SaaS Terms & Conditions and confirm that you are not uploading any data that can be considered Personally Identifiable Information (PII).  For more information,click <a href="https://dpterms.helpdocsonline.com/saasterms" target="_blank">here</a>
                          </label>
                        </div>
                      </div>
                      <div class="col px-0">
                        <or-spinner-button label= 'Cancel' customClass="btn-secondary nav-btn-accent text-white float-right btn-sm ml-1" showSpinnerOnCallback=true (callback)="cancel()"></or-spinner-button>
                        <div *ngIf="uploadFileExist">
                          <or-spinner-button label='Next' #spinner icon="fa fa-check" customClass="btn-secondary nav-btn-accent text-white float-right btn-sm ml-1" showSpinnerOnCallback=true (callback)="openQuestionTypePicker()"></or-spinner-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </tab>
                <tab heading="Question Type" id="tab5" *ngIf="mode === 'edit' || 'new'">
                  <div class="row question-type">
                    <div class="col-12 col-md-4 border-right scrollbar overflow-y">
                      <div class="mt-3">
                        <div *ngIf="stab2">
                          <table class="table table-hover table-light table-striped">
                            <tbody>
                              <ng-container *ngFor="let question of Questions">
                                <tr (click)="currentQuestion = question.questionIdentifier" *ngIf="question.questionType != 'SKIP'"
                                  [ngClass]=" (currentQuestion == question.questionIdentifier) ? 'table-info': ''">
                                  <td class="align-middle nowrap">
                                    <a class="or-primary-link"> {{ question.questionIdentifier }} </a>
                                  </td>
                                  <td class="align-middle nowrap">
                                    <i class="cursor-default" [ngClass]="(question.questionType != '') ? 'fas fa-check text-success' : ''"></i>
                                  </td>
                                </tr>
                              </ng-container>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngFor="let question of Questions">
                      <div class="form-group col px-4" *ngIf="question.questionIdentifier == currentQuestion">
                        <div class="mt-3">
                          <div class="row">
                            <div class="form-group col-12">
                              <label class="h5 d-flex or-title mb-2">{{question.questionIdentifier}}</label>
                              <label class="small">
                                Question Text
                                <span data-html="true" tooltip="Identify this question more easily by adding the question text.">
                                  <i class="fak nav-font16 fa-message-info grey3"></i>
                                </span>
                              </label>
                              <input type="text" [disabled]="mode === 'edit'" class="form-control form-control-sm" [(ngModel)]="question.questionText" [ngModelOptions]="{standalone: true}"  placeholder="Enter Question Text here">
                            </div>
                          </div>
                          <div>
                            <div class="row">
                              <div class="col">
                                <label class="small">
                                  Question Type
                                  <span data-html="true" tooltip="The type has a large impact on scoring so select the appropriate option from below.">
                                    <i class="fak nav-font16 fa-message-info grey3"></i>
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div class="question-type-grid">
                              <ng-container  *ngFor="let qType of questionTypeOptions">
                                <div [class]="uploadFileExist == true ? 'card' : 'card card-nocursor'"  (click)="setActive(question, qType)" [class.selected]="qType.value == question.questionType">
                                  <div class="card-body">
                                    <h6 class="card-title or-title" [class.text-white]="qType.value == question.questionType">
                                      <i class="{{ qType.icon }} mr-2" [class.text-white]="qType.value == question.questionType"></i> {{ qType.name }}
                                    </h6>
                                    <p class="card-text small" [class.text-white]="qType.value == question.questionType">{{ qType.description }}</p>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                          <div class="col px-0 mt-1">
                            <or-spinner-button  [label]="uploadFileExist == true ? 'Cancel' : 'Close'" customClass="btn-secondary nav-btn-accent text-white float-right btn-sm ml-1" showSpinnerOnCallback=true (callback)="cancel()"></or-spinner-button>
                            <div *ngIf="uploadFileExist == true">
                              <or-spinner-button label='Upload'  #spinner icon="fa fa-check" customClass="btn-secondary nav-btn-accent text-white float-right btn-sm ml-1" showSpinnerOnCallback=true (callback)="saveQuestions()"></or-spinner-button>
                              <or-spinner-button label='Back'  customClass="btn-secondary nav-btn-accent text-white float-right btn-sm ml-1" showSpinnerOnCallback=true (callback)="back()"></or-spinner-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </tab>
              </tabset>
            </div>
          </form>
        </tab>
        <tab heading="Upload History" id="tab2">
          <div>
            <table class="table">
              <thead>
                <tr>
                  <th>FileName</th>
                  <th>Total Records</th>
                  <th>Total Completes</th>
                  <th>SubmittedOn</th>
                  <th>SubmittedBy</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let o of batchList">
                  <td>{{o.actualFileName}}</td>
                  <td>{{o.totalRecords}}</td>
                  <td>{{o.totalCompletes}}</td>
                  <td>{{o.submittedOn | date: 'MMM d, y'}}</td>
                  <td>{{o.submittedBy}}</td>
                  <td><button type="button" class="btn btn-sm btn-outline-secondary mt-2 mr-0" (click)="downloadFile(o.url,o.actualFileName,o.fileName)"><em class="fa fa-download"></em> Download</button>    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </tab>
      </tabset>
    </div>
  </div>
</div>
