import { Component, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { ProjectService, AlertifyService, AuthService, FavoritesService, UtilsService, ProjectDataService, SettingsService, Project, CleanidService } from 'core';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ExportFilesService } from 'projects/core/src/services/exportFiles.service';
import { formatDate } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FilterPipe } from 'projects/core/src/helpers/filter.pipe';
import Highcharts from 'highcharts';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-project-family-summary',
  templateUrl: './project-family-summary.component.html',
  styleUrls: ['./project-family-summary.component.scss'],
  providers: [FilterPipe]
})
export class ProjectFamilySummaryComponent implements OnInit, OnDestroy {

  instanceId;
  relatedProjects;
  favorites;
  respondents;
  selectedProject = null;
  loading = false;
  editingRule = false;
  projectListLoading = false;

  currentFamily = {
    bidNumber: null,
    familyCode: null,
    familyName: null,
    familyClient: null,
    familyClientId: null,
    familyClientPo: null
  }

  currentView = {
    cardStyle: 'survey'
  }

  rowActions = [
    { label: 'Open', type: 'open' },
    { label: 'Download', type: 'download' },
    { label: 'Clone', type: 'clone' },
    { label: 'Delete', type: 'delete' }
  ];

  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;
  openModalRef: any;

  highcharts = Highcharts;
  highchartOptionsCurrentProjects: Highcharts.Options;
  highchartOptionsTasks: Highcharts.Options
  stageCount;
  taskCount;
  stageColors;
  colorsList = [];
  totalProjects = 0;
  totalTasks = 0;
  multiProjectTasks;
  teamMembers;
  teamMemberTotal;
  projectManagerNames = [];
  refreshTasks: number = 0;
  totalTaskCount = 0;
  totalAllocationCount = 0;
  totalCompletesCount = 0;
  behindPaceCount = 0;
  liveProjectCount = 0;

  listUrls: any;
  redirectUrls: any;
  projectIdList: any[] = [];

  sidebarOpen = false;
  sidebarSectionOpen = false;
  currentSideSection = "";
  cardNotes = { open: false, expanded: false };
  subsection: string;
  project = {} as Project;
  projectType: any;

  reportLoading = false;
  cleanIdPreviewReport;
  emailTo;
  submitted;

  constructor(
    private projectService: ProjectService,
    private alertify: AlertifyService,
    private favoriteService: FavoritesService,
    private exportFilesService: ExportFilesService,
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private projectDataService: ProjectDataService,
    private utilsService: UtilsService,
    private settingsService: SettingsService,
    private renderer: Renderer2,
    private cleanidService: CleanidService,
    private sanitizer: DomSanitizer) { }

  ngOnDestroy(): void {
    this.relatedProjects = [];
    this.projectDataService.setCurrentProject(null);
    this.projectDataService.setCurrentFamily({ familyCode: null });
  }

  ngOnInit() {
    this.instanceId = this.auth.getInstance();
    this.route.params.subscribe(params => {
      if (params?.bidNumber) this.currentFamily.bidNumber = params?.bidNumber;
      if (params?.familyCode) this.currentFamily.familyCode = params?.familyCode;
      if (this.currentFamily?.bidNumber) this.getRelatedByBid();
      else if (this.currentFamily?.familyCode) this.getRelatedByBid();
      this.relatedProjects?.forEach(project => {
        if (project.accountOwner instanceof Array) project.accountOwner = project.accountOwner[0].name;
      });
    });

    //subscribe to sidebar (project submenu) click
    this.projectDataService.getProject.subscribe(data => {
      if (data?.sidebarProject)
        this.showSideSection(data?.sidebarProject);
    });
    //subscribe to sidebar mode
    this.projectDataService.getSidebarMode.subscribe(data => {
      data = data || 'reduced';
      this.sidebarOpen = (data === 'reduced' ? false : true);
    });

    //subscribe the  projectStatus 
    this.projectDataService.getProjectStatus.subscribe(data => {
      if (data == 'Invoiced') {
        this.getRelatedByBid();
      }
    });

    this.closeSideBar();
    this.projectType = this.project.type;
  }


  getRelatedByBid() {
    this.loading = true;
    this.projectListLoading = true;
    this.relatedProjects = [];
    let code = this.currentFamily.bidNumber != null
      ? this.currentFamily.bidNumber
      : this.currentFamily.familyCode != null
        ? this.currentFamily.familyCode
        : null;

    this.projectService.getMultiProjectDashboardChartInfo(code).subscribe((data) => {

      this.stageCount = data.stageCount;
      this.taskCount = data.taskCount;
      this.stageColors = data.stageColors;
      this.multiProjectTasks = data.taskCount;
      this.setHighchartOptions();
      // this.updateProjectList();
      this.loading = false;
    }, error => {
      this.alertify.error('There was an error getting dashboard details');
    });


    this.projectService.GetRelatedByBidNumber('00000000-0000-0000-0000-000000000000', code).subscribe((data) => {
      this.relatedProjects = this.relatedByBidNumber(data);
      this.setCommonValues(this.relatedProjects)
      var totalAllocationCount = 0;
      var totalCompletesCount = 0;
      var behindPaceCount = 0;
      var liveProjectCount = 0;
      for (let i = 0; i < this.relatedProjects.length; i++) {
        var project = this.relatedProjects[i]
        totalAllocationCount += (project.fullLaunchQuota || 0)
        totalCompletesCount += (project.complete || 0)
        var isLive = project.projectStatus === 'Full Launch' || project.projectStatus === 'Soft Launch'
        if (isLive) {
          liveProjectCount += 1
          if (project.percentageComplete < (100 - project.percentageDaysLeft)) behindPaceCount += 1;
        }
      }
      this.totalAllocationCount = totalAllocationCount;
      this.totalCompletesCount = totalCompletesCount;
      this.liveProjectCount = liveProjectCount;
      this.behindPaceCount = behindPaceCount;

      this.projectService.GetFamilyParticipants(code).subscribe((data) => {
      
        this.respondents = []
        data.forEach(e => {
          const inTime = new Date(e.date);
          e.date = new Date(inTime.getFullYear(), inTime.getMonth(), inTime.getDate());
          e.complete = (e.completeLois == null) ? 0 : e.completeLois.length;
          var project = this.relatedProjects?.find(x => x.id.toString().toLowerCase() == e.projectId.toString().toLowerCase());
          e.project = project?.projectName;
          e.projectCode = project?.projectCode;
          this.respondents.push(e);
        });
      }, error => {
        this.alertify.error('There was an error getting project family details');
      });

    }, error => {
      this.alertify.error('There was an error getting project list');
    });
    var favorites = {}
    this.favoriteService.getProjectFavorites().subscribe((data) => {
      data.forEach(e => favorites[e.projectId] = true);
      this.favorites = favorites;
    }, error => {
    });
    this.projectService.getMultiProjectTeamMembers(code).subscribe((data) => {
      this.teamMembers = data;
      this.teamMemberTotal = (this.teamMembers?.sales?.length + this.teamMembers?.programmers?.length + this.teamMembers?.projectManagers?.length)
    }, error => {
      this.alertify.error('There was an error getting team details');
    });
  }
  relatedByBidNumber(data) {    
    var projects = data.map((item, index) => {
      item = this.projectService.EnrichProject(item);
      const [, code] = item.projectCode?.split('-');
      item.code = code;
      item.order = index + 2;
      return item;
    });
    return projects;
  }

  callbackTasks(event) {
    try {
      var newTaskTotal = Number(event);
      this.totalTaskCount = newTaskTotal;
    }
    catch {
    }
  }
  callbackMyTasks(evt) {
    if (evt) {
      this.refreshTasks = new Date().getTime();
    }
  }

  callbackRules(evt) {
    this.editingRule = evt?.editing || false;
  }

  setCommonValues(projects) {
    if (projects.length > 0) {
      // family short code
      const [shortCode,] = projects[0].projectCode?.split('-');
      this.currentFamily.familyCode = shortCode;

      // family name
      var projects = projects?.map(x => x.projectName)
      this.currentFamily.familyName = this.utilsService.longestCommonSubstring(projects)

      // family client
      var projectWithClient = this.relatedProjects.find(x => x.clientName != null)
      this.currentFamily.familyClient = projectWithClient?.clientName
      this.currentFamily.familyClientId = projectWithClient?.clientId

      // family clientPo
      var projectWithClientPo = this.relatedProjects.find(x => x.clientPo != null)
      this.currentFamily.familyClientPo = projectWithClientPo?.clientPo
    }

    this.projectDataService.setCurrentFamily(this.currentFamily);
    this.projectListLoading = false;

  }

  addProjectToFamily() {
    var code = this.currentFamily.familyCode;
    var bidNumber = this.currentFamily.bidNumber;
    this.projectDataService.setActionNavBar({ type: 'addToFamily', familyConfig: { familyCode: code, bidNumber: bidNumber } });
  }

  handleAction(action) {  
    if (action.type === 'open') {
      this.openProject(action.row);
    } else if (action.type === 'download') {
      this.downloadProject(action.row);
    } else if (action.type === 'favorite') {
      this.favoriteProject(action.row);
    } else if (action.type === 'delete') {
      this.deleteProject(action.row);
    } else if (action.type === 'clone') {
      this.cloneProject({ type: 'clone', project: action.row })
    }
  }

  cloneProject(data) { this.projectDataService.setActionNavBar(data); }
  openProject(project) { this.router.navigateByUrl('/projects/' + project.id); }

  downloadProject(project) {
    const filename = formatDate(new Date(), 'MM-dd-yyyy', 'en').toString() + '-' + project.projectCode + '.xlsx';
    this.alertify.message('Your file will download once ready');
    let projectList = [project.id];

    if (this.auth.isVendor()) {
      this.exportFilesService.downloadVendorParticipantData(projectList, filename, this.auth.getUser().vendorId).subscribe(url => {
        window.location.assign(url);
      }, error => {
        this.alertify.error(error);
      }, () => { });
    } else {
      this.exportFilesService.downloadParticipantData(projectList, filename, false).subscribe(url => {
        window.location.assign(url);
      }, error => {
        this.alertify.error(error);
      }, () => {
        // All Good
      });
    }
  }

  favoriteProject(project) {
    var exists = this.favorites[project.id];
    if (exists) {
      this.favoriteService.removeProjectFavorite(project.id).subscribe(data => {
        this.FavoriteChange(false, project);
        this.alertify.success('Project Removed from Favorites');
      }, error => { console.log(error); });
    } else {
      this.favoriteService.setProjectFavorite(project.id).subscribe(data => {
        this.FavoriteChange(true, project);
        this.alertify.success('Project Added to Favorites');
      }, error => { console.log(error); });
    }
  }

  deleteProject(project) {
    this.selectedProject = project;
    this.openModalRef = this.modalService.show(this.modalRefConfirm);
  }

  confirmDelete() {
    this.projectService.DeleteProject(this.selectedProject.id).subscribe(data => {
    }, error => {
      this.alertify.error('Unable to delete project');
      this.openModalRef.hide();
    }, () => {
      this.alertify.success(this.selectedProject.projectName + ' deleted successfully');
      this.getRelatedByBid();
      this.openModalRef.hide();
    });
  }

  FavoriteChange(value, project) {
    this.favorites[project.id] = value;
    this.favoriteService.getProjectFavorites(true).subscribe(data => {
    }, error => {
    });
  }

  legendClick(item) { 
    
   }

  setHighchartOptions() {
    // Current projects
    var chartData = [];
    for (let [stage, color] of this.stageColors) {
      if (this.stageCount[stage]) {
        chartData.push([stage, this.stageCount[stage]]);
        this.totalProjects += this.stageCount[stage];
        this.colorsList.push(color);
      }
    }

    var totalProjects = this.totalProjects; // local variable needed for render callback function

    this.highchartOptionsCurrentProjects = {
      credits: {
        enabled: false
      },
      chart: {
        plotBorderWidth: null,
        plotShadow: false,
        margin: [0, 0, 0, 0],
        events: {
          render: function () {
            var centerX = this.plotWidth / 2;
            var centerY = this.plotHeight / 2;

            var label = this.renderer
              .label(totalProjects + ' Projects', centerX, centerY)
              .css({
                color: '#000',
                fontSize: '16px',
              })
              .attr({
                padding: 8,
                r: 5,
              })
              .add();

            var labelBox = label.getBBox();
            label.translate(centerX - (labelBox.width / 2), centerY - (labelBox.height / 2));
            // Remove all labels except for the last label
            const element = document.getElementById("projectsChart");
            if (element != null) {
              var elements = element.getElementsByClassName("highcharts-label");
              while (elements.length > 1) elements[0].parentNode.removeChild(elements[0]);
            }
          }
        }
      },
      title: {
        text: ''
      },
      plotOptions: {
        pie: {
          shadow: false,
          center: ['50%', '50%'],
          size: '100%',
          innerSize: '60%',
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: false,
          point: {
            events: {
              legendItemClick: function () {
                return false;
              }
            }
          }
        },
        series: {
          states: {
            hover: {
              halo: null
            }
          }
        }
      },
      series: [{
        type: 'pie',
        data: chartData
      }],
      tooltip: {
        formatter: function () {
          return this.point.name + ": " + this.y + " (" + this.percentage.toFixed(2) + "%)"
        }
      },
      legend: {
        enabled: true,
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        floating: true,
        itemStyle: {
          fontSize: '1.5em',
        }
      }
    };

    this.totalTasks = this.taskCount["open"] + this.taskCount["overdue"];
    var totalTasks = this.totalTasks;

    // Tasks
    this.highchartOptionsTasks = {
      credits: {
        enabled: false
      },
      chart: {
        plotBorderWidth: null,
        plotShadow: false,
        margin: [0, 0, 0, 0],
        events: {
          render: function () {
            var centerX = this.plotWidth / 2;
            var centerY = this.plotHeight / 2;

            var label = this.renderer
              .label(totalTasks + ' Tasks', centerX, centerY)
              .css({
                color: '#000',
                fontSize: '16px',
              })
              .attr({
                padding: 8,
                r: 5,
              })
              .add();

            var labelBox = label.getBBox();
            label.translate(centerX - (labelBox.width / 2), centerY - (labelBox.height / 2));
            // Remove all labels except for the last label
            const element = document.getElementById("tasksChart");
            if (element != null) {
              var elements = element.getElementsByClassName("highcharts-label");
              while (elements.length > 1) elements[0].parentNode.removeChild(elements[0]);
            }

          },
          load: this.onTasksChartLoad.bind(this)
        },
      },
      title: {
        text: ''
      },
      plotOptions: {
        pie: {
          shadow: false,
          center: ['50%', '50%'],
          size: '100%',
          innerSize: '60%',
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: false,
          point: {
            events: {
              legendItemClick: function () {
                console.log(this.name);
                return false;
              }
            }
          }
        },
        series: {
          states: {
            hover: {
              halo: null
            }
          }
        }
      },
      series: [{
        type: 'pie',
        data: [
          ['Open', this.taskCount["open"]],
          ['Overdue', this.taskCount["overdue"]],
        ]
      }],
      tooltip: {
        formatter: function () {
          return this.point.name + ": " + this.y + " (" + this.percentage.toFixed(2) + "%)"
        }
      },
      legend: {
        enabled: true,
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        floating: true,
        itemStyle: {
          fontSize: '1.5em',
        }
      }
    };
  }
  onTasksChartLoad(event) {
    if (this.totalProjects > 0) {
      var points = document.getElementsByTagName('path');
      var colors = this.colorsList.concat(['#55BA2E', '#C72F1E']);

      for (let i = 0; i < points.length; i++) {
        this.renderer.setStyle(points[i], 'fill', colors[i]);
        this.renderer.setStyle(points[i], 'fill-opacity', 0.75);
      }
    }
  }
  goToTaskList(action) {
    if (action === 'overdue') {
      this.router.navigateByUrl('/schedule?mode=overdue');
    }
    else if (action === 'todo') {
      this.router.navigateByUrl('/schedule?mode=todo');
    }
  }

  openModal(template: any) {
    this.openUrlModal();
    this.openModalRef = this.modalService.show(template, { ignoreBackdropClick: true, keyboard: false, class: 'projects modal-lg' });
  }
  openUrlModal() {
    this.projectIdList = [];
    this.relatedProjects.forEach((element) => {
      this.projectIdList.push(element.id);
    });

    this.projectService.GetBulkProjectUrls(this.projectIdList).subscribe(data => {
      this.listUrls = data;
    });
    this.settingsService.getSurveyLinkUrls().subscribe(urls => {
      this.redirectUrls = urls;
    });
  }
  copyAllUrls() {
    const numberOfVendors = this.listUrls.length;
    let totalString = 'Redirects\n\n' +
      'Complete  = ' + this.redirectUrls.completeRedirectURL + '\n' +
      'Terminate = ' + this.redirectUrls.terminateRedirectURL + '\n' +
      'Overquota = ' + this.redirectUrls.overQuotaRedirectURL + '\n' +
      'QC        = ' + this.redirectUrls.qcRedirectURL;

    let vendor;
    let projectName;
    let url;

    for (let i = 0; i < numberOfVendors; i++) {
      vendor = this.listUrls[i].vendor;
      const numberOfUrls = this.listUrls[i].urls.length;
      totalString = totalString.concat('\n\n' + vendor + '\n\n');

      for (let x = 0; x < numberOfUrls; x++) {
        projectName = this.listUrls[i].urls[x].projectName;
        url = this.listUrls[i].urls[x].surveyEntryUrl;
        totalString = totalString.concat(projectName.concat(' = ' + url + '\n'));
      }
    }
    this.utilsService.copyTextToBuffer(totalString);
    this.alertify.success('All URLs are copied');
  }
  copyRedirectUrls() {
    const totalString = 'Redirects\n\n' +
      'Complete  = ' + this.redirectUrls.completeRedirectURL + '\n' +
      'Terminate = ' + this.redirectUrls.terminateRedirectURL + '\n' +
      'Overquota = ' + this.redirectUrls.overQuotaRedirectURL + '\n' +
      'QC        = ' + this.redirectUrls.qcRedirectURL;

    this.utilsService.copyTextToBuffer(totalString);
    this.alertify.success('Redirect URLs are copied');
  }
  copyProjectsUrls(item) {
    const numberOfUrls = item.urls.length;
    let totalString = '';
    const vendor = item.vendor;
    let projectName;
    let url;

    totalString = totalString.concat(vendor + '\n\n');
    for (let i = 0; i < numberOfUrls; i++) {
      projectName = item.urls[i].projectName;
      url = item.urls[i].surveyEntryUrl;
      totalString = totalString.concat(projectName.concat('\n' + url + '\n\n'));
    }

    this.utilsService.copyTextToBuffer(totalString);
    this.alertify.success('Project URLs copied');
  }

  downloadFulldata() { 
  
    this.alertify.message('Your file will download once ready');
    this.projectIdList = [];
    let projectCode = ''
    this.relatedProjects.forEach((element) => {
      projectCode=element.projectCode;
      this.projectIdList.push(element.id);
    });
   
    let filename =  'Full'+ '-' + projectCode + '-' +formatDate(new Date(), 'MM-dd-yyyy HH:mm:ss', 'en').toString() +'.xlsx';
      this.exportFilesService.fullReport(this.projectIdList,filename,'').subscribe(url => {
        window.location.assign(url);
      }, error => {
        this.alertify.error(error);
      }, () => {
        // All Good
      });
   
  }

  bulkDLParticipantExport() {
    this.projectIdList = [];
    this.relatedProjects.forEach((element) => {
      this.projectIdList.push(element.id);
    });

    this.alertify.message('Your file will download once ready');
    const filename = formatDate(new Date(), 'MM-dd-yyyy', 'en').toString() + '.xlsx';

    if (this.auth.isVendor()) {
      this.exportFilesService.downloadVendorParticipantData(this.projectIdList, filename, this.auth.getUser().vendorId).subscribe(url => {
        window.location.assign(url);
        this.projectIdList = [];
      }, error => {
        this.alertify.error(error);
      }, () => {
      });
    } else {
      this.exportFilesService.downloadParticipantData(this.projectIdList, filename, false).subscribe(url => {
        window.location.assign(url);
        this.projectIdList = [];
      }, error => {
        this.alertify.error(error);
      }, () => {
      });
    }
  }

  showSideSection(section) {
    this.currentSideSection = section;
    this.sidebarSectionOpen = true;
    this.projectDataService.setCurrentProject({
      bidNumber: this.currentFamily.bidNumber,
      projectType: {
        showNotes: true,
        showDownload: true
      },
      sidebarSectionOpen: true,
      projectOpen: false,
      projectSummaryOpen: true
    });
  }
  closeSideBar() {
    // send event to change navbar
    this.sidebarSectionOpen = false;
    this.projectDataService.setCurrentProject({
      bidNumber: this.currentFamily.bidNumber,
      projectType: {
        showNotes: true,
        showDownload: true
      },
      sidebarSectionOpen: false,
      projectOpen: false,
      projectSummaryOpen: true
    });
  }
  cardResize(mode, card) { card.mode = mode; }
  notesResize(mode) { this.cardNotes.expanded = (mode === 'expanded'); }

  handleSettingsChange() { this.project = { ...this.project }; }
  infoCallback(event) {
    if (event == 'auditLog')
      this.showSubsection('audit');
  }
  showSubsection(section) { this.subsection = section; }

  sortRelatedList(): void {
    this.relatedProjects.sort((a, b) => this.compareProjectCodes(a.projectCode, b.projectCode));
  }
  compareProjectCodes(codeA: string, codeB: string): number {
    // Splitting the codes into prefix and number parts
    const [prefixA, numberA] = codeA.split('-');
    const [prefixB, numberB] = codeB.split('-');

    // First, compare prefixes alphabetically
    if (prefixA !== prefixB) {
      return prefixA.localeCompare(prefixB);
    }

    // If prefixes are equal, compare numbers as integers
    const numA = parseInt(numberA);
    const numB = parseInt(numberB);

    return numA - numB;
  }

  openSendEmail(modalTemplate) {
    this.openModalRef = this.modalService.show(modalTemplate, { ignoreBackdropClick: true, keyboard: false, class: 'modal-md' });
  }

  async previewReport(modalTemplate) {
    try {
      this.reportLoading = true
      this.alertify.success("Report is loading")

      this.cleanIdPreviewReport = this.sanitizer.bypassSecurityTrustHtml(await this.cleanidService.GetPreviewFamilyReportEmail(this.currentFamily.bidNumber));
      this.openModalRef = this.modalService.show(modalTemplate, { ignoreBackdropClick: true, keyboard: false, class: 'modal-lg' });
      
      this.reportLoading = false
    } catch (error) {
      this.alertify.error("There was an error when loading the Report")
      console.log("Error: ", error)
      this.reportLoading = false;
    }
  }

  closeSendEmail() {
    this.openModalRef.hide();
    this.submitted = false;
    this.emailTo = '';
  }
  
  sendReport(form) {
    this.submitted = true;

    if (!form.valid) {
      this.alertify.error('Please use a valid email');
      return;
    //} else if (this.emailTo === undefined || this.emailTo === null || this.emailTo === "") {
    } else if (this.emailTo === "") {
      this.emailTo = '';
      this.alertify.error("Please type in a valid email!");
      form.valid = false;
      return;
    }

    this.cleanidService.SendFamilyReportByEmail(this.currentFamily.bidNumber, this.emailTo).subscribe(result => {
      if (result) {
        this.alertify.success('CleanID Report sent succesfully!');
        this.relatedProjects.forEach(project => {
          project.cleanIdReportEmailedOnce = true;
        });
        this.closeSendEmail();
        form.reset();
      }
    }, error => {
      this.alertify.error('Failed to Send CleanID Report');
    });
  }

}
