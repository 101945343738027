import { Component, OnInit, Input, Output, OnDestroy, EventEmitter } from '@angular/core';
import { AlertifyService, AuthService, ResponseIdService } from 'core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ResponseIdSettingsComponent } from '../../response-id/response-id-settings.component';
import { interval } from 'rxjs';

declare var google;

@Component({
    selector: 'app-project-card-response-id',
    templateUrl: './project-card-response-id.component.html',
    styleUrls: ['./project-card-response-id.component.scss'],
    providers: [ResponseIdSettingsComponent]
})
export class ProjectCardResponseIdComponent implements OnInit, OnDestroy {

    @Input() public set project(data) {
        this._project = data;
        this.getBatch();
        this.s3buckPath = "responseid/" + this._project.id;
    }

    @Input() projectType;
    @Input() mode;
    @Output() cardresize = new EventEmitter();

    FileSaver = require('file-saver');
    _project;
    batch;   
    actualUploadedFileName: string;
    showUpload = false;
    uploadFileExist = false;
    largeFile=false;
    percentage: number;
    newQuestionCount: number;
    newRespondentCount: number;
    totalRespondentCount: number;
    uploadedFile: string;
    file: string
    bsModalRef: BsModalRef;
    timer;
    metrics;
    s3buckPath: string;
    options = {
        legend: { textStyle: { fontSize: 14, color: 'black' }, alignment: 'center', position: 'left' },
        backgroundColor: 'transparent',
        tooltip: { isHtml: true },
        pieHole: 0.4,
        pieSliceTextStyle: {
            color: 'black',
            fontSize: 12
        },
        chartArea: {
            top: '5%',
            right: '5%',
            bottom: '5%',
            left: '5%',
            width: '100%',
            height: '100%',
            backgroundColor: '#f0f0f0'
        },
        slices: {
            0: { color: 'red' },
            1: { color: 'orange' },
            2: { color: 'yellow' },
            3: { color: 'green' },
        }
    };

    constructor(private modalService: BsModalService,
        private responseIdService: ResponseIdService,
        private alertify: AlertifyService,
        public auth: AuthService) { }

    ngOnInit() {
        this.getMetricsAndDrawChart();
    }

    getMetricsAndDrawChart() {
        this.responseIdService.GetResponseIdMetrics(this._project.id).subscribe((res) => {        
            this.metrics = res;
          
            this.totalRespondentCount=res.criticalCount+res.highCount+res.mediumCount+res.lowCount;
            if (this.batch?.status.toLowerCase() == 'complete') {
                this.cardresize.emit('expanded');
                this.drawChart();
            }
        }, (err) => {
            this.alertify.error('Unable to get ResponseID Metrics');
        });
    }

    drawChart() {
        google.charts.load('current', { packages: ['corechart'] });
        google.charts.setOnLoadCallback(() => {
            const chartData = google.visualization.arrayToDataTable([
                ['Categorization', 'Respondents'],
                ['Critical', this.metrics.criticalCount],
                ['High', this.metrics.highCount],
                ['Medium', this.metrics.mediumCount],
                ['Low', this.metrics.lowCount]
            ]);
            setTimeout(() => {
                const chartDiv = document.getElementById('rid-chart');
                const chart = new google.visualization.PieChart(chartDiv);
                chart.draw(chartData, this.options);
            }, 500);
        });
    }

  setTimer() {
    if (!this.timer) {
      this.timer = interval(60000)
        .subscribe(res => {
          this.getBatch();
        });
    }
  }

    ngOnDestroy(): void {
        this.timer?.unsubscribe();
    }

    download() {        
        let t=this._project;
        this.alertify.message('Your download will start when the report is available');
        const fileName = this._project.projectName.replace('&','').replace(' ', '') + '-results.csv';
        let email= this.auth.getUser().email;       
        let name= this.auth.getUser().firstName+' '+this.auth.getUser().lastName;       
        if(this.totalRespondentCount>=1000)
        {
            this.largeFile=true;         
        }

    this.responseIdService.GetResponseIDFile(this._project.id, fileName,email,name,this.totalRespondentCount).subscribe(url => {
 
    if(url!='LargeFile')
    {
        let downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = fileName;
    
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
    }, error => {
      this.alertify.error('There was an error generating the report.');
    });
  }

    public openModal() {
      if(this.showUpload==false){
        const initialState = {
            project: this._project,
            newQuestionCount: this.newQuestionCount,
            newRespondentCount: this.newRespondentCount,
            uploadFileExist: this.uploadFileExist
        };

        this.bsModalRef = this.modalService.show(ResponseIdSettingsComponent, { ignoreBackdropClick: true, keyboard: false, class: 'modal-full', initialState });
        this.bsModalRef.content.saveevent.subscribe((data) => {
            this.uploadFileExist = false;
            this.getBatch();
        })

        this.bsModalRef.content.cancelevent.subscribe((data) => {
            this.uploadFileExist = data;
        })
      }
    }

    handleFileInput(files: FileList) {   
      this.actualUploadedFileName = files.item(0).name;
    }

    replaceAll(str, find, replace) {
        var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return str.replace(new RegExp(escapedFind, 'g'), replace);
    }

    validateBatch() {
        if (this.uploadedFile !== null && this.uploadedFile !== '') {
            const fileName = this.uploadedFile.substring(this.uploadedFile.lastIndexOf('/') + 1);
            const url = this.replaceAll(this.uploadedFile, '/', '@');
            this.responseIdService.ValidateBatch(this._project.id, fileName, this.actualUploadedFileName, url).subscribe((data) => {
                if (data) {
                    this._project.errorMessage = null;
                    this._project.settings = data.settings;
                    this._project.batchId = data.batchId;
                    this._project.newRespondentCount = data.newRespondentCount;                   
                    this._project.newQuestionCount = data.newQuestionCount;
                    var piiKeyLen = data.settings.piiKeywords.length;
                    if (data.newQuestionCount > 0 || data.newRespondentCount > 0) {
                        this.newQuestionCount = data.newQuestionCount;
                        this.newRespondentCount = data.newRespondentCount;                       
                        this.uploadFileExist = true;
                        this.showUpload = false;
                        this.openModal();
                    } else if (piiKeyLen > 0) {
                            this.alertify.confirm("PII Variable Identified", 'A keyword indicating potential PII has been identified within a variable name: ' + data.settings.piiKeywords + '.  Please ensure all PII is removed from the file before uploading!', this.reset);

                        } else {
                            this.uploadFileExist = true;
                            this.showUpload = false;
                            this.openModal();
                        }

                }
            }, error => {
                this.uploadFileExist = false;
                this._project.errorMessage = error;
                this.showUpload = false;
                //  this.alertify.error(error);
                this.openModal();
            });
        }
    }

    resetOpenModel() {

    }


    reset() {
    }

    Upload() {
        this.showUpload = true;
        this.uploadFileExist = true;
    }

    cancel() {
        this.showUpload = false;
        this.uploadFileExist = false;
        this.getBatch();
    }

    getBatch() {
        this.responseIdService.GetBatchForProject(this._project.id).subscribe((data) => {         
            this.batch = data;
            this.showUpload = this.batch?.status == 'Reset';
        }, error => {
            console.error(error);
        }, () => {
            if (this.batch != null) {
                if (this.batch.status.toLowerCase() == 'complete') {
                    this.getMetricsAndDrawChart();
                }
                if (this.batch.status.toLowerCase() == 'queued' || this.batch.status.toLowerCase() == 'in progress') {
                    this.percentage = (this.batch.totalRecords === 0) ? 0 : Math.round((this.batch.totalCompletes * 100) / this.batch.totalRecords);
                    this.setTimer();
                } else {
                    this.timer?.unsubscribe();
                }
            }
        });
    }
}
