import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {AuthService,ProjectDataService} from 'core';
//
@Component({
  selector: 'app-project-card-infield',
  templateUrl: './project-card-infield.component.html',
  styleUrls: ['./project-card-infield.component.scss']
})
export class ProjectCardInfieldComponent implements OnInit {

  @Input() public set project(data) {
    this._project = data;
    if (this._project.projectManager)
      this.projectManagerNames.push({ name: this._project.projectManager, type: 'pm' });

   
    this._project?.secondPm?.map(e => this.projectManagerNames.push({ name: e, type: 'secondPm' }));
    this._project?.accountOwner?.map(e => this.projectManagerNames.push({ name: e.name, type: 'accountOwner' }));
  }

  @Output() selected = new EventEmitter();

  stagePercentageComplete = null;
  projectManagerNames = [];
  _project:any = {};
  statusVisible = true;
  pageName: string='NotSet';
  constructor(
    public auth: AuthService,
    private projectDataService: ProjectDataService
  ) {}

  ngOnInit() {
    this.projectDataService.getPageName.subscribe(data => {
      if (data == 'ProjectPerformance') {
        this.pageName='ProjectPerformance';
      }
    });
  }
  ngAfterViewInit(){
    if(this.pageName === 'ProjectPerformance' && this._project.projectStatus === 'Invoiced') {
      this.statusVisible = false;
    }
  }
  projectClicked(project) { this.selected.emit(project); }

  getProgressStyle() {
    if (this._project.projectStatus === 'Closed' || this._project.projectStatus === 'Invoiced') {
      return 'nav-bg-accent-blue';
    } else if (this._project.projectStatus === 'Test' || this._project.projectStatus === 'Paused') {
      return 'or-darkgray-bg';
    } else if (this._project.percentageComplete < (100 - this._project.percentageDaysLeft)) {
      return 'nav-bg-error';
    } else return '';
  }

  // TO DO: GET DIFFERENT ICONS FOR HEALTH
  getHealthIcon() {
    if (this._project.health <= 3) {
      return 'far fa-lg fa-check-circle text-success';
    } else if (this._project.health <= 5) {
      return 'far fa-lg fa-check-circle text-warning';
    } else if (this._project.health <= 8) {
      return 'fas fa-lg fa-exclamation-triangle text-warning';
    } else {
      return 'fas fa-lg fa-exclamation-triangle text-danger';
    }
  }
}
