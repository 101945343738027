
<div class="card mb-3" [ngClass]="group.formMode == 'setup' ? 'shadow' : ''">
  <div class="card-header nav-secondary  py-2">
    <div class="row">
      <div class="text-left px-2 col-4" > <!--[ngClass]="group.formMode=='setup' ? 'col-5' : 'col-4'"-->
        <span 
          *ngIf="group.formMode == 'setup' && !newQuota"
          tabindex="-1" class="p-0 btn m-0 mr-2 " 
          (click)="removeQuotaGroup()">
          <i class=" fak fa-trash fa-sm nav-error cursor-pointer grey4"></i>
        </span>
        {{group.name}}
      </div>
      <div class="col-5 text-center"> <!--[ngClass]="group.formMode=='setup' ? 'col-4' : 'col-5'"-->
        <ng-container *ngIf="limitCheck != 'tracking only'">
          <ng-container *ngIf="group.formMode != 'setup'">
            {{limitMode | titlecase}}s
          </ng-container>
          <ng-container *ngIf="group.formMode == 'setup'">
            {{limitType == 'percent' ? '#' : '%' }} of demographic
          </ng-container>
        </ng-container>
      </div>
      <div class="col-3 text-center">
        {{limitCheck != 'tracking only' ? 'Limit' : ''}} <!-- (limitType == 'percent' ? '%' : 'n') -->
      </div>
    </div>
  </div>
  <div class="card-body pb-2">
    <ng-container *ngFor="let quota of group.quotas; let i = index ">
      <ng-container *ngIf="quota.isDeleted == false" class="">
          <div 
           (click)="enableEdit(quota)"
           [class.inlineEdit]="quota.edit == true"
            class="row quotaRow pt-2 clickable">
            <div class="colHeight24 col-4 text-nowrap quotaNameCol px-2 clickable"
              [class.text-truncate]="questions[group.questionId].type != 'HouseholdIncome' && questions[group.questionId].type != 'Age' && ((limitCheck == 'tracking only' && group.formMode != 'setup') || limitCheck != 'tracking only')"> <!--[ngClass]="group.formMode == 'setup' ? 'col-5' : 'col-4'"-->
              <span *ngIf="group.formMode == 'setup'" tabindex="-1" class="p-0 btn m-0 mr-2" (click)="removeQuota(group, quota.id)">
                <i class=" fa fa-times fa-sm cursor-pointer grey4"></i>
              </span>
              <ng-container *ngIf="quota.targetGroup[questions[group.questionId].type.toLowerCase()] as value">
                <span class="clickable" [class.font-italic]="value === 'pnta'">{{ value === 'pnta' ? 'Prefer not to answer' : value}}</span>
              </ng-container>

              <!-- <div class="col-4"  *ngIf="questions[q].type == 'State'">
                <div class="d-flex align-items-center ">
                  <div>
                    <or-type-ahead [multi]="true" [dataCallback]="getStates" (valueChange)="updateQuotaStates(quota, $event)" [selectedValues]="quota.targetGroup.states"></or-type-ahead>
                  </div>
                </div>
              </div> -->
              <ng-container *ngIf="questions[group.questionId].type == 'HouseholdIncome'"> 
                <ng-container *ngIf="quota.targetGroup.minHHI > -1">
                  <div class="d-inline">
                    <div class="d-inline" *ngIf="quota.edit != true && group.formMode != 'setup'">
                     <div class="d-inline">
                       <u class="clickable">{{ quota.targetGroup.minHHI |  currency :'USD':'symbol':'1.0-0'}}</u>
                     </div>
                     <div class="mx-1 mt-1 d-inline"> to </div>
                     <div class="d-inline">
                       <u class="clickable" *ngIf="quota.targetGroup.maxHHI <= 150000">{{ quota.targetGroup.maxHHI |  currency :'USD':'symbol':'1.0-0'}}</u>
                       <u class="clickable" *ngIf="quota.targetGroup.maxHHI > 150000">and above</u>

                     </div>
                    </div>

                   <ng-container *ngIf="quota.edit == true || group.formMode == 'setup'">
                     <div class="d-inline">
                       <select 
                         (change)="minChanged(quota, group, quota.targetGroup)"
                         class=" form-control form-control-sm form-control-nav nav-font12 hhiSelect inlineInput clickable d-inline" 
                         [(ngModel)]="quota.targetGroup.minHHI">
                         <option  [ngValue]="0"> {{0 | currency :'USD':'symbol':'1.0-0' }}</option>
                         <ng-container *ngFor="let n of hhiList">
                           <option [ngValue]="n">{{n/1000 | currency :'USD':'symbol':'1.0-1' }}k</option>
                         </ng-container>
                       </select>
                     </div>
                     <div  class="mr-2 ml-2 mt-1 d-inline"> to </div>
                     <div class="d-inline">
                       <select 
                         class=" form-control form-control-sm form-control-nav nav-font12 hhiSelect hhiInlineInput clickable d-inline" 
                         [(ngModel)]="quota.targetGroup.maxHHI"
                         (change)="quota.changesMade = true;">                      
                         <ng-container *ngFor="let n of hhiList" >
                           <option  *ngIf="quota.targetGroup.minHHI < n-1" [ngValue]="n-1">{{n-1 | currency :'USD':'symbol':'1.0-0' }}</option>
                         </ng-container>
                         <option [ngValue]="hhiMax+1">and above</option>
                       </select>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-container *ngIf="quota.targetGroup.minHHI == -1">
                  <div class="d-inline font-italic clickable">
                    Prefer not to answer
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="group.name == 'Age'">
                <div class="d-inline">
                  <div class="d-inline" *ngIf="quota.edit != true && group.formMode != 'setup'">
                    <div class="d-inline">
                      <u class="clickable">{{ quota.targetGroup.minAge || 0}}</u>
                    </div>
                    <div class="mx-1 mt-1 d-inline"> to </div>
                    <div class="d-inline">
                      <u class="clickable">{{ quota.targetGroup.maxAge || 0}}</u>
                    </div>
                  </div>
                  <ng-container *ngIf="quota.edit == true || group.formMode == 'setup'">
                    <div class="d-inline">
                      <input #inputMin
                        type="text" 
                        mask="999" 
                        [specialCharacters]="[]" 
                        class="text-left form-control form-control-nav form-control-sm ageInlineInput clickable d-inline" 
                        [(ngModel)]="quota.targetGroup.minAge"
                        (keyup.enter)="inputMin.blur()" 
                        (blur)="minChanged(quota, group, quota.targetGroup)">
                    </div>
                    <div class="mr-2 ml-2 mt-1 d-inline"> to </div>
                    <div class="d-inline">
                      <input #inputMax
                        type="text" 
                        mask="999" 
                        [specialCharacters]="[]" 
                        class="text-left form-control form-control-nav form-control-sm ageInlineInput clickable d-inline" 
                        [(ngModel)]="quota.targetGroup.maxAge"
                        (keyup.enter)="inputMax.blur()"
                        (blur)="quota.changesMade = true;">
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
            <ng-container *ngIf="limitType == 'percent'">
              <div class="col-5 clickable" > <!--[ngClass]="group.formMode == 'setup' ? 'col-4' : 'col-5'"-->
                  <div class="rule clickable" *ngIf="group.formMode != 'setup'">
                    <!-- question progress guage -->
                     <!-- {{quota.completes}} {{group.completes}} {{quota.limit}} -->
                    <ng-container *ngIf="limitCheck == 'tracking' || limitCheck == 'enforced'">
                      <ng-container *ngIf="quota.limit < 99999">
                        <ng-container *ngIf="limitMode == 'start'">
                          <or-progress-guage [value]="(quota.starts * 100) / quota.limitN" [expected]="daysPercentagePast" [bgColor]="getBgColor((quota.starts * 100) / quota.limitN)" [bgDanger]="getBgColor((quota.starts * 100) / quota.limitN)" mode="quota"></or-progress-guage>
                        </ng-container>
                        <ng-container *ngIf="limitMode == 'complete'">
                          <or-progress-guage [value]="(quota.completes * 100) / quota.limitN" [expected]="daysPercentagePast" [bgColor]="getBgColor((quota.completes * 100) / quota.limitN)" [bgDanger]="getBgColor((quota.completes * 100) / quota.limitN)" mode="quota"></or-progress-guage>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="quota.limit >= 99999">
                        <ng-container *ngIf=" limitMode == 'start'">
                          <or-progress-guage [value]="(quota.starts * 100) / group.starts" bgColor="'nav-success'" mode="quota"></or-progress-guage>
                        </ng-container>
                        <ng-container *ngIf=" limitMode == 'complete'">
                          <or-progress-guage [value]="(quota.completes * 100) / group.completes" bgColor="'nav-success'"  mode="quota"></or-progress-guage>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  
                    <ng-container *ngIf="limitCheck == 'tracking only'">
                      <ng-container *ngIf="limitMode == 'start'">
                        <or-progress-guage [value]="(quota.starts * 100) / group.starts"  [bgColor]="'nav-success'"  mode="quota"></or-progress-guage>
                      </ng-container>
                      <ng-container *ngIf="limitMode == 'complete'">
                        <or-progress-guage [value]="(quota.completes * 100) / group.completes"  [bgColor]="'nav-success'"  mode="quota"></or-progress-guage>
                      </ng-container>
                    </ng-container>

                  </div>
                  <div class="text-center clickable" *ngIf="group.formMode == 'setup' && limitCheck != 'tracking only'">
                    {{(quota.limitN >= 99999 ? '*' : quota.limitN) || 0}} 

                  </div>
              </div>

              <div 
                class="pl-0 colHeight24 col-3 clickable"
                [ngClass]="limitCheck == 'tracking only' ? 'text-center' : 'text-right'">
                <!-- enforced and tracking -->
                <div class="clickable"
                  *ngIf="(limitCheck == 'enforced' || limitCheck == 'tracking') && quota.edit != true && group.formMode != 'setup'"  
                 >
                  <ng-container *ngIf="limitMode == 'start'   ">
                    <div class="d-inline">{{quota.limit < 99999 ? ((quota.starts * 100 / fullLaunchQuota || 0)  | number: '1.0-0') : (quota.starts * 100 / group.starts | number: '1.0-0') }}/</div>
                    <span  class="editable clickable">{{ quota.limit >= 99999 ? "*" : quota.limit || 0 }} % </span>
                  </ng-container>
                  <ng-container *ngIf="limitMode == 'complete'   ">
                    <div class="d-inline">{{quota.limit < 99999 ? ((quota.completes * 100 / fullLaunchQuota || 0)  | number: '1.0-0') : (quota.completes * 100 / group.completes | number: '1.0-0') }}/</div>
                    <span class="editable clickable">{{ quota.limit >= 99999 ? "*" : quota.limit || 0 }} % </span>
                  </ng-container>

                </div>

                <!-- tracking only -->
                <div class="clickable"
                  *ngIf="limitCheck == 'tracking only' && quota.edit != true  && group.formMode != 'setup'">
                  <ng-container *ngIf="limitMode == 'start'   ">
                    <span class="clickable">{{ (quota.starts / group.starts || 0) | percent }}</span>
                  </ng-container>
                  <ng-container *ngIf="limitMode == 'complete'   ">
                    <span  class="clickable">{{ (quota.completes / group.completes || 0) | percent}}</span>
                  </ng-container>
                </div>
              
                <!-- inline edit -->
                <div  class="pl-1 d-inline clickable" *ngIf="(quota.edit == true || group.formMode == 'setup') && limitCheck != 'tracking only'">
                  <ng-container *ngIf="!newQuota">
                    <span class="clickable" *ngIf="limitMode === 'start'">{{quota.limit < 99999 ? ((quota.starts * 100 / fullLaunchQuota || 0)  | number: '1.0-0') : (quota.starts * 100 / group.starts | number: '1.0-0') }}/ </span>
                    <span class="clickable" *ngIf="limitMode === 'complete'">{{quota.limit < 99999 ? ((quota.completes * 100 / fullLaunchQuota || 0)  | number: '1.0-0') : (quota.completes * 100 / group.completes | number: '1.0-0') }}/ </span>
                  </ng-container>
                  <input #inputLimit (keyup.enter)="inputLimit.blur()" (blur)="handleLimitChange(quota, '%', null, $event)" type="text"
                    class="form-control form-control-nav form-control-sm d-inline nav-font14 inlineInput clickable"
                    oninput="this.value = this.value.replace(/[^0-9*.]/g, '').replace(/(\..*)\./g, '$1');"
                    [value]="quota.limitPercent.toString() ? ( quota.limitPercent < 99999 ?  quota.limitPercent  : '*') : ''"
                    >
                  <span class="pl-1">%</span>
                </div>
              </div>
            </ng-container>
                
            <ng-container *ngIf="limitType == 'number'">  
              <div class="col-5 clickable">
                <div *ngIf="group.formMode != 'setup'">
                  <div class="rule clickable">
                    <!-- question progress guage -->
                    <ng-container *ngIf="limitCheck == 'tracking' || limitCheck == 'enforced'">
                      <ng-container *ngIf="quota.limit < 99999">
                        <ng-container *ngIf=" limitMode == 'start'">
                          <or-progress-guage [value]="(quota.starts * 100)/ quota.limitN" [expected]="daysPercentagePast" [bgColor]="getBgColor((quota.starts * 100)/ quota.limitN)" [bgDanger]="getBgColor((quota.starts * 100)/ quota.limitN)" mode="quota"></or-progress-guage>
                        </ng-container>
                        <ng-container *ngIf=" limitMode == 'complete'">
                          <or-progress-guage [value]="(quota.completes * 100) / quota.limitN" [expected]="daysPercentagePast" [bgColor]="getBgColor((quota.completes * 100) / quota.limitN)" [bgDanger]="getBgColor((quota.completes * 100) / quota.limitN)" mode="quota"></or-progress-guage>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="quota.limit >= 99999">
                        <ng-container *ngIf=" limitMode == 'start'">
                          <or-progress-guage [value]="(quota.starts * 100) / group.starts" bgColor="'nav-success'" mode="quota"></or-progress-guage>
                        </ng-container>
                        <ng-container *ngIf=" limitMode == 'complete'">
                          <or-progress-guage [value]="(quota.completes * 100) / group.completes" bgColor="'nav-success'" mode="quota"></or-progress-guage>
                        </ng-container>
                      </ng-container>
                    </ng-container>

             
                  </div>
                  <!-- <or-progress-guage class="" [value]="(( limitMode == 'complete' ? quota.completes : quota.starts) * 100) / quota.limitN" [expected]="100"></or-progress-guage> -->
                </div>
                <div class="text-center clickable" *ngIf="group.formMode == 'setup' && limitCheck != 'tracking only'">
                  {{(quota.limitPercent >= 99999 ? '*' : quota.limitPercent) || 0}} 

  
                </div>
              </div>

              <div  class="col-3 text-right text-nowrap colHeight24 clickable">

                <!-- tracking and enforced -->
                <div 
                  class="clickable"
                  *ngIf="limitCheck != 'tracking only' && quota.edit != true && group.formMode != 'setup'">
                  <ng-container *ngIf=" limitMode == 'start'   ">
                    <div class="d-inline clickable">{{ quota.starts}}/</div>
                    <span class="editable clickable" >{{ quota.limitN >= 99999 ? "*" : quota.limitN || 0 }} n</span>
                  </ng-container>
                  <ng-container *ngIf=" limitMode == 'complete'   ">
                    <div class="d-inline clickable">{{ quota.completes}}/</div>
                    <span class="editable clickable">{{ quota.limitN >= 99999 ? "*" : quota.limitN || 0 }} n</span>                  
                  </ng-container>
                </div>

                <!-- inline edit -->
                <div  class="pl-1 d-inline clickable" *ngIf="(quota.edit == true || group.formMode == 'setup') && limitCheck != 'tracking only'">
                  <span class="clickable" *ngIf="!newQuota">{{limitMode === 'start' ? quota.starts : quota.completes}}/ </span>
                  <input #inputLimit (keyup.enter)="inputLimit.blur()" (blur)="handleLimitChange(quota, 'n', null, $event)" type="text"
                    class="form-control form-control-nav form-control-sm d-inline nav-font14 inlineInput clickable"
                    oninput="this.value = this.value.replace(/[^0-9*.]/g, '').replace(/(\..*)\./g, '$1');"
                    [value]="quota.limitN?.toString() ? ( quota.limitN < 99999 ?  quota.limitN  : '*') : 0"                    >
                  <span class="pl-1">n</span>
                </div>
              </div>
            </ng-container> 
          </div>
          
      </ng-container>
    </ng-container>

    <div *ngIf="group.nonValueStarts" class="row quotaRow pt-2" >
      <div class="colHeight24 col-4 text-nowrap quotaNameCol px-2 " >
        Other
      </div>
          
        <div class="col-5"></div>

        <div  class="col-3 text-nowrap colHeight24" [ngClass]="limitCheck === 'tracking only' ? 'text-center pl-0' : 'text-right'">
          <div class="">
              <ng-container *ngIf="limitCheck != 'tracking only'">
                <ng-container *ngIf="limitType=='number'">
                  <div class="d-inline">{{ limitMode == 'complete' ? group.nonValueCompletes : group.nonValueStarts}} n</div>
                </ng-container>
                <ng-container *ngIf="limitType=='percent'">
                  <div class="d-inline">{{ (limitMode == 'complete' ? ((group.nonValueCompletes*100)/ fullLaunchQuota || 0)   : ((group.nonValueStarts*100)/ fullLaunchQuota || 0)) | number: '1.0-0' }} %</div>
                </ng-container>
              </ng-container>
             <ng-container *ngIf="limitCheck == 'tracking only'">
              <div class="d-inline">{{ (limitMode == 'complete' ? ((group.nonValueCompletes*100)/ group.completes || 0)   : ((group.nonValueStarts*100)/ group.starts || 0)) | number: '1.0-0' }} %</div>
             </ng-container>
          </div>
        </div>
    </div>


    <!-- last row: adding quotas and totals -->
    <div class="row totalRow mt-2 " [class.totalRowTall]="questions[group.questionId].preferNotToAnswer && !group.preferNotToAnswerAdded && group.deletedOptions.length > 0">
      <!-- adding quotas -->
      <div class="col-4 pl-0 text-nowrap mt-1" [style.visibility]="(auth.isClient() || auth.isVendor() || isVendorview) ? 'hidden' : 'visible'">
        <ng-container *ngIf="questions[group.questionId].type == 'HouseholdIncome'">
          <span tabindex="-1" class="font-weight-bold nav-accent-blue p-0 pl-2 pr-2 clickable" type="button" (click)="addBlankQuota(group, 'HouseholdIncome')">+ &nbsp; Add income range</span>
        </ng-container>
        <ng-container *ngIf="questions[group.questionId].type == 'Age'">
          <span tabindex="-1" class="font-weight-bold nav-accent-blue p-0 pl-2 clickable " type="button" (click)="addBlankQuota(group, 'Age')">+ &nbsp; Add age range</span>
        </ng-container>
        <ng-container *ngIf="group.name !== 'Income' && group.name !== 'Age'">
          <span *ngIf="group.deletedOptions?.length > 0" class="font-weight-bold nav-accent-blue nav-font14 clickable p-0 pl-2">
            <or-dropdown class="clickable" [values]="group.deletedOptions" [text]="'+ &nbsp;&nbsp;Add'" [showBtn]="false" [addClickableClass]="true" (selected)="addBackQuota(group, $event)">
              <div class="p-5  clickable"></div>
            </or-dropdown>
           </span>
        </ng-container>
        <ng-container *ngIf="questions[group.questionId].preferNotToAnswer && !group.preferNotToAnswerAdded">
          <br *ngIf="group.name === 'Income'">
          <span class="font-weight-bold nav-accent-blue nav-font14 cursor-pointer p-0 pl-2 clickable" (click)="addBackPreferNotToAnswer(group)">+ &nbsp; Prefer not to answer</span>
        </ng-container>
      </div>
      

      <!-- total -->
      <ng-container *ngIf="limitCheck != 'tracking only'">
        <div class="text-right grey5 text-nowrap mt-1" *ngIf="group.formMode != 'setup'" [ngClass]="group.formMode != 'setup' ? 'col-6' : 'col-5'">
          Total
        </div>
        <!-- <div class="col-5 text-right grey5" *ngIf="group.formMode != 'setup'">
          Total
        </div> -->
        <ng-container *ngIf="group.formMode == 'setup'">
          <div class="col-5 text-center nav-black1 pr-0">
            <hr class="grey1 m-0 ml-5 mb-1">
            <span class="float-left grey5 mb-1">Total</span>
            <span class="mr-5">
              <ng-container *ngIf="limitType=='percent'">
                {{quotaGroupTotals[group.id] ? quotaGroupTotals[group.id].n : ''}} n
              </ng-container>
              <ng-container *ngIf="limitType=='number'">
                {{quotaGroupTotals[group.id] ? quotaGroupTotals[group.id].percent : ''}} %
              </ng-container>        
            </span>
          </div>
        </ng-container>
       <ng-container>
        <div class="text-right nav-black1 pl-0" [ngClass]="group.formMode != 'setup' ? 'mt-1 col-2' : 'col-3'">
          <ng-container *ngIf="group.formMode == 'setup'">
            <hr class="grey1 m-0 mb-1">
          </ng-container>
          <ng-container *ngIf="limitType=='percent'">
            <ng-container *ngIf="!newQuota">
              {{limitMode=='complete' ? ((group.completes/fullLaunchQuota) *100 | number: '1.0-0') : ((group.starts/fullLaunchQuota)*100 | number: '1.0-0') }}/
            </ng-container>
              {{quotaGroupTotals[group.id] ? quotaGroupTotals[group.id].percent : ''}} %
          </ng-container>
          <ng-container *ngIf="limitType=='number'">
            <ng-container *ngIf="!newQuota">
              {{limitMode=='complete' ? group.completes : group.starts}}/
            </ng-container>
              {{quotaGroupTotals[group.id] ? quotaGroupTotals[group.id].n : ''}} n
          </ng-container>
        </div>
       </ng-container>
      </ng-container>
    </div>
  </div>
</div>
