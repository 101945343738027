<div class="card-body">
  <div class="row px-2 mb-4 nav-bg-primary">
    <div class="col-12">
      <div *ngIf="project.totalDaysLeft == 0 "><span class="font-weight-bold">ENDS TODAY</span></div>
      <div *ngIf="project.totalDaysLeft > 0 "> <span class="font-weight-bold">ENDS IN {{ project.totalDaysLeft }} DAYS</span></div>
      <div *ngIf="project.totalDaysLeft < 0 "> <span class="font-weight-bold">ENDED {{ project.totalDaysLeft  * -1 }} DAYS AGO</span></div>
    </div>
  </div>
  <div class="row">
    <!-- FOR NOW THESE ONLY WORK ON FIRST SEGMENT - WE WILL NEED A MULT SEGMENT APPROACH -->
    <div class="col-3">
      <h6><span class="font-weight-light" >IR</span> {{ project.ir }}%</h6>
    </div>
    <div class="col-3 px-0 text-center">
      <h6><span class="font-weight-light">LOI</span>  {{ project.loi }} min</h6>
    </div>
    <div class="col-3 px-0 text-center">
      <h6><span class="font-weight-light">Quota </span>  {{ project.fullLaunchQuota }}</h6>
    </div>
  </div>
</div>
