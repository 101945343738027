import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { AlertifyService, ProjectService, Project,ProjectSegment, SettingsService, ClientService, ClientContactService, DropdownService, UserService, AuthService } from 'core';
import { Router, ActivatedRoute, } from '@angular/router';
import { forkJoin } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { UtilsService } from 'core';
import { NgForm } from '@angular/forms';
import { UnsavedchangesGuardService } from '../../../_guards/unsavedchanges.guard.service';
import { SpinnerButtonComponent } from 'projects/core/src/components/spinner-button/spinner-button.component';

@Component({
  selector: 'app-project-setup',
  templateUrl: './project-setup.component.html',
  styleUrls: ['./project-setup.component.scss'],
  providers: [ProjectService]
})
export class ProjectSetupComponent implements OnInit, AfterViewInit {
  @Output() onSave = new EventEmitter();
  @Input() public set project(data: Project) {
    this._project = JSON.parse(JSON.stringify(data));
    if (this.mode === 'edit') this.projectReady();
    else this.loadData();
  }
  @Input() step = 1;
  @Input() litemode = false;

  _project;
  mode = 'new';
  types = [];
  tags = '';
  loading = true;
  defaultSettings;
  urlList;
  projectManager;
  projectTeam;
  accountOwner;
  quotaMode;
  partners;
  categoryChange = false;
  partnerConfirmDeletes = {};
  clients;
  clientContacts = [];
  clientLoading = false;
  userList;
  addContact = {};
  newContact = {};
  isNewProject: boolean;
  countries;
  loaded = false;
  hasChanges = false;
  projectType;
  projectTypes = [];
  projectTypesLoading = false;
  allCheck:boolean=false;

  integerPattern = { 9: { pattern: new RegExp('^[0-9]*$'), optional: true } };

  enabledSteps = 1;
  stepSubmitted = {};

  saving = false;

  @ViewChild('quickAddForm')
  private quickAddForm: NgForm;
  @ViewChild('step1Form')
  private step1Form: NgForm;

  private step2Form: NgForm;

  @ViewChild('spinner') spinner: SpinnerButtonComponent;
  @ViewChild('spinnerQuick') spinnerQuick: SpinnerButtonComponent;

  openModalRef: any;

  constructor(
    private projectService: ProjectService,
    private settingsService: SettingsService,
    private clientService: ClientService,
    private clientContactService: ClientContactService,
    private dropdownService: DropdownService,
    private userService: UserService,
    private alertify: AlertifyService,
    private route: ActivatedRoute,
    private router: Router,
    public auth: AuthService,
    public utils: UtilsService,
    private unsavedChangesService: UnsavedchangesGuardService) { }

  ngOnInit() { if (this._project == null) { this.loadData(); } }
  ngAfterViewInit(): void { this.unsavedChangesService.register(this, () => this.hasChanges); }

  loadData() {
    forkJoin([
      this.settingsService.getSurveySettings(''),
      this.settingsService.getSurveyLinkUrls(),
      this.dropdownService.getClients(),
      this.dropdownService.getPartners(),
      this.userService.GetUsers(),
      this.projectService.GetProjectTypes(null, true),
    ]).subscribe((responseList) => {
      this.defaultSettings = responseList[0];
      this.urlList = responseList[1];
      this.clients = responseList[2];
      this.partners = responseList[3];
      this.userList = responseList[4];
      this.projectTypes = responseList[5];

      const typeId = this._project?.projectTypeId ?? this.route.snapshot.params.typeId;
      this.projectType = responseList[5].find(e => e.id === typeId);
        this.mode = 'edit';
        this.enabledSteps = 99;
        this.projectReady();
    }, error => {
      this.alertify.error('There was a problem getting your latest settings.');
    });
  }
  addLiteModeDefaults() {
    this._project.quotaMode = 'Off';
    // TO DO: ANY OTHER DETAULTS
  }
  projectReady() {
    this.loading = false;
    if (this._project.projectEndDate != null) {
      this._project.projectEndDate = new Date(this._project.projectEndDate);
      this._project.projectEndDate.setHours(12, 0, 0, 0);
    }

    if (this._project.projectStartDate != null) {
      this._project.projectStartDate = new Date(this._project.projectStartDate);
      this._project.projectStartDate.setHours(12, 0, 0, 0);
    }
    if (this._project.fieldStartDate != null) {
      this._project.fieldStartDate = new Date(this._project.fieldStartDate);
      this._project.fieldStartDate.setHours(12, 0, 0, 0);
    }
    if (this._project.fieldEndDate != null) {
      this._project.fieldEndDate = new Date(this._project.fieldEndDate);
      this._project.fieldEndDate.setHours(12, 0, 0, 0);
    }

    if (this._project.clientId != null) {
      this._project.clientId = this._project.clientId.toLowerCase();
      this.getClientDetails(this._project.clientId);
    }

    this.tags = this._project.projectTags.map(e => e.tag).join(',');
    this.countries = this._project.countries.map(e => ({value: e, name: e }));

    // For now we have simple UI for PM and Secondary PM but future version will allow different roles
    this.projectManager = this._project.projectTeam.filter( e => e.projectRole === 'PM').map( e => ({id: e.userId, name: e.displayName}));
    this.projectTeam = this._project.projectTeam.filter( e => e.projectRole !== 'PM').map( e => ({id: e.userId, name: e.displayName}));
    this.quotaMode = (this._project.quotaMode === 'Off') ? false : true;
    this.accountOwner = (this._project.internalAccountOwnerId)
      ? this.userList.filter( e => e.id === this._project.internalAccountOwnerId).map( e => ({id: e.id, name: e.firstName + ' ' + e.lastName})) : null;
    this.loaded = true;
  }

  showStep(step) {
    this.stepSubmitted[this.step] = true;
    if (this.step === 1 && !this.step1Form.valid) {
      this.alertify.error('Please fill in all required fields');
      return;
    }
    this.step = step;
    if (this.step > this.enabledSteps) { this.enabledSteps = this.step; }
  }
  showCssValidField(step, field) {
    if (step !== this.step) { return; }

    if (step === 1 && this.stepSubmitted[step] === true && this.projectType === 'Quick Add') {
      return this.utils.showCssValidField(this.quickAddForm.form, field);
    } else if (step === 1 && this.stepSubmitted[step] === true) {
      return this.utils.showCssValidField(this.step1Form.form, field);
    } else if (step === 2 && this.stepSubmitted[step] === true) {
      return this.utils.showCssValidField(this.step2Form.form, field);
    }
  }

  getCountryList = (filter) => { return this.dropdownService.getCountries(); }
  getProjectTypeList() { return this.projectTypes.filter((item) => item.name !== 'Quick Add'); }
  setCountries($event) { this._project.countries = ($event == null || $event.length === 0 ) ? [] : $event.map(e => e.value); }
  saveProject() {

    if (!this.step1Form.valid) {
      this.alertify.error('Please fill in all required fields');
      this.spinner.showSpinner = false;
      return;
    }
    // Some data tidyup before saving
    this._project.projectTags = [];
    this.tags.split(',').forEach( e => {
      if (e !== '') { this._project.projectTags.push({ id: uuidv4(), tag: e }); }
    });
    this.saving = true;
    this._project.quotaMode = (this.quotaMode) ? 'Complete' : 'Off';
    this._project.internalAccountOwnerId = (this.accountOwner != null && this.accountOwner.length > 0) ? this.accountOwner[0].id : null;

    // If we only have one segment we copy the status and quotas from Project (currently always the case)
    if (this._project.projectSegments.length === 1) {
      this._project.projectSegments[0].segmentStatus = this._project.projectStatus;
      this._project.projectSegments[0].fullSegmentQuota = this._project.fullLaunchQuota;
      this._project.projectSegments[0].softSegmentQuota = this._project.softLaunchQuota;
    }
    this._project.projectSegments.forEach(segment => {
      // Remove any Partners if not in edit mode as this is new project, we save with isDeleted value flag otherwise

      if (this.mode !== 'edit') {
        segment.projectSurveyPartners = segment.projectSurveyPartners.filter(e => e.isDeleted === false);
      }

      // We ensure entry url is correct for all partners
      segment.projectSurveyPartners.forEach(partner => {
        const url = this.urlList.entryURL + partner.id + '?' + this.projectService.generateEntryUrlParams(segment.surveyLink, partner);
        partner.surveyEntryUrl = url;
      });
    });

    this._project.projectDedupeStatus[0].otherId = this._project.id;
    this._project.projectDedupeStatus.forEach(dedupe => {
      if (dedupe.id == null || dedupe.id === '') { dedupe.id = uuidv4(); }
      dedupe.projectId =  this._project.id;
    });

    // Based on category
    this._project.isDeDupeDeviceAggressive = this._project.category === 'Consumer';

    // For now we only have one setting on UI which will set both de dupe settings
    this._project.isDeDupeDeviceEnabled = this._project.isDeDupeParticipantEnabled;
    if (this._project.projectEndDate) { this._project.projectEndDate.setHours(12, 0, 0, 0); }
   this._project.operationmode=this.mode;

    this.projectService.SaveProject(this._project.id, this._project).subscribe(data => {
      if (data == null) this.alertify.error('There was a problem saving this project.');
      else {
        this.alertify.success('Project Saved.');
        this.hasChanges = false;
      }

      if (this.mode === 'edit') this.onSave.emit(this._project);
      else this.router.navigateByUrl('/projects/' + this._project.id + '?new=true');

      this.saving = false;
      this.spinner.showSpinner = false;
    }, error => {
      this.alertify.error('There was a problem saving this project.');
      this.spinner.showSpinner = false;
    });
  }

  handleTypeChange() { }
  handleCategoryChange() {
   // this.hasChanges=true;
    if (this._project.projectStatus === 'Test') {
      if (this._project.category === 'B2B') {
        this._project.alertQCRate = this.defaultSettings.qcRateB2B;
        this._project.isDeDupeParticipantEnabled = true;
        this._project.isFraudDetectionEnabled = true;
        this._project.showLandingPage = true;
      } else if (this._project.category === 'Consumer') {
        this._project.alertQCRate = this.defaultSettings.qcRateConsumer;
        this._project.isDeDupeParticipantEnabled = true;
        this._project.isFraudDetectionEnabled = true;
      } else if (this._project.category === 'Healthcare') {
        this._project.alertQCRate = this.defaultSettings.qcRateHealthCare;
        this._project.isDeDupeParticipantEnabled = false;
        this._project.isFraudDetectionEnabled = false;
        this._project.showLandingPage = true;
      }
    }
    if (this.litemode) this._project.isFraudDetectionEnabled = true;
  }
  updateSoftQuota(full, obj, field) {
    if (this._project.projectStatus === 'Test') {
      const t = Math.round(full * .1);
      obj[field] = t;
    }
  }

  getClientDetails(clientId) {
    this.clientContacts = [];
    if (clientId == null) return;

    this.clientService.GetClient(clientId, true).subscribe( data => {
      if (this.accountOwner == null && data.internalAccountOwnerId != null ) {
        this.accountOwner = this.userList
          .filter(e => e.id === data.internalAccountOwnerId)
          .map(e => ({id: e.id, name: e.firstName + ' ' + e.lastName}));
      }
      this.clientLoading = false;
    });

    this.clientContactService.GetClientContacts(clientId).subscribe(data => { this.clientContacts = data; });
  }

  updateAccountOwner(value) { this.accountOwner = value; }
  updatePm(value) {
    this.projectManager = value;
    this.updateProjectTeam();
  }
  updateTeam(value) {
    this.projectTeam = value;
    this.updateProjectTeam();
  }
  updateProjectTeam() {
    const team = [];
    if (this.projectManager.length > 0) { team.push({userId: this.projectManager[0].id, projectRole: 'PM'}); }
    this.projectTeam.forEach(e => { team.push({userId: e.id, projectRole: 'Secondary PM'}); });
    this._project.projectTeam = team;
  }
  getUserList = (filter) => { return this.userService.GetUsers(); }

  updatePartnerStatus(status, segment) {
    if (segment === null) {
      this._project.projectSegments.forEach( seg => {
        seg.segmentStatus = status;
        seg.projectSurveyPartners.forEach( partner => partner.partnerStatus = status);
      });
    } else segment.projectSurveyPartners.forEach( partner => partner.partnerStatus = status);
  }


  addNewContact(guid, type) {
    if (type === 'client') {
      this.newContact[guid] = {
        id: uuidv4(),
        mode: 'new',
        firstName: null,
        lastName: '',
        phone: '',
        email: '',
        clientId: guid,
        address: {
          address1: '',
          address2: '',
          city: '',
          country: ''
        }, social: {
          facebook: '',
          linkedIn: '',
          twitter: '',
          skype: ''
        }
      };
    }
    this.addContact[guid] = true;
  }
  saveNewContact(guid, type) {
    // this.hasChanges=true;
    this.addContact[guid] = false;
    const contact = this.newContact[guid];
     // Add contact to array
    if (type === 'client') {
      this.clientContacts.push(contact);
      this._project.clientContactId = contact.id;
      this.clientContactService.AddClientContact(contact).subscribe(data => {
      }, error => {
         this.alertify.error('Unable to add contact');
         this._project.clientContactId = null;
         // Remove contact from array
         this.clientContacts = this.clientContacts.filter(e => e.id !== contact.id);
      }, () => { this.alertify.success('Contact added successfully'); });
    }
  }

  cancelNewContact(guid) { this.addContact[guid] = false; }

  handleClientChange(client) {
    // this.hasChanges=true;
    this.clientLoading = true;
    this._project.clientContactId = null;
    this.accountOwner = null;
    this.getClientDetails(client);
  }

  formChanged(event) { if (this.loaded) this.hasChanges = true; }
  resetFormChanged() { this.hasChanges = false; }

  setPartnerForm(form) {
    this.step2Form = form;
  }
  projectDetailsDateChanged(event, caller) {
    if (event === null || this.step1Form == null) return;
    if (this._project.projectStartDate === null || this._project.projectEndDate === null) return;

    if (caller === 'projectstartdate' && this._project.projectStartDate >= this._project.projectEndDate) {
      this.alertify.warning("Start date cannot be greater than the end date");
      this.step1Form.controls['projectStartDate'].setErrors({'incorrect': true});
      this.step1Form.controls['projectStartDate'].markAsTouched();
    } else if (caller === 'projectenddate' && this._project.projectEndDate <= this._project.projectStartDate) {
      this.alertify.warning("End date cannot be less than the start date");
      this.step1Form.controls['projectEndDate'].setErrors({'incorrect': true});
      this.step1Form.controls['projectEndDate'].markAsTouched()
    } else {
      this.step1Form.controls['projectStartDate'] && this.step1Form.controls['projectStartDate'].setErrors(null);
      this.step1Form.controls['projectEndDate'] && this.step1Form.controls['projectEndDate'].setErrors(null);
    }
  }
}
