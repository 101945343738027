<h3 class="font-weight-bold nav-secondary px-3">Notes</h3>

<ng-container *ngIf="bidNumber == ''">
  <div class="mt-4 notes scrollbar overflow-y px-3 w-100" [class.add-mode-on]="addNoteModeOn">
    <ul class="nav mt-2">
      <ng-container *ngFor="let note of notes; let noteIdx = index">
        <li class="note w-100">
          <hr class="w-100" *ngIf="noteIdx > 0">
          <div>
            <div class="row mb-3">
              <div class="col-2">
                <or-profile-image [name]="note.name" [url]="note.img" size="medium"></or-profile-image>
              </div>
              <div class="col">
                <h4 class="font-weight-bold grey5 mb-0">{{ note.name }}</h4>
                <span class="grey2 nav-font14">Created {{ note.cDate | date }}<small *ngIf="note.isEdited"> (edited)</small></span>
              </div>
              <div class="col-1 text-right mr-2" dropdown container="body" placement="bottom right">
                <i *ngIf="this.auth.getUser().id == note.userId" class="fak fa-ellipsis-vertical fa-lg cursor-pointer grey4" dropdownToggle></i>

                <!-- dropdown actions -->
                <ul class="dropdown-menu dropdown-menu-right position-relative grey4 cursor-pointer px-1" *dropdownMenu role="menu" aria-labelledby="button-triggers-manual">
                  <a class="dropdown-item" (click)="notesConfirmEdits[note.id] = { enabled: true, message: note.message }">Edit</a>
                  <hr class="mx-2 my-0">
                  <a class="dropdown-item" (click)="showDelete(note)">Delete</a>
                </ul>
              </div>
            </div>

            <div class="nav-font14 grey4 word-wrap-break" *ngIf="!notesConfirmEdits[note.id]?.enabled" [innerHTML]="note.message"></div>

            <a *ngIf="project.id !== note.projectId" class="font-weight-bold nav-font12 nav-accent-blue" [routerLink]="['/projects', note.projectId]">{{ note.projectName }}</a>
          </div>
        </li>
        <li class="edit-note bg-white" *ngIf="notesConfirmEdits[note.id]?.enabled">
          <div class="text-right">
            <div class="col w-100 text-right mt-1">
              <i class="fas fa-close fa-sm cursor-pointer grey4" (click)="notesConfirmEdits[note.id].enabled = false"></i>
            </div>
            <div class="col ckeditor position-relative">
              <or-wysiwyg (messageEvent)="notesConfirmEdits[note.id].message = $event" [config]="editorConfig" [data]="notesConfirmEdits[note.id].message"></or-wysiwyg>
              <div class="save-note">
                <button (click)="notesConfirmEdits[note.id].enabled = false" class="btn btn-sm mx-1 nav-accent-blue shadow-none">Cancel</button>
                <button (click)="editNote(note)" class="btn btn-sm nav-btn-accent shadow-none">Save</button>
              </div>
            </div>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-container>
<ng-container *ngIf="bidNumber != ''">
  <div class="col d-flex justify-content-end px-4 my-2">
    <or-filter [values]="filterValues" position="left" positionDistance="140" (filter)="applyFilterSummary($event)" *ngIf="!this.auth.isVendor()"></or-filter>
  </div>
  <div class="mt-4 notes scrollbar overflow-y px-3 w-100">
    <ul class="nav mt-2">
      <ng-container *ngFor="let note of notes; let noteIdx = index">
        <li class="note w-100">
          <hr class="w-100" *ngIf="noteIdx > 0">
          <div>
            <div class="row mb-3">
              <div class="col-2">
                <or-profile-image [name]="note.name" [url]="note.img" size="medium"></or-profile-image>
              </div>
              <div class="col">
                <h4 class="font-weight-bold grey5 mb-0">{{ note.name }}</h4>
                <span class="grey2 nav-font14">Created {{ note.cDate | date }}<small *ngIf="note.isEdited"> (edited)</small></span>
              </div>
            </div>

            <div class="nav-font14 grey4 word-wrap-break" *ngIf="!notesConfirmEdits[note.id]?.enabled" [innerHTML]="note.message"></div>

            <a *ngIf="project.id !== note.projectId" class="font-weight-bold nav-font12 nav-accent-blue" [routerLink]="['/projects', note.projectId]">{{ note.projectName }}</a>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-container>

<!-- Add Note Box -->
<ng-container *ngIf="bidNumber == ''">
  <div class="add-note w-100 fixed-bottom bg-white position-absolute" *ngIf="addNoteModeOn">
    <div class="text-right">
      <div class="col w-100 text-right mt-1">
        <i class="fas fa-close fa-sm cursor-pointer grey4" (click)="addNoteModeOn = false"></i>
      </div>
      <div class="col ckeditor position-relative">
        <or-wysiwyg (messageEvent)="receiveMessage($event)" [config]="editorConfig" [data]="newNote?.message == null ? '' : newNote?.message"></or-wysiwyg>
        <div class="save-note mr-2">
          <button (click)="addNoteModeOn = false" class="btn btn-sm mx-1 nav-accent-blue shadow-none">Cancel</button>
          <button (click)="addNote()" class="btn btn-sm nav-btn-accent shadow-none">Send</button>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-menu fixed-bottom position-absolute">
    <div class="nav nav-pills flex-column mb-auto">
      <div class="d-flex align-items-center px-3 py-2">
        <span class="grey4 nav-font14">({{notes?.length}}) {{ notes?.length == 1 ? 'Note' : 'Notes' }}</span>
        <button class="btn btn-sm shadow-none font-weight-bold ml-auto"
            [disabled]="addNoteModeOn"
            [ngClass]="{'nav-btn-accent': !addNoteModeOn, 'nav-btn-disabled': addNoteModeOn}"
            (click)="addNoteModeOn = true">Add Note
        </button>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="bidNumber != ''">
  <div class="bottom-menu fixed-bottom position-absolute">
    <div class="nav nav-pills flex-column mb-auto">
      <div class="d-flex align-items-center px-3 py-2">
        <span class="grey4 nav-font14">({{notes?.length}}) {{ notes?.length == 1 ? 'Note' : 'Notes' }}</span>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #modalDelete>
  <div class="modal-header d-flex align-items-center justify-content-center">
    <div class="w-100">
      <div class="d-flex justify-content-end align-content-center">
        <i class="fas fa-close grey4" (click)="closeModal()"></i>
      </div>
    </div>
  </div>
  <div class="modal-body pt-0 text-center">
    <h3 class="font-weight-bold grey5">Are you sure you want to delete this note?</h3>
    <h5 class="grey5 text-center d-block my-3">
      <i class="fak fa-info grey4" (click)="closeModal()"></i>
      This action cannot be undone.
    </h5>
  </div>
  <div class="modal-footer">
    <div class="row d-flex w-100" >
      <div class="col text-right pr-0">
        <button class="btn btn-sm nav-btn-primary mx-1 shadow-none" (click)="closeModal()">Cancel</button>
        <button class="btn btn-sm nav-btn-accent shadow-none" (click)="delete()">Delete</button>
      </div>
    </div>
  </div>
</ng-template>
