import { Component, OnInit, ChangeDetectorRef, Input, ViewChild } from '@angular/core';
import { AuthService, Project, Note, Tags, AlertifyService } from 'core';
import { NotesService } from 'projects/core/src/services/note.service';
import { v4 as uuidv4 } from 'uuid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit {

  notes: Note[] = [];
  tags: Tags[] = [];
  showAll = true;
  newNote:any = {};
  deleteNote:any = {};
  notesConfirmDeletes = [];
  notesConfirmEdits = [];
  addNoteModeOn = false;
  fullList: any[] = [];

  filtersApplied = [];
  filterValues:any = [
    { label: 'Show All', value: 'showAll', selected:true }
  ];

  openModalRef: any;
  @ViewChild('modalDelete') modalDelete: BsModalRef;

  editorConfig = {
    startupFocus : true,
    toolbar: {
      language: 'en',
      items: [
        'bold',
        'italic',
        'underline',
        'link',
        'bulletedList',
        'numberedList'
      ]
    }
  };

  @Input() project = {} as Project;
  @Input() bidNumber = '' as string;

  constructor(
    private noteService: NotesService,
    public auth: AuthService,
    private modalService: BsModalService,
    private alertify: AlertifyService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() { this.getNotes(); }
  receiveMessage($event) { this.newNote.message = $event; }
  getCount($event) { this.newNote.commentLength = $event; }

  getNotes() {
    if (this.bidNumber == '') {
      this.noteService.getProjectNotes(this.project.id).subscribe(result => {
        //we currently return related notes based on tags but the UI has been updated to just show these notes, we may look to update API call in the future
        this.notes = result.filter(e => e.projectId == this.project.id);
        this.cdr.detectChanges();
      }, error => console.error(error));
    } else if (this.bidNumber != '') {
      this.noteService.getProjectNotesForSummary(this.bidNumber).subscribe(result => {
        //we currently return related notes based on tags but the UI has been updated to just show these notes, we may look to update API call in the future
        this.notes = result;

        let distinctProjects = [...new Set(this.notes.map(item => item.projectId))];

        distinctProjects.map(projectId => {
          const projectCode = this.notes.find(x => x.projectId == projectId)?.projectCode;
          this.filterValues.push({ label: projectCode, value: projectId, group: 'Project Number' });
        });

        this.fullList = this.notes;

        this.cdr.detectChanges();
      }, error => console.error(error));
    }
  }

  addNote() {
    // Exit if summary
    if (this.bidNumber != '') return;

    this.newNote.id =  uuidv4();
    this.newNote.projectId = this.project.id;
    const regexp = /\B\#\w\w+\b/g;
    const result = this.newNote.message.match(regexp);

    if (this.newNote.message.trim() === '') {
      this.alertify.error('Please write a note.');
      return;
    }

    if (result != null) {
      for (let i = 0; i < result.length; i++) {
        const newTag = {} as Tags;
        newTag.tag = result[i];
        newTag.tag = newTag.tag.replace(/#/g, '');
        newTag.id =  uuidv4();
        this.tags.push(newTag);
      }
    }

    this.newNote.tags = this.tags;
    this.notes.unshift(this.newNote);
    this.noteService.addNote(this.newNote).subscribe(() => {
      this.addNoteModeOn = false;
      this.alertify.success('Note added successfully');
      this.newNote = {};
      this.getNotes();
    }, error => console.error(error));
  }

  showDelete(note) {
    this.deleteNote = note;
    this.openModal(this.modalDelete);
  }

  openModal(template: any, classes: string = 'modal-sm nav-modal nav-modal-style nav-sidebar-modal') {
    this.openModalRef = this.modalService.show(template, { ignoreBackdropClick: true, keyboard: false, class: classes });
  }

  closeModal() {
    this.openModalRef.hide();
    this.openModalRef = null;
  }

  applyFilterSummary(filters) {
    this.filtersApplied = filters?.filter(x => x.value !== 'showAll' && x?.selected).map(x => x.value);
    this.showAll = filters?.some(x => x.value == 'showAll' && x?.selected);
    this.filterListSummary();
  }

  filterListSummary() {
    let notes = [...this.fullList];

    notes = notes.filter(item => {
      if (!this.showAll)
        return this.filtersApplied.includes(item.projectId);
      else
        return item;
    });

    this.notes = notes;
  }

  delete() {
    this.noteService.deleteNote(this.deleteNote.id).subscribe(() => {
      this.alertify.success('Note deleted successfully');
      this.closeModal();
      this.getNotes();
    }, error => {
       console.error(error);
       this.alertify.error('Unable to delete note');
    });
  }

  editNote(note) {
    note.message = this.notesConfirmEdits[note.id].message;
    this.noteService.editNote(note).subscribe(() => {
      this.alertify.success('Note edited successfully');
      this.notesConfirmEdits[note.id] = null;
      this.getNotes();
    }, error => {
       console.error(error);
       this.alertify.error('Unable to edit note');
    });
  }
}

