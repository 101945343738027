import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener, TemplateRef } from '@angular/core';
import { Project, AuthService, ProjectService, AlertifyService, ProjectDataService } from 'core';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
declare var google: any;

@Component({
  selector: 'app-project-card-survey-performance-chart',
  templateUrl: './project-card-survey-performance-chart.component.html',
  styleUrls: ['./project-card-survey-performance-chart.component.scss']
})
export class ProjectCardSurveyPerformanceChartComponent implements OnInit {

  @Input() project: Project;
  @Input() mode = 'reduced';
  @Input() public set respondents(data) {   
    this.processData(data);
  }

  @Output() cardresize = new EventEmitter();

  data: any;
  partners: Array<number>;
  familyName;
  dates: Array<any>;
  complete: number;
  chartData: any[];
  loading = true;
  daysPercentagePast = 0;
  fullLaunchQuota = 0;
  actualIR = 0;
  dropoffRate = 0;
  qcRate = 0;
  conversionRate = 0;
  expectedRate = 0;
  medianLoi = 0;
  editFieldEndDate = false;
  fieldEndDateFormatted = null;
  modalView = false
  projectPerformance = []
  vendorViewId: string = '';
  clientViewId: string = '';
  fieldTrackingModel: any = {};
  isModalOpen = false;
  isClientview: boolean = false;

  openModalRef: BsModalRef;
  @ViewChild('modalClientReport') modalClientReport: BsModalRef;
  @ViewChild('modalFieldTracking') modalFieldTracking: TemplateRef<any>;
  @ViewChild('fieldEndDatePicker') fieldEndDatePicker: BsDatepickerDirective;
  @ViewChild('fieldEndDateInput', { static: false }) public fieldEndDateInput: ElementRef;

  constructor(public auth: AuthService,
    private modalService: BsModalService,
    private projectService: ProjectService,
    private alertify: AlertifyService,
    private projectDataService: ProjectDataService,
    private elementRef: ElementRef) { }

  ngOnInit() {
    this.projectDataService.getVendorViewSidebar.subscribe(data => {
      if (data) {
        this.vendorViewId = data;

      }
    });
    this.projectDataService.getClientViewSidebar.subscribe(data => {
      if (data) {
        this.isClientview = data != '';
        this.clientViewId = data;
      }
    });

    this.calculateFieldDates();
    this.expectedRate = Math.round((this.project.projectSegments[0].projectIR || 0) * .8);
  }

  calculateFieldDates() {
    this.fieldEndDateFormatted = this.project.fieldEndDate != null ? new Date(this.project.fieldEndDate) : null
    const daysLeft = (this.project.totalDaysLeft < 0) ? 0 : this.project.totalDaysLeft;
    const totalDays = this.project.totalDays;
    this.daysPercentagePast = 100 - Math.round(daysLeft * 100 / totalDays);
    if (this.daysPercentagePast < 0) this.daysPercentagePast = 0;

    if (this.auth.getUser().roles.indexOf("Vendor") >= 0) {
      //Vendor user - we have only returned the one vendor from server so we can reference this one
      this.fullLaunchQuota = this.project.projectSegments[0]?.projectSurveyPartners[0]?.partnerFullQuota;
    }
    else if (this.vendorViewId != '') {
      this.fullLaunchQuota = this.project.projectSegments[0].projectSurveyPartners.find(e => e.partnerId == this.vendorViewId)?.partnerFullQuota;
    }
    else {
      this.fullLaunchQuota = this.project.fullLaunchQuota;
    }
  }

  processData(data) {
    let complete = 0;
    let clientTerminate = 0;
    let dropoff = 0;
    let starts = 0;
    let clientQc = 0;
    const verdorList = [];
    const handledpartners = {};
    let lois = [];
    data.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

    let sDate;
    let eDate;

    data.forEach(e => {   
      if (e.partner != null) {
        if (e.completeLois != null) {
          lois = lois.concat(e.completeLois.map(f => f.loi));
        }
        if (this.isClientview) {
          e.partnerId = this.auth.getInstance();
          e.partner = 'OpinionRoute';
        }
        if (e.complete > 0) {
          eDate = e.date;
          if (sDate == null) { sDate = e.date; }
        }
        starts += e.starts;
        complete += e.complete;
        clientTerminate += e.clientTerminate;
        clientQc += e.clientQc;
        dropoff += e.dropoff;
        if (!handledpartners[e.partnerId]) {
          handledpartners[e.partnerId] = true;
          const partnerName = e.partner.length <= 30 ? e.partner : e.partner.slice(0, 30);
          verdorList.push(partnerName);
        }
      }
    });

    this.complete = complete;
    this.partners = verdorList;
    this.actualIR = complete === 0 ? 0 : (complete) / (complete + clientTerminate);
    this.dropoffRate = starts === 0 ? 0 : dropoff / starts;

    this.qcRate = clientQc === 0 ? 0 : ((clientQc) / (complete + clientQc));
    this.conversionRate = complete === 0 ? 0 : Number(((complete * 100) / starts).toFixed(2))

    const LOIs = lois.sort((a, b) => a - b);
    if (LOIs.length === 0) {
      this.medianLoi = 0;
    } else {
      const mid = Math.floor(LOIs.length / 2);
      const loi = LOIs.length % 2 !== 0 ? LOIs[mid] : (LOIs[mid - 1] + LOIs[mid]) / 2;
      this.medianLoi = Math.round(loi / 1000);
    }

    const header = ['Date', ...this.partners];
    const chartData = [header];
    const startDate = new Date(sDate);
    const endDate = new Date(eDate);
    endDate.setHours(23);

    for (let d = startDate; d.getTime() <= endDate.getTime();) {
      const dDate = d.toISOString().split('T')[0];
      const dateCounts: any = [new Date(d)];
      const dayDetails = data.filter(e => e.date.toISOString().split('T')[0] === dDate);
      this.partners.forEach(partner => {
        const partnerCompletes = dayDetails.filter(e => e.partner.slice(0, 30) === partner);
        let count = 0;
        partnerCompletes.forEach(e => {
          count += e.complete;
        });
        dateCounts.push(count);
      });
      chartData.push(dateCounts);
      d = new Date(d.setDate(d.getDate() + 1));
    }
    this.chartData = chartData;

    setTimeout(() => {
      this.drawpartnerPerformanceChart();
    }, 1);

    this.data = data;
    this.loading = false;
  }

  drawpartnerPerformanceChart() {
    if (this.chartData.length === 1) { return; }

    let maxDay = 0;
    const hAxisTicks = [];
    for (let i = 1; i < this.chartData.length; i++) {
      let dayCount = 0;
      for (let j = 1; j < this.chartData[i].length; j++) {
        dayCount += this.chartData[i][j];
      }
      hAxisTicks.push(this.chartData[i][0]);

      if (dayCount > maxDay) { maxDay = dayCount; }
    }
    maxDay = Math.ceil(maxDay / 10) * 10;

    let legend = (this.auth.isInternal()) ? { position: 'right', textStyle: { fontName: 'Lato', fontSize: 11 } } : { position: 'none' };
    if (this.vendorViewId != '' || (this.isClientview)) {
      legend = { position: 'none' };
    }

    const options: any = {
      isStacked: true,
      height: 230,
      bar: { groupWidth: '75%' },
      legend: legend,
      backgroundColor: 'white',
      theme: 'material',
      titleTextStyle: {
        fontName: 'Lato'
      },
      chartArea: {
        top: '10%',
        right: '30%',
        bottom: '10%',
        left: '10%',
        width: '100%',
        height: '100%',
        backgroundColor: '#FFFFFF'
      },
      vAxis: {
        gridlines: { color: '#E0E0E0' },
        ticks: [0, maxDay],
        titleTextStyle: {
          fontName: 'Lato',
          fontSize: 12
        }
      },
      hAxis: {
        title: '',
        format: 'MMM d',
        gridlines: { color: 'none' },
        titleTextStyle: {
          fontName: 'Lato',
          fontSize: 12
        },
        ticks: (hAxisTicks.length) > 8 ? null : hAxisTicks
      },
      series: {
        0: { color: '#013B5C' },
        1: { color: '#0077BC' },
        2: { color: '#355F82' },
        3: { color: '#5D85AB' },
        4: { color: '#86ADD5' },
        5: { color: '#B1D7FF' },
        6: { color: '#354D85' },
        7: { color: '#007895' },
        8: { color: '#00B9B9' }
      }
    };

    google.charts.load('current', { 'packages': ['bar'] });
    google.charts.setOnLoadCallback(() => {
      setTimeout(() => {
        const data = google.visualization.arrayToDataTable(this.chartData);
        const chartDiv = document.getElementById('chart_performance_reduced');
        const chart = new google.charts.Bar(chartDiv);
        chart.draw(data, google.charts.Bar.convertOptions(options));
      }, 500);
    });
  }

  processClientData() {
    let complete = 0;
    let clientTerminate = 0;
    let dropoff = 0;
    let starts = 0;
    let clientQc = 0;
    const verdorList = [];
    const handledpartners = {};
    let lois = [];
    this.data.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

    let sDate;
    let eDate;

    this.data.forEach(e => {
      if (e.partner != null) {
        if (e.completeLois != null) {
          lois = lois.concat(e.completeLois.map(f => f.loi));
        }
        if (this.isClientview) {
          e.partnerId = this.auth.getInstance();
          e.partner = 'OpinionRoute';
        }
        if (e.complete > 0) {
          eDate = e.date;
          if (sDate == null) { sDate = e.date; }
        }
        starts += e.starts;
        complete += e.complete;
        clientTerminate += e.clientTerminate;
        clientQc += e.clientQc;
        dropoff += e.dropoff;
        if (!handledpartners[e.partnerId]) {
          handledpartners[e.partnerId] = true;
          const partnerName = e.partner.length <= 30 ? e.partner : e.partner.slice(0, 30);
          verdorList.push(partnerName);
        }
      }
    });

    this.complete = complete;
    this.partners = verdorList;
    this.actualIR = complete === 0 ? 0 : (complete) / (complete + clientTerminate);
    this.dropoffRate = starts === 0 ? 0 : dropoff / starts;

    this.qcRate = clientQc === 0 ? 0 : ((clientQc) / (complete + clientQc));
    this.conversionRate = complete === 0 ? 0 : Number(((complete * 100) / starts).toFixed(2))

    const LOIs = lois.sort((a, b) => a - b);
    if (LOIs.length === 0) {
      this.medianLoi = 0;
    } else {
      const mid = Math.floor(LOIs.length / 2);
      const loi = LOIs.length % 2 !== 0 ? LOIs[mid] : (LOIs[mid - 1] + LOIs[mid]) / 2;
      this.medianLoi = Math.round(loi / 1000);
    }

    const header = ['Date', 'OpinionRoute'];
    const chartData = [header];
    const startDate = new Date(sDate);
    const endDate = new Date(eDate);
    endDate.setHours(23);

    for (let d = startDate; d.getTime() <= endDate.getTime();) {
      const dDate = d.toISOString().split('T')[0];
      const dateCounts: any = [new Date(d)];
      const dayDetails = this.data.filter(e => e.date.toISOString().split('T')[0] === dDate);

      // Combine counts for all partners into 'OpinionRoute'
      let totalCount = 0;
      this.partners.forEach(partner => {
        const partnerCompletes = dayDetails.filter(e => e.partner === partner);
        partnerCompletes.forEach(e => {
          totalCount += e.complete;
        });
      });

      dateCounts.push(totalCount);
      chartData.push(dateCounts);
      d = new Date(d.setDate(d.getDate() + 1));
    }

    this.chartData = chartData;

    setTimeout(() => {
      this.drawClientPerformanceChart();
    }, 1);

    this.loading = false;
  }


  drawClientPerformanceChart() {
    if (this.chartData.length === 1) { return; }

    let maxDay = 0;
    const hAxisTicks = [];
    for (let i = 1; i < this.chartData.length; i++) {
      let dayCount = 0;
      for (let j = 1; j < this.chartData[i].length; j++) {
        dayCount += this.chartData[i][j];
      }
      hAxisTicks.push(this.chartData[i][0]);

      if (dayCount > maxDay) { maxDay = dayCount; }
    }
    maxDay = Math.ceil(maxDay / 10) * 10;

    const legend = (this.auth.isInternal()) ? { position: 'right', textStyle: { fontName: 'Lato', fontSize: 11 } } : { position: 'none' };

    const options: any = {
      isStacked: true,
      height: 230,
      bar: { groupWidth: '75%' },
      legend: legend,
      backgroundColor: 'white',
      theme: 'material',
      titleTextStyle: {
        fontName: 'Lato'
      },
      chartArea: {
        top: '10%',
        right: '30%',
        bottom: '10%',
        left: '10%',
        width: '100%',
        height: '100%',
        backgroundColor: '#FFFFFF'
      },
      vAxis: {
        gridlines: { color: '#E0E0E0' },
        ticks: [0, maxDay],
        titleTextStyle: {
          fontName: 'Lato',
          fontSize: 12
        }
      },
      hAxis: {
        title: '',
        format: 'MMM d',
        gridlines: { color: 'none' },
        titleTextStyle: {
          fontName: 'Lato',
          fontSize: 12
        },
        ticks: (hAxisTicks.length) > 8 ? null : hAxisTicks
      },
      series: {
        0: { color: '#013B5C' },
        1: { color: '#0077BC' },
        2: { color: '#355F82' },
        3: { color: '#5D85AB' },
        4: { color: '#86ADD5' },
        5: { color: '#B1D7FF' },
        6: { color: '#354D85' },
        7: { color: '#007895' },
        8: { color: '#00B9B9' }
      }
    };

    google.charts.load('current', { 'packages': ['bar'] });
    google.charts.setOnLoadCallback(() => {
      setTimeout(() => {
        const data = google.visualization.arrayToDataTable(this.chartData);
        const chartDivs = document.querySelectorAll('.chart_performance_reduced');

        chartDivs.forEach(chartDiv => {
          const data = google.visualization.arrayToDataTable(this.chartData);
          const chart = new google.charts.Bar(chartDiv);
          chart.draw(data, google.charts.Bar.convertOptions(options));
        });
      }, 500);
    });
  }

  get ProgressStyle(): string {
    if (this.project.projectStatus === 'Paused' || this.project.projectStatus === 'Closed' || this.project.projectStatus === 'Invoiced') {
      return ' or-darkgray-bg';
    }
    else if (this.daysPercentagePast > (this.complete * 100 / this.fullLaunchQuota)) {
      return 'nav-bg-error';
    }
    return '';
  }

  openModal(template: any, classes: string = 'modal-full nav-modal-style') {
    this.refreshPerformance()
    this.openModalRef = this.modalService.show(template, { ignoreBackdropClick: true, keyboard: false, class: classes });
  }

  previewClientReport() {
    this.processClientData();
    this.openModal(this.modalClientReport);
    this.modalView = true;
  }

  closeModal() {
    this.openModalRef.hide();
    this.openModalRef = null;
    this.modalView = false;
    this.drawpartnerPerformanceChart()
  }

  refreshPerformance() {
    this.projectService.GetProjectParticipants(this.project.id, "").subscribe(data => {
      this.projectPerformance = data.map(e => {
        const ret: any = { ...e };
        const inTime = new Date(e.date);
        ret.date = new Date(inTime.getFullYear(), inTime.getMonth(), inTime.getDate());
        ret.partner = ret.vendor;
        ret.partnerId = ret.vendorId;
        ret.complete = (e.completeLois == null) ? 0 : e.completeLois.length;

        return ret;
      }).sort((a, b) => (new Date(a.in).valueOf() - new Date(b.in).valueOf()));

    });
  }

  closeFieldTrackingModal() {
    this.fieldEndDateFormatted = this.fieldTrackingModel.OldValue;
    this.isModalOpen = false;
    this.openModalRef.hide();
  }

  SaveProjectFieldEndDate(newDate) {
    if (this.fieldEndDateFormatted == newDate) return;

    // endDate tracking only available for OR instances
    if (this.auth.isORInstance() && this.project.fieldEndDate) {
      this.fieldTrackingModel = {
        field: 'fieldEndDate',
        reasonType: '',
        reason: '',
        OldValue: this.fieldEndDateFormatted,
        NewValue: newDate
      };

      this.fieldEndDateFormatted = newDate;
      this.isModalOpen = true;
      this.openModalRef = this.modalService.show(this.modalFieldTracking, { ignoreBackdropClick: true, keyboard: false, class: 'nav-modal-style modal-dialog-centered' });
    }
    else {
      this.projectService.UpdateProjectValue(this.project.id, 'fieldEndDate', newDate).subscribe((data) => {
        this.alertify.success('Project field end date updated successfully');
        this.fieldTrackingModel.OldValue = newDate;
        this.project.fieldEndDate = newDate;
        this.projectService.EnrichProject(this.project);
        this.calculateFieldDates();
        this.editFieldEndDate = false;
        this.openModalRef.hide();
      }, (error) => {
        this.alertify.error('Unable to save project field end date');
      });
    }
  }

  AddProjectFieldTracking(field: string) {

    if (this.fieldTrackingModel.reasonType == 'Other' && !this.fieldTrackingModel.reason.trim()) {
      this.alertify.warning('Please type in the other reason.');
      return;
    }

    forkJoin([
      this.projectService.AddProjectFieldTracking(this.project.id, this.fieldTrackingModel),
      this.projectService.UpdateProjectValue(this.project.id, field, this.fieldEndDateFormatted)
    ]).subscribe((data) => {
      this.alertify.success('Project field end date updated successfully');
      this.project.fieldEndDate = this.fieldEndDateFormatted;
      this.projectService.EnrichProject(this.project);
      this.calculateFieldDates();
      this.editFieldEndDate = false;
      this.isModalOpen = false;
      this.openModalRef.hide();
    }, (error) => {
      this.alertify.error('Unable to save project field end date');
    })
  }

  DateAsUTCDate(date) {
    if (date != null) {
      date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 12)).toISOString()
    }
    return date;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.editFieldEndDate && !this.isModalOpen && !(event.target as HTMLElement).classList.contains('fieldEndDateInput')) {
      this.editFieldEndDate = false;
    }
  }
}
