
<div class="card mb-3 mx-2" [ngClass]="group.formMode == 'setup' ? 'shadow' : ''">
  <div class="card-header nav-secondary  py-2">
    <div class="row">
      <div class="col-5 text-left px-2">
        {{group.name}}
      </div>
      <div class="col-3 text-center">
        @if (group.formMode != 'setup') {
          n
        }
      </div>
      <div class="col-4 text-center">
        {{limitCheck != 'tracking only' ? 'Limit' : ''}}
      </div>
    </div>
  </div>
  <div class="card-body grey4 pb-2">
    @for (quota of group.quotas; track quota; let i = $index) {
      @for (v of quota.vendorLimits; track v; let x = $index) {
        @if (v.vendorId === vendor.partnerId && quota.isDeleted == false) {
          <div class="row quotaRow pt-2 clickable" (click)="enableEdit(quota)"
            [ngClass]="v.edit && limitCheck != 'tracking only' ? 'inlineEdit' : ''">
            @if (quota.targetGroup[group.name.toLowerCase()]; as value) {
              <div class="col-5 text-left grey4 text-nowrap text-truncate px-2 clickable" [class.font-italic]="value === 'pnta'">
                {{ value === 'pnta' ? 'Prefer not to answer' : value}}
              </div>
            }
            @if (group.name == 'Income') {
              <div class="col-5 text-left grey4 text-nowrap text-truncate px-2">
                @if (quota.targetGroup.minHHI > -1) {
                  <div class="d-flex clickable">
                    <div class="d-inline clickable">
                      <u class="clickable">{{ quota.targetGroup.minHHI |  currency :'USD':'symbol':'1.0-0'}}</u>
                    </div>
                    <div class="mx-1 d-inline clickable"> to </div>
                    <div class="d-inline text-truncate clickable">
                      @if (quota.targetGroup.maxHHI <= 150000) {
                        <u class="clickable">{{ quota.targetGroup.maxHHI |  currency :'USD':'symbol':'1.0-0'}}</u>
                      }
                      @if (quota.targetGroup.maxHHI > 150000) {
                        <u class="clickable">and above</u>
                      }
                    </div>
                  </div>
                }
                @if (quota.targetGroup.minHHI == -1) {
                  <div class="d-inline font-italic clickable">
                    Prefer not to answer
                  </div>
                }
              </div>
            }
            @if (group.name == 'Age') {
              <div class="col-5 text-left grey4 text-nowrap text-truncate clickable">
                <div class="d-flex clickable">
                  <div class="clickable">
                    <div class="d-inline clickable">
                      <u class="clickable">{{ quota.targetGroup.minAge || 0}}</u>
                    </div>
                    <div class="mx-1 mt-1 d-inline clickable"> to </div>
                    <div class="d-inline text-truncate clickable">
                      <u class="clickable">{{ quota.targetGroup.maxAge || 0}}</u>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div class="col-3 text-center clickable">
              @if (group.formMode != 'setup') {
                <ng-container>{{ (limitMode == 'start' ? v.starts : v.completes) || 0 }}</ng-container>
              }
            </div>
            @if (limitType == 'percent') {
              <div class="col-4 text-right text-nowrap text-truncate clickable">
                @if (v.edit != true && group.formMode != 'setup' && limitCheck != 'tracking only') {
                  <div>
                    @if (limitMode == 'start') {
                      <span  class="ml-2 clickable" >{{ v.limitPercent >= 99999 ? "*" : v.limitPercent || 0 }} % </span>
                    }
                    @if (limitMode == 'complete') {
                      <span class="ml-2 clickable">{{ v.limitPercent >= 99999 ? "*" : v.limitPercent || 0 }} % </span>
                    }
                  </div>
                }
                <!-- inline edit -->
                @if ((v.edit == true || group.formMode == 'setup') && limitCheck != 'tracking only') {
                  <div  class="pl-1 d-inline clickable">
                    <input #inputLimit (keyup.enter)="inputLimit.blur()" (blur)="handleLimitChange(quota, '%', v, $event)" type="text"
                      class="form-control form-control-nav form-control-sm d-inline nav-font14 inlineInput clickable"
                      oninput="this.value = this.value.replace(/[^0-9*.]/g, '').replace(/(\..*)\./g, '$1');"
                      [value]="v.limitPercent?.toString() ? ( v.limitPercent < 99999 ?  v.limitPercent + ''  : '*') : 0"
                      >
                      <span class="pl-1">%</span>
                    </div>
                  }
                </div>
              }
              @if (limitType == 'number') {
                <div  class="col-4 text-right text-nowrap clickable">
                  @if (v.edit != true && group.formMode != 'setup' && limitCheck != 'tracking only') {
                    <div class="clickable">
                      @if ( limitMode == 'start'   ) {
                        <span class="clickable">{{ v.limitN >= 99999 ? "*" : (v.limitN || 0) }} n</span>
                      }
                      @if ( limitMode == 'complete'   ) {
                        <span class="clickable">{{ v.limitN >= 99999 ? "*" : (v.limitN || 0 )}} n</span>
                      }
                    </div>
                  }
                  <!-- inline edit -->
                  @if ((v.edit == true || group.formMode == 'setup') && limitCheck != 'tracking only') {
                    <div  class="pl-1 d-inline clickable">
                      <input #inputLimit (keyup.enter)="inputLimit.blur()" (blur)="handleLimitChange(quota, 'n', v, $event)" type="text"
                        class="form-control form-control-nav form-control-sm d-inline nav-font14 inlineInput clickable"
                        oninput="this.value = this.value.replace(/[^0-9*.]/g, '').replace(/(\..*)\./g, '$1');"
                        [value]="v.limitN?.toString() ? ( v.limitN < 99999 ?  v.limitN  : '*') : 0"
                        >
                        <span class="pl-1">n</span>
                      </div>
                    }
                  </div>
                }
              </div>
            }
          }
        }
        @if (group.nonValueStarts) {
          <div class="row quotaRow pt-2" >
            <div class="col-5 text-left grey4 text-nowrap text-truncate px-2" [class.pl-3]="group.name === 'Age'">
              Other
            </div>
            <div class="col-3 text-center">
              @if (group.formMode != 'setup') {
                <div class="d-inline">{{ limitMode == 'complete' ? vendor.completes-vendorQuotaCompletes.n : vendor.starts - vendorQuotaStarts.n}}</div>
              }
            </div>
            <div class="col-4"></div>
          </div>
        }


        <!-- last row: adding quotas and totals -->
        <div class="row totalRow mt-2" [class.totalRowTall]="questions[group.questionId].preferNotToAnswer && !group.preferNotToAnswerAdded && group.deletedOptions.length > 0">

          <!-- total -->
          @if (limitCheck != 'tracking only') {
            <div class="col-5 text-left grey5 mt-1">
              @if (group.formMode != 'setup') {
                Total
              }
            </div>
            <div class="col-3 nav-black1 p-0"  [ngClass]="group.formMode == 'setup' ? 'text-right' : 'text-center'">
              <hr class="grey1 m-0 mb-1">
              @if (group.formMode != 'setup') {
                <ng-container>{{limitMode == 'start' ? (vendorQuotaStarts.n + (vendor.starts - vendorQuotaStarts.n)) : (vendorQuotaCompletes.n + (vendor?.completes - vendorQuotaCompletes.n)) }}</ng-container>
              }
              @if (group.formMode == 'setup') {
                <ng-container>Total</ng-container>
              }
            </div>
            <div class="col-4 text-right nav-black1 pl-0">
              <hr class="grey1 m-0 mb-1">
              @if (limitType=='percent') {
                {{(vendorGroupTotals[vendor.partnerId + '_' + group.id]?.percent || 0) | number: '1.0-0'}} %
              }
              @if (limitType=='number') {
                {{vendorGroupTotals[vendor.partnerId + '_' + group.id]?.n || 0}} n
              }
            </div>
          }
        </div>
      </div>
    </div>
