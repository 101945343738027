import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, TemplateRef } from '@angular/core';
import { ClientContactService, ClientService, DropdownService, Project, ProjectService, UserService, AuthService, CountriesService, SurveyTesterUser, SurveyTesterService, SurveyTesterProject, User, SecurityKeyService } from 'core';
import { AlertifyService } from 'core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable, Observer, forkJoin, map, of, switchMap } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-project-settings',
  templateUrl: './project-settings.component.html',
  styleUrls: ['./project-settings.component.scss']
})
export class ProjectSettingsComponent implements OnInit {

  @Input() public set project(data: Project) {

    if (data) {
      this._project = data;
      this.getProjectInfo();
    }

    this._project = data;
    this.origProject = JSON.parse(JSON.stringify(this._project));
    this.getProjectTypes();

    this.projectManager = this._project.projectTeam.filter(e => e.projectRole === 'PM').map(e => ({ id: e.userId, name: e.displayName, projectRole: e.projectRole }));
    this.projectSecondaryManager = this._project.projectTeam.filter(e => e.projectRole === 'Secondary PM').map(e => ({ id: e.userId, name: e.displayName, projectRole: e.projectRole }));
    this.tags = this._project.projectTags.map(e => e.tag).join(',');
    this.countries = this._project.countries.map(e => ({ value: e, name: e }));

    this.dropdownService.getClients().subscribe(data => this.clients = data);

    this.countryService.getCountries().pipe(
      switchMap(data => {
        this.countryList = data;
        this.currentCidCountries = this._project.fraudDetectionGeoCountry == null ? [] : this._project.fraudDetectionGeoCountry.split(',');
        this.cidCountries = this.countryList.filter(e => this.currentCidCountries.includes(e.iso2));
        return this.dropdownService.getCountries();
      })
    ).subscribe(data => {
      this.countryDropdownList = data;
      let fullCountries: any = [];

      for (let cid of this.currentCidCountries) {
        fullCountries.push(this.countryList.find(obj => {
          if (obj.iso2 === cid) return obj;
        }));
      }

      if (fullCountries.length > 0) {
        for (let country of fullCountries) {
          this.displayCountries.push(this.countryDropdownList.find(obj => {
            if (obj.value === country.name) return obj;
          }));
        }
      }
    });

    this.userService.GetUsers().subscribe(data => {
      this.userList = data;
      this.internalAccountOwner = (this._project.internalAccountOwnerId == null) ? [] : this.userList.filter(e => e.id == this._project.internalAccountOwnerId)
    });

    if (this._project.projectEndDate != null) {
      this._project.projectEndDate = new Date(this._project.projectEndDate);
      this._project.projectEndDate.setHours(12, 0, 0, 0);
    }

    if (this._project.projectStartDate != null) {
      this._project.projectStartDate = new Date(this._project.projectStartDate);
      this._project.projectStartDate.setHours(12, 0, 0, 0);
    }
    if (this._project.fieldStartDate != null) {
      this._project.fieldStartDate = new Date(this._project.fieldStartDate);
      this._project.fieldStartDate.setHours(12, 0, 0, 0);
    }
    if (this._project.fieldEndDate != null) {
      this._project.fieldEndDate = new Date(this._project.fieldEndDate);
      this._project.fieldEndDate.setHours(12, 0, 0, 0);
    }

    this.fieldEndDateOldValue = this._project.fieldEndDate;

    if (this._project.clientId != null) {
      this._project.clientId = this._project.clientId.toLowerCase();
      this.clientContactService.GetClientContacts(this._project.clientId).subscribe(data => { this.clientContacts = data; });
    }
    this.showSurveySecurityKey = this._project.surveySecurityKey != null;
  }
  @Input() projectType;

  @Output() closeCallback = new EventEmitter();
  @Output() onChange = new EventEmitter();

  @ViewChild('scrollableContent') scrollableContent!: ElementRef;

  _project: Project;
  origProject: Project;
  projectManager: any = [];
  projectSecondaryManager: any = [];
  internalAccountOwner: any = [];
  clients: any = [];
  clientContacts: any = [];
  tags: any = [];
  countryList: any = [];
  userList: any = [];
  countries: any = [];
  countryDropdownList: any = [];
  currentCidCountries: any = [];
  cidCountries: any = [];
  displayCountries: any = []
  integerPattern = { 9: { pattern: new RegExp('^[0-9]*$'), optional: true } };
  ready = false;
  currentSession = '#projectDetails';
  projectTypeValue = '';
  fieldEndDateOldValue: Date;
  fieldTrackingModel:any = {};
  isModalOpen = false;
  audienceList = ['Ailments','Company Size - Small (0-100 EE)','Company Size - Med (100-500 EE)','Company Size - Large (500+EE)','Acculturation','DM: HR','DM: IT','DM: Marketing','Ethnicities','Geo','Hispanics','Moms','Parents',
    'Payer: Individual','Payer: Medicare','Payer: Medicaid', 'SBO (small biz owner <100)'];
  audienceItems:any = [];

  openModalRef: BsModalRef;
  @ViewChild('addDedupeProjectModal') modalRef: BsModalRef;
  @ViewChild('modalFieldTracking') modalFieldTracking: TemplateRef<any>;
  asyncSearchDupeProjects: string;
  dupeProjectDataSource: Observable<any>;
  saveDedupeDelay;

  loaded = false;
  hasChanges = false;
  surveyId = '';
  selectedPlatform = '';
  suveyProject = {} as SurveyTesterProject;
  errorMessage = ''
  surveyUrl = '';
  surveyUrlDecode = '';
  info: any = {};
  isLive = false;
  statusFilter = ['open'];
  surveyStatus = '';
  reviewers: SurveyTesterUser[];
  programmers: SurveyTesterUser[];
  guests: SurveyTesterUser[];

  newContact;
  contactFirstName;
  contactLastName;
  companyPosition;
  phoneNumber;
  email;
  profilePhoto;
  securityConfigs = [];
  showSurveySecurityKey = true;
  cleanIdThresholds = {'1000' : 'No Threshold', '30' : 'Conservative (30)', '25' : 'Moderate (25)', '20' : 'Aggressive (20)', '-1' : 'Using API'}
  endDateChangeReasons = ['Client', 'OR', 'Scope', 'Other'];

  
  constructor(private projectService: ProjectService,
    private clientContactService: ClientContactService,
    private countryService: CountriesService,
    private surveyService: SurveyTesterService,
    private clientService: ClientService,
    private userService: UserService,
    private dropdownService: DropdownService,
    private alertify: AlertifyService,
    public auth: AuthService,
    private securityKeyService: SecurityKeyService,
    private modalService: BsModalService) { }
    
    
  ngOnInit() {
    this.dupeProjectDataSource = new Observable((observer: Observer<any>) => {
      return this.projectSearch(this.asyncSearchDupeProjects).subscribe((items: any) => {     
       items.sort((a, b) => { if (a['projectCode'] > b['projectCode']) {
        return -1;
      } });
        observer.next(items);
      });
    });
    this.initAudience(this._project.audience);
    this.ready = true;
    this.getSecretKeys();
  }

  projectSearch = (filter) => { return this.projectService.CopySearch(filter); }

  scrollTo(sectionId: string): void {
    this.currentSession = sectionId;
    const element = document.querySelector(sectionId);
    if (element && this.scrollableContent) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  getProjectTypes() {
    if (this._project.type == null) {
      this.projectTypeValue = this.projectType?.name;
    } else {
      this.projectTypeValue = this._project.type;
    }
  }


  updateProjectField(field) {  
    let value = this._project[field];

    if (field === 'fraudDetectionThreshold') {
      this._project.fraudDetectionThreshold = Number(value);
      value = Number(value);
    }
    if (field == 'sellCPI' || field == 'projectIR' || field == 'projectLOI') {
      value = this._project.projectSegments[0][field];
      if (value == this.origProject.projectSegments[0][field])
        return;

      this.origProject.projectSegments[0][field] = value;
    }
    else {
      if (value == this.origProject[field])
        return;

      this.origProject[field] = value;
    }

    let fieldLabel = "Field";
    if (field == 'projectName') fieldLabel = 'Project Name';
    else if (field == 'category') fieldLabel = 'Respondent Type';
    else if (field == 'programmingComplexity') fieldLabel = 'Programming Complexity';
    else if (field == 'projectTags') fieldLabel = 'Tags';
    else if (field == 'countries') fieldLabel = 'Countries';
    else if (field == 'clientId') fieldLabel = 'Client';
    else if (field == 'clientContactId') {
      fieldLabel = 'Point of Contact';
      var clientContact = this.clientContacts.find(e => e.id == this._project.clientContactId);
      if (clientContact) this._project.clientContactName = clientContact.firstName + ' ' + clientContact.lastName;
    }
    else if (field == 'internalAccountOwnerId') fieldLabel = 'Account Owner';
    else if (field == 'showLandingPage') fieldLabel = 'E-mail Security Page';
    else if (field == 'isDupeTrackingOnly') fieldLabel = 'Track Duplications Only';
    else if (field == 'isFraudDetectionEnabled') fieldLabel = 'CleanID';
    else if (field == 'fullLaunchQuota') fieldLabel = 'Completes';
    else if (field == 'softLaunchQuota') fieldLabel = 'Soft Launch Goal';
    else if (field == 'surveySecurityKey') {
      fieldLabel = 'Survey Security Key';
      this._project.surveySecurityKeyName = this.securityConfigs.find(x => x.id === this._project.surveySecurityKey)?.name || null;
    }

    this.projectService.UpdateProjectValue(this._project.id, field, value).subscribe(data => {
      this.alertify.success(fieldLabel + ' Updated Successfully');
    }, error => { this.alertify.error('There was an issue updating ' + fieldLabel);  });

    if (field == 'category') {
      this._project.isFraudDetectionEnabled = true;
      this.updateProjectField('isFraudDetectionEnabled');

      if (this._project.category == 'Consumer') {
        this._project.isDupeTrackingOnly = false;
        this.updateProjectField('isDupeTrackingOnly');
      }
      else if (this._project.category == 'B2B') {
        this._project.showLandingPage = true;
        this.updateProjectField('showLandingPage');
        this._project.isDupeTrackingOnly = false;
        this.updateProjectField('isDupeTrackingOnly');
      }
      else if (this._project.category == 'Healthcare') {
        if (this._project.isDupeTrackingOnly == false) {
          this._project.isDupeTrackingOnly = true;
          this.updateProjectField('isDupeTrackingOnly');
        }
        if (this._project.showLandingPage == false) {
          this._project.showLandingPage = true;
          this.updateProjectField('showLandingPage');
        }
        if (this._project.fraudDetectionThreshold != 30) {
          this._project.fraudDetectionThreshold = 30;
          this.updateProjectField('fraudDetectionThreshold');
        }

      }
    }

    if (field == 'fullLaunchQuota' && this._project.softLaunchQuota == 0) {
      this._project.softLaunchQuota = Math.floor(this._project.fullLaunchQuota / 10);
      this.updateProjectField('softLaunchQuota');
    }

  }
  updateInternalAccountOwner(event) {
    this._project.internalAccountOwnerId = (event == null || event.length == 0) ? null : event[0].id;
    this.updateProjectField('internalAccountOwnerId')
  }

  updateProjectDate(field, event) {
    const newDate = this.DateAsUTCDate(this._project[field]);
    // endDate tracking only available for OR instances
    if (this.auth.isORInstance() && this.fieldEndDateOldValue) {

      if (field === 'fieldEndDate') {
        this.fieldTrackingModel = {
          field: 'fieldEndDate',
          reasonType: '',
          reason: '',
          OldValue: this.fieldEndDateOldValue,
          NewValue: newDate
        };

        this.isModalOpen = true;
        this.openModalRef = this.modalService.show(this.modalFieldTracking, { ignoreBackdropClick: true, keyboard: false, class: 'nav-modal-style modal-dialog-centered modal-change-reason'  });
      }
       else {
        this.projectService.UpdateProjectValue(this._project.id, field, newDate).subscribe(data => {
          this.alertify.success('Date Updated Successfully');
          if (field === 'fieldEndDate') this.fieldEndDateOldValue = newDate;
        }, error => {
          this.alertify.error('There was an issue updating Date');
        });
       }
    }
    else {
      this.projectService.UpdateProjectValue(this._project.id, field, newDate).subscribe(data => {
        this.alertify.success('Date Updated Successfully');
        if (field === 'fieldEndDate') this.fieldEndDateOldValue = newDate;
      }, error => {
        this.alertify.error('There was an issue updating Date');
      });
    }
  }

  closeFieldTrackingModal() {
    this._project['fieldEndDate'] = this.fieldEndDateOldValue;
    this.isModalOpen=false;
    this.openModalRef.hide();
  }

  AddProjectFieldTracking(field: string) {

    if (this.fieldTrackingModel.reasonType == 'Other' && !this.fieldTrackingModel.reason.trim()) {
      this.alertify.warning('Please type in the other reason.');
      return;
    }

    forkJoin([
      this.projectService.AddProjectFieldTracking(this._project.id, this.fieldTrackingModel),
      this.projectService.UpdateProjectValue(this._project.id, field, this.fieldTrackingModel.NewValue)
    ]).subscribe((data) => {
      this.alertify.success('Date Updated Successfully');
      this.fieldEndDateOldValue = this.fieldTrackingModel.NewValue;
      this.isModalOpen = false;
      this.openModalRef.hide();
    }, (error) => {
      this.alertify.error('There was an issue updating Date');
    })
  }

  updateProjectToggleField(field, event) {
    if (field == 'quotaMode') {
      this._project.quotaMode = (event) ? 'Complete' : 'Off';
    }
    else {
      this._project[field] = event;
    }
    this.updateProjectField(field);
  }
  updateProjectCountries(field, event) {
    if (field == 'countries') {
      this._project[field] = event.map(e => e.value);
    }
    else if (field == 'fraudDetectionGeoCountry') {
      this._project[field] = event.map(e => {
        let foundCountry = this.countryList.find(country => country.name === e.value)
        if (foundCountry) {
          return foundCountry.iso2
        }
      }
      ).join(',');
    }
    this.updateProjectField(field);
  }
  updateProjectTags(event) {
    const tags = event;
    this.projectService.UpdateProjectTags(this._project.id, tags).subscribe(data => {
      this._project.projectTags = tags.map((str) => ({
        tag: str
      }));
      this.onChange.emit(null);
      this.alertify.success('Tags Updated Successfully');
    }, error => { this.alertify.error('There was an issue updating Tags'); });
  }
  updateProjectTeam(field, event) {
    if (field == 'projectManager') this.projectManager = event;
    else if (field == 'projectSecondaryManager') this.projectSecondaryManager = event;

    var team = this.projectSecondaryManager.map(e => ({ id: uuidv4(), userId: e.id, displayName: (e.name ?? e.firstName + ' ' + e.lastName), projectRole: 'Secondary PM' }));
    var PM = this.projectManager.map(e => ({ id: uuidv4(), userId: e.id, displayName: (e.name ?? e.firstName + ' ' + e.lastName), projectRole: 'PM' }));
    team = team.concat(PM);

    this.projectService.UpdateProjectTeam(this._project.id, team).subscribe(data => {
      this._project.projectTeam = team;
      this.onChange.emit(null);
      this.alertify.success('Team updated successfully')
    }, error => {
      this.alertify.error('There was an issue updating the project team (PM or Secondary PM).');
    });
  }
  updateClient(evt) {
    this._project.clientId = null;
    this._project.clientName = null;

    if (evt.length > 0) {
      this._project.clientId = evt[0].value;
      this._project.clientName = evt[0].name;
    }
    else {
      this._project.clientId = null;
      this._project.clientName = '';
    }
    this.updateProjectField('clientId');

    this.clientContacts = [];
    if (!this._project.clientId) {
      this.clientContacts = [];
      return;
    }

    this.clientContactService.GetClientContacts(this._project.clientId).subscribe(data => {
      this.clientContacts = data;
    });

    this.clientService.GetClient(this._project.clientId, true).subscribe(data => {
      if (this._project.internalAccountOwnerId == null && data.internalAccountOwnerId != null) {
        this.internalAccountOwner = this.userList.filter(e => e.id == data.internalAccountOwnerId);
        this.updateInternalAccountOwner(this.internalAccountOwner);

      }
      else this.internalAccountOwner = [];
    });

  }

  getClients = () => {
    return of(this.clients);
  }

  openAddDedupeProjectModal(modal) {
    this.openModalRef = this.modalService.show(modal, { animated: false, keyboard: false, class: 'modal-project-setup nav-modal-style modal-md' });
  }
  addDedupeProject(match: TypeaheadMatch): void {
    this.openModalRef.hide();

    const row = {
      id: uuidv4(),
      projectId: this._project.id,
      tag: '',
      otherId: match.item.id,
      otherName: match.item.projectName,
      start: false,
      complete: true,
      oq: false,
      qc: true,
      terminate: false
    };
    this._project.projectDedupeStatus.push(row);
    this.asyncSearchDupeProjects = '';
    this.saveDedupeSettings(false);

  }
  closeAddDedupeProjectModal() {
    this.openModalRef.hide();
  }
  removeOtherDedupeRow(row) {
    this._project.projectDedupeStatus = this._project.projectDedupeStatus.filter(e => e.id != row.id);
    this.saveDedupeSettings(false);
  }
  changeDedupeStatus(row) {
    this.saveDedupeSettings(true);
  }
  saveDedupeSettings(delay) {

    if (!delay) {
      this.projectService.UpdateProjectDedupeStatus(this._project.id, this._project.projectDedupeStatus).subscribe(data => {
        this.alertify.success('DeDupe Settings Updated for this project');
      }, error => {
        this.alertify.error('DeDupe Settings Failed to Update');
      });
    }
    else {
      if (this.saveDedupeDelay != null)
        clearTimeout(this.saveDedupeDelay);

      this.saveDedupeDelay = setTimeout(() => { this.saveDedupeSettings(false) }, 1000);
    }

  }

  getUserList = (filter) => { return of(this.userList) }
  getCountryList = (filter) => { return of(this.countryList) }
  DateAsUTCDate(date) {
    if (date != null) {
      date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 12));
    }
    return date;
  }
  getDropdownCountryList = (filter) => {
    return this.dropdownService.getCountries();
  }

  addNewContact(modal) {
    this.contactFirstName = null;
    this.contactLastName = null;
    this.companyPosition = null;
    this.phoneNumber = null;
    this.email = null;
    this.profilePhoto = null;

    this.newContact = {
      id: uuidv4(),
      mode: 'new',
      billing: false,
      firstName: null,
      lastName: '',
      phone: '',
      email: '',
      clientId: this._project.clientId,
      companyPosition: '',
      profilePhoto: '',
      address: {
        address1: '',
        address2: '',
        city: '',
        country: ''
      },
      social: {
        facebook: '',
        linkedIn: '',
        twitter: '',
        skype: ''
      }
    };

    this.openModalRef = this.modalService.show(modal, { animated: true, keyboard: false, class: 'modal-lg' });

    const modals = Array.from(document.getElementsByClassName("modal-content") as HTMLCollectionOf<HTMLElement>);
    modals.forEach((modal) => {
      modal.style.width = "85%";
    });
  }

  profilePhotoUploadCompleted(img) {
    img.url = this.profilePhoto;
  }

  saveNewContact() {
    this.newContact.firstName = this.contactFirstName;
    this.newContact.lastName = this.contactLastName;

    this.newContact.companyPosition = this.companyPosition;
    this.newContact.email = this.email;
    this.newContact.profilePhoto = this.profilePhoto;

    if (this.newContact.email == null || this.newContact.email == "") {
      this.alertify.error('Email is required');
      return;
    }

    this.clientContactService.AddClientContact(this.newContact).subscribe(data => {
      this._project.clientContactId = this.newContact.id;
      this._project.clientContactName = this.newContact.firstName + ' ' + this.newContact.lastName;

      this.updateProjectField('clientContactId');

      this.clientContactService.GetClientContacts(this._project.clientId).subscribe(data => {
        this.clientContacts = data;
        this.alertify.success('Contact added successfully');
        this.openModalRef.hide();
      });
    }, error => {
      this.alertify.error('Unable to add contact');
    });
  }

  cancelNewContact() {
    this.openModalRef.hide();
  }

  formChanged(event) { if (this.loaded) this.hasChanges = true; }

  getProjectInfo() {
    let url = this._project.projectSegments[0]?.testSurveyLink || '';
    this.selectedPlatform = this._project.projectSegments[0]?.surveyPlatForm;
    if (!url) {
      this.errorMessage = "No Survey added to this project"
    }
    this.surveyUrlDecode = url;
    this.surveyUrl = encodeURIComponent(url);
    this.suveyProject = this.surveyService.getSurveyProject(url, this.selectedPlatform);
    this.surveyService.getSurveyId(this.suveyProject).pipe(
      switchMap(data => {
        this.surveyId = data.id;
        return this.surveyService.getProjectdetails(this.surveyId, this._project.id);
      })
    ).subscribe(data => {   
      this.info = data;
      this.surveyStatus = data.status;
      this.isLive = data?.projects.some(x => status.includes(x.projectStatus));    
      this.programmers = data.programmers.map(x => ({ id: x.id, userId: x.userId, fullName: x.fullName }));
      this.reviewers = data.reviewers.map(x => ({ id: x.id, userId: x.userId, fullName: x.fullName }));

      this.guests = data.guests.map(x => ({
        id: x.id,
        fullName: x.fullName,
        email: x.email,
        expiresAt: x.expiresAt
      }));
    }, error => {
      this.alertify.error('There was an error getting survey tester id');
    });
  }

  updateProgrammers(value) {   
    this.programmers = [];
    if (value.length > 0) {
      value.forEach(e => {
        this.programmers.push({
          userId: e.userId,
          role: 'programmer',
          fullName: e.fullName,
          email: e.email,
          projectId: this._project.id,
          surveyTesterProjectId: this.surveyId
        });
      });
    }

    this.surveyService.updateProgrammers(this.surveyId, this._project.id, this.programmers).subscribe((data) => {
      this.alertify.success('Programmers have been updated.');
    }, error => {
      this.alertify.error('There was a problem updating programmers.');
    });
  }
 
  getUserProgrammerList = (filter) => {
    return this.userService.GetUsers().pipe(map((res: User[]) => res.map(user => ({ userId: user.id, fullName: user.name }))));
  }
  getSecretKeys() {
    this.securityKeyService.getSecurityKeys(this.auth.getUser().instanceId).subscribe(data => {
      this.securityConfigs = data;
      if (this._project.surveySecurityKey != null) {
        this._project.surveySecurityKeyName = this.securityConfigs.find(x => x.id === this._project.surveySecurityKey)?.name;
      }
    }, error => {
      this.alertify.error('Unable to fetch secret key configs');
    });
  }
  toggleSurveySecurityKey() {
    this.showSurveySecurityKey = !this.showSurveySecurityKey;
    if(!this.showSurveySecurityKey){
      this._project.surveySecurityKey = 'null';
      this.updateProjectField('surveySecurityKey');
      this._project.surveySecurityKey = null;
    }
  }

  asIsOrder(a, b) {
    return 1;
  }
  initAudience(audiences){
    this.audienceItems = [];
    if(audiences == null || audiences == ''){
      this.addNewAudienceItem();
    }else{
      var audienceItems =  audiences.split(',');
      audienceItems.forEach(audience => {
        if(this.audienceList.includes(audience))
          this.audienceItems.push({value: audience, otherValue: '', isOther: false});
        else
          this.audienceItems.push({value: 'Other', otherValue: audience, isOther: true});
      });
    }
  }
  updateAudience(audience, label){
    audience.value = label;
    audience.isOther = false;
    audience.otherValue = '';
    this.saveAudience();
  }
  audienceIsOther(audience){
    audience.value = 'Other';
    audience.isOther = true;
    audience.otherValue = '';
    this.saveAudience();
  }
  addNewAudienceItem (){
    if(this.audienceItems.length ==0 || this.audienceItems[this.audienceItems.length-1].value != '')
      this.audienceItems.push({value: '', otherValue: '', isOther: false});
  }
  removeAudience(idx){
    this.audienceItems.splice(idx, 1);
    this.saveAudience();
    if(this.audienceItems.length == 0)
      this.addNewAudienceItem();

  }
  saveAudience(){
    let audience = '';
    this.audienceItems.forEach(item => {
      if(item.isOther)
        audience += item.otherValue + ',';
      else
        audience += item.value + ',';
    });
    audience = audience.slice(0, -1);

    this.projectService.UpdateProjectValue(this._project.id, 'audience', audience).subscribe(data => {
      this._project.audience = audience; 
      this.alertify.success('Audience Updated Successfully');
    }, error => { this.alertify.error('There was an issue updating Audience');  });

  }

}
