<div class="card m-2">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col-8">
        <h3 class="nav-secondary font-weight-bold text-truncate">Duplication</h3>
      </div>
      <div class="col text-right">
        <i (click)="resizeCard()" class="fas {{ mode == 'reduced' ? 'fa-cog' : 'fa-arrow-circle-left' }} fa-lg cursor-pointer opacity-90 text-white"></i>
      </div>
    </div>
  </div>
  @if (project.isDeDupeParticipantEnabled && mode == 'reduced') {
    <div class="card-body">
      <div class="row pb-2">
        <div class="col">
          <p class="h4 font-weight-bold or-secondary"> {{ dupe / (total + dupe) | percent:'1.1-1' }} Dupe rate</p>
        </div>
      </div>
      <div class="row chart or-gray-bg">
        @if (dupe == null) {
          <div class="col">
            @if (loading) {
              <div class="loading"></div>
            }
          </div>
        }
        @if (dupe == 0) {
          <div class="col">
            <div class="or-secondary">No Data</div>
          </div>
        }
        @if (dupe > 0) {
          <div class="col">
            <or-trend-chart [data]="trendData" (callback)="loading = false"></or-trend-chart>
          </div>
        }
      </div>
      <div class="row py-3">
        <div class="col">
          <p class="h5 mb-0 font-weight-normal or-secondary">{{ dupe }} dupes</p>
        </div>
      </div>
    </div>
  }
  @if (!project.isDeDupeParticipantEnabled && mode == 'reduced') {
    <div class="card-body">
      <div class="row">
        <div class="col"></div>
      </div>
      <div class="row my-2 or-gray-bg">
        <div class="col p-3 text-center">
          <div class="row m-2">
            <div class="col">
              <i class="fas fa-shield-alt fa-2x or-secondary"></i>
            </div>
          </div>
          <div class="row m-2">
            <div class="col">
              <p class="font-weight-bold or-secondary">DeDuplication is disabled, you may not get accurate results</p>
              <small class="d-block mb-3 or-secondary">
                Our advanced device fingerprinting allows you to dedupe participants.
                Improve your sample quality by enabling DeDuplication.
              </small>
            </div>
          </div>
          <!-- <div class="row m-2">
          <div class="col">
            <button class="btn btn-sm btn-secondary text-white border-0">Learn more</button>
          </div>
        </div>
        -->
      </div>
    </div>
    <div class="row">
      <div class="col"></div>
    </div>
  </div>
}
@if (mode == 'expanded') {
  <div class="card-body">
    <div class="row">
      <div class="col-12 information">
        <div class="row">
          <div class="col">
            <div class="h6 or-primary">Duplication Status</div>
          </div>
        </div>
        <div class="row ml-1">
          <or-dedupe-status-setup  [projectId]="project.id" [dedupeList]="dupeStatusList" (listChange)="setDupeStatusChanged($event)"></or-dedupe-status-setup>
        </div>
        @if (dupeStatusChanged) {
          <div class="row mt-3">
            <div class="col-12">
              <button class="btn btn-outline-secondary mx-1" (click)="resetStatusSetting()">
                <i class="fa fa-times fa-xs"></i>
              </button>
              <button type="button" class="btn btn-outline-secondary" (click)="saveStatusSetting()">
                <i class="fa fa-check fa-xs"></i>
              </button>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
}

<div class="card-footer py-2 or-title">

  <div class="row">
    @if (auth.isInternal()) {
      <div class="col-2" >
        @if (loading) {
          <div class="loading"></div>
        }
        @if (!loading) {
          <or-toggle-button class="light" size="small" [isChecked]="project.isDeDupeParticipantEnabled" (changed)="changeSetting($event, 'isDeDupeParticipantEnabled')"></or-toggle-button>
        }
      </div>
    }
    <div class="col-10">
      <span class="font-weight-bold ml-2">DeDupe is {{ project.isDeDupeParticipantEnabled ? 'enabled' : 'disabled' }}</span>
    </div>
  </div>
</div>

</div>
