import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { AuthService} from 'core';
import { DashboardService } from 'projects/core/src/services/dashboard.service';
import Highcharts from 'highcharts';

@Component({
  selector: 'app-widget-project-health-by-client',
  templateUrl: './widget-project-health-by-client.component.html',
  styleUrls: ['./widget-project-health-by-client.component.scss']
})
export class WidgetProjectHealthByClient implements OnInit {

  @Input() widget;
  @Input() updated;

  @Output() onremove = new EventEmitter();
  @Output() onupdate = new EventEmitter();

  highcharts = Highcharts;
  highchartOptions: Highcharts.Options;
  projectTypes = ['Good', 'Warning', 'Bad', 'Critical'];

  projectByTypeMyProjects = true;

  settings = {
    myProjects : false,
    timeSpan : null
  };


  constructor(
    public http: HttpClient,
    public auth: AuthService,
    public dashboardService: DashboardService) { }

  ngOnInit() {
  }

  getChartData() {
    this.dashboardService.getProjectHealthByClient(this.settings).subscribe(data => {
      data = this.buildDataCountsArray(data);
      this.drawHighChartsChart(data.header, data.dataCounts)
    })
  }

  ngOnChanges(changes: SimpleChanges) {
      this.widget = changes.widget.currentValue
      this.getChartData();
  }


  buildDataCountsArray(data) {
    let header = []; // clients
    let dataCounts: any = { };
    data.forEach(row => {
      delete row.id;
      if(row.clientName === '') row.clientName = 'Blank';
      if(row.status === '') row.status = 'Blank';
      if(dataCounts[row.clientName] == null){
        header.push(row.clientName);
        dataCounts[row.clientName] = {};
      }
      dataCounts[row.clientName][row.status] = row.count;
    });
    return {header, dataCounts};
  }

  drawHighChartsChart(header, dataCounts) {

    let chartData = [];

    this.projectTypes.forEach((status: string) => {
      var color = "#808080";
      if (status == "Good") color = '#7db713';
      if (status == "Warning") color = '#FDDA0D';
      if (status == "Bad") color = '#F28C28';
      if (status == "Critical") color = '#e02d38';
      var item = {
        name: status,
        data: [],
        color: color
      };
      header.forEach(h => {
        item.data.push(dataCounts[h][status] ?? 0)
      });
      chartData.push(item);
    });

    this.highchartOptions = {
      credits: {
        enabled: false
      },
      chart: {
        type: 'column',
        events: {
          render: function() {
            this.reflow();
          }
        }
      }, title: {
        text: ''
      }, xAxis: {
        categories: header
      }, yAxis: {
        min: 0,
        title: {
          text: 'Projects'
        }, stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: ( // theme
              Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color
            ) || 'gray',
            textOutline: 'none'
          }
        }
      }, legend: {
        align: 'right',
        x: 0,
        verticalAlign: 'top',
        y: 0,
        floating: true,
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      }, tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      }, plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      }, series: chartData
    };
  }

  remove() {
    this.onremove.emit();
  }

  updateWidget() {
    this.onupdate.emit();
  }

  configWidget() {
  }

  updateTitle(widgetTitle) {
    this.widget.title = widgetTitle;
    this.updateWidget();
  }
}
