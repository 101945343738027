
  <div class="container-fluid scheduleContainer" [class.nav-content]="project == null && bidNumber == null"
    [ngClass]="templateMode == 'edit' ? 'pr-2 pl-4' : (templateMode == 'view' ? 'px-2' : (project == null || bidNumber == null ? '' : 'px-4'))">
    @if (project == null && bidNumber == null) {
    <!-- BREADCRUMBS -->
    <div class="row">
      <div class="col-12 breadcrumb mr-auto bg-transparent mb-0 py-0">
        <div class="d-flex align-items-center">
          <div class="nav-secondary d-flex align-items-center">
            <app-pagetitle></app-pagetitle>
          </div>
        </div>
      </div>
    </div>
    }

    <!-- Filter text, view modes and dropdown buttons-->
    @if (!templateMode) {
    @if (project != null) {
    <div class="row pb-3">
      <div class="col nav-secondary font-weight-bold nav-font18">
        {{ editMode ? 'Editing ' + project.projectCode + ' Schedule' : project.projectCode + ' Schedule'}}
      </div>
      @if (editMode && !templateMode) {
      <div class="col d-flex justify-content-end align-items-center mr-3">
        <span class="exit-edit-mode nav-font14 font-weight-bold text-white cursor-pointer" (click)="handleEditMode()">
          Exit edit mode <i class="fas fa-close fa-lg text-white font-weight-normal ml-1"></i>
        </span>
      </div>
      }
    </div>
    }
    }

    @if (templateMode == 'edit') {
    <div class="row pr-2 pt-2 mt-1 ml-0">
      <div class="row align-items-center nav-secondary mb-0 ml-0 pt-2 font-weight-bold nav-font24">
        <i class="fak fa-templates fa-lg font-weight-normal grey4 mr-2"></i>
        <div class="d-inline">Save this schedule as a template</div>
      </div>
      <div class="d-flex justify-content-end pr-3 pt-1 ml-auto">
        <i class="fas fa-close grey4 nav-font18" (click)="cancelTemplate.emit()"></i>
      </div>
    </div>
    <div class="row mt-3 pt-1 mx-0">
      <div class="col-12 col-md-12 grey5">
        <div class="row mb-3 nav-font16 font-weight-bold">
          Enter a name below and make any necessary edits, then click 'Save' to finalize and turn this schedule into a
          template.
        </div>
        <div class="row mb-3 nav-font16">
          <div class="col-8 d-flex align-content-center px-0">
            <div class="text-nowrap mr-3 mt-1 required">Name this template</div>
            <input class="form-control form-control-nav form-control-sm nav-font16 ml-1" maxlength="70"
              (ngModelChange)=checkCanSaveTemplate($event) [(ngModel)]="newTemplateName" placeholder="Template name">
          </div>
        </div>
      </div>
    </div>
    <hr class="mr-3 mt-2">
    }
    <div class="row" [ngClass]="templateMode ? '' : 'mb-3 mt-3'" [class.editing-mode]="inlineAddMode">
      <div class="col d-flex align-items-center">
        @if (!templateMode) {
        @if (project != null) {
        <button class="btn nav-btn-accent shadow-none px-2 mr-3 nav-font14" (click)="OpenCreateTaskModal()">Add
          Task</button>
        }
        <div class="input-group copy-search">
          <div class="input-group-prepend">
            <div class="input-group-text border-right-0">
              <i class="fas fa-search cursor-default"></i>
            </div>
          </div>
          <input [(ngModel)]="textFilter" type="search" class="form-control form-control-sm"
            (ngModelChange)="UpdateTextFilter()" placeholder="Task or stage">
        </div>
        @if (project !=null) {
        <div class="ml-3 grey5 nav-font14">
          <span class="mr-2">View</span>
          <or-view-toggle (valueChange)='SwitchView($event)' [showList]="true" [showGantt]="false"
            [showCalendar]="true" [active]="currentView"></or-view-toggle>
        </div>
        }
        }

        @if (templateMode === 'edit') {
        <div class="grey5 nav-font16 mb-1"> Edit Schedule</div>
        }

        <!-- actions -->
        <div class="ml-auto d-flex align-items-center">
          @if (!templateMode && project != null) {
          <div class="btn-group btn-group-sm ml-2" dropdown #ddTemplates="bs-dropdown" container="body">
            <div class="template-toggle d-flex align-items-center grey4 border-right pr-3 mr-2 cursor-pointer"
              dropdownToggle>
              <i class="fak fa-templates"></i>
              <span class="nav-font14 mx-1 pr-1 font-weight-normal">Templates</span>
              <i class="fas" [class.fa-angle-down]="!ddTemplates.isOpen" [class.fa-angle-up]="ddTemplates.isOpen"></i>
            </div>
            <div id="dropdown-basic" *dropdownMenu (mouseleave)="ddTemplates.hide()"
              class="dropdown-menu dropdown-templates dropdown-menu-right cursor-pointer nav-font14" role="menu"
              aria-labelledby="button-basic">
              <a class="dropdown-item ml-2 pl-2 border-bottom pr-1" role="menuitem" (click)="viewTemplates()">Use a
                template</a>
              <a class="dropdown-item ml-2 pl-2 pr-1" role="menuitem" (click)="CreateTemplate()">Save as template</a>
            </div>
          </div>
          }

          <!-- filters -->
          <ng-container *ngIf="!templateMode && !editMode">
            <div class="filters" *ngIf="this.filters.length>0 && bidNumber == null">
              <div class="d-flex">
                <div class="badge mr-1 text-white font-weight-normal" *ngFor="let filter of filters">
                  {{filter.label}}
                  <i class="fas fa-close ml-2" (click)="UpdateFilter(filter.type, false, filter.id)"></i>
                </div>
              </div>
            </div>
            <div class="btn-group btn-group-sm" dropdown #dropdown="bs-dropdown" container="body" [insideClick]="true">
              <button type="button" dropdownToggle class="btn btn-outline-none" controls="dropdown-basic">
                <i class="fak fa-filter fa-xs grey4 cursor-pointer" [class.filter-mode]="dropdown.isOpen"
                  [ngClass]="bidNumber != null ? 'fa-s' : 'fa-xs'"></i>
              </button>
              <div *ngIf="filters.length > 0 && bidNumber != null" class="filterNumber">
                <span class="">
                  {{filters.length}}
                </span>
              </div>
              <div style="width:260px" class="dropdown-menu dropdown-filters dropdown-menu-right show p-3"
                *dropdownMenu>
                <div class="row">
                  <div class="col d-flex">
                    <h5 class="nav-secondary mb-0 font-weight-bold nav-font14">Filter by</h5>
                    <i class="fas fa-close grey4 ml-auto" (click)="dropdown.hide()"></i>
                  </div>
                </div>
                <div class="row mt-1 mb-3">
                  <div class="col nav-font12 nav-accent-blue">
                    <span (click)="ClearAllFilters();" class="cursor-pointer">Reset
                      Filters</span>
                  </div>
                </div>
                <div class="filter-section">
                  <div class="col px-0">
                    <span class="grey4 nav-font14">My Tasks</span>
                    <i class="fas fa-angle-down float-right grey4" [class.fa-rotate-180]="this.isTaskExpanded"
                      (click)="this.isTaskExpanded = !this.isTaskExpanded;"></i>
                  </div>
                  <div *ngIf="isTaskExpanded" class="filter-content mt-2 border-bottom pb-2">
                    <div class="or-checkbox d-flex justify-content-start align-items-center px-1">
                      <span container="body">
                        <input type="checkbox" id="upcoming" [(ngModel)]="upcoming"
                          (change)="UpdateFilter('upcoming', $event.target.checked)">
                        <label for="upcoming" class="grey5 nav-font14"></label>
                      </span>
                      <span class="grey5 pl-4 nav-font14">My upcoming tasks</span>
                    </div>
                    <div class="or-checkbox d-flex justify-content-start align-items-center px-1">
                      <span container="body">
                        <input type="checkbox" id="todo" [(ngModel)]="myDueToday"
                          (change)="UpdateFilter('todo', $event.target.checked)">
                        <label for="todo" class="grey5 nav-font14"></label>
                      </span>
                      <span class="grey5 pl-4 nav-font14">My tasks due today</span>
                    </div>
                    <div class="or-checkbox d-flex justify-content-start align-items-center px-1">
                      <span container="body">
                        <input type="checkbox" id="overdue" [(ngModel)]="myOverdue"
                          (change)="UpdateFilter('overdue', $event.target.checked)">
                        <label for="overdue" class="grey5 nav-font14"></label>
                      </span>
                      <span class="grey5 pl-4 nav-font14">My overdue tasks</span>
                    </div>
                  </div>
                </div>

                <div class="filter-section mt-2">
                  <div class="col px-0">
                    <span class="grey4 nav-font14">Task Status</span>
                    <i class="fas fa-angle-down float-right grey4" [class.fa-rotate-180]="this.isStatusExpanded"
                      (click)="this.isStatusExpanded = !this.isStatusExpanded;"></i>
                  </div>
                  <div *ngIf="isStatusExpanded" class="filter-content mt-2 border-bottom pb-2">
                    <div class="or-checkbox d-flex justify-content-start align-items-center px-1">
                      <span container="body">
                        <input type="checkbox" id="pending" [(ngModel)]="pending"
                          (change)="UpdateFilter('pending', $event.target.checked)">
                        <label for="pending" class="grey5 nav-font14"></label>
                      </span>
                      <span class="grey5 pl-4 nav-font14">Pending</span>
                    </div>
                    <div class="or-checkbox d-flex justify-content-start align-items-center px-1">
                      <span container="body">
                        <input type="checkbox" id="complete" [(ngModel)]="complete"
                          (change)="UpdateFilter('complete', $event.target.checked)">
                        <label for="complete" class="grey5 nav-font14"></label>
                      </span>
                      <span class="grey5 pl-4 nav-font14">Complete</span>
                    </div>
                  </div>
                </div>

                <div class="filter-section mt-2">
                  <div class="col px-0">
                    <span class="grey4 nav-font14">Assigned Users</span>
                    <i class="fas fa-angle-down float-right grey4" [class.fa-rotate-180]="this.isUserExpanded"
                      (click)="this.isUserExpanded = !this.isUserExpanded;"></i>
                  </div>
                  <div *ngIf="isUserExpanded" class="filter-content mt-2">

                    <div class="col px-0 mb-3">
                      <input type="search" [(ngModel)]="userFilter" placeholder="Search users" autocomplete="off"
                        class="form-control form-control-sm form-control-nav">
                    </div>
                    <div class="scrollbar overflow-y overflow-x-hidden nav-font14 grey5" style="height:200px;">
                      <div *ngFor="let user of users; let i = index">
                        <div
                          *ngIf="user.firstName.toLowerCase().indexOf(userFilter.toLowerCase()) >= 0  || user.lastName.toLowerCase().indexOf(userFilter.toLowerCase()) >= 0">
                          <div class="or-checkbox d-flex justify-content-start align-items-center px-1">
                            <span container="body">
                              <input type="checkbox" [id]="'selectUser'+user.id"
                                (change)="user.filter = $event.target.checked; UpdateFilter('user', $event.target.checked, user.id);"
                                [checked]="user.filter">
                              <label [for]="'selectUser'+user.id"></label>
                            </span>
                            <span class="grey5 pl-4 nav-font14">{{ user.firstName }} {{ i == 0 ? user.lastName :
                              user.lastName }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <!-- edit mode -->
          @if (project != null) {
          @if (!editMode && !templateMode) {
          <div class="btn-group btn-group-sm" container="body">
            <button id="button-basic" type="button" class="btn btn-outline-none pl-1" (click)="handleEditMode()">
              <i class="fak fa-edit-square grey4"></i>
            </button>
          </div>
          }
          @if (!templateMode) {
          @if (editMode) {
          <div class="btn-group btn-group-sm ml-2" dropdown #ddEdit="bs-dropdown" container="body">
            <div class="edit-mode-toggle d-flex align-items-center grey4 mr-3 mb-1 cursor-pointer" dropdownToggle
              (click)="endInline()">
              <i class="fak fa-edit-square"></i>
              <span class="mx-1 pr-1 pb-1 ml-2 font-weight-normal"
                [ngClass]="templateMode === 'edit' ? 'mt-2 nav-font16' : 'nav-font14'">Edit</span>
              <i class="fas" [class.fa-angle-down]="!ddEdit.isOpen" [class.fa-angle-up]="ddEdit.isOpen"
                [class.mt-1]="templateMode"></i>
            </div>
            <div id="dropdown-basic" *dropdownMenu (mouseleave)="ddEdit.hide()"
              class="dropdown-menu dropdown-edit dropdown-menu-right cursor-pointer nav-font14" role="menu"
              aria-labelledby="button-basic">
              <a class="dropdown-item ml-2 pl-2 border-bottom" role="menuitem" (click)="newBulkDeleteModel()"><i
                  class="fak fa-trash mr-1"></i> Delete</a>
              <a class="dropdown-item ml-2 pl-2" role="menuitem" (click)="bulkassignDateModel()"><i
                  class="fak fa-clock-change mr-1"></i> Change dates</a>
              <a class="dropdown-item ml-2 pl-2 border-bottom" role="menuitem" (click)="ExportSchedule()"><i
                  class="fak fa-download mr-1"></i> Download template</a>
              <a class="dropdown-item ml-2 pl-2 border-bottom" role="menuitem" (click)="ImportSchedule()"><i
                  class="fak fa-import-schedule mr-1"></i> Import schedule</a>
              <a class="dropdown-item ml-2 pl-2" role="menuitem" (click)="CloneSchedule()"><i
                  class="fak fa-clone mr-1"></i> Clone a schedule</a>
            </div>
          </div>
          }
          }
          }

          @if (project == null) {
          <div class="btn-group btn-group-sm" dropdown #ddDots="bs-dropdown" container="body">
            <button id="button-basic" dropdownToggle type="button" class="btn btn-outline-none"
              controls="dropdown-basic">
              <i class="fak fa-ellipsis-vertical grey4" [class.filter-mode-dots]="ddDots.isOpen"
                [ngClass]="bidNumber != null ? 'fa-lg' : ''"></i>
            </button>
            <div id="dropdown-basic" *dropdownMenu
              class="dropdown-menu dropdown-dots dropdown-menu-right cursor-pointer" role="menu"
              aria-labelledby="button-basic">
              @if (project!=null) {
              <a class="dropdown-item" role="menuitem" (click)="ImportSchedule()">Import Schedule</a>
              <a class="dropdown-item" role="menuitem" (click)="ExportSchedule()">Export Schedule</a>
              }
              <a class="dropdown-item" role="menuitem" (click)="editMode = true">Enable Edit Mode</a>
              @if (project==null) {
              <a class="dropdown-item" role="menuitem" (click)="ExportGlobalSchedule()">Export Schedule</a>
              }
              <!-- <a class="dropdown-item" *ngIf="bidNumber != null" role="menuitem" (click)="redirectToGlobalTasks()">View in Global Tasks</a> -->
            </div>
          </div>
          }

        </div>
      </div>
    </div>

    @if (loadingNewTemplate) {
    <div class="new-template-loading">
      <span class="loading"></span>
    </div>
    }
    @if (project != null && this.loading == false) {
    <div class="container-fluid pl-0 position-relative"
      [ngClass]="templateMode != 'view' ? 'schedule' : 'template-mode'">
      @if (this.projectSchedule == null || this.projectSchedule.length == 0) {
      <div>
        <div class="h5">
          Schedule is not available on this project.
        </div>
      </div>
      }
      @if (this.projectSchedule?.length > 0) {
      @if (currentView== 'list') {
      <!-- header -->
      <div class="row header grid grey4 nav-font14 grey6 border font-weight-bold mx-0"
        [ngStyle]="{'grid-template-columns': gridTemplateColumns}">
        <div class="task-name d-flex align-items-center border-right">
          @if (editMode) {
          <div class="or-checkbox selectBatch ml-3 pr-4 mr-3 d-inline">
            <span class="grey5 nav-font14 mr-2 mt-1">All</span>
            <span container="body">
              <input type="checkbox" [(ngModel)]="chkSellectAll"
                [id]="'allSelect' + (templateMode ? '_' + templateMode : '')" (change)="SelectAll($event, null)">
              <label [for]="'allSelect' + (templateMode ? '_' + templateMode : '')"></label>
            </span>
          </div>
          }
          @if (!editMode) {
          <div class="expand-toggle d-flex align-items-center cursor-pointer ml-2 h-100" (click)="ExpandCollapseAll()">
            <i class="far grey4 nav-font16" [class.fa-plus]="expandCollapseLabel==='Expand all'"
              [class.fa-minus-circle]="expandCollapseLabel==='Collapse all'"></i>
            <span class="nav-font10 grey6 ml-2 mr-2 font-weight-normal">{{expandCollapseLabel}}</span>
          </div>
          }
          Task
        </div>
        @if (!templateMode) {
        <div class="assignee d-flex align-items-center border-right pl-2">
          Assignee
          @if (!editMode) {
          <i class="far fa-plus grey4 nav-font16 pl-2 cursor-pointer" (click)="OpenModalAssigneeAcrossTasks()"></i>
          }
        </div>
        }
        @if (scheduleColumns.colStatus) {
        <div class="status d-flex align-items-center border-right pl-2">
          Status
          @if (!editMode) {
          <i class="fak fa-trash nav-error font-weight-normal nav-font16 pl-2 cursor-pointer"
            (click)="openConfirmDeleteTimeTracking('status')"></i>
          }
        </div>
        }
        @if (scheduleColumns.colTaskLength && !templateMode) {
        <div class="task-length d-flex align-items-center border-right pl-2">
          Time tracking
          @if (!editMode) {
          <i class="fak fa-trash nav-error font-weight-normal nav-font16 pl-2 cursor-pointer"
            (click)="openConfirmDeleteTimeTracking('task-length')"></i>
          }
        </div>
        }
        <div class="d-flex align-items-center border-right pl-2">Task starts</div>
        @if (templateMode) {
        <div class="task-length d-flex align-items-center border-right pl-2">
          Task length
          @if (!editMode && !templateMode) {
          <i class="fak fa-trash nav-error font-weight-normal nav-font16 pl-2 cursor-pointer"
            (click)="openConfirmDeleteTimeTracking('task-length')"></i>
          }
        </div>
        }
        @if (!templateMode) {
        <div class="d-flex align-items-center border-right pl-2">Task ends</div>
        }
        @if (scheduleColumns.colReoccurrence || templateMode) {
        <div class="reoccurrence d-flex align-items-center border-right pl-2">
          Reoccurrence
          @if (!editMode) {
          <i class="fak fa-trash nav-error font-weight-normal nav-font16 pl-2 cursor-pointer"
            (click)="openConfirmDeleteTimeTracking('reoccurrence')"></i>
          }
        </div>
        }
        @if (templateMode != 'view') {
        <div class="add-field d-flex align-items-center justify-content-center" dropdown #ddAddField="bs-dropdown"
          [container]="addFieldContainer">
          @if (!includedOnRelease && !(scheduleColumns.colTaskLength && scheduleColumns.colReoccurrence)) {
          <div class="dot d-flex align-items-center justify-content-center cursor-pointer"
            [class.active]="ddAddField.isOpen" dropdownToggle tooltip="Add field" containerClass="tooltip-add-field"
            container="body" placement="bottom">
            <i class="fa fa-plus"></i>
          </div>
          }
          <div id="dropdown-basic" *dropdownMenu (mouseleave)="ddAddField.hide()"
            class="dropdown-menu dropdown-add-field dropdown-menu-right cursor-pointer nav-font14" role="menu"
            aria-labelledby="button-basic">
            <!-- <a *ngIf="!scheduleColumns.colStatus" class="dropdown-item ml-2 pl-2 border-bottom pr-1" role="menuitem" (click)="addField('status')">Status</a> -->
            @if (!scheduleColumns.colReoccurrence) {
            <a class="dropdown-item ml-2 pl-2 pr-1" [class.border-bottom]="!scheduleColumns.colTaskLength"
              role="menuitem" (click)="addField('reoccurrence')">Reoccurrence</a>
            }
            @if (!scheduleColumns.colTaskLength) {
            <a class="dropdown-item ml-2 pl-2 pr-1" role="menuitem" (click)="addField('task-length')">Time tracking</a>
            }
          </div>
        </div>
        }
      </div>
      <!-- draggable zone -->
      <div class="scrollbar overflow-y" [class.border-bottom]="templateMode != 'view'">
        @for (stage of projectSchedule; track stage; let isLastStage = $last) {
        @if (stage.showStage && stage.isActive || editMode || templateMode) {
        <div class="row stage" [class.mx-0]="templateMode == 'view'" [ngClass]="!stage.isActive ? 'inactiveStage' : ''">
          <div class="col border-right" [class.border-left]="templateMode != 'view'"
            [class.px-0]="templateMode == 'view'">
            <!-- header -->
            <div class="stageRow row py-1"
              [class.border-bottom]="!templateMode || !isLastStage || (stage.isOpen && isLastStage && (templateMode == 'edit' || stage.projectTasks.length > 0))">
              <div class="col d-flex align-items-center">
                <div class="col px-0">
                  <div class="d-flex align-items-center">
                    <span>
                      <i class="far fa-lg fa-angle-down grey4 mr-2"
                        [ngStyle]="{'pointer-events': (stage.isActive ? 'all' : 'none')}"
                        [class.fa-angle-up]="stage.isOpen" [class.fa-angle-down]="!stage.isOpen"
                        (click)="ExpandCollapseStage(stage)">
                      </i>
                    </span>
                    @if (!editMode || templateMode === 'edit') {
                    <span class="dot d-inline-block mr-2" [style]="'background-color:'+ stage.color+';'"></span>
                    }
                    @if (editMode && !templateMode) {
                    <div class="or-checkbox edit d-inline-block mr-4 pr-2"
                      [ngStyle]="{'pointer-events': (stage.isActive ? 'all' : 'none')}">
                      <span container="body" [ngStyle]="{'--stage-color': stage.color}">
                        <input type="checkbox" [id]="'stageSelect_'+stage.id + (templateMode ? '_' + templateMode : '')"
                          (change)="SelectAll($event, stage)" [(ngModel)]="stage.isSelected">
                        <label [for]="'stageSelect_'+stage.id + (templateMode ? '_' + templateMode : '')"></label>
                      </span>
                    </div>
                    }
                    <span class="grey5 font-weight-bold nav-font16 mr-3">{{ stage.stageName}}
                      @if (templateMode) {
                      <span class="font-weight-normal grey3">({{stage.projectTasks.length}})</span>
                      }
                    </span>
                    @if (!templateMode) {
                    @if (stage.isComplete && stage.dueDate_dt!=null) {
                    <span class="grey3 nav-font14">Tasks due by {{
                      stage.dueDate_dt | date:'MM/dd' }}
                    </span>
                    }
                    }
                  </div>
                </div>
                @if (!templateMode) {
                <div class="col px-0 grey5 d-flex align-items-center justify-content-end">
                  @if (stage.isComplete) {
                  <span class="mr-3">
                    <i class="far fa-lg fa-circle-check nav-success"></i>
                  </span>
                  }
                  @if (stage.isActive) {
                  <span class="font-weight-normal mr-3 nav-font14 grey4">
                    {{ stage.completeCount }}/{{ stage.projectTasks.length }}
                    <span class="font-weight-normal ml-1">Complete</span>
                  </span>
                  }
                  @if (!stage.isActive) {
                  <span class="font-weight-normal mr-3 nav-font14 grey4">
                    <span class="font-weight-normal ml-1 grey5">Inactive</span>
                  </span>
                  }
                  <div class="bar d-inline-block mt-1 mr-4">
                    <div class="progress-bar" role="progressbar" [style.width.%]="20" aria-valuenow="" aria-valuemin="0"
                      aria-valuemax="100"></div>
                    <or-progress-guage bgColor="nav-bg-grey3" mode="schedule"
                      [value]="stage.projectTasks.length == 0? 0 : (stage.completeCount * 100) /stage.projectTasks.length"></or-progress-guage>
                  </div>
                  @if (editMode) {
                  <div class="ml-3 toggle-stage-state">
                    <or-toggle-button class="light" size="small" [isChecked]="stage.isActive"
                      (changed)="ToggleStageActive(stage)">
                    </or-toggle-button>
                  </div>
                  }
                </div>
                }
              </div>
            </div>
            @if (stage.isOpen && stage.isActive) {
            <div class="col px-0" [dndDropzone]="['task']" (dndDrop)="onDrop($event, stage.projectTasks, stage.id)">
              <div class="row item grid grey5 nav-font14 align-items-center inline-div border-bottom"
                [ngStyle]="{'grid-template-columns': gridTemplateColumns}" dndPlaceholderRef>
                <i class="fas fa-grip-lines opacity-20 pl-3"></i>
              </div>
              @for (task of stage.projectTasks; track task; let isLast = $last) {
              @if (task.showTask || templateMode) {
              <div class="row item grid grey5 nav-font14 align-items-center inline-div"
                [class.border-bottom]="templateMode != 'view' || !isLastStage || (!isLast && isLastStage)"
                [ngStyle]="{'grid-template-columns': gridTemplateColumns}" [dndDraggable]="task" dndType="task"
                dndEffectAllowed="move" [class.inline-edit]="task.inlineEditMode == true"
                (click)="inlineEdit(stage, task, $event)">
                <div class="d-flex align-items-center border-right h-100 text-truncate position-relative"
                  [ngClass]="task.inlineEditMode != true ? '' : 'px-0'">
                  @if (templateMode === 'edit') {
                  <i class="fak fa-drag grey5 py-1 font-weight-normal clickable" dndHandle></i>
                  }
                  <!-- checkbox select -->
                  @if (editMode && !task.inlineEditMode && !templateMode) {
                  <div class="">
                    <div class="or-checkbox edit editTask d-inline" [ngClass]="!templateMode ? 'ml-4 pl-3' : ''">
                      <span container="body" [ngStyle]="{'--stage-color': stage.color}">
                        <input type="checkbox" [id]="'taskSelect_'+task.id + (templateMode ? '_' + templateMode : '')"
                          [(ngModel)]="task.isSelected" (change)="SelectTask($event, stage,task)">
                        <label [for]="'taskSelect_'+task.id + (templateMode ? '_' + templateMode : '')"></label>
                      </span>
                    </div>
                  </div>
                  }
                  <!-- actions (drag and delete buttons)-->
                  @if (!editMode) {
                  <div class="actions font-weight-bold d-inline-flex align-items-center ml-2 pl-1"
                    [class.position-absolute]="task?.inlineEditMode">
                    <i class="fak fa-drag grey5 py-1 font-weight-normal clickable" dndHandle
                      [class.invisible]="stage.newInlineTask"></i>
                    <i class="fak fa-trash nav-error px-2 py-2 cursor-pointer font-weight-normal"
                      (click)="task.reoccurrenceSettings ? openReoccurringTaskChangeConfirmModal(task, 'delete', null, null, $event) : DeleteTask(task, $event)"></i>
                    <!-- checkbox complete -->
                    <div class="or-checkbox d-inline-flex complete">
                      <span class="here1"
                        [tooltip]="task.status == 'Complete' ? 'Marked as complete on ' + (task.completeDate | date:'MM/dd/yyyy') : (editMode ? '' : 'Mark as complete')"
                        container="body">
                        <input type="checkbox" [attr.disabled]="editMode ? true : null" [id]="'task_'+task.id"
                          (change)="ToggleStatus(task)" [checked]="task.status == 'Complete'">
                        <label [for]="'task_'+task.id"></label>
                      </span>
                    </div>
                  </div>
                  }
                  @if (!task.inlineEditMode) {
                  <div class="d-block position-relative editable"
                    [ngClass]="!editMode ? 'pl-2 ml-3' : (editMode && !templateMode && !task?.viewMore ? 'ml-4 pl-2' : '')"
                    [class.pl-2]="!templateMode && !task.description" style="width: 85%;">
                    @if (templateMode === 'edit') {
                    <i class="fak fa-trash nav-error pr-2 py-2 nav-font14 cursor-pointer font-weight-normal pr-2"
                      (click)="DeleteTask(task, $event)" [ngClass]="task?.description ? 'mr-4' : 'mr-3'"
                      [class.position-absolute]="task.description"
                      [ngStyle]="{top: task.description && !task?.viewMore ? '-16px' : (task.description ? 'calc(50% - 17px)' : '0')}"></i>
                    }
                    @if (!task.description) {
                    <span class="editable font-weight-bold">
                      {{ task.taskName }}
                    </span>
                    }
                    @if (task.description) {
                    <div class="description w-100 editable" [ngStyle]="{top: !task?.viewMore ? '-20px' : '0'}"
                      [ngClass]="!task?.viewMore ? 'position-absolute' : 'py-2'"
                      [class.pl-2]="editMode && task?.viewMore"
                      [class.ml-4]="!templateMode && editMode && task?.viewMore" [class.viewLess]="task?.viewMore">
                      <span class="editable font-weight-bold">
                        {{ task.taskName }}
                      </span>
                      <div class="d-flex editable">
                        <div class="editable" [class.desc]="!task?.viewMore">
                          {{ task.description }}
                          @if (task?.viewMore) {
                          <span class="nav-accent-blue nav-font-14 ml-2 clickable btn-view text-nowrap cursor-pointer"
                            (click)="viewMore(task)">View less</span>
                          }
                        </div>
                        @if (!task?.viewMore && task.description.length > 40) {
                        <span class="nav-accent-blue nav-font-14 ml-2 clickable btn-view text-nowrap cursor-pointer"
                          [class.mr-2]="!templateMode" (click)="viewMore(task)">View more</span>
                        }
                      </div>
                    </div>
                    }
                  </div>
                  }
                  @if (task.inlineEditMode) {
                  <div class="font-weight-bold d-inline-block w-75 "
                    [ngClass]="templateMode !== 'edit' ? 'ml-5 pl-5 pt-3 mb-1' : 'pt-2 mb-2'">
                    @if (templateMode === 'edit') {
                    <i class="fak fa-trash nav-error pr-2 py-2 nav-font14 cursor-pointer font-weight-normal"
                      (click)="DeleteTask(task, $event)"
                      [class.position-absolute]="task.description || (templateMode === 'edit' && task.inlineEditMode)"
                      [ngStyle]="{top: task.description && !task?.viewMore && templateMode != 'edit' ? '-16px' : (task.description || templateMode === 'edit' ? 'calc(50% - 17px)' : '0')}"></i>
                    }
                    <input #input (keyup.enter)="input.blur()" (blur)="SaveTaskName(task)" type="text"
                      (focus)="$event.target.select()"
                      class="form-control form-control-nav form-control-sm d-inline task-name" placeholder="Task name"
                      maxlength="100" [(ngModel)]="task.editTaskName">
                    @if (task?.showDescription || task?.description) {
                    <textarea rows="2" #inputDesc (blur)="SaveTaskDescription(task)" placeholder="Task description"
                      [(ngModel)]="task.editTaskDescription" maxlength="300"
                      class="task-description form-control form-control-nav form-control-sm clickable"
                      [class.mt-2]="templateMode != 'edit'">
                                                </textarea>
                    }
                    @if (!templateMode) {
                    <span class="float-right cursor-pointer d-inline grey5 font-weight-normal nav-font12 pt-1 clickable"
                      (click)="handleTaskDescription(stage, task)">
                      <i class="far grey4 mr-1" [class.fa-plus]="!task?.showDescription"
                        [class.fa-minus-circle]="task?.showDescription"></i>
                      {{ !task?.showDescription && !task?.description ? 'Add description' : 'Remove description'}}
                    </span>
                    }
                  </div>
                  }
                </div>
                @if (!templateMode) {
                <div class="d-flex align-items-center pl-3 border-right h-100 assignees">
                  <ng-template
                    *ngTemplateOutlet="tplAssignees; context:{ $implicit: task, stage: stage, type: 'inline' }"></ng-template>
                </div>
                }
                @if (scheduleColumns?.colTaskLength && !templateMode) {
                @if (task.inlineEditMode == true) {
                <div class="task-length d-flex align-items-center border-right h-100 pl-3">
                  <div class="col">
                    <div class="row mb-1">
                      <input #inputHours
                        class="form-control form-control-nav text-center form-control-sm d-inline hours"
                        (keyup.enter)="inputHours.blur()" type="text" mask="99" (blur)="SaveTaskLength(task)"
                        [(ngModel)]="task.editTaskLengthHours" (focus)="$event.target.select()" placeholder="00">
                      <span class="grey5 pl-2">hrs</span>
                    </div>
                    <div class="row">
                      <input #inputMinutes
                        class="form-control form-control-nav text-center form-control-sm d-inline minutes"
                        (keyup.enter)="inputMinutes.blur()" type="text" mask="99"
                        (input)="validateTaskLengthMinute($event, task)" (blur)="SaveTaskLength(task)"
                        [(ngModel)]="task.editTaskLengthMinutes" (focus)="$event.target.select()" placeholder="00">
                      <span class="grey5 pl-2">mins</span>
                    </div>
                  </div>
                </div>
                }
                @if (task.inlineEditMode != true) {
                @if (task?.taskLengthHours || task?.taskLengthMinutes) {
                <div class="task-length d-flex align-items-center border-right h-100 pl-3 editable">
                  <span class="editable">
                    {{task?.taskLengthHours ? task?.taskLengthHours + ' hrs' : '' }} {{task?.taskLengthMinutes ?
                    task?.taskLengthMinutes + ' mins' : '' }}
                  </span>
                </div>
                }
                @if (!task?.taskLengthHours && !task?.taskLengthMinutes) {
                <div class="task-length d-flex align-items-center justify-content-center border-right h-100 editable">
                  <i class="far fa-plus grey4 mr-1 invisible editable"></i>
                </div>
                }
                }
                }
                @if (scheduleColumns.colStatus) {
                <div class="d-flex align-items-center pl-3 border-right h-100">
                  TO DO
                </div>
                }
                <div class="border-right d-flex align-items-center h-100 editable"
                  [ngClass]="templateMode ? 'px-2' : 'px-3'">
                  @if (!templateMode) {
                  @if (task.inlineEditMode != true) {
                  <span class="editable">
                    {{task.startDate_dt | date:'MM/dd/YY' }}
                  </span>
                  }
                  }
                  @if (templateMode && !task.inlineEditMode) {
                  <span class="grey6 editable">
                    <span class="editable">{{myMath.abs(task.daysFromProjectStart)}}
                      {{myMath.abs(task.daysFromProjectStart) == 1 ? 'day' : 'days'}}</span>
                    <br class="editable">
                    <span class="grey4 editable">{{task.daysFromProjectStart >= 0 ? 'after' : 'before'}} project
                      starts</span>
                  </span>
                  }
                  @if (task.inlineEditMode == true) {
                  @if (templateMode !== 'edit') {
                  <div class="input-group form-control-date">
                    <input type="text" autocomplete="off" (click)="matchDatesWithProject(dpStartDate)"
                      (focus)="matchDatesWithProject(dpStartDate)" (bsValueChange)="SaveTaskStartDate(task, $event)"
                      bsDatepicker placement="top" #dpStartDate="bsDatepicker" readonly placeholder="MM/DD/YY"
                      [bsValue]="task.editStartDate"
                      [bsConfig]="{ dateInputFormat: 'MM/DD/YY', showWeekNumbers:false, customTodayClass: 'todays-date' }"
                      class="form-control form-control-sm d-inline bg-white date text-left w-50 px-2">
                    <span class="input-group-text bg-white py-0" (click)="matchDatesWithProject(dpStartDate)"><i
                        class="fak fa-calendar grey4"></i></span>
                  </div>
                  }
                  @if (templateMode === 'edit') {
                  <div>
                    <div class="input-group mb-1">
                      <input #inputDaysForTaskStart
                        class="template-task-start form-control form-control-nav text-center form-control-sm d-inline"
                        (keyup.enter)="inputDaysForTaskStart.blur()" type="text" inputmode="numeric" mask="99"
                        (blur)="calculateTemplateTaskDates(task, inputDaysForTaskStart.value)"
                        [(ngModel)]="task.inputDaysFromProjectStart" (focus)="$event.target.select()">
                      <span class="ml-2">day(s)</span>
                    </div>
                    <div class="input-group d-flex align-items-center">
                      <div class="btn-group days-from-project" dropdown #beforeAfterProjectStarts="bs-dropdown">
                        <div id="button-basic" dropdownToggle controls="dropdown-basic" class="d-flex button-container"
                          [ngClass]="beforeAfterProjectStarts.isOpen ? 'open' : 'closed'"
                          (click)="$event.preventDefault()">
                          <span class="nav-font14 cursor-default">{{task.afterProjectStart ? 'After' : 'Before'}}</span>
                          <div class="cursor-pointer d-flex align-items-center grey6 ml-auto">
                            <i class="far grey4 float-right nav-font14 ml-auto"
                              [class.fa-angle-up]="beforeAfterProjectStarts.isOpen"
                              [class.fa-angle-down]="!beforeAfterProjectStarts.isOpen">
                            </i>
                          </div>
                        </div>
                        <div *dropdownMenu class=" dropdown-menu dropdown-menu-right py-0 nav-font14 m-0 w-100"
                          role="menu" aria-labelledby="button-basic">
                          <div class="menu-container overflow-y">
                            <ul class=" py-0 px-0 my-0 noShadow">
                              <li role="menuitem" class="expandable grey4 nav-font14 d-flex align-items-center px-0"
                                (click)="task.afterProjectStart=false; calculateTemplateTaskDates(task, inputDaysForTaskStart.value)">
                                <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
                                  <span>Before</span>
                                </div>
                              </li>
                              <hr class="p-0 my-0 mx-1">
                              <li role="menuitem" class="expandable grey4 nav-font14 d-flex align-items-center px-0"
                                (click)="task.afterProjectStart=true; calculateTemplateTaskDates(task, inputDaysForTaskStart.value)">
                                <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
                                  <span>After</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <span class="grey4 ml-2">project starts</span>
                    </div>
                  </div>
                  }
                  }
                </div>
                <div class="d-flex align-items-center h-100"
                  [ngClass]="templateMode && task.inlineEditMode ? 'px-2' : 'px-3'" [class.border-right]="templateMode">
                  @if (templateMode) {
                  @if (task.inlineEditMode !== true) {
                  <span class="grey6 editable">{{task.taskLength}} {{task.taskLength == 1 ? 'day' : 'days'}}</span>
                  }
                  @if (task.inlineEditMode == true) {
                  <div>
                    <div class="input-group mb-1">
                      <input
                        class="template-task-start form-control form-control-nav text-center form-control-sm d-inline"
                        type="text" inputmode="numeric" mask="99" [(ngModel)]="task.taskLength"
                        (focus)="$event.target.select()">
                      <span class="ml-2">day(s)</span>
                    </div>
                  </div>
                  }
                  }
                  @if (!templateMode) {
                  @if (task.inlineEditMode !== true) {
                  <span class="editable">
                    {{task.dueDate_dt | date:'MM/dd/YY' }}
                  </span>
                  }
                  @if (task.inlineEditMode == true) {
                  @if (!templateMode) {
                  <div class="input-group form-control-date">
                    <input type="text" autocomplete="off" (click)="matchDatesWithProject(dpDueDate)"
                      (focus)="matchDatesWithProject(dpDueDate)" (bsValueChange)="SaveTaskDueDate(task, $event)"
                      bsDatepicker placement="top" #dpDueDate="bsDatepicker" readonly placeholder="MM/DD/YY"
                      [bsValue]="task.editDueDate"
                      [bsConfig]="{ dateInputFormat: 'MM/DD/YY', showWeekNumbers:false, customTodayClass: 'todays-date' }"
                      class="form-control form-control-sm d-inline bg-white date text-left w-50 px-2">
                    <span class="input-group-text bg-white py-0" (click)="matchDatesWithProject(dpDueDate)"><i
                        class="fak fa-calendar grey4"></i></span>
                  </div>
                  }
                  }
                  }
                </div>
                @if (scheduleColumns.colReoccurrence) {
                <div class="d-flex align-items-center border-left pl-3 h-100">
                  @if (task.reoccurrenceSettings == null) {
                  Does not repeat
                  }
                  @if (task.reoccurrenceSettings) {
                  <i class="fak fa-repeat-rounded nav-font18"></i>
                  <span class="ml-2">{{task.reoccurrenceSettings.frequencyType}}</span>
                  }
                  @if (task.inlineEditMode && !templateMode) {
                  <div class="ml-2 position-relative">
                    <i class="fak fa-edit-square cursor-pointer editReocurrenceBtn"
                      [class.active]="task.showReoccurrenceSettings"
                      (click)="$event.preventDefault(); openReoccurrenceSettings(task)"></i>
                    <!-- <ng-container *ngIf="task.inlineEditMode && task.showReoccurrenceSettings">
                                                            <ng-template *ngTemplateOutlet="reoccurrenceSettings"></ng-template>
                                                          </ng-container> -->
                  </div>
                  }
                </div>
                }
              </div>
              }
              }
              <!-- adding new inline task -->
              @if (stage.showInlineTask && stage.newInlineTask != null) {
              <div [dndDraggable]="stage.newInlineTask" dndType="task" dndEffectAllowed="move"
                [ngStyle]="{'grid-template-columns': gridTemplateColumns}"
                class="row item grid grey5 nav-font14 align-items-center inline-div border-bottom inline-edit">
                <div class="d-flex align-items-center border-right h-100 position-relative px-0">
                  <div class="actions font-weight-bold d-inline-flex align-items-center ml-2 pl-1 position-absolute">
                    <i class="fak fa-drag grey5 py-1 font-weight-normal invisible" dndHandle></i>
                    <i class="fak fa-trash nav-error px-2 py-2 cursor-pointer font-weight-normal"
                      (click)="CancelTask(stage.id)"></i>
                    <!-- checkbox complete -->
                    <div class="or-checkbox d-inline-flex complete">
                      <span class="here1" tooltip="Mark as complete" container="body">
                        <input type="checkbox" [id]="'task_newInlineTask'"
                          [checked]="stage.newInlineTask.status == 'Complete'">
                        <label [for]="'task_newInlineTask'"></label>
                      </span>
                    </div>
                  </div>
                  <div class="font-weight-bold d-inline-block ml-5 pl-5 w-75 pt-3 mb-1">
                    <input #newTaskName (keyup.enter)="stage.newInlineTask.taskName = stage.newInlineTask.editTaskName;"
                      (blur)="stage.newInlineTask.taskName = stage.newInlineTask.editTaskName;" type="text"
                      (focus)="$event.target.select()"
                      [ngClass]="{'border-danger': stage.newInlineTask?.errors?.taskName}"
                      class="form-control form-control-nav form-control-sm d-inline" placeholder="Task name"
                      maxlength="100" [(ngModel)]="stage.newInlineTask.editTaskName">
                    @if (stage.newInlineTask?.showDescription) {
                    <textarea rows="2"
                      (keyup.enter)="stage.newInlineTask.description = stage.newInlineTask.editTaskDescription;"
                      (blur)="stage.newInlineTask.description = stage.newInlineTask.editTaskDescription;"
                      placeholder="Task description" [(ngModel)]="stage.newInlineTask.editTaskDescription"
                      maxlength="300"
                      class="task-description form-control form-control-nav form-control-sm mt-2 clickable">
                                                        </textarea>
                    }
                    <span class="float-right cursor-pointer d-inline grey5 font-weight-normal nav-font12 pt-1 clickable"
                      (click)="handleTaskDescription(stage, stage.newInlineTask)">
                      <i class="far grey4 mr-1" [class.fa-plus]="!stage.newInlineTask?.showDescription"
                        [class.fa-minus-circle]="stage.newInlineTask?.showDescription"></i>
                      {{ !stage.newInlineTask?.showDescription ? 'Add description' : 'Remove description'}}
                    </span>
                  </div>
                </div>
                <div class="d-flex align-items-center pl-3 border-right h-100 assignees">
                  <ng-template
                    *ngTemplateOutlet="tplAssignees; context:{ $implicit: stage.newInlineTask, stage: stage, type: 'new-inline' }"></ng-template>
                  <!-- <span [tooltip]="stage.newInlineTask.assignedToDisplayName"
                                                    class="cursor-pointer users inline-div editable"
                                                    (click)="toggleAssignedPicker(stage.newInlineTask, null, true)">
                                                    <or-profile-image [name]="stage.newInlineTask.assignedToDisplayName || ' '" size="medium" [showInitials]="stage.newInlineTask.assignedToDisplayName ?? false"></or-profile-image>
                                                  </span>
                                                  <span>
                                                    <i (click)="toggleAssignedPicker(stage.newInlineTask, null)"
                                                      class="far fa-xs grey4 usersDiv nav-font10"
                                                      [class.fa-angle-up]="!stage.newInlineTask.showDropdown"
                                                      [class.fa-angle-down]="stage.newInlineTask.showDropdown">
                                                    </i>
                                                    <span *ngIf="stage.newInlineTask.showDropdown" class="justify-content-center or-dropdown assigneePicker inline-div">
                                                      <or-dropdown-list class="dropdown-list"
                                                        [values]="this.usersForDropdown"
                                                        [noSearchText]="'Search or select assignee'"
                                                        [search]="true"
                                                        [value]="stage.newInlineTask.editAssignedToId"
                                                        (filter)="getUsersForDropdown($event)"
                                                        (selected)="AssignTaskAssignee(stage.newInlineTask, null, $event, 'new-inline')">
                                                      </or-dropdown-list>
                                                    </span>
                                                  </span> -->
                </div>
                @if (scheduleColumns?.colTaskLength) {
                <div class="task-length d-flex align-items-center border-right h-100 pl-3">
                  <div class="col">
                    <div class="row mb-1">
                      <input #inputHours
                        class="form-control form-control-nav text-center form-control-sm d-inline hours"
                        (keyup.enter)="inputHours.blur()"
                        [ngClass]="{'border-danger': stage.newInlineTask?.errors?.taskLengthHours}" type="text"
                        mask="99"
                        (blur)="stage.newInlineTask.taskLengthHours = stage.newInlineTask.editTaskLengthHours;"
                        [(ngModel)]="stage.newInlineTask.editTaskLengthHours" (focus)="$event.target.select()"
                        placeholder="00">
                      <span class="grey5 pl-2">hrs</span>
                    </div>
                    <div class="row">
                      <input #inputMinutes
                        class="form-control form-control-nav text-center form-control-sm d-inline minutes"
                        (keyup.enter)="inputMinutes.blur()"
                        [ngClass]="{'border-danger': stage.newInlineTask?.errors?.taskLengthMinutes}" type="text"
                        mask="99" (input)="validateTaskLengthMinute($event, stage.newInlineTask)"
                        (blur)="stage.newInlineTask.taskLengthMinutes = stage.newInlineTask.editTaskLengthMinutes;"
                        [(ngModel)]="stage.newInlineTask.editTaskLengthMinutes" (focus)="$event.target.select()"
                        placeholder="00">
                      <span class="grey5 pl-2">mins</span>
                    </div>
                  </div>
                </div>
                }
                @if (scheduleColumns.colStatus) {
                <div class="d-flex align-items-center pl-3 border-right h-100">
                  TO DO
                </div>
                }
                <div class="d-flex align-items-center px-2 border-right h-100">
                  <div class="input-group form-control-date">
                    <input type="text" autocomplete="off" (click)="matchDatesWithProject(dpNewStartDate)"
                      (focus)="matchDatesWithProject(dpNewStartDate)"
                      (bsValueChange)="setNewInlineStartDate(stage, $event, dpeNewDueDate);" bsDatepicker
                      placement="top" #dpNewStartDate="bsDatepicker" placeholder="MM/DD/YY"
                      [ngClass]="{'border-danger': stage.newInlineTask?.errors?.startDate}" [daysDisabled]="[6,0]"
                      [bsConfig]="{ dateInputFormat: 'MM/DD/YY', showWeekNumbers:false, returnFocusToInput: true, customTodayClass: 'todays-date' }"
                      class="form-control form-control-sm d-inline bg-white date text-left w-50 px-2">
                    <span class="input-group-text bg-white py-0"
                      [ngClass]="{'border-danger': stage.newInlineTask?.errors?.startDate}"
                      (click)="matchDatesWithProject(dpNewStartDate)"><i class="fak fa-calendar grey4"></i></span>
                  </div>
                </div>
                <div class="d-flex align-items-center px-2 h-100">
                  <div class="input-group form-control-date">
                    <input type="text" autocomplete="off" (focus)="matchDatesWithProject(dpeNewDueDate)"
                      (click)="matchDatesWithProject(dpeNewDueDate)"
                      (bsValueChange)="setNewInlineDueDate(stage, $event);" bsDatepicker placement="top"
                      #dpeNewDueDate="bsDatepicker" placeholder="MM/DD/YY"
                      [ngClass]="{'border-danger': stage.newInlineTask?.errors?.dueDate}" [daysDisabled]="[6,0]"
                      [bsConfig]="{ dateInputFormat: 'MM/DD/YY', showWeekNumbers:false, returnFocusToInput: true, customTodayClass: 'todays-date' }"
                      class="form-control form-control-sm d-inline bg-white date text-left w-50 px-2">
                    <span class="input-group-text bg-white py-0"
                      [ngClass]="{'border-danger': stage.newInlineTask?.errors?.dueDate}"
                      (click)="matchDatesWithProject(dpeNewDueDate)"><i class="fak fa-calendar grey4"></i></span>
                  </div>
                </div>
                @if (scheduleColumns.colReoccurrence) {
                <div class="d-flex align-items-center border-left pl-3 h-100">
                </div>
                }
              </div>
              }
              <!-- Add task button -->
              @if (templateMode !== 'view') {
              <div class="row py-1 grey5 nav-font14 align-items-center add-task-button"
                [class.border-bottom]="templateMode != 'edit' || !isLastStage">
                <div class="col ml-3">
                  <span type="button" class="nav-accent-blue font-weight-bold nav-font-14 ml-4 pl-3 clickable"
                    [class.disabled]="inlineAddMode || editMode" (click)="AddTask(stage, $event)">
                    <i class="fas fa-plus fa-sm mr-2"></i>
                    Add Task
                  </span>
                </div>
              </div>
              }
            </div>
            }
          </div>
        </div>
        }
        }
      </div>
      @if (templateMode == 'edit') {
      <div class="py-3">
        <div class="row d-flex w-100">
          <div class="col text-right pr-0">
            <button class="btn nav-btn-primary mx-2 shadow-none font-weight-bold"
              (click)="cancelTemplate.emit()">Cancel</button>
            <button class="btn nav-btn-accent shadow-none font-weight-bold" [disabled]="!canSaveTemplate"
              [class.disabled-save]="!canSaveTemplate"
              (click)="submitTemplate.emit({schedule: projectSchedule, name: newTemplateName})">Save</button>
          </div>
        </div>
      </div>
      }
      }
      @if (currentView == 'calendar') {
      <div class="container-fluid mb-4">
        <or-calendar [data]="calendarData" idField="Id" startDateField="startDateCalendar"
          (selectedDoubleClick)="editTaskFromCalendar($event)" endDateField="dueDateCalendar" titleField="taskName"
          subtitleField="taskName" profileField="stageName"
          (selectedReoccurrence)="openReoccurrenceSettingsInCalendar($event?.taskid)" footerField="status"
          eventColorField="stageColor"></or-calendar>
      </div>
      }
      @if (currentView == 'gantt') {
      <div class="container-fluid mb-4">
        @if (highchartOptions != null) {
        <highcharts-chart [Highcharts]="highcharts" [constructorType]="'ganttChart'" [options]="highchartOptions"
          style="width: 100%; height: 100%; display: block;">
        </highcharts-chart>
        }
      </div>
      }
      }
    </div>
    }

    <!-- list for global tasks and project family summary -->
    @if (project == null) {
    @if (currentView == 'list') {
    <div class="projectFamilySummary scrollbar overflow-x" width="100%">
      <table class="table global-tasks table-hover table-light nav-font14 familyTable mb-0">
        <thead class="grey5">
          <tr>
            @if (editMode) {
            <th width="100">
              <div class="or-checkbox d-flex">
                <span container="body">
                  <input type="checkbox" id="taskSelectAll" (change)="SelectAllGlobal($event)">
                  <label for="taskSelectAll"></label>
                </span>
              </div>
            </th>
            }
            <th width="150" (click)="SortBy('projectCode')">Project Code <i class="fas fa-sm or-secondary fa-sort"></i>
            </th>
            <th width="150" (click)="SortBy('projectName')">Project Name <i class="fas fa-sm or-secondary fa-sort"></i>
            </th>
            <th width="150" (click)="SortBy('client')">Client <i class="fas fa-sm or-secondary fa-sort"></i></th>
            <th width="150" (click)="SortBy('stage')">Stage <i class="fas fa-sm or-secondary fa-sort"></i></th>
            <th width="200" (click)="SortBy('task')">Task <i class="fas fa-sm or-secondary fa-sort"></i></th>
            <th width="110" class=" text-center" (click)="SortBy('assignedTo')">Assigned To <i
                class="fas fa-sm or-secondary fa-sort"></i></th>
            <th width="100" (click)="SortBy('dueDate')">Due Date <i class="fas fa-sm or-secondary fa-sort"></i></th>
            <th width="125" (click)="SortBy('completedDate')">Complete Date <i
                class="fas fa-sm or-secondary fa-sort"></i></th>
            <th width="100" class="text-center" (click)="SortBy('status')">Complete <i
                class="fas fa-sm or-secondary fa-sort"></i>
            </th>
          </tr>
        </thead>
        <tbody class="bg-white">
          @for (task of ((tasksLimited && bidNumber != null) ? fifteenTasks : allTasks); track task) {
          <ng-container class="row">
            @if (task.showTask || bidNumber == null) {
            <tr>
              @if (editMode) {
              <td class="col-1">
                <div class="or-checkbox d-flex">
                  <span container="body">
                    <input type="checkbox" [id]="'taskSelect_'+task.id" [(ngModel)]="task.isSelected">
                    <label [for]="'taskSelect_'+task.id"></label>
                  </span>
                </div>
              </td>
              }
              <td>
                <span class=" p-0">{{ task.projectCode }}</span>
              </td>
              <td>
                <a class="nav-accent-blue font-weight-bold nav-link p-0 text-truncate"
                  [routerLink]="['/projects', task.projectId, 'schedule']"> {{ task.projectName }}</a>
              </td>
              <td>
                <span class="d-block text-truncate">
                  {{ task.clientName }}
                </span>
              </td>
              <td>
                <div class="projectStageIcon text-nowrap">
                  <or-project-stage [stage]="task.stageName" [color]="task.stageColor"
                    [percentComplete]="(task?.stageCompleteTaskCount / task?.stageTotalTaskCount)*100"></or-project-stage>
                </div>
              </td>
              <td class="text-truncate">
                <span [tooltip]="task.taskName != null ? (task.taskName.length > 20 ? task.taskName : '') : ''"
                  placement="bottom">
                  {{ task.taskName }}
                </span>
              </td>
              <td>
                <div class="d-flex justify-content-center">
                  <!-- <or-profile-image [name]="task.assignedToUsers[0]?.assignedToName || ' '" size="medium" [showInitials]="task.assignedToUsers[0]?.assignedToName ?? false"></or-profile-image> -->
                  <ng-template
                    *ngTemplateOutlet="tplAssignees; context:{ $implicit: task, stage: null, type: 'readonly' }"></ng-template>
                </div>
              </td>
              <td>{{ task.dueDate | date:'MMM d, y' }}</td>
              <td>
                @if (task.completeDate == '1970-01-01T00:00:00') {
                <span [ngClass]="'grey1'">
                  {{'Pending'}}
                </span>
                }
                @if (task.completeDate != '1970-01-01T00:00:00') {
                <span>
                  {{task.completeDate | date: 'MMM d, y'}}
                </span>
                }
                @if (!task.completeDate) {
                <span [ngClass]="compareDates(task.dueDate, today) == 'before' ? 'nav-error' : 'grey1'">
                  {{compareDates(task.dueDate, today) == 'before' ? 'Overdue' : 'Pending'}}
                </span>
                }
              </td>
              <td>
                <div class="or-checkbox complete d-flex justify-content-center">
                  <span
                    [tooltip]="task.status == 'Complete' ? 'Marked as complete on ' + (task.completeDate | date:'MM/dd/yyyy') : 'Mark as complete'"
                    container="body">
                    <input type="checkbox" [id]="'task_'+task.id" (change)="ToggleStatus(task)"
                      [checked]="task.status == 'Complete'">
                    <label [for]="'task_'+task.id"></label>
                  </span>
                </div>
              </td>
            </tr>
            }
          </ng-container>
          }
        </tbody>
      </table>
    </div>
    }
    @if (currentView == 'gantt') {
    <div class="container-fluid mb-4">
      @if (highchartOptions != null) {
      <highcharts-chart [Highcharts]="highcharts" [constructorType]="'ganttChart'" [options]="highchartOptions"
        style="width: 100%; height: 100%; display: block;">
      </highcharts-chart>
      }
    </div>
    }
    }
    @if (project == null && bidNumber != null && allTasks.length > 15) {
    @if (tasksLimited && fifteenTasks.length == 15) {
    <div (click)="tasksLimited=false"
      class="nav-font-14 nav-accent-blue font-weight-bold cursor-pointer float-right mx-4 mb-5 mt-3">+ View all tasks
    </div>
    }
    @if (!tasksLimited && fifteenTasks.length == 15) {
    <div (click)="tasksLimited=true"
      class="nav-font-14 nav-accent-blue font-weight-bold cursor-pointer float-right mx-4 mb-5">- View less tasks</div>
    }
    }
  </div>

  <ng-template #modalTask>
    <div class="modal-header">
      <div class="d-flex w-100 align-items-center">
        <i class="fak {{!newTask?.id ? 'fa-new-task nav-font24' : 'fa-edit-square nav-font18 mb-1'}} mr-2 grey4"></i>
        <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18">{{!newTask?.id ? 'New Task' : 'Edit Task'}}</h2>
      </div>
      <div class="d-flex justify-content-end">
        <i class="fas fa-close grey4" (click)="CloseCreateTaskModal()"></i>
      </div>
    </div>
    <div class="modal-body nav-font14 grey5">
      <div class="row align-items-center mb-4">
        <div class="col-3 font-weight-bold"><label class="required">Task name</label></div>
        <div class="col">
          <input [(ngModel)]="newTask.name" (input)="validateNewTask()" type="text"
            class="form-control form-control-sm form-control-nav" maxlength="100" placeholder="Task Name">
        </div>
      </div>
      <!-- <div class="row align-items-center mb-4">
                                  <div class="col-3 font-weight-bold"><label class="required">For project(s)</label></div>
                                  <div class="col">
                                    <select [(ngModel)]="newTask.stageId" class="form-control form-control-sm form-control-nav d-block w-100">
                                      <option *ngFor="let stage of this.projectSchedule" value="{{ stage.id }}">{{ stage.stageName }}
                                      </option>
                                    </select>
                                  </div>
                                </div> -->
      <div class="row align-items-center mb-4">
        <div class="col-3 font-weight-bold"><label class="required">For stage</label></div>
        <div class="col">
          <select [(ngModel)]="newTask.stageId" class="form-control form-control-sm form-control-nav d-block w-100">
            @for (stage of this.projectSchedule; track stage) {
            <option value="{{ stage.id }}">{{ stage.stageName }}
            </option>
            }
          </select>
        </div>
      </div>
      <div class="row align-items-start mb-4">
        <div class="col-3 font-weight-bold">Assignee(s)</div>
        <div class="col">
          @for (assignee of newTask?.assignedToUsers; track assignee) {
          <div class="row align-items-center mb-4">
            <div class="col rid-col-picker">
              <ng-template *ngTemplateOutlet="tplNewTaskAssignee; context:{ $implicit: assignee }"></ng-template>
            </div>
          </div>
          }
        </div>
      </div>
      <div class="row align-items-center mb-4">
        <div class="col-3 font-weight-bold"><label class="required">Task starts</label></div>
        <div class="col">
          <div class="input-group form-control-date">
            <input type="text" autocomplete="off" [(ngModel)]="newTask.startDate"
              (click)="matchDatesWithProject(dpStartDateAdd)" (focus)="matchDatesWithProject(dpStartDateAdd)"
              (bsValueChange)="setNewTaskStartDate(newTask, $event, dpDueDateAdd);" bsDatepicker
              #dpStartDateAdd="bsDatepicker" readonly placeholder="MM/DD/YY" [bsValue]="newTask.startDate"
              [bsConfig]="{ dateInputFormat: 'MM/DD/YY', showWeekNumbers:false, returnFocusToInput: true, adaptivePosition: true, customTodayClass: 'todays-date' }"
              class="form-control form-control-sm d-inline bg-white date text-left w-50 px-2">
            <span class="input-group-text bg-white py-0" (click)="matchDatesWithProject(dpStartDateAdd)"><i
                class="fak fa-calendar grey4"></i></span>
          </div>
        </div>
      </div>
      <div class="row align-items-center mb-4">
        <div class="col-3 font-weight-bold"><label class="required">Task ends</label></div>
        <div class="col">
          <!-- <input type="text" autocomplete="off" bsDatepicker [bsValue]="newTask.dueDate" (focus)="onFocusEndEvent($event)" [isOpen]="isOpenEnd" [(ngModel)]="newTask.dueDate"
                                      [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', showWeekNumbers:false, returnFocusToInput: true,adaptivePosition: true, customTodayClass: 'todays-date' }"
                                      placeholder="MM/DD/YYYY" #dpeTask="bsDatepicker"
                                      class="form-control form-control-sm form-control-nav d-inline bg-white date"> -->
          <div class="input-group form-control-date">
            <input type="text" autocomplete="off" [(ngModel)]="newTask.dueDate"
              (click)="matchDatesWithProject(dpDueDateAdd)" (focus)="matchDatesWithProject(dpDueDateAdd)"
              (bsValueChange)="setNewTaskDueDate(newTask, $event);" bsDatepicker #dpDueDateAdd="bsDatepicker" readonly
              placeholder="MM/DD/YY"
              [bsConfig]="{ dateInputFormat: 'MM/DD/YY', showWeekNumbers:false, returnFocusToInput: true,adaptivePosition: true, customTodayClass: 'todays-date' }"
              class="form-control form-control-sm d-inline bg-white date text-left w-50 px-2">
            <span class="input-group-text bg-white py-0" (click)="matchDatesWithProject(dpDueDateAdd)"><i
                class="fak fa-calendar grey4"></i></span>
          </div>
        </div>
      </div>
      <!-- <div class="row align-items-center mb-4">
                                    <div class="col-3 font-weight-bold">Reoccurrence</div>
                                    <div class="col">
                                      <select class="form-control form-control-sm form-control-nav d-block w-100">
                                        <option value="NotRepeat">Does not repeat</option>
                                      </select>
                                    </div>
                                  </div> -->
      <div class="row align-items-start mb-4">
        <div class="col-3 font-weight-bold">Description</div>
        <div class="col">
          <textarea [(ngModel)]="newTask.description" [autosize]="false"
            class="form-control form-control-sm form-control-nav" rows="2" placeholder="Task description"></textarea>
        </div>
      </div>

      <div class="col text-right pr-0 mt-4">
        <button type="button" class="btn nav-btn-primary mx-2 shadow-none font-weight-bold"
          (click)="CloseCreateTaskModal()">Cancel</button>
        <button type="button" class="btn shadow-none font-weight-bold" [disabled]="!newTask?.isValid"
          [ngClass]="!newTask?.isValid ? 'nav-btn-disabled' : 'nav-btn-accent'" (click)="SaveTaskModal()">{{!newTask?.id
          ? 'Add' : 'Save'}}</button>
      </div>
    </div>
  </ng-template>

  <ng-template #modalEditAssignees>
    <div class="modal-header">
      <div class="d-flex w-100 align-items-center">
        <i class="fak fa-reassign mr-2 grey4 nav-font24"></i>
        <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18">Edit Assignees</h2>
      </div>
      <div class="d-flex justify-content-end editable">
        <i class="fas fa-close grey4 editable" (click)="CloseEditAssigneesModal()"></i>
      </div>
    </div>
    <div class="modal-body nav-font14 grey5 pr-3 pb-3">
      <div class="row align-items-start mb-2">
        <div class="col grey5 col grey5 ml-3 pl-4">
          Edit assignees assigned to this task:
        </div>
      </div>
      <div class="row align-items-start mb-4">
        <div class="col font-weight-bold col grey5 ml-3 pl-4">
          '{{editTaskAssignees.taskName}}'
        </div>
      </div>
      <div class="row align-items-start">
        <div class="col px-2">
          @for (assignee of editTaskAssignees?.assignedToUsers; track assignee; let idx = $index) {
          <div class="row align-items-center"
            [ngClass]="(idx+1) === editTaskAssignees?.assignedToUsers.length ? 'mb-3' : 'mb-4'">
            <div class="col rid-col-picker">
              <ng-template
                *ngTemplateOutlet="tplEditAssignees; context:{ $implicit: assignee, task: editTaskAssignees, type: 'edit', index: idx }"></ng-template>
            </div>
          </div>
          }
        </div>
      </div>
      <div class="row">
        <div class="col d-flex align-items-center ml-4">
          <span class="nav-font14 font-weight-bold cursor-pointer clickable"
            [ngClass]="editTaskAssignees?.canAdd ? 'nav-accent-blue cursor-pointer': 'grey2 cursor-default'"
            (click)="editTaskAssignees?.canAdd ? addEditAssignee() : null">
            <i class="far fa-plus mr-2 clickable"></i> Add assignee
          </span>
        </div>
      </div>
      <div class="col text-right pr-0 mt-4">
        <button type="button" class="btn nav-btn-primary mx-2 shadow-none font-weight-bold nav-font14 clickable"
          (click)="CloseEditAssigneesModal()">Cancel</button>
        <button type="button" class="btn shadow-none font-weight-bold nav-font14 clickable"
          [disabled]="!editTaskAssignees?.canAdd"
          [ngClass]="!editTaskAssignees?.canAdd ? 'nav-btn-disabled' : 'nav-btn-accent'"
          (click)="EditTaskAssignees()">Save</button>
      </div>
    </div>
  </ng-template>

  <ng-template #modalAssigneeAcrossTasks>
    <div class="modal-header">
      <div class="d-flex w-100 align-items-center">
        <i class="fak fa-reassign mr-2 grey4 nav-font24"></i>
        <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18">Reassign Across All Tasks</h2>
      </div>
      <div class="d-flex justify-content-end">
        <i class="fas fa-close grey4 clickable" (click)="CloseAssigneeAcrossTasks()"></i>
      </div>
    </div>
    <div class="modal-body nav-font14 grey5 pr-3 pb-3">
      <div class="row align-items-start mb-3">
        <div class="col grey5 col grey5 ml-3 pl-4">
          Select assignee(s) to assign to all <span
            class="font-weight-bold">{{assigneesAcrossTasks?.tasks.length}}</span> task(s) in this project:
        </div>
      </div>
      <div class="row align-items-start">
        <div class="col px-2">
          @for (assignee of assigneesAcrossTasks?.assignedToUsers; track assignee; let idx = $index) {
          <div class="row align-items-center"
            [ngClass]="(idx+1) === assigneesAcrossTasks?.assignedToUsers.length ? 'mb-3' : 'mb-4'">
            <div class="col rid-col-picker">
              <ng-template
                *ngTemplateOutlet="tplEditAssignees; context:{ $implicit: assignee, task: assigneesAcrossTasks, type: 'across', index: idx }"></ng-template>
            </div>
          </div>
          }
        </div>
      </div>
      <div class="row">
        <div class="col d-flex align-items-center ml-4">
          <span class="nav-font14 font-weight-bold cursor-pointer clickable"
            [ngClass]="assigneesAcrossTasks?.canAdd ? 'nav-accent-blue cursor-pointer': 'grey2 cursor-default'"
            (click)="assigneesAcrossTasks?.canAdd ? addAssigneeAcrossTasks() : null">
            <i class="far fa-plus mr-2 clickable"></i> Add assignee
          </span>
        </div>
      </div>
      <div class="col text-right pr-0 mt-4">
        <button type="button" class="btn nav-btn-primary mx-2 shadow-none font-weight-bold nav-font14 clickable"
          (click)="CloseAssigneeAcrossTasks()">Cancel</button>
        <button type="button" class="btn shadow-none font-weight-bold nav-font14 clickable"
          [disabled]="!assigneesAcrossTasks?.canAdd"
          [ngClass]="!assigneesAcrossTasks?.canAdd ? 'nav-btn-disabled' : 'nav-btn-accent'"
          (click)="EditAssigneesAcross()">Save</button>
      </div>
    </div>
  </ng-template>

  <ng-template #modalConfirmStageDeactivate>
    <div class="modal-header">
      <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18"><i
          class="far fa-exclamation-triangle nav-warning mr-2"></i>Deactivate a Stage with Tasks</h2>
      <div class="d-flex justify-content-end">
        <i class="fas fa-close grey4" (click)="CloseConfirmStageDeactivateModal()"></i>
      </div>
    </div>
    <div class="modal-body grey4 nav-font14">
      <span>This action will result in the removal of all {{ selectedStage.stageName}} tasks from your schedule and
        you will no longer receive any scheduled reminders for them. Are you sure you want to deactivate this
        stage?
      </span>
      <span class="mt-3 d-block">
        <i class="fak fa-info mr-2"></i>You can reactivate a stage with tasks at any time.
      </span>
      <div class="col text-right pr-0 mt-4">
        <button type="button" class="btn nav-btn-primary mx-2 shadow-none font-weight-bold"
          (click)="CloseConfirmStageDeactivateModal()">Cancel</button>
        <button type="button" class="btn nav-btn-accent shadow-none font-weight-bold"
          (click)="ToggleStageActive(selectedStage, true)">Deactivate</button>
      </div>
    </div>
  </ng-template>

  <ng-template #modalBulkDateAssign>
    <div class="modal-header">
      <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18"><i
          class="fak fa-clock-change fa-lg font-weight-normal grey4 mr-2"></i>Date assign</h2>
      <div class="d-flex justify-content-end">
        <i class="fas fa-close grey4" (click)="closeBulkAssignDateModal()"></i>
      </div>
    </div>
    <div class="modal-body grey4 nav-font14">
      <div class="row mb-3">
        <div class="col d-flex align-items-center">Type</div>
        <div class="col">
          <select [(ngModel)]="selectedBulkDateType" class="form-control form-control-sm form-control-nav">
            @for (type of bulkDateTypes; track type) {
            <option [value]="type.id">{{ type.name }}</option>
            }
          </select>
        </div>
      </div>
      <div class="row justify-content-end">
        @if (selectedBulkDateType==1 ||selectedBulkDateType==2) {
        <div class="col-6">
          <input type="text" autocomplete="off" readonly class="form-control form-control-sm form-control-nav"
            [bsValue]="lastStartDate" [daysDisabled]="[6,0]"
            [bsConfig]="{ dateInputFormat: 'MMM DD, YYYY', showWeekNumbers:false, adaptivePosition: true, customTodayClass: 'todays-date' }"
            placeholder="Date" (bsValueChange)="validateStartDate($event)" bsDatepicker>
        </div>
        }
        @if (selectedBulkDateType==3 || selectedBulkDateType==4 || selectedBulkDateType==5) {
        <div class="col-6">
          <input [(ngModel)]="shiftdays" required min="-100" max="1000" type="number"
            class="form-control form-control-sm form-control-nav" maxlength="30" id="days" placeholder="days">
        </div>
        }
      </div>
      <div class="col text-right pr-0 mt-4">
        <button type="button" class="btn nav-btn-primary mx-2 shadow-none font-weight-bold"
          (click)="closeBulkAssignDateModal()">Cancel</button>
        <button type="button" class="btn nav-btn-accent shadow-none font-weight-bold"
          (click)="BulkUpdateTaskDates()">Confirm</button>
      </div>
    </div>
  </ng-template>

  <ng-template #modalImportSchedule>
    <div class="modal-header">
      <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18"><i
          class="far fa-upload font-weight-normal grey4 mr-2"></i>Import schedule</h2>
      <div class="d-flex justify-content-end">
        <i class="fas fa-close grey4" (click)="closeImportScheduleModal()"></i>
      </div>
    </div>
    <div class="modal-body grey4 nav-font14">
      <div class="row d-block p-3">
        <a class="nav-font12 nav-accent-blue position-relative download-link float-right"
          (click)="ImportScheduleTemplate()">Download Template</a>
      </div>
      <div class="row d-block p-3">
        <or-upload smallMsg=".csv files only" isCsv=true (uploadCompleted)="importTasks()" publicBucket=false
          [includeProgressBar]=true acceptTypes=".csv" [instanceId]="auth.getUser().instanceId" [S3Folder]="s3buckPath"
          directToS3=true [(ngModel)]="uploadedFile" (change)="handleFileInput($event.target.files)">
        </or-upload>
      </div>
    </div>
  </ng-template>

  <ng-template #modalBulkTaskDelete>
    <div class="modal-header">
      <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18"><i
          class="fak fa-trash font-weight-normal grey4 mr-2"></i>Delete tasks</h2>
      <div class="d-flex justify-content-end">
        <i class="fas fa-close grey4" (click)="closeBulkTaskDeleteModal()"></i>
      </div>
    </div>
    <div class="modal-body grey4 nav-font14">
      <div class="row mb-4">
        <div class="col-8">Task</div>
        @if (templateMode != 'edit') {
        <div class="col text-right">Current assignee</div>
        }
      </div>
      <div class="reassign-tasks scrollbar overflow-y overflow-x-hidden">
        @for (task of allTasks; track task) {
        @if (task.isSelected) {
        <div class="row mb-2">
          <div class="col-9 d-flex align-items-center">
            <span class="dot d-inline-block mr-3" [style]="'background-color:'+ task.stageColor+';'"></span>
            <span class="grey5">{{task.taskName}}</span>
          </div>
          @if (templateMode != 'edit') {
          <div class="col">{{task.assignedToDisplayName ?? task.assignedToName}}</div>
          }
        </div>
        }
        }
      </div>
      <div class="row mt-4">
        <div class="col d-flex align-items-center">Please confirm you want to delete these tasks</div>
      </div>
      <div class="col text-right pr-0 mt-4">
        <button type="button" class="btn nav-btn-primary mx-2 shadow-none font-weight-bold"
          (click)="closeBulkTaskDeleteModal()">Cancel</button>
        <button type="button" class="btn nav-btn-accent shadow-none font-weight-bold"
          (click)="bulkDeleteTask()">Confirm</button>
      </div>
    </div>
  </ng-template>

  <!-- Modal New Project-->
  <ng-template #modalCloneSchedule>
    <ng-container>
      <div class="modal-header">
        <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18"><i
            class="fak fa-clock-change fa-lg font-weight-normal grey4 mr-2"></i>Clone a new Schedule</h2>
        <div class="d-flex justify-content-end">
          <i class="fas fa-close grey4" (click)="closeCloneScheduleModal()"></i>
        </div>
      </div>


      <div class="modal-body">
        <div class="row">
          <div class="col"></div>
          <div class="col-10 col-md-9">

            <ng-container>
              <div class="row mb-3 nav-font18">
                Select an existing project to clone
              </div>
              <div class="row mb-3">
                <div class="col d-flex align-content-center px-0">
                  <div class="input-group copy-search">
                    <div class="input-group-prepend">
                      <div class="input-group-text border-right-0">
                        <i class="fas fa-search grey4 cursor-default"></i>
                      </div>
                    </div>
                    <input type="search" #cloneProjectList [(ngModel)]="asyncSearch" (ngModelChange)="getMatches()"
                      matches="[]" placeholder="Search project name, project number, or client"
                      class="form-control form-control-sm cloneProjectDropdown" autocomplete="off">
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col px-0">
                  <div class="scrollbar overflow-y cloneProjectList" style="height: 300px;">
                    <table class="table table-hover mb-0">
                      <thead>
                        <tr class="grey6 nav-font14 font-weight-bold nav-bg-snow-white">
                          <th scope="col" class="px-4 border-bottom-0 border-top-0">Project name</th>
                          <th scope="col" class="px-4 border-bottom-0 border-top-0">ProjectID</th>
                          <th scope="col" class="px-4 border-bottom-0 border-top-0">Client</th>
                          <th scope="col" class="px-4 border-bottom-0 border-top-0">Start Date</th>
                          <th scope="col" class="px-4 border-bottom-0 border-top-0">End Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        @for (match of matches; track match; let idx = $index) {
                        <tr class="grey5 nav-font14 cursor-pointer" (mouseenter)="selectMatch(match)"
                          (click)="selectMatch(match, $event)">
                          <th scope="row" class="px-4 nav-accent-blue font-weight-normal"
                            [ngClass]="match?.id == selectedMatch ? 'selectedMatch selectedLeft' : ''"
                            [class.border-top-0]="idx == 0">{{match?.projectName}}</th>
                          <td class="px-4" [ngClass]="match?.id == selectedMatch ? 'selectedMatch' : ''"
                            [class.border-top-0]="idx == 0">{{match?.projectCode}}</td>
                          <td class="px-4 " [ngClass]="match?.id == selectedMatch ? 'selectedMatch' : ''"
                            [class.border-top-0]="idx == 0">{{match?.clientName}}</td>
                          <td class="px-4 " [ngClass]="match?.id == selectedMatch ? 'selectedMatch' : ''"
                            [class.border-top-0]="idx == 0">{{match?.projectStartDate | date: 'MM/dd/yyyy' }}</td>
                          <td class="px-4 " [ngClass]="match?.id == selectedMatch ? 'selectedMatch selectedRight' : ''"
                            [class.border-top-0]="idx == 0">{{match?.projectEndDate | date: 'MM/dd/yyyy' }}</td>
                        </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="row mb-3 nav-font18">
                Select a new start date to shift all task dates relative to the original project's start date
              </div>
              <div class="row mb-3">

                <div class="col align-content-center px-0">

                  <span class="font-weight-bold grey5 mr-2">Start date:</span>
                  <div class="col-4">
                    <input type="text" readonly class="form-control form-control-sm form-control-nav"
                      [bsValue]="lastStartDate" [daysDisabled]="[6,0]"
                      [bsConfig]="{ dateInputFormat: 'MMM DD, YYYY', showWeekNumbers:false, adaptivePosition: true, customTodayClass: 'todays-date' }"
                      placeholder="Date" (bsValueChange)="validateStartDate($event)" #dp="bsDatepicker" bsDatepicker>

                  </div>

                </div>
              </div>
            </ng-container>
          </div>
          <div class="col"></div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row d-flex w-100">
          <div class="col text-right pr-0">
            <button class="btn nav-btn-primary mx-2 shadow-none font-weight-bold"
              (click)="closeCloneScheduleModal()">Cancel</button>
            <button class="btn nav-btn-accent shadow-none font-weight-bold" [disabled]="startDateEnable"
              (click)="cloneSchedule();">Clone</button>
            <!-- <button  class="btn nav-btn-accent shadow-none font-weight-bold" [attr.disabled]="">Cancel</button> -->
            <!-- <or-spinner-button label="Create" #spinnerQuick customClass="btn btn-sm nav-btn-accent shadow-none" (callback)="saveQuickProject()"></or-spinner-button> -->
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <!-- modal confirm delete dynamic columns -->
  <ng-template #modalConfirmDelete>
    @if (modalConfirmDeleteData != null) {
    <div class="modal-header nav-error pt-2 pr-2">
      <div class="col pt-2 px-0">
        <div class="row d-flex align-items-center">
          <i class="fak fa-message-warning nav-font20 mx-2"></i>
          <span class="font-weight-bold nav-font16">{{modalConfirmDeleteData?.title}}</span>
        </div>
      </div>
      <div class="col-2 text-right px-0">
        <i class="far fa-close grey4 nav-font18 p-1 pr-2 clickable" (click)="openModalRefConfirmDelete.hide()"></i>
      </div>
    </div>
    }
    <div class="modal-body pt-0">
      <h5 class="grey5 d-block mb-3 mx-2">
        {{modalConfirmDeleteData?.message}}
      </h5>
    </div>
    <div class="modal-footer">
      <div class="row d-flex w-100 align-items-center">
        <div class="col-5 px-0 or-checkbox d-flex align-items-center justify-content-start">
          <input [(ngModel)]="notAskAgainScheduleColumns" type="checkbox" name="notAskAgainScheduleColumns"
            id="notAskAgainScheduleColumns">
          <label for="notAskAgainScheduleColumns"></label>
          <small class="pl-4 grey4">Don't ask again</small>
        </div>
        <div class="col text-right px-0">
          <button class="btn btn-sm nav-btn-primary mx-2 shadow-none font-weight-bold"
            (click)="openModalRefConfirmDelete.hide()">Cancel</button>
          <button class="btn btn-sm nav-btn-accent shadow-none clickable"
            (click)="modalConfirmDeleteData?.deleteCallback(); openModalRefConfirmDelete.hide()">Delete</button>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- Templates for newTask assignees -->
  <ng-template #tplNewTaskAssignee let-assignee>
    <div class="row">
      <div class="col-9 d-flex align-items-center justify-content-center pr-0">
        <div class="btn-group" dropdown #ddAssignee="bs-dropdown">
          <div id="button-basic" dropdownToggle controls="dropdown-basic" class="d-flex button-container p-0"
            [ngClass]="ddAssignee.isOpen ? 'open' : 'closed'">
            <div class="input-group copy-search w-75">
              <input #assigneeSearch [(ngModel)]="assignee.assigneeFilterText"
                class="form-control form-control-sm clickable"
                (keypress)="setIdIfExists($event, assignee) ? ddAssignee.toggle(true) : null"
                (blur)="setIdIfExistsOnBlur(ddAssignee, assignee, newTask)"
                (ngModelChange)="fetchAssigneeList(assignee); ddAssignee.isOpen ? null : ddAssignee.toggle(true)"
                placeholder="Type to search">
            </div>
            <div class="cursor-pointer d-flex align-items-center grey6 ml-auto">
              <i class="far grey4 float-right nav-font16 ml-auto mr-2" [class.fa-angle-up]="ddAssignee.isOpen"
                [class.fa-angle-down]="!ddAssignee.isOpen">
              </i>
            </div>
          </div>
          <div id="dropdownRIDCol" *dropdownMenu
            class="rid-col-picker-dropdown dropdown-menu dropdown-menu-right py-0 nav-font14 m-0" role="menu"
            aria-labelledby="button-basic">
            <div>
              <ul class=" py-0 px-0 my-0 noShadow">
                @for (item of filteredAssignees; track item) {
                <li class="expandable grey4 nav-font14 d-flex align-items-center px-0"
                  (mousedown)="updateAssignees(item.assignedToId, assignee, ddAssignee, newTask);">
                  <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
                    <span>{{item.assignedToName}}</span>
                  </div>
                  <hr class="p-0 my-0 mx-1">
                </li>
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col d-flex align-items-center justify-content-center">
        @if (assignee?.new && (assignee.assignedToId || !assignee.assignedToId)) {
        <span class="nav-font14 font-weight-bold"
          [ngClass]="assignee.assignedToId ? 'nav-accent-blue cursor-pointer': 'grey2 cursor-default'"
          (click)="assignee.assignedToId ? addAssignee(assignee) : null">
          <i class="far fa-plus mr-2"></i> Add
        </span>
        }
      </div>
    </div>
  </ng-template>

  <!-- Templates for edit assignees -->
  <ng-template #tplEditAssignees let-assignee let-task="task" let-type="type" let-index="index">
    <div class="row">
      <div class="col-10 d-flex align-items-center justify-content-center pr-0">
        <div class="mr-2 editable">
          <i [class.invisible]="task.assignedToUsers?.length===1"
            class="fak fa-trash nav-error font-weight-normal nav-font16 pl-2 cursor-pointer clickable"
            (click)="removeAssignee(assignee, type)"></i>
        </div>
        <div class="btn-group" dropdown #ddAssignee="bs-dropdown">
          <div id="button-basic" dropdownToggle controls="dropdown-basic" class="d-flex button-container p-0"
            [ngClass]="ddAssignee.isOpen ? 'open' : 'closed'">
            <div class="input-group copy-search w-75">
              <input #assigneeSearch [(ngModel)]="assignee.assigneeFilterText"
                class="form-control form-control-sm clickable"
                (keypress)="setIdIfExists($event, assignee) ? ddAssignee.toggle(true) : null"
                (blur)="setIdIfExistsOnBlur(ddAssignee, assignee, task)"
                (ngModelChange)="fetchAssigneeList(assignee); ddAssignee.isOpen ? null : ddAssignee.toggle(true)"
                placeholder="Type to search">
            </div>
            <div class="cursor-pointer d-flex align-items-center grey6 ml-auto">
              <i class="far grey4 float-right nav-font16 ml-auto mr-2" [class.fa-angle-up]="ddAssignee.isOpen"
                [class.fa-angle-down]="!ddAssignee.isOpen">
              </i>
            </div>
          </div>
          <div id="dropdownRIDCol" *dropdownMenu
            class="rid-col-picker-dropdown dropdown-menu dropdown-menu-right py-0 nav-font14 m-0" role="menu"
            aria-labelledby="button-basic">
            <div>
              <ul class=" py-0 px-0 my-0 noShadow">
                @for (item of filteredAssignees; track item) {
                <li class="expandable grey4 nav-font14 d-flex align-items-center px-0"
                  (mousedown)="updateAssignees(item.assignedToId, assignee, ddAssignee, task);">
                  <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
                    <span>{{item.assignedToName}}</span>
                  </div>
                  <hr class="p-0 my-0 mx-1">
                </li>
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>


  <!-- Template for inline assignees -->
  <ng-template #tplAssignees let-task let-stage="stage" let-type="type">
    @if (task?.assignedToUsers.length < 4) { <div class="d-flex w-100 align-items-center position-relative">
      @for (assignee of task?.assignedToUsers; track assignee; let idx = $index) {
      <span [tooltip]="task?.inlineEditMode ? '' : assignee.assignedToName"
        class="cursor-pointer inline-div clickable position-relative assignee"
        [class.editing-mode]="task?.addingAssignee && !assignee?.showPicker && (task?.inlineEditMode || type == 'new-inline')"
        [style.z-index]="assignee?.showPicker ? 1000 : (idx + 1)"
        (click)="task?.assignedToUsers.length < 4 ? handleAssigneeClick(task, stage, assignee, $event, type) : !assignee?.showPicker ? OpenModalEditAssignees(task, type): null">
        @if (assignee?.showPicker && (task?.inlineEditMode || type == 'new-inline')) {
        <div class="rounded-circle add-assignee d-flex align-items-center justifyu-content-center clickable"
          (click)="removeInlineAssignee(task, assignee, $event, type)">
          <i class="far fa-plus grey5 clickable"></i>
        </div>
        }
        <or-profile-image [name]="assignee.assignedToName || ' '" size="medium"
          [showInitials]="assignee.assignedToName ?? false"></or-profile-image>
      </span>
      @if (assignee?.showPicker && (task?.inlineEditMode || type == 'new-inline')) {
      <span>
        <i (click)="handleAssigneeClick(task, stage, assignee, $event, type)"
          class="far fa-xs grey4 usersDiv position-absolute" [class.fa-angle-up]="!assignee?.showDropdown"
          [class.fa-angle-down]="assignee?.showDropdown">
        </i>
        @if (assignee?.showDropdown) {
        <span class="justify-content-center or-dropdown assigneePicker inline-div">
          <or-dropdown-list class="dropdown-list clickable" [values]="this.usersForDropdown"
            [noSearchText]="'Search and select assignee'" [search]="true" [value]="assignee.assignedToId"
            (filter)="getUsersForDropdown($event)" (selected)="AssignTaskAssignee(task, assignee, $event, type)">
          </or-dropdown-list>
        </span>
        }
      </span>
      }
      }
      @if (task?.inlineEditMode || (type == 'new-inline')) {
      <div class="d-flex align-items-center w-100  h-100 clickable mr-3"
        [ngClass]="task?.assignedToUsers.length == 0 ? 'justify-content-center' : 'justify-content-end'"
        [class.editing-mode]="task?.addingAssignee && (task?.inlineEditMode || type == 'new-inline')"
        (click)="task?.assignedToUsers.length < 3 ? toggleAssignedPicker(task, null, true) : OpenModalEditAssignees(task, type)">
        <i class="far fa-plus grey4 mr-1 clickable"></i>
      </div>
      }
</div>
}

<!-- More than 3 assignees -->
@if (task?.assignedToUsers.length > 3) {
<div class="d-flex w-100 align-items-center"
  [tooltip]="!task?.inlineEditMode ? 'Assigned to ' + task?.assignedToUsersTooltip : ''" placement="bottom">
  @for (assignee of task?.assignedToUsers; track assignee; let idx = $index) {
  @if ((idx+1) < 3) { <span class="cursor-pointer inline-div editable position-relative assignee"
    [style.z-index]="idx + 1"
    (click)="!task?.inlineEditMode ? inlineEdit(stage, task, $event) : OpenModalEditAssignees(task, type)">
    <or-profile-image [name]="assignee.assignedToName || ' '" size="medium"
      [showInitials]="assignee.assignedToName ?? false"></or-profile-image>
    </span>
    }
    }
    <span class="cursor-pointer inline-div editable position-relative assignee" [style.z-index]="3"
      (click)="!task?.inlineEditMode ? inlineEdit(stage, task, $event) : OpenModalEditAssignees(task, type)">
      <or-profile-image [name]="'+ ' + (task?.assignedToUsers.length - 2)" size="medium"
        [showInitials]="true"></or-profile-image>
    </span>
    @if (task?.inlineEditMode || (type == 'new-inline')) {
    <div class="d-flex align-items-center w-100 justify-content-end h-100 editable mr-3"
      [ngClass]="task?.assignedToUsers.length == 0 ? 'justify-content-center' : 'justify-content-end'"
      (click)="OpenModalEditAssignees(task, type)">
      <i class="far fa-plus grey4 mr-1 editable"></i>
    </div>
    }
</div>
}
</ng-template>

<!-- modal view templates and apply -->
<ng-template #modalViewTemplates>
  <div class="modal-header nav-error pt-3 mt-2 pb-2 pr-2">
    <div class=" px-0 w-100">
      <div class="row mb-0 pt-2 align-items-center justify-content-center nav-secondary font-weight-bold nav-font24">
        @if (templateStep == 2) {
        <span class="grey4 nav-font14 cursor-pointer back-button font-weight-normal" (click)="templateStep = 1">
          <i class="fas fa-chevron-left mr-2 pr-1"></i>
          Back
        </span>
        }
        <div>
          <i class="fak fa-templates fa-lg font-weight-normal grey4 mr-2"></i>
          <div class="d-inline">Use a template for {{project.projectCode}}</div>
        </div>
        <i class="fas fa-close grey4 font-weight-normal nav-font18" (click)="closeViewTemplates()"></i>
      </div>
      <div class="row mt-4 pt- mx-0">
        <div class="col-12 col-md-12 grey5" [class.px-0]="templateStep == 1">
          @switch (templateStep) {
          @case (1) {
          <div class="mb-3 nav-font16 font-weight-bold">
            Find an existing template to apply to your project's schedule then click 'Next' to continue.
          </div>
          <div class="nav-font16">
            <div class="col-7 d-flex align-content-center px-0">
              <div class="input-group copy-search w-100">
                <div class="input-group-prepend">
                  <div class="input-group-text border-right-0 pr-2">
                    <i class="fas fa-search cursor-default"></i>
                  </div>
                </div>
                <input [(ngModel)]="templateViewFilter" type="search" class="form-control form-control-sm nav-font16"
                  (ngModelChange)="updateTemplateList()" placeholder="Search template name">
              </div>
            </div>
          </div>
          }
          @case (2) {
          <div class="mb-3 nav-font16 grey6">
            Review the <span class="font-weight-bold">{{templateScheduleSelected.templateName}}</span> template details
            below,
            then click 'Apply' to confirm your choice and integrate this template into your project schedule. To change
            this template, click 'Back' to go to the previous screen.
          </div>
          }
          }
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body pt-0 px-3 pr-4">

    @switch (templateStep) {
    <!-- template list -->
    @case (1) {
    <div class="row header template-list-header template-grid grey4 nav-font14 grey6 border font-weight-bold mx-0">
      <div class="task-name d-flex align-items-center border-right px-3 py-2">
        Template name
      </div>
      <div class="assignee d-flex align-items-center border-right p-2">
        Created by
      </div>
      <div class="status d-flex align-items-center p-2">
        Date
      </div>
    </div>
    <div class="body nav-font14" [class.text-center]="loadingTemplates">
      @if (scheduleTemplates.length == 0 && !loadingTemplates) {
      <div class="grey4 p-3">No templates saved. Save a schedule as a template to begin.</div>
      }
      @if (loadingTemplates) {
      <span class="loading"></span>
      }
      @if (scheduleTemplates.length > 0 && !loadingTemplates) {
      @for (template of filteredScheduleTemplates; track template) {
      <div class="row item template-grid grey6 nav-font14 align-items-center inline-div border-bottom mx-0"
        (click)="selectTemplate($event, template)"
        [class.selected-schedule]="templateScheduleSelected?.id == template.id">
        <div class="border-right h-100 pl-2 pr-3 py-2">
          <i class="fak fa-trash font-weight-normal nav-font14 nav-error mr-1"
            [class.remove-template]="auth.isAdmin() || auth.isSuperAdmin()"
            (click)="$event.preventDefault(); deleteTemplate(template);" [style.visibility]="'hidden'"></i>
          {{template.templateName}}
        </div>
        <div class="border-right px-2 h-100 d-flex align-items-center or-profile-container">
          <or-profile-image [name]="template.createdByName" showOnHover="true" showInitials="true"></or-profile-image>
        </div>
        <div class="px-2 py-2 h-100">{{template.createdOn | date: 'MM/d/yy' }}</div>
      </div>
      }
      }
    </div>
    }
    <!-- view selected template -->
    @case (2) {
    <app-schedule [project]="project" [templateForPreview]="templateForPreview" templateMode="view"></app-schedule>
    }
    }

  </div>
  <div class="modal-footer">
    <div class="row d-flex w-100 align-items-center">
      @if (templateStep==2) {
      <div class="col-4">
        <div class="d-flex float-left mb-1">
          <div class="or-checkbox">
            <input type="checkbox" name="assignAllToMe" id="assignAllToMe" [(ngModel)]="assignAllToMe">
            <label for="assignAllToMe"></label>
          </div>
          <div class="grey5 nav-font14 text-nowrap ml-3 pl-2">Assign me to all tasks</div>
        </div>
        <div class="d-flex float-left">
          <div class="or-checkbox">
            <input type="checkbox" name="overrideExisting" id="overrideExisting" [(ngModel)]="overrideExisting">
            <label for="overrideExisting"></label>
          </div>
          <div class="grey5 nav-font14 text-nowrap ml-3 pl-2">Override existing scheduled tasks</div>
          <i class="fal fa-info-circle nav-font12 grey4 ml-2 margin-top5" container="body"
            tooltip="This will remove pre-existing tasks on this project" placement="top"></i>
        </div>
      </div>
      }
      <div class="col text-right px-0">
        <button class="btn btn-sm nav-btn-primary mx-2 shadow-none font-weight-bold"
          (click)="closeViewTemplates()">Cancel</button>
        @switch (templateStep) {
        @case (1) {
        <button class="btn btn-sm nav-btn-accent shadow-none clickable" [disabled]="!templateScheduleSelected"
          [class.disabled-next]="!templateScheduleSelected" (click)="viewSelectedTemplate()">Next</button>
        }
        @case (2) {
        <button class="btn btn-sm nav-btn-accent shadow-none clickable" (click)="applySelectedTemplate()">Apply</button>
        }
        }

      </div>
    </div>
  </div>
</ng-template>


<ng-template #reoccurrenceSettings>
  @if (taskToEditReoccurrence.reoccurrenceSettings; as settings) {
  <div class="reoccurrenceSettingsContainer" [class.dont-show]="openModalRefReoccurringTaskChangeConfirm">
    <div class="modal-header pl-4 mr-1">
      <div class="d-flex w-100 align-items-center">
        <i class="fak fa-repeat-rounded mr-2 grey4 nav-font24"></i>
        <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18">Customize Task Reoccurrence</h2>
      </div>
      <div class="d-flex justify-content-end">
        <i class="far fa-close nav-font18 grey4 clickable"
          (click)="openModalRefReoccurrenceSettings.hide(); openModalRefReoccurrenceSettings = null"></i>
      </div>
    </div>
    <div class="modal-body grey5 nav-font14 pt-3">
      <div class="row mb-3">
        <div class="col-3 font-weight-bold">Starts</div>
        <div class="col-3">
          <div class="input-group form-control-date reoccurrence-startdate">
            <!-- (bsValueChange)="SaveTaskStartDate(task, $event)"-->
            <input type="text" autocomplete="off" bsDatepicker placement="bottom" #reoccurrenceStartDate="bsDatepicker"
              readonly (bsValueChange)="SetReoccurrenceDate($event, 'start')" [bsValue]="settings.startDate_dt"
              placeholder="MM/DD/YY"
              [bsConfig]="{ dateInputFormat: 'MM/DD/YY', showWeekNumbers:false, customTodayClass: 'todays-date'}"
              class="form-control form-control-sm d-inline bg-white date text-left w-50 px-2">
            <span class="input-group-text bg-white py-0"></span>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-3 font-weight-bold">Ends</div>
        <div class="col">
          <div class="input-group form-control-date">
            <input type="text" autocomplete="off" (click)="matchDatesWithProject(reoccurrenceEndDate)"
              (focus)="matchDatesWithProject(reoccurrenceEndDate)" bsDatepicker placement="bottom"
              #reoccurrenceEndDate="bsDatepicker" readonly (bsValueChange)="SetReoccurrenceDate($event, 'end')"
              [bsValue]="settings.endDate_dt" placeholder="MM/DD/YY"
              [bsConfig]="{ dateInputFormat: 'MM/DD/YY', showWeekNumbers:false, customTodayClass: 'todays-date' }"
              class="form-control form-control-sm d-inline bg-white date text-left w-50 px-2">
            <span class="input-group-text bg-white py-0" (click)="matchDatesWithProject(reoccurrenceEndDate)"><i
                class="fak fa-calendar grey4"></i></span>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-3 pr-0 font-weight-bold">Repeat</div>
        <div class="col d-flex">
          <div>
            <div class="btn-group frequency-type-dropdown" dropdown #reoccurrenceFrequencyType="bs-dropdown">
              <div id="button-basic" dropdownToggle controls="dropdown-basic" class="d-flex button-container"
                [ngClass]="reoccurrenceFrequencyType.isOpen ? 'open' : 'closed'" (click)="$event.preventDefault()">
                <span class="nav-font14 cursor-default"
                  [class.grey3]="settings.frequencyType == null">{{settings.frequencyType ?? 'Select'}}</span>
                <div class="cursor-pointer d-flex align-items-center grey6 ml-auto">
                  <i class="far grey4 float-right nav-font14 ml-auto"
                    [class.fa-angle-up]="reoccurrenceFrequencyType.isOpen"
                    [class.fa-angle-down]="!reoccurrenceFrequencyType.isOpen">
                  </i>
                </div>
              </div>
              <div *dropdownMenu class=" dropdown-menu dropdown-menu-right py-0 nav-font14 m-0 w-100" role="menu"
                aria-labelledby="button-basic" [ngClass]="reoccurrenceFrequencyType.isOpen ? 'open' : 'closed'">
                <ul class=" py-0 px-0 my-0 noShadow">
                  @for (t of frequencyTypes; track t; let isLast = $last) {
                  <li role="menuitem" class="dropdown-item grey4 nav-font14 d-flex align-items-center px-0"
                    (click)="settings.frequencyType != t ? changeFrequencyType(t) : null">
                    <div class=" cursor-pointer font-weight-normal w-100 px-2">
                      <span>{{t}}</span>
                    </div>
                  </li>
                  @if (!isLast) {
                  <hr class="p-0 my-0 mx-1">
                  }
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-3 pr-0 font-weight-bold">Every</div>
        <div class="col">
          <div class="mr-2 d-flex">
            <div class="btn-group frequency-dropdown" dropdown #reoccurrenceFrequency="bs-dropdown">
              <div id="button-basic" dropdownToggle controls="dropdown-basic" class="d-flex button-container"
                [ngClass]="reoccurrenceFrequency.isOpen ? 'open' : 'closed'" (click)="$event.preventDefault()">
                <span class="nav-font14 cursor-default" [class.grey3]="settings.frequency == null">{{settings.frequency
                  ?? 0}}</span>
                <div class="cursor-pointer d-flex align-items-center grey6 ml-auto">
                  <i class="far grey4 float-right nav-font14 ml-auto" [class.fa-angle-up]="reoccurrenceFrequency.isOpen"
                    [class.fa-angle-down]="!reoccurrenceFrequency.isOpen">
                  </i>
                </div>
              </div>
              <div *dropdownMenu class=" dropdown-menu dropdown-menu-right py-0 nav-font14 m-0 w-100" role="menu"
                aria-labelledby="button-basic" [ngClass]="reoccurrenceFrequency.isOpen ? 'open' : 'closed'">
                <ul class=" pb-1 pt-0 px-0 my-0 noShadow">
                  @for (f of frequencies; track f; let isLast = $last) {
                  <li role="menuitem" class="dropdown-item  grey4 nav-font14 d-flex align-items-center px-0"
                    (click)="settings.frequency = f; onReocurrenceChange();">
                    <div class="cursor-pointer font-weight-normal w-100 px-2">
                      <span>{{f}}</span>
                    </div>
                  </li>
                  @if (!isLast) {
                  <hr class="p-0 my-0 mx-1">
                  }
                  }
                </ul>
              </div>
            </div>
            <div class="ml-2">{{settings.frequencyType === 'Weekly' ? 'week(s)' : (settings.frequencyType === 'Daily' ?
              'day(s)': '')}}</div>
          </div>
        </div>
      </div>
      <div class="row mb-3" [ngStyle]="{'visibility' : settings.frequencyType === 'Weekly' ? 'visible' : 'hidden'}">
        <div class="col-3 font-weight-bold">On</div>
        <div class="col pl-0 d-flex justify-content-evenly justify-content-end">
          @for (day of settings.daysArray; track day; let isLast = $last) {
          <div class="dayPicker cursor-pointer d-flex align-items-center justify-content-center"
            [class.picked]="day.bit" (click)="day.bit ? day.bit = 0 : day.bit = 1; daysPickedChanged(settings)">
            {{day.label}}</div>
          }
        </div>
      </div>
      <div class="row summaryRow">
        <div class="col">
          <p [innerHtml]="settings.summary"></p>
        </div>
      </div>
    </div>
    <div class="modal-footer mb-0 pb-3">
      <div class="row d-flex w-100">
        <div class="col text-right pr-0">
          <button class="btn nav-btn-primary mx-2 shadow-none font-weight-bold clickable py-1"
            (click)="openModalRefReoccurrenceSettings.hide(); openModalRefReoccurrenceSettings = null">
            Cancel
          </button>
          <button class="btn nav-btn-accent shadow-none font-weight-bold clickable py-1"
            [class.nav-btn-disabled]="!settings.summary || !reoccurrenceChange"
            (click)="(settings.id != null && !onlyEndDateChanged && taskToEditReoccurrence.startDate != settings.startDate) ? 
                openReoccurringTaskChangeConfirmModal(taskToEditReoccurrence, 'edit', null, null) : saveReocurrenceSettings(taskToEditReoccurrence.id, settings)">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  }
</ng-template>

<ng-template #modalReoccurrenceSettings>
  <ng-template *ngTemplateOutlet="reoccurrenceSettings"></ng-template>
</ng-template>

<ng-template #modalReoccurringTaskChangeConfirm>
  <div class="modal-header pb-4 mb-2">
    <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18">
      @if (reoccurringTaskChangeData.mode === 'delete') {
      <i class="fak fa-trash font-weight-normal nav-font18 mr-2 grey4"></i>
      }
      @if (reoccurringTaskChangeData.mode === 'edit') {
      <i class="fak fa-edit-square font-weight-normal nav-font18 mr-2 grey4"></i>
      }

      {{reoccurringTaskChangeData.mode === 'delete' ? 'Delete' : 'Edit'}} Reoccurring Task
    </h2>
    <div class="d-flex justify-content-end">
      <i class="far fa-close grey4 nav-font18 clickable" (click)="cancelReoccurringTaskConfirm()"></i>
    </div>
  </div>
  <div class="modal-body grey4 nav-font14 pb-4 pt-0 pl-4 ml-4">
    <!-- <div>You are {{reoccurringTaskChangeData.mode === 'delete' ? 'deleting' : 'editing'}} the reoccuring task  '<span class="font-weight-bold">{{reoccurringTaskChangeData.task.taskName}}</span>'. </div> -->

    @for (o of reoccurringTaskChangeData.options; track o; let i = $index) {
    <div class="mb-3 d-flex align-items-center">
      <input type="radio" [id]="o.value" [checked]="o.checked" (change)="checkboxClickedReoccuringTaskChange(o.value)">
      <label class="radio-label" [for]="o.value"></label>
      <span class="ml-2 grey5">{{o.label}}</span>
    </div>
    }

    <div class="col text-right pr-0 mt-4">
      <button type="button" class="btn nav-btn-primary mx-2 shadow-none font-weight-bold clickable nav-font14"
        (click)="cancelReoccurringTaskConfirm()">Cancel</button>
      <button type="button" class="btn nav-btn-accent shadow-none font-weight-bold clickable nav-font14"
        (click)="updateReoccuringTasks(reoccurringTaskChangeData.mode)">Save</button>
    </div>
  </div>
</ng-template>