import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService, AlertifyService, SurveyTesterService } from 'core';
import { validate as isValidUUID } from 'uuid';
import { environment } from 'projects/core/src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthGuestGuard implements CanActivate {

  tokenName = 'TOKEN_' + environment.apiEndpoint;

  constructor(
    private authService: AuthService,
    private router: Router,
    private service: SurveyTesterService,
    private alertify: AlertifyService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let { url, token } = route.queryParams;

    if (!this.isValidUrl(url)) {
      return this.redirect();
    }

    if (!token) {
      this.alertify.error('Guest token has not been provided.');
      return this.redirect();
    }

    if (!isValidUUID(token)) {
      this.alertify.error('Guest token is invalid.');
      return this.redirect();
    } else {
      this.authService.guestLogin(token).subscribe(data => {
        if (data.user != null) {
          // Perform check first
          this.service.isLinkExpired(token).subscribe(isExpired => {
            if (isExpired === null || isExpired) {
              this.alertify.error('Link has expired!');
              return this.redirect();
            } else {
              this.alertify.success('You are now logged in as a guest.');
              return true;
            }
          });
        } else {
          this.alertify.message('Guest login failed. Check with Adm if your token is valid and not expired.');
          return this.redirect();
        }
      }, error => {
        console.log(error);
        this.alertify.error('Guest login failed. Check with Adm if your token is valid and not expired.');
        return this.redirect();
      });
    }
  }

  redirect() {
    localStorage.removeItem(this.tokenName);
    localStorage.removeItem('user');
    localStorage.removeItem('comments');
    this.router.navigate(['']);
    return false;
  }

  isValidUrl(urlString) {
    var a  = document.createElement('a');
    a.href = urlString;
    return (a.host && a.host != window.location.host);
  }
}
