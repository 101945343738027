import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { AuthService} from 'core';
import { DashboardService } from 'projects/core/src/services/dashboard.service';
import Highcharts from 'highcharts';

@Component({
  selector: 'app-widget-revenue-by-manager',
  templateUrl: './widget-revenue-by-manager.component.html',
  styleUrls: ['./widget-revenue-by-manager.component.scss']
})
export class WidgetRevenueByManagerComponent implements OnInit {

  @Input() widget;
  @Output() onremove = new EventEmitter();
  @Output() onupdate = new EventEmitter();

  highcharts = Highcharts;
  highchartOptions: Highcharts.Options;

  projectByTypeMyProjects = true;

  settings = {
    myProjects: false,
    timeSpan: null,
    maxCount: null,
    projectCategories: [],
    projectTypes: []
  };

  constructor(
    public http: HttpClient,
    public auth: AuthService,
    public dashboardService: DashboardService) { }

  ngOnInit() {
    this.revenueByManagerChart();
  }

  revenueByManagerChart() {
    this.dashboardService.getRevenueByManager(this.settings).subscribe(data => {
      if (data.length <= 0) return;

      var managers = data.map(item => {
        //item.color = this.randomRGB();
        return item
      });

      var chartData = this.buildChartData(managers);
      console.log(chartData);
      this.drawHighChartsChart(chartData);
    },
    error => console.log(error));
  }

  buildChartData(managers) {

    let series= [];
    let estimated = [];
    let approved= [];

    managers.forEach((item) => {
      if (item.estimated == 0 && item.approved == 0) return;
      estimated.push([item.manager, parseFloat(item.estimated)]);
      approved.push([item.manager, parseFloat(item.approved)]);
    });

    series.push(estimated, approved);
    return series;
  }

  drawHighChartsChart(chartData) {
    this.highchartOptions = {
      credits: {
        enabled: false
      },
        chart: {
          type: 'column',
          events: {
            render: function() {
              this.reflow();
            }
          }
      },
      title: {
          text: ''
      },
      plotOptions: {
        column: {
            grouping: false,
            borderWidth: 0
          }
      },
      legend: {
          enabled: false
      },
      tooltip: {
          shared: true,
          headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
          pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} </b><br/>'
      },
      xAxis: {
          type: 'category',
          labels: {
              useHTML: true,
              style: {
                  textAlign: 'center'
              }
          }
      },
      yAxis: [{
          title: {
              text: 'Revenue'
          },
          showFirstLabel: false
      }],
      series: [{
        name: 'Estimated',
        color: 'rgb(158, 159, 163)',
        pointPlacement: -0.2,
        linkedTo: 'main',
        type: 'column',
        data: chartData[0],
      },
      {
        name: 'Approved',
        type: 'column',
        id: 'main',
        dataSorting: {
          enabled: true,
          matchByName: true
        },
        dataLabels: [{
          enabled: true,
          inside: true,
          style: {
            fontSize: '16px'
          }
        }],
        data: chartData[1]
      }],
      exporting: {
          allowHTML: true
      }
    };
  }

  remove() {
    this.onremove.emit();
  }

  updateWidget() {
    this.onupdate.emit();
  }

  updateTitle(widgetTitle) {
    this.widget.title = widgetTitle;
    this.updateWidget();
  }
}
