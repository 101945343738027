import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertifyService, AlertMessageService, AuthService, UserService, UtilsService } from 'core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-alert-settings',
  templateUrl: './alert-settings.component.html',
  styleUrls: ['./alert-settings.component.scss']
})
export class AlertSettingsComponent implements OnInit {

  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;
  openModalRef: BsModalRef;

  columns: any = [
    { label: 'Event', id: 'eventLabel', sortable: true },
    { label: 'Projects', id: 'project', sortable: true },
    { label: 'Action', id: 'action', sortable: true },
    { label: 'Actions', type: 'actions-inline', width: 70 }
  ]
  filterColumns = ['eventLabel', 'project', 'action'];
  actions = [{label: 'Delete Setting', type: 'delete', icon: 'times'}];

  columnsAlert: any = [
    { label: 'Time', id: 'time', sortable: true },
    { label: 'Type', id: 'type', sortable: true, filterable: true },
    { label: 'Message', id: 'title', sortable: true },
    { label: 'Project', id: 'projectName', sortable: true, selectable: true, filterable: true },
    { label: 'Updated By', id: 'updatedBy', sortable: true, filterable: true },
    { label: 'Actions', type: 'actions-inline', width: 50 }
  ]

  eventLabels = {
    'ProjectCreated' : 'Project Created',
    'ProjectStatusPaused' : 'Project Paused',
    'ProjectStatusLive' : 'Project Live',
    'ProjectStatusInvoiced' : 'Project Invoiced',
    'ProjectStatusClosed' : 'Project Closed',
    'ProjectAllocation' : 'Allocation Reached',
    'ProjectOverQuota' : 'Overquota',
    'ProjectDOR' : 'Dropout Rate',
    'OverQuotaGroup' : 'Quota Group',
    'NoteAdded' : 'Note Added',
    'TasksAssignedToMe' : 'Tasks Assigned to Me',
    'OverdueTasksAssignedToMe' : 'Overdue Tasks Assigned to Me',
    'VendorStatus' : 'Status Changed',
    'VendorProjectStop' : 'Project Closed/Paused',
    'VendorOverQuota' : 'Overquota',
    'VendorCostConfirmed' : 'Costs Confirmed',
    'VendorRecRemoveComplete' : 'Reconcilation Complete removal',
    'VendorReconciledID' : 'Reconciled ID',
    'VendorOverQuotaGroup' : 'Quota Group'
  }

  filterColumnsAlert = ['type', 'time', 'projectId'];

  userModel;
  tab = 'settings';
  tableData = [];
  tableAlerts = [];
  clickroute = '/projects';
  filterText = '';
  dataDelete: any;
  loading = true;
  submitted = false;
  settingsForm: FormGroup;
  notificationUsers = [];
  copyUser;

  constructor(
    private alertService: AlertMessageService,
    public auth: AuthService,
    private router: Router,
    private alertify: AlertifyService,
    private user: UserService,
    private modalService: BsModalService,
    public utils: UtilsService) { }

  ngOnInit(): void {
    this.userModel = this.auth.getUser();
    this.getSettings();

    this.settingsForm = new FormGroup({
      event: new FormControl('', [Validators.required]),
      project: new FormControl('', [Validators.required]),
      action: new FormControl('', [Validators.required])
    });
  }

  handleAction(action) {
    if (action.type === 'delete') {
      this.dataDelete = action.row;
      this.openModalRef = this.modalService.show(this.modalRefConfirm);
    }
  }

  confirmDelete() {
    this.alertService.deleteNotificationSettings(this.dataDelete.id).subscribe(data => {
      this.alertify.success('Notification Settings deleted successfully');
    }, error => {
       this.alertify.error('Unable to delete notification settings');
       this.openModalRef.hide();
    }, () => {
      this.openModalRef.hide();
      this.getSettings();
    });
  }

  getSettings() {
    this.alertService.getNotificationSettings().subscribe(data => {
      this.loading = false;
      data.forEach(e => {
        e.eventLabel = this.eventLabels[e.event];
      });

      this.tableData = data;
    });
    this.alertService.getNotificationUsers().subscribe(data => {
      this.notificationUsers = data;
    });
  }

  changeTabs(tab) {
    this.tab = tab;
    if (tab == 'alerts') this.openMessages();
  }

  openMessages() {
    this.loading = true;
    this.alertService.getAlerts(200).subscribe(data => {
      this.tableAlerts = data.filter(item => {
        var date = new Date(item.lastUpdated);
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        item.time = this.timeSince(date);
        return item;
      });
    }, error => {
      this.alertify.error('Unable to get alerts');
    }, () => {
      this.loading = false;
    });
  }

  copyNotifications(){
    if(this.copyUser == null || this.copyUser == '') return;

    this.alertService.copyNotificationSettings(this.copyUser).subscribe(data => {
      this.getSettings();
      this.alertify.success('Notification Settings have been copied and list is updating');
    });
  }

  submit() {
    this.submitted = true;
    if (!this.settingsForm.valid) return;

    this.alertService.insertNotificationSettings(this.settingsForm.value).subscribe(data => {
      this.alertify.success('Notification setting added successfully');
      this.getSettings();
    }, error => {
       this.alertify.error('Unable to add notification setting');
    }, () => {
      this.reset();
    });
  }

  reset() { this.settingsForm.reset({event: '', project: '', action: '', instanceId: this.auth.getUser().instanceId}); }

  timeSince(date) {
    const intervals = [
      { label: 'year', seconds: 31536000 },
      { label: 'month', seconds: 2592000 },
      { label: 'day', seconds: 86400 },
      { label: 'hour', seconds: 3600 },
      { label: 'minute', seconds: 60 },
      { label: 'second', seconds: 0 }
    ];

    const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
    const interval = intervals.find(i => i.seconds < seconds);
    const count = Math.floor(seconds / interval.seconds);

    if (!isFinite(count) && interval.label == 'second') return 'just now';
    return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
  }
}
