import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef, HostListener, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { AlertifyService, ProjectService, AuthService, QuotaService, ProjectQuota, QuotaGroup, Quota, DropdownQuota, UserService, QuotaVendorLimit, DropdownService, UtilsService, ProjectDataService } from 'core';
import { v4 as uuidv4 } from 'uuid';
import {  forkJoin } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { cl } from '@fullcalendar/core/internal-common';
import { DndDropEvent } from 'ngx-drag-drop';

@Component({
  selector: 'app-project-quota',
  templateUrl: './project-quota.component.html',
  styleUrls: ['./project-quota.component.scss']
})
export class ProjectQuotaComponent implements AfterViewChecked, OnInit {
  @ViewChild('modal')
  modalRefConfirm: BsModalRef;
  openModalRef: any;
  modalType = "";

  @ViewChild('quotaConfig')
  quotaConfig: TemplateRef<any>;
  openModalRefQuotaConfig: any;
  quotaConfigEditDetails: any;
  

  @Input() project;
  @Input() respondents;
  @Output() onclose = new EventEmitter();
  @Output() redirectToSection = new EventEmitter();

  @ViewChild('dropdownDemos', {static: false}) dropdownDemo;
  listenerFn: () => void;
  propagateChange: (_: any) => {};

  @ViewChild('dropdownSettings', {static: false}) dropdownSettings;

  isVendorview: boolean;
  quotaEnabled
  instanceId: string;
  optionFilterArgs = {isDeleted: true};
  metrics = {starts: 0, completes: 0}
  justSaved = true; // to know if changes to quota were saved
  newQuotaGroups = [];
  newQuota = null;
  s3buckPath;
  importFileName;
  loadingImport = false;

  questions;
  questionIDs = [];
  projectQuota: ProjectQuota = null;
  quotaGroupTotals = {};
  vendorGroupTotals = {};
  vendors;
  deletedQGOptions = []
  groupWithNoLimitExists = false;
  overlapExists = false;
  overTotalExists = false;
  overTotalError = false;
  overlapError = false;
  actionsOpen = false;
  daysPercentagePast = 0;

  questionTypes = [
    {label:"Age", value:"Age"},
    {label:"Gender",value:"Gender"},
    {label:"Income", value:"HouseholdIncome"},
    {label:"Region", value:"Region", type: "location"},
    {label: "Ethnicity", value: "Ethnicity"},
    {label: "Hispanic", value: "Hispanic"}]
  addedTypes = {}; // added Q types
  typesEmpty = true; // true if no question type selected
  typesFull = false; // true if every type of question selected

  // hhi
  hhiInterval = 5000;
  hhiMin = 15000;
  hhiMax = 150000;
  hhiList = this.populateHHIList();

  // for vendor carousel
  vendorList = [];
  vendorIndexLeft = 0;
  vendorIndexRight = 1;
  leftVendorComing = false;
  rightVendorComing = false;
  swapColor: boolean = false;

  vendorCardGridTemplate = null;
  vendorCardGridMinWidth = '200px';
  vendorCardOverflowX = false;
  vendorCardOverflowY = false;

  // for importing quota template
  quotaTemplateId: string;
  quotaTemplateSearch: string;
  quotaTemplates = [];
  actions = [];
  projectList = [];
  projectIds = [];
  


  constructor(
    private alertify: AlertifyService,
    private dropdownService: DropdownService,
    public auth: AuthService,
    private projectService: ProjectService,
    private quotaService: QuotaService,
    private userService: UserService,
    private modalService: BsModalService,
    private utils: UtilsService,
    private projectDataService: ProjectDataService,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.instanceId = this.auth.getInstance();
    this.s3buckPath =  "quota/" + this.project.id;

    window.scroll(0, 0);

    this.quotaEnabled = this.project.isQuotaEnabled;
    this.getQuota();

    const daysLeft = (this.project.totalDaysLeft < 0 ) ? 0 : this.project.totalDaysLeft;
    const totalDays = this.project.totalDays;
    this.daysPercentagePast = 100 - Math.round(daysLeft * 100 / totalDays)

    this.projectDataService.getVendorViewSidebar.subscribe(data => {
      if (data) {
        this.isVendorview = data != '';
      }
    });
  }

  ngAfterViewChecked() {
    this.checkVendorCardOverflow();
  }

  populateHHIList() {
    var hhiList = [];
    for (var n = this.hhiMin; n <= this.hhiMax; n += this.hhiInterval) hhiList.push(n);
    return hhiList;
}

  SaveOverallN()
  {
    var fullQuota = Number(this.project.fullLaunchQuota)
    if (!Number.isNaN(fullQuota)) {
      this.projectService.UpdateProjectValue(this.project.id, 'fullLaunchQuota', fullQuota).subscribe({
        complete: () => {
          this.alertify.success('Full launch quota updated successfully.');
          this.processQuota(this.projectQuota);
        },
        error: () => {
          this.alertify.error('There was an issue updating the full launch quota value.');
        },
      });
    }
  }

  leaveSetupMode(){
    this.getQuota();
    this.newQuotaGroups = [];
  }

  processData(data) {
    if (data.length > 0) {
      let summary = {starts: 0, completes : 0};
      data.forEach(element => {
  
        summary.completes += element.complete
        summary.starts += element.starts
      });

      var vendorAllocationTotal = this.vendors.filter(x => !x.isDeleted).reduce((sum, item) => sum + item.partnerFullQuota, 0);
  
      this.vendors.forEach(vendor => {
        vendor.starts = 0;
        vendor.completes = 0;
        data.filter(x => x.partnerId === vendor.partnerId).forEach(element => {
          vendor.starts += element.starts;
          vendor.completes += element.complete;
        });

        vendor.totalFullQuotaPercent = vendor.partnerFullQuota > 0 ? Math.ceil(vendor.partnerFullQuota * 100 / vendorAllocationTotal) : 0;

      });
      this.metrics = summary;
    }
    if(this.project.id == "d583bf3a-ab7e-441f-b1a1-07c96bb3e2e6") {
      //Dummy data for demo
      this.metrics.starts = 23513;
      this.metrics.completes = 4000;
      this.vendors.forEach(vendor => {
        if(vendor.partnerName == "Lucid Holdings, Inc") {
          vendor.starts = 10616;
          vendor.completes = 1921;
        }
        else  if(vendor.partnerName == "Cint") {
          vendor.starts = 6465;
          vendor.completes = 1217;
        }
        else  if(vendor.partnerName == "TapResearch Inc") {
          vendor.starts = 6383;
          vendor.completes = 852;
        }
        else  if(vendor.partnerName == "Prodege POD") {
          vendor.starts = 49;
          vendor.completes = 10;
        }
      });
    }
  }


  getQuota()
  {
    forkJoin([this.projectService.GetQuota(this.project.id), this.quotaService.ListQuestions()]).subscribe((data) => {
      
      this.vendors = this.project.projectSegments[0].projectSurveyPartners
      .filter(x => x.isDeleted == false)
      .sort((a, b) => a.partnerName.localeCompare(b.partnerName));

      this.processData(this.respondents);

      this.questions = {};
      this.questionIDs = [null];

      data[1].forEach(q => {
        this.questions[q.id] = q;
        this.questionIDs.push(q.id);
      })

      if (data[0] != null) {
        this.processQuota(data[0]);
      }

      if (this.projectQuota.quotaGroups.length > 0) {
        this.updateAddedQuotaGroupList();
      };
      this.justSaved = true;
      if (this.projectQuota.limitMode == null) this.projectQuota.limitMode = 'start'

    },
      error => {
        this.alertify.error('There was an error getting quota details');
      },);
  }



  processQuota(projectQuota: ProjectQuota) {

    //if (projectQuota.limitCheck == 'tracking only') projectQuota.limitCheck = 'tracking';
    if (projectQuota.limitType == null) projectQuota.limitType = 'number';

    if(this.project.id == "d583bf3a-ab7e-441f-b1a1-07c96bb3e2e6") {
      projectQuota.quotaGroups.forEach(qg => {

        qg.quotas.forEach(q => {
          let percent = Math.floor(Math.random() * 25 + 80)/100;
          q.completes = q.limit * percent;
          
          //random allocation to each vendor
          let arr = Array.from({length: 5}, () => Math.floor(Math.random() * 100));
          let sum = arr.reduce((a, b) => a + b, 0);
          arr = arr.map(x => Math.floor(x / sum * 100));
          let idx = 0;
          q.vendorLimits.forEach(vendor => {
            vendor.completes = Math.floor(q.completes * arr[idx++]/100);
          });
        });
        if (qg.questionId.length <= 36) this.handleDeletedQuotas(qg);
      });
    }
    //console.log("QUOTA DETAILS:", projectQuota);
    this.projectQuota = projectQuota;
    this.sort(this.projectQuota.quotaGroups, 'order')
    var quotasWithOptionAnswers = ['Gender', 'Ethnicity', 'Hispanic']
    var i = 0;
    this.projectQuota.quotaGroups.forEach(qg => {
      qg.completes = 0;
      qg.starts = 0;
      qg.quotas.filter(x=> !x.isDeleted).forEach(q => {
        q.groupId = qg.id;
        qg.completes += q.completes;
        qg.starts += q.starts;

        var quotaValue;

        if (quotasWithOptionAnswers.includes(qg.name)) {
          quotaValue = q.targetGroup[qg.name.toLocaleLowerCase()];
          q.order = this.questions[qg.questionId].options.find(x => x.value === quotaValue)?.order;
        }
        else {
          if (qg.name === 'Income' && q.targetGroup.minHHI == -1) quotaValue = 'pnta';
          for (let qId in this.questions) {
            if (this.questions[qId].type === qg.name) {
              if (qg.name == 'Region') q.order =  this.questions[qId].defaults.find(x => x.value === q.targetGroup[qg.name.toLocaleLowerCase()]).order
            }
          }
        }

        if (q.limit == 99999) {
          q.limitN = 99999;
          q.limitPercent = 99999;
        } else {
          q.limitN = (projectQuota.limitType === 'number') ? q.limit : Math.ceil(this.project.fullLaunchQuota * q.limit / 100);
          q.limitPercent = (projectQuota.limitType === 'percent') ? q.limit : Math.ceil(q.limit * 100 / this.project.fullLaunchQuota);
        }
        i++;
        this.addVendorsToQuota(q);
        this.handleQuotaChange(q);
      });
      if (qg.questionId.length <= 36) {
        this.handleDeletedQuotas(qg);
        var pntaExistsInQuotas;
        var preferNotToAnswerEnabled = this.questions[qg.questionId].preferNotToAnswer;
        if (qg.name == 'Income' || quotasWithOptionAnswers.includes(qg.name)) {
          if (quotasWithOptionAnswers.includes(qg.name)) {
            this.questions[qg.questionId].options.forEach(option => {
              if (option.value === 'pnta') {
                pntaExistsInQuotas = qg.quotas.find(q => q.targetGroup[qg.name.toLowerCase()] === option.value);
              }
            });
          }
          else { // if income
            pntaExistsInQuotas = qg.quotas.find(q => q.targetGroup.minHHI === -1);
          }

          if (!pntaExistsInQuotas && preferNotToAnswerEnabled) {
            var quota = this.blankQuota();
            quota.isDeleted = true;
            if (qg.name === 'Income') quota.targetGroup.minHHI = -1;
            else quota.targetGroup[qg.name.toLowerCase()] = 'pnta';
            quota.limitPercent = 0;
            quota.limitN = 0;
            quota.limit = 0;
            this.addVendorsToQuota(quota);
            qg.quotas.push(quota);
            qg.preferNotToAnswerAdded = false;
          }
        }
      }
      else {
        qg.questionIdArray = qg.questionId.split(',')
      }

      if (qg.name === 'Income') this.sort(qg.quotas, 'minHHI');
      else if (qg.name === 'Age') this.sort(qg.quotas, 'minAge');
      else this.sort(qg.quotas, 'order');

      qg.nonValueStarts = this.metrics.starts > 0 ? (this.metrics.starts - qg.starts) || 0 : 0;
      qg.nonValueCompletes = this.metrics.completes ? (this.metrics.completes - qg.completes) || 0 : 0;

      qg.starts = qg.starts + qg.nonValueStarts;
      qg.completes = qg.completes + qg.nonValueCompletes;

      const totalN = qg.quotas.filter(x => x.isDeleted == false && x.limitN != 99999).reduce((a, b) => a + (Number(b.limitN) || 0), 0);
      const totalPercent = qg.quotas.filter(x => x.isDeleted == false && x.limitPercent != 99999).reduce((a, b) => a + (Number(b.limitPercent) || 0), 0);
      this.quotaGroupTotals[qg.id] = { n: totalN, percent: totalPercent, vendorTotals: {} };
    });

    if (!this.projectQuota.limitCheck) {
      this.projectQuota.limitCheck = 'tracking'
    }

    this.getVendorCardGridTemplate();
  }

  closeQuota() {
    this.onclose.emit(null);
  }

  getVendorCardGridTemplate() {
    var numVendors = this.vendors.filter(x => !x.isDeleted).length;
    if (numVendors > 0) {
      this.vendorCardGridTemplate = `265px repeat(auto-fill, minmax(249px, calc((100% - 265px)/${numVendors})))`
      this.vendorCardGridMinWidth = (265 + numVendors * 252 ) + 'px';
    }
    else {
      this.vendorCardGridTemplate = `265px`
    }

  }

  handleQuotaChange(quota) {
    var quotaGroupTotals = {};
    const vendorGroupTotals = {};

    var group = this.projectQuota.quotaGroups.find(x => x.id === quota.groupId);
    const totalN = group.quotas.filter(x => x.isDeleted == false && x.limitN != 99999).reduce((a, b) => a + (Number(b.limitN) || 0), 0);
    const totalPercent = group.quotas.filter(x => x.isDeleted == false && x.limitPercent != 99999).reduce((a, b) => a + (Number(b.limitPercent) || 0), 0);

    quotaGroupTotals = { n: totalN, percent: totalPercent, vendorTotals: {} };

      var vendorN;
      var vendorPercent;

      if ((this.projectQuota.limitType == 'number' && quota.limitN >= 99999) || ( this.projectQuota.limitType == 'percent' && quota.limitPercent >= 99999)) {
        quota.limitN = 99999;
        quota.limitPercent = 99999;
        vendorN = 99999;
        vendorPercent = 99999;
      }
      else {
        if (this.projectQuota.enableVendorLimits) {
          vendorN = quota.vendorLimits.filter(x => x.limitN != 99999).reduce((a, b) => Number(a) + (Number(b.limitN) || 0), 0);
          vendorPercent = quota.vendorLimits.filter(x => x.limitPercent != 99999).reduce((a, b) => Number(a) + (Number(b.limitPercent) || 0), 0);
        }
      }
      quotaGroupTotals[quota.id] = { n: vendorN, percent: vendorPercent };

      quota.vendorLimits.filter(x => x.limitN != 99999 && x.limitPercent != 99999).forEach(v => {
        if (quotaGroupTotals[v.id + '_' + group.id] == null) { quotaGroupTotals[v.id + '_' +  group.id] = 0; }
        quotaGroupTotals[v.id + '_' +  group.id] += v.limitN;
        if (!vendorGroupTotals[v.vendorId + "_" +  group.id]) {
          vendorGroupTotals[v.vendorId + "_" +  group.id] = {n: 0, percent: 0}
        }
        vendorGroupTotals[v.vendorId + "_" +  group.id].n += Number(v.limitN);
        vendorGroupTotals[v.vendorId + "_" +  group.id].percent += Number(v.limitPercent);

      });

    this.quotaGroupTotals[group.id] = quotaGroupTotals;

    if (group.name === 'Income') this.sort(group.quotas, 'minHHI');
    else if (group.name === 'Age') this.sort(group.quotas, 'minAge');
    else this.sort(group.quotas, 'order');
  }

  addBackQuota(group, quota) {
    var quotaToSave = null;
    if (quota.id == null) {
      group.deletedOptions.forEach((element,index)=>{
        if(element.label==quota.name) group.deletedOptions.splice(index,1);
     });
      const question = this.questions[group.questionId];
      const newQuota: Quota = this.blankQuota()        
      newQuota.targetGroup[group.name.toLowerCase()] = quota.name;
      newQuota.order = question.options.find(x => x.value === quota.name).order || 999;

      const defaultItem = question.defaults.find(e => e.value === quota.name);
      if (defaultItem != null) {
        newQuota.limitPercent = defaultItem.percent;
        newQuota.limitN = Math.ceil((this.project.fullLaunchQuota * (defaultItem.percent / 100)));
        newQuota.limit = ((this.projectQuota.limitType === 'number') ? newQuota.limitN : newQuota.limitPercent)
      }
      newQuota.groupId = group.id;
      this.addVendorsToQuota(newQuota);
      group.quotas.push(newQuota);
      quotaToSave = newQuota;
    }
    else {
      quotaToSave = group.quotas.find(e => e.id === quota.id);
      quotaToSave.groupId = group.id;
      quotaToSave.isDeleted = false;

      // if limitPercent isnt set but limitN is, set limitPercent
      if (!quotaToSave.limitPercent && quotaToSave.limitN != null) {
        quotaToSave.limitPercent = Math.ceil(quotaToSave.limitN * 100 / this.project.fullLaunchQuota);
      }
      else if (!quotaToSave.limitN && quotaToSave.limitPercent != null) {
        quotaToSave.limitN = Math.ceil(this.project.fullLaunchQuota * (quotaToSave.limitPercent / 100));
      }
      else if (!quotaToSave.limitN && !quotaToSave.limitPercent ) {
        quotaToSave.limitN = 0;
        quotaToSave.limitPercent = 0;
      }

      group.deletedOptions.forEach((element,index)=>{
        if(element.id==quotaToSave.id) group.deletedOptions.splice(index,1);
     });
     if (group.name === 'Gender' || group.name === 'Ethnicity' || group.name === 'Hispanic') {
      quotaToSave.order = this.questions[group.questionId].options.find(x => x.value === quotaToSave.targetGroup[group.name.toLowerCase()]).order
     }
      else {
        for (let qId in this.questions) {
          var question = this.questions[qId]
          if (question.type === group.name) {
            if (group.name === 'Division' || group.name === 'Region') quotaToSave.order = question.defaults.find(x => x.value === quotaToSave.targetGroup[group.name.toLowerCase()]).order
          }
        }
      }
    }
    this.sort(group.quotas, 'order');
    this.saveQuota(quotaToSave);
  }

  addBackPNTA(group) {
    var quota = null
    if (group.name === 'Income') quota = group.quotas.find(e => e.targetGroup.minHHI === -1);
    else quota = group.quotas.find(e => e.targetGroup[group.name.toLowerCase()] === 'pnta');
     
    if (quota) {
      quota.groupId = group.id;
      quota.order = 9999999;
      group.preferNotToAnswerAdded = true;
      this.addBackQuota(group, quota);
    }
  }

  addBlankQuota(group, type){
    if (this.auth.isClient() || this.auth.isVendor() || this.isVendorview) return;

    const quota = this.blankQuota();
    if (type === 'HouseholdIncome' || type === 'Age') {
      this.newQuota = quota;
    }
    quota.groupId = group.id;
    if (type =='HouseholdIncome') {
      var lastIndex = null;
      for (let i = group.quotas.filter(e => e.isDeleted == false).length-1; i >= 0; i--) {
        var maxHHI = group.quotas.filter(e => e.isDeleted == false)[i].targetGroup.maxHHI
        if (maxHHI != null) {
          lastIndex = i;
          break;
        }
      }

      if (lastIndex != null)
      {
        var lastMax = group.quotas.filter(e => e.isDeleted == false)[lastIndex].targetGroup.maxHHI
        if (lastMax < this.hhiMax -1)
        {
          quota.targetGroup.minHHI = lastMax + 1;
          quota.targetGroup.maxHHI = lastMax + 5000;
        }
        else if (lastMax == this.hhiMax - 1)
        {
          quota.targetGroup.minHHI = this.hhiMax;
          quota.targetGroup.maxHHI = this.hhiMax + 1;
        }
        else if (lastMax == this.hhiMax + 1)
        {
          quota.targetGroup.minHHI = null;
          quota.targetGroup.maxHHI = null;
        }
      }
      else
      {
        quota.targetGroup.minHHI = 0;
        quota.targetGroup.maxHHI = this.hhiMin-1;
      }
    }

    this.addVendorsToQuota(quota);
    group.quotas.push(quota);
  }

  handleLimitChange(quota, limitType, vendor, event) {
    // if (limitType === 'n' ? quota.editLimitN == quota.limitN : quota.editLimitPercent == quota.limitPercent) return;
    var val = event.target.value;

    if (val !== '*' && val !== '99999') {
      val = Number(val);
    }
    if (limitType === 'n' ? val == quota.limitN : val == quota.limitPercent) return;
    if (this.project.fullLaunchQuota === 0) {
      this.alertify.error("Cannot update quota limit as overall project completes allocation is 0")
      return 0; 
    }

    if (limitType === '%') {
      if ( val === '*' || val === '99999') {
        quota.limitPercent = 99999;
        quota.limitN = 99999;
        quota.limit = 99999;
      }
      else {
        val = Number(val)
        if (!isNaN(val)) {

          quota.limitPercent = val
          quota.limitN = Math.ceil(this.project.fullLaunchQuota * (quota.limitPercent / 100));
        }
      }
    }
    else {
      if ( val[0] === '*' || val === '99999') {
        quota.limitPercent = 99999;
        quota.limitN = 99999;
      }
      else {
        val = Number(val)
        if (!isNaN(val)) {
          quota.limitN = val;
          quota.limitPercent = Math.ceil(quota.limitN * 100 / this.project.fullLaunchQuota);
        }
      }
    }

    // Update all vendor values for this row
    quota.vendorLimits.forEach(v => {
      if (!vendor && val[0]!=='*' && val !== '99999') {
        if (v.limitPercent === '*' || v.limitPercent === '99999' || v.limitPercent >= 99999) {
          v.limitN = 99999
        }
        else if (v.limitN === '*' || v.limitN === '*' || v.limitN >= 99999) {
          v.limitPercent = 99999
        }
        else if (this.projectQuota.limitType == 'number') {
          v.limitPercent = Math.ceil(( v.limitN / quota.limitN) * 100);
        }
        else if (this.projectQuota.limitType == 'percent') {
          v.limitN = Math.ceil(quota.limitN * v.limitPercent / 100);
        }
      }
      else if (!vendor && (val[0]==='*' || val === '99999')) {
        v.limitN = 99999;
        v.limitPercent = 99999;
      }
    });

    if (this.newQuota?.id !== quota.id) {
      this.saveQuota(quota);
    }
    else {
      this.removeNewQuotaWithBlanksIfExists();
    }
  }

  minMaxChanged(quota, group, targetGroup) {
    this.saveQuota(quota);
  }

  vendorLimitChanged(quota, vendorQuota, limitType, event) {
    var value = Number(event.target.value);

    console.log(vendorQuota, limitType, value)
    if (quota.limitN === 0) {
      value = 0;
    }
    if (limitType === 'n') {
      vendorQuota.limitN = value;
      vendorQuota.limitPercent = quota.limitN > 0 && value > 0 ? Math.ceil((vendorQuota.limitN / quota.limitN) * 100) : 0;
    }
    else {
      vendorQuota.limitPercent = value;
      vendorQuota.limitN = Math.ceil(quota.limitN * (vendorQuota.limitPercent / 100));
    }
    if (vendorQuota.limitN !== vendorQuota.limit) { 
      vendorQuota.limit = vendorQuota.limitN;
      this.projectService.SaveSingleVendorQuota(this.project.id, quota.id, vendorQuota).subscribe(data => {
        this.alertify.success('Quota updated');
      }, error => {
        this.alertify.error('Unable to save quota');
      });
    }
  }

  handleDeletedQuotas(group) {
    // called whenever we add/remove/change quotas so we can recalculate everything
    if (group.isDeleted == false) {
      group.deletedOptions = [];
      const quotas = group.quotas;

      // add deleted quotas to deletedOptions
      quotas.forEach(q => {
        if (q.isDeleted && (group.name != 'Income' || q.targetGroup.minHHI == -1)) {
          var deletedQ: DropdownQuota = {type: "", name: "", id: ""};
          deletedQ.type = group.name.toLowerCase()
          deletedQ.id = q.id          
          deletedQ.name = q.targetGroup[group.name.toLowerCase()];
          group.deletedOptions.push(deletedQ)
        }
      });

      // add in quota options that exist in the question but not in the quotas
      if (group.name !== 'Age' && group.name !== 'Region') {
        this.questions[group.questionId].options.forEach(option => {
          var optionExistsInQuotas = null;
          if (group.name !== 'Income' || option.value === 'pnta') {

            if (group.name === 'Income') optionExistsInQuotas = quotas.find(q => q.targetGroup['minHHI'] == -1);
            else optionExistsInQuotas = quotas.find(q => q.targetGroup[group.name.toLowerCase()] === option.value);

            if (!optionExistsInQuotas) {
              var deletedQ: DropdownQuota = {type: group.name.toLowerCase(), name: option.value, id: null};
              deletedQ.type = group.name.toLowerCase()
              deletedQ.name = option.value
              group.deletedOptions.push(deletedQ)
            }
          }
        });
      }
    }
  }

  createQuotaGroup(type) {
    if (this.auth.isClient() || this.auth.isVendor() || this.isVendorview) return;
    //else if ((type == 'Division' && this.addedTypes['Region'] != null) || (type == 'Region' && this.addedTypes['Division'] != null)) return;

    const qs = [];

    var groupName = type != 'HouseholdIncome' ? type : 'Income';

    for (const q in this.questions) {
      if (this.questions[q].type === type) {
        qs.push(this.questions[q]);
      }
    }

    if (qs.length === 0) {
      this.alertify.error('This Category has no questions configured');
      return;
    }
    
    const question = qs[0];

    const qg: QuotaGroup = {
      id: uuidv4(),
      isDeleted: false,
      order: this.projectQuota.quotaGroups.length,
      name: groupName,
      questionId: question.id,
      quotas: [],
      completes: 0,
      starts: 0,
      deletedOptions: [],
    };

    if (type === 'HouseholdIncome' || type === 'Age' || type === 'Region' || type === 'Division') {

      if (type === 'HouseholdIncome' || type === 'Age') {
        if (type === 'HouseholdIncome' && question.preferNotToAnswer) {
          var hasPNTA = question.options.find(x => x.value === 'pnta');
          if (hasPNTA) {
            qg.preferNotToAnswerAdded = true;
          }
        }
        this.sort(question.defaults, 'min')
      }
      else this.sort(question.defaults, 'order')

      var numOptions = question.defaults.length;

      question.defaults.forEach(option => {
        const quota: Quota = this.blankQuota();

        if (type === 'HouseholdIncome') {
          quota.targetGroup.minHHI = option.min;
          quota.targetGroup.maxHHI = option.max;
        } else if (type === 'Age') {
          quota.targetGroup.minAge = option.min;
          quota.targetGroup.maxAge = option.max;
        } else if (type === 'Region') {
          quota.targetGroup.region = option.value;
        } else if (type === 'Division') {
          quota.targetGroup.division = option.value;
        }

        if (this.quotaConfigEditDetails.distributeBy === 'census') {
          quota.limitPercent = this.project.fullLaunchQuota == 0 ? 0 : option.percent;
          quota.limitN = this.project.fullLaunchQuota == 0 ? 0 : Math.ceil((this.project.fullLaunchQuota * (option.percent / 100)));
          quota.limit = ((this.projectQuota.limitType === 'number') ? quota.limitN : quota.limitPercent)
        }
        else if (this.quotaConfigEditDetails.distributeBy === 'equal') {
          // equally distribute between all options
          quota.limitPercent = 100 / numOptions;
          quota.limitN = Math.ceil((this.project.fullLaunchQuota * (quota.limitPercent / 100)));
          quota.limit = ((this.projectQuota.limitType === 'number') ? quota.limitN : quota.limitPercent)
        }
        else { // if 'custom'
          quota.limitPercent = 0;
          quota.limitN = 0;
          quota.limit = 0;
        }

        this.addVendorsToQuota(quota);
        qg.quotas.push(quota);
      });


    }  else {
      this.sort(question.options, 'order')

      var numOptions = question.options.length;
 
      question.options.forEach(option => {
        const quota: Quota = this.blankQuota()        
        quota.targetGroup[type.toLowerCase()] = option.value;

        const defaultItem = question.defaults.find(e => e.value === option.value);

        if (this.quotaConfigEditDetails.distributeBy === 'census' && defaultItem != null) {
          quota.limitPercent = this.project.fullLaunchQuota == 0 ? 0 : defaultItem.percent;
          quota.limitN = this.project.fullLaunchQuota == 0 ? 0 : Math.ceil((this.project.fullLaunchQuota * (defaultItem.percent / 100)));
          quota.limit = ((this.projectQuota.limitType === 'number') ? quota.limitN : quota.limitPercent)
        }
        else if (this.quotaConfigEditDetails.distributeBy === 'equal') {
          // equally distribute between all options
          quota.limitPercent = 100 / numOptions;
          quota.limitN = Math.ceil((this.project.fullLaunchQuota * (quota.limitPercent / 100)));
          quota.limit = ((this.projectQuota.limitType === 'number') ? quota.limitN : quota.limitPercent)
        }
        else { // if 'custom'
          quota.limitPercent = 0;
          quota.limitN = 0;
          quota.limit = 0;
        }

        if (this.projectQuota.enableVendorLimits) {
          this.addVendorsToQuota(quota);
        }
        qg.quotas.push(quota);
      });
    }


    if (type === 'HouseholdIncome' && qg.preferNotToAnswerAdded)  {
      const quota: Quota = this.blankQuota();
      quota.targetGroup.minHHI = -1;
      quota.limitPercent = 0;
      quota.limitN = 0;
      quota.limit = 0;
      this.addVendorsToQuota(quota);
      qg.quotas.push(quota);  
    }
    return qg;
    // this.newQuotaGroups = qg
    // this.saveQuota();
  }

  blankQuota() {
    const quota: Quota = {
      id: uuidv4(),
      isDeleted: false,
      limit: 0,
      starts: 0,
      completes: 0,
      limitPercent: 0,
      limitN: 0,
      order:999,
      targetGroup: {
        id: uuidv4(),
        gender: null,
        ethnicity: null,
        hispanic: null,
        minHHI: null,
        maxHHI: null,
        minAge: null,
        maxAge: null,
        region: null,
        division: null,
        states: '',
      },
      vendorLimits: []
    };

    return quota;
  }

  openRemoveQuotaGroupModal(group) {
    this.alertify.confirm('Remove Quota Group', 'Are you sure you want to remove this quota group?', () => {
      this.removeQuotaGroup(group.id);
    });
  }

  removeQuotaGroup(id) {
    var index = this.projectQuota.quotaGroups.findIndex(e => e.id === id);
    this.projectQuota.quotaGroups[index].isDeleted = true;
    this.saveQuota();
    this.sort(this.projectQuota.quotaGroups, 'order')
  }

  updateAddedQuotaGroupList() {
    // Update added quotas
    this.typesEmpty = true;
    const addedTypes: any = {};
    var count = 0;

    this.projectQuota.quotaGroups.forEach(e => {
      var questionIdArray = e.questionId.split(',');
      questionIdArray.forEach(qId => {
        if (this.questions[qId]) {
          addedTypes[this.questions[qId]?.type] = true;
          this.typesEmpty = false;
          count += 1
        }   
      });
     
    });

    if (count >= 6) this.typesFull = true;
    else this.typesFull = false;

    this.addedTypes = addedTypes;
  }

  removeQuota(group, id) {
    group.quotas.filter(e => e.id === id).forEach(q => {
      q.isDeleted = true
      this.saveQuota(JSON.parse(JSON.stringify((q))));
    });
  }


  enableQuota() {
    this.projectService.UpdateProjectValue(this.project.id, 'isQuotaEnabled', !this.quotaEnabled).subscribe( data => {
    },
    error => {
      this.alertify.error('Failed to enable or disable Quota');
    },
    () => {
      // this.quotaEnabled = !this.quotaEnabled;
      this.project.isQuotaEnabled = !this.quotaEnabled;
      this.quotaEnabled = !this.quotaEnabled;

      if (this.projectQuota == null && this.quotaEnabled) {
        // saving quota for the first time
        this.projectQuota = {
          id: uuidv4(),
          limitType: 'number',
          limitMode: 'complete',
          limitCheck: 'tracking',
          enableVendorLimits: false,
          distributeBy: 'census',
          distributionFlexibility: null,
          quotaGroups: []
        };

        this.saveQuota();
      }

      this.getQuota();
    });
  }

  changeVendorQuotaSetting(val) {
    this.projectQuota.enableVendorLimits = val;
    if (this.projectQuota.enableVendorLimits) {
      this.projectQuota.quotaGroups.forEach(qg => {
        qg.quotas.filter(e => e.isDeleted === false).forEach(q => {
          this.addVendorsToQuota(q);
          this.handleQuotaChange(q);
        });
      });
    }
    this.saveQuota();
  }

  addVendorsToQuota(quota) {
    const vendorLimits = [];
    var vendorAllocationTotal = this.vendors.filter(x => !x.isDeleted).reduce((sum, item) => sum + item.partnerFullQuota, 0);

    this.vendors.forEach(vendor => {
      // if (!vendor.partnerFullQuota || !this.project.fullLaunchQuota) {
      //   vendor.totalFullQuotaPercent = 0;
      // }
      // else {
      //   vendor.totalFullQuotaPercent = this.project.fullLaunchQuota > 0 && vendor.partnerFullQuota > 0 ? Math.ceil(vendor.partnerFullQuota * 100 / this.project.fullLaunchQuota) : 0;
      // }
      
      // For every vendor on this project we add the vendor limits if specified
      const existing = quota.vendorLimits.find(v => v.vendorId === vendor.partnerId);
      
      if (existing) {
        existing.limitN = (quota.limit >= 99999 ? 99999 : (this.projectQuota.limitType === 'number') ? existing.limit : Math.ceil(quota.limitN * existing.limit / 100));
        existing.limitPercent = (quota.limit >= 99999 ? 99999 : (quota.limit === 0) ? 0 : (this.projectQuota.limitType === 'percent') ? existing.limit : Math.ceil(existing.limit * 100 / quota.limitN));
        vendorLimits.push(existing);

      } else {
        var vendorRatio;
        if (this.vendors.length > 1) {
          if (!vendor.partnerFullQuota) vendorRatio = 1/this.vendors.length
          else vendorRatio = vendor.partnerFullQuota/vendorAllocationTotal
        }
        else vendorRatio = 1; // if one vendor only, give it full allocation

        const qv: QuotaVendorLimit = {
          id: uuidv4(),
          vendorId: vendor.partnerId,
          vendorName: vendor.partnerName,
          limitN: quota.limit ? (quota.limit >= 99999 ? 99999 : Math.ceil(quota.limitN * vendorRatio) ) : 0,
          limitPercent: quota.limit ? (quota.limit >= 99999 ? 99999 : vendorRatio*100) : 0,
          limit: 0,
          completes: 0,
          starts: 0
        };
        
        qv.limit = ((this.projectQuota.limitType === 'number') ? qv.limitN : qv.limitPercent);
        vendorLimits.push(qv);
      }
    });

    quota.vendorLimits = vendorLimits;
  }


  saveQuota(inlineSaveQuota = null) {
    if (this.newQuotaGroups.length > 0) {
     this.projectQuota.quotaGroups.concat(this.newQuotaGroups);
     this.updateAddedQuotaGroupList();
    }

    //we do not save the dummy project we use for demo
    if(this.project.id == "d583bf3a-ab7e-441f-b1a1-07c96bb3e2e6")
      return;

    if (!inlineSaveQuota) { // saved through quota config modal

      var projectQuota = JSON.parse(JSON.stringify(this.projectQuota));
      projectQuota.quotaGroups = projectQuota.quotaGroups.concat(this.newQuotaGroups);

      var x = 0;

      projectQuota.quotaGroups.forEach(qg => {
        qg.order = x;
        x++;

      qg.quotas.forEach(q => {
        if (q.id === this.newQuota?.id) {
          this.newQuota = null;
        }
        if (projectQuota.limitCheck != 'tracking only') {
          q.limit = (this.projectQuota.limitType === 'number') ? q.limitN || 0: q.limitPercent || 0;
          q.vendorLimits.forEach(qv => {
            if (q.limit < 99999) qv.limit = (projectQuota.limitType === 'number') ? qv.limitN : qv.limitPercent;
            else qv.limit = 99999
          });
        }
        q.targetGroup.minAge = (q.targetGroup.minAge == null) ? null : Number(q.targetGroup.minAge);
        q.targetGroup.maxAge = (q.targetGroup.minAge == null) ? null : Number(q.targetGroup.maxAge);
        q.targetGroup.minHHI = (q.targetGroup.minHHI == null) ? null : Number(q.targetGroup.minHHI);
        q.targetGroup.maxHHI = (q.targetGroup.maxHHI == null) ? null : Number(q.targetGroup.maxHHI);
      });
    });
      if (this.quotaConfigEditDetails) {
        projectQuota.limitCheck = this.quotaConfigEditDetails.limitCheck;
        projectQuota.limitMode = this.quotaConfigEditDetails.limitMode;
        projectQuota.enableVendorLimits = this.quotaConfigEditDetails.enableVendorLimits;
        projectQuota.distributeBy = this.quotaConfigEditDetails.distributeBy;
        projectQuota.distributionFlexibility = this.quotaConfigEditDetails.distributionFlexibility;
      }
      this.projectService.SaveQuota(this.project.id, projectQuota).subscribe(data => {
      }, error => {
        this.alertify.error('Failed to add Quota');
        }, () => {
          if (this.newQuotaGroups.length > 0 ) this.alertify.success('Quota added');
          else this.alertify.success('Quota updated');

          this.projectQuota.quotaGroups = projectQuota.quotaGroups;
          this.justSaved = true;
          this.newQuotaGroups = [];
          if (this.openModalRefQuotaConfig) this.openModalRefQuotaConfig.hide()
          this.getQuota();
      });

      this.updateAddedQuotaGroupList();
    }
    // if inline save
    else {
      var q = inlineSaveQuota;

      if (q.id === this.newQuota?.id) {
        this.newQuota = null;
      }
      if (this.projectQuota.limitCheck != 'tracking only') {
        q.limit = (this.projectQuota.limitType === 'number') ? q.limitN || 0: q.limitPercent || 0;
        q.vendorLimits.forEach(qv => {
          if (q.limit < 99999) qv.limit = (this.projectQuota.limitType === 'number') ? qv.limitN : qv.limitPercent;
          else qv.limit = 99999
        });
      }
      q.targetGroup.minAge = (q.targetGroup.minAge == null) ? null : Number(q.targetGroup.minAge);
      q.targetGroup.maxAge = (q.targetGroup.maxAge == null) ? null : Number(q.targetGroup.maxAge);
      q.targetGroup.minHHI = (q.targetGroup.minHHI == null) ? null : Number(q.targetGroup.minHHI);
      q.targetGroup.maxHHI = (q.targetGroup.maxHHI == null) ? null : Number(q.targetGroup.maxHHI);
      this.projectService.SaveSingleQuota(this.project.id, inlineSaveQuota).subscribe(data => {
        if (data) {
          this.alertify.success('Quota updated');
          this.handleQuotaChange(q);
          this.handleDeletedQuotas(this.projectQuota.quotaGroups.find(e => e.id === q.groupId));
        }
        else {
          this.alertify.error('Failed to update Quota');
          this.getQuota();
        }
      }, (error)=> {
        this.alertify.error('Failed to update Quota');
        this.getQuota();
      });
    }
  }

  toggleLimitType(trigger : string = "") {
    if (trigger == "limitCheckChanged" ) {
      if (this.projectQuota.limitCheck == 'tracking only') {
        this.projectQuota.limitType = 'percent';
      }
    }
    else if (trigger == 'percent' && this.projectQuota.limitType === 'number'){
      this.projectQuota.limitType =  'percent'
    }
    else if (trigger == 'number' && this.projectQuota.limitType === 'percent'){
      this.projectQuota.limitType =  'number'
    }

    if (!this.auth.isClient() && !this.auth.isVendor() && !this.isVendorview) {
      this.saveQuota();
    }
  }

  // change which vendors are shown in carousel
  changeVendorIndex(toAdd) {

    this.swapColor = !this.swapColor;
    this.vendorIndexLeft += toAdd;
    this.vendorIndexRight += toAdd;

      if (this.vendorIndexRight >= this.vendors.length) {
        this.vendorIndexRight = 0;
      }
      if (this.vendorIndexLeft >= this.vendors.length) {
        this.vendorIndexLeft = 0;
      }

      if (this.vendorIndexLeft < 0) {
        this.vendorIndexLeft = this.vendors.length - 1
      }
      if (this.vendorIndexRight < 0) {
        this.vendorIndexRight = this.vendors.length - 1
      }

      if (toAdd < 0) {
        this.leftVendorComing = true;
      }
      else {
        this.rightVendorComing = true;
      }

      this.projectQuota = JSON.parse(JSON.stringify(this.projectQuota))
  }


  handleAction(event) {
    if (event.type == "import") {
      this.closeModal();
      this.updateProject(event.row)
    }
  }

  updateProject(value) {
    this.quotaTemplateId = value.value
    this.cloneQuota();
  }

  // get project list to import a quota
  getProjectList = (filter) => {
    this.quotaTemplates = [
      { label: 'ID', id: 'code', sortable: false }, { label: 'Name', id: 'name', sortable: false }, { label: 'Client', id: 'client', sortable: true }, { label: 'End Date', id: 'closeDate', sortable: true }, {label: 'Import', type: 'actions-inline'}
    ];
    this.actions = [ {label: 'Import', type: 'import', icon: 'file-import'}];
    // this.dropdownService.projects(filter, '', 'survey');
    var list = this.dropdownService.getQuotaTemplates(filter, this.project.id, 'survey')
    list.forEach(element => {
      this.projectList = element
    });
    if (filter == '' || filter == null) {
      this.projectList = [];
    }
    return list
  }

  cloneQuota() {

    this.projectService.CloneQuotaFromProject(this.project.id, this.quotaTemplateId).subscribe((data) => {
      this.alertify.success('Quota updated');
      this.getQuota();
    }, error => {
      this.alertify.error('There was an error cloning quota');
    });
  }

  openModal(modal, type) {

    if (this.auth.isClient() || this.auth.isVendor() || this.isVendorview) return;

    if (type == "onClose") {
      if (this.justSaved) {
        this.closeQuota();
      }
      else {
        this.modalType = type;
        this.openModalRef = this.modalService.show(modal, {keyboard: false, ignoreBackdropClick: true, class: 'nav-modal-style'});
      }
    }
    else if (type == 'errorSaving') {
      this.modalType = type;
      this.openModalRef = this.modalService.show(modal, {keyboard: false, ignoreBackdropClick: true, class: 'nav-modal-style'});
    }
    else if (type == 'templateClone' && this.project.fullLaunchQuota > 0) {
      this.modalType = type;
      this.openModalRef = this.modalService.show(modal, {keyboard: false, ignoreBackdropClick: true, class:'modal-lg nav-modal-style' });
    }
    else if (type == 'templateImport' && this.project.fullLaunchQuota > 0) {
      this.modalType = type;
      this.openModalRef = this.modalService.show(modal, {keyboard: false, ignoreBackdropClick: true, class:'modal-lg nav-modal-style' });
    }
  }

  closeModal(type: string = "") {
    if (type == "onClose") {
      this.closeQuota();
    }
    else {
      this.projectList = [];
    }
    this.openModalRef.hide()
  }

  sort(array, sortBy) {
    var A;
    var B
    array.sort((a, b) => {

      if (sortBy === 'order')
      {
        A = a.order;
        B = b.order;
      }
      else if (sortBy === 'min')
      {
        A = a.min
        B = b.min
      }
      else if (sortBy === 'minAge')
      {
        A = a.targetGroup.minAge
        B = b.targetGroup.minAge
      }
      else if (sortBy === 'minHHI')
      {
        A = a.targetGroup.minHHI
        B = b.targetGroup.minHHI
        if (A == -1) A =999999999999
        if (B == -1) B =999999999999
      }

      let comparison = 0;
      if (A > B) {
        comparison = 1;
      } else if (A < B) {
        comparison = -1;
      }
      return comparison;
    });
  }

  getBgColor(value): string {
    if ( this.daysPercentagePast > value) {
      return 'nav-bg-error';
    }
    else {
      return 'nav-success';
    }
  }

  navigateToLinksAndVendors() {
    this.redirectToSection.emit('links-and-vendors');
  }

  importFileUploaded(data) {
    this.importFileName = data?.origFile?.name;
  }

  importQuotaFromFile() {
    if (this.importFileName) {
      this.loadingImport = true;
      this.projectService.ImportQuotaFromFile(this.project.id, this.importFileName).subscribe((data) => {
        this.loadingImport = false;
        this.importFileName = null;
        this.openModalRef.hide();
        this.alertify.success("Quota succefully imported from file")
        this.getQuota();
      }, error => {
        this.alertify.error("Unable to import quota from file - " + error);
        this.loadingImport = false;
      }) 
    }
  }

  downloadQuotaTemplate() {
    if (this.auth.isClient() || this.auth.isVendor() || this.isVendorview) return;

    let csvContent = 'Quota Label,Attribute,Allocation\n';

    let data = [
      { label: 'Age', attribute: '18-24', allocation: 100 },
      { label: '\n', attribute: '25-34', allocation: 100 },
      { label: '\n', attribute: '35-44', allocation: 100 },
      { label: '\n', attribute: '45-54', allocation: 100 },
      { label: '\n', attribute: '55-64', allocation: 100 },
      { label: '\n', attribute: '65+', allocation: 100 },
      { label: 'Gender', attribute: 'Male', allocation: 300 },
      { label: '\n', attribute: 'Female', allocation: 300 },
      { label: 'Region', attribute: 'Northeast', allocation: 150 },
      { label: '\n', attribute: 'Midwest', allocation: 150 },
      { label: '\n', attribute: 'South', allocation: 150 },
      { label: '\n', attribute: 'West', allocation: 150 },
      { label: 'Income', attribute: '0-14999', allocation: 125 },
      { label: '\n', attribute: '25000-49999', allocation: 125 },
      { label: '\n', attribute: '50000-99999', allocation: 125 },
      { label: '\n', attribute: '100000-149999', allocation: 125 },
      { label: '\n', attribute: '150000+', allocation: 125 },
      { label: 'Ethnicity', attribute: 'White/Caucasian', allocation: 125 },
      { label: '\n', attribute: 'Black or African American', allocation: 125 },
      { label: '\n', attribute: 'Asian or Pacific Islander', allocation: 125 },
      { label: '\n', attribute: 'Native American Indian or Alaska Native', allocation: 125 },
      { label: '\n', attribute: 'Other/Prefer to Self-Describe', allocation: 125 },
      { label: 'Hispanic', attribute: 'Yes, Hispanic', allocation: 300 },
      { label: '\n', attribute: 'No, Not Hispanic', allocation: 300 },
    ];
    
    data.forEach((row) => {
      csvContent += `"${row.label}","${row.attribute}","${row.allocation}"\n`;
    });

    this.utils.generateXLSX(csvContent, 'quota-template.csv');



  }

  downloadQuota() {
    if (this.auth.isClient() || this.auth.isVendor() || this.isVendorview) return;

    let csvContent = '';
   
    // Colum headers
    csvContent = 'Quota Label,Attribute,Allocation\n'

    this.projectQuota?.quotaGroups.forEach( (qg: QuotaGroup) => {
      csvContent += qg.name + ',';

      var i = 0;
      qg.quotas.filter(x => !x.isDeleted).forEach((quota: Quota) => {
        const targetGroup = quota.targetGroup;
        
        if (qg.name != 'Income' && qg.name != 'Age') {
          var value = targetGroup[qg.name.toLowerCase()];
          if (value != 'pnta') {
            if (i > 0) csvContent += ',' // skip quota label if not first row
            csvContent += `"${targetGroup[qg.name.toLowerCase()]}"`
            csvContent += ', ' + quota.limitN + '\n'
          }
        }
        else {
          if (qg.name == 'Income') {
            if ((targetGroup.minHHI || targetGroup.maxHHI) && targetGroup.minHHI != -1 ) {
              if (i > 0) csvContent += ',' // skip quota label if not first row
              csvContent += !targetGroup.minHHI ? 0 : targetGroup.minHHI
              if (targetGroup.maxHHI.toString()) {
                csvContent += targetGroup.maxHHI > 150000 ? '+' : '-' + targetGroup.maxHHI;
              }
              csvContent += ', ' + quota.limitN + '\n'
            }
          }
          else if (targetGroup.minAge.toString()) {
            if (i > 0) csvContent += ',' // skip quota label if not first row
            csvContent += (targetGroup.minAge + (targetGroup.maxAge.toString() ? '-' + targetGroup.maxAge : ''));
            csvContent += ', ' + quota.limitN + '\n'
          }
        }
        i++;
      })
      
    });

    this.utils.generateXLSX(csvContent, this.project.projectCode+'-quota-allocation.csv');
  }

  removeNewQuotaWithBlanksIfExists() {
    var quota = null;
    var group = null;
    // find quota thats new in all quota groups
    this.projectQuota.quotaGroups.forEach(qg => {
      if (qg.quotas) {
        quota = qg.quotas.find(q => q.id === this.newQuota.id);
        if (quota) {
          group = qg;
          var blankQuota = false;
          if (group.name === 'Income' || group.name === 'Age') {
            if (group.name === 'Income') {
              if (!quota.targetGroup.minHHI && !quota.targetGroup.maxHHI && (!quota.limitN || quota.limitN == 0) && (!quota.limitPercent || quota.limitPercent == 0)) {
                blankQuota = true;
              }
            }
            else if (group.name === 'Age') {
              if (!quota.targetGroup.minAge && !quota.targetGroup.maxAge && (!quota.limitN || quota.limitN == 0) && (!quota.limitPercent || quota.limitPercent == 0)) {
                blankQuota = true;
              }
            }
            if (blankQuota && quota.id === this.newQuota.id) {
              group.quotas = group.quotas.filter(e => e.id != quota.id);
            }
    
            if (!blankQuota) this.saveQuota(quota);
            else {
              group.quotas = group.quotas.filter(e => e.id != quota.id);
              this.newQuota = false;
            }
          }
        }
      }
    });
  }

  openModalQuotaConfig(mode) {
    this.quotaConfigEditDetails = {
      mode: mode,
      step: 1,
      quotasNotAdded: [],
      quotasAdded: [],
      selectAllQuotas: false,
      atLeastOneQuotaSelected: false,
      atLeastTwoQuotasSelected: false,
      atLeastThreeSelected: false,
      limitMode: this.projectQuota.limitMode,
      limitCheck: (this.projectQuota.limitCheck == 'tracking only' ? 'tracking' : this.projectQuota.limitCheck) ?? 'tracking',
      distributeBy: this.projectQuota.distributeBy,
      applyFlexibility: this.projectQuota.distributionFlexibility != null,
      distributionFlexibility: this.projectQuota.distributionFlexibility,
      enableVendorLimits: this.projectQuota.enableVendorLimits,
    }

    if (mode !== 'edit') {
      this.questionIDs.forEach(id => {
        if (id != null) {
          if (!this.addedTypes[this.questions[id].type]) {
            this.quotaConfigEditDetails.quotasNotAdded.push(
              {questionId: id, value: this.questions[id].type, selected: false}
            )
          }
          else  {
            var existingGroup = this.projectQuota.quotaGroups.find(x => x.questionId.includes(id));
            if (existingGroup.questionId.length <= 36) {
              this.quotaConfigEditDetails.quotasAdded.push(
                {groupId: existingGroup.id, value: this.questions[id].type, selected: false}
              )
            }
          }
        }
      });
    }

    this.openModalRefQuotaConfig = this.modalService.show(this.quotaConfig, {keyboard: false, ignoreBackdropClick: true, class: 'nav-modal-style modal-quotas-config'});
  }

  selectAllQuotasInConfig(event) {
    var val = event.target.checked;
    this.quotaConfigEditDetails.quotasNotAdded.forEach(quotaType => {
      quotaType.selected = val;
    });
    if (val == true) this.quotaConfigEditDetails.atLeastOneQuotaSelected = true;
    else (this.quotaConfigEditDetails.atLeastOneQuotaSelected = false);
  }

  quotaSelected() {
    var numSelected = 0;
    this.quotaConfigEditDetails.quotasNotAdded.forEach(quotaType => {
      if (quotaType.selected) numSelected++;
    });
    this.quotaConfigEditDetails.atLeastOneQuotaSelected = numSelected > 0;
    this.quotaConfigEditDetails.selectAllQuotas = numSelected == this.quotaConfigEditDetails.quotasNotAdded.length;
  }

  quotaSelectedForNest() {
    var numSelected = 0;
    this.quotaConfigEditDetails.quotasAdded.forEach(quotaType => {
      if (quotaType.selected) numSelected++;
    });
    this.quotaConfigEditDetails.atLeastTwoQuotasSelected = numSelected > 1;
    this.quotaConfigEditDetails.atLeastThreeSelected = numSelected > 2;
  }
  
  nextInQuotaConfigModal() {
    var newQuotaGroups = [];
    if (this.quotaConfigEditDetails.mode === 'add') {
      if (this.quotaConfigEditDetails.step == 1) {
        this.quotaConfigEditDetails.step = 2;
      }
      else if (this.quotaConfigEditDetails.step == 2) {
        this.quotaConfigEditDetails.quotasNotAdded.filter(x => x.selected).forEach(newGroup => {
          var type = this.questions[newGroup.questionId].type;
          var qg = this.createQuotaGroup(type);
          newQuotaGroups.push(qg);
        });

        this.newQuotaGroups = newQuotaGroups;
        this.saveQuota();
      }
    }
    else if (this.quotaConfigEditDetails.mode === 'edit') {
      this.newQuotaGroups = newQuotaGroups;
      this.saveQuota();
    }
    else if (this.quotaConfigEditDetails.mode === 'nest') {
      var quotasToNest = this.quotaConfigEditDetails.quotasAdded.filter(x => x.selected)?.map(x => x.groupId);
      if (quotasToNest.length > 1) {
        this.nestQuotas(quotasToNest);
      };
    }
  }

  nestQuotas(quotaIds: Array<string>) {
    this.projectService.NestQuota(this.project.id, quotaIds).subscribe(data => {
      this.alertify.success('Quota nested');
      if (this.openModalRefQuotaConfig) this.openModalRefQuotaConfig.hide()
      this.getQuota();
    }, error => {
      this.alertify.error('Failed to nest quotas');
    });
  }

  undoNest(group: QuotaGroup) {
    this.projectService.UndoNest(this.project.id, group.id).subscribe(data => {
      this.alertify.success('Quota unested');
      this.getQuota();
    }, error => {
      this.alertify.error('Failed to nest quotas');
    });
  }

  // check if vendor card has overflow
  checkVendorCardOverflow() {
    var el = document.querySelector('.vendor-quota-container');
    if (el) {
      if (el.scrollWidth > el.clientWidth) {
        this.vendorCardOverflowX = true;
        
      }
      else {
        this.vendorCardOverflowX = false;
      }

      if (el.scrollHeight > el.clientHeight) {
        this.vendorCardOverflowY = true;
      }
      else {
        this.vendorCardOverflowY = false;
      }
      this.changeDetectorRef.detectChanges();

    }
  }

  onDrop(event: DndDropEvent) {
    var oldIndex = this.quotaConfigEditDetails.quotasAdded.findIndex(item => item.groupId.toLowerCase() === event.data.groupId.toLowerCase());
    let movedTask;

    if (this.quotaConfigEditDetails.quotasAdded && event.dropEffect === 'move') {
      let index = event.index;

      if (typeof index === 'undefined') {
        index = this.quotaConfigEditDetails.quotasAdded.length;
      }

      [movedTask] = this.quotaConfigEditDetails.quotasAdded.splice(oldIndex, 1);
      // Add to new list at the specified index
      this.quotaConfigEditDetails.quotasAdded.splice(index, 0, movedTask);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkVendorCardOverflow()
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {

    const clickedElement = event.target as HTMLElement;
    if (clickedElement.classList.contains('clickable')) {
      return;
    }
    else {
      if (this.projectQuota?.quotaGroups && this.newQuota) {
        // check if there is a quota in a quota group that has new Quota flag to true
        this.removeNewQuotaWithBlanksIfExists();
      }
    }
  }
}
