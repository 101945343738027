<div class="container-fluid">
  <ng-container *ngIf="closureType === 'close' && project.clientId == null">
    <div class="alert alert-warning mt-1" role="alert">
      There is no client on this project so it is not possible to initiate the closure process. Please go to settings to
      add a client.
    </div>
  </ng-container>
  <ng-container *ngIf="closureDetails != null">
    <div *ngIf="showStep <= 1">
      <div class="closure-steps mb-3">
        <div class="card">
          <div class="card-header" [ngClass]="getStepStyle(1)">
            <div class="row align-items-center">
              <div class="col-12">
                <i *ngIf="closureDetails?.step1CompletedBy != null"
                  class="fas fa-check-circle fa-lg mr-3 text-white opacity-90 completed-mark"></i>
                <span class="h5 text-white">Upload Final Completes</span>
              </div>
            </div>
          </div>
          <div class="card-body {{ loading == true ? 'd-flex justify-content-center align-items-center' : '' }}">
            <div class="row">
              <div class="col" *ngIf="loading == true">
                <span class="h6 or-secondary">
                  <div class="loading"></div> Please wait, the file is being loaded...
                </span>
              </div>
              <ng-container *ngIf="loading == false">
                <div class="col" *ngIf="project.projectStatus == 'Closed' &&
                    (project.metrics == null || project.metrics.complete == 0) &&
                    (closureDetails.step1CompletedBy == null || closureDetails.step2CompletedBy == null)">
                  <div class="alert alert-warning mt-1" role="alert">
                    There are no completes on this project.
                    <br /><br />
                    <b>Upload your completes</b>
                    <br />
                    <b>or</b>
                    <br />
                    <button (click)="closeOutNow()" type="button" class="btn btn-sm btn-secondary">Close Out
                      Now</button>
                  </div>
                </div>
                <div class="col" *ngIf="project.projectStatus != 'Closed' && project.projectStatus != 'Invoiced'">
                  <div class="alert alert-warning mt-1" role="alert">
                    <div>This project is not closed. To start this process you must first update the status.</div>
                    <div class="btn btn-secondary mt-3" (click)="closeProject()"> Close Project Now</div>
                  </div>
                </div>
                <div class="col" *ngIf="project.projectStatus == 'Closed' || project.projectStatus == 'Invoiced'">
                  <ng-container *ngIf="!loading && !fileUploaded">
                    <or-upload id="uploadedFile" smallMsg="excel files only"
                      downloadLink="/assets/ClientCompletesTemplate.xlsx" showFileName=false
                      (uploadCompleted)="clientUpload($event)" publicBucket=false [includeProgressBar]=true
                      acceptTypes=".xlsx" [instanceId]="auth.getUser().instanceId" S3Folder="closure"
                      directToS3=true></or-upload>
                  </ng-container>
                  <ng-container *ngIf="!loading && fileUploaded">
                    <div class="upload-container" *ngIf="project.projectStatus != 'Invoiced'">
                      <div class="border-container">
                        <div>
                          <button (click)="resetClosure()" type="button" id="reset"
                            class="btn btn-sm btn-secondary d-inline-block">Reset Closure</button>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="project.projectStatus == 'Invoiced'">This project is already Invoiced.</div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" [ngClass]="getStepStyle(2)">
            <div class="row align-items-center">
              <div class="col-10">
                <i *ngIf="closureDetails.step2CompletedBy != null"
                  class="fas fa-check-circle fa-lg mr-3 text-white opacity-90 completed-mark"></i>
                <span class="h5 text-white">Reconcile IDs</span>
              </div>
              <div class="col-2 text-right">
                <i class="fas fa-cog fa-lg cursor-pointer opacity-90 text-white" (click)="openStep(2)"></i>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <table class="table mb-0">
                  <thead class="thead-light">
                    <tr class="table-secondary">
                      <td>Client File</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="table-light" *ngFor="let row of closureDetails.recSummary">
                      <td *ngIf="row.type === 'Client' && row.status != 'Total'">{{ row.status }} completes: {{
                        row.total }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col">
                <table class="table mb-0">
                  <thead class="thead-light">
                    <tr class="table-secondary">
                      <td>Nav Completes</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="table-light" *ngFor="let row of closureDetails.recSummary">
                      <td *ngIf="row.type === 'Nav' && row.status != 'Total'">{{ row.status }} updates: {{ row.total }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <table class="table mb-0">
                  <tbody>
                    <tr class="table-primary" *ngFor="let row of closureDetails.recSummary">
                      <td *ngIf="row.type === 'Client' && row.status === 'Total'">{{ row.status }} client completes: {{
                        row.total }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col">
                <table class="table mb-0">
                  <tbody>
                    <tr class="table-primary" *ngFor="let row of closureDetails.recSummary">
                      <td *ngIf="row.type === 'Nav' && row.status === 'Total'">{{ row.status }} nav updates: {{
                        row.total }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="closure-steps mb-3">
        <div class="card" *ngIf="closureType === 'close'">
          <div class="card-header" [ngClass]="getStepStyle(3)">
            <div class="row align-items-center">
              <div class="col-10">
                <i *ngIf="closureDetails.step3CompletedBy != null"
                  class="fas fa-check-circle fa-lg mr-3 text-white opacity-90 completed-mark"></i>
                <span class="h5 text-white">Client Pricing</span>
              </div>
              <div class="col-2 text-right">
                <i class="fas fa-cog fa-lg cursor-pointer opacity-90 text-white" (click)="openStep(3)"></i>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-4">
              <div class="col-6 pr-1">
                <div class="d-inline-flex shadow-sm w-100 p-2">
                  <div class="text-center w-100">
                    <span class="mb-2">Fee</span>
                    <h4 class="total-num">{{ clientPricing.fee | currency }}</h4>
                  </div>
                  <i class="fas fa-coins fa-2x align-self-center or-title opacity-70 pr-2"></i>
                </div>
              </div>
              <div class="col-6 pl-1">
                <div class="d-inline-flex shadow-sm w-100 p-2">
                  <div class="text-center w-100">
                    <span class="mb-2">Expenses</span>
                    <h4 class="total-num">{{ clientPricing.expense | currency }}</h4>
                  </div>
                  <i class="fas fa-receipt fa-2x align-self-center or-title opacity-70 pr-2"></i>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="d-inline-flex shadow-sm w-100 p-2">
                  <div class="text-center w-100">
                    <span class="mb-2">Total</span>
                    <h2 class="total-num">{{ clientPricing.total | currency }}</h2>
                  </div>
                  <i class="fas fa-dollar-sign fa-3x align-self-center or-title opacity-70 pr-3"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div #step4VendorPricing class="card-header" [ngClass]="getStepStyle(4)">
            <div class="row align-items-center">
              <div class="col-8">
                <i *ngIf="closureDetails.Step4CompletedBy != null"
                  class="fas fa-check-circle fa-lg mr-3 text-white opacity-90 completed-mark"></i>
                <span class="h5 text-white">Vendor Pricing</span>
              </div>
              <div class="col-4 text-right">
                <span class="p-1 pr-2" data-html="true" tooltip="Download vendor ID files"
                  (click)="downloadPartnerCompletes()">
                  <i class="cursor-pointer fa-download fa-lg fas opacity-90 text-white"></i>
                </span>
                <span class="p-1" data-html="true" tooltip="Select Vendors to send IDs and costs to"
                  (click)="openModalVendor()" *ngIf="closureDetails.step2CompletedBy != null">
                  <i class="cursor-pointer fa-envelope fa-lg fas opacity-90 text-white"></i>
                </span>
                <span class="p-1" data-html="true" (click)="openStep(4)">
                  <i class="cursor-pointer fa-cog fa-lg fas opacity-90 text-white"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="card-body scrollbar overflow-y" [ngClass]="{'pt-0 px-0': closureDetails?.vendors?.length > 0}">
            <div class="col mb-1 px-0" *ngIf="closureDetails?.vendors?.length > 0">
              <div class="vendor-pricing-grid p-2 or-primary or-gray-bg mb-3">
                <div></div>
                <div class="text-left small or-title">
                  Vendor
                </div>
                <div class="small or-title">
                  Latest Update
                </div>
                <div class="small or-title pr-3">
                  Status
                </div>
              </div>
              <div class="vendor-pricing-grid p-2 pr-3 w-100"
                *ngFor="let vendor of checkVendorIsDeleted(closureDetails.vendors); let i = index">
                <span *ngIf="vendor.externalPortalUrl === null"></span>
                <a class="or-title mr-2" *ngIf="vendor.externalPortalUrl !== null" placement="bottom"
                  tooltip="Open Vendor Portal" [href]="vendor.externalPortalUrl" target="_blank">
                  <i class="fas fa-link cursor-pointer"></i>
                </a>
                <small class="or-title text-left">{{vendor.vendorName}}</small>
                <small class="or-title small">{{vendor.latestDecisionUpdated | date}}</small>
                <small class="or-title text-right">
                  <div role="alert" class="mb-0 d-flex justify-content-end"
                    *ngIf="!vendor.isAccepted && vendor.latestDecisionStatus == 'Confirmed'">
                    <select #venderStatus (change)="resetVendor(vendor, i,venderStatus)"
                      class="form-control w-auto pr-4 form-control-sm form-control-nav" [ngClass]="{
                      'alert-info': vendor.latestDecisionStatus == 'Pending',
                      'alert-reset': vendor.latestDecisionStatus == 'Reset',
                      'alert-secondary': vendor.latestDecisionStatus == 'NA',
                      'alert-success': vendor.latestDecisionStatus == 'Accepted' || vendor.latestDecisionStatus == 'Auto-Accepted',
                      'alert-danger': vendor.latestDecisionStatus == 'Disputed',
                      'alert-confirmed': vendor.latestDecisionStatus == 'Confirmed',
                      'alert-warning': vendor.latestDecisionStatus == null
                      }">
                      <option selected *ngIf="vendor.latestDecisionStatus == 'Reset'" value="Reset">Reset</option>
                      <option value="Pending">Resend Email</option>
                    </select>
                  </div>
                  <div role="alert" class="mb-0 d-flex justify-content-end"
                    *ngIf="!vendor.isAccepted && vendor.latestDecisionStatus == 'Disputed'">
                    <select #venderStatus (change)="resetVendor(vendor, i,venderStatus)"
                      class="form-control w-auto pr-4 form-control-sm form-control-nav" [ngClass]="{
                      'alert-info': vendor.latestDecisionStatus == 'Pending',
                      'alert-reset': vendor.latestDecisionStatus == 'Reset',
                      'alert-secondary': vendor.latestDecisionStatus == 'NA',
                      'alert-success': vendor.latestDecisionStatus == 'Accepted' || vendor.latestDecisionStatus == 'Auto-Accepted',
                      'alert-danger': vendor.latestDecisionStatus == 'Disputed',
                      'alert-confirmed': vendor.latestDecisionStatus == 'Confirmed',
                      'alert-warning': vendor.latestDecisionStatus == null
                      }">
                      <option selected *ngIf="vendor.latestDecisionStatus == 'Disputed'" value="Disputed">Disputed
                      </option>
                      <option value="Reset">Reset</option>
                    </select>
                  </div>
                  <div role="alert" class="mb-0 d-flex justify-content-end"
                    *ngIf="!vendor.isAccepted && vendor.latestDecisionStatus == 'Pending'">
                    <select #venderStatus (change)="confirmVendor(vendor, i,venderStatus)"
                      class="form-control w-auto pr-4 form-control-sm form-control-nav" [ngClass]="{
                      'alert-info': vendor.latestDecisionStatus == 'Pending',
                      'alert-reset': vendor.latestDecisionStatus == 'Reset',
                      'alert-secondary': vendor.latestDecisionStatus == 'NA',
                      'alert-success': vendor.latestDecisionStatus == 'Accepted' || vendor.latestDecisionStatus == 'Auto-Accepted',
                      'alert-danger': vendor.latestDecisionStatus == 'Disputed',
                      'alert-confirmed': vendor.latestDecisionStatus == 'Confirmed',
                      'alert-warning': vendor.latestDecisionStatus == null
                      }">
                      <option selected *ngIf="vendor.latestDecisionStatus == 'Pending'" value="Pending">Pending</option>
                      <option value="Confirmed">Confirmed</option>
                    </select>
                  </div>
                  <div role="alert" class="mb-0 d-flex justify-content-end"
                    *ngIf="!vendor.isAccepted && vendor.latestDecisionStatus == 'Reset'">
                    <select #venderStatus (change)="resetVendor(vendor, i,venderStatus)"
                      class="form-control w-auto pr-4 form-control-sm form-control-nav" [ngClass]="{
                      'alert-info': vendor.latestDecisionStatus == 'Pending',
                      'alert-reset': vendor.latestDecisionStatus == 'Reset',
                      'alert-secondary': vendor.latestDecisionStatus == 'NA',
                      'alert-success': vendor.latestDecisionStatus == 'Accepted' || vendor.latestDecisionStatus == 'Auto-Accepted',
                      'alert-danger': vendor.latestDecisionStatus == 'Disputed',
                      'alert-confirmed': vendor.latestDecisionStatus == 'Confirmed',
                      'alert-warning': vendor.latestDecisionStatus == null
                      }">
                      <option selected *ngIf="vendor.latestDecisionStatus == 'Reset'" value="Reset">Reset</option>
                      <option value="Pending">Resend Email</option>
                      <option value="Confirmed">Confirmed</option>
                    </select>
                  </div>
                  <div role="alert" class="mb-0 d-flex justify-content-end" *ngIf="vendor.isAccepted">
                    <select #venderStatus (change)="resetVendor(vendor, i, venderStatus)"
                      class="form-control w-auto pr-4 form-control-sm form-control-nav" [ngClass]="{
                        'alert-info': vendor.latestDecisionStatus == 'Pending',
                        'alert-reset': vendor.latestDecisionStatus == 'Reset',
                        'alert-secondary': vendor.latestDecisionStatus == 'NA',
                        'alert-success': vendor.latestDecisionStatus == 'Accepted' || vendor.latestDecisionStatus == 'Auto-Accepted',
                        'alert-danger': vendor.latestDecisionStatus == 'Disputed',
                        'alert-confirmed': vendor.latestDecisionStatus == 'Confirmed',
                        'alert-warning': vendor.latestDecisionStatus == null
                      }">
                      <option selected *ngIf="vendor.latestDecisionStatus == 'Accepted'" value="Accepted">Accepted
                      </option>
                      <option selected *ngIf="vendor.latestDecisionStatus == 'Auto-Accepted'" value="Auto-Accepted">
                        Auto-Accepted</option>
                      <option selected *ngIf="vendor.latestDecisionStatus == 'Confirmed'" value="Confirmed">Confirmed
                      </option>
                      <option value="Reset">Reset</option>
                    </select>
                  </div>
                </small>
              </div>
            </div>
            <div class="col mb-1 px-0" *ngIf="closureDetails?.vendors?.length == 0">
              <div class="alert alert-warning mt-1" role="alert">
                External Vendor pages will be available after reconciliation .
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="closureType === 'close-external'"></div>
      </div>
      <div class="closure-steps mb-3">

        <div class="card" *ngIf="closureType === 'close'">
          <div #step5FinalMargin class="card-header" [ngClass]="getStepStyle(5)">
            <div class="row align-items-center">
              <div class="col-10">
                <i *ngIf="closureDetails.step5CompletedBy != null"
                  class="fas fa-check-circle fa-lg mr-3 text-white opacity-90 completed-mark"></i>
                <span class="h5 text-white">Final Margin Review</span>
              </div>
              <div class="col-2 text-right">
                <i class="cursor-pointer fa-cog fa-lg fas opacity-90 text-white" (click)="openStep(5)"></i>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="d-inline-flex shadow-sm w-100 p-2">
              <div class="text-center w-100">
                <span class="mb-2">Margin</span>
                <h2 class="total-num">{{ vendorDetails.total.margin | percent }}</h2>
              </div>
              <i class="fas fa-coins fa-3x align-self-center or-title opacity-70 pr-3"></i>
            </div>
          </div>
        </div>
        <div class="card" *ngIf="closureType === 'close'">
          <div class="card-header" [ngClass]="getStepStyle(7)">
            <div class="row align-items-center">
              <div class="col-10">
                <i *ngIf="closureDetails.step7CompletedBy != null"
                  class="fas fa-check-circle fa-lg mr-3 text-white opacity-90 completed-mark"></i>
                <span class="h5 text-white">Invoice</span>
              </div>
              <div class="col-2 text-right">
                <i class="fas fa-cog fa-lg cursor-pointer opacity-90 text-white" (click)="openStep(7)"></i>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-4 text-left">
                <label>Billing Date</label>
                <input type="text" name="billingDate" autocomplete="off" readonly bsDatepicker #dp="bsDatepicker"
                  [bsValue]="closureDetails.billingDate" (bsValueChange)="updateBillingDate($event)"
                  [bsConfig]="{ dateInputFormat: 'MMM Do, YYYY', isAnimated: true, isOpen:true, showWeekNumbers:false, customTodayClass: 'todays-date' }"
                  class="form-control form-control-sm date d-inline bg-white" placement="top">
              </div>
            </div>
            <div class="row mt-3">
              <div class="col text-left">
                <button type="button" (click)="downloadInvoice()" class="btn btn-sm btn-outline-secondary btn-sm"
                  tooltip="Show/Hide Key">
                  <em class="fa fa-download"></em> Download Invoice
                </button>
                <button type="button" (click)="downloadBilling()" class="btn btn-sm btn-outline-secondary btn-sm ml-2"
                  tooltip="Show/Hide Key">
                  <em class="fa fa-download"></em> Download Billing
                </button>
              </div>
            </div>
            <div class="row mt-3" *ngIf="auth.isSuperAdmin() && project.projectStatus === 'Invoiced'">
              <div class="col text-left">
                <button type="button" (click)="revertToClosed()"
                  class="btn btn-sm btn-outline-secondary btn-sm nav-error">
                  <em class="fa fa-download"></em> Revert to Closed
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Individual expanded sections (Steps) -->
    <div class="wizard pt-2 pb-3 d-flex" *ngIf="showStep > 1">
      <button type="button" class="btn btn-arrow-right" [ngClass]="getStepStyle(1)" (click)="openStep(0)">
        <i class="fas fa-sm fa-home mr-2"></i>
      </button>
      <button type="button" class="btn btn-arrow-right ml-1" [ngClass]="getStepStyle(2)" [class.current]="showStep == 2"
        (click)="openStep(2)">
        <i class="fas fa-sm fa-check-circle mr-1" *ngIf="closureDetails.Step2CompletedBy != null"></i>
        Reconcile IDs
      </button>
      <button type="button" class="btn btn-arrow-right" [ngClass]="getStepStyle(3)" [class.current]="showStep == 3"
        (click)="openStep(3)" *ngIf="closureType === 'close'">
        <i class="fas fa-sm fa-check-circle mr-1" *ngIf="closureDetails.Step3CompletedBy != null"></i>
        Client Pricing
      </button>
      <button type="button" class="btn btn-arrow-right" [ngClass]="getStepStyle(4)" [class.current]="showStep == 4"
        (click)="openStep(4)">
        <i class="fas fa-sm fa-check-circle mr-1" *ngIf="closureDetails.Step4CompletedBy != null"></i>
        Vendor Pricing
      </button>
      <button type="button" class="btn btn-arrow-right" [ngClass]="getStepStyle(5)" [class.current]="showStep == 5"
        (click)="openStep(5)" *ngIf="closureType === 'close'">
        <i class="fas fa-sm fa-check-circle mr-1" *ngIf="closureDetails.Step5CompletedBy != null"></i>
        Margin Review
      </button>
      <!-- Vendor satisfaction is hidden for now -->
      <button type="button" class="btn btn-arrow-right" [ngClass]="getStepStyle(7)" [class.current]="showStep == 7"
        (click)="openStep(7)" *ngIf="closureType === 'close'">
        <i class="fas fa-sm fa-check-circle mr-1" *ngIf="closureDetails.Step7CompletedBy != null"></i>
        Invoice
      </button>
      <!--<button type="button" class="btn btn-arrow-right ml-1" [ngClass]="{'done': showStep == 8 }" (click)="openStep(8)">
        <i class="fas fa-sm fa-check-circle mr-2"></i>
      </button>-->
    </div>

    <!-- Reconcile IDs -->
    <ng-container *ngIf="showStep === 2">
      <div class="mb-5" *ngIf="closureDetails.step1CompletedBy == null">
        <div class="alert alert-warning mt-1" role="alert">
          Upload the completes on step 1 before you can complete this step.
        </div>
      </div>
      <div class="mb-5" *ngIf="closureDetails.step1CompletedBy != null">
        <div *ngIf="!closureComplete" class="alert alert-info mx-1 py-2" role="alert">
          <div class="d-flex align-items-center">
            <div>
              Update your Nav Complete status values and then confirm when finished
            </div>
            <div class="ml-auto">
              <button type="button" class="btn btn-outline-secondary btn-sm mr-2" (click)="saveClientCompletes(false)">
                <i class="fas fa-save cursor-pointer mr-2"></i>
                Save Changes
              </button>
              <button type="button" class="btn btn-outline-secondary btn-sm" (click)="saveClientCompletes(true)">
                <i class="fas fa-save cursor-pointer mr-2"></i>
                Save and Confirm
              </button>
            </div>
          </div>
        </div>
        <div class="step2-expanded">
          <ng-container *ngIf="reconcileData != null">
            <div class="card">
              <div class="card-header">
                <div class="d-flex align-items-center">
                  <div>
                    <span class="h5 text-white">
                      <i class="fas fa-user-tie mr-3 text-white opacity-90"></i>
                      Client Completes to Update
                    </span>
                  </div>
                  <div class="ml-auto">
                    <div class="btn btn-info btn-xs" (click)="showAll = ! showAll">{{ showAll ? 'Show Matched' : 'Show
                      All' }}</div>
                  </div>
                </div>
              </div>
              <div class="card-body reconcile scrollbar overflow-y py-0 px-3">
                <div class="row">
                  <div class="col px-0">
                    <table class="table mb-0">
                      <thead class="thead-light">
                        <tr class="table-secondary">
                          <td class="">Project Code</td>
                          <td class="">Segment</td>
                          <td class="">Participant</td>
                          <td class="">Status</td>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let row of reconcileData.clientCompletes.details">
                          <tr *ngIf="(showAll || ! row.matched) && !row.rejected" class="table-light">
                            <td>{{ row.projectCode }}</td>
                            <td>{{ row.segment }}</td>
                            <td>{{ row.participantId }}</td>
                            <td *ngIf="row.rejectedReason === 'Test'" style="color:red;">{{ row.exitStatus }}</td>
                            <td *ngIf="row.rejectedReason != 'Test'">{{ row.exitStatus }}</td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-footer py-2 or-gray-bg">
                <div class="row">
                  <div class="col d-flex justify-content-center">
                    <button type="button" class="btn border-0 w-100 shadow-none" (click)="downloadCompletes()">
                      <i class="fas fa-download fa-sm cursor-pointer mr-2"></i>
                      Download IDs
                    </button>
                  </div>
                  <div class="col d-flex justify-content-center">
                    <button type="button" class="btn border-0 w-100 shadow-none" (click)="copyClientCompletes()">
                      <i class="fas fa-copy fa-sm cursor-pointer mr-2"></i>
                      Copy IDs
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <div class="d-flex align-items-center">
                  <div>
                    <span class="h5 text-white">
                      <i class="fas fa-tasks mr-3 text-white opacity-90"></i>
                      NAV Completes to Update
                    </span>
                  </div>
                  <div *ngIf="!closureComplete" class="ml-auto">
                    <span class="text-white h6">
                      Update All
                      <select [disabled]="closureComplete" (change)="updateAllStatus($event.target.value)">
                        <option *ngFor="let stat of statusOptions" value={{stat}}>
                          {{stat}}
                        </option>
                      </select>
                    </span>
                  </div>
                </div>
              </div>
              <div class="card-body reconcile scrollbar overflow-y py-0 px-3">
                <div class="row">
                  <div class="col px-0">
                    <table class="table mb-0">
                      <thead class="thead-light">
                        <tr class="table-secondary">
                          <td style="width: 160px;">Project Code</td>
                          <!--<td class="">Segment</td>-->
                          <td style="width: 250px;">Participant</td>
                          <td style="width: 165px;">Status</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="table-light" *ngFor="let row of reconcileData.navCompletes.details">
                          <td>{{ row.projectCode }}</td>
                          <!--
                          <td>
                            <select [(ngModel)]="row.segment">
                              <option *ngFor="let seg of segmentOptions" value={{seg}}>
                                {{seg}}
                              </option>
                            </select>
                          </td>
                          -->
                          <td>{{ row.participantId }}</td>
                          <td>
                            <select [disabled]="closureComplete" [(ngModel)]="row.exitStatus">
                              <option *ngFor="let stat of statusOptions" value={{stat}}>
                                {{stat}}
                              </option>
                            </select>
                            <select [disabled]="closureComplete"
                              *ngIf="segmentOptions.length > 1 && row.exitStatus == 'Complete'"
                              [(ngModel)]="row.segment">
                              <option *ngFor="let seg of segmentOptions" value={{seg}}>
                                {{seg}}
                              </option>
                            </select>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-footer py-2 or-gray-bg">
                <div class="row">
                  <div class="col d-flex justify-content-center">
                    <button type="button" class="btn border-0 w-100 shadow-none" (click)="downloadNavCompletes()">
                      <i class="fas fa-download fa-sm cursor-pointer mr-2"></i>
                      Download IDs
                    </button>
                  </div>
                  <div class="col d-flex justify-content-center">
                    <button type="button" class="btn border-0 w-100 shadow-none" (click)="copyNavCompletes()">
                      <i class="fas fa-copy fa-sm cursor-pointer mr-2"></i>
                      Copy IDs
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <!-- Client Pricing -->
    <ng-container *ngIf="showStep === 3 && closureType === 'close'">
      <div class="mb-5" *ngIf="closureDetails.step2CompletedBy == null">
        <div class="alert alert-warning mt-1" role="alert">
          You need to upload and reconcile the completes before adding Client Pricing.
        </div>
      </div>
      <div class="mb-5" *ngIf="closureDetails.step2CompletedBy != null">
        <div *ngIf="!closureComplete" class="alert alert-info mx-1 py-2" role="alert">
          <div class="d-flex align-items-center">
            <div>
              Set your CPIs and client pricing
            </div>
            <div class="ml-auto">
              <button type="button" class="btn btn-outline-secondary btn-sm mr-2" (click)="saveClientFinance(false)">
                <i class="fas fa-save cursor-pointer mr-2"></i>
                Save Changes
              </button>
              <button type="button" class="btn btn-outline-secondary btn-sm" (click)="saveClientFinance(true)">
                <i class="fas fa-save cursor-pointer mr-2"></i>
                Save and Confirm
              </button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col-7">
                <span class="h5 nav-secondary">
                  <i class="fas fa-user-tie mr-3 nav-secondary opacity-90"></i>
                  Client Pricing
                </span>
              </div>
              <div class="col-2" *ngIf="closureDetails.client != null">
                <ul class="nav alerts d-flex align-items-end justify-content-end ml-auto mt-n2">
                  <li class="nav-item ml-3 cursor-pointer" tooltip="Download Client Cost" placement="left"
                    container="body">
                    <i class="fak fa-download fa-lg grey4" (click)="ExportClientCost()"></i>
                  </li>
                </ul>
              </div>

              <div class="col-3" *ngIf="closureDetails.client != null">
                <div class="input-group input-group-sm">

                  <div class="input-group-prepend">
                    <span class="input-group-text text-white or-primary-bg border-0">Client PO Number A</span>
                  </div>
                  <input [disabled]="closureComplete" type="text" class="form-control form-control-sm text-center"
                    required [(ngModel)]="closureDetails.client.po"
                    [ngClass]="{'is-invalid': isInvalid(closureDetails.client.po) }">
                </div>
              </div>
            </div>
          </div>
          <div class="card-body scrollbar overflow-y py-0 px-3">
            <div class="row">
              <div class="col px-0">        

                <table class="table mb-0" *ngIf="closureDetails.client != null">
                  <tbody>
                    <ng-container *ngIf="closureDetails?.client?.pricing?.length > 0">
                      <tr class="table-secondary">
                        <td>Segment</td>
                        <td>Product</td>
                        <td>Category</td>
                        <td>Completes</td>
                        <td>CPI</td>
                        <td>Line Total</td>
                      </tr>
                      <tr class="table-light" *ngFor="let pricing of closureDetails.client.pricing">
                        <td>{{ pricing.segment }}</td>
                        <td>
                          <select [disabled]="closureComplete" [(ngModel)]="pricing.product"
                            class="custom-select custom-select-sm d-block w-100">
                            <option *ngFor="let product of productOptions"> {{ product }}</option>
                          </select>
                        </td>
                        <td>
                          <select [disabled]="closureComplete" [(ngModel)]="pricing.category"
                            class="custom-select custom-select-sm d-block w-100">
                            <option *ngFor="let category of categoryOptions"> {{ category }}</option>
                          </select>
                        </td>
                        <td>{{ pricing.completes }}</td>
                        <td>
                          <input [disabled]="closureComplete" type="number" class="form-control form-control-sm"
                            [(ngModel)]="pricing.sellCpi"
                            (change)="pricing.total = pricing.completes * pricing.sellCpi">
                        </td>
                        <td>
                          <input [disabled]="closureComplete" type="number" class="form-control form-control-sm"
                            [(ngModel)]="pricing.total"
                            (change)="pricing.sellCpi = (pricing.completes === 0) ? null : pricing.total/pricing.completes">
                        </td>
                      </tr>
                      <tr class="table-secondary h6">
                        <td colspan="3" class="font-weight-normal text-left pl-5 py-2">Sub Total</td>
                        <td class="font-weight-normal py-2">{{ clientPricing.completes}}</td>
                        <td></td>
                        <td class="font-weight-normal py-2">{{ clientPricing.fee | currency}}</td>
                      </tr>
                    </ng-container>

                    <tr
                      *ngIf="closureDetails?.client?.pricing?.length > 0 && closureDetails?.client?.expenses?.length > 0">
                      <td> </td>
                    </tr>

                    <ng-container *ngIf="closureDetails?.client?.expenses?.length >= 0">
                      <tr class="table-secondary">
                        <td><label class="required mb-0">Description</label></td>
                        <td>Product</td>
                        <td>Category</td>
                        <td>Qty</td>
                        <td>Unit Cost</td>
                        <td>Line Total</td>
                      </tr>
                      <ng-container *ngFor="let expense of closureDetails.client.expenses; let idx=index">
                        <tr *ngIf="expense.isDeleted != true">
                          <td>
                            <div class="d-flex">
                              <em class="btn fa fa-times-circle px-1 text-danger"
                                (click)="removeClientExpense(idx)"></em>
                              <input [disabled]="closureComplete" type="text" class="form-control form-control-sm"
                                [(ngModel)]="expense.description"
                                [ngClass]="{'is-invalid': isInvalid(expense.description) }">
                            </div>
                          </td>
                          <td>
                            <select [disabled]="closureComplete" [(ngModel)]="expense.product"
                              class="custom-select custom-select-sm d-block w-100">
                              <option *ngFor="let product of productOptions"> {{ product }}</option>
                            </select>
                          </td>
                          <td>
                            <select [disabled]="closureComplete" [(ngModel)]="expense.category"
                              class="custom-select custom-select-sm d-block w-100">
                              <option *ngFor="let category of categoryOptions"> {{ category }}</option>
                            </select>
                          </td>
                          <td>
                            <input [disabled]="closureComplete" type="number" class="form-control form-control-sm"
                              [ngClass]="{'is-invalid': isInvalid(expense.completes) }" [(ngModel)]="expense.completes"
                              (change)="expense.amount = expense.completes * expense.expenseCpi">
                          </td>
                          <td>
                            <input [disabled]="closureComplete" type="number" class="form-control form-control-sm"
                              [(ngModel)]="expense.expenseCpi"
                              (change)="expense.amount = expense.completes * expense.expenseCpi">
                          </td>
                          <td>
                            <input [disabled]="closureComplete" type="number" class="form-control form-control-sm"
                              [(ngModel)]="expense.amount"
                              (change)="expense.expenseCpi = (expense.completes === 0 || expense.completes == null) ? 0 : expense.amount/expense.completes">
                          </td>
                        </tr>
                      </ng-container>
                      <tr *ngIf="!closureComplete || closureComplete === undefined">
                        <td colspan="6">
                          <div class="btn btn-outline-secondary btn-sm mb-4" (click)="addClientExpense()">Add Line Item
                          </div>
                        </td>
                      </tr>
                      <tr class="table-secondary h6">
                        <td colspan="5" class="font-weight-normal text-left pl-5 py-2">Sub Total</td>
                        <td class="font-weight-normal py-2">{{ clientPricing.expense | currency }}</td>
                      </tr>
                    </ng-container>

                    <tr class="text-white h5 border text-center table-primary">
                      <td class=" text-left pl-5" colspan="5">Total</td>
                      <td>{{ clientPricing.total | currency }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Vendor Pricing -->
    <ng-container *ngIf="showStep === 4">
      <div class="mb-5" *ngIf="closureDetails.step2CompletedBy == null">
        <div class="alert alert-warning mt-1" role="alert">
          You need to upload and reconcile the completes before adding Vendor Pricing.
        </div>
      </div>
      <div class="mb-5" *ngIf="closureDetails.step2CompletedBy != null && closureDetails.vendors.length == 0">
        <div class="alert alert-warning mt-1" role="alert">No Vendor Details available.</div>
      </div>
      <div class="mb-5" *ngIf="closureDetails.step2CompletedBy != null && closureDetails.vendors.length > 0">
        <div *ngIf="!closureComplete" class="alert alert-info mx-1 py-2" role="alert">
          <div class="d-flex align-items-center">
            <div>
              Set your CPIs and vendor pricing for each vendor
            </div>
            <div class="ml-auto">
              <button type="button" class="btn btn-outline-secondary btn-sm mr-2" (click)="saveVendorFinance(false)">
                <i class="fas fa-save cursor-pointer mr-2"></i>
                Save Changes
              </button>
              <button type="button" class="btn btn-outline-secondary btn-sm" (click)="saveVendorFinance(true)">
                <i class="fas fa-save cursor-pointer mr-2"></i>
                Save and Confirm
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="!closureComplete" class="row">
          <div class="col d-flex align-items-center">
            <span class="h6 mr-3">Add Vendor</span>
            <span>
              <or-type-ahead [width]="'400px'" [placeholder]="'Type to find and add a new vendor'" [labelField]="'name'"
                [multi]="false" [cleanAfterSelect]="true" [dataCallback]="getPartnerList"
                (valueChange)="addPartnerTypeahead($event)">
              </or-type-ahead>
            </span>
          </div>
          <div class="col d-flex justify-content-end">
          </div>
          <div class="col d-flex justify-content-end">
            <span class="p-3 align-items-right" data-html="true" tooltip="Download vendor ID files"
              (click)="downloadPartnerCompletes()">
              <i class="cursor-pointer fa-download fa-lg fas opacity-90 text-green"></i>
            </span>
            <span class="p-3 align-items-right" data-html="true" tooltip="Select Vendors to send IDs and costs to"
              (click)="openModalVendor()" *ngIf="closureDetails.step2CompletedBy != null">
              <i class="cursor-pointer fa-envelope fa-lg fas opacity-90 text-green"></i>
            </span>
          </div>
        </div>
        <hr class="mb-4" />
        <div class="row">
          <div class="col-12 mb-3" *ngFor="let vendor of checkVendorIsDeleted(closureDetails.vendors); let i = index">
            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-7 d-flex">
                    <span class="h5 nav-secondary">
                      <i class="fas fa-handshake mr-3 nav-secondary opacity-90"></i>
                      {{ vendor.vendorName }}
                    </span>
                    <small class="or-title text-right">
                      <div role="alert" class="ml-3 d-flex justify-content-end"
                        *ngIf="!vendor.isAccepted && vendor.latestDecisionStatus == 'Confirmed'">
                        <select #venderStatus (change)="resetVendor(vendor, i,venderStatus)"
                          class="form-control w-auto pr-4 form-control-sm form-control-nav" [ngClass]="{
                          'alert-info': vendor.latestDecisionStatus == 'Pending',
                          'alert-reset': vendor.latestDecisionStatus == 'Reset',
                          'alert-secondary': vendor.latestDecisionStatus == 'NA',
                          'alert-success': vendor.latestDecisionStatus == 'Accepted' || vendor.latestDecisionStatus == 'Auto-Accepted',
                          'alert-danger': vendor.latestDecisionStatus == 'Disputed',
                          'alert-confirmed': vendor.latestDecisionStatus == 'Confirmed',
                          'alert-warning': vendor.latestDecisionStatus == null
                          }">
                          <option selected *ngIf="vendor.latestDecisionStatus == 'Reset'" value="Reset">Reset</option>
                          <option value="Pending">Resend Email</option>
                        </select>
                      </div>
                      <div role="alert" class="ml-3 d-flex justify-content-end"
                        *ngIf="!vendor.isAccepted && vendor.latestDecisionStatus == 'Disputed'">
                        <select #venderStatus (change)="resetVendor(vendor, i,venderStatus)"
                          class="form-control w-auto pr-4 form-control-sm form-control-nav" [ngClass]="{
                          'alert-info': vendor.latestDecisionStatus == 'Pending',
                          'alert-reset': vendor.latestDecisionStatus == 'Reset',
                          'alert-secondary': vendor.latestDecisionStatus == 'NA',
                          'alert-success': vendor.latestDecisionStatus == 'Accepted' || vendor.latestDecisionStatus == 'Auto-Accepted',
                          'alert-danger': vendor.latestDecisionStatus == 'Disputed',
                          'alert-confirmed': vendor.latestDecisionStatus == 'Confirmed',
                          'alert-warning': vendor.latestDecisionStatus == null
                          }">
                          <option selected *ngIf="vendor.latestDecisionStatus == 'Disputed'" value="Disputed">Disputed
                          </option>
                          <option value="Reset">Reset</option>
                        </select>
                      </div>
                      <div role="alert" class="ml-3 d-flex justify-content-end"
                        *ngIf="!vendor.isAccepted && vendor.latestDecisionStatus == 'Pending'">
                        <select #venderStatus (change)="confirmVendor(vendor, i,venderStatus)"
                          class="form-control w-auto pr-4 form-control-sm form-control-nav" [ngClass]="{
                          'alert-info': vendor.latestDecisionStatus == 'Pending',
                          'alert-reset': vendor.latestDecisionStatus == 'Reset',
                          'alert-secondary': vendor.latestDecisionStatus == 'NA',
                          'alert-success': vendor.latestDecisionStatus == 'Accepted' || vendor.latestDecisionStatus == 'Auto-Accepted',
                          'alert-danger': vendor.latestDecisionStatus == 'Disputed',
                          'alert-confirmed': vendor.latestDecisionStatus == 'Confirmed',
                          'alert-warning': vendor.latestDecisionStatus == null
                          }">
                          <option selected *ngIf="vendor.latestDecisionStatus == 'Pending'" value="Pending">Pending
                          </option>
                          <option value="Confirmed">Confirmed</option>
                        </select>
                      </div>
                      <div role="alert" class="ml-3 d-flex justify-content-end"
                        *ngIf="!vendor.isAccepted && vendor.latestDecisionStatus == 'Reset'">
                        <select #venderStatus (change)="resetVendor(vendor, i,venderStatus)"
                          class="form-control w-auto pr-4 form-control-sm form-control-nav" [ngClass]="{
                          'alert-info': vendor.latestDecisionStatus == 'Pending',
                          'alert-reset': vendor.latestDecisionStatus == 'Reset',
                          'alert-secondary': vendor.latestDecisionStatus == 'NA',
                          'alert-success': vendor.latestDecisionStatus == 'Accepted' || vendor.latestDecisionStatus == 'Auto-Accepted',
                          'alert-danger': vendor.latestDecisionStatus == 'Disputed',
                          'alert-confirmed': vendor.latestDecisionStatus == 'Confirmed',
                          'alert-warning': vendor.latestDecisionStatus == null
                          }">
                          <option selected *ngIf="vendor.latestDecisionStatus == 'Reset'" value="Reset">Reset</option>
                          <option value="Pending">Resend Email</option>
                          <option value="Confirmed">Confirmed</option>
                        </select>
                      </div>
                      <div role="alert" class="ml-3 d-flex justify-content-end" *ngIf="vendor.isAccepted">
                        <select #venderStatus (change)="resetVendor(vendor, i, venderStatus)"
                          class="form-control w-auto pr-4 form-control-sm form-control-nav" [ngClass]="{
                            'alert-info': vendor.latestDecisionStatus == 'Pending',
                            'alert-reset': vendor.latestDecisionStatus == 'Reset',
                            'alert-secondary': vendor.latestDecisionStatus == 'NA',
                            'alert-success': vendor.latestDecisionStatus == 'Accepted' || vendor.latestDecisionStatus == 'Auto-Accepted',
                            'alert-danger': vendor.latestDecisionStatus == 'Disputed',
                            'alert-confirmed': vendor.latestDecisionStatus == 'Confirmed',
                            'alert-warning': vendor.latestDecisionStatus == null
                          }">
                          <option selected *ngIf="vendor.latestDecisionStatus == 'Accepted'" value="Accepted">Accepted
                          </option>
                          <option selected *ngIf="vendor.latestDecisionStatus == 'Auto-Accepted'" value="Auto-Accepted">
                            Auto-Accepted</option>
                          <option selected *ngIf="vendor.latestDecisionStatus == 'Confirmed'" value="Confirmed">
                            Confirmed</option>
                          <option value="Reset">Reset</option>
                        </select>
                      </div>
                    </small>
                  </div>

                  <div class="col-4">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text text-white or-primary-bg border-0">Project Number</span>
                      </div>
                      <input [disabled]="closureComplete || vendor.isAccepted" type="text"
                        class="form-control form-control-sm text-center" [(ngModel)]="vendor.po">
                    </div>
                  </div>
                  <div class="col-1">
                    <em *ngIf="vendorDetails[vendor.vendorName].completes == 0 && !vendor.isAccepted"
                      (click)="removeVendor(vendor)" class="btn far fa-trash-alt p-0 nav-secondary"></em>
                  </div>
                </div>
              </div>
              <div class="card-body scrollbar overflow-y py-0 px-3">
                <div class="row">
                  <div class="col px-0">
                    <table class="table mb-0">
                      <thead class="thead-light" *ngIf="vendorDetails[vendor.vendorName].completes > 0">
                        <tr class="table-secondary">
                          <td colspan="2">Segment</td>
                          <td>Completes</td>
                          <td>CPI</td>
                          <td>Line Total</td>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngIf="vendorDetails[vendor.vendorName].completes > 0">
                          <tr class="table-light" *ngFor="let pricing of vendor.pricing">
                            <td colspan="2">{{ pricing.segment }}</td>
                            <td>{{ pricing.completes }}</td>
                            <td>
                              <input [disabled]="closureComplete || vendor.isAccepted" type="number"
                                class="form-control form-control-sm" [(ngModel)]="pricing.buyCpi"
                                (change)="pricing.total = pricing.completes * pricing.buyCpi">
                            </td>
                            <td>
                              <input [disabled]="closureComplete || vendor.isAccepted" type="number"
                                class="form-control form-control-sm" [(ngModel)]="pricing.total"
                                (change)="pricing.buyCpi = (pricing.completes === 0) ? null : pricing.total/pricing.completes">
                            </td>
                          </tr>
                          <tr class="table-secondary h6">
                            <td colspan="2" class="font-weight-normal text-left pl-5 py-2">Sub Total</td>
                            <td class="font-weight-normal py-2">{{ vendorDetails[vendor.vendorName].completes}}</td>
                            <td></td>
                            <td class="font-weight-normal py-2">{{ vendorDetails[vendor.vendorName].fee | currency}}
                            </td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="vendor.expenses.length > 0">
                          <tr *ngIf="vendorDetails[vendor.vendorName].completes > 0">
                            <td></td>
                          </tr>
                          <tr class="table-secondary">
                            <td>Description</td>
                            <td>Product</td>
                            <td>Qty</td>
                            <td>Unit Price</td>
                            <td>Line Total</td>
                          </tr>
                        </ng-container>
                        <ng-container *ngFor="let expense of vendor.expenses let idx=index">
                          <tr *ngIf="expense.isDeleted != true">
                            <td class="d-flex">
                              <em class="btn fa fa-times-circle px-1 text-danger"
                                (click)="removeVendorExpense(vendor, idx)"></em>
                              <input [disabled]="closureComplete" type="text" class="form-control form-control-sm"
                                [(ngModel)]="expense.description"
                                [ngClass]="{'is-invalid': isInvalid(expense.description) }">
                            </td>
                            <td>
                              <select [disabled]="closureComplete" [(ngModel)]="expense.product"
                                class="custom-select custom-select-sm d-block w-100">
                                <option *ngFor="let product of productOptions"> {{ product }}</option>
                              </select>
                            </td>
                            <td>
                              <input [disabled]="closureComplete" type="number" required
                                class="form-control form-control-sm"
                                [ngClass]="{'is-invalid': isInvalid(expense.completes) }"
                                [(ngModel)]="expense.completes"
                                (change)="expense.amount = expense.completes * expense.expenseCpi">
                            </td>
                            <td>
                              <input [disabled]="closureComplete" type="number" class="form-control form-control-sm"
                                [(ngModel)]="expense.expenseCpi"
                                (change)="expense.amount = expense.completes * expense.expenseCpi">
                            </td>
                            <td>
                              <input [disabled]="closureComplete" type="number" class="form-control form-control-sm"
                                [(ngModel)]="expense.amount" (change)="changeLineTotal(expense);">
                            </td>
                          </tr>
                        </ng-container>
                        <tr *ngIf="!closureComplete && !vendor.isAccepted">
                          <td colspan="5">
                            <div class="btn btn-outline-secondary btn-sm mb-4" (click)="addVendorExpense(vendor)">Add
                              Line Item</div>
                          </td>
                        </tr>
                        <tr class="table-secondary h6" *ngIf="vendorDetails[vendor.vendorName].completes > 0">
                          <td colspan="4" class="font-weight-normal text-left pl-5 py-2">Sub Total</td>
                          <td class="font-weight-normal py-2">{{ vendorDetails[vendor.vendorName].expense | currency }}
                          </td>
                        </tr>
                        <tr class="text-white h5 border text-center table-primary">
                          <td class="text-left pl-5" colspan="4">Total</td>
                          <td>{{ vendorDetails[vendor.vendorName].total + vendorDetails[vendor.vendorName].expense |
                            currency}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Final Margin Review -->
    <ng-container *ngIf="showStep === 5 && closureType === 'close'">
      <div class="mb-5">
        <div *ngIf="closureDetails.step3CompletedBy == null || closureDetails.step4CompletedBy == null"
          class="alert alert-warning mx-1 py-2" role="alert">
          You need to complete client and vendor pricing before you can confirm this step.
        </div>
        <div
          *ngIf="!closureComplete && closureDetails.step3CompletedBy != null && closureDetails.step4CompletedBy != null"
          class="alert alert-info mx-1 py-2" role="alert">
          <div class="d-flex align-items-center">
            <div>
              Your client and vendor pricing are finalized so you can confirm the overall margins now.
            </div>
            <div class="ml-auto"
              *ngIf="closureDetails.invoiceNum != null && closureDetails.invoiceDate != null && closureDetails.step7CompletedBy != null">
              <div class="or-checkbox d-block position-relative mb-2">
                <!-- <input [disabled]="closureComplete" type="checkbox" name="fellshort" id="fellshort" [checked]="selectAll == 1" (change)="updateAllAnswers($event.target.checked, vendorSatisfaction[currentQuestion].answers, 1)"> -->
                <input [disabled]="closureComplete" type="checkbox" name="pmConfirm" id="pmConfirm"
                  [checked]="selectAll == 1" (change)="pmConfirmCheckbox($event.target.checked)">
                <label style="padding-left: 30px;" for="pmConfirm">Confirm COGs</label>
              </div>
            </div>
            <div class="ml-auto">
              <button type="button" class="btn btn-outline-secondary btn-sm" (click)="confirmMargin()"
                [disabled]="closureDetails.invoiceNum != null && closureDetails.invoiceDate != null && closureDetails.step7CompletedBy != null && !pmConfirmCogs">
                <i class="fas fa-check cursor-pointer mr-2"></i>
                Confirm
              </button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col-12">
                <span class="h5 text-white">
                  <i class="fas fa-coins mr-3 text-white opacity-90"></i>
                  Final Margin Review
                </span>
              </div>
            </div>
          </div>
          <div class="card-body scrollbar overflow-y py-0 px-3">
            <div class="row">
              <div class="col px-0">

                <table class="table mb-0">
                  <thead class="thead-light">
                    <tr class="table-secondary h6 text-white border">
                      <td class="or-secondary-bg">Vendors</td>
                      <td class="or-secondary-bg">Product</td>
                      <td class="or-secondary-bg">Total</td>
                      <td class="or-secondary-bg">Margin</td>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let vendor of checkVendorIsDeleted(closureDetails.vendors)">
                      <tr class="table-light" *ngIf="vendorDetails[vendor.vendorName].completes > 0">
                        <td>{{ vendor.vendorName }}</td>
                        <td>Sample</td>
                        <td>{{ vendorDetails[vendor.vendorName].fee | currency }}</td>
                        <td>{{ vendorDetails[vendor.vendorName].margin | percent }}</td>
                      </tr>
                    </ng-container>

                    <tr class="table-secondary">
                      <td>Sample Subtotal</td>
                      <td></td>
                      <td>{{ sampleTotal | currency }}</td>
                      <td>{{this.sampleMargin | percent}}</td>
                    </tr>

                    <tr class="table-light" *ngFor="let vendor of getVendorServiceDetails()">
                      <td>{{ vendor.vendorName }}</td>
                      <td>{{vendor.product}}</td>
                      <td>{{ vendor.total | currency }}</td>
                      <td></td>
                    </tr>
                    <tr class="table-secondary">
                      <td>Service Subtotal</td>
                      <td></td>
                      <td>{{ serviceTotal | currency }}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col pr-1">
            <div class="d-inline-flex shadow-sm border w-100 p-2">
              <div class="text-center w-100">
                <span class="mb-2">Total Client</span>
                <h2 class="total-num">{{ clientPricing.total | currency }}</h2>
              </div>
              <i class="fas fa-user-tie fa-2x align-self-center or-title opacity-70 pr-4"></i>
            </div>
          </div>
          <div class="col px-1">
            <div class="d-inline-flex shadow-sm border w-100 p-2">
              <div class="text-center w-100">
                <span class="mb-2">Total Vendor</span>
                <h2 class="total-num">{{ vendorDetails.total.total | currency }}</h2>
              </div>
              <i class="fas fa-handshake fa-2x align-self-center or-title opacity-70 pr-4"></i>
            </div>
          </div>
          <div class="col pl-1">
            <div class="d-inline-flex shadow-sm border w-100 p-2">
              <div class="text-center w-100">
                <span class="mb-2">Margin</span>
                <h2 class="total-num">{{ vendorDetails.total.margin | percent }}</h2>
              </div>
              <i class="fas fa-coins fa-2x align-self-center or-title opacity-70 pr-4"></i>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Invoice -->
    <ng-container *ngIf="showStep === 7 && closureType === 'close'">
      <div *ngIf="closureDetails.step4CompletedBy == null ||
                  closureDetails.step5CompletedBy == null " class="alert alert-warning mx-1 py-2" role="alert">
        Please complete all other steps before you finalize the invoice.
      </div>
      <div *ngIf="closureDetails.step4CompletedBy != null &&
                  closureDetails.step5CompletedBy != null">
        <div *ngIf="!closureComplete && confirmInvoiceClosure == false && closureDetails.step7CompletedBy == null"
          class="alert alert-info mx-1 py-2" role="alert">
          <div class="d-flex align-items-center">
            <div>
              Review and Update the invoice information before confirming
            </div>
            <div class="ml-auto">
              <button type="button" class="btn btn-outline-secondary btn-sm mr-2" (click)="saveInvoiceDetails(false)">
                <i class="fas fa-save cursor-pointer mr-2"></i>
                Save Changes
              </button>
              <button type="button" class="btn btn-outline-secondary btn-sm" (click)="confirmInvoiceClosure = true">
                <i class="fas fa-save cursor-pointer mr-2"></i>
                Save and Confirm
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="confirmInvoiceClosure == true" class="alert alert-danger mx-1 py-2" role="alert">
          <div class="d-flex align-items-center">
            <div>
              The project status will be changed to 'Invoiced'. The invoice date will be set to <span
                *ngIf="closureDetails.invoiceDate == null">today</span><span
                *ngIf="closureDetails.invoiceDate != null">{{closureDetails.invoiceDate | date }}</span> and you will no
              longer be able to make changes.
            </div>
            <div class="ml-auto">
              <button type="button" class="btn btn-outline-secondary btn-sm mr-2"
                (click)="confirmInvoiceClosure = false">
                <i class="fas fa-save cursor-pointer mr-2"></i>
                Cancel
              </button>
              <button type="button" class="btn btn-outline-secondary btn-sm" (click)="saveInvoiceDetails(true)">
                <i class="fas fa-save cursor-pointer mr-2"></i>
                Confirm
              </button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="mt-2">
              <div class="h6 or-primary">
                Invoice#
              </div>
              <div class="col-4 pl-0">
                <input [disabled]="closureComplete || closureDetails.step7CompletedBy != null" type="text"
                  class="form-control form-control-sm" [(ngModel)]="closureDetails.invoiceNum">
              </div>
            </div>
            <div class="mt-4">
              <div class="h6 or-primary">
                Invoice Date
              </div>
              <div *ngIf="closureComplete || closureDetails.step7CompletedBy != null" class="h6">
                {{ closureDetails.invoiceDate | date}}
              </div>
              <div *ngIf="!closureComplete && closureDetails.step7CompletedBy == null" class="col-2 pl-0">
                <input type="text" autocomplete="off" name="invoiceDate" readonly bsDatepicker #dp="bsDatepicker"
                  [maxDate]="maxInvoiceDate" [bsValue]="closureDetails.invoiceDate"
                  (bsValueChange)="closureDetails.invoiceDate = $event"
                  [bsConfig]="{ dateInputFormat: 'MMM Do, YYYY', isAnimated: true, isOpen:true, showWeekNumbers:false, customTodayClass: 'todays-date' }"
                  class="form-control form-control-sm date d-inline bg-white">
                <small *ngIf="closureDetails.invoiceDate == null">This will default to the date invoice step is
                  confirmed if left blank</small>
              </div>
            </div>
            <div class="mt-4">
              <div class="h6 or-primary">
                Billing Prefix
              </div>
              <div class="col-4 pl-0">
                <input [disabled]="closureComplete || closureDetails.step7CompletedBy != null" type="text"
                  class="form-control form-control-sm" [(ngModel)]="closureDetails.billingNum">
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #modalConfirm>
    <div class="modal-header text-white">
      <p class="h4 position-absolute">{{this.modelMessage}}</p>
      <button type="button" class="close pull-right text-white" aria-label="Close" (click)="openModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="card-body p-0">
        <div class="row">
          <div class="col">
            <ng-container *ngIf="preview.invoicedParticipantsExcel?.length>0">
              <p class="h6"> The below Ids are already Invoiced. Please remove and reupload the file again.</p>

              <table class="table mb-0">
                <tr class="table-light" *ngFor="let row of preview.invoicedParticipantsExcel">
                  <td>{{ row }}</td>
                </tr>
              </table>
            </ng-container>
            <table class="table mb-0" *ngIf="preview.invoicedParticipantsExcel==null">
              <thead class="thead-light">
                <tr class="table-secondary">
                  <td>Project Code</td>
                  <td>Segment</td>
                  <td>Current Status</td>
                </tr>
              </thead>
              <tbody>
                <tr class="table-light" *ngFor="let row of preview.files">
                  <td>{{ row.projectCode }}</td>
                  <td>{{ row.segment }}</td>
                  <td>{{ row.status }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <table class="table mb-0" *ngIf="preview.invoicedParticipantsExcel==null">
            <tr class="table-light" *ngFor="let row of preview.messages">
              <td>{{ row }}</td>
            </tr>
          </table>
        </div>
        <div class="row" *ngIf="preview.invoicedParticipantsExcel==null">
          <div class="col">
            <button (click)="clientCompletesUploaded()" class="btn my-1 btn-sm btn-secondary float-right"
              type="button">Yes</button>
            <button (click)="openModalRef.hide()" class="btn m-1 btn-sm btn-outline-secondary float-right"
              type="button">No</button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>



  <ng-template #modalReconciliationError>
    <div class="modal-header">
      <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18"><i
          class="fak fa-trash font-weight-normal grey4 mr-2"></i>Error Details</h2>
      <div class="d-flex justify-content-end">
        <i class="fas fa-close grey4" (click)="openModalReconError.hide()"></i>
      </div>
    </div>

    <div class="modal-body grey4">
      <div class="row mt-2 nav-font16">
        <div class="col d-flex align-items-center">The following issues were found in the file.</div>
      </div>
      <div class="col reassign-tasks scrollbar overflow-y overflow-x-hidden">
        <div class="row my-3 mt-4 align-items-center justify-content-center">
          <div class="col-3">
            <p class="">Row:</p>
          </div>
          <div class="col-3">
            <p class="">Error:</p>
          </div>
          <div class="col text-left">
            <p class="">Value</p>
          </div>
        </div>
        <div class="row nav-font14" *ngFor="let error of errorList.slice(0, 10); let idxerror = index">
          <div class="col-3">
            <p class="">{{error.code}}</p>
          </div>
          <div class="col-3">
            <p class="">{{error.type}}</p>
          </div>
          <div class="col text-left">
            <p class="">{{error.message}}</p>
          </div>
        </div>
        <div class="row nav-font14" *ngIf="this.errorList.length > 10">
          <div class="col text-left">
            <p class="">+{{errorList.length-10}} more</p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="row d-flex w-100" >
        <div class="col text-right pr-0">
          <button type="button" class="btn nav-btn-primary mx-2 shadow-none font-weight-bold" (click)="openModalReconError.hide()">Close</button>
        </div>
      </div>
    </div>
  </ng-template>


  <ng-template #modalReset>
    <div class="modal-header">
      <p class="h5">Confirm Closure Rollback</p>
    </div>
    <div class="modal-body text-center">
      <p class="h6">This will erase all closure data for the current project. Do you wish to continue?</p>
      <button type="button" class="btn btn-outline-secondary m-2" (click)="callClosureReset()">Yes</button>
      <button type="button" class="btn btn-secondary" (click)="modalOpenConfirmRef.hide()">No</button>
    </div>
  </ng-template>

  <ng-template #modalResetVendorPricing>
    <div class="modal-header">
      <p class="h5">Confirm Vendor Pricing Reset</p>
    </div>
    <div class="modal-body">
      <div class="h6">
        <label class="required">Reason for status rollback:</label>
        <br>
        <small>Will be presented to vendors</small>
      </div>

      <p><textarea id="resetReason" type="text" style="width:90%" [(ngModel)]="resetReason"></textarea></p>
      <div class=" text-center">
        <p class="h6 ">This will reset the status for vendor '{{chosenVendorReset.vendorName}}'. <br> Do you wish to
          continue?</p>
        <button type="button" class="btn btn-outline-secondary m-2" (click)="resetConfirmVendor()">Yes</button>
        <button type="button" class="btn btn-secondary" (click)="cancelResetVendor()">No</button>
      </div>

    </div>
  </ng-template>

  <ng-template #modalZeroValueConfirm>
    <div class="modal-header text-white">
      <p class="h5 position-absolute">Confirm Cost</p>
      <button type="button" class="close pull-right text-white" aria-label="Close" (click)="notConfirmZeroValue()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body text-center">
      <p class="h6 mt-1"> {{this.vendorMessage}}</p>

      <div class="pb-0 mt-3">
        <button type="button" class="btn btn-outline-secondary m-2 py-1" (click)="notConfirmZeroValue()">Edit</button>
        <button type="button" class="btn btn-secondary py-1" (click)="confirmZeroValue()">Continue</button>
      </div>
    </div>
  </ng-template>
</div>
