<div class="col d-flex">
    <div class="btn btn-sm btn-outline-secondary" (click)="closePage()">
        <em class="fa fa-arrow-left mr-1"></em>
        <b>Return to Project</b>
    </div>
</div>

<div *ngIf="!emails" class="mt-3" style="width: 100%;">
    <div class="loading m-auto d-block" style="height: 50px; width: 50px;"></div>
</div>
<div *ngIf="emails" class="mx-3 mt-3">
    <div class="h3 or-secondary mb-2">Email Log</div>
    <or-list *ngIf="emails.length > 0" [items]="emails" [layout]="columns"></or-list>
    <div *ngIf="emails.length == 0" ngClass="h4 or-secondary">No emails found for this project</div>
</div>