@if (auth.loggedIn()) {
  <div class="wrapper">
    @if (!surveyTester) {
      @if (auth.loggedIn()) {
        <app-nav-bar [scrollX]="scrollX"></app-nav-bar>
      }
    }
    @if (instanceService.siteSections.length>0) {
      <div  class="content">
        <router-outlet></router-outlet>
      </div>
    }
  </div>
}

@if (!auth.loggedIn()) {
  <router-outlet></router-outlet>
}
