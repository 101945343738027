<div class="mx-4 customDateRangeGroup">
  <div class="float-right">
    <input type="text" autocomplete="off" (bsValueChange)="minDateChange($event)" bsDatepicker readonly
    #inputMin
    [bsValue]="ganntMinDate"
    [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', showWeekNumbers:false, adaptivePosition: true, customTodayClass: 'todays-date' }"
    (focus)="inputMin.showPicker()"
    onkeydown="event.preventDefault()"
    class="form-control form-control-nav form-control-sm d-inline bg-white text-left ganttDate">
    <span>
      <i class="far fa-thin fa-arrow-right-long fa-xs px-1 mx-1 cursor-default"></i>
    </span>
    <input type="text" autocomplete="off" (bsValueChange)="maxDateChange($event)" bsDatepicker readonly
    #inputMax
    [bsValue]="ganntMaxDate"
    [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', showWeekNumbers:false, adaptivePosition: true, customTodayClass: 'todays-date' }"
    (focus)="inputMax.showPicker()"
    onkeydown="event.preventDefault()"
    class="form-control form-control-nav form-control-sm d-inline bg-white text-left ganttDate">
  </div>
</div>
<div class="container-fluid mb-4">
    <ng-container *ngIf="highchartOptions != null">
      <highcharts-chart #chart [Highcharts]="highcharts" [constructorType]="'ganttChart'" [options]="highchartOptions" [update]="updateFlag"

        style="width: 100%; height: 100%; display: block;">
      </highcharts-chart>
    </ng-container>
  </div>