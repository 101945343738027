import { AfterViewInit, Component, OnInit, Renderer2, TemplateRef, ViewChild, ChangeDetectorRef  } from '@angular/core';
import { PartnerService, ProjectService, ProjectFilters, AlertifyService, Partner, PartnerContact, PartnerContactService, AuthService, UtilsService, LookupService, SettingsService, Webhook } from 'core';
import { ActivatedRoute, Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UnsavedchangesGuardService } from '../../../_guards/unsavedchanges.guard.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GroupNote } from 'projects/core/src/models/group-note';
import { forkJoin, take } from 'rxjs';
import { LookupTable } from 'projects/core/src/models/lookupTable';
import { FilterPipe } from 'projects/core/src/helpers/filter.pipe';
import { guid } from '@fullcalendar/core/internal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { After } from 'v8';
import { link } from 'fs';

@Component({
  selector: 'app-partner-details',
  templateUrl: './partner-details.component.html',
  styleUrls: ['./partner-details.component.scss'],
  providers: [FilterPipe]
})
export class PartnerDetailsComponent implements OnInit, AfterViewInit  {

  partner: Partner = {
    id: '',
    instanceId: '',
    name: '',
    connectionId: '',
    userDomain: '',
    img: '',
    address: {
      address1: '',
      address2: '',
      city: '',
      country: ''
    },
    notes: null,
    allNotes: []
  };

  contactForm: FormGroup;
  emailFocus = false;
  notAskAgainDeleteContact = false;
  submitted = false;
  model: any;
  mode = 'view';
  projects: any[] = null;
  allProjects: any[] = [];
  partnerContacts = [];
  allPartnerContacts = [];
  filters: ProjectFilters;
  filterText = '';
  filterContacts = '';
  filterValues:any = [];
  numToLoad = 50;
  showLoadMore = false;
  partnerPreference;
  tab = 'projects';
  redirectFieldSaved = '';

  filter = {
    orderBy: '',
    orderDirection: ''
  };

  // notes
  notes: GroupNote[] = [];
  newNote: GroupNote = null;
  editingNote: GroupNote = null;
  noteHistory: GroupNote[] = null;
  showDifferences: boolean = false;
  noteToDelete: GroupNote = null;
  tagOptions = {};
  subjectOptions = {};
  subjects = [];
  noteFilterValues = [];
  filterNotes = '';

  partnerForm: FormGroup;
  showRedirects = false;
  tagsLabel = {};
  allPartners: any[] = [];
  partners: any [];

  // redirects tab
  linkSecurityTypes = {null : 'None', 'hashing' : 'Hashing', 'server-to-server': 'Server to Server'};
  linkHashingTypes = ['SHA1','SHA256','ED25519'];
  methodTypes = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE', 'HEAD', 'OPTIONS'];
  linkHashKey = null;
  linkHashParam = null;
  originalPartner = null;
  originalRedirectLinkData = null;
  routeToTab = null;

  linkHashCompleteKey
  redirectLinkData = null;

  completeWebhookDetails = null;
  overQuotaWebhookDetails = null;
  terminateWebhookDetails = null;
  qcWebhookDetails = null;

  @ViewChild('tabSet')
  tabSet: TabsetComponent;

  @ViewChild('modalContact')
  modalRef: TemplateRef<any>;

  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;

  @ViewChild('modalNoteHistory')
  modalRefNoteHistory: TemplateRef<any>;

  @ViewChild('modalNoteConfirm')
  modalRefNoteConfirm: TemplateRef<any>;
  
  @ViewChild('modalEditVendor')
  modalRefEditVendor: TemplateRef<any>;
  
  openModalRef: any;

  rowActions = [
    {label: 'Open', type: 'open'}
  ];


  columns:any = [
    { label: 'ID', id: 'projectCode', sortable: true },
    { label: 'Name', id: 'projectName', sortable: true, selectable: true },
    { label: 'Project Manager', id: 'projectManager', sortable: true, filterable: true },
    { label: 'Status', id: 'projectStatus', sortable: true, filterable: true, type: 'status' },
    { label: 'QC', id: 'clientQC', sortable: true, type: 'number' },
    { label: 'QC Rate', id: 'actualQCRate', sortable: true, type: 'number' },
    { label: 'Time Left', id: 'projectEndDate', sortable: true, type: 'date',  autoSave: true },
    { label: '', id: 'actions', type: 'actions-dropdown' }
  ];

  constructor(
    private partnerContactService: PartnerContactService,
    private lookupService: LookupService,
    private partnerService: PartnerService,
    public auth: AuthService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
    public utils: UtilsService,
    private alertify: AlertifyService,
    private filterPipe: FilterPipe,
    private settingsService: SettingsService,
    private modalService: BsModalService,
    private unsavedChangesService: UnsavedchangesGuardService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef) {
    this.routeToTab = this.router.getCurrentNavigation().extras?.state?.tab;
    }

  ngOnInit() {
    this.getPartner();
    this.getPartnerContacts();
    this.getPartnerNotes();
    this.loadProjectListViews();
  }

  ngAfterViewInit() {
    this.selectTab(this.routeToTab ? this.routeToTab : 'projects');
    this.cdr.detectChanges();
  }

  selectTab(type: string) {
    if (type) {
      if (type === 'projects') {
        this.tabSet.tabs[0].active = true;
      }
      else if (type === 'redirects') {
        this.tabSet.tabs[3].active = true;
      }
    }
  }

  getPartner() {
    this.partnerService.GetPartner(this.route.snapshot.params.id, true).subscribe( data => {
      this.partner  = data;
      this.originalPartner = JSON.parse(JSON.stringify(this.partner));
      if (this.partner.linkSecurity == 'null') this.partner.linkSecurity = null 

    }, error => {
       this.alertify.error('Unable to get vendor');
    }, () => {

      this.redirectLinkData = [
        {type: 'complete', heading: 'Complete Redirect', redirectURL: this.partner.completeRedirectURL, stringToHash: null, webhookDetails: null, webhookRawData: null}, 
        {type: 'terminate', heading: 'Terminate Redirect', redirectURL: this.partner.terminateRedirectURL, stringToHash: null, webhookDetails: null, webhookRawData: null}, 
        {type: 'overQuota', heading: 'Overquota Redirect', redirectURL: this.partner.overQuotaRedirectURL, stringToHash: null, webhookDetails: null, webhookRawData: null}, 
        {type: 'qc', heading: 'Quality Control (Auto QC) Redirect', redirectURL: this.partner.qcRedirectURL, stringToHash: null, webhookDetails: null, webhookRawData: null}];

      this.originalRedirectLinkData = JSON.parse(JSON.stringify(this.redirectLinkData));

      // might be able to get rid of the preference code below as we use notes now?
      let queryParams = this.route.snapshot.queryParams;
      if (queryParams && queryParams.preference) {
        this.tab = 'preferences';
      }
      if (!this.partner.partnerPreference) {
         this.enablePreferenceEdit();
      }

      if (this.partner.linkSecurity === 'hashing') this.setUpHashing();
      this.getPartnerNotes();
      this.getPartnerLinkSecurityDetails();
    });
  }

  getPartnerLinkSecurityDetails() {
    if (this.partner.linkSecurity === 'server-to-server') {
      this.getPartnerWebhooks();
    }
  }

  getPartnerWebhooks() {
    this.partnerService.GetPartnerWebhooks(this.partner.id).subscribe(data=> {
      var redirectLinkData = [...this.redirectLinkData];

      var completesWebhook = data.find(x => x.hookEvent === 'SURVEY_COMPLETE') || null;
      var oqWebhook = data.find(x => x.hookEvent === 'SURVEY_OVERQUOTA') || null;
      var termWebhook = data.find(x => x.hookEvent === 'SURVEY_TERM') || null;
      var qcWebhook = data.find(x => x.hookEvent === 'SURVEY_QC') || null;

      redirectLinkData.forEach(redirect => {
        redirect.webhookDetails = this.createBlankWebhookDetails();
        if (redirect.type === 'complete') redirect.webhookRawData = completesWebhook;
        else if (redirect.type === 'overQuota') redirect.webhookRawData = oqWebhook;
        else if (redirect.type === 'terminate') redirect.webhookRawData = termWebhook;
        else if (redirect.type === 'qc') redirect.webhookRawData = qcWebhook; 
        }
      );

      redirectLinkData.forEach(webhook => {
        if (webhook.webhookRawData) {
          webhook.webhookDetails.endpoint = webhook.webhookRawData?.endpoint || null;
          webhook.webhookDetails.method = webhook?.webhookRawData?.method || 'POST';
          // getting body
          if (webhook.webhookRawData?.body != null){
            webhook.webhookRawData.body = JSON.parse(webhook.webhookRawData?.body)
            for (let key of Object.keys(webhook.webhookRawData?.body)) {
              webhook.webhookDetails.body.push({name: key, value: webhook.webhookRawData.body[key]});
            };
          }
          // getting headers
          if (webhook.webhookRawData?.headers != null) {
            var headers = webhook?.webhookRawData?.headers.split(';');
            headers.forEach(header => {
              var parts = header.split(':');
              webhook.webhookDetails.headers.push({name: parts[0], value: parts[1]});
            });
          }
          // getting params
          if (webhook.webhookRawData?.urlParams != null) {
            var urlParams = webhook.webhookRawData?.urlParams;
            if (webhook.webhookRawData?.urlParams.charAt(0) === "?") {
              urlParams = webhook.webhookRawData.urlParams.slice(1)
            };
            var paramsList = urlParams.split('&');
            paramsList.forEach(param => {
              var parts = param.split('=');
              webhook.webhookDetails.parameters.push({name: parts[0], value: parts[1]});
            });
          }
        }
      });

      this.redirectLinkData = redirectLinkData;
      this.originalRedirectLinkData = JSON.parse(JSON.stringify(this.redirectLinkData));
        
    }, error => {
      this.alertify.error('Unable to get vendor webhooks');
    });
  }



  updateContacts() {
    const filter = this.filterContacts.toLowerCase();
    if(filter === ''){
      this.partnerContacts = this.allPartnerContacts;
    }
    else {
      this.partnerContacts = this.allPartnerContacts.filter(e => 
        e.name.toLowerCase().indexOf(filter) >= 0 || 
        e.title.toLowerCase().indexOf(filter) >= 0 ||
        e.fullPhone.toLowerCase().indexOf(filter) >= 0 ||
        e.email.toLowerCase().indexOf(filter) >= 0);
    }
  }

  getPartnerContacts () {
    this.partnerContactService.GetPartnerContacts(this.route.snapshot.params.id).subscribe( data => {
      data.forEach(contact => {
        contact.mode = 'view';
      });
      this.allPartnerContacts = data.map(item => {
        let contact:any = {...item};

        if (contact.lastLogin === '0001-01-01T00:00:00') {         
          contact.lastLogin = '';
        }
        if (!contact.blockLogon) {
          contact.portalAccess = 'Yes';
        } else {
          contact.portalAccess = '';
        }
        contact.name = item.firstName + ' ' + item.lastName;
        if (!contact.title) contact.title = '';
        if (!contact.fullPhone) contact.fullPhone = '';
        return contact;
      });
      this.updateContacts();
    }, error => {
       this.alertify.error('Unable to get contacts');
    });
  }

  enablePreferenceEdit() {
    this.partnerPreference = this.partner.partnerPreference;
    this.mode = 'edit';
  }

  copyAllRedirects() {
    const copyString = '\n' + 'Complete RedirectUrl  = ' + this.partner.completeRedirectURL + '\n' +
                              'Terminate RedirectUrl = ' + this.partner.terminateRedirectURL + '\n' +
                              'Overquota RedirectUrl = ' + this.partner.overQuotaRedirectURL + '\n' +
                              'QC Redirect Url        = ' + this.partner.qcRedirectURL;

    this.utils.copyTextToClipboard(copyString);
    this.alertify.success('URLs copied');
  }


  addHashToUrl(url, type) {
    if (this.partner.hashParameters && url?.trim() != '' && url != null) {
      var status = this.redirectLinkData.find(x => x.type === type);
      var stringToHash = status.stringToHash;
      let urlSeperator = (url.indexOf('?') > -1) ? '&' : '?';
      
      if (stringToHash == null || stringToHash == '') return url;
  
      let hashKey = this.partner.linkHashingKey ? '[' + this.partner.linkHashingKey + ']' : '';
      return url + urlSeperator + this.partner.hashParameters + '=[[' + this.partner.linkHashingType + '][' + stringToHash + ']' + hashKey + ']';
    }
    else return url;
  }

  removeHashString(url) {

    var hashDetails = url.match(/\[\[(.*?)\]\]/g);
    if(hashDetails == null) {
      return url;
    }

    var hashItems = hashDetails[0].replace('[[', '').replace(']]', '').split('][');
   
    if(hashItems.length == 0) return url;

    //save the hash string for the relevent status
    var stringToHash = hashItems[1];

    //remove the hash from the url
    url = url.replace('[' + stringToHash + ']','');
    
    return url;
  }


  savePartnerLinks(inline = false) {
    this.partner.partnerPreference = this.partnerPreference;
    var model = {};
    this.redirectLinkData.forEach(redirect => {
      if (this.partner.linkSecurity === 'hashing' && redirect.stringToHash?.trim() != '' && redirect.stringToHash != null) {
        model[redirect.type+'RedirectURL'] = this.addHashToUrl(redirect.redirectURL, redirect.type);
      }          
      else {
        model[redirect.type+'RedirectURL'] = redirect.redirectURL;
      }
      this.partner[redirect.type+'RedirectURL'] = redirect.redirectURL;    

    });

    model['linkSecurity']= this.partner.linkSecurity;
    model['hashParameters'] = this.partner.hashParameters;
    model['linkHashingType'] = this.partner.linkHashingType;
    model['linkHashingKey'] = this.partner.linkHashingKey;

    this.partnerService.EditPartnerLinks(this.partner.id, model).subscribe( data => {
    }, error => {
       this.alertify.error('Unable to update partner');
       this.getPartner();
    }, () => {
        this.originalPartner = JSON.parse(JSON.stringify(this.partner));
        this.originalRedirectLinkData = JSON.parse(JSON.stringify(this.redirectLinkData));

      if (this.redirectFieldSaved === 'completeRedirectURL') this.alertify.success('Complete URL updated');
      else if (this.redirectFieldSaved === 'terminateRedirectURL') this.alertify.success('Term URL updated');
      else if (this.redirectFieldSaved === 'overQuotaRedirectURL') this.alertify.success('OverQuota URL updated');
      else if (this.redirectFieldSaved === 'qcRedirectURL') this.alertify.success('QC URL updated');
      else if (this.redirectFieldSaved === 'linkSecurityType') this.alertify.success('Link security type updated');
      else if (this.redirectFieldSaved === 'partnerProjectNumber') this.alertify.success('Project number updated');
      else if (this.redirectFieldSaved === 'partnerFullQuota') this.alertify.success('Allocation updated');
      else if (this.redirectFieldSaved === 'partnerSoftQuota') this.alertify.success('Soft Launch updated');
      else if (this.redirectFieldSaved === 'hashType') this.alertify.success('Link hashing type updated');
      else if (this.redirectFieldSaved === 'hashKey') this.alertify.success('Link hashing key updated');
      else if (this.redirectFieldSaved === 'hashParams') this.alertify.success('Hash parameters updated');
      else this.alertify.success('Redirect field updated');
      if (this.partner.linkSecurity === 'hashing')  this.setUpHashing();
    });
    this.mode = 'view';
  }
  cancelPartner() {
    this.mode = 'view';
  }

  loadProjectListViews() {
    this.projectService.GetProjectListViews(null, true).subscribe(data => {
      const listViews = data.filter(v => v.locationType =='vendor');

      if(listViews == null || listViews.length == 0) {
        this.columns = [
          { label: 'ID', id: 'projectCode', sortable: true },
          { label: 'Name', id: 'projectName', sortable: true, selectable: true },
          { label: 'Project Manager', id: 'projectManager', sortable: true, filterable: true },
          { label: 'Status', id: 'projectStatus', sortable: true, filterable: true, type: 'status' },
          { label: 'QC', id: 'clientQC', sortable: true, type: 'number' },
          { label: 'QC Rate', id: 'actualQCRate', sortable: true, type: 'number' },
          { label: 'Time Left', id: 'projectEndDate', sortable: true, type: 'date',  autoSave: true },
          { label: '', id: 'actions', type: 'actions-dropdown'  }
        ];
      } else {
        this.columns = listViews[0].tableColumns;

        this.columns.forEach(col => {
          col.id = col.field;
        });
      }
      this.getProjects();
    }, error => {
    });
  }

  updateProjects() {
    const filter = this.filterText.toLowerCase();
    if(filter === ''){
      this.projects = this.allProjects;
    }
    else {
      this.projects = this.allProjects.filter(e => e.projectName.toLowerCase().indexOf(filter) >= 0);
    }
    this.showLoadMore = (this.projects.length > this.numToLoad);
    this.projects = this.projects.slice(0, this.numToLoad);
  }

  getProjects() {
    this.filters = {
      status : [],
      clientID : [],
      partnerID : [this.route.snapshot.params.id],
      freeText : '',
      isMine : false,
      archivedOnly: false,
      type: ''
    }

    this.projectService.GetProjects(this.filters).subscribe( data => {
      this.projects = this.projectService.EnrichProjects(data);
      this.allProjects = this.projects;
    }, error => {
      this.alertify.error('Unable to get projects');
    });
  }

  handleAction(action) {
    if (action.type === 'open') {
      this.router.navigateByUrl('/projects/' + action.row.id);
    }
  }

  createContactForm() {
    this.contactForm = new FormGroup({
      id: new FormControl(null),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      companyPosition: new FormControl(''),
      profilePhoto: new FormControl(''),
      phone: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.email]),
      newemail: new FormControl(''),
      partnerId: new FormControl(this.partner.id)
    });
  }

  contactAdd() {
    this.model = {};
    this.createContactForm();
    this.unsavedChangesService.register(
      this.contactForm,
      (form) => form.dirty
    );
    this.openModalRef = this.modalService.show(this.modalRef, {class: 'nav-modal-style'});
  }

  contactEdit(contact) {
    this.model = contact;
    this.bindContactForm();
    this.openModalRef = this.modalService.show(this.modalRef, {class: 'nav-modal-style'});
  }

  contactDelete(contact) {
    this.model = contact;
    this.openModalRef = this.modalService.show(this.modalRefConfirm, {class: 'nav-modal-style'});
  }

  uploadCallback(event) {
    this.model.profilePhoto = event.file;
    this.contactForm.controls['profilePhoto'].setValue(event.file);
  }

  closeModal() {
    this.unsavedChangesService.deregister([this.contactForm]);
    this.openModalRef.hide()
  }

  bindContactForm() {
    if (this.model) {
        this.contactForm = new FormGroup({
          id: new FormControl(this.model.id),
          firstName: new FormControl(this.model.firstName),
          lastName: new FormControl(this.model.lastName),
          companyPosition: new FormControl(this.model.title),
          phone: new FormControl(this.model.fullPhone),
          email: new FormControl({value: this.model.email, disabled: !(this.auth.isAdmin()) } ),
          newemail: new FormControl(this.model.email),
          profilePhoto: new FormControl(this.model.profilePhoto),
          partnerId: new FormControl(this.partner.id)
      });
    }
  }

  confirmDelete() {
    this.partnerContactService.DeletePartnerContact(this.model).subscribe(data => {
      if (data) {
        this.alertify.success('Contact deleted successfully');
        this.model = {};
        this.closeModal();
        this.getPartnerContacts();
      }
    }, error => {
       this.alertify.error('Unable to delete contact');
    });
  }
  onBlurEmail(){
    const email = this.contactForm.get('email');
    const trimmedEmail = email?.value.trim();  // Trim the value
    email?.setValue(trimmedEmail);
    const newemail = this.contactForm.get('newemail');
    const trimmednewemail = newemail?.value.trim();  // Trim the value
    newemail?.setValue(trimmednewemail);
  }
  contactSave() {
    this.submitted = true;
    if (!this.contactForm.valid) {
      return;
    }

    if (!this.contactForm.value.id) {
      this.contactForm.controls['id'].setValue(uuidv4());
      this.partnerContactService.AddPartnerContact(this.contactForm.value).subscribe(data => {
        if (data) {
          this.alertify.success('Contact added successfully');
          this.model = {};
          this.closeModal();
          this.getPartnerContacts();
          this.createContactForm();
        }
      }, error => {
         this.alertify.error('Unable to add contact');
      });

    } else {
      this.partnerContactService.EditPartnerContact(this.contactForm.value).subscribe(data => {
        if (data) {
          this.alertify.success('Contact edited successfully');
          this.model = {};
          this.closeModal();
          this.getPartnerContacts();
          this.createContactForm();
        }
      }, error => {
        if(error == 'EMAIL_EXISTS'){
          this.alertify.error('User already exists please try again');
        }
        else {
          this.alertify.error('Unable to edit contact');
        }
         
      });
    }
  }

  SortBy(field) {
    if (field == this.filter.orderBy) {
      this.filter.orderDirection = (this.filter.orderDirection == 'ASC') ? 'DESC' : 'ASC'
    } else {
      this.filter.orderBy = field;
      this.filter.orderDirection = 'ASC';
    }
    this.sort(this.partnerContacts, this.filter.orderBy, this.filter.orderDirection);
  }

  sort(array, type, direction) {
    array.sort((a, b) => {
      var A;
      var B;
      if (type == 'firstName') {
        A = a.firstName;
        B = b.firstName;
      }

      if (type == 'lastName') {
        A = a.lastName;
        B = b.lastName;
      }
      else if (type == 'phone') {
        A = a.phone;
        B = b.phone;
      }
      else if (type == 'email') {
        A = a.email;
        B = b.email;
      }

      let comparison = 0;
      if (direction === 'ASC') {
        if (A > B) {
          comparison = 1;
        } else if (A < B) {
          comparison = -1;
        }
        return comparison;
      }
      else {
        if (A < B) {
          comparison = 1;
        } else if (A > B) {
          comparison = -1;
        }
        return comparison;
      }

    });
    return array;
  }

  // bindForm() {
  //   if (this.partner) {
  //     this.partnerForm = new FormGroup({
  //       id: new FormControl(this.partner.id),
  //       name: new FormControl(this.partner.name),
  //       website: new FormControl(this.partner.website),
  //       clientPreference: new FormControl(this.partner.partnerPreference),
  //       img: new FormControl(this.partner.img),
  //       instanceId: new FormControl(this.partner.instanceId)
  //     });
  //   }
  // }


  getPartnerNotes() {

    forkJoin([
      this.partnerService.GetPartnerNotes(this.route.snapshot.params.id),
      this.lookupService.GetLookupTable('vendor-note-tag'),
      this.lookupService.GetLookupTable('vendor-note-subject')
    ]).subscribe( data => {
      this.noteFilterValues = [];
      var tagFilters = [];
      var subjectFilters = [];
      var memberFilters = [];
      // set up tag dictionary
      if (data[1].length > 0) {
        data[1].forEach(tag => {
          this.tagOptions[tag.id] = tag.value;
        });
      }

      if (data[2].length >0) {
        var subjects = [];
        this.subjectOptions = {};
        data[2].forEach(subject => {
          this.subjectOptions[subject.id] = subject.value;
          subjects.push({id: subject.id, name: subject.value})
        })
        this.subjects = subjects;
      }

      // set up notes
      var allNotes = [];
      if (data[0].length > 0) {
        
        var latest = data[0].filter(x => x.isLatest)
        latest.sort(function(a,b){
          return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
        });

        latest.forEach(note => {
          note.tagIds = (note.tags.map(tag => tag)).join(',');
          note.createdOn = new Date(note.createdOn);
          if (note.originalNoteId) {
            note.previousVersions = data[0].filter(x => x.id != note.id && (x.originalNoteId == note.originalNoteId || x.id == note.originalNoteId))
            note.previousVersions.sort(function(a,b){
              return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
            });

            note.previousVersions.forEach(prevNote => {
              prevNote.tagIds = (prevNote.tags.map(tag => tag)).join(',');
            });
          }

          if (note.subject.trim() != '' && !subjectFilters.find(x => x.group === 'Subjects' && x.value === note.subject)) {
            subjectFilters.push({ label: this.subjectOptions[note.subject], value: note.subject, group: 'Subjects', selected: false, });
          }
          subjectFilters.sort((a, b) => a.label.localeCompare(b.label));

          if (note.body != '' && note.body != null) {
            note.hasExpand = true;
          }
          note.expand = false;
          allNotes.push(note);
        });
        this.partner.allNotes = allNotes;
        this.noteFilterValues = subjectFilters
        
      }
      this.notes = this.partner.allNotes;
      this.multiLineOverflows() 

    }, error => {
      this.alertify.error('Unable to get vendor Notes');
    }, () => {

    })
  }

  updateNotes(notes) {
    const filter = this.filterNotes.toLowerCase();
    var filteredNotes = [];
    if(filter === ''){
      filteredNotes = notes;
    }
    else {
      filteredNotes = notes.filter(e => e.body.toLowerCase().indexOf(filter) >= 0 || e.title.toLowerCase().indexOf(filter) >= 0);
    }
    this.notes = filteredNotes;
  }

  addNote() {
    this.newNote = {
      id: null,
      subject: '',
      title: '',
      body: '',
      tags: [],
      isLatest: true,
      showTags: false,
      tagIds: ''
    }
  }
  editNote(note: GroupNote) {
    this.editingNote = {
      id: note.id,
      subject: note.subject,
      title: note.title,
      body: note.body,
      tags: note.tags,
      isLatest: note.isLatest,
      showTags: note.tags.length > 0,
      tagIds: (note.tags.map(tag => tag)).join(',')
    };
  }

  openDeleteNoteConfirm(note) {
    this.noteToDelete = note;
    this.openModalRef = this.modalService.show(this.modalRefNoteConfirm, {class: 'nav-modal-style modal-dialog-centered'});
  }

  deleteNote() {
    this.partnerService.DeletePartnerNote(this.partner.id, this.noteToDelete).subscribe(data => {

    }, error => {
       this.alertify.error('Unable to delete note');
    }, () => {
      this.alertify.success('Note deleted successfully');
      this.noteToDelete = null;
      this.closeModal();
      this.getPartnerNotes();
    });
  }

  cancelNewNote() {
    this.newNote = null;
  }
  cancelEditNote() {
    this.editingNote = null;
  }

  saveNote() {
    this.partnerService.SavePartnerNote(this.partner.id, this.editingNote).subscribe(result => {
      this.editingNote = null;
      this.getPartnerNotes();
    }, error => {
      this.alertify.error('Unable to save Note');
    }, () => {
      this.alertify.success('Vendor Note saved successfully');
    })
  }

  openNoteHistory(note) {
    if (note.previousVersions.length > 0) {
      this.showDifferences = false;
      this.noteHistory = [note].concat(note.previousVersions)
      this.openModalRef = this.modalService.show(this.modalRefNoteHistory, {class: 'nav-modal-style modal-xl modal-dialog-scrollable'});
    }
  }

  addTags(note, tags) {
    var t = [];
    tags.forEach(tag => {
      t.push(tag.id);
    });
    note.tags = t
  }

  applySubject(item, note) {
    if (item != null) {
      note.subject = item.id;
    }
  }

  createSubject(subject) {
    var newSubject: LookupTable = {
      id: uuidv4(),
      type: 'vendor-note-subject',
      value: subject,
      suggest: true
    }

    this.lookupService.InsertLookupValue('vendor-note-subject', newSubject).subscribe((data) => {
    }, error => {
      this.alertify.error("Couldn't create a new subject")
    }, () => {
      this.subjects.push({id: newSubject.id, name: newSubject.value});
      this.subjects = [...this.subjects] // triggers change in child component
    })
  }

  createNewNote() {
      this.partnerService.CreatePartnerNote(this.partner.id, this.newNote).subscribe(result => {
      this.newNote = null;
      this.getPartnerNotes();
    }, error => {
      this.alertify.error('Unable to create Note');
    }, () => {
      this.alertify.success('Vendor Note created successfully');
    });
  }

  applyFilterNotes() {
    var notes = this.partner.allNotes;

    const subjects = this.noteFilterValues.filter(x => x.selected).map(x => x.value);

    if (subjects.length > 0) {
      notes = notes.filter(x => subjects.includes(x.subject))
    }

    this.updateNotes(notes);
  }

  exportNoteHistory(notes) {
    let csvContent = '';
    var noteWithMostTags = notes.reduce((max, obj) => (obj.tags.length > max.tags.length ? obj : max), notes[0]);
    var tagColumns = '';
    var i = 1;
    noteWithMostTags.tags.forEach(tag => {
      tagColumns += ('Tag '+ i.toString() + ',')
      i ++;
    });
    // Colum headers
    csvContent = 'Date,User,Title,Subject,Body,' + tagColumns + '\n\r'

    // Data
    const items = notes;
    items.forEach( (row: any) => {

      csvContent += new Date(row.createdOn) + ','
      csvContent += row.createdByName  + ',';

      csvContent += ((row.title ?? '') + ',')
      csvContent += ((this.subjectOptions[row.subject] ?? '') + ',')
      csvContent += this.formatHTMLForCSV(row.body)+ ','
      for (let key in this.tagOptions) {
        if (row.tags.includes(key)) csvContent += (this.tagOptions[key] + ',')
      }
      csvContent += '\n';
    });

    this.utils.generateXLSX(csvContent, this.partner.name+'-note-history.csv');
  }

  exportNotes(notes) {
    let csvContent = '';
    var noteWithMostTags = notes.reduce((max, obj) => (obj.tags.length > max.tags.length ? obj : max), notes[0]);
    var tagColumns = '';
    var i = 1;
    noteWithMostTags.tags.forEach(tag => {
      tagColumns += ('Tag '+ i.toString() + ',')
      i ++;
    });
    // Colum headers
    csvContent = 'Date,User,Last Updated,Updated By,Title,Subject,Body,' + tagColumns + '\n\r'

    // Data
    const items = notes;
    items.forEach( (row: any) => {
      if (row.previousVersions?.length > 0 ) {
        var firstVersion = row.previousVersions.find(x => !x.originalNoteId);
        csvContent += new Date(firstVersion.createdOn) + ',';
        csvContent += firstVersion.createdByName + ',';
      }
      csvContent += new Date(row.createdOn) + ','
      csvContent += row.createdByName  + ',';

      if (!row.previousVersions ){
        csvContent += ',,'
      }
      csvContent += ((row.title ?? '') + ',')
      csvContent += ((this.subjectOptions[row.subject] ?? '')  + ',')
      csvContent += this.formatHTMLForCSV(row.body)+ ','

      for (let key in this.tagOptions) {
        if (row.tags.includes(key)) csvContent += (this.tagOptions[key] + ',')
      }

      csvContent += '\n';
    });

    this.utils.generateXLSX(csvContent, this.partner.name+'-notes.csv');
  }

  private formatHTMLForCSV(htmlText: string): string {
    // Remove line breaks and tabs
    const formattedText = htmlText.replace(/\n/g, ' ').replace(/\r/g, ' ').replace(/\t/g, ' ');

    // Remove double quotes and escape any existing double quotes with double double quotes
    const escapedText = formattedText.replace(/"/g, '""');
    const temp = document.createElement('div');
    temp.innerHTML = escapedText;
    var result = temp.textContent || temp.innerText || ''

    return `"${result}"`; // Wrap the text in double quotes to preserve spaces and special characters in the CSV
  }

  multiLineOverflows() {

    setTimeout(() => {
      const elements = document.getElementsByClassName('note-body');

      for (let i = 0; i < elements.length; i++) {
        var el = elements.item(i)
        var noteId = el.getAttribute('id')

        if (el.scrollHeight != 0 && el.clientHeight != 0) {
          if (el.scrollHeight <= el.clientHeight) {
            var note = this.notes.find(x => x.id === noteId);
            note.hasExpand = false;
          }
        }
      };
    }, 100);
  }

  createPartnerForm() {
    this.partnerForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      instanceId: new FormControl(this.auth.getUser().instanceId),
      img: new FormControl(''),
      website: new FormControl(''),
      address1: new FormControl(''),
      address2: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      zip: new FormControl(''),
      country: new FormControl(''),
      connectionId: new FormControl(null),
      userDomain: new FormControl(null),
      linkSecurity: new FormControl(null),
      completeRedirectURL: new FormControl(null),
      terminateRedirectURL: new FormControl(null),
      overQuotaRedirectURL: new FormControl(null),
      qcRedirectURL: new FormControl(null),
      audienceType: new FormControl([]),
      regions: new FormControl([]),
      tags: new FormControl([]),
    });
  }

  partnerEdit(partner) {
    this.partnerService.GetPartner(partner.id, true).subscribe(data => {
      if (data) {
    
        this.model = { ...data };
        this.model.audienceTypeIds = data.audienceType.map(item => item).join(',');
        this.model.regionIds = data.regions.map(item => item).join(',');
        this.model.tagIds = data.tags.map(item => item).join(',');

        this.bindForm();
        this.openModalRef = this.modalService.show(this.modalRefEditVendor, {class: 'nav-modal-style modal-md modal-partners'});
      }
    }, error => {
      this.alertify.error('Unable to get partner');
    });
  }


  removeHashFromUrl(url, type) {

    var status = this.redirectLinkData.find(x => x.type === type);
    var hashDetailsFull;
    if (this.partner.hashParameters == null || this.partner.hashParameters == '') hashDetailsFull = url.match(/[\?&][^=]+?=((\[\[.*?]]))/g);
    else hashDetailsFull = url.match(new RegExp('[?&]' + this.partner.hashParameters + '=((.*?]]))', 'g'));

    // change that regex expression to have a ? or & at the start of the match


    if (hashDetailsFull == null || hashDetailsFull.length == 0) return url;

    var hashDetailsList = []
    hashDetailsFull.forEach(details => {
      var pairs = details.split('=');
      var fullValue = '' // in case theres more '=' in the url
      var index= 0;
      pairs.forEach( v => {
        if (index > 0) {
          if (index > 1 && index+1 % 2 != 0) fullValue += '=';
          fullValue += v;
        }
        index++;
      });
      hashDetailsList.push({param: pairs[0].slice(1), value: fullValue, items: []});
    });
    if(hashDetailsList.length == 0) return url;

    hashDetailsList.forEach(details => {
      details.items = details.value.replace('[[', '').replace(']]', '').split('][');
    });

    var matchedDetail;

    if (type === 'complete' && !this.partner.hashParameters && 
    (!this.partner.linkHashingType || this.partner.linkHashingType == 'SHA1') &&
    ((this.partner.linkHashingKey && hashDetailsList[0].items.length <= 2) || 
    (!this.partner.linkHashingKey && hashDetailsList[0].items.length > 2) ||
     ((this.partner.linkHashingKey || null) == (hashDetailsList[0].items[2] || null)))) {

      this.partner.hashParameters = hashDetailsList[0].param
      this.partner.linkHashingType = this.linkHashingTypes.includes(hashDetailsList[0].items[0].toUpperCase()) ? hashDetailsList[0].items[0].toUpperCase() : 'SHA1';
      this.partner.linkHashingKey = this.partner.linkHashingKey ? this.partner.linkHashingKey : (hashDetailsList[0].items[2] || null);
      matchedDetail = hashDetailsList[0];
    }
    else {
      hashDetailsList = hashDetailsList.filter(x => x.param == this.partner.hashParameters);
      hashDetailsList.forEach(detail => {
        if (detail.items.length > 0) {
          if (detail.items[0].toUpperCase() == this.partner.linkHashingType && (this.partner.linkHashingKey ? detail.items[2] == this.partner.linkHashingKey : !detail.items[2])) {
            //matchedDetail = hashDetailsList.indexOf(detail);
            matchedDetail = detail;
          }
        }
      });
      if(matchedDetail == null) return url;
      matchedDetail.items[0] = matchedDetail.items[0] || null;
      matchedDetail.items[1] = matchedDetail.items[1] || null;
    }
    
   


    status.stringToHash = matchedDetail.items[1];
    var stringToReplace = this.partner.hashParameters + '=' + matchedDetail.value
    if (url.includes(stringToReplace + '&')) {
      url = url.replace(stringToReplace + '&','');
    }
    else url = url.replace(stringToReplace,'');
    
    //remove the trailing & or ? from the url
    if(url[url.length - 1] == '&' || url[url.length - 1] == '?')
      url = url.slice(0, -1);

    return url;
  }

  setUpHashing() {
    if (this.partner.linkHashingType == null) this.partner.linkHashingType = 'SHA1';

    this.redirectLinkData.forEach(redirect => {
      if (this.redirectFieldSaved === 'linkSecurityType') redirect.stringToHash = null;
      redirect.redirectURL = this.removeHashFromUrl(this.partner[redirect.type+'RedirectURL'], redirect.type);
    });
  }

  changeHashType() {
    if (this.originalPartner.linkHashingType == this.partner.linkHashingType) return;
    this.redirectFieldSaved = 'hashType';

    this.savePartnerLinks(true);
  }

  bindForm() {
    if (this.model) {
      this.partnerForm = new FormGroup({
        id: new FormControl(this.model.id),
        name: new FormControl(this.model.name, [Validators.required]),
        img: new FormControl(this.model.img),
        website: new FormControl(this.model.website),
        address1: new FormControl(this.model.address.address1),
        address2: new FormControl(this.model.address.address2),
        city: new FormControl(this.model.address.city),
        state: new FormControl(this.model.address.state),
        zip: new FormControl(this.model.address.zip),
        country: new FormControl(this.model.address.country),
        instanceId: new FormControl(this.auth.getUser().instanceId),
        connectionId: new FormControl(null),
        userDomain: new FormControl(null),
        linkSecurity: new FormControl(this.model.linkSecurity),
        completeRedirectURL: new FormControl(this.model.completeRedirectURL),
        terminateRedirectURL: new FormControl(this.model.terminateRedirectURL),
        overQuotaRedirectURL: new FormControl(this.model.overQuotaRedirectURL),
        qcRedirectURL: new FormControl(this.model.qcRedirectURL),
        audienceType: new FormControl([]),
        regions: new FormControl([]),
        tags: new FormControl([]),
      });
    }
  }


  getPartnerFormEvent(partnerDetails) {
    var partnerForm = partnerDetails.form;
    var goToRedirects = partnerDetails.goToRedirects;
    if (!partnerForm.value.id) {
      this.partnerService.AddPartner(partnerForm.value).subscribe(data => {
        if (data) {
          this.alertify.success('Vendor added successfully');
          this.model = {};
          this.closeModal();
          this.getPartner();
          this.createPartnerForm();
          if (goToRedirects) this.selectTab('redirects');
        }
      }, error => {
        this.alertify.error('Unable to add vendor');
      });

    } else {
      this.partnerService.EditPartner(partnerForm.value, false).subscribe(data => {
        var ogPartner = JSON.parse(JSON.stringify(this.partner));
        if (data) {
          this.alertify.success('Vendor edited successfully');
          this.model = {};
          this.closeModal();
          this.partner = data;
          this.partner.linkHashingKey = ogPartner.linkHashingKey;
          this.partner.linkHashingType = ogPartner.linkHashingType;
          this.partner.hashParameters = ogPartner.hashParameters;
          if (goToRedirects) this.selectTab('redirects');

        }
      }, error => {
        this.alertify.error('Unable to edit vendor');
      });
    }
  }

  reloadPage() {
    setTimeout(() => {
      location.reload();
    }, 500);
  }

  // for vendor redirects and security 
  onKeyPress(event) {
    var keyEvent = event as KeyboardEvent
    if (keyEvent.key === "Enter") {
      event.preventDefault();
      event.target.blur(); // blur triggers save
    }
  }

  onChange(type) {
    this.redirectFieldSaved = type;
    if (type === 'linkSecurityType') {
      if (this.partner.linkSecurity == 'null') this.partner.linkSecurity = null 
      else if (this.partner.linkSecurity === 'server-to-server') this.getPartnerWebhooks();
      else if (this.partner.linkSecurity === 'hashing') this.setUpHashing();
    } 
    this.savePartnerLinks(true);
  }

  createBlankWebhookDetails() {
    const webhookDetails = {
      method: 'POST',
      endpoint: null,
      parameters : [],
      headers : [],
      body: []
    }
    return webhookDetails;
  }

  
  onBlur(changeType:string, type, index = null) {
    this.redirectFieldSaved = changeType;
    var origRedirect = this.originalRedirectLinkData.find(x => x.type === type);
    var currentRedirect = this.redirectLinkData.find(x => x.type === type);
    setTimeout(() => {
      if (['hashString', 'hashParams', 'hashKey', 'completeRedirectURL', 'terminateRedirectURL', 'overQuotaRedirectURL','qcRedirectURL'].includes(changeType)) {
        if ((changeType === 'hashParams' && this.originalPartner.hashParameters != this.partner.hashParameters) ||
          (changeType === 'hashKey' && this.originalPartner.linkHashingKey != this.partner.linkHashingKey) ||
          (changeType === 'hashString' && origRedirect.stringToHash != currentRedirect.stringToHash) ||
          (changeType === 'completeRedirectURL' && this.partner.linkSecurity != 'hashing' && this.redirectLinkData.find(x=>x.type === 'complete').redirectURL != this.partner.completeRedirectURL) ||
          (changeType === 'completeRedirectURL' && this.partner.linkSecurity == 'hashing' && origRedirect.redirectURL != currentRedirect.redirectURL) ||
          (changeType === 'terminateRedirectURL' && this.partner.linkSecurity != 'hashing' && this.redirectLinkData.find(x=>x.type === 'terminate').redirectURL!= this.partner.terminateRedirectURL) || 
          (changeType === 'terminateRedirectURL' && this.partner.linkSecurity == 'hashing' && origRedirect.redirectURL!= currentRedirect.redirectURL) || 
          (changeType === 'overQuotaRedirectURL' && this.partner.linkSecurity != 'hashing' && this.redirectLinkData.find(x=>x.type === 'overQuota').redirectURL != this.partner.overQuotaRedirectURL) ||
          (changeType === 'overQuotaRedirectURL' && this.partner.linkSecurity == 'hashing' && origRedirect.redirectURL != currentRedirect.redirectURL) ||
          (changeType === 'qcRedirectURL' && this.partner.linkSecurity != 'hashing' && this.redirectLinkData.find(x=>x.type === 'qc').redirectURL != this.partner.qcRedirectURL) ||
          (changeType === 'qcRedirectURL' && this.partner.linkSecurity == 'hashing' && origRedirect.redirectURL != currentRedirect.redirectURL)) {
            this.savePartnerLinks(true);
          }
      }
      else if (type) {
        if ((changeType === 'webhookMethod') ||
          (changeType === 'webhookEndpoint' && origRedirect.webhookDetails.endpoint != currentRedirect.webhookDetails.endpoint) ||
          (changeType === 'parameterName' && origRedirect.webhookDetails.parameters[index]?.name != currentRedirect.webhookDetails.parameters[index]?.name) ||
          (changeType === 'headerName' && origRedirect.webhookDetails.headers[index]?.name != currentRedirect.webhookDetails.headers[index]?.name) ||
          (changeType === 'bodyName' && origRedirect.webhookDetails.body[index]?.name != currentRedirect.webhookDetails.body[index]?.name) ||
          (changeType === 'parameterValue' && origRedirect.webhookDetails.parameters[index]?.value != currentRedirect.webhookDetails.parameters[index]?.value) ||
          (changeType === 'headerValue' && origRedirect.webhookDetails.headers[index]?.value != currentRedirect.webhookDetails.headers[index]?.value) ||
          (changeType === 'bodyValue' && origRedirect.webhookDetails.body[index]?.value != currentRedirect.webhookDetails.body[index]?.value)) {

            this.saveWebhookDetails(type);
          }
      }
    }, 200);
  }

  copyLinks(vendor, type, field = null, index = null) {
    let copyString = '';
    var message = 'Link copied to clipboard';
    if (field == null) {
      if (type === 'complete') copyString =  vendor.completeRedirectURL;
      else if (type === 'terminate') copyString =  vendor.terminateRedirectURL;
      else if (type === 'overQuota') copyString = vendor.overQuotaRedirectURL;
      else if (type === 'qc') copyString =  vendor.qcRedirectURL;
      else if (type === 'hashKey') copyString = vendor.linkHashingKey;
      else if (type === 'hashParams') copyString = vendor.hashParameters;
    }
    else {
      var webhookDetails = this.redirectLinkData.find(x => x.type === type).webhookDetails;
      if (index == null) {
        message = 'Endpoint copied to clipboard'
        if (field === 'endpoint') copyString = webhookDetails.endpoint;
      }
      else if (field != null && index != null) {
        message = 'Copied to clipboard'
        if (field === 'parameter-name') copyString = webhookDetails.parameters[index].name;
        else if (field === 'header-name') copyString = webhookDetails.headers[index].name;
        else if (field === 'body-name') copyString = webhookDetails.body[index].name;
        else if (field === 'parameter-value') copyString = webhookDetails.parameters[index].value;
        else if (field === 'header-value') copyString = webhookDetails.headers[index].value;
        else if (field === 'body-value') copyString = webhookDetails.body[index].value;
      }
    }
    
    this.utils.copyTextToBuffer(copyString);
    this.alertify.success(message);
  }

  addBlankField(array) {
    array.push({name: '', value: ''});
  }

  removeByIndex(type, array, index) {
    if (array) {
      array.splice(index, 1);
      this.saveWebhookDetails(type)
    }
  }

  saveWebhookDetails(type) {
    var webhookDetails = this.redirectLinkData.find(x => x.type === type).webhookDetails;
    var originalRawData = this.redirectLinkData.find(x => x.type === type).webhookRawData;
    var webhookRawData: Webhook = {
      id: '00000000-0000-0000-0000-000000000000',
      vendorId : this.partner.id,
      instanceId: '00000000-0000-0000-0000-000000000000',
      method: null,
      endpoint: null,
      hookEvent: null,
      urlParams: null,
      headers: null,
      body: null,
    };
    if (originalRawData != null) {
      webhookRawData.id = originalRawData.id;
    }
    webhookRawData.endpoint = webhookDetails.endpoint;
    webhookRawData.method = webhookDetails.method;
    if (type === 'complete') webhookRawData.hookEvent = 'SURVEY_COMPLETE';
    else if (type === 'terminate') webhookRawData.hookEvent = 'SURVEY_TERM';
    else if (type === 'overQuota') webhookRawData.hookEvent = 'SURVEY_OVERQUOTA';
    else if (type === 'qc') webhookRawData.hookEvent = 'SURVEY_QC';
    
    // creating urlParams
    var paramString = '';
    if (webhookDetails.parameters.length > 0) paramString += '?'
    for (let i = 0; i < webhookDetails.parameters.length; i++) {
      var param = webhookDetails.parameters[i];
      if (param.name==null) param.name = '';
      if (param.value==null) param.value = '';
      paramString += param.name + '=' + param.value;
      if (i != webhookDetails.parameters.length - 1) paramString += '&';
    }
    webhookRawData.urlParams = paramString;

    // creating headers
    var headerString = '';
    for (let i = 0; i < webhookDetails.headers.length; i++) {
      var header = webhookDetails.headers[i];
      if (header.name==null) header.name = '';
      if (header.value==null) header.value = '';
      headerString += header.name + ':' + header.value;
      if (i != webhookDetails.headers.length - 1) headerString += ';';
    }
    webhookRawData.headers = headerString;
    
    // creating body
    var bodyObject = {};
    for (let i = 0; i < webhookDetails.body.length; i++) {
      var body = webhookDetails.body[i];
      bodyObject[body.name] = body.value;
    }
    webhookRawData.body = JSON.stringify(bodyObject);

    // saving
    this.partnerService.SavePartnerWebhook(this.partner.id, webhookRawData).subscribe(newRawData => {
    this.redirectLinkData.find(x => x.type === type).webhookRawData = newRawData;
    this.originalRedirectLinkData = JSON.parse(JSON.stringify(this.redirectLinkData));
    this.alertify.success('Webhook field updated');
    }, error => {
      this.getPartnerWebhooks();
      this.alertify.error('Unable to save webhook');
    }, () => {
    });
  }

  orderByRowNum = (a,b) => {
    return a
  } 
}

