import { AfterViewInit, Component, Input, OnInit, } from '@angular/core';
import { AuthService, AlertifyService, environment, InstanceService, UserService, ProjectDataService, ScheduleService, RulesService } from 'core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FilterPipe } from 'projects/core/src/helpers/filter.pipe';
import { forkJoin } from 'rxjs';
import { NotesService } from 'projects/core/src/services/note.service';
import { PendoService } from 'projects/core/src/services/pendo.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [FilterPipe]
})
export class SidebarComponent implements OnInit, AfterViewInit {

  @Input() public set isSurveyTester(data) {
    this._isSurveyTester = data;

    if (data) {
      this.mode = 'reduced';
    }

    this.isSuperAdmin = this.auth.isSuperAdmin();
    this.isAbleToImpersonate = this.auth.isAbleToImpersonate();
    this.isAdmin = this.auth.isAdmin();
    this.isInternal = this.auth.isInternal();
    this.isGuest = this.auth.isGuest();
    this.path = this.location.path();
    this.setLogo();

    if (!this.isGuest)
      this.getSiteNavigation();
  };

  _isSurveyTester = false;
  _project: any = {};
  _projectType: any = {};
  projectOpen = false;
  projectSummaryOpen = false;
  filterColumns = ['name', 'email'];
  filterText: string;
  isSuperAdmin = false;
  isAbleToImpersonate = false;
  isAdmin = false;
  isInternal = false;
  isGuest = false;
  impersonateUsers: any[] = [];
  tempimpersonateUsers: any[] = [];
  adminConsoleUrl = environment.adminSiteUrl;
  siteSections = [];
  isProjectSectionOpen = false;
  sidebarProject = '';
  openModalRef: BsModalRef;
  defaultSectionUrl = '';
  path = ''
  mode = 'expanded';
  previousMode = 'expanded';
  logoSrc = '';
  _bidNumber = '';
  notes = null;
  tasks = null;
  rules = null;
  showHelp = false;
  canSubmitIdea = false;

  siteSectionsLinks = [
    { type: 'ProjectList', url: '/projects' },
    { type: 'Team', url: '/team' },
    { type: 'Vendors', url: '/vendors' },
    { type: 'Clients', url: '/clients' },
    { type: 'ReportsCleanID', url: '/reports/cleanid/api' },
    { type: 'ReportsCleanIDNav', url: '/reports/cleanid/nav' },
    { type: 'TaskList', url: '/schedule' },
    { type: 'ReportsPerformance', url: '/reports/performance' },
    { type: 'Licensee', url: '/reports/licensee' },
    { type: 'ReportsAudit', url: '/reports/audit' },
    { type: 'ReportsReportBuilder', url: '/reports/builder' },
    { type: 'ReportsWorkload', url: '/reports/workload' },
    { type: 'ReportsClient', url: '/reports/client/invoice' },
    { type: 'ReportsClientColl', url: '/reports/client/coll' },
    { type: 'ReportsVendor', url: '/reports/vendor' },
    { type: 'ReportsResponseID', url: '/reports/responseid' },
    { type: 'ReportsResponseIDApi', url: '/reports/responseid/api' },
    { type: 'ReportsResponseIDNav', url: '/reports/responseid/nav' },
    { type: 'Dashboard', url: '/dashboard' },
    { type: 'Home', url: '/home' },
    { type: 'HomeManager', url: '/home/manager' },
    { type: 'Account', url: '/account' }
  ];

  layout = [
    { label: 'Instance', id: 'instanceName', sortable: true },
    { label: 'Name', id: 'name', selectable: true, sortable: true },
    { label: 'Email', id: 'email', sortable: true },
    { label: 'User Type', id: 'userType', sortable: true }
  ];

  constructor(
    public auth: AuthService,
    private alertify: AlertifyService,
    private instanceService: InstanceService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private userService: UserService,
    private notesService: NotesService,
    private scheduleService: ScheduleService,
    private rulesService: RulesService,
    private filterPipe: FilterPipe,
    private modalService: BsModalService,
    private projectDataService: ProjectDataService,
    private pendoService: PendoService) { }

  ngOnInit() {
    //subscribe to any change on current (opened) project
    this.projectDataService.getProject.subscribe(data => {
      this._project = data?.project;
      this._projectType = data?.projectType;
      this._bidNumber = data?.bidNumber ?? '';
      this.projectOpen = data?.projectOpen || false;
      this.projectSummaryOpen = data?.projectSummaryOpen || false;
      this.isProjectSectionOpen = data?.sidebarSectionOpen || false;

      if (this.isProjectSectionOpen !== undefined && !this.isProjectSectionOpen) {
        this.mode = this.previousMode;
        this.projectDataService.toggleSidebar(this.previousMode);
      } else if (this.isProjectSectionOpen && (this.projectOpen || this.projectSummaryOpen)) {
        this.mode = 'reduced';
        this.projectDataService.toggleSidebar(this.mode);
      }

      this.setLogo();
      if (this._bidNumber != '' && this.auth.isInternal()) this.getInfoForSummary();
    });
  }

  isVendorview: boolean = false;
  isClientview: boolean = false;
  ngAfterViewInit() {
    setTimeout(() => {

      if (window['pendo'] != null) {
        const user = this.auth.getUser();
        const sectionNames = [...new Set(this.siteSections.filter(e => e.type != null).map(e => e.type))];
        if (user != null) {
          this.pendoService.init(user.id, user.email, user.firstName + ' ' + user.lastName, user.instanceId, user.instanceName, user.roles, sectionNames);
        }
      }

      this.projectDataService.getVendorViewSidebar.subscribe(data => {
        if (data) {
          this.isVendorview = data != '';
        }
      });

      this.projectDataService.getClientViewSidebar.subscribe(data => {
        if (data) {
          this.isClientview = data != '';
        }
      });


    }, 500 * 1);



  }

  toggleSidebar() {
    this.mode = this.mode == 'reduced' ? 'expanded' : 'reduced';
    this.previousMode = this.mode;
    this.setLogo();
    this.projectDataService.toggleSidebar(this.mode);
  }

  setLogo() {
    this.logoSrc = this.mode == 'reduced'
      ? 'assets/imgs/logo-sm.png'
      : (this.auth.getUser().logo !== null
        ? this.auth.getUser().logo
        : 'assets/imgs/logo.jpeg');
  }

  changeProjectSidebar(section) {
    // send event to change navbar
    this.mode = 'reduced';
    this.sidebarProject = section;
    this.projectDataService.setCurrentProject({
      project: this._project,
      projectType: this._projectType,
      sidebarProject: section,
      projectOpen: this.projectOpen,
      projectSummaryOpen: this.projectSummaryOpen || false
    });

    this.projectDataService.toggleSidebar(this.mode);
  }

  updateimpersonateUsersList() {
    let users;
    this.impersonateUsers = this.tempimpersonateUsers;
    users = this.filterPipe.transform(this.impersonateUsers, this.filterText, this.filterColumns);
    if (users.count != 0) this.impersonateUsers = users;
    else this.impersonateUsers = this.tempimpersonateUsers;
  }

  loadImpersonateUsers() {
    if (this.isSuperAdmin || this.isAbleToImpersonate) {
      this.impersonateUsers = [{ email: 'Loading... Please wait while we load the list of users', value: '' }];
      this.userService.getImpersonationUsers().subscribe(data => {
        data.forEach(e => {
          if (e.clientName != null && e.clientName !== '') {
            e.userType = 'Client';
            e.companyName = e.clientName
          } else if (e.vendorName != null && e.vendorName !== '') {
            e.userType = 'Vendor';
            e.companyName = e.vendorName
          } else e.userType = 'Internal';
        });
        this.impersonateUsers = data;
        this.tempimpersonateUsers = data;
      }, error => { }, () => { });
    }
  }

  getSiteNavigation() {
    this.instanceService.getUserSections().subscribe(data => {
      this.siteSections = data;
    }, error => {
      this.alertify.error('Unable to get Sections');
    }, () => {
      if (this.siteSections.length === 0) {
        this.alertify.message('Site Sections have not been setup');
      } else {
        this.siteSections = this.siteSections.map(item => {
          if (item.type == 'Partners') item.type = 'Vendors';

          let parent = this.siteSections.find(x => x.id == item.parentId);
          let idx = this.siteSections.findIndex(x => x.id == item.parentId);

          if (parent == null || parent == undefined) {
            item.parentId = null;
            item.parentName = '';
          } else {
            item.parentName = parent.label;
          }
          item.icon = (item?.icon.trim() == '' ? null : item.icon);

          if (item.type) {
            item.url = this.siteSectionsLinks.find(x => x.type == item.type)?.url;

            if (item.projectTypeId !== null && item.type === 'ProjectList') {
              item.url = item.url + '/list/' + item.projectTypeId;
            }
            if (item.otherInstanceId !== null && (item.type === 'ReportsResponseIDApi' || item.type === 'ReportsCleanID')) {
              item.url = item.url + '/' + item.otherInstanceId;
            }
            item.icon = (item.icon ? item.icon : 'fak fa-' + (item?.type.toLowerCase()) + ' fa-md');
          } else {
            item.icon = (item.icon ? item.icon : 'fak fa-' + (item?.label.replace(' ', '').toLowerCase()) + ' fa-md');
          }

          return item;
        });

        //Save siteSections to handle breadcrumbs
        localStorage.setItem('sections', JSON.stringify(this.siteSections));
        localStorage.setItem('sectionsLinks', JSON.stringify(this.siteSectionsLinks));
        this.defaultSectionUrl = this.siteSections[0].url;

        if (!this.path || this.path == '') {
          this.router.navigate([this.defaultSectionUrl]);
          return;
        }

        if (this.path.includes('?returnUrl')) {
          const arrReturnUrl = decodeURIComponent(this.path).replace('?', '').split('=');
          if (arrReturnUrl.length > 0 && arrReturnUrl[1] !== null) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate([arrReturnUrl[1]]);
            });
          }
        }
      }
    });
  }

  handleSection(section) {
    section.open = !section.open;
    this.siteSections.filter(sec => sec.id !== section.id).forEach(sec => {
      sec.open = this.isProjectSectionOpen ? true : false;
    });
  }

  openImpersonate(modalTemplate) {
    this.loadImpersonateUsers();
    this.openModalRef = this.modalService.show(modalTemplate, { ignoreBackdropClick: true, keyboard: false, class: 'modal-lg' });
  }

  impersonate(row) {
    this.auth.impersonate(row.email, row.instanceId).subscribe(next => {
    }, error => {
      this.alertify.error(error);
    }, () => {
      this.alertify.success('Your are now impersonating');
      window.location.reload();
    });
  }

  goToAdminConsole(event: Event) {
    event.preventDefault();  // Prevent the default action (page refresh)
    const url = this.adminConsoleUrl + '?token=' + this.auth.getToken();
    window.open(url, '_blank');
  }

  logout() {
    this.auth.logOut();
    this.router.navigate(['/login']);
    this.alertify.message('You are now logged out');
  }

  getInfoForSummary() {
    forkJoin([
      this.notesService.getProjectNotesForSummary(this._bidNumber),
      this.scheduleService.GetFamilySchedule(this._bidNumber),
      this.rulesService.GetRulesSummary(this._bidNumber)]).subscribe((responseList) => {
        this.notes = responseList[0];
        this.tasks = responseList[1];
        this.rules = responseList[2];
      }, error => {
        this.alertify.error('There was a problem getting Info for summary.');
      });
  }

  openHelpComponent() {

  }

  checkIfCanSubmitIdea(subject, idea) {
    console.log(subject, idea)
    if (subject == null || subject.trim() === '' || idea == null || idea.trim() === '') {
      this.canSubmitIdea = false;
    }
    else {
      this.canSubmitIdea = true;
    }
  }

  submitIdea(subject, idea, file) {
    console.log(subject, idea, file);
    // console.log(subject.value, idea.value, file.value);
  }

  cancelIdea(subject, idea, file) {
    console.log(subject, idea, file);
    subject.value = '';
    idea.value = '';
    file.value = '';
    this.showHelp = false;
  }
}
