import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { AlertifyService, AuthService, SurveyTesterService } from 'core';

@Component({
  selector: 'survey-tester-info',
  templateUrl: './survey-tester-info.component.html',
  styleUrls: ['./survey-tester-info.component.scss']
})
export class SurveyTesterInfoComponent implements OnChanges {
  showtooltoogle = false;

  @Input() public set surveyId(data) {
     this._surveyId = data;
  }
  @Input() public set settings(data) {   
    if(data.showTestWarningMessage!=null)
    {
      this._settings = data;
    }else{
    
    this.client=data.client;
    }
 }

   _settings;
  _surveyId = '';
  client=''
  info;
  isLive = false;
  hide = false;
  showQuestion;
  heightToRemove = '175px';

  @Output() callback = new EventEmitter();
  @Output() emulate = new EventEmitter<string>();

  constructor(
    public auth: AuthService,
    private alertify: AlertifyService,
    private service: SurveyTesterService) { }

  ngOnChanges() {
    this.getProjectInfo();
  }

  sendMessageExtension(type, evt) {  
   this.showtooltoogle=evt;
   console.log("send message extension", this.showtooltoogle);
    this.callback.emit({type, data: evt});
  }


  getProjectInfo() {
    this.service.getProjectInfo(this._surveyId).subscribe((data) => {
      this.info = data; 
      this.info.programmers = [...new Set(data.programmers.map(x => x.fullName))].join(', ');
      this.info.reviewers =[...new Set(data.reviewers.map(x => x.fullName))].join(', ');   
      const status = ['Soft Launch', 'Full Launch'];
      this.isLive = data?.projects.some(x => status.includes(x.projectStatus));
      if (this.isLive && this._settings.showTestWarningMessage==true) {
        this.heightToRemove = '295px';
      }
    },
    error => {
      this.alertify.error('There was an error getting project info');
    });
  }

  hideWarning() {
    this.callback.emit({type: 'or_surveytester_hideWarning', data: false});
    this.heightToRemove = '175px';
  }

  phoneEmulation() {
    this.emulate.emit("phone");
  }

  tabletEmulation() {
    this.emulate.emit("tablet");
  }

  desktopEmulation() {
    this.emulate.emit("desktop");
  }
}
