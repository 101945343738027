<div class="wrapper" [ngClass]="auth.loggedIn() ? 'd-flex' : ''">
  <app-sidebar *ngIf="auth.loggedIn()" [isSurveyTester]="surveyTester"></app-sidebar>

  <div *ngIf="!surveyTester" [class.content]="auth.loggedIn()" [class.sidebar-open]="sidebarOpen">
    <app-nav-bar *ngIf="auth.loggedIn()"></app-nav-bar>
    <router-outlet></router-outlet>
  </div>

  <!-- Survey Tester -->
  <ng-container *ngIf="surveyTester">
    <router-outlet></router-outlet>
  </ng-container>

</div>



