<div class="card m-2">
  <div class="card-header">
    <div class="row align-items-center">
      <div [class]="mode == 'reduced' ? 'col-10' : 'col-6'">
        <div class="d-block text-truncate">
          <h3 class="nav-secondary font-weight-bold text-truncate">Project Info</h3>
        </div>
      </div>
    </div>
  </div>
  <ng-container>
    <div class="card-body pb-0">
      <div class="row">
        <div [ngClass]="mode == 'expanded' ? 'col-5' : 'col-12'">
          <div class="row pb-2">
            <div class="col-10">
              <div class="d-block text-truncate">
                <span class="h5 font-weight-bold text-truncate or-secondary" tooltip="{{ _project.projectCode }}">{{ _project.projectCode }}</span>
              </div>
            </div>
            <div class="col-2 ">
              <div class="ml-auto mt-1 favorite">
                <i (click)="toggleFavorite()" class="far fa-star fa-lg cursor-pointer opacity-90" [ngClass]="{'active': isFavorite}"></i>
              </div>
            </div>
            <div class="col-12 or-primary h5"  tooltip="{{ _project.projectName }}">{{ _project.projectName }}</div>
            <div class="col-7">
              <div>
                <span class="or-title mb-0">Started </span>
                <small class="or-secondary"><strong>{{ _project.projectStartDate | date }}</strong></small>
              </div>
            </div>
            <div class="col px-0">
              <div>
                <span class="or-title mb-0">
                  End Date <span tooltip="Edit" *ngIf="auth.isInternal()  && !isVendorview" (click)="enableEdit('projectEndDate')">
                  <i class="mr-2 fas fa-edit cursor-pointer or-secondary"></i></span>
                </span>
                <ng-container *ngIf="editEnabled['projectEndDate'] == null">
                  <small class="or-secondary"><strong>{{ _project.projectEndDate | date }}</strong></small>
                </ng-container>
                <ng-container *ngIf="editEnabled['projectEndDate'] != null">
                  <div class="input-group mb-3">
                    <input type="text"  autocomplete="off" class="form-control form-control-sm bg-white" readonly #dp="bsDatepicker"
                      [bsValue]="editEnabled['projectEndDate']"
                      (bsValueChange)="editEnabled['projectEndDate'] = $event"
                      bsDatepicker [bsConfig]="{ dateInputFormat: 'MMM Do, YYYY', isAnimated: true, isOpen:true, showWeekNumbers:false, customTodayClass: 'todays-date' }">
                    <div class="input-group-append">
                      <button class="btn btn-light border" type="button" (click)="dp.toggle()">
                        <i class="far fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="d-inline-flex actions">
                    <button type="button" class="btn btn-outline-secondary shadow-sm mx-1" (click)="cancelEdit('projectEndDate')"><i class="fas fa-times fa-xs"></i></button>
                    <button type="button" class="btn btn-outline-secondary shadow-sm" (click)="saveField('projectEndDate')"><i class="fas fa-check fa-xs"></i></button>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="row client mt-2 py-3 or-gray-bg">
            <div class="col mx-2">
              <div class="row">
                <div class="col-12">
                  <div class="d-block text-truncate">
                    <span *ngIf="!user.roles.includes('Member' || 'Admin' || 'SuperAdmin')" class="h6 font-weight-bold text-truncate or-secondary" tooltip="{{ _project.clientName }}">{{ _project.clientName }}</span>
                    <span *ngIf="user.roles.includes('Member' || 'Admin' || 'SuperAdmin')" class="h6 font-weight-bold text-truncate or-secondary dotted-underline" [routerLink]="['/clients', _project.clientId]" tooltip="{{ _project.clientName }}">{{ _project.clientName }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <or-email-address [emailaddress]="_project.clientContactEmail" [displayname]="_project.clientContactName"></or-email-address>
                </div>
              </div>
              <div class="row" *ngIf="_project.clientContactPhone != ''">
                <div class="col">
                  <or-phone [phone]="_project.clientContactPhone"></or-phone>
                </div>
              </div>
            </div>
          </div>
          <div class="row py-2">
            <div class="col-7">
              <div class="col px-0">
                <div class="or-title mb-0">
                  PM <span tooltip="Edit" *ngIf="auth.isInternal() && !isVendorview" (click)="enableEdit('projectManager')">
                  <i class="fas fa-edit cursor-pointer or-secondary"></i></span>
                </div>
                <ng-container *ngIf="editEnabled['projectManager'] == null">
                  <small class="d-block or-secondary"><strong>{{ this.projectManager }}</strong></small>
                </ng-container>
                <ng-container *ngIf="editEnabled['projectManager'] != null">
                  <or-type-ahead
                    [(ngModel)]="editEnabled['projectManager']"
                    name="projectManager"
                    [labelField]="'name'"
                    [valueField]="'id'"
                    [dataCallback]="getUserList"
                    (valueChange)="editEnabled['projectManager'] = $event"
                    placeholder="Type to search..."
                    [selectedValues]="editEnabled['projectManager']">
                  </or-type-ahead>
                  <div class="d-inline-flex actions">
                    <button type="button" class="btn btn-outline-secondary shadow-sm mx-1" (click)="cancelEdit('projectManager')"><i class="fas fa-times fa-xs"></i></button>
                    <button type="button" class="btn btn-outline-secondary shadow-sm" (click)="saveField('projectManager')"><i class="fas fa-check fa-xs"></i></button>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col px-0">
              <div class="or-title mb-0">
                Secondary PM  <span tooltip="Edit" *ngIf="auth.isInternal() && !isVendorview" (click)="enableEdit('projectTeam')">
                <i class="fas fa-edit cursor-pointer or-secondary"></i></span>
              </div>
              <ng-container *ngIf="editEnabled['projectTeam'] == null">
                <small class="d-block or-secondary"><strong>{{ this.projectTeam }}</strong></small>
              </ng-container>
              <ng-container *ngIf="editEnabled['projectTeam'] != null">
                <or-type-ahead
                  [(ngModel)]="editEnabled['projectTeam']"
                  name="projectTeam"
                  [labelField]="'name'"
                  [valueField]="'id'"
                  [multi]="true"
                  [dataCallback]="getUserList"
                  (valueChange)="editEnabled['projectTeam'] = $event"
                  placeholder="Type to search..."
                  [selectedValues]="editEnabled['projectTeam']">
                </or-type-ahead>
                <div class="d-inline-flex actions">
                  <button type="button" class="btn btn-outline-secondary shadow-sm mx-1" (click)="cancelEdit('projectTeam')"><i class="fas fa-times fa-xs"></i></button>
                  <button type="button" class="btn btn-outline-secondary shadow-sm" (click)="saveField('projectTeam')"><i class="fas fa-check fa-xs"></i></button>
                </div>
              </ng-container>
            </div>
          </div>
          <ng-container  *ngIf="auth.isInternal() && !isVendorview">
            <div class="row mt-2">
              <div [ngClass]="((hasSurveyUrl && isDecipherUrl)) ? 'col-7' : 'col-12'">
                <span class="or-title">
                  Tags: <span tooltip="Edit" *ngIf="auth.isInternal()" (click)="enableEdit('projectTags')">
                  <i class="fas fa-edit cursor-pointer or-secondary"></i></span>
                </span>
              </div>
              <div class="col px-0" *ngIf="(hasSurveyUrl && isDecipherUrl) && (editEnabled['projectTags'] == null)">
                <span class="or-title">Decipher Quotas</span>
              </div>
            </div>
            <div class="row" *ngIf="editEnabled['projectTags'] == null">
              <div class="tags scrollbar overflow-x" [ngClass]="hasSurveyUrl && isDecipherUrl ? 'col-7' : 'col-12'">
                <span *ngIf="_project.category != null && _project.category !='' " class="badge badge-pill badge-light text-uppercase m-1 or-secondary-bg text-white"> {{ _project.category }}</span>
                <span *ngIf="type != null  && type !='' " class="badge badge-pill badge-light text-uppercase m-1 or-secondary-bg text-white"> {{ type }}</span>
                <i *ngIf="_project.projectTags != null && _project.projectTags?.length > 0">
                  <span class="badge badge-pill badge-light text-uppercase m-1 nav-btn-accent text-white" *ngFor="let tag of _project.projectTags"> {{ tag.tag }}</span>
                </i>
              </div>
              <div class="col px-0" *ngIf="hasSurveyUrl && isDecipherUrl">
                <button class="btn btn-xs nav-btn-accent text-white" (click)="goToLink(surveyLink)">OPEN</button>
              </div>
            </div>
            <div class="row" *ngIf="editEnabled['projectTags'] != null">
              <div class="mb-3">
                <or-input-multiple placeholder="Type and press Enter" [(selectedValuesString)]="editEnabled['projectTags']"></or-input-multiple>
              </div>
              <div class="d-inline-flex actions">
                <button type="button" class="btn btn-outline-secondary shadow-sm mx-1" (click)="cancelEdit('projectTags')"><i class="fas fa-times fa-xs"></i></button>
                <button type="button" class="btn btn-outline-secondary shadow-sm" (click)="saveField('projectTags')"><i class="fas fa-check fa-xs"></i></button>
              </div>
            </div>


          </ng-container>
        </div>

      </div>
    </div>
  </ng-container>
</div>
