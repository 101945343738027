import { Component, OnInit } from '@angular/core';
import { AuthService, AlertifyService } from 'core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  model: any = {};
  constructor(
    private auth: AuthService,
    private alertify: AlertifyService,
    private router: Router
  ) {}

  ngOnInit() {}

  reset() {
    this.model.sender = 'main';
    this.auth.forgot(this.model).subscribe(
      next => {
        this.alertify.success('Link has been emailed to you');
        //this.router.navigate(['']);
      },
      error => {
        this.alertify.error('Please check details and try again.');
      },
      () => {
        //this.router.navigate(['']);
      }
    );
  }
}
