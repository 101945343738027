import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { CleanidService, ProjectService, AlertifyService, Project, AuthService } from 'core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

declare var google: any;

@Component({
  selector: 'app-project-card-cleanid',
  templateUrl: './project-card-cleanid.component.html',
  styleUrls: ['./project-card-cleanid.component.scss']
})
export class ProjectCardCleanidComponent implements OnInit {
  @Input() public set project(data: Project) {
    this._project = JSON.parse(JSON.stringify(data));
  } 
  
  @Input() mode = 'reduced';

  @Input() public set respondents(data) {
    this.processData(data);
  }

  @Output() cardresize = new EventEmitter();
  @Output() onChange = new EventEmitter();

  _project;
  total;
  fraud: any;
  trendData: any = [];
  markerData: any;
  chartType = 'piechart';
  loading:any = {};
  partnerCounts = [];
  thresholdList;
  selectedPartner: any = {};
  thresholdOn: boolean;
  sendEmailMode = false;
  emailTo = '';
  submitted = false;
  cleanIdPreviewReport;

  openModalRef: BsModalRef;

  @ViewChild('sendForm')
  private sendForm: NgForm;

  constructor(
    private cleanidService: CleanidService,
    private projectService: ProjectService,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private sanitizer: DomSanitizer,
    public auth: AuthService) { }

  ngOnInit() {
    this.drawChart();
  }

  drawSummaryChart() {
    this.drawChart();
  }
  drawChart() {
    this.thresholdOn = (this._project.fraudDetectionThreshold > 0 && this._project.fraudDetectionThreshold < 900);

    if (this.mode === 'expanded') {
      // TO DO: ONLY GET MARKER DATA IF WE DON:T ALREADY HAVE IT OR READ FROM CACHE ETC.
      this.cleanidService.GetCleanIDThresholds().subscribe( data => {
        this.thresholdList = data;
      });

      this.cleanidService.GetCleanIDMarkerDataFromNav(this._project.id, this.thresholdOn, '').subscribe( data => {
        this.markerData = data;
      }, error => {

      });
    }
  }

  processData(data) {
    let total = 0;
    let fraud = 0;
    const partners = {};
    const partnerIds = {};

    data.forEach(e => {
      total += e.total;
      fraud += e.cid;

      partners[e.partner] = partners[e.partner] ?? 0;
      partners[e.partner] += e.cid;
      partnerIds[e.partner] = e.partnerId;
    });
    this.fraud = fraud;
    this.total = total;

    if (fraud === 0 ) {
      // TO DO: SHOW NO DATA DETAILS
      return;
    }
    data.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });
    const startDate = new Date(data[0].date);
    const endDate = new Date(data[data.length - 1].date);
    endDate.setHours(23);
    const chartData = [];
    for (let d = startDate; d.getTime() <= endDate.getTime(); ) {
      const dDate = d.toISOString().split('T')[0];
      const dayDetails = data.filter(e =>  e.date.toISOString().split('T')[0] === dDate);
      let count = 0;
      dayDetails.forEach(e => {
        count += e.cid;
      });
      chartData.push([ new Date(d), count]);
      d = new Date(d.setDate(d.getDate() + 1));
    }
    this.trendData = chartData;

    const partnerList = [];
    for (const partner in partners) {
     partnerList.push({name: partner, value: partners[partner], id: partnerIds[partner]});
    }
    this.partnerCounts = partnerList.sort( (a, b) => b.value - a.value);
  }
  filterOnPartner(partner) {
    if (partner.name === this.selectedPartner.name) {
      this.selectedPartner = {};
    } else {
      this.selectedPartner = partner;
    }
    this.drawChart();
  }

  sendReport() {
    this.submitted = true;

    if (!this.sendForm.valid) {
      this.alertify.error('Please type in a valid email');
      return;
    } else if (this.emailTo === undefined || this.emailTo === null || this.emailTo === "") {
      this.alertify.error("Please type in a valid email!");
      return;
    }

    this.cleanidService.SendReportByEmail(this._project.id, this.emailTo).subscribe(result => {
      if (result) {
        this.alertify.success('CleanID Report sent succesfully');
        this._project.cleanIdReportEmailedOnce = true;
        this.emailTo = null;
        this.submitted = false;
        this.sendForm.reset();
      }
    }, error => {
      this.alertify.error('Failed to Send CleanID Report');
    });
  }

  resizeCard(mode?:any) {
    if (mode) {
      this.mode = mode;
    } else {
      this.mode = this.mode === 'reduced' ? 'expanded' : 'reduced';
    }
    this.drawChart();
    this.cardresize.emit(this.mode);
  }

  changeEnabled(evt) {
    const rollback = this._project.isFraudDetectionEnabled;
    this.loading.isFraudDetectionEnabled = true;

    this.projectService.UpdateProjectValue(this._project.id, 'isFraudDetectionEnabled', evt).subscribe( data => {
      this.alertify.success('CleanID Updated for this project');
      this._project.isFraudDetectionEnabled = evt;
      this.loading.isFraudDetectionEnabled = false;
      this.onChange.emit(this._project)
    } , error => {
      this.alertify.error('Failed to Update CleanID');
      this._project.isFraudDetectionEnabled = rollback;
      this.loading.isFraudDetectionEnabled = false;
    });

  }
  switchMode(chartType) {
    this.chartType = chartType;
  }

  changeThreshold(evt) {
    const threshold = parseInt(evt.value, 10);
    this.loading.fraudDetectionThreshold = true;
    this.projectService.UpdateProjectValue(this._project.id, 'fraudDetectionThreshold', threshold).subscribe( data => {
      this.alertify.success('Threshold Updated for this project');
      this._project.fraudDetectionThreshold = threshold;
      this.thresholdOn = (this._project.fraudDetectionThreshold > 0 && this._project.fraudDetectionThreshold < 900);
      this.loading.fraudDetectionThreshold = false;
    } , error => {
      this.alertify.error('Failed to Update CleanID Threshold');
      this._project.fraudDetectionThreshold = parseInt(evt.origValue, 10);
      this.loading.fraudDetectionThreshold = false;
    });
  }

  changeAllowMobile(evt) {
    const allowMobile = evt;
    this.loading.allowMobile = true;
    this.projectService.UpdateProjectValue(this._project.id, 'allowMobile', allowMobile).subscribe( data => {
      this.alertify.success('Allow Mobile Updated for this project');
      this.loading.allowMobile = false;
    } , error => {
      this.alertify.error('Failed to Update CleanID Allow Mobile');
      this._project.allowMobile = !this._project.allowMobile;
      this.loading.allowMobile = false;
    });
  }
  changeShowLandingPage(evt) {
    const showLandingPage = evt;
    this.loading.showLandingPage = true;
    this.projectService.UpdateProjectValue(this._project.id, 'showLandingPage', showLandingPage).subscribe( data => {
      this.alertify.success('E-mail Security Page setting updated for this project');
      this.loading.showLandingPage = false;
    } , error => {
      this.alertify.error('Failed to Update E-mail Security Page');
      this._project.showLandingPage = !this._project.showLandingPage;
      this.loading.showLandingPage = false;
    });
  }

  async previewReport(modalTemplate) {
    this.cleanIdPreviewReport = this.sanitizer.bypassSecurityTrustHtml(await this.cleanidService.GetPreviewReportEmail(this._project.id));
    this.openModalRef = this.modalService.show(modalTemplate, {ignoreBackdropClick: true, keyboard: false, class: 'modal-lg' });
  }
  onToggleReportSentToClient() {
    // If the user un-checked, open confirmation dialog to make sure they want to do this
    if (this._project.cleanIdReportEmailedToClient) {
      this.projectService.UpdateProjectValue(this._project.id, 'cleanIdReportEmailedToClient', this._project.cleanIdReportEmailedToClient).subscribe(
        () => {},
        () => { this.alertify.error("Failed to update project"); this._project.cleanIdReportEmailedToClient = !this._project.cleanIdReportEmailedToClient }
      );
    } else {
      this.alertify.confirmYesNo(
        'Warning!',
        'Are you sure you want to mark the report as not sent?',
        () => {
          this.projectService.UpdateProjectValue(this._project.id, 'cleanIdReportEmailedToClient', this._project.cleanIdReportEmailedToClient).subscribe(
            () => {},
            () => { this.alertify.error("Failed to update project"); this._project.cleanIdReportEmailedToClient = !this._project.cleanIdReportEmailedToClient }
          );
        },
        () => {
          this._project.cleanIdReportEmailedToClient = true;
        }
      );
    }
  }
}
