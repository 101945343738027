import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { AuthService, MilestoneService, ScheduleService, AlertifyService } from 'core';
import { DashboardService } from 'projects/core/src/services/dashboard.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-widget-my-priorities',
  templateUrl: './widget-my-priorities.component.html',
  styleUrls: ['./widget-my-priorities.component.scss']
})
export class WidgetMyPrioritiesComponent implements OnInit {

  @Input() widget;
  @Input() updated;

  @Output() onremove = new EventEmitter();
  @Output() onupdate = new EventEmitter();
  @Output() onopenconfig = new EventEmitter();

  tasks: any = null;
  totalToday = 0;
  completeToday = 0;
  today = new Date().toISOString();
  currentListType;

  constructor(
    public http: HttpClient,
    public auth: AuthService,
    private router: Router,
    private scheduleService: ScheduleService,
    private alertify: AlertifyService,
    private milestoneService: MilestoneService,
    private dashboardService: DashboardService) { }

  ngOnInit() {
    this.LoadList('current');   
  }

  CompleteTask(taskId) {
    this.scheduleService.CompleteTask(taskId).subscribe((data) => {
      this.LoadList(this.currentListType);
    }, error => {
      this.alertify.error(error);
    });
  }
  LoadList(listType){
    this.currentListType = listType
    
    let today = new Date();
    today.setHours(12, 0, 0);
    var userIds = localStorage.getItem('dashboard');
    let users: string[] = [];
    if (userIds != null && userIds != '') {
      users = userIds.split(',').map(item => item.trim());
    }
    else {
      users.push(this.auth.getUser().id);
    }

    let filter = {};
    if(this.currentListType == 'current'){
      filter = {
        assignedToId: users,
        dueDateEnd: today.toISOString(),
        status: 'Pending'
      }
    }
    else if(this.currentListType == 'upcoming'){
      let tomorrow = new Date(today); 
      tomorrow.setDate(today.getDate() + 1);
      filter = {
        assignedToId: users,
        dueDateBegin: tomorrow.toISOString(),
        status: 'Pending',
        limit: 20
      }
    }
    else if(this.currentListType == 'complete'){
      filter = {
        assignedToId: users,
        completeDateBegin: today.toISOString(),
        status: 'Complete'
      }
    }

    this.tasks = null;
    this.scheduleService.GetAllTasks(filter).subscribe(data => {
      this.tasks = data;
    }, error => {
      this.alertify.error('Unable to get tasks');
    });

    //We get all tasks that were due for this user today so we can get total counts
    let todayFilter = {
      assignedToId: users,
      dueDateBegin: today.toISOString(),
      dueDateEnd: today.toISOString()
    }

    /*this.scheduleService.GetAllTasks(todayFilter).subscribe(data => {
      this.totalToday = data.length;
      this.completeToday = data.filter(e => e.status =='Complete').length;
    }, error => {
      this.alertify.error('Unable to get tasks');
    });*/

  }


  remove() { this.onremove.emit(); }
  updateWidget() { this.onupdate.emit(); }
  configWidget() { this.onopenconfig.emit(); }
  updateTitle(widgetTitle) {
    this.widget.title = widgetTitle;
    this.updateWidget();
  }

  shortenProjectName(name) {
    return name.length > 50 ? `${name.slice(0, 20)}...${name.slice(-20)}` : name;
  }
}