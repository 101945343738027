import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-project-homepage',
  templateUrl: './project-homepage.component.html',
  styleUrls: ['./project-homepage.component.scss']
})
export class ProjectHomepageComponent implements OnInit {

  @Input() public set project(data) {
    this._project = data;
  }

  @Input() stage: any;

  @Output() callback = new EventEmitter();

  _project:any = {};

  constructor() { }

  ngOnInit() {

  }

  cardCallback(evt) {
    this.callback.emit(evt);
  }

}
