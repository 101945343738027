<div class="modal-header text-white">
  <p class="h5 position-absolute"><em class="fas fa-cog"></em> Widget Configuration</p>
  <button type="button" class="close pull-right text-white" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <input type="hidden" id="id">
  <div class="">
    <div class="h6">
      {{widget.title}}
    </div>
    <hr class="m-0 p-1">
    <div class=" m-2 p-2">
      <ng-container *ngFor="let set of editSettings | keyvalue">
        <div class="row m-2">
          <div class="col form-group">
            <label>{{set.value.label}}</label>
            <or-toggle-button *ngIf="set.value.type == 'toggle'" [(ngModel)]="set.value.value" ></or-toggle-button>
            <input  *ngIf="set.value.type == 'text'" style="width:20%" class="text-right form-control form-control-sm" [(ngModel)]="set.value.value">
            <div *ngIf="set.value.type == 'dropdown'" class="w-50">
              <select class="custom-select" [(ngModel)]="set.value.value">
                <ng-container *ngFor="let item of dateRangeOptions | keyvalue">
                  <option value={{item.value.value}}>{{item.value.label}}</option>
                </ng-container>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class=" text-right">
      <button type="submit" class="btn btn-sm btn-outline-secondary mx-2" (click)="cancel()"> Cancel</button>
      <button type="submit" class="btn btn-sm nav-btn-accent text-white" (click)="saveWidgetConfig()"><em class="fa fa-save"></em> Save</button>
    </div>
  </div>
</div>
