import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CleanidService, ReportsService, AlertifyService, Project, AuthService } from 'core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-project-card-idsuite-settings',
  templateUrl: './project-card-idsuite-settings.component.html',
  styleUrls: ['./project-card-idsuite-settings.component.scss']
})
export class ProjectCardIdSuiteSettingsComponent implements OnInit {

  @Input() public set project(data: Project) {
    this._project = JSON.parse(JSON.stringify(data));
  }
  @Input() clientMode: boolean = false;

  @ViewChild('myIframe') public myIframe;
  iframeUrl = '';

  _project;
  cleanIdPreviewReport;
  section = 'CleanID';

  // Emails
  submitted = false;
  openModalRef: BsModalRef;
  emailTo = '';

  unsavedSettings = false;
  reportLoading = false;

  constructor(
    private cleanidService: CleanidService,
    private alertify: AlertifyService,
    private reportService: ReportsService,
    private modalService: BsModalService,
    private sanitizer: DomSanitizer,
    public auth: AuthService) { }

  ngOnInit() {
    this.loadSection('CleanID');
  }


  loadSection(section) {
    this.section = section;
    let reportId = this.section === 'CleanID' ? (this.clientMode)? '178': '175' : '139';
    let projectIdentifier = this.section === 'CleanID' ? this._project.projectCode : this._project.id.toUpperCase();
    this.reportService.GenerateMetabaseEmbedTokenProject(reportId, projectIdentifier).subscribe( data => {
      this.iframeUrl = data;
      const iframe = this.myIframe.nativeElement;
      if (iframe != null) {
        iframe.src = this.iframeUrl;
      }
     }, error => {
 
     });

  }


  openSendEmail(modalTemplate) {
    //this.resizeCard('reduced')
    this.openModalRef = this.modalService.show(modalTemplate, { ignoreBackdropClick: true, keyboard: false, class: 'modal-md' });
  }

  closeSendEmail() {
    this.openModalRef.hide();
    this.submitted = false;
    this.emailTo = '';
  }

  sendReport(form) {
    this.submitted = true;

    if (!form.valid) {
      this.alertify.error('Please use a valid email');
      return;
    //} else if (this.emailTo === undefined || this.emailTo === null || this.emailTo === "") {
    } else if (this.emailTo === "") {
      this.emailTo = '';
      this.alertify.error("Please type in a valid email!");
      form.valid = false;
      return;
    }

    this.cleanidService.SendReportByEmail(this._project.id, this.emailTo).subscribe(result => {
      if (result) {
        this.alertify.success('CleanID Report sent succesfully!');
        this._project.cleanIdReportEmailedOnce = true;
        this.closeSendEmail();
        form.reset();
      }
    }, error => {
      this.alertify.error('Failed to Send CleanID Report');
    });
  }

  async previewReport(modalTemplate) {
    try {
      this.reportLoading = true
      this.alertify.success("Report is loading")

      this.cleanIdPreviewReport = this.sanitizer.bypassSecurityTrustHtml(await this.cleanidService.GetPreviewReportEmail(this._project.id));
      this.openModalRef = this.modalService.show(modalTemplate, { ignoreBackdropClick: true, keyboard: false, class: 'modal-lg' });
      
      this.reportLoading = false
    } catch (error) {
      this.alertify.error("There was an error when loading the Report")
      console.log("Error: ", error)
      this.reportLoading = false;
    }
  }

}
