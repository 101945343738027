<div class="card m-2">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col-8">
        <h3 class="nav-secondary font-weight-bold">Vendor Details</h3>
      </div>
    </div>
  </div>

  <div class="card-body overflow-y">
    @for (vendor of vendors; track vendor) {
      <div class="row">
        <div class="col-lg-4 col-md-12">
          <div class="or-primary h5 mr-2">
            {{vendor.partnerName }}
            <span tooltip="Download IDs" (click)="downloadIdFile(vendor)"><i class="fas or-secondary opacity-70 fa-download fa-xs"></i></span>
          </div>
          <div class="or-title mb-0 mt-4"> Project ID <span tooltip="Edit Project ID" (click)="vendor.editProjectIdMode = true; vendor.partnerProjectNumberEdit=vendor.partnerProjectNumber"><i class="fas fa-edit cursor-pointer or-secondary subtle-icon"></i></span></div>
          @if (vendor.editProjectIdMode != true) {
            <strong>{{ vendor.partnerProjectNumber || 'none' }} &nbsp;</strong>
          }
          @if (vendor.editProjectIdMode == true) {
            <div>
              <div class="d-inline-flex actions">
                <input [(ngModel)]="vendor.partnerProjectNumberEdit" placeholder="Project Id" class="form-control form-control-sm">
                <button type="button" class="btn btn-sm btn-outline-secondary shadow-sm mx-1"  (click)="cancelSaveProjectId(vendor)"><i  class="fas fa-times fa-xs"></i></button>
                <button type="button" class="btn btn-sm btn-outline-secondary shadow-sm"  (click)="saveProjectId(vendor)"><i class="fas fa-check fa-xs"></i></button>
              </div>
            </div>
          }
          <div class="or-title mb-0  mt-2"> PM <span tooltip="Edit PM" (click)="enableEditVendorPm(vendor)"><i class="fas fa-edit cursor-pointer or-secondary subtle-icon"></i></span></div>
          @if (vendor.editPmMode != true) {
            @for (pm of vendor.partnerPms; track pm) {
              <p><strong >{{ pm.name }}</strong></p>
            }
          }
          @if (vendor.editPmMode == true) {
            <div>
              <div class="d-inline-flex actions">
                <or-type-ahead  [labelField]="'name'" [multi]="true" [valueField]="'id'" [selectedValues]="vendor.partnerPms === null ? [] : vendor.partnerPms"  (valueChange)="updateVendorPM($event, vendor)"  [data]="vendor.contacts" placeholder="Type to search..."></or-type-ahead>
                <button type="button" class="btn btn-sm btn-outline-secondary shadow-sm mx-1"  (click)="cancelVendorPM(vendor)"><i  class="fas fa-times fa-xs"></i></button>
                <button type="button" class="btn btn-sm btn-outline-secondary shadow-sm"  (click)="saveVendorPM(vendor)"><i class="fas fa-check fa-xs"></i></button>
              </div>
            </div>
          }
          <div class="row">
            <div class="col-6">
              <div class="or-title mb-0 mt-2">Allocation</div>
              <strong>{{ vendor.partnerFullQuota }}</strong>
            </div>
            <div class="col-6">
              <div class="or-title mb-0 mt-2">Soft Launch</div>
              <strong>{{ vendor.partnerSoftQuota }}</strong>
            </div>
          </div>
          <div class="or-title mb-0 mt-2">CPI @if (this.auditLog.length > 0) {
            <i class="fa-regular fa-clock"  (click)="showVendorCPIHistory(vendor)"></i>
          }</div>
          <strong>{{ vendor.buyCPI | currency }}</strong>
        </div>
        <div class="col-lg-8 col-md-12 nav-leftborder">
          <div class="row ">
            <div class="col-4">
              <div class="alert text-center alert-success small d-inline px-2 py-1" [ngClass]=" (vendor.partnerStatus=='Full Launch' || vendor.partnerStatus == 'Soft Launch')?'alert-success' :'alert-primary'" role="alert">
                {{vendor.partnerStatus }}
              </div> @if (this.auditLog.length > 0) {
              <i class="fa-regular fa-clock"  (click)="showVendorStatusHistory(vendor)"></i>
            }
          </div>
          <div class="col-8">
            @if (vendor.partnerStatus != 'Test') {
              <div class="row">
                <div class="col-8">
                  <div class="rule">
                    <hr class="or-line" />
                    <div class="progress or-gray-bg">
                      <div class="progress-bar" role="progressbar" [ngClass]="progressStyle(vendor.partnerStatus, daysPercentagePast, (vendor.complete * 100 / vendor.partnerFullQuota))" [style.width.%]="(vendor.complete * 100/vendor.partnerFullQuota )"
                      aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
                      <div class="vl" [style.right.%]="100 - daysPercentagePast"></div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <small><strong>{{ vendor.complete }}</strong> / {{vendor.partnerFullQuota}}n</small>
                </div>
              </div>
            }
          </div>
        </div>
        <div class="or-primary mt-4 h6">Survey URLs</div>
        @if (vendor.testMode == true) {
          <div class="inlinefield">
            <div><label>ID</label></div>
            <div>
              <input [(ngModel)]="vendor.testid" class="form-control form-control-sm">
            </div>
            @for (param of vendor.entryfields; track param) {
              <div><label>{{ param }}</label></div>
              <div>
                <input [(ngModel)]="vendor.testparams[param]" class="form-control form-control-sm">
              </div>
            }
          </div>
        }
        @if (vendor.testMode != true) {
          <div class="inlinefield">
            <small>Complete</small>
            @if (vendor.editRedirectsMode != true) {
              <div class="or-secondary">
                <input [(ngModel)]="vendor.completeRedirectURL" disabled class="form-control form-control-sm bg-white border-0">
              </div>
            }
            @if (vendor.editRedirectsMode) {
              <div>
                <input [(ngModel)]="vendor.completeRedirectURLEdit" class="form-control form-control-sm">
              </div>
            }
            <small>Term</small>
            @if (vendor.editRedirectsMode != true) {
              <div class="or-secondary">
                <input [(ngModel)]="vendor.terminateRedirectURL" disabled class="form-control form-control-sm bg-white border-0">
              </div>
            }
            @if (vendor.editRedirectsMode) {
              <div>
                <input [(ngModel)]="vendor.terminateRedirectURLEdit" class="form-control form-control-sm">
              </div>
            }
            <small>OQ</small>
            @if (vendor.editRedirectsMode != true) {
              <div class="or-secondary">
                <input [(ngModel)]="vendor.overQuotaRedirectURL" disabled class="form-control form-control-sm bg-white border-0">
              </div>
            }
            @if (vendor.editRedirectsMode) {
              <div>
                <input [(ngModel)]="vendor.overQuotaRedirectURLEdit" class="form-control form-control-sm">
              </div>
            }
            <small>QC</small>
            @if (vendor.editRedirectsMode != true) {
              <div class="or-secondary">
                <input [(ngModel)]="vendor.qcRedirectURL" disabled class="form-control form-control-sm bg-white border-0">
              </div>
            }
            @if (vendor.editRedirectsMode) {
              <div>
                <input [(ngModel)]="vendor.qcRedirectURLEdit" class="form-control form-control-sm">
              </div>
            }
          </div>
        }
        @if (vendor.editRedirectsMode == true) {
          <div class="mt-2 text-right" >
            <button type="button" class="btn btn-xs btn-outline-secondary" (click)="cancelEditRedirects(vendor)">Cancel Changes</button>
            <button type="button" class="ml-2 btn btn-xs btn-secondary" (click)="saveRedirects(vendor)">Save Redirects</button>
          </div>
        }
        @if (vendor.testMode == true) {
          <div class="mt-2 text-right" >
            <button type="button" class="btn btn-sm btn-outline-secondary" (click)="cancelTestURL(vendor)">Done</button>
            <button type="button" class="ml-2 btn btn-sm btn-outline-success" (click)="openTestUrl(vendor)">Run</button>
          </div>
        }
        @if (vendor.editRedirectsMode != true && vendor.testMode != true) {
          <div class="row mt-3" >
            <div class="col-12 d-flex">
              <button type="button" class="btn btn-xs btn-outline-secondary" (click)="copyEntryUrl(vendor)"><em class="fa fa-copy"></em> Copy Entry Url</button>
              <!--<button type="button" class="btn btn-xs btn-outline-secondary ml-2" (click)="copyRedirectUrls(vendor)"><em class="fa fa-copy"></em> Copy Redirect Urls</button>-->
              <button type="button" class="btn btn-xs btn-outline-secondary ml-2" (click)="enableEditRedirects(vendor)"><em class="fa fa-edit"></em> Edit Redirects</button>
              @if (vendor.partnerStatus == 'Test') {
                <button type="button" class="btn btn-xs btn-secondary ml-2" (click)="enableTestURL(vendor)"><em class="fa fa-flask"></em> Test Redirects</button>
              }
            </div>
          </div>
        }
      </div>
    </div>
    @if (vendors.length > 1) {
      <hr>
      }
    }
  </div>

</div>

<ng-template #vendorauditmodal>
  <div class="modal-header">
    <p class="h5 position-absolute"> Activity Log</p>
    <button type="button" class="close pull-right text-white" aria-label="Close" (click)="vendorAuditModalOpenRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <or-list [items]="vendorAuditItems" [layout]="vendorAuditColumns"></or-list>
  </div>
</ng-template>
