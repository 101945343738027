<div class="card surveycard">
  <div class="card-header">
    <div class="row">
      <div class="col-5 font-weight-light">
        <div class="h5 d-inline" [ngClass]="project.projectCode.length > 11 ? 'h6' : 'h5'">
          <a [routerLink]="['/projects', project.id]" class="font-weight-bold or-primary-link text-white">{{ project.projectCode }}</a>
        </div>
      </div>
      <div class="col-2 or-profile-image">
        <div class="d-flex justify-content-center" *ngIf="project.projectManager">
          <span tooltip="{{ project.projectManager }}">
            <or-profile-image [name]="project.projectManager" size="small" showInitials="true"></or-profile-image>
          </span>
        </div>
      </div>
      <div class="col-5 d-flex">
        <div class=" ml-auto text-right or-dropdown-status">
          <or-dropdown-status [status]="project.projectStatus"  [projectId]="project.id"></or-dropdown-status>
        </div>
        <div class="text-center px-0">
          <div class="btn-group btn-group-sm" dropdown>
            <button id="button-basic" dropdownToggle type="button" class="btn btn-outline-secondary" controls="dropdown-basic">
              <span><i class="fa fa-ellipsis-v"></i></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
              <li *ngFor="let action of actions" role="menuitem">
                <or-action-button
                  [label]="action.label"
                  [description]="action.description"
                  [icon]="action.icon"
                  (selected)="actionSelected(action.type)">
                </or-action-button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body py-1">
    <div class="d-flex mt-2">
      <div class="d-block text-truncate mr-2">
        <a class="h5 text-truncate or-primary-link" [routerLink]="['/projects', project.id]" tooltip="{{ project.projectName }}">{{ project.projectName }}</a>
      </div>
      <div class="ml-auto mt-1 favorite">
        <i (click)="toggleFavorite()" class="far fa-star fa-lg cursor-pointer opacity-90" [ngClass]="{'active': isFavorite}"></i>
      </div>
    </div>
    <div class="row">
      <div class="col-11">
        <div class="d-block text-truncate">
          <span class="h5 font-weight-light text-truncate or-secondary-link dotted-underline" [routerLink]="['/clients', project.clientId]" tooltip="{{ project.clientName }}">{{ project.clientName }}</span>
        </div>
      </div>
    </div>
    <div class="row pt-2 ">
      <div class="col">
        <p class="h5 mb-0 font-weight-normal or-secondary">{{ project.cleanID / (project.starts + project.overQuota + project.cleanID + project.dupes) | percent:'1.1-1' }} Fraud Rate</p>
        <small class="mb-0 font-weight-normal or-secondary">{{ project.cleanID }} Fraudulent Devices Blocked </small>
      </div>
    </div>
    <div class="row pt-2 ">
      <div class="col">
        <p class="h5 mb-0 font-weight-normal or-secondary">{{ project.dupes / (project.dupes + project.starts) | percent:'1.1-1' }} Dupe Rate</p>
        <small class="mb-0 font-weight-normal or-secondary">{{ project.dupes}} Duplicate Devices Blocked</small>
      </div>
    </div>
  </div>
</div>
