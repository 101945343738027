import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertifyService, ProjectService } from 'core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-project-thirdparty-status',
  templateUrl: './project-thirdparty-status.component.html',
  styleUrls: ['./project-thirdparty-status.component.scss']
})
export class ProjectThirdpartyStatusComponent implements OnInit {

  @Input() public set project(data) {
    this._project = data;
  }

  @Input() public set mode(data) {
    this._mode = data;
  }

  _mode;
  _project;
  projectStats: any;
  updated = false;
  loading = true;
  @Output() saveevent = new EventEmitter();

  constructor(public bsModalRef: BsModalRef, private alertify: AlertifyService, private projectService: ProjectService) { }

  ngOnInit(): void {
    if (this._mode === 'edit') {
      this.GetProjectStatusThirdParties();
    } else {
      this.GetProjectStatusThirdParties();
    }
  }

  GetProjectStatusThirdParties() {
    this.projectService.GetProjectStatusWithThirdParties(this._project.id).subscribe((data) => {
          this.projectStats = data;
          this.loading = false;
        },
        (error) => {
          this.alertify.error('Unable to get project with vendors');
          this.loading = false;
        },
        () => {

        }
      );
  }

}
