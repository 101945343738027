import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService, Project, ProjectService } from 'core';
import { DashboardService } from 'projects/core/src/services/dashboard.service';

@Component({
  selector: 'app-widget-ytd',
  templateUrl: './widget-ytd.component.html',
  styleUrls: ['./widget-ytd.component.scss']
})
export class WidgetYtdComponent implements OnInit {

  @Input() widget;

  @Output() onremove = new EventEmitter();
  @Output() onupdate = new EventEmitter();

  allProjects: any[];
  instanceId;

  tasksPercentage;
  projectsPercentage;
  projectsNumber;
  projectsRevenue;

  constructor(public dashboardService: DashboardService,
    public projectService: ProjectService,
    public auth: AuthService) { this.instanceId = this.auth.getInstance(); }

  ngOnInit(): void {
    this.instanceId = this.auth.getInstance();
    this.getStats();
  }

  getStats() {
    var userIds = localStorage.getItem('dashboard');
    let users: string[] = [];
    if (userIds != null && userIds != '') {
      users = userIds.split(',').map(item => item.trim());
    }
    const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0});
    this.dashboardService.getTasksCompletedOnTimePercentage(users).subscribe(data => {
      this.tasksPercentage = data[0];
      this.projectsPercentage = data[1];
      this.projectsNumber = data[2];
      this.projectsRevenue = formatter.format(data[3]);
    });
  }

  remove() { this.onremove.emit(); }

  updateWidget() {
    this.widget = {... this.widget};
    this.onupdate.emit();
  }

  updateTitle(widgetTitle) {
    this.widget.title = widgetTitle;
    this.updateWidget();
  }

}
