import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { AlertMessageService, AuthService } from 'core';
import { DashboardService } from 'projects/core/src/services/dashboard.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-widget-jobs-to-be-done',
  templateUrl: './widget-jobs-to-be-done.component.html',
  styleUrls: ['./widget-jobs-to-be-done.component.scss']
})
export class WidgetJobsToBeDoneComponent implements OnInit {

  @Input() widget;
  @Input() updated;

  @Output() onremove = new EventEmitter();
  @Output() onupdate = new EventEmitter();
  @Output() onopenconfig = new EventEmitter();

  data;

  constructor(
    public http: HttpClient,
    public auth: AuthService,
    private router: Router,
    public dashboardService: DashboardService) { }

  ngOnInit() {
    var userIds = localStorage.getItem('dashboard');
    let users: string[] = [];
    if (userIds != null && userIds != '') {
      users = userIds.split(',').map(item => item.trim());
    }
    //let instanceId = this.auth.getUser().instanceId;
    this.dashboardService.getJobsToBeDone(users).subscribe(data => {
      this.data = data;
    }, error => console.log(error));
  }
  
  goToTaskList(action) {
    if (action === 'overdue') {
      this.router.navigateByUrl('/schedule?mode=overdue');
    }
    else if (action === 'todo') {
      this.router.navigateByUrl('/schedule?mode=todo');
    }
  }

  remove() { this.onremove.emit(); }
  updateWidget() { this.onupdate.emit(); }
  configWidget() { this.onopenconfig.emit(); }
  updateTitle(widgetTitle) {
    this.widget.title = widgetTitle;
    this.updateWidget();
  }
}
