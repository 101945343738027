import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';

type CanDeactivateType = boolean;

export interface ICanComponentDeactivate {
  canDeactivate: (nextRoute: string, showDialog: boolean) => CanDeactivateType;
}

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<ICanComponentDeactivate> {
  canDeactivate(component: ICanComponentDeactivate, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): CanDeactivateType {

      const intendedRoute = nextState ? nextState.url : '';

      window.addEventListener('beforeunload', (e) => {
        if (component?.canDeactivate && !component?.canDeactivate(intendedRoute, false)) {
          e.returnValue = 'unsaved';
          e.preventDefault();
        }
        return true;
      });

      return component?.canDeactivate ? component.canDeactivate(intendedRoute, true) : true;
  }
}
