import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-project-audit',
  templateUrl: './project-audit.component.html',
  styleUrls: ['./project-audit.component.scss']
})
export class ProjectAuditComponent implements OnInit {
  @Output() onclose = new EventEmitter();
  projectId: string;

  constructor(private route: ActivatedRoute) {
    this.projectId = this.route.snapshot.params.id;
   }


  ngOnInit() {
  }
  closePage(){
    this.onclose.emit(null);
  }

}
