import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PartnersComponent } from './partners.component';
import { ComponentsModule, HelpersModule, DirectivesModule } from 'core';
import { PartnerDetailsComponent } from './partner-details/partner-details.component';
import { RouterModule } from '@angular/router';


@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    HelpersModule,
    DirectivesModule,
    RouterModule
  ],
  declarations: [PartnersComponent, PartnerDetailsComponent]
})
export class PartnersModule { }

