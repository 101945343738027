<!-- <div class="card-body py-3">
  <div class="row px-2 mb-4 or-gray-bg">
    <div class="col-12">
      <p *ngIf="project.totalDaysLeft == 0 ">CLOSED <span class="font-weight-bold">Today</span> </p>
      <p *ngIf="project.totalDaysLeft > 0 ">CLOSED <span class="font-weight-bold">{{ project.totalDaysLeft }} DAYS LEFT</span> </p>
      <p *ngIf="project.totalDaysLeft < 0 ">CLOSED <span class="font-weight-bold">{{ project.totalDaysLeft  * -1 }} DAYS AGO</span> </p>
    </div>
    <div class="col-9">
      <div class="rule">
        <hr class="or-line" />
        <div class="progress or-gray-bg">
          <div class="progress-bar or-darkgray-bg" role="progressbar"  [style.width.%]="project.percentageComplete"
                aria-valuenow="" aria-valuemin="0" aria-valuemax="100">
            <div class="vl" [style.right.%]="project.percentageDaysLeft"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <small class="d-block"><strong>{{ project.complete }}</strong> / {{ project.fullLaunchQuota }}n</small>
    </div>
  </div>
  <div class="col-12 text-right">
    <small><span class="font-weight-bold">$8,500</span></small>
  </div>
</div> -->

<div class="card-body pb-0">
  <div class="row px-2 mb-2">

    <!-- Stage and task progress -->
    <ng-container>
      <div class="col-3">
        <small class="text-nowrap grey4 d-block">{{ project.stageDaysLeft || 0 }} {{project.stageDaysLeft == 1 ? 'Day' : 'Days'}} Left</small>
      </div>
      <div class="col">
        <div class="rule">
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              [ngClass]="getProgressStyle()"
              [style.width.%]="stagePercentageComplete"
              aria-valuenow=""
              aria-valuemin="0"
              aria-valuemax="100">
            </div>
          </div>
        </div>
      </div>
      <div class="col-3  p-0 m-0">
        <small class="d-block grey4 text-center">{{ project.stageCompleteTaskCount || 0 }}/{{ project.stageTotalTaskCount || 0}} Tasks</small>
      </div>
    </ng-container>
  </div>
  <div class="row mt-4 pt-2 pb-2 px-3 border-top footer">
    <div class="col-4">
      <div class="mt-2">
        <span>
          <or-profile-image [names]="projectManagerNames" showInitials="true" size="medium"></or-profile-image>
        </span>
      </div>
    </div>   
    <div class="col-4 px-0"   [ngStyle]="{'visibility': statusVisible ? 'visible' : 'hidden'}">
      <span class="nav-font14 mb-1 nav-secondary">Field status</span>
      <div class=" ml-auto text-left or-dropdown-status">
        <or-dropdown-status [status]="project.projectStatus" [projectId]="project.id" [disabled]="!auth.isInternal()"></or-dropdown-status>
      </div>
    </div>
    <div class="col-4 px-0 text-center">
      <!-- <span class="nav-font14 mb-1 nav-secondary">Health Score</span>
      <div class="or-secondary">
        <span class="mr-2 mt-1" [ngClass]="getHealthIcon()"></span>
        <span class="mr-2">{{project.health}}</span>
      </div> -->
    </div>
  </div>
</div>
