import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DashboardService } from 'projects/core/src/services/dashboard.service';

@Component({
  selector: 'app-widget-project-health',
  templateUrl: './widget-project-health.component.html',
  styleUrls: ['./widget-project-health.component.scss']
})
export class WidgetProjectHealthComponent implements OnInit {

  @Input() widget;
  @Output() onremove = new EventEmitter();
  @Output() onupdate = new EventEmitter();

  tableData = [];

  settings = {
    myProjects: false,
    maxCount: 10,
    healthScore: 0,
    projectStatus: ['Soft Launch', 'Full Launch']
  };

  filterColumns = ['projectName', 'clientName'];
  filterText = '';

  layout = [
    {label: 'Code', id: 'projectCode', sortable: true, filterable: true, selectable: true},
    {label: 'Name', id: 'projectName', sortable: true, filterable: true, selectable: true},
    {label: 'Health', id: 'healthScore', type: 'health'},
    {label: 'Client', id: 'clientName', sortable: true},
    {label: 'PM', id: 'users', type: 'users'},
    {label: 'Status', id: 'projectStatus'}
  ];

  constructor(
    public dashboardService: DashboardService) { }

  ngOnInit() {
    this.projectWorst();
  }

  projectWorst() {
    this.dashboardService.getProjectByWorst(this.settings).subscribe(data => {
      if (data.length <= 0)
        return;

      this.tableData = data.map(item => {
        item.users = [];
        item.users.push({ name: item.manager });

        item.healthScore_icons = [];

        let tooltip = 'Project Health: ' + item.healthScore;
        let msg = '10+';

        if (item.healthScore <= 10)
          msg = item.healthScore;

        if (item.healthScore <= 3) {
          item.healthScore_icons.push({status: 'badge-success', message: msg, tooltip});
        } else if (item.healthScore <= 5) {
          item.healthScore_icons.push({status: 'badge-warning', message: msg, tooltip});
        } else if (item.healthScore <= 8) {
          item.healthScore_icons.push({status: 'badge-warning', message: msg, tooltip});
        } else {
          item.healthScore_icons.push({status: 'badge-danger', message: msg, tooltip});
        }

        return item;
      });

    }, error => console.log(error));
  }

  remove() {
    this.onremove.emit();
  }

  updateWidget() {
    this.onupdate.emit();
  }
  
  updateTitle(widgetTitle) {
    this.widget.title = widgetTitle;
    this.updateWidget();
  }
}
