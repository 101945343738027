import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ReportsService, UserService } from 'core';
import { formatDate } from '@angular/common'

@Component({
  selector: 'app-audit-report',
  templateUrl: './audit-report.component.html',
  styleUrls: ['./audit-report.component.scss']
})
export class AuditReportComponent implements OnInit {
 


  fullData = [];
  tableData = [];
  dateRange: Date[];
  userId = '';
  filterColumns = ['projectCode', 'table', 'column', 'user'];
  filterText = '';
  loading = true;
  auditFilter: string;
  loadCount = 500;
 

  columns: any = [
    { label: 'Code', id: 'projectCode', sortable: true },
    { label: 'Name', id: 'projectName', sortable: true },
    { label: 'Date', id: 'auditDateTime', sortable: true, type: 'datetime' },
    { label: 'User', id: 'user', sortable: true },
    { label: 'User Type', id: 'userType', sortable: true, filterable: true },
    { label: 'Change', id: 'change', sortable: true},
    { label: 'Old Value', id: 'oldValue', sortable: true, width: 200},
    { label: 'New Value', id: 'newValue', sortable: true, width: 200},
    //{ label: 'Type', id: 'type', sortable: true }
  ];

  changeMap = new Map<string, string>()
    .set("project - clientid", "Client Name")
    .set("project clientcontactid", "Client Contact")
    .set("project countries", "Country")
    .set("project - internalaccountownerid", "Account Owner")
    .set("project - clientcontactid", "Client Contact")
    .set("project - category", "Project Category")
    .set("project - bidnumber", "Project Bid Number")
    .set("project - type", "Project Type")
    .set("project - projectdeadline", "Project End Date")
    .set("project - projectEndDate", "Project End Date")
    .set("project - projectcode", "Project Code")
    .set("project - projectstatus", "Project Status")
    .set("projectsurveypartner - null", "Partner Added")
    .set("project - null", "Project Created")
    .set("project - projectstartdate", "Project Start Date")
    .set("projectsegment - surveylink", "Survey Link")
    .set("projectsegment - testsurveylink", "Test Survey Link")
    .set("projectsegment - sellcpi", "Sell CPI")
    .set("projectsegment - projectir", "Project IR")
    .set("projectsegment - projectloi", "Project LOI")
    .set("projectteam - secondary pm", "Secondary PM")
    .set("projectteam - pm", "PM")
    .set("projectalerts - alertpacemetric", "Pace Alert")
    .set("projectalerts - alertnavoq", "NAV Overquota Alert")
    .set("projectalerts - alertqcrate", "QC Alert")
    .set("projectalerts - alertdropoutrate", "DOR Alert")
    .set("projectalerts - alertdeadlineremainingdays", "Days Remaining Alert")
    .set("projecttags - null", "Project Tags")
    .set("surveysettings - fulllaunchquota", "Full Launch Quota")
    .set("surveysettings - dedupelegacyprojects", "De-Dupe Legacy Projects")
    .set("surveysettings - quotamode", "Quota Mode")
    .set("surveysettings - isfrauddetectionenabled", "Fraud Detection Enabled")
    .set("surveysettings - isdedupeparticipantenabled", "De-Dupe Participant Enabled")
    .set("surveysettings - isdedupedeviceenabled", "De-Dupe Device Enabled")
    .set("surveysettings - softlaunchquota", "Soft Launch Quota")
    .set("surveysettings - showlandingpage", "Show Landing Page")
  


  @Input() projectId = '';

  constructor(private reportService: ReportsService,private userService: UserService ) { }

  ngOnInit() {
      this.getAuditDetails();
  }

  updateUser(value) {
    this.userId = value[0]?.id;
  }

  getAuditDetails() {
    const sDate = this.dateRange == null ? null : this.dateRange[0];
    const eDate = this.dateRange == null ? null : this.dateRange[1];

    this.reportService.AuditReportData(this.projectId, this.userId, this.auditFilter, sDate, eDate).subscribe( data => {
      this.loading = false;
      data = data.filter(x => 
        ((x.newValue == null && x.newValue == x.oldValue) || x.newValue != x.oldValue) &&
        ((x.table == 'ProjectSegment') && ['SurveyLink', 'TestSurveyLink', 'SellCPI', 'ProjectIR', 'ProjectLOI'].includes(x.column) || (x.table != 'ProjectSegment')) &&
        x.column != "ClosureDetailsId");
      const offset = new Date().getTimezoneOffset();

      data.forEach(e => {

        const d = new Date(e.auditDateTime);
        e.auditDateTime = d.setMinutes(d.getMinutes() - offset);

        e.change, e.newValue, e.oldValue = this.getNewChangeValue(e)

      });
      this.fullData = data
      this.updateTableData();
    });
  }


  getNewChangeValue(e)
  {
    
    e.change = e.table + " - " + e.column
    var changePhrase = this.changeMap.get(e.change.toLowerCase())

    if (e.column && !changePhrase)
    {
      var partnerName = e.column.split(' ').splice(2).join(' ') || null 

      if (e.table.toLowerCase() === "projectsurveypartner") {

        switch(e.column.split(' ')[0].toLowerCase()) {
          case "partnerstatus": e.change = "Partner Status - " + partnerName; break;
          case "partnersoftquota": e.change = "Partner Soft Quota - " + partnerName; break;
          case "overquotaredirecturl": e.change = "Partner OQ Redirect URL - " + partnerName; break;
          case "completeredirecturl": e.change = "Partner Complete Redirect URL - " + partnerName; break;
          case "terminateredirecturl": e.change = "Partner Term Redirect URL - " + partnerName; break;
          case "qcredirecturl": e.change = "Partner QC Redirect URL - " + partnerName; break;
          case "partnerfullquota": e.change = "Partner Full Quota - " + partnerName; break;
          case "partnerprojectnumber": e.change = "Partner Project Number - " + partnerName; break;
          //case "partnercontactid": e.change = "Partner Contact - " + partnerName; break;
          case "buycpi": e.change = "Partner Buy CPI - " + partnerName; break;
          case "isdeleted" : {
            if (e.newValue.toLowerCase() == 'true' || e.newValue == true) {
              e.change = "Partner Removed";
              e.oldValue = partnerName;
              e.newValue = "";
            }
            else {
              e.change = "Partner Added";
              e.newValue = partnerName
              e.oldValue = ""
            }
            break;
          } 
        }
      }
      if (e.table.toLowerCase() === "surveysettings") {
        switch(e.column.split(' ')[0].toLowerCase()) {
          case "frauddetectionthreshold": {
            e.newValue = (e.newValue.toString() === '1000' ? 'No Threshold' : e.newValue);
            e.oldValue = (e.oldValue.toString() === '1000' ? 'No Threshold' : e.oldValue);
            break;
          }
       }
      }
      if (e.table.toLowerCase() === "projectdedupestatus") {

        e.change = "De-Dupe Status - " + e.column
      }
    }
    if (changePhrase) e.change = changePhrase;

    return e.change, e.newValue, e.oldValue
  }

  updateTableData() {
    // TODO: Add some way to load more that via a button etc.
    this.tableData = this.fullData.slice(0, this.loadCount);
  }
  getUserList = (filter) => {
    return this.userService.GetUsers();
  }

}
