import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService, AlertifyService } from 'core';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private alertify: AlertifyService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const roles = next.firstChild.data['roles'] as Array<string>;
    if (roles) {
      const match = this.authService.roleMatch(roles);
      if (match) {
        return true;
      } else {
        this.router.navigate(['']);
        this.alertify.error('That url was not available');
      }
    }

    if (this.authService.loggedIn()) {
      if(state.url.indexOf('/survey-tester') == 0)
      {
        var user = this.authService.getUser();
        if(user.roles.includes('Vendor'))
        {
          this.router.navigate(['']);
          this.alertify.error('That url was not available');
          return false
        }
      }
      return true;
    }

    //this.alertify.error('Login failed. Please try again.');
    this.router.navigate([''], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
