<h3 class="font-weight-bold nav-font18 grey5 px-4">
  <i class="fak fa-project-alerts grey4 mr-2 font-weight-normal"></i>Project Alerts
</h3>

<div class="mt-4 alerts scrollbar overflow-y px-3 w-100" [class.new]="addMode == true">
  <tabset>
    <tab tabOrder="1" (selectTab)="changeViewMode('pending')">
      <ng-template tabHeading>
        <span class="nav-font14">Pending</span>
      </ng-template>
      @if (rules !== null && _bidNumber == '') {
        <ng-template *ngTemplateOutlet="tplRules"></ng-template>
      }
      @if (rules !== null && _bidNumber != '') {
        <ng-template *ngTemplateOutlet="tplRulesSummary"></ng-template>
      }
    </tab>
    <tab  (selectTab)="changeViewMode('closed')">
      <ng-template tabHeading>
        <span class="nav-font14">Closed</span>
      </ng-template>
      @if (rules !== null && _bidNumber == '') {
        <ng-template *ngTemplateOutlet="tplRules"></ng-template>
      }
      @if (rules !== null && _bidNumber != '') {
        <ng-template *ngTemplateOutlet="tplRulesSummary"></ng-template>
      }
    </tab>
  </tabset>
</div>

<!-- box to add new rule -->
@if ((addMode || editMode) && _bidNumber == '') {
  <div class="card new position-absolute fixed-bottom">
    <div class="card-header border-0">
      <div class="row">
        <div class="col font-weight-bold">
          <span class="nav-secondary nav-font16">
            <i class="fak mr-2"
              [class.fa-clock]="editRule?.ruleType === 'scheduled'"
              [class.fa-lightning]="editRule?.ruleType === 'live'"
              [class.fa-tasks]="editRule?.ruleType === 'task'">
            </i>
            @if (addMode) {
              Create a {{ editRule?.ruleType === 'scheduled' ? 'timed' : (editRule?.ruleType === 'task' ? 'scheduled' : 'live') }} alert
            }
            @if (editMode) {
              @if (!editRule?.copy) {
                Edit {{ editRule?.ruleType === 'scheduled' ? 'timed' : (editRule?.ruleType === 'task' ? 'scheduled' : 'live') }} alert
              }
              @if (editRule?.copy) {
                Copy {{ editRule?.ruleType === 'scheduled' ? 'timed' : (editRule?.ruleType === 'task' ? 'scheduled' : 'live') }} alert
              }
            }
          </span>
        </div>
      </div>
    </div>
    <div class="card-body py-2 pb-2">
      <or-rule-manager [project]="_project" [tasks]="_tasks" [rule]="editRule" (conditionChanged)="setChangesEvent($event)"></or-rule-manager>
      @if (viewEmail && editRule?.ruleActions[0].alertMethod === 'Email') {
        <div class="row mt-2 mb-1 align-items-center">
          <div class="col grey5 nav-font14 font-weight-bold">E-mail template</div>
          <div class="col text-right pr-2">
            <span class="nav-font12 cursor-pointer" [ngClass]="!editEmail ? 'nav-accent-blue' : 'grey2'" (click)="editEmail ? null : editEmail=true">
              <i class="fak fa-edit-square mr-1"></i>Edit
            </span>
          </div>
        </div>
        <div class="row">
          @if (!editEmail) {
            <div class="col email-template scrollbar overflow-y word-wrap-break nav-font14" [innerHTML]="editRule?.emailTemplate" (dblclick)="editEmail=true"></div>
          }
          @if (editEmail) {
            <div class="col email-template ckeditor position-relative scrollbar overflow-y px-1 nav-font14" [ngClass]="editEmail ? 'cursor-auto' : 'cursor-pointer'">
              <or-wysiwyg (messageEvent)="editRule.emailTemplate = $event" [config]="editorConfig" [data]="editRule?.emailTemplate"></or-wysiwyg>
              <div class="save-note">
                <button (click)="cancelEditEmail()" class="btn btn-sm mx-1 nav-accent-blue shadow-none">Cancel</button>
                <button (click)="saveEditEmail()" class="btn btn-sm nav-btn-accent shadow-none" style="padding: 3px 8px">Save</button>
              </div>
            </div>
          }
        </div>
      }
    </div>
    <div class="card-footer bg-white border-0 p-2">
      <div class="row actions">
        @if (editRule?.ruleType !== 'task' && editRule?.ruleActions[0].alertMethod === 'Email') {
          <div class="col-4">
            <span class="nav-font12 cursor-pointer" [ngClass]="!viewEmail ? 'nav-accent-blue' : 'grey2'" (click)="!viewEmail ? viewEmailMode() : null"><i class="far fa-eye mr-1"></i>View E-mail</span>
          </div>
        }
        <div class="col text-right">
          <button class="btn btn-sm nav-btn-primary mx-1 shadow-none" (click)="cancel()">Cancel</button>
          <button class="btn btn-sm shadow-none" [disabled]="(viewEmail && editEmail)" [ngClass]="(viewEmail && editEmail) ? 'nav-btn-disabled' : 'nav-btn-accent'" (click)="confirm()">{{ addMode ? 'Create' : 'Save' }}</button>
        </div>
      </div>
    </div>
  </div>
}

<!-- Add Alert -->
<div class="bottom-menu fixed-bottom position-absolute" dropdown container="body" placement="top right">
  <ul class="nav nav-pills flex-column mb-auto">
    <li class="d-flex align-items-center px-3 py-2">
      <span class="grey4 nav-font14">({{rules?.length}}) {{ viewMode | titlecase }} {{ rules?.length == 1 ? 'alert' : 'alerts' }}</span>
      @if (!this.auth.isVendor() && !this.auth.isClient() && !isVendorview && !isClientview && _bidNumber == '') {
        <button class="btn btn-sm nav-btn-accent shadow-none font-weight-bold ml-auto" dropdownToggle>Add Alert</button>
      }
    </li>
  </ul>
  <!-- dropdown actions -->
  <ul class="dropdown-menu dropdown-menu-right dropdown-menu-alerts position-relative grey4 cursor-pointer px-1" *dropdownMenu role="menu" aria-labelledby="button-triggers-manual">
    <or-action-button label="Timed alert" description="Create alerts with a time and date to notify you" icon="fak fa-clock grey4" (selected)="addRule('scheduled')"></or-action-button>
    <hr class="mx-2 my-0">
    <or-action-button label="Live alert" description="Create an alert to notify you of a completes metric" icon="fak fa-lightning grey4" (selected)="addRule('live')"></or-action-button>

    @if (_tasks?.length > 0) {
      <hr class="mx-2 my-0">
      <or-action-button label="Task alert" description="Create an alert based on your tasks" icon="fak fa-tasks grey4" (selected)="addRule('task')"></or-action-button>
    }
  </ul>
</div>


<!-- Templates for a neater layout -->
<ng-template #tplRules>
  <!-- <div class="col d-flex justify-content-end px-2 my-3">
  <or-filter [values]="filterValues" position="left" (filter)="applyFilter($event)" *ngIf="!this.auth.isVendor()"></or-filter>
</div> -->
<div class="px-0">
  <div class="rules">
    @for (rule of rules; track rule; let idx = $index) {
      <div class="card m-2 mb-3" [class.editing]="addMode || editMode">
        <div class="card-header border-0">
          <div class="row">
            <div class="col font-weight-bold">
              <span class="grey5 nav-font14">{{ rule.name }}</span>
            </div>
            <div class="col-3 text-right">
              @if (rule?.ruleType === 'scheduled') {
                @if ((!rule.isExpired) && !rule.processed && !rule.triggered) {
                  <span [tooltip]="rule.id === '' ? '' : 'Scheduled'">
                    <i class="far fa-clock grey4" [class.text-white]="rule.id === ''"></i>
                  </span>
                }
                @if ((!rule.isExpired) && rule.processed && !rule.triggered) {
                  <span tooltip="Processed">
                    <i class="fak fa-clock grey4"></i>
                  </span>
                }
                @if ((!rule.isExpired) && rule.triggered) {
                  <span tooltip="Triggered">
                    <i class="fak fa-clock grey4"></i>
                  </span>
                }
                @if (rule.isExpired) {
                  <span tooltip="Expired">
                    <i class="fak fa-clock grey4"></i>
                  </span>
                }
              }
              @if (rule?.ruleType === 'live') {
                @if ((!rule.isExpired) && !rule.processed && !rule.triggered) {
                  <span [tooltip]="rule.id === '' ? '' : 'Live'">
                    <i class="fak fa-lightning grey4" [class.text-white]="rule.id === ''"></i>
                  </span>
                }
                @if ((!rule.isExpired) && rule.processed && !rule.triggered) {
                  <span tooltip="Processed">
                    <i class="fak fa-lightning grey4"></i>
                  </span>
                }
                @if ((!rule.isExpired) && rule.triggered) {
                  <span tooltip="Triggered">
                    <i class="fak fa-lightning grey4"></i>
                  </span>
                }
                @if (rule.isExpired) {
                  <span tooltip="Expired">
                    <i class="fak fa-lightning grey4"></i>
                  </span>
                }
              }
              @if (rule?.ruleType === 'task') {
                @if ((!rule.isExpired) && !rule.processed && !rule.triggered) {
                  <span [tooltip]="rule.id === '' ? '' : 'Task'">
                    <i class="fak fa-tasks grey4" [class.text-white]="rule.id === ''"></i>
                  </span>
                }
                @if ((!rule.isExpired) && rule.processed && !rule.triggered) {
                  <span tooltip="Processed">
                    <i class="fak fa-tasks grey4"></i>
                  </span>
                }
                @if ((!rule.isExpired) && rule.triggered) {
                  <span tooltip="Triggered">
                    <i class="fak fa-tasks grey4"></i>
                  </span>
                }
                @if (rule.isExpired) {
                  <span tooltip="Expired">
                    <i class="fak fa-tasks grey4"></i>
                  </span>
                }
              }
              @if (!addMode && !editMode && !isClientview && !isClient  && !this.auth.isVendor() && _bidNumber == '') {
                <span class="ml-3">
                  <i class="fak fa-ellipsis-vertical cursor-pointer" (click)="dropdown.toggle(true)"></i>
                </span>
              }
              <!-- dropdown actions (edit) -->
              <div class="actions-dropdown" dropdown #dropdown="bs-dropdown" container="body" placement="bottom right">
                <ul class="dropdown-menu dropdown-menu-right position-relative grey4 cursor-pointer px-1" *dropdownMenu role="menu" aria-labelledby="button-triggers-manual" (mouseleave)="dropdown.hide()">
                  @if (rule.isMine && !rule.isExpired) {
                    <a class="dropdown-item" (click)="edit(rule)">Edit</a>
                    <hr class="mx-2 my-0">
                  }
                  @if (rule.isMine && !rule.isExpired) {
                    <a class="dropdown-item" (click)="cancelAlert(rule)">Delete</a>
                    <hr class="mx-2 my-0">
                  }
                  <a class="dropdown-item" (click)="copy(rule)">Duplicate</a>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col grey3 nav-font12">{{rule.lastUpdatedText}} {{ rule.lastUpdated | date : 'MMM d' }}</div>
          </div>
        </div>
        <div class="card-body py-2 pb-3">
          <or-rule-manager [project]="_project" [tasks]="_tasks" [rule]="rule" (conditionChanged)="setChangesEvent($event)"></or-rule-manager>
        </div>
        @if (rule?.editing && !addMode) {
          <div class="card-footer bg-white border-0 p-3">
            <div class="row actions">
              <div class="col text-right">
                <button class="btn btn-sm nav-btn-primary mx-1 shadow-none" (click)="cancelEdit(rule)">Cancel</button>
                <button class="btn btn-sm nav-btn-accent shadow-none" (click)="confirm()">Save</button>
              </div>
            </div>
          </div>
        }
      </div>
    }
  </div>
</div>
</ng-template>

<ng-template #tplRulesSummary>
  <div class="col d-flex justify-content-end px-2 my-3">
    @if (!this.auth.isVendor()) {
      <or-filter [values]="filterValues" position="left" positionDistance="140" (filter)="applyFilterSummary($event)"></or-filter>
    }
  </div>
  <div class="px-0">
    <div class="rules">
      @for (ruleGroup of rules; track ruleGroup; let idxGroup = $index) {
        <div class="m-2 mb-3" [class.mt-4]="idxGroup > 0">
          <span class="nav-font14 grey5">{{ruleGroup[0].projectCode}}</span>
          @for (rule of ruleGroup; track rule; let idx = $index) {
            <div class="card m-2 mb-3" [class.editing]="addMode || editMode">
              <div class="card-header border-0">
                <div class="row">
                  <div class="col font-weight-bold">
                    <span class="grey5 nav-font14">{{ rule.name }}</span>
                  </div>
                  <div class="col-3 text-right">
                    @if (rule?.ruleType === 'scheduled') {
                      @if ((!rule.isExpired) && !rule.processed && !rule.triggered) {
                        <span [tooltip]="rule.id === '' ? '' : 'Scheduled'">
                          <i class="far fa-clock grey4" [class.text-white]="rule.id === ''"></i>
                        </span>
                      }
                      @if ((!rule.isExpired) && rule.processed && !rule.triggered) {
                        <span tooltip="Processed">
                          <i class="fak fa-clock grey4"></i>
                        </span>
                      }
                      @if ((!rule.isExpired) && rule.triggered) {
                        <span tooltip="Triggered">
                          <i class="fak fa-clock grey4"></i>
                        </span>
                      }
                      @if (rule.isExpired) {
                        <span tooltip="Expired">
                          <i class="fak fa-clock grey4"></i>
                        </span>
                      }
                    }
                    @if (rule?.ruleType === 'live') {
                      @if ((!rule.isExpired) && !rule.processed && !rule.triggered) {
                        <span [tooltip]="rule.id === '' ? '' : 'Live'">
                          <i class="fak fa-lightning grey4" [class.text-white]="rule.id === ''"></i>
                        </span>
                      }
                      @if ((!rule.isExpired) && rule.processed && !rule.triggered) {
                        <span tooltip="Processed">
                          <i class="fak fa-lightning grey4"></i>
                        </span>
                      }
                      @if ((!rule.isExpired) && rule.triggered) {
                        <span tooltip="Triggered">
                          <i class="fak fa-lightning grey4"></i>
                        </span>
                      }
                      @if (rule.isExpired) {
                        <span tooltip="Expired">
                          <i class="fak fa-lightning grey4"></i>
                        </span>
                      }
                    }
                    @if (rule?.ruleType === 'task') {
                      @if ((!rule.isExpired) && !rule.processed && !rule.triggered) {
                        <span [tooltip]="rule.id === '' ? '' : 'Task'">
                          <i class="fak fa-tasks grey4" [class.text-white]="rule.id === ''"></i>
                        </span>
                      }
                      @if ((!rule.isExpired) && rule.processed && !rule.triggered) {
                        <span tooltip="Processed">
                          <i class="fak fa-tasks grey4"></i>
                        </span>
                      }
                      @if ((!rule.isExpired) && rule.triggered) {
                        <span tooltip="Triggered">
                          <i class="fak fa-tasks grey4"></i>
                        </span>
                      }
                      @if (rule.isExpired) {
                        <span tooltip="Expired">
                          <i class="fak fa-tasks grey4"></i>
                        </span>
                      }
                    }
                    @if (!addMode && !editMode && !this.auth.isVendor() && _bidNumber == '') {
                      <span class="ml-3">
                        <i class="fak fa-ellipsis-vertical cursor-pointer" (click)="dropdown.toggle(true)"></i>
                      </span>
                    }
                    <!-- dropdown actions (edit) -->
                    <div class="actions-dropdown" dropdown #dropdown="bs-dropdown" container="body" placement="bottom right">
                      <ul class="dropdown-menu dropdown-menu-right position-relative grey4 cursor-pointer px-1" *dropdownMenu role="menu" aria-labelledby="button-triggers-manual" (mouseleave)="dropdown.hide()">
                        @if (rule.isMine && !rule.isExpired) {
                          <a class="dropdown-item" (click)="edit(rule)">Edit</a>
                          <hr class="mx-2 my-0">
                        }
                        @if (rule.isMine && !rule.isExpired) {
                          <a class="dropdown-item" (click)="cancelAlert(rule)">Delete</a>
                          <hr class="mx-2 my-0">
                        }
                        <a class="dropdown-item" (click)="copy(rule)">Duplicate</a>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col grey3 nav-font12">{{rule.lastUpdatedText}} {{ rule.lastUpdated | date : 'MMM d' }}</div>
                </div>
              </div>
              <div class="card-body py-2 pb-3">
                <or-rule-manager [project]="_project" [tasks]="_tasks" [rule]="rule" (conditionChanged)="setChangesEvent($event)"></or-rule-manager>
              </div>
              @if (rule?.editing && !addMode) {
                <div class="card-footer bg-white border-0 p-3">
                  <div class="row actions">
                    <div class="col text-right">
                      <button class="btn btn-sm nav-btn-primary mx-1 shadow-none" (click)="cancelEdit(rule)">Cancel</button>
                      <button class="btn btn-sm nav-btn-accent shadow-none" (click)="confirm()">Save</button>
                    </div>
                  </div>
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
  </div>
</ng-template>
