import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-project-card-test-mode',
  templateUrl: './project-card-test-mode.component.html',
  styleUrls: ['./project-card-test-mode.component.scss']
})
export class ProjectCardTestModeComponent implements OnInit {

  @Input() project: any;
  @Output() selected = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  projectClicked(project) {
    this.selected.emit(project);
  }

}
