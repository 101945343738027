import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit  } from '@angular/core';
import { Project, ProjectService, AlertifyService, AuthService } from 'core';

declare var google: any;

@Component({
  selector: 'app-project-card-dedupe',
  templateUrl: './project-card-dedupe.component.html',
  styleUrls: ['./project-card-dedupe.component.scss']
})
export class ProjectCardDedupeComponent implements OnInit {
  @Input() mode = 'reduced';
  @Input() project: Project;
  @Input()  public set respondents(data) {
    this.processData(data);
  }

  @Output() cardresize = new EventEmitter();
  total;
  trendData: any;
  dupe = 0;
  loading = false;
  dupeStatusList;
  dupeStatusChanged = false;

  constructor(private projectService: ProjectService, private alertify: AlertifyService, public auth: AuthService) { }

  ngOnInit() {
    this.resetStatusSetting();
  }
  processData(data) {
      let total = 0;
      let dupe = 0;

      data.forEach(e => {
        total += e.starts;
        dupe += e.dupe;
      });
      this.dupe = dupe;
      this.total = total;

      if (dupe === 0 ) {
        // TO DO: SHOW NO DATA DETAILS
        return;
      }
      data.sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });
      const startDate = new Date(data[0].date);
      const endDate = new Date(data[data.length - 1].date);
      endDate.setHours(23);
      const chartData = [];
      for (let d = startDate; d.getTime() <= endDate.getTime(); ) {
        const dDate = d.toISOString().split('T')[0];
        const dayDetails = data.filter(e =>  e.date.toISOString().split('T')[0] === dDate);
        let count = 0;
        dayDetails.forEach(e => {
          count += e.dupe;
        });
        chartData.push([ new Date(d), count]);
        d = new Date(d.setDate(d.getDate() + 1));
      }
      this.trendData = chartData;
    }

  resizeCard() {
    this.mode = this.mode === 'reduced' ? 'expanded' : 'reduced';
    this.cardresize.emit(this.mode);
  }

  changeSetting(evt, setting) {
    const rollback = this.project[setting];
    this.project[setting] = evt;
    this.loading = true;

    this.projectService.UpdateProjectValue(this.project.id, setting, evt).subscribe( data => {
      this.alertify.success('DeDupe Updated for this project');
      this.loading = false;
    } , error => {
      this.alertify.error('Failed to Update DeDupe');
      this.project[setting] = rollback;
      this.loading = false;
    });
  }

  saveStatusSetting() {
    this.dupeStatusChanged = false;
    this.loading = true;
    this.projectService.UpdateProjectDedupeStatus(this.project.id, this.dupeStatusList).subscribe( data => {
      this.project.projectDedupeStatus = this.dupeStatusList;
      this.resetStatusSetting();
      this.alertify.success('DeDupe Settings Updated for this project');
      this.loading = false;
    } , error => {
      this.alertify.error('DeDupe Settings Failed to Update');
      this.dupeStatusChanged = true;
      this.loading = false;
    });

  }

  setDupeStatusChanged( newList) {
    this.dupeStatusList = newList;
    // TO DO: ONLY SHOW IF VALUES ARE DIFFERENT TO WHAT WAS ALREADY STORED
    this.dupeStatusChanged = true;
  }

  resetStatusSetting() {
    this.dupeStatusList = JSON.parse(JSON.stringify(this.project.projectDedupeStatus));
    this.dupeStatusChanged = false;
  }

}
